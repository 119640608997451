import React, {useContext} from 'react'
import { LangContext } from '../../../context/DataContext'

function RecoleccionInformacion() {
    const {myLang} = useContext(LangContext)
    return (
        <div> 
            <div id="p9"> 
                {myLang === 'es' && <>
                    <h2>Recolecci&oacute;n de Informaci&oacute;n</h2>
                    <p>Para conocer los distintos  modelos de CPEs con soporte IPv6 m&aacute;s utilizados en la regi&oacute;n se realizaron las  siguientes acciones:</p>
                    <ul>
                        <li><strong>Consultas a fabricantes de CPEs</strong>: Se contactaron a algunos fabricantes de CPEs con dispositivos en las tecnolog&iacute;as  de acceso requeridas.</li>
                        <li><strong>Encuesta a ISPs</strong>: Se realiz&oacute; una encuesta  dirigida a los ISPs para que nos cuenten si est&aacute;n brindando IPv6 y cuales son  los CPEs m&aacute;s utilizados con soporte de IPv6.</li>
                    </ul>
                    <p>A continuaci&oacute;n se  brindar&aacute; informaci&oacute;n detallada sobre cada acci&oacute;n.</p> <p>&nbsp;</p>
                </>}
            </div>
        </div>
    )
}

export default RecoleccionInformacion
