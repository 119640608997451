import React, {useState} from 'react';
import { LangContext } from './DataContext';  
import '../css/article.css' 

export const LangState = ( {children} ) => {  
     
    /* Lenguaje importante */
     if(localStorage.getItem('lang') === null) { 
        localStorage.setItem('lang', JSON.stringify('es'));
        window.location.href = "/es"; 
    }     
    // Obligo a la aplicación a crear la propiedad "lang" en Local Storage, si es null por defecto agrega 'es'
    
    let defaultLang = 'es';  // defino un lenguaje por defecto
    
    const lang =   JSON.parse(localStorage.getItem('lang') || defaultLang); //establezco el lenguaje por defecto de la aplicación
    
    const [ myLang, setMyLang] = useState(lang); //establezco el estado del lenguaje
     
    const handleLanguaje = (value) =>{  
        localStorage.setItem('lang', JSON.stringify(value));  
        setMyLang(value) 
    } // si hago click en el botón de lenguaje cambiará de idioma y lo actualizo en el estado y en localstorage
    
    //Data Lang para controlar las iteraciones de los componentes
    const datalang = [ {languaje : 'es'}, {languaje : 'en'}, {languaje : 'pt'} ]
    
    /* Lenguaje importante */ 
    return (
        <LangContext.Provider value={
             {myLang, setMyLang, handleLanguaje, datalang}
          }>
             {children}
        </LangContext.Provider>
    );
}