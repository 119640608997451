import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'

function Acciones(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p6">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-81">Acciones realizadas</MatchText></h2>
                    <p>
                        <MatchText id="match-82">El trabajo de investigación realizó las siguientes acciones:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-83">Seleccionó a las organizaciones para reportar las vulnerabilidades de los distintos protocolos:</MatchText></li>
                        <li>
                            <ul>
                                <li>
                                    <MatchText id="match-84">CharGen</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r12_es.png" /> </figure>
                                </li>
                                <li>
                                    <MatchText id="match-85">NTP (versión)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r13_es.png" /> </figure>

                                    <MatchText id="match-"> Nota: no se incluyó el tercer ASN para incluir organizaciones de tres países distintos y lograr mayor cobertura del proyecto en lo que refiere a la mitigación de esta vulnerabilidad.</MatchText></li>
                                <li>
                                    <MatchText id="match-86">NTP (monitor)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r14_es.png" /> </figure>

                                    <MatchText id="match-87"> Nota: no se incluyeron el segundo, tercer y quinto ASN para incluir organizaciones de tres países distintos y lograr mayor cobertura de países en el proyecto, en lo que refiere a la mitigación de esta vulnerabilidad.</MatchText></li>
                                <li>
                                    <MatchText id="match-88">SSDP</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r15_es.png" /> </figure>
                                </li>
                                <li>
                                    <MatchText id="match-89">DNS</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r16_es.png" /> </figure>

                                    <MatchText id="match-90"> Nota: no se incluyeron el quinto y sexto ASN para incluir organizaciones de cinco países distintos y lograr mayor cobertura de países en el proyecto, en lo que refiere a la mitigación de esta vulnerabilidad.</MatchText></li>
                            </ul>
                        </li>
                        <li>
                            <MatchText id="match-91">Se buscaron puntos de contactos válidos para las organizaciones elegidas. En conjunto con LACNIC se buscaron los contactos asociados a partir de la información brindada por los servicios de WHOIS y RDAP. También se tuvo en cuenta la información de los CSIRT de nuestra comunidad para contar con un contacto alternativo en caso de ser necesario.</MatchText></li>
                        <li>
                            <MatchText id="match-92">Se contactaron doce organizaciones de países de la región. Una de ellas presentaba cuatro de las dificultades detectadas y otras dos de las problemáticas involucradas en este estudio.</MatchText></li>
                        <li>
                            <MatchText id="match-93">Se informó sobre los distintos tipos de hallazgos.</MatchText></li>
                        <li>
                            <MatchText id="match-94">Se generó la evidencia para presentar la problemática a los afectados de manera tal que estos puedan dimensionar el impacto.</MatchText></li>
                        <li>
                            <MatchText id="match-95">Se contactó a los asociados de LACNIC seleccionados que tienen servicios vulnerables. En un primer mensaje se explicó el objetivo del proyecto y se solicitó confirmación para saber si el contacto era pertinente, ofreciendo a partir de ello compartir la información específica de las vulnerabilidades halladas.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-96">En función de las necesidades de las organizaciones contactadas con el objeto de dar soporte:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-97">Se programaron reuniones para trabajar en conjunto con los asociados afectados de manera tal de mitigar las vulnerabilidades halladas, mejorando así el nivel de seguridad de la región.</MatchText></li>
                        <li>
                            <MatchText id="match-98">Se respondieron consultas e inquietudes por correo electrónico, tanto para dar soporte en la mitigación como para verificar la correcta solución de las problemáticas informadas.</MatchText></li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-81">Actions</MatchText></h2>
                    <p>
                        <MatchText id="match-82">The study included the following actions:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-83">The organizations for which the vulnerabilities of the different protocols were to be recorded were selecte:</MatchText></li>
                        <li>
                            <ul>
                                <li>
                                    <MatchText id="match-84">CharGen</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r12_es.png" /> </figure>
                                </li>
                                <li>
                                    <MatchText id="match-85">NTP (version)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r13_es.png" /> </figure>

                                    <MatchText id="match-">  Note: The third ASN was not selected. Instead, organizations from three different countries were included to increase the project's coverage for mitigating this vulnerability.</MatchText></li>
                                <li>
                                    <MatchText id="match-86">NTP (monitor)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r14_es.png" /> </figure>

                                    <MatchText id="match-87">  Note: The second, third and fifth ASNs were not selected. Instead, organizations from three different countries were included to increase the project's coverage for mitigating this vulnerability.</MatchText></li>
                                <li>
                                    <MatchText id="match-88">SSDP<figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r15_es.png" /> </figure>
                                    </MatchText></li>
                                <li>
                                    <MatchText id="match-89">DNS</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r16_es.png" /> </figure>

                                    <MatchText id="match-90"> Note: The fifth and sixth ASNs were not selected. Instead, organizations from five different countries were included to increase the project's coverage for mitigating this vulnerability.</MatchText></li>
                            </ul>
                        </li>
                        <li>
                            <MatchText id="match-91">A search for valid points of contact for the selected organizations was performed. Together with LACNIC, a search for contacts was performed using the information provided by the WHOIS and RDAP services. Information available from the CSIRTs operating in our community was also used to find alternative points of contact where necessary.</MatchText></li>
                        <li>
                            <MatchText id="match-92">Twelve organizations from countries in the region were contacted. For one of these organizations, four issues were detected; for others, two.</MatchText></li>
                        <li>
                            <MatchText id="match-93">The different findings were reported.</MatchText></li>
                        <li>
                            <MatchText id="match-94">Evidence was produced to inform the affected parties of the detected issues so that they would be aware of their impact.</MatchText></li>
                        <li>
                            <MatchText id="match-95">Selected LACNIC members for which vulnerable services were detected were contacted. A first message was sent explaining the purpose of the project and asking them to confirm whether they agreed to be contacted. Members were then asked whether they would like to receive specific information regarding the vulnerabilities detected by the project.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-96">Depending on the needs of the organizations we contacted for the purpose of offering our support:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-97">Meetings were scheduled to collaborate with the affected members to mitigate the vulnerabilities that were detected and thus improve regional security level.</MatchText></li>
                        <li>
                            <MatchText id="match-98">Questions and concerns were addressed via email, both to support their mitigation efforts and to verify the correct solution of the reported issues.</MatchText></li>
                    </ul>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-81">Ações realizadas</MatchText></h2>
                    <p>
                        <MatchText id="match-82">No trabalho de pesquisa foram realizadas as seguintes ações:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-83">Selecionou as organizações para relatar as vulnerabilidades dos diferentes protocolos:</MatchText></li>
                        <li>
                            <ul>
                                <li>
                                    <MatchText id="match-84">CharGen</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r12_es.png" /> </figure>
                                </li>
                                <li>
                                    <MatchText id="match-85">NTP (versão)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r13_es.png" /> </figure>

                                    <MatchText id="match-">  Nota: o terceiro ASN não foi incluído para incluir organizações de três países diferentes e conseguir uma maior cobertura do projeto, em termos de mitigação desta vulnerabilidade.</MatchText></li>
                                <li>
                                    <MatchText id="match-86">NTP (monitor)</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r14_es.png" /> </figure>

                                    <MatchText id="match-87"> Nota: o segundo, terceiro e quinto ASN não foram incluídos para incluir organizações de três países diferentes e conseguir uma maior cobertura de países no projeto, em termos de mitigação desta vulnerabilidade.</MatchText></li>
                                <li>
                                    <MatchText id="match-88">SSDP</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r15_es.png" /> </figure>
                                </li>
                                <li>
                                    <MatchText id="match-89">DNS<figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r16_es.png" /> </figure>
                                        Nota: o quinto e sext90o ASN não foram incluídos para incluir organizações de cinco países diferentes e conseguir uma maior cobertura de países no projeto, em termos de mitigação desta vulnerabilidad.</MatchText></li>
                            </ul>
                        </li>
                        <li>
                            <MatchText id="match-91">Foram procurados pontos de contatos válidos para as organizações escolhidas. Em conjunto com o LACNIC, os contatos associados foram buscados a partir das informações disponibilizadas pelos serviços de WHOIS e RDAP. As informações dos CSIRT da nossa comunidade também foram levadas em consideração para que houvesse um contato alternativo se necessário.</MatchText></li>
                        <li>
                            <MatchText id="match-92">Foram contatadas doze organizações de países da região. Uma delas apresentava quatro das dificuldades detectadas e outros dois dos problemas envolvidos neste estudo.</MatchText></li>
                        <li>
                            <MatchText id="match-93">Informou-se sobre os diferentes tipos de descobertas.</MatchText></li>
                        <li>
                            <MatchText id="match-94">Foram geradas evidências para apresentar o problema às pessoas afetadas de forma que possam medir o impacto.</MatchText></li>
                        <li>
                            <MatchText id="match-95">Foram contatados os associados do LACNIC selecionados que têm serviços vulneráveis. Em uma primeira mensagem, foi explicado o objetivo do projeto e solicitada a confirmação para saber se o contato era pertinente, oferecendo a partir disso compartilhar as informações específicas das vulnerabilidades encontradas.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-96">Dependendo das necessidades das organizações contatadas com o objetivo de dar suporte:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-97">Foram agendadas reuniões para trabalhar em conjunto com os associados afetados, a fim de mitigar as vulnerabilidades encontradas, melhorando assim o nível de segurança da região.</MatchText></li>
                        <li>
                            <MatchText id="match-98">Foram respondidas perguntas e dúvidas por e-mail, tanto para dar suporte na mitigação quanto para verificar a solução correta dos problemas relatados.</MatchText></li>
                    </ul>

                </>}
            </div>
        </div>
    );
}

export default Acciones;