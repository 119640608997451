import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import Alerts from '../../../components/Alerts'
import Button from '../../../components/Button'
import { MatchText } from 'react-ctrl-f';


export default function Resumen(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p1">
                {myLang === 'es' && <>
                    <Alerts color="alert_white">
                        <p>
                            <MatchText id="match-1">Área: I+D en Infraestructura de Internet</MatchText></p>
                        <p>
                            <MatchText id="match-2"> Proyecto: Fortalecimiento de Infraestructura de Internet Regional</MatchText></p>
                        <p>
                            <MatchText id="match-3">Autor: Agustín Formoso</MatchText></p>
                        <p>
                            <MatchText id="match-4">Coordinación/Revisión: Guillermo Cicileo y Elisa Peirano</MatchText></p>
                    </Alerts>
                    <p>
                        <MatchText id="match-5">Cuando hablamos de conectividad, ¿de qué estamos hablando? Desde que las redes se conectan unas con las otras, los operadores han estado trabajando para que la conectividad entre ellas sea mejor. En este artículo les presentamos un estudio de conectividad donde les explicamos cómo medimos la conectividad de la región LAC, y cómo ha evolucionado durante los últimos años.</MatchText></p>
                    <p>
                        <MatchText id="match-6">Este estudio se compara con otros similares realizados en años anteriores y podemos ver que hay un grupo importante de países (que incluye algunos de los países con mayor población y desarrollo de Internet de la región) que en 2020 presentan una mejora importante respecto de 2017, donde la mediana de la latencia medida mejora en 49 ms. Además, si se considera el subconjunto de redes (Sistemas Autónomos) que se midieron en ambas campañas, se observan mejoras. Y si se consideran los datos en términos relativos a las distancias geográficas <a href="https://imasd.lacnic.net/conect_lac.html#velocidadPING">(velocidad de ping) </a>, también se observan mejoras.</MatchText></p>
                    <Button color="button_blue" to="/reportes/interconexion/Conectividad_LAC_2020_ES.pdf" target="_blank">Descargar (PDF)</Button>

                </>}
                {myLang === 'en' && <>
                    <Alerts color="alert_white">
                        <p>
                            <MatchText id="match-1">Departament:  Internet Infrastructure R&amp;D</MatchText></p>
                        <p>
                            <MatchText id="match-2"> Project:  Strengthening Regional Internet Infrastructure</MatchText></p>
                        <p>
                            <MatchText id="match-3"> Author:  Agustín Formoso</MatchText></p>
                        <p>
                            <MatchText id="match-4"> Coordination/Revision:  Guillermo Cicileo y Elisa Peirano</MatchText></p>
                    </Alerts>
                    <p>
                        <MatchText id="match-5">When we talk about connectivity, what are we talking about? Since the networks are connected to each other, the operators have been working to make the connectivity between them better. In this article we present a connectivity study where we explain how we measure the connectivity of the LAC region, and how it has evolved in recent years.</MatchText></p>
                    <p>
                        <MatchText id="match-6">This study is compared with other similar ones carried out in previous years and we can see that there is an important group of countries (which includes some of the countries with the largest population and Internet development in the region) that in 2020 show a significant improvement compared to 2017, where the median measured latency improves by 49 ms. Furthermore, if the subset of networks (Autonomous Systems) that were measured in both campaigns is considered, improvements are observed. And if the data is considered in terms relative to geographic distances&nbsp;<a href="#velocidadPING">(ping speed)&nbsp;</a>, improvements are also observed.</MatchText></p>
                    <Button color="button_blue" to="/reportes/interconexion/Conectividad_LAC_2020_EN.pdf" target="_blank">Download (PDF)</Button>
                </>}
                {/*  */}
                {myLang === 'pt' && <>
                    <Alerts color="alert_white">
                        <p>
                            <MatchText id="match-1">&Aacute;rea:&nbsp;I+D na infraestrutura da Internet</MatchText></p>
                        <p>
                            <MatchText id="match-2"> Projeto:&nbsp;Fortalecimento de Infraestrutura da Internet Regional</MatchText></p>
                        <p>
                            <MatchText id="match-3"> Autor:&nbsp;Agust&iacute;n Formoso</MatchText></p>
                        <p>
                            <MatchText id="match-4"> Coordena&ccedil;&atilde;o/revis&atilde;o:&nbsp;Guillermo Cicileo y Elisa Peirano</MatchText></p>
                    </Alerts>
                    <p>
                        <MatchText id="match-5">Quando falamos sobre conectividade, do que estamos falando? Como as redes est&atilde;o conectadas entre si, as operadoras t&ecirc;m trabalhado para tornar a conectividade entre elas melhor. Neste artigo, apresentamos um estudo de conectividade onde explicamos como medimos a conectividade da regi&atilde;o da ALC e como ela evoluiu nos &uacute;ltimos anos.</MatchText></p>
                    <p>
                        <MatchText id="match-6">Este estudo &eacute; comparado com outros semelhantes realizados em anos anteriores e podemos constatar que existe um grupo importante de pa&iacute;ses (que inclui alguns dos pa&iacute;ses com maior popula&ccedil;&atilde;o e desenvolvimento de Internet na regi&atilde;o) que em 2020 apresentam uma melhoria significativa em compara&ccedil;&atilde;o com 2017, onde a lat&ecirc;ncia mediana medida melhora em 49 ms. Al&eacute;m disso, se for considerado o subconjunto de redes (Sistemas Aut&ocirc;nomos) que foram medidos em ambas as campanhas, melhorias s&atilde;o observadas. E se os dados s&atilde;o considerados em termos relativos a dist&acirc;ncias geogr&aacute;ficas&nbsp;<a href="#velocidadPING">(velocidade do ping)&nbsp;</a>, melhorias tamb&eacute;m s&atilde;o observadas.</MatchText></p>
                    <Button color="button_blue" to="/reportes/interconexion/Conectividad_LAC_2020_PT.pdf" target="_blank">Download (PDF)</Button>
                </>}
            </div>
        </div>
    );
} 