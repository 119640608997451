import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Situacion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' &&  <Contenido/>}
                {myLang === 'en' &&  <Contenido/>}
                {myLang === 'pt' &&  <Contenido/>}
            </div>
        </div>
    )
}
function Contenido() {
    return (<>
        <h2><MatchText id="match-14">Situación actual</MatchText></h2>
        <p><MatchText id="match-15">A pesar del esfuerzo coordinado entre los Registros Regionales de Internet por mantener el tama&ntilde;o de la tabla de BGP (i.e. la base que asocia ASes a prefijos) lo m&aacute;s reducida posible, &eacute;sta cuenta en la actualidad con unos 900.000 registros en IPv4, m&aacute;s de 150.000 registros en IPv6, y sigue creciendo. Un n&uacute;mero tan alto dificulta la correcta comprensi&oacute;n respecto a c&oacute;mo fluye el tr&aacute;fico en cada AS y entre ellos, y requiere adem&aacute;s el intercambio y procesamiento de mucha informaci&oacute;n. La dificultad se potencia por el hecho que al estar sesgadas por diversos factores y decisiones administrativas, las entradas en la tabla cambian de un router a otro, incluso dentro de un mismo AS.</MatchText></p>
        <figure>
            <img alt="Ver" src="/images/optimizacion-de-redes-mediante-un-mejor-ibgp/figure3.png" />
            <figurecaption><MatchText id="match-16">Gr&aacute;fico que muestra el crecimiento de la tabla de ruteo desde Junio de 1988 hasta Marzo del 2022 (fuente </MatchText><a href="https://www.cidr-report.org/" target="_blank" rel="noreferrer"><MatchText>https://www.cidr-report.org/</MatchText></a>)</figurecaption>
        </figure>   
        <p><MatchText id="match-17">El tama&ntilde;o del problema afecta negativamente la calidad de la ingenier&iacute;a de la red, al ser humanamente imposible en primer lugar el identificar muchos de los problemas; paso necesario para buscar sus soluciones.</MatchText></p>
        <p><MatchText id="match-18">Para determinar los mejores peers con los cuales conectarse, se puede considerar: temas vinculados a la ubicaci&oacute;n geogr&aacute;fica; a la infraestructura f&iacute;sica disponible; a las redes que queremos acceder; motivos de costos de conexi&oacute;n o de calidad de los peers. Respecto de este &uacute;ltimo para seleccionar el mejor proveedor o peer, podemos utilizar el ranking de CAIDA. Para realizar este ranking utiliza el concepto de <strong>cono</strong>, el cual establece una medida de los AS que pueden alcanzarse a partir de un AS en particular. En el cono de un AS determinado va a estar el AS al que hacemos referencia, sus clientes, los clientes de sus clientes, los clientes de estos y as&iacute; sucesivamente. De esta manera, si nos conectamos con un proveedor que tiene un cono de mayor tama&ntilde;o que otro, vamos a tener una conectividad mejor con m&aacute;s cantidad de ASes, que si lo realiz&aacute;ramos con un AS que posea un cono de menor tama&ntilde;o. Por lo tanto analizando los peers de cada ASBR y el tama&ntilde;o de sus respectivos conos vamos a tener una medida de por d&oacute;nde vamos a tener una conectividad con m&aacute;s AS.</MatchText></p>
        <p><MatchText id="match-19">Tambi&eacute;n existen tecnolog&iacute;as para mitigar la sobrecarga de mensajes y su procesamiento, donde se destaca el uso de Reflectores de Rutas (RRs). Sin embargo, el mero dise&ntilde;o de un overlay iBGP usando RRs es una tarea desafiante si se busca preservar el funcionamiento &oacute;ptimo de ese protocolo. La dificultad tambi&eacute;n tiene al tama&ntilde;o de la tabla como una de sus causas ra&iacute;z.</MatchText></p>
    
    </>)
}
export default Situacion
