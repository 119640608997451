import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css'; 
import Resumen from "./Resumen"; 
import Objetivo from "./Objetivo";
import Motivacion from "./Motivacion";
import Analisis from "./Analisis";
import Relevamiento from "./Relevamiento";
import Acciones from "./Acciones";
import Resultados from "./Resultados";
import Conclusiones from "./Conclusiones";
import Referencias from "./Referencias";

export default function VulnerabilidadAtaquesDdosMaster(props) {

    return ( <>
        <ReportTitle />
        <Resumen />
        <Objetivo />
        <Motivacion />
        <Analisis />
        <Relevamiento />
        <Acciones />
        <Resultados />
        <Conclusiones />
        <Referencias />
    </>)
}

function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
        <h1>
            {myLang === 'es' && 'Estudio de recursos de internet de la región LAC que posibilitan ataques de DDoS'}
            {myLang === 'en' && 'Study of Internet Resources in the LACNIC Service Region that Use UDP and Enable DDoS Attacks'}
            {myLang === 'pt' && 'Estudo dos recursos da Internet presentes na região do LACNIC com protocolos UDP que possibilitam ataques do tipo DDoS'}
        </h1>
    )
}







