import React, {  useContext } from 'react';
import MenuLateralReports from '../../../components/MenuLateralReports';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
/* import SearchComponent from '../../../components/SearchComponent'; */

function MenuLateralInterconexionBgpLocal() {
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return (  
              <>   
              {myLang === 'es' && 
                <nav>
                    <button>Uso de root servers del DNS en la región
                         <i className="fas fa-angle-down"></i></button>
                    {/* <SearchComponent /> */}
                    <ul className="active">
                        <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen</a></li>
                        <li><a href="#introduccion" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')} >Introducción</a></li>
                        <li><a href="#root-servers-y-su-importancia" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')} >Root Servers y su Importancia</a></li>
                        <li><a href="#metodologia-del-estudio" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')} >Metodologia del Estudio</a></li>
                        <li><a href="#resultados" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')} >Resultados</a></li>
                        <li><a href="#tiempos-de-respuesta-nacionales-hacia-cada-root-server" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')} >Tiempos de respuesta nacionales hacia cada root server</a></li>
                        <li><a href="#tiempos-de-respuesta-promedio-por-pais" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')} >Tiempos de respuesta promedio por país</a></li>
                        <li><a href="#conclusiones" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')} >Conclusiones</a></li>
                        <li><a href="#sobre-el-estudio" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')} >Sobre el estudio</a></li>
                        <li><a href="#anexos" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')} >Anexos</a></li>
                   
                    </ul>
                </nav>
                } 
                {myLang === 'en' && 
                <nav>
                    <button>Use of DNS Root Servers in Latin America
                         <i className="fas fa-angle-down"></i></button>
                    {/* <SearchComponent /> */}
                    <ul className="active">
                        <li><a href="#abstract" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Abstract</a></li>
                        <li><a href="#introduction" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')} >Introduction</a></li>
                        <li><a href="#root-servers-and-their-importance" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')} >Root Servers and their Importance</a></li>
                        <li><a href="#research-methodolgy" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')} >Research Methodolgy</a></li>
                        <li><a href="#results" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')} >Results</a></li>
                        <li><a href="#national-response-times-for-each-root-server" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')} >National Response Times for Each Root Server</a></li>
                        <li><a href="#average-response-times-by-country" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')} >Average Response Times By Country</a></li>
                        <li><a href="#conclusions" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')} >Conclusions</a></li>
                        <li><a href="#about-this-study" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')} >About This Study</a></li>
                        <li><a href="#annexes" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')} >Annexes</a></li>
                   
                    </ul>
                </nav>
                } 
                {myLang ==='pt' && <><MenuLateralReports category="interconexion" /> </>}
                </>
             
    );
}

export default MenuLateralInterconexionBgpLocal
