import React from 'react'
import Button from '../../../components/Button'

function Conectividad2022Pt({styles}) {
    return (
        <>
            <h1>Análise das rotas do tráfego dentro de cada país da região LAC</h1> 
            <div><div id="p1" className="alerts alert_white">
                <p>Autor: Agustín Formoso y Guillermo Cicileo</p>
                <p>Coordenação/revisãon: Área de Comunicaciones</p>
                <p>Edição: Septiembre 2023</p>
                <p>Área: Área de Tecnología</p>
                <Button color="button_blue" to="/reportes/interconexion/lacnic-estudio_trafico_ pais_lac-pt.pdf">Download (PDF)</Button>
            </div></div>

            <h2 id="resumen_select">Resumo Executivo</h2>
            <p >Este documento faz parte de uma série de documentos que analisam as medições feitas durante 2022 por parte do LACNIC na região da ALC. Este documento abrange as <strong key="7">medições feitas internamente em um país: tráfego com origem em um país e com destino dentro do mesmo país</strong>.<br />
                A medição ativa do comportamento do tráfego foi realizada mediante o uso de traceroutes. Esses traceroutes são medidos desde diferentes pontos da rede para endereços IP de cada país, medindo cerca de <a href="#5500redes" >5500 redes</a>Com base nisso, foram realizadas diversas análises que estão à disposição dos operadores da região e de outros pesquisadores que queiram aprofundar no assunto. Além disso, <a href="#anexo3grafos">os dados foram disponibilizados</a> para quem quiser fazer uma análise mais aprofundada.<br />
                Em termos gerais, foi medida uma latência melhor do que na campanha de 2020. Em relação ao estudo de 2020, este estudo acrescenta a <a href="#perspectivacaminos" >perspectiva de rotas</a>, isto é, os pontos intermediários por onde o tráfego passa.<br />
                Países com presença de IXP ou grandes operadores mantêm mais de 90% do tráfego dentro do país. Também nesses casos, os valores de latência são tipicamente inferiores a 40ms, e quando os <a href="#caminoslatencias">caminhos e latências</a> são observados ao mesmo tempo, percebe-se um panorama razoável. Este estudo também toma nota dos <a href="#fugatrafico">casos em que há vazamento de tráfego</a>, e por onde isso acontece.<br />
                Ampliando para o nível de ASN, é listado um ranking de cada país com <a href="#redcentral" target='_blank' rel="noreferrer">as redes mais centrais</a>, quer dizer, aquelas que recolheram a maioria das medições. Este ranking inclui as redes de cada país, bem como as de outros países e redes que operam em vários países. Observa-se que os IXP estabelecidos estão no topo do ranking. Além disso, foram criados <a href="#anexo3grafos">grafos de conectividade</a> que mostram as relações entre as diferentes redes de cada país.<br />
                É dedicada uma seção exclusiva à análise dos IXP. Aprecia-se que estes normalmente roteiam mais de 40% do tráfego medido, o tráfego que passa pelo IXP apresenta um <a href="#aspath">as-path mais curto</a>, e têm <a href="#impactolatencia" >latência</a> em comparação ao tráfego que não passa pelo IXP.<br />
                Adicionalmente, são fornecidos 3 anexos detalhando os destinos dos traceroutes de saída (ASN), as rotas com circuitos no exterior e os grafos de conectividade de cada país.
            </p>
            <ul>
                <li><a href="#anexo1" >Anexo 1: Destinos de traceroutes de saída (ASN)</a></li>
                <li><a href="#anexo2" >Anexo 2: Rotas com circuitos no exterior</a></li>
                <li><a href="#anexo3grafos">Anexo 3: Grafos dos países da ALC</a></li>
            </ul>


            <h2 key="7" id="introduccion_select">Introdução: análise de caminhos do tráfego regional</h2>
            <p key="8">No LACNIC realizamos diferentes tipos de medições na Internet em nível nacional e principalmente em nível regional. Um dos aspectos que queremos medir é a conectividade entre as redes que operam nos países da região, por isso realizamos estudos baseados em tempos de latência. Estes estudos mostraram uma melhoria significativa em termos de tempos de conectividade tanto dentro de cada país como entre países. Confira <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">o estudo de Conectividade na região da ALC: 2020</a> na seção de Relatórios Técnicos do site do LACNIC.<br />
                Atendendo às dúvidas e ao interesse da comunidade quanto aos resultados destes estudos baseados em medições de latência, realizamos outros estudos com base nas informações disponíveis nas tabelas de roteamento da região: <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-en-lac">Interconexão no BGP na região da América Latina e o Caribe</a> e <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">Interconexão BGP em nível local na região da América Latina e o Caribe</a>. Estes estudos permitiram ter uma visão geral da conectividade no nível do roteamento em cada país e no nível regional, complementando as informações anteriores.<br />
                Para este novo estudo procuramos fazer uma medição ativa do comportamento do tráfego mediante o uso de traceroutes. Esses traceroutes são medidos a partir de diferentes pontos da rede para endereços IP de cada país. E com os resultados podemos analisar quais ASN possuem maior centralidade em cada país, se os caminhos têm comprimentos razoáveis, se permanecem dentro de cada país ou se são feitas interconexões no exterior, se se constata a presença de IXP locais nesse país, entre outros.
            </p>


            <h2 id="datosusados_select">Dados usados neste estudo</h2>
            <h3 id="fuente">Fontes de dados</h3>
            <p>Acerca das fontes de dados usadas:</p>
            <ul>
                <li><strong>Geofeeds do LACNIC:</strong> é um serviço do MiLACNIC em que os membros podem adicionar informações de geolocalização de seus blocos IP <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic">(Saiba Mais)</a>.</li>
                <li><strong>nformações de registro</strong>: os RIR são uma fonte de dados que indica onde os endereços IP são registrados, em especial aqueles que aparecem no conjunto de dados deste estudo. O formato usado são os arquivos “delegados” publicados por cada RIR <a target='_blank' rel="noreferrer" href="https://ftp.ripe.net/pub/stats/">(Saiba Mais)</a>.</li>
                <li><strong>PeeringDB</strong>: é uma plataforma que tem informações fornecidas pela comunidade da Internet (Saiba Mais). Em particular, foram usados os conjuntos de dados a seguir: <a target='_blank' rel="noreferrer" href="https://www.peeringdb.com/">(Saiba Mais)</a>. Specifically, the following datasets were used:
                    <ul>
                        <li>IX, com informações sobre os IXP.</li>
                        <li>IXPFX, ou “IX prefix”, com informações sobre que prefixos são usados em cada IXP.</li>
                        <li>As duas fontes de dados anteriores permitem obter os IXP pelos quais circularam os traceroutes das medições.</li>
                    </ul>
                </li>
                <li><strong >AS Names</strong>: possui o conjunto de dados asns.txt que lista os nomes coloquiais pelos quais os ASN são conhecidos e os países onde estão sendo usados. Este conjunto de dados serve para usar esses rótulos em diferentes visualizações e tabelas <a target='_blank' rel="noreferrer" href="https://bgp.potaroo.net/cidr/autnums.html">(Saiba Mais)</a>.</li>
                <li><strong>AS Population</strong>: estimativa da população por trás dos ASN, fornecida pelo APNIC. Esses dados são úteis para estimar o impacto que a operação de um ASN tem sobre os usuários finais. Esses dados são usados apenas na seção de <a href="#traceroutes">Traceroutes que são roteados por outro país</a><a target='_blank' rel="noreferrer" href="https://stats.labs.apnic.net/aspop">(Saiba Mais)</a>.</li>
                <li><strong>RIPE IPmap</strong>: é uma plataforma onde informações de geolocalização de endereços IP são inferidas mediante medições ativas <a target='_blank' rel="noreferrer" href="https://ipmap.ripe.net/"> (Saiba Mais)</a>.</li>
                <li><strong>RIPE RIS</strong>: é um observatório de rotas da Internet onde, além de outro grande número de informações, é possível ver o ASN que origina um prefixo na Internet <a target='_blank' rel="noreferrer" href="https://ris.ripe.net/">(Saiba Mais)</a>.</li>
                <li><strong>Speedchecker</strong>: plataforma de medições ativa que permite lançar pings e traceroutes desde sondas localizadas na região <a target='_blank' rel="noreferrer" href="https://www.speedchecker.com/">(Saiba Mais)</a>.</li>
            </ul>


            <h4 id="plataforma">Plataformas de medições</h4>
            <p>Em estudos anteriores, foram analisadas as plataformas de medição disponíveis que poderiam ser usadas para este tipo de estudos (ver seção Introdução em <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">Conectividade na região da ALC: 2020</a>), chegando à conclusão de que o RIPE Atlas e o Speedchecker são as mais adequadas.<br />
                RIPE Atlas e Speedchecker são plataformas complementares, uma vez que cobrem diferentes tipos de rede por meio de mecanismos comparáveis (medem através dos mesmos protocolos). Embora o Speedchecker cubra mais redes do que o RIPE Atlas, ele não o substitui; espera-se que, se a cobertura do RIPE Atlas melhorar, o faça para redes (ou segmentos dessas redes) que ainda não estejam cobertas pela outra plataforma. Se considerarmos as redes cobertas por cada plataforma, estas podem ser agrupadas da seguinte forma:</p>

            <figure><figcaption>ASNs de la región LAC cubiertos por la campaña de mediciones</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico01_s_i.png" className="imguno" alt="grafico01_s_i" /></figure>
            <figure><figcaption>ASNs de la región LAC cubiertos (y no cubiertos) por la campaña de mediciones</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico01_s_d.png" className="imguno" alt="grafico01_s_d" /></figure>

            <p>Pelo mencionado acima em termos de cobertura e consistência com estudos de anos anteriores, a campanha de medições 2022 foi implementada através do Speedchecker, sem remover a opção de veicular uma campanha semelhante com o RIPE Atlas.</p>

            <h3 id="consideraciones">Considerações sobre os dados</h3>
            <p>Algumas considerações tiveram que ser feitas ao analisar os dados:</p>
            <ul>
                <li>Embora o objetivo do relatório seja cobrir os países da região da ALC, nem todos tiveram resultados suficientes ao final da campanha de medições.</li>
                <ul>
                    <li>Os seguintes países não tinham sondas no momento das medições:  Antilhas Neerlandesas (AN), Aruba (AW), Belize (BZ), Cuba (CU), Ilhas Malvinas (FK), Ilhas Geórgia do Sul e Sandwich do Sul (GS), Suriname (SR).</li>
                    <li>Os seguintes países não reportaram resultados suficientes (menos de 10) e são omitidos em algumas seções do documento: Guiana Francesa (GF, 9 resultados), Guiana (GY, 5).</li>
                </ul>
                <li>A partir das medições constatou-se que algumas redes operam no nível global. Neste estudo, essas redes não possuem um país de registo específico, mas quando lhes foi atribuída uma localização geográfica, receberam o código <strong>WW</strong> (worldwide, ou global). As redes são as seguintes:
                    <ul >
                        <li>AS16625 AKAMAI-AS</li>
                        <li>AS1299 TWELVE99 Telia Company AB</li>
                        <li>AS3356 LEVEL3</li>
                        <li>AS13335 CLOUDFLARENET</li>
                        <li>AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.</li>
                        <li>AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL</li>
                        <li>AS3549 LVLT-3549</li>
                        <li>AS7195 EDGEUNO SAS</li>
                        <li>AS18747 IFX18747</li>
                        <li>AS23520 COLUMBUS-NETWORKS</li>
                        <li>AS3257 GTT-BACKBONE GTT Communications Inc.</li>
                        <li>AS174 COGENT-174</li>
                        <li>AS1239 SPRINTLINK</li>
                    </ul>
                </li>
            </ul>



            <h2 id="5500redes_select">Cobertura da campanha de medições</h2>

            <div className="row">
                <div className="columna_left"><p id="p_padding">A campanha de medições abrangeu um total de 5588 redes. Essas redes podem ser aquelas que hospedam uma sonda (origem das medições), hospedam um endereço IP (destino das medições), ou aquelas por onde passa a medição (redes intermediárias, não são origem ou destino das medições). Uma observação disso é que existem 511 redes que não possuem uma sonda de medição ou endereço IP de destino, ou seja, são redes “intermediárias” que foram levantadas pela campanha de medições. Confira uma distribuição das redes no gráfico a seguir.</p></div>
                <div className="columna_right"><figure className="figure_c"><figcaption>ASNs vistos por la campaña de mediciones</figcaption><img style={styles.images} className="columna-imgc" src="/images/estudio-de-conectividad-2022/grafico02.png" alt="grafico02" /></figure></div>
            </div>




            <h2 id="impactolatencia_select">Latências</h2>
            <h3 id="compara">Comparação 2020 / 2022 </h3>

            <div className="row">
                <div className="columna"><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico03.png" alt="grafico03" /></div>
                <div className="columna"><p>Em 2020 foi feito um <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-conectividad-lac-es.pdf">estudo de latência da região da ALC</a> , em que foi medida a latência interna de vários países da região. Uma vez que a plataforma e a metodologia dos dois estudos são semelhantes, é possível fazer uma comparação entre os dois resultados. À esquerda é apresentada uma comparação dos resultados das medições de latência obtidas em cada ano. Como se comparam os resultados obtidos em 2022 com os obtidos em 2020?</p>
                    <p>Uma primeira observação é que muitos dos países apresentam valores de latência mais baixos em 2022 do que em 2020, particularmente aqueles países com latências mais elevadas. Em média, as melhorias são de 44.4ms.</p>
                    <p>Merece destaque o caso do Panamá, que a partir do estudo de 2020 iniciou <a target='_blank' rel="noreferrer" href="https://blog.lacnic.net/programa-frida/proyecto-de-sondas-en-panama-para-medir-la-latencia">um projeto de implementação de sondas de medição</a> para melhorar a observabilidade da conectividade regional. Se compararmos as latências dos dois anos, nota-se uma melhoria de ~34ms.</p>
                </div>
            </div>


            <div className="row">
                <div className="columna"><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico04.png" alt="grafico04" /></div>
                <div className="columna"><p>Como esta campanha de medições é baseada em traceroutes, é possível comparar os hops que permanecem dentro de um país com aqueles que saem do país (para depois entrar novamente). Sem grandes surpresas, as medições se alinham com as expectativas: os valores de latência dos hops que saem do país excedem por ampla margem os que permanecem dentro do país.</p>
                    <p>No entanto, os hops que saem do país são um indicador de roteamento abaixo do ideal, uma vez que estes pacotes têm como destino um endereço IP do mesmo país.</p>
                    <p>Na seção de <a href="#caminoslatencias">latências e caminhos</a> veremos com mais detalhes a diferença de RTT entre hops externos e internos, e como isso está relacionado com a percentagem de hops que saem de um país.</p>
                </div>
            </div>



            <h2 id="perspectivacaminos_select">Caminhos</h2>
            <h3 id="traceroutes">Traceroutes que são roteados por outro país</h3>

            <div className="row">
                <div className="columna"><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico05.png" alt="grafico05" /></div>
                <div className="columna"><p className="p_padding">Se compararmos os caminhos que foram mantidos dentro do país com os que saíram do país, surgem algumas observações.</p>
                    <p className="p_padding">Em primeiro lugar, pode ser comparada a porcentagem de hops e traceroutes que saem de cada país. Considera-se um hop de saída para um país se o endereço IP do hop não estiver registrado no país de origem, e um traceroute é considerado de saída se pelo menos um hop desse traceroute for de saída.</p>
                    <p className="p_padding">Além de contemplar o país onde um endereço IP (hop) está registrado, a latência também é levada em consideração. Este critério é detalhado em profundidade na seção <a href="#geolocalizacion">Correções de geolocalização</a> , que explica que o motivo deste é minimizar o número de recursos que estão registrados fora de um país, mas operam dentro dele (baixa latência). Por exemplo, um endereço IP registrado nos Estados Unidos, mas a 20ms (ou menos) de uma sonda localizada na Argentina, será considerado como localizado na Argentina.</p>
                    <p className="p_padding">No gráfico vemos aqueles países grandes com presença de IXP, como a Argentina, Brasil ou Chile, que apresentam um baixo percentual de traceroutes de saída, com 2.7, 5.6 e 0.4% respectivamente.</p>
                    <p className="p_padding">Também é possível observar países com presença de operadores grandes com percentuais baixos, como o México e Uruguai com 6.1 e 3.1% respectivamente.</p>
                    <p className="p_padding">O ranking dos países com maior percentual de traceroutes roteados por fora do país foram a Guiana, Honduras, Panamá, Venezuela, El Salvador, Guatemala e República Dominicana com valores acima de 10%. Quando se analisa a porcentagem dos ASN que decidem rotear traceroutes por fora, nota-se o seguinte: na Guiana 100% dos ASN vistos pelo experimento decidem fazê-lo, em Honduras 66%, no Panamá 50%, na Venezuela 41%, e em El Salvador 25%. Estas “passagens de fronteira” estão detalhadas na tabela <a href="#fugatrafico">ASN que roteiam traceroutes por outro país</a>, em que são mostrados aqueles ASN que decidem rotear traceroutes por outro país (aqueles que roteiam mais de 5% de todos os traceroutes lançados desde esse país).</p>
                    <p className="p_padding">Outro recurso neste documento é a tabela dos <a href="#fugatrafico" target='_blank' rel="noreferrer">ASN mais visualizados em traceroutes, por país</a>. Esta detalha todos os ASN mais vistos nos traceroutes lançados desde cada país, não apenas aqueles que atravessam uma fronteira.</p>
                    <p className="p_padding">Observe que um dos objetivos deste estudo é descrever da melhor forma possível os resultados e observações feitas durante o experimento. <strong>Convidamos os leitores, que possuem maior conhecimento do ambiente local, a tomarem este estudo como gatilho e fazerem uma melhor interpretação dos dados.</strong></p>
                </div>
            </div>




            <div className="row">
                <div className="columna">
                    <p className="p_padding">Lembremos que neste documento são analisadas as medições que têm origem e destino no mesmo país. É interessante conhecer o <strong>número de ASN</strong> que, por terem que enviar um pacote dentro do país, acabam roteando por outro país e depois retornam ao país de origem. Esta é a porcentagem de ASN de cada país que roteiam pacotes através de um ASN fora do país, considerando como 100% todos os ASN desse país que foram vistos no experimento. Abaixo segue o ranking para esta métrica.</p>
                    <figure className="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                        Cantidad de ASNs
                    </figcaption><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico06.png" alt="grafico06" />
                    </figure></div>
                <div className="columna"><p className="p_padding">Ao ter a estimativa de usuários por ASN, também pode ser estimada a <strong>população de um país</strong> que se enquadra nos casos mencionados acima. Estes estão representados graficamente abaixo.</p>
                    <div><figure className="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                        Porcentaje de la población
                    </figcaption><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico07.png" alt="grafico07" /></figure></div>
                    <p className="p_padding">Destacam-se os casos do Chile, Trinidad e Tobago e Panamá: embora tenham ASN que roteiam fora do seu país, por atenderem poucos ou nenhum usuário, a percentagem da população acaba sendo zero.</p>
                    <p className="p_padding">Sem muita notoriedade, seguem no ranking alguns casos com população em torno de 3%.</p>
                </div>
            </div>
            <p>Em geral, os países com uma percentagem maior (superior a 3% da população do país) têm um grande componente em um ou no máximo dois ASN. Esses componentes podem ser atribuídos a:
            </p><ul>
                <li>A Colômbia com maior participação de UFINET PANAMÁ e TV AZTECA SUCURSAL COLÔMBIA (3 e 2% respectivamente)</li>
                <li>Uruguai com Telefônica Móviles do Uruguai</li>
                <li>Venezuela com TELEFóNICA VENEZOLANA</li>
                <li>Guayana com E-Networks</li>
                <li>Paraguay com Núcleo</li>
                <li>México wicomth Uninet</li>
                <li>Costa Rica com o Instituto Costarriquense de Eletricidade e Telecom.</li>
                <li>Peru com Telefônica do Peru</li>
                <li>Honduras com Telefônica Celular e CABLECOLOR (27 e 14%)</li>
                <li>Guatemala com Telgu e, COMCEL GUATEMALA (39 e 18%)</li>
                <li>República Dominicana com Companhia Dominicana de Telefones e ALTICE DOMINICANA (64 e 23%) </li>
            </ul><p></p>


            <h3 id="fugatrafico">Tabelas</h3>

            <p>Dado que as medições têm origem e destino no mesmo país, é interessante conhecer os países por onde as medições saem do país. Foram elaboradas duas tabelas com informações sobre os destinos por onde saem os traceroutes. A primeira tabela mostra os países por onde as medições saíram, e a segunda os ASN por onde o fizeram. Pode-se pensar na segunda tabela como uma ampliação da primeira. Para ambos os casos, são mostradas as linhas (países ou ASN) que representam mais de 5% do total dos traceroutes que saem desse país. Calcula-se a percentagem com base em:</p>
            <ul>
                <ol>1.	Um traceroute de saída é aquele que tem pelo menos um salto que passa primeiro por um IP geolocalizado em seu próprio país, e no salto seguinte passa por um endereço IP geolocalizado em outro país. Por mais informações, consulte a seção <a href="https://www.reddisegno.com.ar/lacnic/2023/geolocalizacion" target='_blank' rel="noreferrer">Geolocalização</a>.</ol>
                <ol>2.	Para calcular a percentagem, todos os traceroutes do ponto 1 são contados e comparados com todos os traceroutes lançados desde esse país.</ol>
            </ul>

            <p>Existe uma consideração ao geolocalizar redes que operam em muitos países. Esta consideração as geolocaliza com o código <strong>WW</strong> saiba mais acessando a seção <a href="#consideraciones" target='_blank' rel="noreferrer">Considerações sobre os dados.</a></p>
            <p>A primeira tabela é mostrada abaixo (nível país), e no <a href="#anexo1" target='_blank' rel="noreferrer">Anexo 1: Destinos de traceroutes de saída (ASN)</a> aparece a tabela com mais detalhes (nível ASN).</p>
            <p>Referência:<br />
                PAÍS: país onde as medições se originaram. Também é o país destino das medições.<br />
                PAIS_INTERMEDIÁRIO: país por onde as medições saíram.
            </p>

            <figure className="tabla_small">

                <table className="table">
                    <thead>
                        <tr><th>PAÍS</th><th>PAIS_INTERMEDIÁRIO</th><th>PERCENTAGEM</th></tr></thead>
                    <tbody><tr><td>AR</td><td>WW</td><td>94%</td></tr>
                        <tr><td>BR</td><td>WW<br />US</td><td>82%<br />9%</td></tr>
                        <tr><td>CL</td><td>WW</td><td>100%</td></tr>
                        <tr><td>CO</td><td>WW</td><td>100%</td></tr>
                        <tr><td>CR</td><td>WW<br />GT</td><td>88%<br />12%</td></tr>
                        <tr><td>DO</td><td>WW</td><td>100%</td></tr>
                        <tr><td>EC</td><td>WW</td><td>100%</td></tr>
                        <tr><td>SV</td><td>WW</td><td>100%</td></tr>
                        <tr><td>GT</td><td>WW<br />US</td><td>87%<br />13%</td></tr>
                        <tr><td>GY</td><td>WW</td><td>100%</td></tr>
                        <tr><td>HN</td><td>WW</td><td>100%</td></tr>
                        <tr><td>MX</td><td>WW<br />US</td><td>55%<br />44%</td></tr>
                        <tr><td>PA</td><td>WW</td><td>100%</td></tr>
                        <tr><td>PY</td><td>BR<br />WW</td><td>50%<br />50%</td></tr>
                        <tr><td>PE</td><td>WW</td><td>100%</td></tr>
                        <tr><td>TT</td><td>WW<br />GD<br />JM</td><td>67%<br />25%<br />8%</td></tr>
                        <tr><td>UY</td><td>WW<br />BR</td><td>75%<br />25%</td></tr>
                        <tr><td>VE</td><td>WW<br />CO<br />US</td><td>69%<br />15%<br />15%</td></tr>
                    </tbody>
                </table>
            </figure>

            <h3 id="redcentral">ASN mais vistos em traceroutes, por país</h3>
            <p>A partir das medições é possível determinar quais redes são mais visualizadas pelos traceroutes dentro de um país, tanto se são redes desse país quanto se são outras redes pelas quais os traceroutes passaram. Essa métrica pode dar uma ideia da importância de cada rede nesse país. Para isso, todos os traceroutes que envolvem cada rede são contados e divididos pelo número de traceroutes lançados desde esse país, como percentagem. Se for elaborado um ranking levando em consideração esse percentual, filtrando apenas as redes que representam mais de 5%, obtém-se o seguinte (observe que os percentuais não devem somar 100%, pois o mesmo traceroute envolve várias redes):</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/asn_1.png" className="imguno" alt="asn_1" /></p>


            <h2 id="caminoslatencias_select">Latências e caminhos</h2>

            <p>Dois pontos importantes emergem das medições:</p>
            <ul>
                <li>Há países que, ao enviarem pacotes com destino o mesmo país, roteiam uma percentagem deles através de outro país.</li>
                <li>Eles geralmente fazem isso com uma latência maior do que fariam internamente.</li>
            </ul>
            <p>Se forem representados graficamente estes dois pontos, isto é, a diferença de RTT entre os pacotes que saem menos os que permanecem dentro do país (eixo x), e a percentagem de pacotes que sai (eixo y), obtemos o seguinte:</p>

            <p></p><figure><figcaption>Latencia en países de LAC<br />
                Hops que salen vs. hops que permanecen dentro de cada país
            </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico08.png" className="columna-grafos" alt="Estudo de Conectividade 2022_files" /></figure><p></p>

            <ul>
                <li>Eixo x: a diferença de RTT entre os hops que saem do país e os que permanecem nele. Valores altos indicam penalidade ao sair do país. Valores baixos indicam que as latências são semelhantes.</li>
                <li>Eixo y: Percentagem de hops que saem do país. Normalmente, este deveria ser um valor baixo. Vale destacar os casos da Bolívia, Guiana Francesa e Nicarágua, que não apresentaram hops por fora do país.</li>
            </ul>

            <p>Dos pontos anteriores podemos agrupar os países nas seguintes categorias</p>
            <ul>
                <li>Quadrante superior direito: aqueles que têm penalidade de RTT ao sair do país e também uma grande percentagem. Este quadrante está vazio, seria necessário rever a conectividade a nível do país.</li>
                <li>Quadrante inferior direito: é paga uma penalidade ao sair, mas isso é feito raramente. São rotas que ao sair têm uma <strong>alta latência</strong>.</li>
                <li>Quadrante superior esquerdo: esses países pagam uma penalidade baixa em termos de latência, mas possuem muitas <strong>rotas de saída</strong>.</li>
                <li>Quadrante inferior esquerdo: raramente é paga uma penalidade baixa; esse tráfego poderia ser considerado um <strong>roteamento ideal</strong>.</li>

            </ul>

            <p>Observações semelhantes podem ser feitas se, em vez de representar graficamente o número de pacotes de saída, olharmos para o número de traceroutes de saída. A distribuição dos países é semelhante, com a grande diferença de que a percentagem é maior (basta apenas 1 hop de saída para que o traceroute seja considerado de saída, daí a tendência para ser uma percentagem maior):</p>

            <p></p><figure><figcaption>Latencia en países de LAC<br />
                Traceroutes que salen vs. traceroutes que permanecen dentro de cada país
            </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico09.png" className="columna-grafos" alt="grafico09" /></figure><p></p>


            <h2 id="grafos_select">Grafos</h2>
            <p>É útil carregar os resultados das medições em uma estrutura de grafo, onde métodos conhecidos podem ser aplicados sobre este. Por exemplo, podem ser procurados loops ou circuitos nesse grafo. Com base nos resultados de traceroutes, foi construído um grafo para cada país onde:</p>
            <ul>
                <li>Cada nó do grafo é uma rede, pode ser um ASN ou um IXP</li>
                <li>Cada aresta do grafo representa o salto em um traceroute visto entre ASN consecutivos.</li>
            </ul>

            <p>A seguir vemos um exemplo de traceroute que faz parte da campanha de medições. É importante ressaltar que cada linha corresponde a um hop do traceroute, quer dizer, as colunas asn_origin e asn_destination são a origem e o destino desse hop.</p>
            <p>O ASN de origem do traceroute é o campo asn_origin do primeiro salto e o ASN de destino final do traceroute é o asn_destination do último salto (última linha). No caso a seguir, o traceroute é lançado desde o AS 6057 e tem como destino o AS <strong>19422</strong>.</p>


            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/traceroute1.png" className="imguno" alt="traceroute1" /></p>


            <p>Exemplo de traceroute da campanha de medições,
                com o ASN de origem e destino marcados em negrito.
            </p>

            <p>A partir do traceroute anterior podem ser extraídas as seguintes informações:</p>
            <ul>
                <li>Origina-se da sonda com endereço 167.57.114.53, anunciada pelo ASN 6057.</li>
                <li>Tem destino o endereço 200.58.155.34, anunciada pelo ASN 19422.</li>
                <li>O traceroute passa pelos ASN 6057 ➔ 12956 ➔ 19422.</li>
                <li>Tem 11 saltos e um RTT que varia.</li>
            </ul>

            <p>O traceroute anterior contém mais informações do que o necessário para montar o grafo de latências. Como estamos procurando os saltos entre ASN, podemos descartar os saltos que não conectam dois ASN. Além disso, vamos manter o RTT diferença entre os ASN, quer dizer, RTTn+1 - RTTn. Isso nos permite reduzir o traceroute para um com menos informações, onde <strong>pares de hops</strong> são mantidos (linhas 1-2, 3-4, etc.):</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/traceroute2.png" className="imguno" alt='traceroute2' /></p>


            <p>Exemplo de traceroute reduzido aos dados necessários,
                que serão incluídos no grafo do país.
            </p>

            <p>A partir dessas informações, é construído um grafo com as seguintes características:</p>
            <ul>
                <li>3 nós 6057, 12956, e 19422</li>
                <li>2 arestas: 6057 ➔ 12956 e 12956 ➔ 19422</li>
                <li>Às arestas é adicionado um valor de RTT, que será usado pela biblioteca de grafos para calcular a posição dos nós ao pintá-los: nós com RTT menor ficarão localizados mais próximos uns dos outros. Este é o RTT diferença entre os hops hopn e hopn+1.
                    <ul>
                        <li>Entre 6057 ➔ 12956, o RTT diferença é 14.2ms - 9.3ms = 4.9ms</li>
                        <li>Entre 12956 ➔ 19422 é 15.1 - 14.2 = 0.9ms</li>
                    </ul>

                </li>
            </ul>

            <p>Além disso, pode ser criada uma escala de cores em que cada cor representa o país onde o nó está localizado (ASN). Os nós são marcados com um círculo pintado e os IXP com um círculo vazio. A relação ASN ➔ país foi determinada por:</p>
            <ul>
                <ol>1. Tomando o endereço IP de cada salto do traceroute, foi procurado no
                    <ul>
                        <ol>a. Whois ou país de registro desse endereço</ol>
                        <ol>b. No RIPE RIS ou ASN que anuncia esse endereço para a Internet.</ol>
                    </ul>
                </ol>
                <ol>2. Ao dispor de um endereço IP e um ASN dos pontos 1.a e 1.b, conclui-se que o ASN está designado nesse país.
                    <ul><ol>a. Se houver vários endereços IP anunciados pelo mesmo ASN, e todos eles estiverem registrados no mesmo país, então o ASN continua estando no único país obtido de 1.a. No entanto, se o ASN anunciar endereços registados em países diferentes, por exemplo registados nos países XX e YY, então o ASN é marcado como designado em XX|YY.</ol></ul>
                </ol>
                <ol>3. Além disso, caso não seja encontrado um país para um ASN seguindo o método anterior, é usado um banco de dados em potaroo.net que contém o país onde se estima que o ASN opera. Aos ASN que localizam desta forma, por exemplo, ao país XX são designados como XX*.</ol>
            </ul>



            <p></p><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico10.png" className="columna-grafos" alt="grafico10" /></figure><p></p>

            <p>Esta análise foi aplicada a todos os países da região e os resultados podem ser vistos no <a href="#anexo3grafos" target='_blank' rel="noreferrer">Annex 3: Graphs for the Countries in LAC.</a></p>

            <h2 id="ixp_select">IXP</h2>
            <h3 id="presencia">Presença</h3>

            <p>Uma observação que emerge dos traceroutes é a percentagem de traceroutes lançados em um país que passam por IXP do mesmo país. Para determinar isso, comparamos os endereços IP vistos nos traceroutes com os endereços IP registrados no PeeringDB designados a IXP. É importante salientar que se os IXP de um país não registraram suas redes no PeeringDB não serão levados em conta e não serão contabilizados como IXP. Se contarmos o número de traceroutes que passam por um IXP nesse país, obtemos o seguinte:</p>
            <p><b>IXP vistos por traceroutes da campanha de medições</b></p>
            <p>AR AR-IX Cabase (Argentina)<br />
                BO PIT Bolivia (La Paz)<br />
                BR IX.br (PTT.br)<br />
                CL PIT Santiago - PIT Chile (Santiago)<br />
                CO NAP Colombia (Bogota)<br />
                CR CRIX (San Jose)<br />
                EC NAP.EC - UIO (Quito/UIO)<br />
                HN IXP-HN (Tegucigalpa)<br />
                PA InteRed Panama (Panama)<br />
                PE Peru IX (PIT Peru sac) - Lima (Lima)<br />
                PY IXpy (San Lorenzo)<br />
                TT TTIX (Barataria)
            </p>

            <p></p><figure className="figure_c figure_left"><figcaption>Presencia de IXPs<br />
                Traceroutes que pasan por el IXP
            </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico11.png" className="columna-img" alt="grafico11" /></figure><p></p>

            <p>No gráfico pode se ver que o caso típico roteia entre 40 e 50% do tráfego medido. Dois pontos a levar em conta são os casos do Brasil e do Chile, onde a expectativa era obter muito mais amostras passando pelo IXP. Esses casos não dizem tanto da presença do IXP, mas sim do viés da plataforma de medições.</p>

            <h3 id="latencia">Latências</h3>

            <div className="row">
                <div className="columna"><figure className="figure_c figure_left"><figcaption>Comparación de latencia<br />
                    Traceroutes internos a cada país
                </figcaption><img style={styles.images} className="columna-img" src="/images/estudio-de-conectividad-2022/grafico12.png" alt="grafico12" /></figure></div>
                <div className="columna"><p>Também é útil analisar a latência dos traceroutes que passam por IXP e compará-la com a latência daqueles que não passam por IXP. Neste caso, foi tomado o RTT do último salto do traceroute. Se esses valores forem representados graficamente, obtemos o gráfico da esquerda.</p>
                    <p>À primeira vista percebe-se que, em geral, as latências dos traceroutes que passam por IXP são menores. O caso excepcional em que as latências mais altas são as de traceroutes que passam por IXP é o do Chile, onde as latências diferem muito pouco (17.0 vs. 15.6 para aqueles traceroutes que passam e não passam por IXP respectivamente).</p>
                    <p>Dois casos particulares são os do Panamá e Trinidad e Tobago, onde as latências de traceroutes que passam por IXP são mais baixas e alinhadas com o resto da região (barras pretas), no entanto, as latências que não passam por IXP são visivelmente altas (barra cinza). </p>
                </div>
            </div>


            <h3 id="preslat">Presença e latências</h3>

            <p>Nesta seção será feita uma análise semelhante à análise que foi feita na seção<a href="https://www.reddisegno.com.ar/lacnic/2023/caminoslatencias" target='_blank' rel="noreferrer">Latências e caminhos</a>, em que duas variáveis são tomadas e representadas graficamente em um par de eixos cartesianos. Isso permite uma análise rápida da região e a divisão dos casos em quatro quadrantes.</p>
            <p>Ao considerar a <strong>diferença de RTT</strong> entre os traceroutes que passam por um IXP e os que não passam, o impacto de um IXP no ecossistema local pode ser quantificado. Além disso, se a <strong>porcentagem de traceroutes que não passam pelo IXP</strong> for comparada ao total de traceroutes lançados, a frequência desse comportamento pode ser quantificada. Essas duas métricas dão uma ideia de:</p>
            <ul>
                <li>Se é paga uma penalidade alta (diferença de RTT)</li>
                <li>Com que frequência é paga (percentagem de traceroutes)</li>
            </ul>
            <p>Se essas duas métricas forem representadas graficamente, a diferença RTT no eixo x e a porcentagem de traceroutes que não passam por um IXP no eixo y, os diferentes casos podem ser detectados rapidamente:</p>
            <ul>
                <li>Quadrante superior direito, felizmente sem países. Esses seriam os casos em que pouco tráfego passa pelo IXP local, e o que não passa paga uma penalidade de latência muito alta.</li>
                <li>Quadrante inferior direito, por exemplo o caso do Panamá (PA). Há muito tráfego que passa pelo IXP e quem decide sair paga uma penalidade de latência.</li>
                <li>Quadrante superior esquerdo, por exemplo Peru (PE). Há pouco tráfego que passa pelo IXP, porém a penalidade por não passar é muito baixa.</li>
                <li>Quadrante inferior esquerdo, por exemplo a Bolívia (BO). Grande parte do tráfego passa pelo IXP, porém a penalidade por não passar não é alta.</li>
            </ul>

            <p></p><figure><figcaption>Latencia  en países de LAC<br />
                Traceroutes que pasan por un IXP vs. Los que no lo hacen
            </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico13.png" className="imagen_wide" alt="grafico13" /></figure><p></p>


            <h3 id="aspath">Número de saltos e redes</h3>

            <p>Duas métricas podem ser determinadas a partir dos traceroutes: o número de saltos dentro dos traceroutes e o número de redes pelas quais o traceroute passa. As redes podem ser de dois tipos: ASN ou IXP. Para determinar que tipo de rede é, duas fontes de dados são levadas em conta: a pesquisa reversa de IP para ASN segundo RIPE RIS, e as informações de IXP em PeeringDB.</p>
            <p>Aplicando o mesmo conceito ao número de saltos de cada traceroute, obtêm-se os dados do gráfico à esquerda. A partir dessas informações pode se ver que os traceroutes que passam pelos IXP são geralmente mais curtos, mas com algumas ressalvas. Os casos onde há mais saltos em traceroutes que usam o IXP são o Chile, Colômbia, Costa Rica, Equador e Trinidad e Tobago. Conforme analisado na seção anterior, estes 5, exceto o Chile, apresentam melhores valores de latência ao usar o IXP, portanto o número de hops acaba sendo uma métrica pouco útil para medir o impacto.</p>

            <div className="row">
                <div className="columna" ><figure><figcaption>Número de hops <br />
                    Traceroutes internos a cada país
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico14.png" className="imagen" alt="grafico14" /></figure></div>
                <div className="columna" ><figure><figcaption>Redes<br />
                    Traceroutes internos a cada país
                </figcaption>
                    <img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico15.png" className="imagen" alt="grafico14" /></figure></div>
            </div>

            <p>Da mesma forma, o número de redes (sejam ASN ou IXP) pelas quais passam os traceroutes pode ser representado graficamente.</p>
            <p>A expectativa inicial é que nos países que possuem um IXP, seu impacto na conectividade seja percebido. Um ponto a ser levado em conta é que esta métrica inclui o IXP pelo qual passa o traceroute. Se quisermos contar apenas os ASN, devemos subtrair 1 à métrica. Por exemplo, Costa Rica (CR) tem uma métrica de 2.4 redes (média), e ao subtrair 1 ficaria em 1.4 ASN.</p>
            <p>Em primeira instância, foram observados casos em que o número de redes em traceroutes que envolvem o IXP local é maior do que quando não é envolvido. O primeiro caso é o de Honduras, onde se reporta 1.0 no número de redes que passam pelo IXP-HN (Tegucigalpa). Isso ocorre porque todos os outros endereços IP dos traceroutes que passam pelo IXP pertencem ao espaço privado e um ASN não pode ser derivado deles. Por esse motivo, neste caso a única rede que tem é a do IXP. Esse mesmo problema poderia estar acontecendo em outros casos e distorcendo os dados.</p>
            <p>Esta inconsistência levou a questionar se a qualidade dos dados poderia ser melhorada. Poderíamos aplicar alguns filtros para garantir que os dados com os quais trabalhamos sejam mais confiáveis na contagem de redes? Uma regra simples que foi aplicada foi:</p>
            <ul>
                <li>Para os traceroutes que envolvem o IXP, pelo menos devem ser observadas 3 redes, incluindo a rede do IXP: ASN1 --&gt; IXP --&gt; ASN2.</li>
                <li>Para os traceroutes que não envolvem o IXP, pelo menos 2: ASN1 --&gt; ASN2.</li>
            </ul>
            <p>Uma vez aplicada esta restrição, obtêm-se dados mais alinhados com as expectativas (número de redes semelhante entre os dois casos). O caso de Honduras mencionado acima fica automaticamente descartado.</p>
            <p>Neste caso também foi decidido adicionar mais uma métrica: número de redes em um traceroute que passa por um IXP, sem contar o IXP. Quer dizer, esta métrica conta apenas os ASN envolvidos no traceroute e é representada graficamente em branco:</p>


            <p></p><figure><figcaption>Redes<br />
                Traceroutes internos a cada país
            </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico16.png" className="imagen" alt="grafico16" /></figure><p></p>

            <p>Uma vez filtrados, os dados mostram que os traceroutes envolvem um número geralmente menor de ASN, uma vez que envolvem o IXP local.</p>
            <p>Alguns casos a levar em conta:</p>
            <ul>
                <li>No caso do Haiti, foi obtida uma única amostra que passou por HIX Haiti (Porto Príncipe), por isso foi descartada e retirada do gráfico.</li>
                <li>No Peru, apenas 5 traceroutes passaram pelo Peru IX (PIT Peru sac) - Lima (Lima), originados desde 2 redes (de um total de 8 vistas pela campanha de medições).</li>
            </ul>

            <h3 id="ejemplo">Um exemplo: Argentina</h3>

            <p>Nas seções anteriores foi visto que é comum que a latência e o número de saltos sejam melhores nos traceroutes que passam pelos IXP. Se tomarmos o exemplo da Argentina, país com forte presença do IXP e também com um número considerável de medições, e observando os detalhes do conjunto de dados, pode se observar o seguinte: </p>
            <p>Em termos de latência, a média do RTT dos traceroutes mais lentos (percentil 99) é de 175ms para aqueles que passam por IXP e 215ms para aqueles que não passam. Embora estejamos falando dos casos mais lentos, uma diferença de 40ms não é coisa trivial. O traceroute típico (percentil 50) tem uma média de 21.3ms se passar por um IXP e 24.4 se não passar. É uma diferença de 3ms. Por baixo de 12ms não há diferença significativa. Este ponto ocorre em torno do percentil 20, isto é, que 20% dos traceroutes mais rápidos apresentam valores de latência semelhantes quando comparamos aqueles que passam por um IXP com aqueles que não o fazem.</p>
            <p>Em termos de saltos, os traceroutes requerem, na pior das hipóteses (percentil 99), de 19 saltos para chegar ao destino se passarem por um IXP. Caso não passem por um IXP, requerem 21 saltos. O caso típico (percentil 50) é de 9 saltos se passarem por um IXP e 11 se não passarem. Para os traceroutes mais curtos (percentil 7, menos de 6 hops) não há diferenças.</p>
            <p>Em termos do número de redes pelas quais passam os traceroutes, observam-se valores muito semelhantes. Duas observações a margem:</p>
            <ul>
                <li>Os traceroutes que passam pelo IXP apresentam 3 ou mais redes, já que são contadas a rede de origem, o IXP e a rede de destino.</li>
                <li>Os que não passam pelo IXP, apresentam 2 ou mais: é o caso de uma conexão direta.</li>
            </ul>


            <div className="row">
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17a.png" className="imgdos" alt="grafico17a" /></div>
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17b.png" className="imgdos" alt="grafico17b" /></div>
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17c.png" className="imgdos" alt="grafico17c" /></div>
            </div>
            <p>A conclusão mais clara é que as medições que passam pelo IXP na Argentina apresentam melhores métricas de latência e número mensurável de saltos. Tomando um traceroute típico desta campanha de medições, a melhoria é de ~18% em termos de saltos e ~12% em termos de latência. Para traceroutes mais curtos ou de menor latência (menos de 7 saltos ou menos de 20ms) a melhoria é quase zero.</p>

            <h2 id="geolocalizacion_select">Geolocalização</h2>
            <h3 id="fuentedato">Fontes de dados</h3>
            <p>A principal fonte de geolocalização para este trabalho são os <a href="https://ftp.ripe.net/pub/stats/" target='_blank' rel="noreferrer">arquivos de registro</a> (conhecidos como delegated-extended, fornecido pelos diferentes RIR). Estes arquivos capturam o país onde a entidade legal por trás de um bloco IP está constituída, e isso por si só introduz erros. Por esse motivo foi necessário fazer algumas correções.</p>
            <p>Uma fonte secundária de geolocalização é o <a href="https://ipmap.ripe.net/" target='_blank' rel="noreferrer">RIPE IPmap</a>, em que a geolocalização dos endereços IP é baseada em medições ativas feitas por sondas de Atlas. A localização geográfica de endereços IP que respondem com um ping suficientemente baixo a uma sonda com localização conhecida pode ser inferida.</p>
            <p>Outra fonte secundária foi o serviço de <a href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic" target='_blank' rel="noreferrer">Geofeeds do LACNIC</a>, em que os membros de prefixos podem indicar em qual localização geográfica estão sendo usados.</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico18.png" className="imagen" alt="grafico18" /></p>


            <p>Quando as 3 fontes de geolocalização são incorporadas, isso é feito seguindo o seguinte critério, em que as fontes são listadas de maior a menor precisão:</p>
            <ul>
                <ol>1. Primeiro se tomam as informações provenientes das <a href="#consideraciones" target='_blank' rel="noreferrer">Considerações sobre os dados</a>, estes são endereços IP pertencentes a redes globais que operam em múltiplos países.</ol>
                <ol>2. Depois são tomadas as informações do Geofeeds, considerada a fonte de informações mais confiável, uma vez que é uma declaração do operador sobre onde os blocos IP são usados.</ol>
                <ol>3. Caso não haja informações no ponto anterior, são tomadas as informações do IPmap.</ol>
                <ol>4. E da mesma forma, as informações são obtidas dos arquivos de registro. </ol>
            </ul>


            <h3 id="correccion">Correções: Delta RTT</h3>

            <p>Ao analisar os dados, notou-se que havia valores de latência muito baixos entre países distantes, como medições de 5ms entre a Argentina e os Estados Unidos. Este fato levou a analisar a latência dos saltos dentro de um mesmo traceroute, conforme segue:</p>
            <ul>
                <ol>1. Cada traceroute é percorrido desde o hop #1 até o último hop, analisando cada par de hops adjacentes. (1-2, 2-3, 3-4, etc.).</ol>
                <ol>2. São descartadas comparações de pares de hops que pertencem ao mesmo país.</ol>
                <ol>3. Determina-se a diferença de RTT, ou delta RTT, entre os dois hops de cada par de hops segundo RTTn+1 - RTTn. Quer dizer, se o 1º hop responde com 3ms e o 2º com 4ms, o delta RTT é de 4ms - 3ms = 1ms.  Caso o 1º hop responda com 4ms e o 2° com 3ms, o delta RTT é de -1ms. Isso pode acontecer porque os saltos mais altos não respondem necessariamente com valores de latência mais altos devido a diversos fatores, entre eles que o momento exato da medição não é o mesmo, o nível de carga dos roteadores varia, ou outras causas.</ol>
                <ol>4. A partir dos valores do delta RTT pode-se inferir se os pontos IP nas extremidades do link estão próximos uns dos outros, em termos de latência. Na seção <a href="#determinando" target='_blank' rel="noreferrer">Determinando o limiar de latência</a> veremos como definimos proximidade no contexto deste estudo. A partir dessa métrica a seguinte modificação pode ser feita:
                    <ul>
                        <ol>a. Para os endereços IP nas extremidades do link com uma geolocalização conhecida pelos métodos anteriores, os países de registro podem ser comparados.</ol>
                        <ol>b. Se forem países diferentes e tiverem latência muito baixa, considera-se que as informações de registo não refletem as informações de latência, e o país do hopn+1 é substituído com a do país do hopn.</ol>
                    </ul>
                </ol>
            </ul>

            <p>Depois de incorporar o conceito de Delta RTT, passa a ocupar o 4º lugar na lista de fontes de geolocalização a consultar (mais confiável que as informações de registro, mas menos que o IPmap). A lista final é a seguinte (com a porcentagem de IP geolocalizados entre parênteses):</p>
            <ul>
                <ol>1. Redes globais  (26%)</ol>
                <ol>2. Geofeeds (1.7%)</ol>
                <ol>3. IPmap (10%)</ol>
                <ol>4. Delta RTT (1.9%)</ol>
                <ol>5. Registro (60%)</ol>
            </ul>

            <p>Verifica-se que existem 40% dos IP cujas informações de geolocalização não provêm dos arquivos de registro, o que representa uma melhoria considerável na qualidade dos dados</p>

            <h4 id="determinando">Determinando o limiar de latência</h4>

            <p>Até agora falamos sobre o conceito de baixa latência, mas quão baixa ela deve ser para considerar o endereço IPn+1 como estando no mesmo país que o endereço IPn? Uma referência possível é o limiar que o RIPE IPmap usa para determinar um endereço IP como estando muito próximo de uma sonda RIPE Atlas: 10ms.</p>
            <p>Antes de determinar um limiar, o que pode ser visto nos perfis de latência dos países? Vamos comparar a latência com base na localização dos endereços IP, provenientes de fontes confiáveis (Geofeeds e IPmap). Confira, a seguir, os perfis de latência de alguns países, indicando se o endereço IP pertence ao mesmo país ou não.</p>

            <p>Traceroute graph for AR</p>

            <div className="row">
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19a.png" className="imgdos" alt="grafico19a" /></div>
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19b.png" className="imgdos" alt="grafico19b" /></div>
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19c.png" className="imgdos" alt="grafico19c" /></div>
                <div className="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19d.png" className="imgdos" alt="grafico19d" /></div>
            </div>


            <p>Os perfis apresentam características muito semelhantes: uma componente de latência principal correspondente às medições que permanecem dentro do país (laranja), e o restante das medições com uma ou mais componentes. Em quase todos os países, a componente principal costuma estar claramente delimitada e estar abaixo de 50ms. Isto mostra uma primeira aproximação a um limiar de latência que pode discriminar se um RTT está no país ou não.</p>
            <p>Quando as medidas de toda a região são analisadas como um todo, nota-se que a componente principal está centrada em 17.4ms (mediana). Afim de ter um critério simples e fácil de lembrar, o limiar para esta campanha de medições foi definido em <strong>20 ms</strong>.</p>
            <p>Uma questão em aberto é se o mesmo limiar deve ser aplicado a todos os países, dado que variam muito em tamanho, número de redes e outras propriedades.</p>
            <p>Outra questão em aberto é se o limiar para os RTT que permanecem no país é o mesmo que o limiar para os RTT que saem do país. Quer dizer, haverá um RTT1 para medições que permanecem e um RTT2 para medições que saem? Exemplo disso são países como o Brasil e a Argentina, onde as medições que permanecem dentro parecem estar abaixo dos 50ms e as que saem parecem estar acima de 100ms.</p>
            <p>Uma vez aplicado o critério de Delta RTT sobre todo o conjunto de medições, percebeu-se que este fornecia informações complementares aos outros métodos. O gráfico a seguir mostra o número de endereços IP únicos aos quais cada método adiciona informações de geolocalização. Pelo gráfico pode-se observar que o IPmap faz a maior parte das contribuições de geolocalização das três fontes. Além disso, pode se observar que as contribuições de cada fonte são complementares, apresentando poucas interseções (IP em que ambas as fontes fornecem um resultado).</p>
            <p>Um exemplo onde o Delta RTT teve impacto é o caso do México, que está muito próximo dos Estados Unidos em termos de latência. Das correções de geolocalização aplicadas, 16% dos endereços registrados nos Estados Unidos foram corrigidos para o México. Notou-se ainda que 52% dos saltos são através de operadores como Cogent (AS174, com 8.2% dos casos dentro desse intervalo) o Level 3 (AS3356 e AS3549, com 6.1%). Observações semelhantes foram levantadas para os outros países. No caso da Argentina foi 55% de casos nesse intervalo (casos notáveis com Telecom Itália, 2.3% e Level 3 com 1.4%), e na Colômbia foram 37% (Level 3 com 11.3% e GTT Communications com 3.7%). </p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico20.png" className="imagen" alt="grafico20" /></p>


            <h2 id="conclusion_select">Conclusões</h2>

            <p>Embora não seja o objetivo principal desta pesquisa, quando os resultados de 2020 foram comparados com os de 2023, notou-se que <strong>os valores de latência de 2023 são visivelmente melhores</strong> para a maioria dos países.</p>
            <p>Em relação ao <strong>tráfego que é mantido de forma local dentro de um país</strong>, a maioria dos casos apresenta percentagens superiores a 90%, o que representa um valor bom. Os países maiores como a Argentina, Brasil, e México apresentaram valores altos (97, 94, e 94% respectivamente). A presença de IXP e grandes operadores contribui para a elevada percentagem. Apenas alguns casos específicos apresentaram percentuais inferiores a 90%.</p>
            <p>Se também é levada em conta a latência introduzida ao enviar tráfego para o exterior (para depois entrar novamente), poucos países introduzem latências alarmantes. Em termos gerais, não há <strong>tráfego que vaze</strong> por outros países e, quando isso acontece, é introduzida uma latência que não preocupa.</p>
            <p>Ao analisar a centralidade das redes, ou seja, a quantidade de tráfego que passa por cada rede, notou-se que <strong>os IXP e as principais redes estão no topo do ranking</strong> em cada país.</p>
            <p>Os <strong>IXP locais de diferentes países são visíveis tanto em termos de quantidade de tráfego quanto de desempenho</strong>. Além disso, o tráfego através de IXP circulou com latências mais baixas que o restante do tráfego.</p>
            <p>Com uma Internet cada vez mais globalizada e otimizada, obter fontes confiáveis de geolocalização é extremamente importante. As informações de registro dos RIR não são suficientemente precisas como para geolocalizar a infraestrutura da Internet. O estudo considera diversas fontes de geolocalização e adiciona sua própria fonte. No total, melhora-se a precisão de 40% dos resultados.</p>

            <h2 id="anexo1_select">Anexo 1: Destinos de traceroutes de saída (ASN)</h2>
            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/anexo1.png" className="imguno" alt="anexo1" /></p>



            <h2 id="anexo2_select">Anexo 2: Rotas com circuitos no exterior</h2>
            <h3 id="a2res">Resumo dos circuitos</h3>

            <p>A seguir, são listados os casos de traceroutes com circuitos que saem do país. Isto é, são traceroutes que saíram de um ASN, passaram por um país estrangeiro e depois entraram novamente no mesmo ASN. Estes casos poderiam indicar roteamento abaixo do ideal.</p>
            <p>Para mostrar todas as informações, as tabelas são apresentadas em formato compacto. Descrição das colunas:</p>
            <p><b>ip_o:</b> ip_origin, endereço IP da sonda<br />
                <b>ip_d:</b> ip_destination, endereço IP do hop<br />
                <b>asn_o:</b> asn_origin, ASN da sondabr&gt;
                <b>asn_d:</b> asn_destination, ASN do hop<br />
                <b>co:</b> country_origin, país da sonda<br />
                <b>cd:</b> country_destination, país do hop<br />
                <b>h:</b> hop_number, número de hop<br />
                <b>r:</b> RTT
            </p>


            <p>ASNs da <strong>AR</strong> que possuem uma rota de circuito pelo exterior</p>
            <p>AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA)</p>


            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta0.png" className="imguno" alt="ruta0" /></p>
            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta1.png" className="imguno" alt="ruta1" /></p>
            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta2.png" className="imguno" alt="ruta2" /></p>
            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta3.png" className="imguno" alt="ruta3" /></p>

            <p>ASNs de <b>CO</b> que tienen una ruta circuitosa por el extranjero</p>
            <p>AS265688 (SINERGY SOLUCIONES INTEGRALES) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS265688 (SINERGY SOLUCIONES INTEGRALES)</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta4.png" className="imguno" alt="ruta4" /></p>

            <p>ASNs da <strong>PE</strong> que possuem uma rota de circuito pelo exterior</p>
            <p>AS6147 (Telefonica del Peru S.A.A.) --&gt; AS12956 (TELXIUS TELEFONICA GLOBAL SOLUTIONS SL, WW) --&gt; AS6147 (Telefonica del Peru S.A.A.)</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta5.png" className="imguno" alt="ruta5" /></p>

            <p>ASNs da <strong>UY</strong> que possuem uma rota de circuito pelo exterior</p>

            <p>Nota: Este caso foi analisado em conjunto com os <br />
                operadores de AS 6057. Foi feita uma tentativa de <br />
                reproduzir o caminho com sondas do RIPE Atlas mas <br />
                não foi possível porque o traceroute não apresentava <br />
                circuitos.
            </p>

            <p>AS6057 (Administração Nacional das Telecomunicações) --&gt; AS10429 (TELEFÔNICA BRASIL S.A, BR) --&gt; AS26599 (TELEFÔNICA BRASIL S.A, BR) --&gt; AS8167 (V tal, BR) --&gt; AS6057 (Administração Nacional das Telecomunicações)</p>

            <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta6.png" className="imguno" alt="ruta6" /></p>


            <h2 id="anexo3grafos_select">Anexo 3:  Grafos dos países da ALC</h2>
            <h3 id="a3des">Downloads on-line</h3>

            <p>Os dados que suportam os grafos deste documento (grafos com origem e destino dentro do mesmo país) podem ser encontrados na seção de downloads do <a href="https://simon.lacnic.net/static/simon_app/conectividad-2022/" target='_blank' rel="noreferrer">site do LACNIC</a>. São arquivos CSV em que cada linha corresponde a uma aresta do grafo e contém as seguintes informações:</p>
            <ul>
                <li>Rede de origem - dado um traceroute com hopn e hopn+1, esta rede é o ASN ou IXP correspondente ao hopn.</li>
                <li>Rede de destino - idem mas correspondente ao hopn+1</li>
                <li>Rede de destino - idem mas correspondente ao hopn+1</li>
            </ul>
            <p>É de grande interesse contar com a colaboração dos leitores em termos de análises sucessivas dos dados ou visualizações baseadas nestes.</p>


            <h3>Representação visual</h3>

            <p>Esta seção mostra de forma visual os grafos calculados durante o estudo. A metodologia usada na elaboração dos grafos pode ser vista na seção <a href="#grafos" target='_blank' rel="noreferrer">Grafos</a>.</p>

            <table className="table" id="t1">
                <thead><tr><th>ARGENTINA (AR)</th></tr></thead>
                <tbody>
                    <tr><td><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico21.png" className="imagen_wide" alt="grafico21" /></figure></td></tr>
                    <tr><td>
                        <div className="row">
                            <div className="columna">
                                Graph centrality (top 20)<br />
                                1.	AR-IX Cabase<br />
                                2.	AS7303 Telecom Argentina S.A.<br />
                                3.	AS10834 Telefonica de Argentina<br />
                                4.	AS3549 LVLT-3549<br />
                                5.	AS262589 InterNexa Global Network<br />
                                6.	AS19037 AMX Argentina S.A.<br />
                                7.	AS3356 LEVEL3<br />
                                8.	AS11664 Techtel LMDS Comunicaciones Interactivas S.A.<br />
                                9.	AS11014 CPS<br />
                                10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                            </div>
                            <div className="columna">11.	AS16814 NSS S.A.<br />
                                12.	AS22927 Telefonica de Argentina<br />
                                13.	AS27747 Telecentro S.A.<br />
                                14.	AS52361 ARSAT - Empresa Argentina de Soluciones Satelitales S.A.<br />
                                15.	AS52360 CABASE Camara Arg de Base de Datos y Serv en Linea<br />
                                16.	AS174 COGENT-174<br />
                                17.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                18.	AS265862 BM SOLUCIONES S.R.L.<br />
                                19.	AS20207 Gigared S.A.<br />
                                20.	AS52376 CABASE Camara Arg de Base de Datos y Serv en Linea
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t2">
                <thead><tr><th>BOLIVIA (BO)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for BR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico22.png" className="imagen_wide" alt="grafico22" /></figure><br />
                            </div>
                            <div className="columna">Graph centrality (top 20)<br />
                                1.	PIT Bolivia<br />
                                2.	AS27839 Comteco Ltda<br />
                                3.	AS26210 AXS Bolivia S. A.<br />
                                4.	AS6568 Entel S.A. - EntelNet<br />
                                5.	AS25620 COTAS LTDA.<br />
                                6.	AS27882 Telefonica Celular de Bolivia S.A.<br />
                                7.	AS262159 Digital TV CABLE DE EDMUND S.R.L.<br />
                                8.	AS52250 Ag para el Desarrollo de la Sociedad de la Inf en Bolivia - ADSIB<br />
                                9.	AS267685 SIRIO TELECOMUNICACIONES S.R.L
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t3">
                <thead><tr><th>BRASIL (BR)</th></tr></thead>
                <tbody>
                    <tr><td><figure><figcaption>Traceroute graph for BO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico23.png" className="imagen_wide" alt="grafico23" /></figure></td></tr>
                    <tr><td>
                        <div className="row">
                            <div className="columna">
                                Graph centrality (top 20)<br />
                                1.	IX.br (PTT.br) São Paulo<br />
                                2.	AS16735 ALGAR TELECOM SA<br />
                                3.	AS3356 LEVEL3<br />
                                4.	AS4230 CLARO S.A.<br />
                                5.	IX.br (PTT.br) Porto Alegre<br />
                                6.	AS8167 V tal<br />
                                7.	AS26599 TELEFONICA BRASIL S.A<br />
                                8.	AS3549 LVLT-3549<br />
                                9.	IX.br (PTT.br) Fortaleza<br />
                                10.	AS26615 TIM SA
                            </div>
                            <div className="columna">11.	IX.br (PTT.br) Rio de Janeiro<br />
                                12.	AS28283 Adylnet Telecom<br />
                                13.	AS10429 TELEFONICA BRASIL S.A<br />
                                14.	AS28573 Claro NXT Telecomunicacoes Ltda<br />
                                15.	IX.br (PTT.br) Curitiba<br />
                                16.	AS25933 Vogel Solucoes em Telecom e Informatica SA<br />
                                17.	Equinix São Paulo<br />
                                18.	AS268886 WILLYNET PROVEDOR<br />
                                19.	AS14840 BR Digital<br />
                                20.	AS53062 GGNET TELECOM BACKBONE
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t4">
                <thead><tr><th>CHILE (CH)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for CL</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico24.png" className="imagen_wide" alt="grafico24" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	PIT Santiago - PIT Chile<br />
                                2.	AS7004 CTC Transmisiones Regionales S.A.<br />
                                3.	AS7418 TELEFONICA CHILE S.A.<br />
                                4.	AS14259 Gtd Internet S.A.<br />
                                5.	AS6535 Telmex Servicios Empresariales S.A.<br />
                                6.	AS6429 Telmex Chile Internet S.A.<br />
                                7.	AS22047 VTR BANDA ANCHA S.A.<br />
                                8.	AS3549 LVLT-3549<br />
                                9.	AS265662 TLINK SPA<br />
                                10.	AS27651 ENTEL CHILE S.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t5">
                <thead><tr><th>COLOMBIA (CO)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for CO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico25.png" className="imagen_wide" alt="grafico25" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	NAP Colombia<br />
                                2.	AS262186 TV AZTECA SUCURSAL COLOMBIA<br />
                                3.	AS13489 EPM Telecomunicaciones S.A. E.S.P.<br />
                                4.	AS3549 LVLT-3549<br />
                                5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                                6.	AS19429 ETB - Colombia<br />
                                7.	AS52468 UFINET PANAMA S.A.<br />
                                8.	AS14080 Telmex Colombia S.A.<br />
                                9.	AS10299 EMPRESAS MUNICIPALES DE CALI E.I.C.E. E.S.P.<br />
                                10.	AS23520 COLUMBUS-NETWORKS
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>


            <table className="table" id="t6">
                <thead><tr><th>COSTA RICA (CR)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for CR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico26.png" className="imagen_wide" alt="grafico26" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	CRIX<br />
                                2.	AS52468 UFINET PANAMA S.A.<br />
                                3.	AS262197 MILLICOM CABLE COSTA RICA S.A.<br />
                                4.	AS23520 COLUMBUS-NETWORKS<br />
                                5.	AS52228 Cable Tica<br />
                                6.	AS11830 Instituto Costarricense de Electricidad y Telecom.<br />
                                7.	AS1299 TWELVE99 Telia Company AB<br />
                                8.	AS262202 Telefonica de Costa Rica TC, SA<br />
                                9.	AS263762 COOPERATIVA DE ELECTRIFICACION RURAL DE GUANACASTE R.L.<br />
                                10.	AS28022 CRISP S.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t7">
                <thead><tr><th>REPúBLICA DOMINICANA (DO)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for DO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico27.png" className="imagen_wide" alt="grafico27" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS23520 COLUMBUS-NETWORKS<br />
                                2.	AS28118 ALTICE DOMINICANA S.A.<br />
                                3.	AS6400 Compania Dominicana de Telefonos S. A.<br />
                                4.	AS64126 DOMINICAN CABLE GROUP DCG, S.R.L.<br />
                                5.	AS1299 TWELVE99 Telia Company AB<br />
                                6.	AS264821 COMCAST-SRL<br />
                                7.	AS174 COGENT-174<br />
                                8.	AS264605 TELEVIADUCTO S.R.L.<br />
                                9.	AS270098 WI-FI DOMINICANA, E.I.R.L.<br />
                                10.	AS12066 ALTICE DOMINICANA S.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t8">
                <thead><tr><th>ECUADOR (EC)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for EC</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico28.png" className="imagen_wide" alt="grafico28" /></figure><br />
                            </div>
                            <div className="columna">Graph centrality (top 20)<br />
                                1.	NAP.EC - UIO<br />
                                2.	AS26613 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                3.	AS264668 NEDETEL S.A.<br />
                                4.	NAP.EC - GYE<br />
                                5.	AS27947 Telconet S.A<br />
                                6.	AS61468 CEDIA<br />
                                7.	AS27738 Ecuadortelecom S.A.<br />
                                8.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                                9.	AS263238 Eliana Vanessa Morocho Ona<br />
                                10.	AS23487 CONECEL<br />
                                11.	AS14522 Satnet<br />
                                12.	AS1299 TWELVE99 Telia Company AB<br />
                                13.	AS28006 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                14.	AS3356 LEVEL3<br />
                                15.	AS27668 ETAPA EP<br />
                                16.	AS27757 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                17.	AS263792 IN.PLANET S. A<br />
                                18.	AS52458 WISP INTERNET ECUADOR<br />
                                19.	AS265711 KOLVECH S.A. TELECOMVAS<br />
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t9">
                <thead><tr><th>GUYANA FRANCESA (GF)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for GF</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico29.png" className="imagen_wide" alt="grafico29" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS2200 FR-RENATER Reseau National de telecommunications pour la Technologie<br />
                                2.	AS21351 CANALPLUSTELECOM Canal + Telecom SAS<br />
                                3.	AS263175 GUYACOM
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t10">
                <thead><tr><th>GUATEMALA (GT)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for GT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico30.png" className="imagen_wide" alt="grafico30" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                2.	AS23243 COMCEL GUATEMALA S.A.<br />
                                3.	AS174 COGENT-174<br />
                                4.	AS52468 UFINET PANAMA S.A.<br />
                                5.	AS14754 Telgua<br />
                                6.	AS6453 AS6453<br />
                                7.	AS3356 LEVEL3<br />
                                8.	AS27742 Amnet Telecomunicaciones S.A.<br />
                                9.	AS267715 RED CENTROAMERICANA DE TELECOMUNICACIONES S.A, SUCURSAL GUATEMALA, SOCIEDAD EXTRANJERA<br />
                                10.	AS1299 TWELVE99 Telia Company AB
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>


            <table className="table" id="t11">
                <thead><tr><th>GUYANA (GY)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for GY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico31.png" className="imagen_wide" alt="grafico31" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS19863 Guyana Telephone &amp; Telegraph Co.<br />
                                2.	AS23520 COLUMBUS-NETWORKS<br />
                                3.	AS264694 EGOVERNMENT UNIT<br />
                                4.	AS52253 E-Networks Inc.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t12">
                <thead><tr><th>HONDURAS (HN)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for HN</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico32.png" className="imagen_wide" alt="grafico32" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS52262 Telefonica Celular S.A<br />
                                2.	AS27932 Redes y Telecomunicaciones<br />
                                3.	AS1299 TWELVE99 Telia Company AB<br />
                                4.	AS23520 COLUMBUS-NETWORKS<br />
                                5.	AS27884 CABLECOLOR S.A.<br />
                                6.	AS7087 Administracion de Redes Colomsat S.A.<br />
                                7.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                8.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                9.	AS1239 SPRINTLINK<br />
                                10.	AS20299 Newcom Limited
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>

            <table className="table" id="t13">
                <thead><tr><th>HAITÍ (HT)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for HT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico33.png" className="imagen_wide" alt="grafico33" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS27759 ACCESS HAITI S.A.<br />
                                2.	AS52260 Telecommunications de Haiti Teleco<br />
                                3.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                                4.	AS33576 DIG001<br />
                                5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                6.	AS1299 TWELVE99 Telia Company AB
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t14">
                <thead><tr><th>MEXICO (MX)</th></tr></thead>
                <tbody>
                    <tr><td><figure><figcaption>Traceroute graph for MX</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico34.png" className="imagen_wide" alt="grafico34" /></figure></td></tr>
                    <tr><td>
                        <div className="row">
                            <div className="columna">
                                Graph centrality (top 20)<br />
                                1.	AS8151 Uninet S.A. de C.V.<br />
                                2.	AS32098 TRANSTELCO-INC<br />
                                3.	AS18734 Operbes, S.A. de C.V.<br />
                                4.	AS174 COGENT-174<br />
                                5.	AS3356 LEVEL3<br />
                                6.	AS1299 TWELVE99 Telia Company AB<br />
                                7.	AS6503 Axtel, S.A.B. de C.V.<br />
                                8.	AS22884 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                                9.	AS3549 LVLT-3549<br />
                                10.	AS11172 Alestra, S. de R.L. de C.V.
                            </div>
                            <div className="columna">111.	AS17072 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                                12.	AS13999 Mega Cable, S.A. de C.V.<br />
                                13.	AS6453 AS6453<br />
                                14.	AS28469 AT&amp;T COMUNICACIONES DIGITALES S DE RL<br />
                                15.	AS23520 COLUMBUS-NETWORKS<br />
                                16.	AS6461 ZAYO-6461<br />
                                17.	AS28548 Cablevision, S.A. de C.V.<br />
                                18.	AS11888 Television Internacional, S.A. de C.V.<br />
                                19.	AS7438 Pegaso PCS, S.A. de C.V.<br />
                                20.	AS28545 Cablemas Telecomunicaciones SA de CV
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t15">
                <thead><tr><th>NICARAGUA (NI)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for NI</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico35.png" className="imagen_wide" alt="grafico35" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS14754 Telgua<br />
                                2.	AS52242 Yota De Nicaragua<br />
                                3.	AS25607 IBW Communications
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>


            <table className="table" id="t16">
                <thead><tr><th>PANAMA (PA)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for PA</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico36.png" className="imagen_wide" alt="grafico36" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	InteRed Panama<br />
                                2.	AS1299 TWELVE99 Telia Company AB<br />
                                3.	AS23520 COLUMBUS-NETWORKS<br />
                                4.	AS18809 Cable Onda<br />
                                5.	AS11556 Cable &amp; Wireless Panama<br />
                                6.	AS27930 Shadwell International Inc<br />
                                7.	AS52468 UFINET PANAMA S.A.<br />
                                8.	AS21599 Cable Onda<br />
                                9.	AS28005 Digicel Panama, S.A<br />
                                10.	AS262191 COLUMBUS NETWORKS COLOMBIA<br />
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t17">
                <thead><tr><th>PERÚ (PE)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for PE</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico37.png" className="imagen_wide" alt="grafico37" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS3132 Red Cientifica Peruana<br />
                                2.	AS6147 Telefonica del Peru S.A.A.<br />
                                3.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                4.	AS262210 VIETTEL PERU S.A.C.<br />
                                5.	AS61482 CONVERGIA<br />
                                6.	AS262253 ECONOCABLE MEDIA SAC<br />
                                7.	AS269857 FIBER DIGITAL S.R.L<br />
                                8.	AS27843 OPTICAL TECHNOLOGIES S.A.C.<br />
                                9.	AS12252 America Movil Peru S.A.C.<br />
                                10.	AS21575 ENTEL PERU S.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t18">
                <thead><tr><th>PARAGUAY (PY)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for PY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico38.png" className="imagen_wide" alt="grafico38" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS23201 Telecel S.A.<br />
                                2.	IX.br (PTT.br) São Paulo<br />
                                3.	AS27768 COMPANIA PARAGUAYA DE COMUNICACIONES S.A. COPACO S.A.<br />
                                4.	AS61512 GIG@NET SOCIEDAD ANONIMA<br />
                                5.	AS3356 LEVEL3<br />
                                6.	AS266831 MONGELOS ARCE MARCIALDELTA NETWORKS<br />
                                7.	AS52468 UFINET PANAMA S.A.<br />
                                8.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                9.	AS21928 T-MOBILE-AS21928<br />
                                10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>




            <table className="table" id="t19r">
                <thead><tr><th>EL SALVADOR (SV)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for SV</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico39.png" className="imagen_wide" alt="grafico39" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS6453 AS6453<br />
                                2.	AS1299 TWELVE99 Telia Company AB<br />
                                3.	AS23520 COLUMBUS-NETWORKS<br />
                                4.	AS3491 BTN-ASN<br />
                                5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                6.	AS14754 Telgua<br />
                                7.	AS27773 MILLICOM CABLE EL SALVADOR S.A. DE C.V.<br />
                                8.	AS33576 DIG001<br />
                                9.	AS262199 Columbus Networks El Salvador SA de CV<br />
                                10.	AS6461 ZAYO-6461
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>

            <table className="table" id="t20">
                <thead><tr><th>TRINIDAD Y TOBAGO (TT)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for TT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico40.png" className="imagen_wide" alt="grafico40" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS27665 Columbus Communications Trinidad Limited.<br />
                                2.	AS23520 COLUMBUS-NETWORKS<br />
                                3.	AS5639 Telecommunication Services of Trinidad and Tobago<br />
                                4.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                                5.	AS27924 AMPLIA COMMUNICATIONS LTD.<br />
                                6.	AS264811 AIR LINK COMMUNICATIONS<br />
                                7.	AS27789 GREENDOT<br />
                                8.	AS393629 GDGR<br />
                                9.	AS1299 TWELVE99 Telia Company AB<br />
                                10.	AS263222 RVR INTERNATIONAL LIMITED
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>



            <table className="table" id="t21">
                <thead><tr><th>URUGUAY (UY)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for UY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico41.png" className="imagen_wide" alt="grafico41" /></figure><br />
                            </div>
                            <div className="columna" >Graph centrality (top 10)<br />
                                1.	AS6057 Administracion Nacional de Telecomunicaciones<br />
                                2.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                3.	AS19422 Telefonica Moviles del Uruguay SA<br />
                                4.	AS28000 LACNIC - Latin American and Caribbean IP address<br />
                                5.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                                6.	AS10429 TELEFONICA BRASIL S.A<br />
                                7.	AS26599 TELEFONICA BRASIL S.A<br />
                                8.	AS8167 V tal<br />
                                9.	AS61455 LACTLD - LATIN AMERICAN AND CARIBBEAN TLD ASSOCIATION<br />
                                10.	AS1797 Uruguay
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>


            <table className="table" id="t22">
                <thead><tr><th>VENEZUELA (VE)</th></tr></thead>
                <tbody>
                    <tr><td>
                        <div className="row">
                            <div className="columna" ><figure><figcaption>Traceroute graph for VE</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico42.png" className="imagen_wide" alt="grafico42" /></figure><br />
                            </div>
                            <div className="columna">Graph centrality (top 10)<br />
                                1.	AS3549 LVLT-3549<br />
                                2.	AS6306 TELEFONICA VENEZOLANA, C.A.<br />
                                3.	AS263703 VIGINET C.A<br />
                                4.	AS8048 CANTV Servicios, Venezuela<br />
                                5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                                6.	AS1299 TWELVE99 Telia Company AB<br />
                                7.	AS61461 Airtek Solutions C.A.<br />
                                8.	AS3356 LEVEL3<br />
                                9.	FL-IX <br />
                                10.	AS11562 Net Uno, C.A.
                            </div>
                        </div>
                    </td></tr>
                </tbody>
            </table>
        </>
    )
}

export default Conectividad2022Pt
