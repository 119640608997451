import React, {useContext} from 'react';
import { LangContext } from '../../../context/DataContext';

function Objetivo() {
    const {myLang} = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <>
                    <h2>Objetivo</h2>
                    <p>Contribuir a la mejora de la seguridad en la regi&oacute;n de LACNIC, alentando a las organizaciones asociadas a implementar buenas pr&aacute;cticas de seguridad para colaborar con la estabilidad y resiliencia de Internet.</p>
                </>}
                {myLang === 'en' && <>
                    <h2> Goal</h2>
                    <p>To contribute to the enhancement of security in the LACNIC region, encouraging member organizations to implement good security practices that will contribute to Internet stability and resilience.</p>
                </>}
                {myLang === 'pt' && <>
                <h2>Objetivo</h2>
<p>Contribuir para a melhoria da seguran&ccedil;a na regi&atilde;o do LACNIC, incentivando as organiza&ccedil;&otilde;es associadas a implementarem boas pr&aacute;ticas de seguran&ccedil;a para contribuir para a estabilidade e resili&ecirc;ncia da Internet.</p>
                </>}
            </div>
        </div>
    )
}

export default Objetivo