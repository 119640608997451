import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';  

function Analisis(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4"> 
                {myLang === 'es' && <>
                    <h2>
<MatchText id="match-19"> Análisis preliminar del problema</MatchText></h2>
                    <p>
<MatchText id="match-20"> Un ataque de denegación de servicio distribuido —llamado también DDoS— es generado, desde distintos orígenes, para causar que un servicio, recurso de internet o red quede inaccesible. Los ataques de DDoS pueden afectar los recursos de una organización víctima durante el tiempo que el ejecutante desee. Dependiendo del volumen de tráfico recibido, puede afectar no solo a la organización víctima, sino también a su proveedor de internet.</MatchText></p>
                    <p>
<MatchText id="match-21"> Una forma de llevar a cabo estos ataques es aprovechar la presencia de servicios reflexivos y amplificables expuestos a internet. Los servicios reflexivos son aquellos que no necesitan de un handshake previo para el intercambio de datos. De esto se desprende que cualquier servicio que utiliza UDP como transporte podría entrar en esta categoría.</MatchText></p>
                    <p>
<MatchText id="match-22"> El otro requisito es que el servicio, además de ser reflexivo, sea amplificable. Los servicios amplificables son aquellos que, ante un requerimiento determinado, dan una respuesta cuyo tamaño es mucho mayor que el del requerimiento. A esta diferencia se la conoce como factor de amplificación.</MatchText></p>
                    <h3>
<MatchText id="match-23">Posibles protocolos amplificables</MatchText></h3>
                    <p>
<MatchText id="match-24"> Distintos protocolos de internet entran en la categoría de reflexivos y amplificables. Algunos, como CHARGEN, no deberían estar expuestos a internet. Otros, como NTP, pueden presentar vulnerabilidades en el servidor que lo implementa.</MatchText></p>
                    <p>
<MatchText id="match-25"> Por último, cabe mencionar que malas configuraciones, por ejemplo en NTP o DNS, pueden permitir exponer servicios reflexivos con amplificaciones indeseables.</MatchText></p>
                    <p>
<MatchText id="match-26"> La alerta TA14-017A del Sistema Nacional de Concienciación Cibernética del US-CERT [3] identifica distintos protocolos basados en UDP que podrían ser potencialmente utilizados en ataques de DDoS. En esta alerta se informan estos protocolos, su factor de amplificación y el comando vulnerable asociado.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r1_es.png" /> </figure>
                    <p>
<MatchText id="match-27"> La alerta, originalmente publicada en el año 2014, se fue actualizando y se fueron incorporando nuevos protocolos vulnerables que podrían ser explotados o que fueron utilizados en ataques de DDoS que generaron grandes volúmenes de tráfico [4][5][6][7][8].</MatchText></p>
                    <p>
<MatchText id="match-28"> Adicionalmente, en una alerta del FBI del 2020 y en diversos sitios dedicados al análisis de problemáticas de DDoS a partir del resultado de ataques realizados, se mencionan protocolos que complementan los observados antes por la alerta TA14-017A. Entre ellos cabe mencionar CoAP, un protocolo de transferencia web diseñado para redes y dispositivos con recursos limitados, con potencial para desarrollar aplicaciones en internet of things (IoT) [9][10][11].</MatchText></p>
                    <p>
<MatchText id="match-29"> En el futuro, esta lista de protocolos reflexivos y amplificables puede cambiar. Esto, por la posible aparición de nuevos protocolos vulnerables o el descubrimiento de nuevas formas de utilizar protocolos existentes para provocar amplificaciones hasta el momento inesperadas.</MatchText></p>
                    <h3>
<MatchText id="match-30">Fuentes de información</MatchText></h3>
                    <p>
<MatchText id="match-31"> Existen distintas maneras de detectar dispositivos que exponen protocolos reflexivos y amplificables a internet. La mejor forma de determinar la existencia de un protocolo vulnerable es conectarse a cada posible recurso IP e intentar realizar una consulta inocua usando ese protocolo. En este sentido, vale la pena mencionar el proyecto SCANNING de la fundación Shadowserver, el cual, entre otras cosas, intenta identificar la presencia de recursos en internet implementando protocolos amplificables y reflexivos[12][13].</MatchText></p>
                    <p>
<MatchText id="match-32"> Alternativamente, se pueden utilizar otras fuentes de información abiertas que brinden inteligencia de amenazas u OSINT. Como ejemplos de este tipo de fuentes de información abierta podemos mencionar SHODAN o CENSYS, que pueden ser utilizados para encontrar servicios amplificables vulnerables[14][15].</MatchText></p>
                    <p>
<MatchText id="match-33"> Sin embargo, que un protocolo esté expuesto no significa necesariamente que sea vulnerable y pueda ser explotado. Por ejemplo, un servidor de DNS es vulnerable si está configurado como open resolver sin ningún tipo de restricción en su uso. Debido a esto, dependiendo el protocolo evaluado, puede ser necesaria una posterior verificación de la vulnerabilidad del servicio.</MatchText></p>
                    <h3>
<MatchText id="match-34">Análisis de protocolos de interés para el estudio</MatchText></h3>
                    <p>
<MatchText id="match-35"> Si bien lo ideal es erradicar todos los protocolos vulnerables a ataques de amplificación, se seleccionaron aquellos que pueden representar mayor riesgo para la seguridad y estabilidad de internet. Para la selección, se tuvieron en cuenta:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-36">Los protocolos reflexivos y amplificables que se conoce que son utilizados en estas problemáticas.</MatchText></li>
                        <li>
<MatchText id="match-37">Nuevos protocolos vulnerables observados en los últimos años.</MatchText></li>
                        <li>
<MatchText id="match-38">El factor de amplificación posible que cada protocolo puede ofrecer a un atacante.</MatchText></li>
                        <li>
<MatchText id="match-39">La cantidad de potenciales dispositivos vulnerables en internet.</MatchText></li>
                        <li>
<MatchText id="match-40">Las fuentes de información con las que se puede contar.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-41"> Como punto de partida, tomamos los servicios que en principio pueden considerarse los exponentes más claros de esta problemática. Con ellos se utilizó Shodan, para tener una estimación rápida de la cantidad de dispositivos que exponen este servicio a internet. Combinando esta información con el factor de amplificación de cada uno, se generó la siguiente tabla:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r2_es.png" /> </figure>
                    <p>
<MatchText id="match-42"> Algunas observaciones a partir de estos resultados son:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-43">No todos los dispositivos estimados por Shodan son reflexivos. Los siguientes protocolos pueden funcionar tanto sobre UDP como sobre TCP: Memcached, DNS, WS-Discovery, CharGen, SNMP y QOTD.</MatchText></li>
                        <li>
<MatchText id="match-44">Debido a que con Shodan no podemos consultar sobre los que corren exclusivamente sobre UDP, en principio no tenemos certeza de cuántos de estos se pueden utilizar para montar ataques de DDoS, lo cual es de interés para este estudio.</MatchText></li>
                        <li>
<MatchText id="match-45">Los protocolos informados por Shodan no distinguen versiones. Esto, en el caso particular de SNMP es de interés, puesto que la versión vulnerable es la 2c, la cual permite utilizar de manera reflexiva el comando GetBulk para lograr factores de amplificación elevados.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-46"> Adicionalmente, para la evaluación, se utilizó información de distintas fuentes, de modo de contrastar el riesgo que cada uno de estos protocolos representa hoy en día. En este sentido, si bien todos los problemas son graves, vale la pena mencionar que:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-47">Durante 2018, un estudio realizado por un proyecto financiado por la Unión Europea[https://sissden.eu/blog/amp2018] mostraba en los resultados de una red de honeypots que los ataques de DDoS observados evidenciaban la vigencia y relevancia del uso por parte de los atacantes de protocolos como CharGen, DNS, NTP y SSDP.</MatchText></li>
                        <li>
<MatchText id="match-48">Se analizaron los datos públicos de la fundación Shadowserver. La fundación Shadowserver reúne y analiza datos sobre actividades maliciosas de internet. En particular, se utilizaron estadísticas públicas globales y por países sobre distintos protocolos que se pueden utilizar en ataques de DDoS.</MatchText></li>
                        <li>
<MatchText id="match-49">El protocolo Memcached irrumpió en la escena de los servicios reflexivos y amplificables en febrero de 2018, cuando fue utilizado para realizar, hasta ese entonces, el mayor ataque volumétrico de DDoS con el que Akamai experimentó 1.3. TBps sobre uno de sus clientes [8]. Desde ese momento se visibilizó el problema y se concientizó adecuadamente a la comunidad. Como resultado de esto, podemos ver estadísticas realizadas por la fundación Shadowserver que muestran la cantidad de servidores Memcached sobre UDP observados en internet.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-50"> La gráfica empieza cuando sucedió el ataque de DDoS descripto. A partir de ahí, el descenso es visible. Los datos disponibles del 2020, los últimos publicados por Shadowserver, muestran mejor la escala sobre la cantidad de servidores reflexivos de este protocolo:</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r5_es.png" /> </figure>
                    <p>
<MatchText id="match-51"> Respecto a CoAP, protocolo que se espera encontrar en distintos dispositivos de internet de las cosas (IoT), existe un proyecto de la Unión Europa llamado VARIoT que intenta clasificar dispositivos vulnerables expuestos a internet. De los resultados informados en el proyecto, la gran mayoría de los dispositivos vulnerables se encuentran en Filipinas, Rusia y China[10]. Esta información también fue contrastada con Shodan, el cual mostró que en la región de LACNIC no hay una cantidad significativa de dispositivos que implementen CoAP.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r6_es.png" /> </figure>
                    <p>
<MatchText id="match-52"> Por último, como parte de la evaluación de los protocolos de interés, se tuvo en cuenta la manera de definir si el servicio expuesto es vulnerable. En este sentido, se estudió, para cada uno de los protocolos analizados, si alcanza con hacer un requerimiento válido para determinar, en función de la respuesta, si el servicio es vulnerable.</MatchText></p>
                    <p>
<MatchText id="match-53"> Para WS-Discovery es necesario enviar un comando mal formado para constatar la vulnerabilidad del servicio expuesto. Mientras, para el resto de los protocolos se dispone de comandos válidos que permiten confirmar la vulnerabilidad del servicio sin poner en riesgo su normal funcionamiento. No consideramos adecuado incluir el protocolo WS-Discovery en el estudio, pues no se puede asegurar la inocuidad de los tests realizados contra dispositivos de este tipo.</MatchText></p>
                    <p>
<MatchText id="match-54"> En virtud de esos resultados, se define que es pertinente analizar y evaluar la problemática en la región, haciendo foco en la detección, concientización y mitigación de instancias vulnerables de servicios de los protocolos: NTP, DNS, CharGen y SSDP.</MatchText></p>

                </>}
                {myLang === 'en' && <>
                    <h2>
<MatchText id="match-19"> Preliminary Analysis of the Problem</MatchText></h2>
                    <p>
<MatchText id="match-20"> A distributed denial of service (DDoS) attack is launched from different origins for the purpose of rendering inaccessible an Internet resource, service, or network. DDoS attacks can affect the victim organization's resources for as long as the attacker desires. Depending on the volume of traffic received by the target, an attack can affect not only the victim organization, but also its Internet provider.</MatchText></p>
                    <p>
<MatchText id="match-21"> One way to conduct these attacks is to leverage the presence of reflective and amplifiable services exposed to the Internet. Reflective services are services that do not require handshaking prior to exchanging data. It follows from this that any service that uses UDP as transport protocol can fall into this category.</MatchText></p>
                    <p>
<MatchText id="match-22"> The other requirement is that, in addition to being reflective, the service must be amplifiable. Amplifiable services are services which provide a response that is not much larger than the specific requirement they receive. This difference is known as the amplification factor.</MatchText></p>
                    <h3>
<MatchText id="match-23">Potentially Amplifiable Protocols</MatchText></h3>
                    <p>
<MatchText id="match-24"> Several Internet protocols fall into the reflective and amplifiable category. Some of these protocols, such as CHARGEN, should not be exposed to the Internet. Others, such as NTP, may create vulnerabilities in the servers where they are implemented.</MatchText></p>
                    <p>
<MatchText id="match-25"> Finally, it should be noted that improper configurations, for example in NTP or DNS, may cause reflective services to be exposed and lead to undesirable amplifications.</MatchText></p>
                    <p>
<MatchText id="match-26"> US-CERT National Cyber Awareness System Alert TA14-017A [3] identifies several protocols that rely on UDP as potential DDoS attack vectors. It reports the protocols, their amplification factor, and the associated vulnerable command.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r1_en.png" /> </figure>
                    <p>
<MatchText id="match-27"> Originally published in 2014, this Alert has been updated several times and new vulnerable protocols have been incorporated which may potentially be exploited or which were used in DDoS attacks that generated large volumes of traffic [4] [5] [6] [7] [8].</MatchText></p>
                    <p>
<MatchText id="match-28"> Additionally, in 2020, a notification issued by the FBI as well as several websites dedicated to the analysis of DDoS attacks mentioned additional protocols to those previously noted by alert TA14-017A. These include CoAP, a web transfer protocol designed for constrained nodes and networks and with the potential for use in Internet of Things (IoT) applications [9] [10] [11].</MatchText></p>
                    <p>
<MatchText id="match-29"> In the future, this list of reflective and amplifiable protocols may change, as additional vulnerable protocols may appear or new ways of using existing protocols to cause hitherto unexpected amplifications may be discovered.</MatchText></p>
                    <h3>
<MatchText id="match-30">Information Sources</MatchText></h3>
                    <p>
<MatchText id="match-31"> There are different ways to detect devices that expose reflective and amplifiable protocols to the Internet. The best way to determine the existence of a vulnerable protocol is to connect to every possible IP resource and attempt to perform an innocuous query using that protocol. In this sense, Shadowserver Foundation&rsquo;s SCANNING Project is worth noting. Among other things, this project attempts to identify the presence of Internet resources implementing reflective and amplifiable protocols [12] [13].</MatchText></p>
                    <p>
<MatchText id="match-32"> Another option is to use open information sources that provide threat intelligence or OSINT. Examples of this type of open information sources include SHODAN and CENSYS, which can be used to detect vulnerable amplifiable services [14] [15].</MatchText></p>
                    <p>
<MatchText id="match-33"> However, just because a protocol is exposed does not necessarily mean that it is vulnerable and may be exploited. For example, a DNS server is vulnerable if it is configured as an open resolver with no restrictions to its utilization. Therefore, depending on which protocol is being evaluated, it may later be necessary to verify whether the service is vulnerable or not.</MatchText></p>
                    <h3>
<MatchText id="match-34">Analysis of the Protocols of Interest to the Study</MatchText></h3>
                    <p>
<MatchText id="match-35"> While ideally the study would like to eradicate any protocol that is vulnerable to amplification attacks, we selected those which might represent the greatest risk to Internet security and stability. This selection was based on the following:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-36">Reflective and amplifiable protocols that are known to be used in this type of attacks.</MatchText></li>
                        <li>
<MatchText id="match-37">New vulnerable protocols detected in recent years.</MatchText></li>
                        <li>
<MatchText id="match-38">Each protocol&rsquo;s potential amplification factor.</MatchText></li>
                        <li>
<MatchText id="match-39">The number of potentially vulnerable devices on the Internet.</MatchText></li>
                        <li>
<MatchText id="match-40">Available sources of information.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-41"> We began by analyzing the services usually considered the clearest examples of this problem and used Shodan to obtain a quick estimate of the number of devices exposing this service to the Internet. The table below was generated by combining this information with the corresponding amplification factor.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r2_en.png" /> </figure>
                    <p>
<MatchText id="match-42"> The following observations are based on these results:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-43">Not all devices estimated by Shodan are reflective. The following protocols can work both over UDP as well as over TCP: Memcached, DNS, WS-Discovery, CharGen, SNMP, and QOTD.</MatchText></li>
                        <li>
<MatchText id="match-44">Because Shodan does not allow querying protocols that run exclusively over UDP, in principle, we are not sure how many of these can be used to generate a DDoS attack, something that is of interest to this study.</MatchText></li>
                        <li>
<MatchText id="match-45">The protocols reported by Shodan are versionless. This is of interest in the case of SNMP, as 2c is the vulnerable version which allows the GetBulk command to be used reflectively to achieve high amplification factors.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-46"> In addition, this analysis used information from different sources in order to compare the risk currently represented by each of these protocols. In this sense, while all the issues are serious, it is worth mentioning that:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-47">During 2018, a study conducted by a project funded by the European Union [https://sissden.eu/blog/amp2018] and operating a network of honeypots designed for amplification DDoS attacks showed that CharGen, DNS, NTP, and SSDP were the most prevalent attack vectors.</MatchText></li>
                        <li>
<MatchText id="match-48">Public data from the Shadowserver Foundation were analyzed. The Shadowserver Foundation gathers and analyzes data on malicious Internet activity. In particular, public national and global statistics were analyzed for the different protocols that can be used in DDoS attacks.</MatchText></li>
                        <li>
<MatchText id="match-49">The Memcached protocol burst onto the reflective and amplifiable services scene in February 2018, when it was used to generate the largest-ever volumetric DDoS attack which led one of Akamai's customers to experience 1.3 TBps of sustained traffic [8]. From that moment on, the issue gained visibility and awareness was raised among the community. As a result, Shadowserver Foundation now produces statistics that show the number of Memcached servers over UDP on the Internet.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-50"> The chart begins at the time of the DDoS attack described above, and a reduction is clearly visible from that moment on. The data available for 2020 — the latest data published by Shadowserver — allow a better understanding of the scale of the number of reflective servers using this protocol:</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r5_es.png" /> </figure>
                    <p>
<MatchText id="match-51"> RAs for CoAP, a protocol that can be expected to be found on multiple Internet of Things (IoT) devices, the European Union&rsquo;s VARIoT project is trying to classify vulnerable IoT devices exposed to the Internet. VARIoT project outcomes show that the vast majority of accessible devices are found in the Philippines, Russia, and China [10]. This information was also compared with information available through Shodan, which showed that, in the LACNIC service region, the number of devices implementing CoAP is not significant.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r6_es.png" /> </figure>
                    <p>
<MatchText id="match-52"> Finally, our analysis of the protocols that would be of interest to the study included how to define whether an exposed service was vulnerable. In this sense, for each of the protocols we analyzed, we studied whether the response to a valid request would be enough to determine if the service was vulnerable.</MatchText></p>
                    <p>
<MatchText id="match-53"> In the case of WS-Discovery, verifying the vulnerability of the exposed service required sending a malformed command. However, valid commands are available for the remaining protocols that allow confirming the vulnerability of a service without jeopardizing its normal operation. In our opinion, including the WS-Discovery protocol in the study would not have been appropriate, as it is impossible to guarantee the security of the tests conducted on this type of devices.</MatchText></p>
                    <p>
<MatchText id="match-54"> Based on these results, we concluded that an analysis and evaluation of the problem at the regional level would be appropriate, focusing on the detection, awareness, and mitigation of vulnerable instances of services using the NTP, DNS, CharGen, and SSDP protocols.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2>
<MatchText id="match-19"> Análise preliminar do problema</MatchText></h2>
                    <p>
<MatchText id="match-20"> Um ataque de denegação de serviço distribuído, também chamado de DDoS, é gerado desde origens diferentes para fazer com que um serviço, recurso da Internet ou rede fique inacessível. Os ataques de DDoS podem afetar os recursos de uma organização vítima durante o tempo que o executante quiser. Dependendo do volume de tráfego recebido, o ataque pode afetar não apenas a organização vítima, mas também o seu provedor da Internet.</MatchText></p>
                    <p>
<MatchText id="match-21"> Uma forma de efetivar esses ataques é aproveitando a presença de serviços reflexivos e amplificáveis expostos na Internet. Os serviços reflexivos são aqueles que não precisam de um handshake prévio para a troca de dados. Conclui-se com isso, que qualquer serviço que usa UDP como transporte poderia cair nesta categoria.</MatchText></p>
                    <p>
<MatchText id="match-22"> O outro requisito é que o serviço, além de ser reflexivo, seja amplificável. Os serviços amplificáveis são aqueles que, ao se depararem com um requisito específico, dão uma resposta cujo tamanho é muito maior que o do requisito. Essa diferença é conhecida como fator de amplificação.</MatchText></p>
                    <h3>
<MatchText id="match-23">Protocolos amplificáveis possíveis</MatchText></h3>
                    <p>
<MatchText id="match-24"> Diferentes protocolos da Internet se enquadram na categoria de reflexivos e amplificáveis. Alguns, como o CHARGEN, não deveriam ser expostos na Internet. Outros, como o NTP, podem apresentar vulnerabilidades no servidor que o implementa.</MatchText></p>
                    <p>
<MatchText id="match-25"> Por fim, deve-se mencionar que configurações ruins, como em NTP ou DNS, podem permitir expor serviços reflexivos com amplificações indesejáveis.</MatchText></p>
                    <p>
<MatchText id="match-26"> O alerta TA14-017A do Sistema Nacional de Conscientização Cibernética do US-CERT [3], identifica diferentes protocolos baseados em UDP que poderiam ser potencialmente usados em ataques de DDoS. Esses protocolos, seu fator de amplificação e o comando vulnerável associado são informados neste alerta.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r1_pt.png" /> </figure>
                    <p>
<MatchText id="match-27"> O alerta, publicado originalmente em 2014, foi se atualizando e foram-se incorporando novos protocolos vulneráveis que poderiam ser explorados ou que foram usados em ataques de DDoS que geraram grandes volumes de tráfego [4][5][6][7][8].</MatchText></p>
                    <p>
<MatchText id="match-28"> Adicionalmente, em um alerta do FBI de 2020 e em vários sites dedicados à análise de problemas de DDoS com base nos resultados dos ataques realizados, são mencionados protocolos que complementam os observados anteriormente pelo alerta TA14-017A. Entre eles encontramos o CoAP, um protocolo de transferência web projetado para redes e dispositivos com recursos limitados, com potencial para desenvolver aplicativos na Internet das Coisas (IoT) [9][10][11].</MatchText></p>
                    <p>
<MatchText id="match-29"> ENo futuro, essa lista de protocolos reflexivos e amplificáveis pode mudar. Isso, devido ao potencial surgimento de novos protocolos vulneráveis ou à descoberta de novas formas de usar protocolos existentes para provocar amplificações até então inesperadas.</MatchText></p>
                    <h3>
<MatchText id="match-30">Fontes de informação</MatchText></h3>
                    <p>
<MatchText id="match-31"> Existem diferentes formas de detectar dispositivos que expõem protocolos reflexivos e amplificáveis à Internet. A melhor forma de determinar a existência de um protocolo vulnerável é conectar-se a cada recurso IP possível e tentar fazer uma consulta inócua usando esse protocolo. Nesse sentido, vale a pena mencionar o projeto SCANNING da fundação Shadowserver, que, entre outras coisas, tenta identificar a presença de recursos na Internet através da implementação de protocolos amplificáveis e reflexivos[12][13].</MatchText></p>
                    <p>
<MatchText id="match-32"> Como alternativa, outras fontes de informação abertas que fornecem inteligência sobre ameaças ou OSINT podem ser usadas. Como exemplos desse tipo de fontes de informação aberta temos o SHODAN ou CENSYS que podem ser usados para encontrar serviços amplificáveis vulneráveis[14][15].</MatchText></p>
                    <p>
<MatchText id="match-33"> No entanto, só porque um protocolo esteja exposto não quer dizer necessariamente que ele seja vulnerável e possa ser explorado. Por exemplo, um servidor do DNS é vulnerável se estiver configurado como Open Resolver sem quaisquer restrições ao seu uso. Por isso, dependendo do protocolo avaliado, pode ser necessária uma verificação posterior da vulnerabilidade do serviço.</MatchText></p>
                    <h3>
<MatchText id="match-34">Análise de protocolos de interesse para o estudo</MatchText></h3>
                    <p>
<MatchText id="match-35"> Embora o ideal seja erradicar todos os protocolos vulneráveis a ataques de amplificação, foram selecionados aqueles que podem representar maior risco para a segurança e estabilidade da Internet. Para a seleção, foram levados em consideração:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-36">Os protocolos reflexivos e amplificáveis que são conhecidos por serem usados nesses problemas</MatchText></li>
                        <li>
<MatchText id="match-37">Novos protocolos vulneráveis observados nos últimos anos.</MatchText></li>
                        <li>
<MatchText id="match-38">O fator de amplificação possível que cada protocolo pode oferecer a um atacante.</MatchText></li>
                        <li>
<MatchText id="match-39">O número de dispositivos vulneráveis potenciais na Internet</MatchText></li>
                        <li>
<MatchText id="match-40">As fontes de informação com as quais se pode contar</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-41"> Como ponto de partida, tomamos os serviços que em princípio podem ser considerados os expoentes mais claros deste problema. Com eles, foi usado o SHODAN, para ter uma estimativa rápida do número de dispositivos que expõem esse serviço à Internet. Combinando essas informações com o fator de amplificação de cada um, foi gerada a seguinte tabela:</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r2_pt.png" /> </figure>
                    <p>
<MatchText id="match-42"> A seguir, algumas observações desses resultados:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-43">Nem todos os dispositivos estimados por Shodan são reflexivos. Os seguintes protocolos podem funcionar tanto no UDP quanto no TCP: Memcached, DNS, WS-Discovery, CharGEN, SNMP e QOTD.</MatchText></li>
                        <li>
<MatchText id="match-44">Devido a que com o Shodan não podemos consultar sobre aqueles que são executados exclusivamente no UDP, em princípio, não temos certeza de quantos deles podem ser usados para montar ataques de DDoS, o que é de interesse para este estudo.</MatchText></li>
                        <li>
<MatchText id="match-45">Os protocolos informados por Shodan não distinguem versões. Isso, no caso particular do SNMP, é de interesse, uma vez que a versão vulnerável é a 2c, que permite que o comando GetBulk seja usado de forma reflexiva para alcançar fatores de amplificação elevados.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-46"> Além disso, foram usadas para a avaliação informações de diferentes fontes, a fim de contrastar o risco que cada um desses protocolos representa hoje. Nesse sentido, embora todos os problemas sejam graves, vale ressaltar que:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-47">Durante 2018 um estudo desenvolvido por um projeto financiado pela União Europeia [https://sissden.eu/blog/amp2018] mostrava nos resultados de uma rede de honeypot que os ataques de DDoS observados evidenciaram a validade e relevância do uso de protocolos como CharGen, DNS, NTP e SSDP por parte dos atacantes.</MatchText></li>
                        <li>
<MatchText id="match-48">Foram analisados os dados públicos da fundação Shadowserver. A fundação Shadowserver coleta e analisa dados sobre atividades maliciosas da Internet. Em particular, foram usadas estatísticas públicas globais e nacionais sobre diferentes protocolos que podem ser usados em ataques de DDoS.</MatchText></li>
                        <li>
<MatchText id="match-49">O protocolo Memcached estourou na cena dos serviços reflexivos e amplificáveis em fevereiro de 2018, quando foi usado para realizar, até então, o maior ataque volumétrico de DDoS com o qual Akamai experimentou 1.3. TBps sobre um de seus clientes[1.3]. A partir desse momento, o problema se tornou visível e a comunidade foi devidamente informada. Como resultado disso, podemos ver estatísticas feitas pela fundação Shadowserver que mostram o número de servidores Memcached sobre UDP observados na Internet.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-50"> O gráfico começa quando o ataque DDoS descrito aconteceu. A partir daí, a descida é visível. Os dados disponíveis de 2020, os últimos publicados pela Shadowserver, mostram melhor a escala sobre o número de servidores reflexivos deste protocolo:</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r5_es.png" /> </figure>
                    <p>
<MatchText id="match-51"> Com relação ao CoAP, protocolo que se espera seja encontrado em diferentes dispositivos da Internet das Coisas (IoT), existe um projeto da União Europeia denominado VARIoT que tenta classificar dispositivos vulneráveis expostos à Internet. A partir dos resultados relatados no projeto, a grande maioria dos dispositivos vulneráveis são encontrados nas Filipinas, Rússia e China[10]. Essas informações também foram contrastadas com Shodan, que mostrou que na região do LACNIC não há um número significativo de dispositivos que implementem CoAP.</MatchText></p>
                    <figure><img alt="Ver"  src="/images/vulnerabilidades-ataques-ddos/r6_es.png" /> </figure>
                    <p>
<MatchText id="match-52"> Por último, no âmbito da avaliação dos protocolos de interesse, foi considerada a forma de determinar se o serviço exposto é vulnerável. Nesse sentido, foi estudado para cada um dos protocolos analisados, se basta fazer um requerimento válido para determinar, com base na resposta, se o serviço é vulnerável.</MatchText></p>
                    <p>
<MatchText id="match-53"> Para o WS-Discovery um comando malformado deve ser enviado para constatar a vulnerabilidade do serviço exposto. Já para o resto dos protocolos existem comandos válidos que permitem confirmar a vulnerabilidade do serviço sem comprometer o seu funcionamento normal. Não consideramos adequado incluir o protocolo WS-Discovery no estudo, uma vez que não é possível garantir a segurança dos testes realizados com dispositivos deste tipo.</MatchText></p>
                    <p>
<MatchText id="match-54"> Em função desses resultados, determina-se que é pertinente analisar e avaliar o problema na região, com foco na detecção, conscientização e mitigação de instâncias de serviços vulneráveis dos protocolos: NTP, DNS, CharGen e SSDP.</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Analisis;