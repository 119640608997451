
import React, { useContext } from "react";
import { LangContext } from "./context/DataContext"; 
import { Routes, Route } from "react-router-dom";
import './App.css';
import Index from './pages/Index';
import Interconexion from './pages/Interconexion/Interconexion';
import NoMatch from './components/NoMatch' 
import Ciberseguridad from "./pages/Ciberseguridad/Ciberseguridad";
import IPv6 from "./pages/IPv6/IPv6";


export default function App() { 
  const { datalang } = useContext(LangContext)
  return (
    <div>
      <Routes>
        {/* Página de Inicio */}
        <Route path={`/`} element={<Index />}>
          <Route path={`/:slug`} element={<Index />} />
        </Route>
        {/* Ciberseguridad */}
        {datalang.map((item) =>
          <Route key={`${item.languaje}`} path={`/${item.languaje}/ciberseguridad`} element={<Ciberseguridad />}>
            <Route  path=":slug" element={<Ciberseguridad footer='reports'/>} />
          </Route>
        )}
        {/* Interconexión */}
        {datalang.map((item) =>
          <Route key={`${item.languaje}`} path={`/${item.languaje}/interconexion`} element={<Interconexion />}>
            <Route path=":slug" element={<Interconexion footer='reports'/>} />
          </Route>
        )}
        {/* IPv6 */}
        {datalang.map((item) =>
          <Route key={`${item.languaje}`} path={`/${item.languaje}/ipv6`} element={<IPv6 />}>
            <Route path=":slug" element={<IPv6 footer='reports' />} />
          </Route>
        )}
         
         
         
        {/* Not Found Page */}
        <Route path="*" element={<NoMatch />} />
      </Routes> 
    </div>
  )
}
