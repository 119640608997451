import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'

function Conclusiones(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p8">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-125">Conclusiones</MatchText></h2>
                    <p>
                        <MatchText id="match-126">El estudio cumplió su objetivo, ya que se pudieron mitigar vulnerabilidades en la región, lo cual mejora el nivel de seguridad de internet. Asimismo, la metodología usada propició la interacción entre el CSIRT de LACNIC y sus asociados en temas de seguridad, fortaleciendo el rol del equipo para compartir herramientas que permitan a las organizaciones miembros de LACNIC la prestación de un mejor servicio.</MatchText></p>
                    <p>
                        <MatchText id="match-127">En el relevamiento final se pudo observar la aparición de nuevas organizaciones con un gran volumen de vulnerabilidades que no estaban presentes en relevamientos previos. Esto refleja que acciones como las promovidas durante este estudio son siempre necesarias, ya que aparecen otros actores y, con ellos, idénticas o nuevas situaciones de ciberseguridad.</MatchText></p>
                    <p>
                        <MatchText id="match-128">Se propone realizar un webinar donde se explique la problemática, se ofrezca la colaboración para mitigar las vulnerabilidades asociadas y se discutan acciones posibles para mejorar el nivel de seguridad de la región.</MatchText></p>
                    <p>
                        <MatchText id="match-129">Se propone realizar un webinar donde se explique la problemática, se ofrezca la colaboración para mitigar las vulnerabilidades asociadas y se discutan acciones posibles para mejorar el nivel de seguridad de la región.</MatchText></p>
                    <p>
                        <MatchText id="match-130">Recomendamos también a LACNIC CSIRT impulsar más proyectos de este tipo para estudiar problemas de seguridad en la región. Por lo general, suelen estar concentrados en unas pocas organizaciones, por lo que trabajar mancomunadamente ayuda a mitigarlos.</MatchText></p>
                    <p>
                        <MatchText id="match-131">Por último, creemos importante difundir en la comunidad servicios como los provistos por Shadowserver. La exposición que estas fuentes de información realizan no solo ayuda a generar conciencia sobre la importancia de la ciberseguridad en las organizaciones, sino también a pensar en la necesidad de tener un CSIRT que aborde estas problemáticas.</MatchText></p>

                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-125">Conclusions</MatchText></h2>
                    <p>
                        <MatchText id="match-126">The study met its objective, as the it allowed mitigating vulnerabilities and therefore improving regional Internet security levels. Likewise, the methodology used by the study promoted interaction between LACNIC CSIRT and its members on security issues, strengthening LACNIC CSIRT's role in sharing tools that allow LACNIC member organizations to provide better services.</MatchText></p>
                    <p>
                        <MatchText id="match-127">The final survey showed organizations with large amounts of vulnerabilities that were not detected in prior surveys. This reflects the fact that actions such as those promoted during this study are always necessary, as additional actors may appear and, with them, new or identical cybersecurity issues.</MatchText></p>
                    <p>
                        <MatchText id="match-128">We propose organizing a webinar to explain these issues, offer support to mitigate related vulnerabilities, and discuss potential actions to increase the level of regional security.</MatchText></p>
                    <p>
                        <MatchText id="match-129">We also recommend that LACNIC CSIRT promote more projects of this type to study security issues across the region. These issues are usually concentrated in a few organizations, so working together contributes to their mitigation.</MatchText></p>
                    <p>
                        <MatchText id="match-130">Finally, we believe it is important to disseminate among the community services such as those provided by Shadowserver. The exposure generated by these sources of information not only helps to raise awareness about the importance of cybersecurity within each organization, but also to consider the need to have a CSIRT to address these issues.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-125">Conclusões</MatchText></h2>
                    <p>
                        <MatchText id="match-126">O estudo cumpriu seu objetivo, pois foi possível mitigar vulnerabilidades na região, o que melhora o nível de segurança da internet. Assim mesmo, a metodologia usada fomentou a interação entre o CSIRT do LACNIC e seus associados em questões de segurança, fortalecendo o papel da equipe de compartilhar ferramentas que permitam às organizações associadas do LACNIC prestar um melhor serviço.</MatchText></p>
                    <p>
                        <MatchText id="match-127">No levantamento final, pôde-se observar o surgimento de novas organizações com grande volume de vulnerabilidades que não estavam presentes em levantamentos anteriores. Isso reflete que ações como as promovidas durante este estudo são sempre necessárias, uma vez que aparecem outros atores e, com eles, situações de segurança cibernética idênticas ou novas.</MatchText></p>
                    <p>
                        <MatchText id="match-128">Propõe-se a realização de um webinar em que o problema seja explicado, seja oferecida a colaboração para mitigar as vulnerabilidades associadas e sejam discutidas ações possíveis para melhorar o nível de segurança da região.</MatchText></p>
                    <p>
                        <MatchText id="match-129">Também recomendamos ao LACNIC CSIRT que promova mais projetos deste tipo para estudar os problemas de segurança na região. Geralmente, estão concentrados em umas poucas organizações, portanto, trabalhar juntos ajuda a mitigálos.</MatchText></p>
                    <p>
                        <MatchText id="match-130">Por último, acreditamos que é importante divulgar serviços como os prestados por Shadowserver na comunidade. A exposição que essas fontes de informação proporcionam não apenas ajuda a conscientizar sobre a importância da segurança cibernética nas organizações, mas também a pensar na necessidade de se ter um CSIRT que aborde esses problemas.</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Conclusiones;