import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';


function Conclusiones(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p9">
                {myLang === 'es' && <>
                    <h2>
<MatchText id="match-95">Conclusiones</MatchText></h2>
                    <p>
<MatchText id="match-96">En resumen, podemos ver que hay un grupo importante de pa&iacute;ses (que incluye algunos de los pa&iacute;ses con mayor poblaci&oacute;n y desarrollo de Internet de la regi&oacute;n) que en 2020 presentan una mejora importante respecto de 2017, donde la mediana de la latencia medida mejora en 49 ms.</MatchText></p>
                    <p>
<MatchText id="match-97">Si se considera el subconjunto de redes (Sistemas Aut&oacute;nomos) que se midieron en ambas campa&ntilde;as, se observan mejoras. Adem&aacute;s si se consideran los datos en t&eacute;rminos relativos a las distancias geogr&aacute;ficas (velocidad de ping), tambi&eacute;n se observan mejoras.</MatchText></p>
                    <p>
<MatchText id="match-98">Otro aspecto importante es que esta campa&ntilde;a de mediciones de latencia incluy&oacute; dejar mediciones activas corriendo de forma permanente. De esta forma se podr&aacute; comparar, con la misma metodolog&iacute;a, mediciones futuras de la conectividad regional. Adem&aacute;s, a partir de esta campa&ntilde;a y utilizando la misma metodolog&iacute;a, tambi&eacute;n se comenzaron a recolectar traceroutes.</MatchText></p>
                    <p>
<MatchText id="match-99">Queda planteado para un futuro estudio complementar este an&aacute;lisis con datos sobre interconexi&oacute;n de redes entre este grupo de pa&iacute;ses a nivel de tablas de BGP.</MatchText></p>
                    <h3>
<MatchText id="match-100">Notas finales</MatchText></h3>
                    <p>
<MatchText id="match-101">Pueden encontrar el an&aacute;lisis que soporta este estudio en el&nbsp;</MatchText>
                    <a href="https://github.com/LACNIC/simon/blob/master/estudios/LACNOG 2017 Revisitado.ipynb" target="_blank" rel="noreferrer">
<MatchText id="match-102">GitHub de LACNIC Labs&nbsp;.</MatchText></a></p>
                    <p>
<MatchText id="match-103">Las mediciones que soportan el estudio forman parte del&nbsp;</MatchText>
                    <a href="http://simon.lacnic.net/" target="_blank" rel="noreferrer">
<MatchText id="match-104">proyecto SIMON&nbsp;</MatchText></a>
                    
<MatchText id="match-105">de LACNIC. Se pueden descargar&nbsp;</MatchText>
                    <a href="http://simon.lacnic.net/static/results-lac-connectivity.json" target="_blank" rel="noreferrer">
                        
<MatchText id="match-106">aqu&iacute;&nbsp;.</MatchText>
                        </a></p>
                </>}
                {myLang === 'en' && <>
                    <h2>
<MatchText id="match-95">Conclusions</MatchText></h2>
                    <p>
<MatchText id="match-96">In summary, we can see that there is an important group of countries (which includes some of the countries with the largest populations and Internet development in the region) which, in 2020, exhibit a significant improvement with regard to 2017, as the median measured latency improved by 49 ms.</MatchText></p>
                    <p>
<MatchText id="match-97">Improvements can be observed if one considers the subset of networks (autonomous systems) that were measured in both campaigns. Furthermore, if the data is considered in terms of their geographic distance (ping speed), improvements can also be observed.</MatchText></p>
                    <p>
<MatchText id="match-98">Another aspect worth noting is that this latency measurement campaign left active measurements running permanently. This will allow using the same methodology to compare future regional connectivity measurements. Likewise, traceroutes started to be collected during this campaign using the same methodology.</MatchText></p>
                    <p>
<MatchText id="match-99">It is proposed that a future study might complement this analysis with interconnection data for networks in this group of countries at BGP table level.</MatchText></p>
                    <h3>
<MatchText id="match-99">Final Notes</MatchText></h3>
                    <p>
<MatchText id="match-100">The analysis supporting this study is available at the </MatchText>
                    <a data-cke-saved-href="https://github.com/LACNIC/simon/blob/master/estudios/LACNOG 2017 Revisitado.ipynb" 
                    href="https://github.com/LACNIC/simon/blob/master/estudios/LACNOG%202017%20Revisitado.ipynb" 
                    target="_blank" rel="noreferrer">
                        
<MatchText id="match-101">LACNIC Labs GitHub. </MatchText></a></p>
                    <p>
<MatchText id="match-102">The measurements supporting this study are part of LACNIC's </MatchText>
                    <a data-cke-saved-href="http://simon.lacnic.net/" href="http://simon.lacnic.net/" target="_blank" rel="noreferrer">
                        
<MatchText id="match-103">SIMON project. Click</MatchText></a>
                        <a data-cke-saved-href="http://simon.lacnic.net/static/results-lac-connectivity.json" 
                        href="http://simon.lacnic.net/static/results-lac-connectivity.json" target="_blank" 
                        rel="noreferrer">
<MatchText id="match-104"> here </MatchText></a>
                        
<MatchText id="match-105">to download the measurements.</MatchText></p>
                </>}
                {myLang === 'pt' && <>
                    <h2>
<MatchText id="match-95">Conclusões</MatchText></h2>
                    <p>
<MatchText id="match-96">Em resumo, podemos ver que existe um grupo importante de países (que inclui alguns dos países com mais população e desenvolvimento da Internet na região) que em 2020 apresenta uma melhoria significativa em relação a 2017, em que a mediana da latência medida melhora em 49 ms.</MatchText></p>
                    <p>
<MatchText id="match-97">Se considerarmos o subconjunto de redes (Sistemas Autônomos) que foram medidas nas duas campanhas, são observadas melhorias. Além disso, se os dados forem considerados em termos relativos às distâncias geográficas (velocidade de ping), também são observadas melhorias.</MatchText></p>
                    <p>
<MatchText id="match-98">Outro aspecto importante é que esta campanha de medições de latência incluiu deixar as medições ativas em execução permanente. Desta forma, futuras medições da conectividade regional poderão ser comparadas com a mesma metodologia. Além disso, a partir desta campanha e usando a mesma metodologia, também foram coletados traceroutes.</MatchText></p>
                    <p>
<MatchText id="match-99">Propõe-se para um estudo futuro complementar esta análise com dados sobre a interconexão de redes entre este grupo de países ao nível das tabelas do BGP.</MatchText></p>
                    <h3>
<MatchText id="match-100">Notas finais</MatchText></h3>
                    <p>
<MatchText id="match-101">Confira a análise que suporta este estudo no  </MatchText>
                    <a href="https://github.com/LACNIC/simon/blob/master/estudios/LACNOG 2017 Revisitado.ipynb" 
                    target="_blank" rel="noreferrer">
                        
<MatchText id="match-102">GitHub de LACNIC Labs.</MatchText> 
                    </a></p>
                    <p>
<MatchText id="match-103">As medições que suportam o estudo fazem parte do  </MatchText>
                    <a href="http://simon.lacnic.net/" target="_blank" rel="noreferrer">
                        
<MatchText id="match-104">projeto SIMON </MatchText></a>
                        
<MatchText id="match-105">do LACNIC. Faça o download </MatchText>
                        <a href="http://simon.lacnic.net/static/results-lac-connectivity.json" target="_blank" rel="noreferrer">
<MatchText id="match-106">aqui</MatchText></a></p>

                </>}
        </div>
        </div >
    );
}

export default Conclusiones;