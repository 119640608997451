import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

const Resumen = props => {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p1">
                {myLang === 'es' && <>
                    <h2>Resumen ejecutivo</h2>
                    <p>Este estudio logr&oacute; identificar fuentes de informaci&oacute;n para visibilizar la correcta implementaci&oacute;n de buenas pr&aacute;cticas para mitigar el IP <em>spoofing</em> en redes de la regi&oacute;n de Am&eacute;rica Latina y el Caribe. A partir del an&aacute;lisis de los datos obtenidos, se recomiendan acciones para que las organizaciones puedan detectar y mitigar este problema. De esta forma no solo se da a conocer el estado de adhesi&oacute;n a las buenas pr&aacute;cticas, sino tambi&eacute;n se muestra que es posible mejorar el estado de la regi&oacute;n para prevenir el spoofing de direcciones IP.</p>
                    <p>Estas estad&iacute;sticas brindan al CSIRT de LACNIC informaci&oacute;n para trabajar directamente con las organizaciones asociadas y reducir los riesgos de que los recursos asignados a &eacute;stas sean utilizados con fines maliciosos.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Executive Summary</h2>
                    <p>This study managed to identify sources of information to assess the proper implementation of good practices to mitigate IP spoofing in networks across Latin America and the Caribbean. Based on the analysis of the data we obtained, this document recommends actions that will allow organizations to detect and mitigate this problem. Thus, the report does not only show the status of adherence to good practices, but it also shows that it is possible to improve IP address spoofing prevention in the region.&nbsp;</p>
                    <p>These statistics provide LACNIC CSIRT with information to work directly with member organizations and reduce the risk of the resources assigned to them being used for malicious purposes.</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Resumo executivo</h2>
                    <p>Este estudo conseguiu identificar fontes de informa&ccedil;&atilde;o para visibilizar a implementa&ccedil;&atilde;o correta de boas pr&aacute;ticas, e mitigar o IP <em>spoofing</em> nas redes da regi&atilde;o da Am&eacute;rica Latina e o Caribe. Com base na an&aacute;lise dos dados obtidos, s&atilde;o recomendadas a&ccedil;&otilde;es para que as organiza&ccedil;&otilde;es possam detectar e mitigar esse problema. Assim, n&atilde;o s&oacute; &eacute; conhecido o estado de ades&atilde;o &agrave;s boas pr&aacute;ticas, mas tamb&eacute;m &eacute; demonstrado que &eacute; poss&iacute;vel melhorar o estado da regi&atilde;o para prevenir o <em>spoofing</em> de endere&ccedil;os IP.</p>
                    <p>Estas estat&iacute;sticas fornecem informa&ccedil;&otilde;es ao CSIRT do LACNIC para trabalhar diretamente com as organiza&ccedil;&otilde;es associadas e reduzir o risco de que os recursos designados a estas sejam usados para fins maliciosos.</p>
                </>}
            </div>
        </div>
    );
}

export default Resumen;