import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function SobreElEstudio() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p9">
                {myLang === 'es' && <>
                    <h2>Sobre el estudio</h2>
                    <p>Este estudio fue realizado por el investigador Hugo Salgado, bajo encargo y direcci&oacute;n de LACNIC, entre los meses de junio y septiembre del a&ntilde;o 2019. Hugo Salgado es un experto en DNS y nombres de dominio; y trabaja en NIC Chile, el registro del ccTLD .CL.</p>
                    <p>Fueron muy importantes los comentarios y sugerencias de Guillermo Cicileo, Responsable del Programa de Seguridad y Estabilidad de Internet de LACNIC.</p>
                    <p>Si desea m&aacute;s informaci&oacute;n del proyecto RIPE Atlas que permiti&oacute; obtener los datos para este informe, u hospedar una sonda de mediciones, por favor contacte a <a href="https://atlas.ripe.net/" target="blank" rel="noreferrer">https://atlas.ripe.net/</a>.</p>
                    <p>Si su organizaci&oacute;n desea hospedar un root server, por favor contacte al Proyecto +RAICES de LACNIC en <a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a>.</p>
                    <p>Derechos protegidos por LACNIC. Permitida su reproducci&oacute;n y distribuci&oacute;n manteniendo atribuciones y referencia del informe original. Este informe y sus actualizaciones se podr&aacute;n encontrar en el sitio del Proyecto +RAICES de LACNIC en <a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a>.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>About This Study</h2>
                    <p>This research study was conducted by Hugo Salgado at the request of and with the supervision of LACNIC between the months of June and September of 2019. Hugo Salgado is an expert on topics relating to the DNS and domain names and works at NIC Chile, the .cl ccTLD registry.</p>
                    <p>The comments and suggestions by Guillermo Cicileo, Head of LACNIC's Internet Security and Stability Program, were also very important.</p>
                    <p>For more information on RIPE Atlas — the project that allowed obtaining the data for this report — or if you would like to host a measurement probe, please contact <a href="https://atlas.ripe.net/" target="_blank" rel="noreferrer">https://atlas.ripe.net/</a>.</p>
                    <p>If your organization would like to host a root server copy, please contact LACNIC's +RAICES Project at <a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="_blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a>.</p>
                    <p>Copyright LACNIC. Users are allowed to reproduce and distribute this publication, provided that attribution and reference to the original report are maintained. This report and its updates will be available on the website of LACNIC’s +RAICES Project at <a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="_blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a>.</p>

                </>}
            </div>

        </div>
    )
}

export default SobreElEstudio

