import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import {Link} from 'react-router-dom' 

function InterconexionBgpEnLaRegion(props) {
    const { myLang } = useContext(LangContext)
    return (   
        <div>    
            <div>
                {myLang === 'es' && <> 
                    <h1>Interconexión en BGP en la región de América Latina y el Caribe</h1>
                    <p>Autor: Augusto Mathurín</p>
                    <p>Para complementar la información obtenida con el Proyecto Simón, con este estudio de interconexión se busca conocer la situación actual de las conexiones entre los países latinoamericanos a partir de una fuente distinta de información: las tablas de ruteo BGP registradas y almacenadas por colectores globales.</p>
                    <Link className="buttons button_blue" to="/es/interconexion/interconexion-bgp-en-lac">Leer Reporte</Link>

                </>}
                {myLang === 'en' && <> 
                    <h1>BGP Interconnection in the Region of Latin America and the Caribbean</h1>
                    <p>Author: Augusto Mathurín</p>
                    <p>To complement the information obtained by the Simón Project, this interconnection study will attempt to understand the status of the connections between the countries of Latin America based on the BGP routing tables registered and stored by global collectors.</p>
                    <Link className="buttons button_blue" to="/en/interconexion/interconexion-bgp-en-lac">Read Report</Link> 
                </>}
                {myLang === 'pt' && <> 
                    <h1>Interconexão no BGP na região da América Latina e o Caribe</h1>
                    <p>Autor: Augusto Mathurín</p>
                    <p>Para complementar a informação obtida com o Projeto Simón, este estudo de interconexão buscará conhecer a situação atual das conexões entre os países da América Latina a partir de uma fonte diferente de informações: as tabelas de roteamento BGP, cadastradas e armazenadas por coletores globais.</p>
                    <Link className="buttons button_blue" to="/pt/interconexion/interconexion-bgp-en-lac">Leia o Relatório</Link>  
                </>}
            </div>
        </div>  
    );
}


 
export default InterconexionBgpEnLaRegion;