import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'; 

function Descripcion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p1">
                {myLang === 'es' && <Contenido />}
                {myLang === 'en' && <Contenido />}
                {myLang === 'pt' && <Contenido />}

            </div>
        </div>
    )
}
function Contenido() {
    return (<> 
        <h2><MatchText id="match-7" >Descripción del problema</MatchText></h2>
        <p><MatchText id="match-8" >Internet es una federaci&oacute;n de islas administrativas o Sistemas Aut&oacute;nomos (AS) compartiendo informaci&oacute;n, donde cada AS tiene direcciones IP global y un&iacute;vocamente asignadas.</MatchText> </p>
        <p><MatchText id="match-9" >La conectividad de la red requiere que todos sus routers tengan los elementos para encaminar los paquetes IP, esto es: de acuerdo con la direcci&oacute;n de destino de cualquier paquete en curso, elegir por cu&aacute;l de sus conexiones debe el router enviar ese paquete hacia su destinatario.</MatchText> </p>
        <p><MatchText id="match-10" >El protocolo BGP es el responsable de mantener globalmente actualizada la correspondencia entre ASes y direcciones, una base de datos global y distribuida, imprescindible para sostener el enrutamiento en Internet. BGP no s&oacute;lo se utiliza entre sistemas aut&oacute;nomos, sino que tambi&eacute;n cumple con una funci&oacute;n clave en propagar la informaci&oacute;n de alcanzabilidad de red (NRLI) dentro de cada sistema aut&oacute;nomo (BGP interno o iBGP)</MatchText> </p>
        <p><MatchText id="match-11" >El buen dise&ntilde;o de una malla iBGP dentro de un sistema aut&oacute;nomo es fundamental para aprovechar de manera &oacute;ptima los caminos disponibles. Este aprovechamiento redunda para los operadores de Internet en una optimizaci&oacute;n de sus costos as&iacute; como tambi&eacute;n en una mejora de la calidad de servicio percibida por sus usuarios.</MatchText> </p>
        <p><MatchText id="match-12" >Sin embargo, este buen dise&ntilde;o es un problema que no cuenta con una soluci&oacute;n bien definida. Tradicionalmente los operadores han realizado esta optimizaci&oacute;n manualmente basados en su experiencia, apoy&aacute;ndose en ensayos de prueba y error.&nbsp;</MatchText> </p>
        <p><MatchText id="match-13" >En este documento introducimos un trabajo de investigaci&oacute;n que busca lograr una aproximaci&oacute;n sistem&aacute;tica para este problema del dise&ntilde;o de la malla iBGP, aproximaci&oacute;n a la que llamamos &ldquo;Clases de Equivalencia de Prefijos en BGP&rdquo;. Mostraremos c&oacute;mo esta t&eacute;cnica podr&iacute;a ayudar a un operador a optimizar su red.</MatchText> </p>
        <p><MatchText id="match-14" >Finalmente nos gustar&iacute;a solicitar la colaboraci&oacute;n de la comunidad de operadores de LACNOG para poder continuar refinando las t&eacute;cnicas aqu&iacute; propuestas utilizando datos reales de redes en producci&oacute;n.</MatchText> </p>
        <div className='figure_doble'>
            <figure>
                <img alt="Ver" src="/images/optimizacion-de-redes-mediante-un-mejor-ibgp/figure1.png" />
            </figure>
            <figure>
                <img alt="Ver" src="/images/optimizacion-de-redes-mediante-un-mejor-ibgp/figure2.png" />
            </figure>
        </div>
    </>)
}

export default Descripcion

