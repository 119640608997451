import React, { useContext } from 'react'
import Infraestructura from './Infraestructura'
import {LangContext} from '../../../context/DataContext'; 
import Servicio from './Servicio';
import Operacion from './Operacion';
import Gobernanza from './Gobernanza';
import Comunidad from './Comunidad';
import ResumenMejoresPractivasParaIXP from './ResumenMejoresPractivasParaIXP';

export default function MejoresPracticasParaIXP() {
    
    return ( 
        <> 
          <ReportTitle /> 
          <ResumenMejoresPractivasParaIXP />
          <Infraestructura/>
          <Servicio />
          <Operacion />
          <Gobernanza />
          <Comunidad />
        </>
      )
}
 

function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
      <h1>
          {myLang === 'es' && 'Mejores prácticas para IXP'}
          {myLang === 'en' && 'Best Practices for IXPs'}
          {myLang === 'pt' && 'Melhores práticas para IXP'}
      </h1>
      )
  }
