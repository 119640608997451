import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';


function Comparacion(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-52">Comparando 2020 con 2017</MatchText></h2>
                    <p>
                        <MatchText id="match-53">Anteriormente mencionamos que llevamos a cabo un estudio similar durante 2017. ¿Cómo se comparan los resultados obtenidos en 2020 con los anteriores? De similar forma a como se presentaron los resultados en la sección anterior, podemos ver una comparativa de las mediciones agrupadas. Este gráfico representa las mediciones que fueron originadas en cada país.</MatchText></p>
                    <p>
                        <MatchText id="match-54">De la campaña de mediciones de 2017 notamos que hay algunos países que presentan latencias muy por encima de su valor 2020. En particular, los valores para Uruguay (UY), Paraguay (PY), Chile (CL), y Bolivia (BO) del gráfico a la izquierda. Pudimos verificar que no se debe a una baja cantidad de muestras, ya que tienen entre 100 y 1000 muestras. El único país que sí tiene un sesgo de medición es Cuba (CU), que presenta únicamente 1 muestra durante 2017.</MatchText></p>
                    <p>
                        <MatchText id="match-55">Pero observando los datos más detalladamente, vemos que la mayoría de los países presenta en 2020 peores valores de latencia hacia la región, en comparación con 2017. De los 37 países del gráfico, 28 (75%) presentan valores más altos. ¿A qué se debe este incremento? Para responder esa pregunta necesitamos hacer un análisis más profundo sobre los datos.</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r7_color.jpg" /></figure>
                    <p>
                        <MatchText id="match-56">Nos preguntamos: ¿será posible que los países hayan empeorado su conectividad? Nos cuestionamos esa posibilidad, y nos preguntamos: ¿será posible que las mediciones que hemos realizado en 2020 estén recorriendo distancias más grandes que las de 2017? Es una posibilidad, sobre todo si tenemos en cuenta que:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-57">Los puntos de prueba de 2020 no son los mismos que los de 2017: ¡se dan de alta y de baja nodos de Speedtest todos los días!</MatchText></li>
                        <li>
                            <MatchText id="match-58">Cada medición que agendamos elige de forma aleatoria el punto de prueba: existe una componente aleatoria que es fuerte al comienzo del experimento, cuando aún no hemos tenido una cantidad de muestras estadísticamente significante. ¿Podrá ser que de hecho se haya elegido puntos más distantes en el comienzo de este experimento?</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-59">Para responder esa pregunta utilizamos una base de datos de geolocalización para conocer el país de origen y la ciudad de destino de cada medición, y así aproximar, a través de una línea geodésica , la distancia que habría recorrido la medición. Si tomamos cada medición, le asignamos una distancia recorrida, y graficamos estas distancias para los años 2017 y 2020, obtenemos una figura como la de abajo, donde las curvas que se encuentran ubicadas más a la izquierda del gráfico representan distancias menores (distancias más cortas). Esta figura es conocida como una CDF , y en este caso representa la fracción de mediciones que están por debajo de una determinada distancia. En el eje X tenemos representadas las distancias recorridas y en el eje Y la fracción de distancias que se encuentran por debajo del valor de las X.</MatchText></p>
                    <p>
                        <MatchText id="match-60">El hecho de que la curva roja se encuentra ubicada sobre la izquierda del gráfico nos da un indicio que el set de datos de 2017 contiene distancias más cortas, es decir, puntos que se encuentran más próximos uno de otro, por lo que es lógico que ese set de datos contenga latencias absolutas más bajas. Del gráfico se puede ver que el 50% de las mediciones más cortas de 2017 recorren sólo una distancia de 3535 km, mientras que el 50% de las mediciones más cortas de 2020 recorren una distancia de 4170 km.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r8.png" /></figure>
                    <p>
                        <MatchText id="match-61">Una vez hecha la comparación de distancias, agregamos la componente de latencias. Por ejemplo, para una distancia de 1000 km, no es lo mismo un ping de 10 ms que uno de 20 ms, consideramos que el ping de 10 ms viaja más rápido, es decir tiene mayor velocidad. La forma en que se agrega la componente de latencias es analizando la velocidad de ping, es decir la relación distancia/tiempo de la medición, frente a la velocidad de propagación de la luz en un medio de fibra óptica (una aproximación teórica común es utilizar c/3, o un tercio de la velocidad de la luz en el vacío). Esto nos da una fracción, donde un valor cercano a 1 indica que el ping viaja tan rápido como indica la teoría, y valores cercanos a 0 indican velocidades sub-óptimas (debido a caminos más largos que la línea directa, saltos intermedios que introducen retrasos, y otras posibles fuentes de demoras). En este caso, y a diferencia de la medición de latencia, tener un valor más alto es mejor.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r9.png" /></figure>
                    <p>
                        <MatchText id="match-62">Una vez introducida la corrección, calculamos la nueva métrica (velocidad de ping) e hicimos la misma comparación que antes. Podemos ver que ahora 31 de 35 países han percibido mejoras en 2020 frente a 2017 (88%), y únicamente 4 no (Bolivia, Paraguay, Perú, e Islas Turcas y Caicos). Esta nueva métrica nos ayuda a normalizar sets de datos diferentes bajo una misma métrica que los hace comparables, y así poder medir una mejora en la conectividad regional.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/ping_es.jpg" /></figure>
                </>}
                {myLang === 'en' && <>

                    <h2>
                        <MatchText id="match-52">2020 versus 2017</MatchText></h2>
                    <p>
                        <MatchText id="match-53">We already mentioned that we conducted a similar study in 2017. So, how do the results obtained in 2020 compare with the ones obtained in 2017? Just as the results presented in the previous section, it is possible to group and compare the measurements. This chart represents the measurements that originated in each country.</MatchText></p>
                    <p>
                        <MatchText id="match-54">The 2017 measurement campaign shows that latencies in certain countries were much higher than those measured in 2020, particularly in Uruguay (UY), Paraguay (PY), Chile (CL) and Bolivia (BO), as can be seen in the graph to the left. We were able to verify that this was not due to a small number of samples, as they had between 100 and 1000 samples. The only country with a measurement bias is Cuba (CU), which had only 1 sample during 2017.</MatchText></p>
                    <p>
                        <MatchText id="match-55">Nevertheless, a more detailed analysis of the data shows that most of the countries had worse latency values toward the region than in 2017. Of the 37 countries included in the chart, 28 (75%) had higher values. But what is the reason for this increase? To answer this question, we had to perform a more in-depth analysis of the data.</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r7_color.jpg" /></figure>
                    <p>
                        <MatchText id="match-56">We asked ourselves the following question: Is it possible that connectivity is now worse in these countries? We analyzed this possibility and proposed the following hypothesis: Could it be that the measurements we performed in 2020 covered longer distances than those covered in 2017? This is a possibility, particularly considering that:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-57">The vantage points of 2020 were not the same as the ones of 2017: Speedtest nodes are enabled and disabled daily!</MatchText></li>
                        <li>
                            <MatchText id="match-58">Every measurement we schedule randomly selects a vantage point, so there is a strong random element at the beginning of the experiment, when we do not yet have a statistically significant number of samples. Could it be that the points selected at the start of this experiment were farther away?</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-59">To answer this question, we used a geolocation database1 to determine the country of origin and destination city for each measurement. Then, considering geodesics2, we determined the approximate distance traveled by each measurement. If we assign each measurement the distance it traveled and represent these distances for the years 2017 and 2020, we obtain a plot like the one below, where the lines located farther to the left represent smaller (shorter) distances. This is known as a cumulative distribution function (CDF)3 and, in this case, it represents the fraction of measurements that are below a certain distance. The x-axis represents the distances traveled by the measurements; the y-axis, the fraction of distances that are below the value of X.</MatchText></p>
                    <p>
                        <MatchText id="match-60">The fact that the red line is located to the left of the graph means that the 2017 dataset contains shorter distances, i.e., points that are closer to each other, so it follows that the dataset contains lower absolute latencies. The graph shows that, in 2017, the 50% corresponding to the shortest measurements covered a distance of only 3,535 km, while, in 2020, 50% of these measurements covered a distance of 4,170 km.</MatchText></p>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r8.png" /></figure> </div>
                        <div>
                            <p>
                                <MatchText id="match-61">Once the distances had been compared, we added the latency component. For example, for a distance of 1,000 km, a 10 ms ping is not the same as a 20 ms ping — we consider that the 10 ms ping travels faster, i.e., it has greater speed. The latency component was added by analyzing the ping speed, that is, the distance-time relationship of the measurement vs. the speed of light through a fiber-optic medium (an approximation commonly used is c/3, one-third of the speed of light in vacuum). The result is a fraction, where a value close to 1 means that the ping travels as fast in practice as in theory, and values close to 0 mean suboptimal speeds (resulting from paths that are longer than a direct line, intermediate hops, and other potential sources of delay). In this case, unlike latency measurements, higher values are better.</MatchText></p>
                        </div>
                    </div>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r9.png" /></figure> </div>
                        <div>
                            <p>
                                <MatchText id="match-62">Once the correction was introduced, we calculated the new metric (ping speed) and performed the same comparison once again. As we can see, 31 out of 35 countries show improvements in 2020 compared to 2017 (88%), and only 4 do not (Bolivia, Paraguay, Peru, and the Turks and Caicos Islands). This new metric helps us to normalize different datasets under a single metric that allows us to compare them and thus measure an improvement in regional connectivity.</MatchText></p>
                        </div>
                    </div>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/ping_en.jpg" /></figure>
                        </div>
                    </div>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-52">Comparando 2020 com 2017</MatchText></h2>
                    <p>
                        <MatchText id="match-53">Mencionamos anteriormente que fizemos um estudo semelhante em 2017. Como os resultados obtidos em 2020 se comparam aos anteriores? Semelhante à forma como os resultados foram apresentados na seção anterior, podemos ver uma comparação das medições agrupadas. Este gráfico representa as medições que foram originadas em cada país.</MatchText></p>
                    <p>
                        <MatchText id="match-54">A partir da campanha de medição de 2017, notamos que existem alguns países que apresentam latências bem acima do seu valor de 2020. Em particular, os valores para o Uruguai (UY), Paraguai (PY), Chile (CL), e Bolívia (BO) do gráfico à esquerda. Pudemos constatar que não é devido a um baixo número de amostras, pois já têm entre 100 e 1000 amostras. O único país que sim tem um viés de medição é Cuba (CU), que apresenta apenas 1 amostra em 2017.</MatchText></p>
                    <p>
                        <MatchText id="match-55">Mas, olhando os dados com mais detalhe, vemos que a maioria dos países tem valores de latência piores para a região em 2020, em comparação com 2017. Dos 37 países do gráfico, 28 (75%) apresentam valores mais elevados. Qual é o motivo desse aumento? Para responder essa pergunta, precisamos fazer uma análise mais profunda dos dados.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r7_color.jpg" /></figure>
                    <p>
                        <MatchText id="match-56">A gente se pergunta: Será que os países pioraram sua conectividade? Questionamos essa possibilidade e nos perguntamos: Será que as medições que fizemos em 2020 estão cobrindo distâncias maiores do que as de 2017? É uma possibilidade, principalmente se levarmos em conta que:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-57">Os pontos de teste de 2020 não são os mesmos do que em 2017: os nós do Speedtest são registrados e cancelados todos os dias!</MatchText></li>
                        <li>
                            <MatchText id="match-58">Cada medição que agendamos escolhe aleatoriamente o ponto de teste; há um componente aleatório que é forte no início do experimento, quando ainda não tivemos um número estatisticamente significativo de amostras. Será que pontos mais distantes foram de fato escolhidos no início deste experimento?</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-59">Para responder essa pergunta, usamos um banco de dados de geolocalização1 para conhecer o país de origem e a cidade de destino de cada medição, e assim aproximar, por meio de uma linha geodésica2, a distância que a medição teria percorrido. Se tomarmos cada medição, atribuímos a ela uma distância percorrida, e representamos graficamente essas distâncias para os anos de 2017 e 2020, obtemos uma figura como a de abaixo, em que as curvas localizadas mais à esquerda do gráfico representam distâncias menores (distâncias mais curtas). Esta figura é conhecida como uma CDF3, e, neste caso, representa a fração de medições que estão por baixo de uma determinada distância. No eixo X temos as distâncias percorridas e no eixo Y a fração das distâncias que estão por baixo do valor das X.</MatchText></p>
                    <p>
                        <MatchText id="match-60">O fato de a curva vermelha estar localizada à esquerda do gráfico nos dá uma indicação de que o conjunto de dados de 2017 contém distâncias mais curtas, ou seja, pontos mais próximos uns dos outros, pelo que é lógico que esse conjunto de dados contenha latências absolutas mais baixas. No gráfico, pode-se observar que 50% das medições mais curtas de 2017 cobrem apenas uma distância de 3535 km, enquanto 50% das medições mais curtas de 2020 cobrem uma distância de 4170 km.</MatchText></p>
                    <div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r8.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-61">Assim que a comparação de distância for feita, adicionamos o componente de latências. Por exemplo, para uma distância de 1000 km, um ping de 10 ms não é igual a um ping de 20 ms, consideramos que o ping de 10 ms viaja mais rápido, ou seja, tem maior velocidade. A forma em que é adicionado o componente de latências é analisada a velocidade do ping, ou seja, a relação distância/tempo da medição, perante a velocidade de propagação da luz em um meio de fibra óptica (uma aproximação teórica comum é usar c/3, ou um terço da velocidade da luz no vácuo). Isso nos dá uma fração, em que um valor próximo a 1 indica que o ping viaja tão rápido quanto a teoria indica, e valores próximos a 0 indicam velocidades sub-ótimas (devido a caminhos mais longos que a linha direta, saltos intermediários que introduzem atrasos e outras possíveis fontes de demoras). Nesse caso, e a diferença da medição de latência, ter um valor mais alto é melhor.</MatchText></p>
                        </div>
                    </div>
                    <div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r9.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-62">Assim que a correção foi inserida, calculamos a nova métrica (velocidade de ping) e fizemos a mesma comparação de antes. Podemos ver que agora 31 de 35 países perceberam melhorias em 2020 em comparação com 2017 (88%), e apenas 4 não (Bolívia, Paraguai, Peru e Ilhas Turcas e Caicos). Esta nova métrica ajuda a normalizar diferentes conjuntos de dados sob a mesma métrica que os torna comparáveis, e assim poder medir uma melhoria na conectividade regional.</MatchText></p>
                        </div>
                    </div>
                    <figure><img id="velocidadPING" alt="Ver" src="/images/conectividad-lac-2020/ping_pt.jpg" /></figure>

                </>}
            </div>
        </div>
    );
}

export default Comparacion;