import React, {useState} from 'react';
import { ReferenceContext } from './DataContext';  

export const ReferenceState = ( {children} ) => {  
      
 
    const [activeReference, setActiveReference] = useState(null);

    const scrollToReference = (anchorRef) => {
        if (anchorRef) {
            // encuentra el ancla
            let anchorElementRef = document.getElementById(anchorRef);
            setActiveReference(anchorRef)  
            // Si existe el punto de anclaje correspondiente al id, salta al punto de anclaje
            if (anchorElementRef) { anchorElementRef.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        } 
    }

    return (
        <ReferenceContext.Provider value={
            {
                scrollToReference, 
                activeReference
            }
          }>
             {children}
        </ReferenceContext.Provider>
    );
}

 