
import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function RootServersSuImportancia() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <>
                    <h2>Root Servers y su Importancia</h2>
                    <p>El servicio DNS es el que permite que las direcciones de sitios web, correos electr&oacute;nicos, y m&uacute;ltiples servicios en Internet, sean correctamente transformadas a direcciones “IP” y desde ah&iacute; puedan ser alcanzadas por los servidores que rutean el tr&aacute;fico. Es un servicio cr&iacute;tico y b&aacute;sico.</p>
                    <p>El &eacute;xito de este servicio, que data originalmente del a&ntilde;o 1985 y ha acompa&ntilde;ado el crecimiento exponencial de las redes y los servicios en Internet, se debe a su naturaleza esencialmente distribuida y delegativa. Distribuida porque existen m&uacute;ltiples y redundantes formas de obtenerla, lo que permite su efectividad en rapidez, performance, robustez y resiliencia. Pero adem&aacute;s es delegativa, lo que permite que distintas organizaciones sean las responsables de distintas etiquetas en el DNS, y as&iacute; cada una tiene la independencia y prerrogativa de administrar como le parezca conveniente su propio espacio.</p>
                    <p>Para lograr esto &uacute;ltimo, se defini&oacute; que el DNS ser&iacute;a una serie de etiquetas con una jerarqu&iacute;a desde una “ra&iacute;z” en la base, que se ir&iacute;a desplegando hacia niveles superiores como las ramas de un &aacute;rbol. Como la delegaci&oacute;n de jerarqu&iacute;a es desde la ra&iacute;z hacia arriba, generalmente esto se representa como un “&aacute;rbol invertido”, donde la ra&iacute;z es el origen superior y se va bajando hacias las ramas con la sub-administraci&oacute;n de cada etiqueta.</p>
                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure1.png"
                            alt="Ver" /></figure>
                        <figcaption>Fig. 1, imagen esquem&aacute;tica de la estructura de “&aacute;rbol invertido” del DNS. CC BY-SA 3.0 By Jane Kruch - Own work, <a href="https://commons.wikimedia.org/w/index.php?curid=31749683" target="blank" rel="noreferrer">https://commons.wikimedia.org/w/index.php?curid=31749683</a>.</figcaption>
                    </div>

                    <p>Esta estructura es la que ha permitido un DNS robusto y eficaz desde los primeros cientos de servidores en Internet, hasta los 4.500 millones de usuarios estimados en el a&ntilde;o 2019.</p>
                    <p>Sin embargo, existe un punto cr&iacute;tico y delicado en esta estructura: la ra&iacute;z. Por ello desde un comienzo se defini&oacute; que la ra&iacute;z del DNS ser&iacute;a distribuida entre 12 <a href="https://root-servers.org/" target="blank" rel="noreferrer">organizaciones</a>, y que cada una de ellas administrar&iacute;a una “letra” de la ra&iacute;z. El n&uacute;mero 12 no es caprichoso sino que representa el m&aacute;ximo n&uacute;mero que permit&iacute;a el tama&ntilde;o de los mensajes en ese momento. Estas 12 organizaciones mantuvieron sus servidores durante casi 20 a&ntilde;os, hasta que por el crecimiento explosivo de Internet se vi&oacute; que ser&iacute;a necesario hacer ajustes y m&aacute;s copias. Por ello se aprovech&oacute; de una caracter&iacute;stica del ruteo en Internet con una tecnolog&iacute;a bautizada como “Anycast”, que permite que cada una de estas 12 letras a su vez sea subdividida en una “nube” de nodos ubicados en distintos lugares; por lo que ya podemos seguir escalando el servicio. Es as&iacute; como por ejemplo actualmente la letra “F” del DNS, que en su momento fue 1 servidor ubicado en Californa, USA; actualmente son 241 copias en m&aacute;s de 90 pa&iacute;ses, cada una capaz de atender su regi&oacute;n.</p>
                    <p>Este esfuerzo particular de cada organizaci&oacute;n fue tambi&eacute;n apoyada por organismos regionales como LACNIC, creando el a&ntilde;o 2004 el proyecto +RAICES, que apoya la instalaci&oacute;n de copias de root servers en la regi&oacute;n LAC mediante esfuerzos de comunicaci&oacute;n de la importancia de tener copias, coordinaci&oacute;n y contacto entre organizaciones dispuestas a alojar una copia y los administradores de los root servers, e incluso apoyo financiero comprando los equipos y encarg&aacute;ndose de su instalaci&oacute;n. Es gracias a esto que +RAICES hasta la fecha ha instalado 26 copias en nuestra regi&oacute;n, y lleva a cabo un llamado anual para las distintas organizaciones que est&eacute;n dispuestas a hospedar una copia, eligiendo en un comit&eacute; las que ser&aacute;n merecedoras del apoyo.</p>


                </>}
                {myLang === 'en' && <>
                    <h2>Root Servers and their Importance</h2>
                    <p>The DNS service allows website addresses, email messages and multiple other Internet services to be correctly translated into “IP” addresses so that they can be reached by the servers routing traffic. It is a critical and essential service.</p>
                    <p>This service originally dates back to 1985 and has accompanied the exponential growth of networks and Internet services. The DNS owes its success to its essentially distributed and decentralized nature: distributed because there are multiple, redundant ways to obtain the service, which increases its effective speed, performance, robustness and resilience; and decentralized, as it allows different organizations to be responsible for different labels in the DNS so that they can each have the independence and prerogative to manage their own space as they see fit.</p>
                    <p>To achieve this, it was decided that the DNS would be a series of hierarchical labels with the root domain at the bottom, which would branch out to higher levels just as the branches of an actual tree. Because the hierarchy is delegated from the root upwards, this is generally represented as an inverted tree, with the root at the top supporting descending branches with the delegation of the administration for each label.</p>

                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure1.png"
                            alt="Ver" /></figure>
                        <figcaption>Figure 1: Representation of the hierarchical inverted tree structure of the DNS. CC BY-SA 3.0 By Jane Kruch - Own work, <a href="https://commons.wikimedia.org/w/index.php?curid=31749683" target="_blank" rel="noreferrer">https://commons.wikimedia.org/w/index.php?curid=31749683</a>.</figcaption>
                    </div>

                    <p>This structure is what has allowed a robust and effective DNS from the time when there were just a few hundreds of servers connected to the Internet, to the 4.5 billion estimated users in 2019.</p>
                    <p>However, this structure has a critical and sensitive element: its root. This is why, from the very beginning, it was decided that the root of the DNS would be distributed among twelve different <a href="https://root-servers.org/" target="_blank" rel="noreferrer">organizations</a>. Each of these organizations would manage one root server, which in turn would be named with a different letter of the alphabet. The number twelve was not selected at random, but instead represents the maximum number allowed by the size of the messages at the time. These twelve organizations maintained their servers for almost twenty years until the explosive growth of the Internet led to the decision that adjustments and additional copies would be necessary. As a result, the features of Internet routing enabled a technology known as Anycast, which allows each of these twelve letters to be subdivided into a cloud of nodes at different physical locations, so it is possible to continue to scale up the service. Thus, for example, the “F” DNS server, originally a server located in California (USA), now includes 241 copies in more than 90 different countries, each of them capable of serving their own region.</p>
                    <p>These particular efforts by each organization were also supported by regional organizations such as LACNIC and its +RAICES Project created in 2004, which promotes the deployment of root server copies in the region of Latin America and the Caribbean (LAC) by providing communication, coordination and contact among organizations willing to host a copy and root server administrators, and even by offering financial support to purchase the equipment and manage its installation. As a result, +RAICES has already installed 26 copies in our region, it launches an annual call for proposals for organizations willing to host a copy, and a committee selects which proposals will be worthy of support.</p>

                </>}
            </div>

        </div>
    )
}

export default RootServersSuImportancia
/* 
     
    */