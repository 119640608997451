import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext';
import SearchComponent from '../../../components/SearchComponent';

function MenuLateralOptimizacionRedesBgp() {
    const { myLang } = useContext(LangContext)
   

    return (
        <>
            {myLang === 'es' && <MenuContent />}
            {myLang === 'en' && <MenuContent />}
            {myLang === 'pt' && <MenuContent />}
        </>);
}

function MenuContent() {
    const { activeAnchor, scrollToAnchor } = useContext(MenuContext)
    return (
        <nav>
            <button>Optimización de redes mediante un mejor iBGP <i className="fas fa-angle-down"></i></button>
            <SearchComponent />
            <ul className="active">
                <li><a href="#resumen" className={activeAnchor === 'p0' ? 'active' : null} onClick={() => scrollToAnchor('p0')}>Resumen</a></li>
                <li><a href="#descripcion-del-problema" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Descripción del problema</a></li>
                <li><a href="#situacion-actual" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Situación actual</a></li>
                <li><a href="#quee-aporta-esta-nueva-aproximacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Qué aporta esta nueva aproximación</a></li>
                <li><a href="#en-que-estado-esta-este-trabajo" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>¿En qué estado está este trabajo?</a></li>
                <li><a href="#un-llamado-a-la-accion" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Un llamado a la acción</a></li>
                <li><a href="#como-colaborar" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Cómo colaborar</a></li>
                <li><a href="#referencias" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Referencias</a></li>
            </ul>
        </nav>
    )
}
export default MenuLateralOptimizacionRedesBgp;