import React, {useContext} from 'react'
import { LangContext } from '../../../context/DataContext'


function Motivo() {
    const {myLang} = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' &&<>
                    <h2>Motivo</h2>
                    <p>LACNIC desea evaluar el nivel de despliegue de CPEs con soporte de IPv6 en la regi&oacute;n (Latinoam&eacute;rica y Caribe) y qu&eacute; Mecanismos de Transici&oacute;n soportan los CPEs m&aacute;s populares de estos.</p>
                </>}
            </div>
        </div>
    )
}

export default Motivo
