import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css';
import '../../../css/react-ctrl-f.css'; 
import Resumen from "./Resumen";
import Introduccion from "./Introduccion";
import Metodologia from "./Metodologia";
import DatosPorPais from "./DatosPorPais"
import AnalisisRegional from './AnalisisRegional';
import Conclusiones from './Conclusiones';
import Referencias from './Referencias'; 

export default function InterconexionBgp() {
 
    return ( 
      <> 
        <ReportTitle />
        <Resumen /> 
        <Introduccion /> 
        <Metodologia /> 
        <DatosPorPais /> 
        <AnalisisRegional /> 
        <Conclusiones /> 
        <Referencias />  
      </>
    )
}
 
function ReportTitle() {
  const { myLang } = useContext(LangContext)
  return (
    <h1>
        {myLang === 'es' && 'Interconexión en BGP en la región de América Latina y el Caribe'}
        {myLang === 'en' && 'BGP Interconnection in the Region of Latin America and the Caribbean'}
        {myLang === 'pt' && 'Interconexão no BGP na região da América Latina e o Caribe'}
    </h1>
    )
}


/* export default function InterconexionBgp() {

  const uselocation = useLocation();
  const url = uselocation.pathname;  
  const { handleLanguaje } = useContext(LangContext)

  if (url.includes(`/es/`) === true) {
    handleLanguaje('es')
    return (
      <ContentReport/>
    )
  } if (url.includes(`/en/`) === true) {
      handleLanguaje('en')
      return (
        <ContentReport/>
      )
  } if (url.includes(`/pt/`) === true) {
      handleLanguaje('pt')
      return (
          <ContentReport/>
      )
  }
}

function ContentReport() { 
   
  const { slug } = useParams();
  if ( slug === undefined ) {
    return(
      <ReportMainWrapper> 
          <Resumen /> 
          <Introduccion /> 
          <Metodologia /> 
          <DatosPorPais /> 
          <AnalisisRegional /> 
          <Conclusiones /> 
          <Referencias /> 
      </ReportMainWrapper>
    )
  } 
  else return <NoMatch />
}

function ReportMainWrapper({ children }) {
  const { slug } = useParams();
  return (
      <Wrapper
          url_es={`/es/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}
          url_en={`/en/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}
          url_pt={`/pt/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}>
          <ReportTitle />
          {children}
      </Wrapper>
  )
}
function ReportTitle() {
  const { myLang } = useContext(LangContext)
  return (
    <h1>
        {myLang === 'es' && 'Interconexión en BGP en la región de América Latina y el Caribe'}
        {myLang === 'en' && 'BGP Interconnection in the Region of Latin America and the Caribbean'}
        {myLang === 'pt' && 'Interconexão no BGP na região da América Latina e o Caribe'}
    </h1>
    )
}
 */

/* 

import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext";
import { useParams, useLocation } from "react-router-dom"
import '../../../css/reports.css';
import Wrapper from "./Wrapper";
import Resumen from "./Resumen";
import Introduccion from "./Introduccion";
import Metodologia from "./Metodologia";
import DatosPorPais from "./DatosPorPais"
import AnalisisRegional from './AnalisisRegional';
import Conclusiones from './Conclusiones';
import Referencias from './Referencias';
import NoMatch from "../../../components/NoMatch";

export default function InterconexionBgp() {

  const uselocation = useLocation();
  const url = uselocation.pathname;  
  const { handleLanguaje } = useContext(LangContext)

  if (url.includes(`/es/`) === true) {
    handleLanguaje('es')
    return (
      <ContentReport/>
    )
  } if (url.includes(`/en/`) === true) {
      handleLanguaje('en')
      return (
        <ContentReport/>
      )
  } if (url.includes(`/pt/`) === true) {
      handleLanguaje('pt')
      return (
          <ContentReport/>
      )
  }
}

function ContentReport() {
  const { slug } = useParams();
  if ( slug === undefined ) {
    return(
    <ReportMainWrapper>
      <Resumen />
    </ReportMainWrapper>
    )
  } if ( slug === 'introduccion' ) {
    return(
      <ReportMainWrapper> 
        <Introduccion />
      </ReportMainWrapper> 
    )
  }if (slug === 'metodologia') {
    return (
      <ReportMainWrapper> 
        <Metodologia />
      </ReportMainWrapper>
    )
  } if (slug === 'datos-por-pais') {
    return (
      <ReportMainWrapper> 
        <DatosPorPais />
      </ReportMainWrapper>
    )
  }if (slug === 'analisis-de-los-datos-a-nivel-regional') {
    return (
      <ReportMainWrapper> 
        <AnalisisRegional />
      </ReportMainWrapper>
    )
  } if (slug === 'conclusiones-y-trabajo-a-futuro') {
    return (
      <ReportMainWrapper> 
        <Conclusiones />
      </ReportMainWrapper>
    )
  } if (slug === 'referencias') {
    return (
      <ReportMainWrapper> 
        <Referencias />
      </ReportMainWrapper>
    )
  } 
  else return <NoMatch />
}

function ReportMainWrapper({ children }) {
  const { slug } = useParams();
  return (
      <Wrapper
          url_es={`/es/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}
          url_en={`/en/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}
          url_pt={`/pt/interconexion-bgp-en-lac/${slug === undefined ? '' : slug}`}>
          <ReportTitle />
          {children}
      </Wrapper>
  )
}
function ReportTitle() {
  const { myLang } = useContext(LangContext)
  return (
    <h1>
        {myLang === 'es' && 'Interconexión en BGP en la región de América Latina y el Caribe'}
        {myLang === 'en' && 'BGP Interconnection in the Region of Latin America and the Caribbean'}
        {myLang === 'pt' && 'Interconexão no BGP na região da América Latina e o Caribe'}
    </h1>
    )
}

 


 
 
   
 */

 


 
 
   
 