import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Introduccion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' && <>
                    <h2>Introducci&oacute;n</h2>
                    <p>Los Servidores Ra&iacute;z del DNS (“root servers” desde ahora en adelante) son servicios cr&iacute;ticos para el funcionamiento b&aacute;sico de Internet. Estos permiten el direccionamiento y la ubicaci&oacute;n de sitios web, correos electr&oacute;nicos, aplicaciones de celular, etc&eacute;tera. Para el correcto funcionamiento y su rapidez en la respuesta, es importante que los root servers se encuentren ubicados lo m&aacute;s cerca posible de los usuarios finales.</p>
                    <p>Desde el a&ntilde;o 2003 que tanto las organizaciones que administran los root servers, como organismos regionales de latinoam&eacute;rica tal como LACNIC y su programa +RAICES, han estado desarrollando esfuerzos para la instalaci&oacute;n de “copias” de estos servidores en regiones lejanas a la ubicaci&oacute;n cl&aacute;sica de estos servicios. El esfuerzo a la fecha ha dado como resultado que existan m&aacute;s de mil copias en todo el mundo, con un alcance mundial importante.</p>
                    <p>Este estudio es el primero llevado a cabo en nuestra regi&oacute;n para medir el resultado de estos a&ntilde;os de esfuerzo. Gracias a datos hist&oacute;ricos desde el a&ntilde;o 2013, es posible medir la mejora en los lugares donde s&iacute; se han instalado copias, as&iacute; como detectar lugares y regiones donde es necesario enfocar los proyectos en el futuro.</p>

                </>}
                {myLang === 'en' && <>
                    <h2>Introduction</h2>
                    <p>DNS root servers are critical services for the basic operation of the Internet. They allow addressing and locating websites, email mailboxes, mobile applications, etc. For their proper operation and response speed, root servers must be located as close as possible to end users.</p>
                    <p>Since 2003, both the organizations that manage root servers as well as different regional Latin American organizations, such as the Latin American and Caribbean Internet Addresses Registry (LACNIC) and its +RAICES Project, have been undertaking efforts to install copies of these servers in regions that are far from the classic location of these services. To date, such efforts have resulted in the installation of more than a thousand such copies worldwide.</p>
                    <p>This study is the first of its kind conducted in our region to measure the results of these years of efforts. The historical data available since 2013 has made it possible to assess the improvement in the locations where copies have been installed, as well as to detect locations and regions where the projects should focus in the future.</p>

                </>}
            </div>

        </div>
    )
}

export default Introduccion
