import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function EstadoDelTrabajo() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4">
                {myLang === 'es' &&  <Contenido/>}
            </div>
        </div>
    )
}
function Contenido() {
    return (<>
        <h2><MatchText id="match-27">¿En qué estado está este trabajo?</MatchText></h2>
        <p><MatchText id="match-28">Este estudio es un spin-off de una l&iacute;nea de investigaci&oacute;n previa que en sus or&iacute;genes apuntaba al dise&ntilde;o optimizado de overlays iBGP con Reflectores de Rutas. El dise&ntilde;o de un overlay iBGP &oacute;ptimo es un problema de optimizaci&oacute;n combinatoria, num&eacute;ricamente intratable de integrar exhaustivamente el detalle de prefijos a ser procesados en un AS real.</MatchText></p>
        <p><MatchText id="match-29">Fue as&iacute; como se desarroll&oacute; el concepto de clase de prefijos y las herramientas de software para calcularlos.</MatchText></p>
        <p><em><MatchText id="match-30">Optimizaci&oacute;n de reflectores aparte, se est&aacute; trabajando activamente en los desarrollos para el procesamiento de clases, en el entendido que el problema es de notoria importancia en s&iacute; mismo.</MatchText></em></p>
        <p><em><MatchText id="match-31">os desarrollos implican: el poder interpretar la informaci&oacute;n de routers de distintos proveedores y versiones de software; la mejora de la performance de los algoritmos usados para procesar esa informaci&oacute;n; y capturar con precisi&oacute;n qu&eacute; cortes de la informaci&oacute;n resultante son de inter&eacute;s para los potenciales usuarios de esta aplicaci&oacute;n.</MatchText></em></p>
        <p><em><MatchText id="match-32">A la fecha, las aplicaciones del mundo real se hicieron sobre escenarios de crecimiento en la Red Internacional de ANTEL (operador nacional de telecomunicaciones de Uruguay) y de la Red Clara (Cooperaci&oacute;n Latino Americana de Redes Avanzadas).</MatchText></em></p>
        <p><em><MatchText id="match-33">Al estar en pleno desarrollo, de momento el c&oacute;digo no est&aacute; abierto, ni se cuenta con front-ends para que otros usuarios puedan procesar la informaci&oacute;n por s&iacute; mismos.</MatchText></em></p>

    </>)
}
export default EstadoDelTrabajo
