import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function LatenciaAgregadaComparada(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p6">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-63">Latencia agregada comparada</MatchText></h2>
                    <p>
                        <MatchText id="match-64">Otra representación de las mediciones que podemos tomar es un gráfico de latencia en general, tomando todas las mediciones realizadas. De forma similar a los gráficos anteriores, dividimos las mediciones entre aquellas que son realizadas entre puntos de un mismo país y las que son entre sitios de diferentes países. Podemos ver esto, para 2017 y 2020, en el siguiente gráfico:</MatchText></p>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r10.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-65">En este gráfico podemos ver las curvas de comparación entre el estudio de 2017 y el de 2020. En línea punteada se representan los tiempos de latencia en 2017 y en línea continua los de 2020. A su vez, están representadas las mediciones intra-país (es decir, entre puntos ubicados dentro de un mismo país) y las mediciones inter-país (es decir, mediciones entre puntos ubicados en diferentes países).</MatchText></p>
                            <p>
                                <MatchText id="match-66">En el gráfico podemos ver una clara mejora en los tiempos en las conexiones internas dentro de los países. Si ponemos foco en las curvas de la izquierda, en color azul, podemos ver que la curva de 2020 (línea continua) se encuentra completamente a la izquierda de la curva de 2017, es decir representa latencias más bajas. Para 2020 el 50% de las mediciones (mediana) está por debajo de 39 ms, en comparación con 52 ms de 2017. Analizando las mediciones más lentas, el 95% de las mediciones de 2020 se encuentran por debajo de 166 ms, en comparación con 150 ms en 2017.</MatchText></p>
                        </div>
                    </div>
                    <p>
                        <MatchText id="match-67">Sin embargo, si analizamos la conectividad entre países (curvas rojas), es decir, la latencia entre puntos ubicados en distintos países, vemos que no es tan clara la situación. Hay un porcentaje de mediciones que mejoran respecto a 2017. Podemos ver que para valores más bajos de latencia la curva 2020 es más rápida, sin embargo en el percentil 25 (122 ms en el eje de las x) ocurre un cambio y la curva 2017 comienza a ser más rápida. Sobre el percentil 72 (199 ms) la situación se vuelve a invertir y la curva 2020 vuelve a ser la más rápida. Pero la pregunta es, ¿a qué se debe ese comportamiento? ¿Estaremos cayendo nuevamente dentro de un sesgo de las mediciones?</MatchText></p>
                    <p>
                        <MatchText id="match-68">Primero analicemos aquellas componentes que se mantienen constante entre los dos sets de datos. Por ejemplo, podemos analizar cómo es la latencia entre aquellos sistemas autónomos que forman parte de la campaña 2017 y a su vez de la campaña 2020. Recordemos que las mediciones tienen un sistema autónomo de origen y uno de destino, y para realizar una correcta comparación debemos mantener la tupla completa &lt; AS_origen, AS_destino&gt;. Mantener la tupla completa por un lado implica filtrar agresivamente y quedarnos únicamente con un pequeño subconjunto del set de datos original. Pero, por otro lado, trae el beneficio de que las mediciones son comparables unas con las otras, y a partir de ellas se puede inferir si ha habido un cambio real durante estos tres años. De un total de 10750 combinaciones de &lt; AS_origen, AS_destino&gt; del set de datos de 2020, nos quedamos con 373 muestras. La CDF para este subconjunto es la siguiente:</MatchText></p>
                    <div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r11.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-69">Podemos ver que para este subconjunto de mediciones, el comportamiento de la latencia intra-país se mantiene (2020 representa mejoras frente a 2017), y la latencia inter-país tiene un comportamiento un poco más claro: 2020 presenta valores menores en casi todas las partes del espectro de latencia, sobre todo a partir del percentil 29.</MatchText></p>
                            <p>
                                <MatchText id="match-70">La conclusión del análisis de este subconjunto de mediciones es que para aquellos sistemas autónomos donde tuvimos mediciones y puntos de prueba durante estos dos años la latencia ha mejorado.</MatchText></p>
                        </div>
                    </div>

                    <p>
                        <MatchText id="match-61">Para poder obtener mayor claridad en el comportamiento de la CDF original, repetimos el método anterior donde consideramos la distancia geodésica entre origen y destino, y comparamos la velocidad de ping frente a su valor teórico. Recordemos que esta comparación la realizamos mediante un valor que puede ir de 0 a 1, donde valores bajos indican ineficiencias en la propagación del ping, pero a medida que el enrutamiento mejora, las condiciones se acercan a lo que indica la teoría, y el valor se aproxima a 1. En este caso consideramos todo el set de datos de 2020 y de 2017. Graficando la CDF de la velocidad de ping obtenemos la siguiente figura:</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r12.png" /></figure>
                    <p>
                        <MatchText id="match-62">Vemos del gráfico anterior que de hecho la velocidad de ping para 2020 es consistentemente mejor que para 2017 (se encuentra a la derecha), lo que nos demuestra que han habido mejoras en la conectividad regional durante estos últimos 3 años. La curva de 2017 (punteada) se desplaza aproximadamente un 20% hacia la derecha hasta su valor de 2020. Además, gracias a esta nueva métrica, podemos concluir que el comportamiento mixto de las latencias para las mediciones inter-país es debido a la diferencia en las muestras recabadas entre un año y el otro. Una vez las muestras son normalizadas según su distancia geográfica, el comportamiento mixto desaparece.</MatchText></p>

                </>}

                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-63">A Comparison of Aggregate Latency</MatchText></h2>
                    <p>
                        <MatchText id="match-64">The measurements can also be represented in a general latency graph. Just as for the graphs above, we divided the measurements into two groups, those that were performed between points located in the same country and those that were performed between points in different countries. The following shows latencies for 2017 and 2020.</MatchText></p>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r10.png" /></figure> </div>
                        <div>
                            <p>
                                <MatchText id="match-65">Here, we can compare the lines plotted based on the data obtained in the studies conducted in 2017 and 2020, respectively. The dotted line represents latency times in 2017, while the solid line shows latency times in 2020. In turn, the graph shows intra-country measurements (i.e., measurements between points in the same country) and intercountry measurements (i.e., measurements between points in different countries).</MatchText></p>
                            <p>
                                <MatchText id="match-66">In the graph we can observe a clear improvement in the times for internal connections within each country. If we focus on the lines to the left (blue), we can see that the 2020 line (solid line) is entirely to the left of the 2017 line, in other words, it represents lower latencies. For 2020, 50% of the measurements (median) are below 39 ms, compared to 52 ms in 2017. An analysis of the slower measurements shows that 95% of the measurements (median) are below 166 ms, compared to 150 ms in 2017.</MatchText></p>
                        </div>
                    </div>
                    <p>
                        <MatchText id="match-67">However, an analysis of inter-country connectivity (red lines), i.e., the latency between points located in different countries, the situation is not su clear. Part of the measurements have improved as compared to 2017. We can observe that, for lower latency values, the 2020 curve is faster; however, this changed at the 25th percentile (122 ms on the x-axis) and the 2017 curve becomes faster. Above the 72nd percentile (199 ms), the situation changes once again and the 2020 curve is again the faster of the two. Yet the question is, what is the reason for this behavior? Is this another case of measurement bias?</MatchText></p>
                    <p>
                        <MatchText id="match-68">First, let's analyze the components that remain constant between the two datasets. For example, we can analyze the latency between the autonomous systems that were part of both the 2017 and Intra-country / inter-country latency by year 10 the 2020 campaigns. It is important to remember that measurements have an origin autonomous system and a destination autonomous, and that for proper comparison we must maintain the complete tuple &lt; origin_AS, estination_AS &gt;. On the one hand, maintaining the complete tuple involves aggressive filtering and keeping only a small subset of the original dataset. On the other, however, it has the benefit of producing measurements are comparable with each other, and which allow us to determine whether there has been an actual change over the three years. Of a total of 10,750 &lt;origin_AS, destination_AS&gt; combinations that were part of the 2020 dataset, we kept 373 samples. The CDF for this subset is plotted below.</MatchText></p>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r11.png" /></figure> </div>
                        <div>
                            <p>
                                <MatchText id="match-69">We can see that, for this subset of measurements, the intra-country latency has the same behavior (2020 shows improvements compared to 2017), while the inter-country latency has a slightly clearer behavior: The values for 2020 are lower in almost all parts of the latency spectrum, particularly above the 29th percentile.</MatchText></p>
                            <p>
                                <MatchText id="match-70">An analysis of this subset of measurements allows us to conclude that latency has improved for the autonomous systems for which we had measurements and vantage points both in 2017 and 2020.</MatchText></p>
                        </div>
                    </div>
                    <p>
                        <MatchText id="match-71">To understand more clearly the behavior of the original CDF, we repeated the method described earlier according to which we considered the geodetic distance between origin and destination and compared ping speeds against their theoretical value. Let us recall that this comparison is performed using a value that can range from 0 to 1, where low values indicate inefficiencies in ping propagation. As routing improves, the actual conditions approach theory and this value approaches 1. In this case, we considered the entire dataset for 2020 and 2017. The following figure shows the CDF of the ping speed</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r12.png" /></figure>
                    <p>
                        <MatchText id="match-72">The graph shows that, in fact, the ping speed for 2020 is consistently better than for 2017 (plotted on the right), which proves that regional connectivity has seen improvements over the last 3 years. The 2017 curve (dotted line) shifts approximately 20% to the right to its value in 2020. Furthermore, this new metric allows us to conclude that the mixed behavior of latencies for inter-country measurements is due to the difference in the samples collected in one year and the other. Once the samples are normalized based on geographical distance, the mixed behavior disappears.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-63">Latência agregada comparada</MatchText></h2>
                    <p>
                        <MatchText id="match-64">Outra representação das medições que podemos fazer é um gráfico de latência em geral, colocando todas as medições feitas. De forma semelhante aos gráficos anteriores, dividimos as medições entre aquelas que são feitas entre pontos de um mesmo país e aquelas que são feitas entre sites em países diferentes. Podemos ver isso, para 2017 e 2020, no gráfico a seguir:</MatchText></p>
                    <div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r10.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-65">Neste gráfico podemos ver as curvas de comparação entre o estudo de 2017 e o estudo de 2020. A linha pontilhada representa os tempos de latência em 2017 e a linha contínua os de 2020. Por sua vez, as medições intrapaís (ou seja, entre pontos localizados dentro do mesmo país) e as medições interpaíses (ou seja, medições entre pontos localizados em diferentes países) são representadas.</MatchText></p>
                            <p>
                                <MatchText id="match-56">No gráfico podemos ver uma clara melhoria nos tempos de conexões internas dentro dos países. Se focarmos nas curvas da esquerda, em azul, podemos observar que a curva de 2020 (linha contínua) está totalmente à esquerda da curva de 2017, ou seja, representa latências mais baixas. Em 2020, 50% das medições (mediana) está por baixo de 39 ms, em comparação com 52 ms de 2017. Analisando as medições mais lentas, 95% das medições de 2020 está por baixo de 166 ms, em comparação com 150 ms de 2017.</MatchText></p>
                        </div>
                    </div>
                    <p>
                        <MatchText id="match-67">No entanto, se analisarmos a conectividade entre países (curvas vermelhas), ou seja, a latência entre pontos localizados em diferentes países, vemos que a situação não é tão clara. Há uma porcentagem de medições que melhoram em relação a 2017. Podemos ver que para valores de latência mais baixos, a curva de 2020 é mais rápida, porém no percentil 25 (122 ms no eixo das x) ocorre uma mudança e a curva de 2017 começa a ser mais rápida. Sobre o percentil 72 (199 ms) a situação se inverte novamente e a curva de 2020 é mais uma vez a mais rápida. Mas a questão é: qual é o motivo desse comportamento? Estaremos caindo novamente dentro de um viés das medições?</MatchText></p>
                    <p>
                        <MatchText id="match-68">Vamos primeiro analisar os componentes que permanecem constantes entre os dois conjuntos de dados. Por exemplo, podemos analisar como é a latência entre aqueles sistemas autônomos que fazem parte da campanha 2017 e ao mesmo tempo da campanha 2020. Lembre-se de que as medições têm um sistema autônomo de origem e outro de destino, e para fazer uma comparação correta devemos manter a tupla completa &lt;AS_origem, AS_destino&gt;. Manter a tupla completa, de um lado significa filtrar agressivamente e ficarmos com apenas um pequeno subconjunto do conjunto de dados original. Mas, do outro, traz o benefício de que as medições são comparáveis entre si, e delas pode-se inferir se houve uma mudança real nesses três anos. De um total de 10750 combinações de &lt;AS_origem, AS_destino&gt; do conjunto de dados de 2020, ficamos com 373 amostras. A CDF para este subconjunto é a seguinte:</MatchText></p>
                    <div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r11.png" /></figure></div>
                        <div>
                            <p>
                                <MatchText id="match-69">Podemos ver que para este subconjunto de medições, o comportamento da latência intrapaís é mantido (2020 representa melhorias em comparação com 2017), e a latência interpaís tem um comportamento um pouco mais claro: 2020 tem valores menores em quase todas as partes do espectro de latência, principalmente a partir do percentil 29.</MatchText></p>
                            <p>
                                <MatchText id="match-70">A conclusão da análise desse subconjunto de medições é que para aqueles sistemas autônomos em que tivemos medições e pontos de teste durante esses dois anos, a latência melhorou.</MatchText></p>
                        </div>
                    </div>

                    <p>
                        <MatchText id="match-71">Para obter mais clareza no comportamento da CDF original, repetimos o método anterior em que consideramos a distância geodésica entre origem e destino, e comparamos a velocidade do ping com seu valor teórico. Lembre-se de que fazemos essa comparação usando um valor que pode variar de 0 a 1, em que valores baixos indicam ineficiências na propagação do ping, mas conforme o roteamento melhora, as condições ficam mais próximas do que a teoria indica, e o valor se aproxima a 1. Nesse caso consideramos todo o conjunto de dados de 2020 e 2017. Traçando a CDF da velocidade de ping, obtemos a seguinte figura:</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r12.png" /></figure>
                    <p>
                        <MatchText id="match-72">Vemos no gráfico acima que de fato a velocidade de ping para 2020 é consistentemente melhor do que para 2017 (encontrado à direita), o que nos mostra que houve melhorias na conectividade regional nos últimos 3 anos. A curva de 2017 (pontilhada) se desloca aproximadamente 20% para a direita até seu valor de 2020. Além disso, graças a esta nova métrica, podemos concluir que o comportamento misto das latências para medições interpaís é devido à diferença nas amostras coletadas entre um ano e o outro. Uma vez que as amostras são normalizadas de acordo com sua distância geográfica, o comportamento misto desaparece.</MatchText></p>


                </>}
            </div>
        </div>
    );
}

export default LatenciaAgregadaComparada;