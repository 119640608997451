import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import {Link} from 'react-router-dom' 

function ResumenMejoresPracticasIXP(props) {
    const { myLang } = useContext(LangContext)
    return (   
        <div>    
            <div>
                {myLang === 'es' && <> 
                    <h1>Mejores prácticas para IXP</h1>
                    <p>Estas recomendaciones de mejores prácticas para IXP es una guía elaborada por LACNIC, con el apoyo de LAC-IX. Este documento de referencia para Puntos de Intercambio de Tráfico se encuentra agrupado en cinco categorías: Infraestructuras, servicios, operaciones, gobernanza y comunidad. Para LACNIC es fundamental continuar promoviendo y colaborando con otras entidades regionales que permitan fortalecer la infraestructura crítica de Internet.</p>
                    <Link className="buttons button_blue" to="/es/interconexion/mejores-practicas-para-ixp">Leer Reporte</Link>

                </>}
                {myLang === 'en' && <> 
                    <h1>Best Practices for IXPs</h1>
                    <p>These recommended best practices for IXPs are a guide prepared by LACNIC with the support of LAC-IX. The document is intended to serve as a reference for Internet Exchange Points and is divided into five categories: Infrastructure, Service, Operation, Governance, and Community. LACNIC believes it is essential to continue promoting and collaborating with other regional entities to strengthen critical Internet infrastructure.</p>
                    <Link className="buttons button_blue" to="/en/interconexion/mejores-practicas-para-ixp">Read Report</Link> 
                </>}
                {myLang === 'pt' && <> 
                    <h1>Melhores práticas para IXP</h1>
                    <p>Estas recomendações de melhores práticas para IXP são um guia elaborado pelo LACNIC, com o apoio de LAC-IX. Este documento de referência para Pontos de Troca de Tráfego está agrupado em cinco categorias: Infraestruturas, serviços, operações, governança e comunidade. Para o LACNIC é fundamental continuar promovendo e colaborando com outras entidades regionais que permitam fortalecer a infraestrutura crítica da Internet.</p>
                    <Link className="buttons button_blue" to="/pt/interconexion/mejores-practicas-para-ixp">Leia o Relatório</Link>  
                </>}
            </div>
        </div>  
    );
}


 
export default ResumenMejoresPracticasIXP;