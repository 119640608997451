import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Resultados() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id='p6'>
                {myLang === 'es' && <>
                    <h2>Primeros resultados</h2>
                    <p>De la lista de resolvers en IPv6 de LACNIC se obtuvieron 310 direcciones IPv6 &uacute;nicas, de las cuales 82 responden por Do53 y siete responden por DoT. Esto representa un 7,9% de los&nbsp;<em>resolvers</em>&nbsp;abiertos en IPv6.</p>
                    <p><img src="/images/uso-dns-over-tls/fig1.png" alt="Fig" /></p>
                    <p>De las listas de&nbsp;<em>resolvers</em>&nbsp;abiertos en IPv4 de public-dns.info, hubo 1312 direcciones &uacute;nicas, de las cuales 770 responden por Do53 y nueve responden por DoT. Esto representa un 1,2% de los&nbsp;<em>resolvers</em>&nbsp;abiertos en IPv4.</p>
                    <p><img src="/images/uso-dns-over-tls/fig2.png" alt="Fig" /></p>
                    <p>De las mediciones en las sondas Atlas de LAC, en las dirigidas a los propios&nbsp;<em>resolvers</em>&nbsp;locales de cada una solo se obtuvo una respuesta correcta sobre DoT de un total de 307. Esto representa un 0,3%. La sonda que funcion&oacute; tiene configurada los&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos de Google. Otras sondas que ten&iacute;an&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos que tambi&eacute;n ofrecen DoT, como Cloudflare y Quad9, no dieron resultados correctos. Eso indica que tienen alg&uacute;n tipo de bloqueo local en su red que impide la comunicaci&oacute;n DoT.</p>
                    <p><img src="/images/uso-dns-over-tls/fig3.png" alt="Fig" /></p>
                    <p>Las mediciones desde las sondas Atlas de LAC hacia los&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos que ofrecen servicio DoT mostraron una tasa de &eacute;xito de entre 86,7% (Cleanbrowsing) y 96.5% (Cloudflare). Esto indica que, de todas formas, no existe un camino limpio para DoT desde todas las sondas a los&nbsp;<em>resolvers</em>&nbsp;que lo soportan p&uacute;blicamente.</p>

                    <p><img src="/images/uso-dns-over-tls/fig4.png" alt="Fig" /></p>
                </>}
                {myLang === 'en' && <>
                    <h2>Initial Findings</h2>
                    <p>A total of 310 unique IPv6 addresses were obtained from LACNIC's list of IPv6 resolvers, 82 of which responded over Do53 and seven responded over DoT. This represents 7.9% of open IPv6 resolvers.</p>
                    <p><img src="/images/uso-dns-over-tls/fig1.png" alt="Fig" /></p>
                    <p>A total of 1,312 unique addresses were obtained from the lists of open IPv4 resolvers on public-dns.info, 770 of which responded over Do53 and nine responded over DoT. This represents 1.2% of open IPv4 resolvers.</p>
                    <p><img src="/images/uso-dns-over-tls/fig2.png" alt="Fig" /></p>
                    <p>As for the measurements obtained with the Atlas probes in Latin America and the Carobbean, out of a total of 307 directed at their own local resolvers, only one correct response was obtained over DoT. This represents 0.3%. The probe that worked is configured in Google's public resolvers. Other probes that had public resolvers which also offered DoT, such as Cloudflare and Quad9, did not provide correct results. This means that there is some kind of local block on their network that is preventing DoT communication.</p>
                    <p><img src="/images/uso-dns-over-tls/fig3.png" alt="Fig" /></p>
                    <p>Measurements from the Atlas probes in LAC to the public resolvers that offer the DoT service showed a success rate ranging from 86.7% (Cleanbrowsing) to 96.5% (Cloudflare). This means that, in any case, there is no clear path for DoT from all the probes to the resolvers that publicly support it.</p>
                    <p><img src="/images/uso-dns-over-tls/fig4.png" alt="fig" /></p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Primeiros resultados</h2>
                    <p>Da lista de&nbsp;<em>resolvers</em>&nbsp;no IPv6 do LACNIC foram obtidos 310 endere&ccedil;os s&oacute; IPv6, dos quais 82 respondem por Do53 e sete respondem por DoT. Isso representa&nbsp; 7,9% dos&nbsp;<em>resolvers</em>&nbsp;abertos no IPv6.</p>
                    <p><img src="/images/uso-dns-over-tls/fig1.png" alt="fig" /></p>
                    <p>Das listas de&nbsp;<em>resolvers</em>&nbsp;no IPv4 de public-dns.info, houve 1312 endere&ccedil;os s&oacute; IPv4, dos quais 770 respondem por Do53 e nove respondem por DoT. Isso representa&nbsp; 1,2% dos&nbsp;<em>resolvers</em>&nbsp;abertos no IPv4.</p>
                    <p><img src="/images/uso-dns-over-tls/fig2.png" alt="fig" /></p>
                    <p>Das medições nas sondas Atlas da ALC, naquelas direcionadas aos próprios resolvers locais de cada uma, obteve-se apenas uma resposta correta sobre o DoT de um total de 307. Isso representa 0,3%. A sonda que funcionou tem resolvers públicos do Google configurados. Outras sondas que tinham resolvers públicos que também oferecem DoT, tais como Cloudflare e Quad9, não deram resultados corretos. Isso indica que estas têm algum tipo de bloqueio local na sua rede que impede a comunicação DoT.</p>
                    <p><img src="/images/uso-dns-over-tls/fig3.png" alt="fig" /></p>
                    <p>As medi&ccedil;&otilde;es desde as sondas Atlas da ALC para&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos que oferecem servi&ccedil;o DoT mostraram uma taxa de sucesso entre 86,7% (Cleanbrowsing) e 96,5% (Cloudflare). Isso indica que, em qualquer caso, n&atilde;o h&aacute; um caminho limpo para o DoT desde todas as sondas para os&nbsp;<em>resolvers</em>&nbsp;que o suportam publicamente.</p> 
                    <p><img src="/images/uso-dns-over-tls/fig4.png" alt="fig" /></p>
                </>}
            </div>
        </div>
    )
}

export default Resultados
