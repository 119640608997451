import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

export default function HistoriaDeExito() {
    const { myLang } = useContext(LangContext);
    return ( 
        <div>
            <div> 
                {myLang === 'es' && <>
                    <h1>Una historia de éxito en ciberseguridad</h1>
                    <p>Resumen de cinco años de gestión de incidentes de seguridad informática.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/informe-warp-2019.pdf">Descargar (PDF)</Button>
                </>}
                {myLang === 'en' && <>
                    <h1>A Cybersecurity Success Story</h1>
                    <p>A summary of five years of computer security incident management.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/informe-warp-2019-en.pdf">Download (PDF)</Button>
                </>}
                {myLang === 'pt' && <>
                    <h1>Una historia de éxito en ciberseguridad [espanhol]</h1>
                    <p>Resumo dos cinco anos de gestão de incidentes de segurança informática.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/informe-warp-2019.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div> 
    )
}