import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function TiemposDeRespuestaPromedio() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>Tiempos de respuesta promedio por país</h2>
                    <p>Otra medida m&aacute;s interesante es obtener un promedio del tiempo de acceso desde todas las sondas de un pa&iacute;s hacia cada uno de los root servers. Esta medida nos permite resumir los puntos de los casos anteriores, y darle una cifra permite estimar la mejora durante el tiempo. Adem&aacute;s al graficar cada letra en una curva de un color distinto permite diferenciar claramente las letras que tienen mejor comportamiento.</p>
                    <p>El siguiente gr&aacute;fico representa el promedio desde Chile a cada root server. Cada curva representa una letra distinta. En el eje X va el tiempo, y en el Y el tiempo de respuesta en milisegundos.</p>
                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure10.png"
                            alt="Ver" /></figure>
                        <figcaption>Figura 10, promedio de tiempo de respuesta hist&oacute;rico desde Chile hacia root servers.</figcaption>
                    </div>

                    <p>Las curvas tienden a bajar hacia la derecha, lo que es una buena se&ntilde;al e indica que los tiempos de respuesta han disminuido a trav&eacute;s del tiempo. Claramente los root servers L, E, J y K son los que tienen mejor respuesta al d&iacute;a de hoy desde Chile, con F y D en segundo lugar pero tambi&eacute;n cercanos a los 50 ms, lo que es bastante bueno. M&aacute;s atr&aacute;s queda el resto, con el root server M mucho m&aacute;s alejado, cercano a los 200 ms, que representa una mala cifra. Adem&aacute;s podemos sacar promedios de acceso en general, dando 182 ms en el a&ntilde;o 2013 y 99 el 2019, indicando un 45% de reducci&oacute;n en el tiempo de respuesta.</p>
                    <p>La siguiente figura representa el caso desde Argentina:</p>

                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure11.png"
                            alt="Ver" /></figure>
                        <figcaption>Figura 11, promedio de tiempo de respuesta hist&oacute;rico desde Argentina hacia root servers.</figcaption>
                    </div>

                    <p>Ac&aacute; en lo grueso es parecido las letras con mejor comportamiento, y se repite el caso de M con el peor alcance. Tambi&eacute;n se ven problemas a comienzos del a&ntilde;o 2017 en las letras D y M, que probablemente representen pol&iacute;ticas de ruteo internacional que afectaron el tiempo de respuesta desde esas ubicaciones, que fueron resueltas prontamente. En el caso de Argentina, el promedio de tiempo de respuesta pas&oacute; de 179 ms en el 2013 a 116 el 2019, representando un 35% de reducci&oacute;n.</p>
                    <p>El resto de pa&iacute;ses se puede encontrar en la informaci&oacute;n indicada en el Anexo A de este informe.</p>


                </>}
                {myLang === 'en' && <>
                    <h2>Average Response Times By Country</h2>
                    <p>Another interesting measurement is the average response time from all the probes in a country to each root server. This measurement allows us to summarize the points plotted for the previous cases and provides us with numbers that allow us to estimate the improvements that have occurred during this time. In addition, by plotting each root server in a different color, we can clearly distinguish the root servers with the best performance.</p>
                    <p>The following line chart represents average response times from Chile to each root server. Each curve represents a different root server (different letter). The x-axis represents time, while the y-axis represents response times in milliseconds.</p>
                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure10.png"
                            alt="Ver" /></figure>
                        <figcaption>Figure 10: Historical response times from Chile to the different root servers.</figcaption>
                    </div>

                    <p>The curves tend to fall towards the right of the chart, which it is a good sign and indicates that response times have decreased over time. Currently, the L-, E-, J- and K-root servers are clearly the ones with the best response times when queried from Chile, with the F- and D-root servers taking second place but still close to 50 ms, which is also quite good. The remaining servers are lagging, with the M-root server having a response time close to 200 ms, a figure indicative of a poor performance. Likewise, we can also calculate overall average response times: 182 ms for 2013 and 99 ms for 2019, a 45% reduction in response times.</p>
                    <p>The following figure shows the case of Argentina.</p>

                    <div>
                        <figure><img src="/images/uso-root-server-en-lac/figure11.png"
                            alt="Ver" /></figure>
                        <figcaption>Figure 11. Historical response times from Argentina to the different root servers.</figcaption>
                    </div>

                    <p>Generally speaking, the root servers with the best performance are similar and the M-root server also has the worst coverage. Issues can also be detected for root servers D and M in early 2017, which are likely the result of international routing policies that affected response times from these locations and which were quickly solved. In the case of Argentina, the average response time fell from 179 ms in 2013 to 116 ms in 2019, representing a reduction of 35%.</p>
                    <p>The rest of the countries can be found in the information included in Annex A to this report.</p>

                </>}
            </div>

        </div>
    )
}

export default TiemposDeRespuestaPromedio
