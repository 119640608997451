import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Referencias() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p13">  
                {myLang === 'es' && 
                    <><h2>Referencias</h2>
                    <div className='alerts alert_white'> 
                        <ul className='reference_component'>
                            <li>Mecanismos de transici&oacute;n IPv6: <a target="_blank" rel="noreferrer" href="https://es.wikipedia.org/wiki/Mecanismos_de_transici%C3%B3n_IPv6">https://es.wikipedia.org/wiki/Mecanismos_de_transici%C3%B3n_IPv6</a></li>
                            <li>Mecanismo de Transici&oacute;n IPv6: <a target="_blank" rel="noreferrer" href="http://slides.lacnic.net/wp-content/themes/slides/docs/lacnic26/lunes/transicion_ipv6-v2.pdf">http://slides.lacnic.net/wp-content/themes/slides/docs/lacnic26/lunes/transicion_ipv6-v2.pdf</a></li>
                            <li>RFC 7084 - Basic Requirements for  IPv6 Customer Edge Routers: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc7084">https://tools.ietf.org/html/rfc7084</a></li>
                            <li>RFC 8585 - Requirements for IPv6  Customer Edge Routers to Support IPv4-as-a-Service: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc8585">https://tools.ietf.org/html/rfc8585</a></li>
                            <li>RFC 6877 - 464XLAT: Combination of  Stateful and Stateless Translation: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc6877">https://tools.ietf.org/html/rfc6877</a></li>
                            <li>RFC 6145 - IP/ICMP Translation  Algorithm: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc6145">https://tools.ietf.org/html/rfc6145</a></li>
                            <li>RFC 6146 - Stateful NAT64: Network  Address and Protocol Translation from IPv6 Clients to IPv4 Servers: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc6146">https://tools.ietf.org/html/rfc6146</a></li>
                            <li>Draft 464XLAT Optimization: <a target="_blank" rel="noreferrer" href="https://datatracker.ietf.org/doc/draft-palet-v6ops-464xlat-opt-cdn-caches/">https://datatracker.ietf.org/doc/draft-palet-v6ops-464xlat-opt-cdn-caches/</a></li>
                            <li>464XLAT en Redes de Cable: <a target="_blank" rel="noreferrer" href="https://www.lacnic.net/innovaportal/file/3635/1/23-464xlat-en-redes-de-cable-v4.pdf">https://www.lacnic.net/innovaportal/file/3635/1/23-464xlat-en-redes-de-cable-v4.pdf</a></li>
                            <li>IPv6 Transition and Coexistence  IPv6-only and IPv4 as a Service in Broadband and Cellular Networks: <a target="_blank" rel="noreferrer" href="https://www.lacnic.net/innovaportal/file/2621/1/ipv6-trans-and-ipv6-only_v8.pdf">https://www.lacnic.net/innovaportal/file/2621/1/ipv6-trans-and-ipv6-only_v8.pdf</a></li>
                            <li>Technical White Paper for IPv6  DS-Lite Solution: <a target="_blank" rel="noreferrer" href="https://www.huawei.com/us/static/HW-141053.pdf">https://www.huawei.com/us/static/HW-141053.pdf</a></li>
                            <li>DS-Lite: Implementation experience  and views: <a target="_blank" rel="noreferrer" href="https://www.prolixium.com/share/DSLite_Implementation_Experiences.pdf">https://www.prolixium.com/share/DSLite_Implementation_Experiences.pdf</a></li>
                            <li>RFC 6333 - Dual-Stack Lite Broadband  Deployments Following IPv4 Exhaustion: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc6333">https://tools.ietf.org/html/rfc6333</a></li>
                            <li>RFC 6908 - Deployment Considerations  for Dual-Stack Lite: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc6908">https://tools.ietf.org/html/rfc6908</a></li>
                            <li>RFC 7596 - Lightweight 4over6: An  Extension to the Dual-Stack Lite Architecture: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc7596">https://tools.ietf.org/html/rfc7596</a></li>
                            <li>Dive into Lightweight 4over6: <a target="_blank" rel="noreferrer" href="https://blogs.igalia.com/dpino/2017/06/05/dive-into-lw4o6/">https://blogs.igalia.com/dpino/2017/06/05/dive-into-lw4o6/</a></li>
                            <li>Lightweight 4over6: An Extension to  DS-Lite: <a target="_blank" rel="noreferrer" href="https://slideplayer.com/slide/10818043/">https://slideplayer.com/slide/10818043/https://slideplayer.com/slide/10818043/</a></li>
                            <li>IPv4 and IPv6 eRouter Specification  - CM-SP-eRouter-I17-151210: <a target="_blank" rel="noreferrer" href="https://community.cablelabs.com/wiki/plugins/servlet/cablelabs/alfresco/download?id=c189ff96-7144-4bf7-9cf3-cffcb39acfbb">https://community.cablelabs.com/wiki/plugins/servlet/cablelabs/alfresco/download?id=c189ff96-7144-4bf7-9cf3-cffcb39acfbb</a></li>
                            <li>RFC 7597 - Mapping of Address and  Port with Encapsulation (MAP-E): <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc7597">https://tools.ietf.org/html/rfc7597</a></li>
                            <li>RFC 7598 - DHCPv6 Options for  Configuration of Softwire Address and Port-Mapped Clients: <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc7598">https://tools.ietf.org/html/rfc7598</a></li>
                            <li>MAP-E as IPv4 over IPv6 Technology: <a target="_blank" rel="noreferrer" href="https://es.slideshare.net/akiranakagawa3/20150304-apricot2015apnicfukuoka">https://es.slideshare.net/akiranakagawa3/20150304-apricot2015apnicfukuoka</a></li>
                            <li>IPv6 Transition Mechanisms for Broadband  Networks: <a target="_blank" rel="noreferrer" href="https://www.broadband-forum.org/download/TR-242.pdf">https://www.broadband-forum.org/download/TR-242.pdf</a></li>
                            <li>RFC 7599 - Mapping of Address and  Port using Translation (MAP-T): <a target="_blank" rel="noreferrer" href="https://tools.ietf.org/html/rfc7599">https://tools.ietf.org/html/rfc7599</a></li>
                            <li>In-line MAP-T solution  explanation….: <a target="_blank" rel="noreferrer" href="https://community.cisco.com/t5/service-providers-documents/in-line-map-t-solution-explanation-with-vsm-for-exception/ta-p/3750505">https://community.cisco.com/t5/service-providers-documents/in-line-map-t-solution-explanation-with-vsm-for-exception/ta-p/3750505</a></li>
                            <li>Mapping of Address and Port using  Translation (MAP-T): <a target="_blank" rel="noreferrer" href="https://pc.nanog.org/static/published/meetings/NANOG71/1452/20171004_Gottlieb_Mapping_Of_Address_v1.pdf">https://pc.nanog.org/static/published/meetings/NANOG71/1452/20171004_Gottlieb_Mapping_Of_Address_v1.pdf</a></li>
                            <li>Mapping of Address and Port (MAP)  Technical Report: <a target="_blank" rel="noreferrer" href="https://community.cablelabs.com/wiki/plugins/servlet/cablelabs/alfresco/download?id=9280e9b7-773e-4e11-ae84-68b09e32baf0">https://community.cablelabs.com/wiki/plugins/servlet/cablelabs/alfresco/download?id=9280e9b7-773e-4e11-ae84-68b09e32baf0</a></li>
                        </ul>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Referencias
