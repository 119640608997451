import React, { useContext} from "react"; 
import '../css/generic.css'
import '../css/reports.css'
import Footer from "./Footer";
import Header from "./Header";
import MenuLateralReports from './MenuLateralReports';
import { MobileContext } from '../context/DataContext'

export default function Reports({category, children, url_en, url_es, url_pt, footer}){ 
    const {isMobile} = useContext(MobileContext)
 
    return(
        <> 
        <Header url_es={`${url_es}`} url_en={`${url_en}`} url_pt={`${url_pt}`} />
        <div className="report_work_table"> 
            <div className="report_main_wrapper">
                <div> 
                { !isMobile && <MenuLateralReports category={category} />}
                    <article className="report_content_wrapper">
                        <section> 
                            {children}
                        </section>
                    </article>
                     
                </div>
            </div>
        </div>
        <Footer footer='reports' className="master_foot_reports foot_report"  />
        </>
    )
}
 