import React, { useContext } from 'react';
import { Link} from 'react-router-dom'
import { LangContext } from '../../context/DataContext';

export default function AntiSpoofingIntro(props) {  
    const { myLang } = useContext(LangContext);
    return (
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Estudio anti-spoofing en la región</h1>
                    <p>Autores: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                    <p>Este estudio logró identificar fuentes de información para visibilizar la correcta implementación de buenas prácticas para mitigar el IP spoofing en redes de la región de América Latina y el Caribe. A partir del análisis de los datos obtenidos, se recomiendan acciones para que las organizaciones puedan detectar y mitigar este problema. De esta forma no solo se da a conocer el estado de adhesión a las buenas prácticas, sino también se muestra que es posible mejorar el estado de la región para prevenir el spoofing de direcciones IP.</p>
                    <p>Estas estadísticas brindan al CSIRT de LACNIC información para trabajar directamente con las organizaciones asociadas y reducir los riesgos de que los recursos asignados a éstas sean utilizados con fines maliciosos.</p>
                    <Link className="buttons button_blue" to="/es/ciberseguridad/estudio-anti-spoofing-en-la-region">Leer Reporte</Link>
                </>}
                {myLang === 'en' && <> 
                    <h1>Study on Anti-spoofing in the Region</h1>
                    <p>Author: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                    <p>This study managed to identify sources of information to assess the proper implementation of good practices to mitigate IP spoofing in networks across Latin America and the Caribbean. Based on the analysis of the data we obtained, this document recommends actions that will allow organizations to detect and mitigate this problem. Thus, the report does not only show the status of adherence to good practices, but it also shows that it is possible to improve IP address spoofing prevention in the region.&nbsp;</p>
                    <p>These statistics provide LACNIC CSIRT with information to work directly with member organizations and reduce the risk of the resources assigned to them being used for malicious purposes.</p>
                    <Link className="buttons button_blue" to="/en/ciberseguridad/estudio-anti-spoofing-en-la-region">Leer Reporte</Link>
                </>}
                {myLang === 'pt' && <> 
                    <h1>Estudo anti-spoofing na região</h1>
                    <p>Autores: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                    <p>Este estudio logró identificar fuentes de información para visibilizar la correcta implementación de buenas prácticas para mitigar el IP spoofing en redes de la región de América Latina y el Caribe. A partir del análisis de los datos obtenidos, se recomiendan acciones para que las organizaciones puedan detectar y mitigar este problema. De esta forma no solo se da a conocer el estado de adhesión a las buenas prácticas, sino también se muestra que es posible mejorar el estado de la región para prevenir el spoofing de direcciones IP.</p>
                    <p>Estas estadísticas brindan al CSIRT de LACNIC información para trabajar directamente con las organizaciones asociadas y reducir los riesgos de que los recursos asignados a éstas sean utilizados con fines maliciosos.</p>
                    <Link className="buttons button_blue" to="/pt/ciberseguridad/estudio-anti-spoofing-en-la-region">Leer Reporte</Link>
                </>}
            </div>
        </div>
    )
} 