import React, { useContext  } from 'react';
import { LangContext } from '../../context/DataContext';
import { useParams, useLocation } from "react-router-dom"
import AtaquesDDoS from './AtaquesDDoS';
import DNSOpenResolvers from './DNSOpenResolvers';
import HistoriaDeExito from './HistoriaDeExito';
import VulnerabilidadAtaquesDdosMaster from '../reports/VulnerabilidadAtaquesDdos/VulnerabilidadAtaquesDdosMaster';
import Wrapper from '../../components/Wrapper';
import Reports from '../../components/Reports';
import NoMatch from '../../components/NoMatch';
import AntiSpoofing from '../reports/AntiSpoofing/AntiSpoofing';
import AntiSpoofingIntro from './AntiSpoofingIntro';

export default function Ciberseguridad(props) {
    const uselocation = useLocation();
    const url = uselocation.pathname;
    const { handleLanguaje } = useContext(LangContext)

    if (url.includes(`/es/`) === true) {
        handleLanguaje('es')
        return (
            <ContentReport />
        )
    } if (url.includes(`/en/`) === true) {
        handleLanguaje('en')
        return (
            <ContentReport />
        )
    } if (url.includes(`/pt/`) === true) {
        handleLanguaje('pt')
        return (
            <ContentReport />
        )
    }
}

function ContentReport() {
    const { slug } = useParams();
    if (slug === undefined) {
        return (
            <ReportMainWrapper >
                <div className="report_list"> 
                    <AntiSpoofingIntro />
                    <AtaquesDDoS />
                    <DNSOpenResolvers />
                    <HistoriaDeExito /> 
                </div>
            </ReportMainWrapper >
        )
    } if (slug === 'dns-open-resolvers-con-ipv4-que-involucran-recursos-bajo-la-administracion-de-lacnic') {
        return (
            <ReportMainWrapper>
                <DNSOpenResolvers />
            </ReportMainWrapper>
        )
    } if (slug === 'una-historia-de-exito-en-ciberseguridad') {
        return (
            <ReportMainWrapper>
                <HistoriaDeExito />
            </ReportMainWrapper>
        )
    } if (slug === 'estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos'){
        return(
            <ReportMainWrapperCiberseguridad menu_lateral='estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos'> 
                <VulnerabilidadAtaquesDdosMaster />
            </ReportMainWrapperCiberseguridad>
        )
    } if(slug==='estudio-anti-spoofing-en-la-region'){
        return(
            <ReportMainWrapperCiberseguridad menu_lateral="estudio-anti-spoofing-en-la-region">
                 <AntiSpoofing />
            </ReportMainWrapperCiberseguridad>
        )
    }else return <NoMatch />
}

function ReportMainWrapperCiberseguridad({ children, menu_lateral }) {
    const { slug } = useParams();
    return (
        <Wrapper footer='reports'
            url_es={`/es/ciberseguridad/${slug === undefined ? '' : slug}`}
            url_en={`/en/ciberseguridad/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/ciberseguridad/${slug === undefined ? '' : slug}`}
            menu_lateral={menu_lateral}
        >
            <ReportTitle />
            {children} 
        </Wrapper>
    )
}

function ReportMainWrapper({ children }) {
    const { slug } = useParams();
    return (
        <Reports category="ciberseguridad" 
            url_es={`/es/ciberseguridad/${slug === undefined ? '' : slug}`}
            url_en={`/en/ciberseguridad/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/ciberseguridad/${slug === undefined ? '' : slug}`}>
            <ReportTitle />
            {children}
        </Reports>
    )
}

function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
        <p>
            {myLang === 'es' && <>  Informes técnicos, Ciberseguridad </>}
            {myLang === 'en' && <>  Technical Reports, Cibersecurity </>}
            {myLang === 'pt' && <>  Relatórios técnicos, Cibersegurança </>}
        </p>
    )
}
