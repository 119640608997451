import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function SecondBGPLac(props) {
    const { myLang } = useContext(LangContext)
    return (   
        <div>    
            <div>
                {myLang === 'es' && <> 
                    <h1>Interconexión BGP a nivel local en la región de AméricaLatina y el Caribe</h1>
                    <p>Autor: Augusto Mathurín</p>
                    <p>El estudio previo se enfocó en conocer la situación actual de las conexiones entre los países latinoamericanos a partir de las tablas de ruteo BGP, registradas y almacenadas por colectores globales de RIPE NCC. Este estudio analizó las tablas BGP de los IXP para poder conocer con mayor detalle qué está sucediendo a nivel local y si la conectividad que reflejan estas tablas coincide con los resultados a nivel global.</p>
                    <Button color="button_blue" to="/es/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">Leer Reporte</Button>

                </>}
                {myLang === 'en' && <> 
                    <h1>Local BGP Interconnection in Latin America and the Caribbean</h1>
                    <p>Author: Augusto Mathurín</p>
                    <p>The previous study focused on identifying the status of the connections between the countries of Latin America based on the BGP routing tables registered and stored by RIPE NCC's global collectors. This study analyzes IXP BGP tables to gain a more detailed understanding of what is going on at the local level and whether the connectivity reflected in these tables matches the results at the global level.</p>
                    <Button color="button_blue" to="/en/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">Read Report</Button> 
                </>}
                {myLang === 'pt' && <> 
                    <h1>Interconexão BGP no nível local na região da América Latina e o Caribe</h1>
                    <p>Autor: Augusto Mathurín</p>
                    <p>O estudo anterior esteve focado em conhecer a situação atual das conexões entre os países da América Latina a partir das tabelas de roteamento BGP, registradas e armazenadas pelos coletores globais do RIPE NCC. Este estudo analisou as tabelas BGP dos IXP, a fim de conhecer com mais detalhes o que está acontecendo no nível local, e se a conectividade refletida nessas tabelas coincide com os resultados no nível global.</p>
                    <Button color="button_blue" to="/pt/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">LEIA O RELATÓRIO</Button>  
                </>}
            </div>
        </div>  
    );
}


 
export default SecondBGPLac;