import React, { useContext } from 'react';
import { MatchText } from 'react-ctrl-f';
import { LangContext, ReferenceContext } from '../../../context/DataContext';


export default function Introduccion(props) {
    const { myLang } = useContext(LangContext)
    const { scrollToReference } = useContext(ReferenceContext)
    return (
        <div>
            <div>
                {myLang === 'es' && <>
                    <h2 id="bgp2">
                        <MatchText id="match-5">Introducción</MatchText> </h2>
                    <p>
                        <MatchText id="match-6">El desarrollo de Internet y la calidad en la conectividad de sus usuarios depende de una buena infraestructura en las comunicaciones y una buena conectividad entre países. En América Latina, aún existen deficiencias en este aspecto, lo que se traduce en altas latencias en las conexiones de muchos habitantes de la región. El principal motivo de estas latencias es la falta de interconexión local entre los distintos operadores de red. Entonces, el tráfico entre países cercanos, muchas veces, debe ser intercambiado en puntos de intercambio lejanos, ubicados en Estados Unidos o Europa.</MatchText></p>
                    <p>
                        <MatchText id="match-7">La aparición de muchos puntos de intercambio de tráfico (IXP) ha ayudado a mejorar esta situación, aunque el estado real de la conectividad entre los países y redes es un gran misterio. Para despejar estas incógnitas, LACNIC desarrolló hace un tiempo el Proyecto Simón, con el cual se busca generar información midiendo los niveles de latencia entre los países y, con esto, estimar los volúmenes de tráfico.</MatchText></p>
                    <p>
                        <MatchText id="match-8">Para complementar la información obtenida con el Proyecto Simón</MatchText> <a href="#ref-1" onClick={() => scrollToReference('ref-1')}> (1)</a>
                        <MatchText id="match-9">, con este estudio de interconexión, se procurará conocer la situación actual de las conexiones entre los países latinoamericanos a partir de una fuente distinta de información: las tablas de ruteo BGP registradas y almacenadas por colectores globales.</MatchText></p>
                </>}
                {myLang === 'en' && <>
                    <h2 id="bgp2">
                        <MatchText id="match-5">Introduction</MatchText></h2>
                    <p>
                        <MatchText id="match-6">Internet development and the quality of user connectivity depend on the existence of good communications infrastructure and proper connectivity between countries. In Latin America, there are still some deficiencies in this regard which result in many people experiencing high latencies in their connections. The main reason for these latencies is the lack of local interconnection between different network operators, which means that traffic between nearby countries must often use distant Internet exchange points, located in the United States or Europe.</MatchText></p>
                    <p>
                        <MatchText id="match-7">The deployment of various Internet exchange points (IXPs) has helped improve this situation, although the actual status of connectivity between countries and networks remains a mystery. To find answers to these unknowns, some time ago LACNIC created Simón1, a project that seeks to generate information by measuring latency levels between countries and in this way estimate traffic volumes.</MatchText></p>
                    <p>
                        <MatchText id="match-8">To complement the information obtained by the Simón Project</MatchText><a href="#ref-1" onClick={() => scrollToReference('ref-1')}> (1)</a>
                        <MatchText id="match-9">, this interconnection study will attempt to understand the current status of the connections between the countries of Latin America based on a different source of information, namely, the BGP routing tables registered and stored by global collectors</MatchText></p>
                </>}
                {myLang === 'pt' && <>
                    <h2 id="bgp2">
                        <MatchText id="match-5">Introdução</MatchText></h2>
                    <p>
                        <MatchText id="match-6">O desenvolvimento da Internet e a qualidade da conectividade de seus usuários dependem de uma boa infraestrutura nas comunicações e de uma boa conectividade entre países. Na América Latina, ainda há deficiências nesse sentido, o que se traduz em altas latências nas conexões de muitos habitantes da região. O principal motivo para essas latências é a falta de interconexão local entre as diferentes operadoras de rede. Assim, o tráfego entre países próximos, muitas vezes, deve ser trocado em pontos de troca distantes, localizados nos Estados Unidos ou na Europa.</MatchText></p>
                    <p>
                        <MatchText id="match-7">O surgimento de muitos pontos de troca de tráfego (IXPs) ajudou a melhorar esta situação, embora o estado real da conectividade entre os países e as redes seja um grande mistério. Para esclarecer essas incógnitas, LACNIC desenvolveu há algum tempo o Projeto Simón1 , que busca gerar informações medindo os níveis de latência entre os países e com isso estimar os volumes de tráfego.</MatchText></p>
                    <p>
                        <MatchText id="match-8">Para complementar as informações obtidas com o Projeto Simón </MatchText><a href="#ref-1" onClick={() => scrollToReference('ref-1')}> (1)</a>,
                        <MatchText id="match-9">  este estudo de interconexão buscará conhecer a situação atual das conexões entre os países da América Latina a partir de uma fonte diferente de informações: as tabelas de roteamento BGP, cadastradas e armazenadas por coletores globais.</MatchText></p>
                </>}
            </div>
        </div>
    )
}