import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import '../css/404.css' 

export default function NoMatch(){
    const uselocation = useLocation();
    const url = uselocation.pathname;
    return(
        <> 
            <div className="page_not_found"> 
                <h2><strong>404.</strong> <br /> Hay un error</h2>
                <Link to="https://www.lacnic.net/" title="LACNIC" >
                    <img src="/lacnic.svg" alt="LACNIC" title="LACNIC" width="200px" />
                </Link> 
                <p>No hemos encontrado <code>{url}</code> en nuestro servidor.</p> 
                <Link to="/" >LACNIC</Link> 
            </div>  
        </>
    )
}