import React, { useContext } from 'react'
import { LangContext } from '../../context/DataContext'
import Button from '../../components/Button'

function EstudioConectividad2022() {
    const {myLang} = useContext(LangContext)
    return (
        <div> 
            <div>
                { myLang === 'es' && <> 
                    <h1>Análisis de los caminos del tráfico dentro de cada país de la región LAC</h1>
                    <p>Autor: Agustín Formoso y Guillermo Cicileo</p>
                    <p>Este documento abarca las mediciones realizadas de forma interna a un país: tráfico con origen en un país y con destino dentro el mismo país.</p>
                    <Button color="button_blue" to="/es/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac">Leer Reporte</Button>
                </>}
                { myLang === 'en' && <> 
                <h1>Analysis of the paths followed by traffic within each country of LAC region</h1>
                    <p>Autor: Agustín Formoso y Guillermo Cicileo</p>
                    <p>Este documento abarca las mediciones realizadas de forma interna a un país: tráfico con origen en un país y con destino dentro el mismo país.</p>
                    <Button color="button_blue" to="/en/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac">READ REPORT</Button>
                </> }
                { myLang === 'pt' && <> 
                <h1>Análise das rotas do tráfego dentro de cada país da região LAC</h1>
                    <p>Autor: Agustín Formoso y Guillermo Cicileo</p>
                    <p>Este documento abarca las mediciones realizadas de forma interna a un país: tráfico con origen en un país y con destino dentro el mismo país.</p>
                    <Button color="button_blue" to="/pt/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac">LEIA O RELATÓRIO</Button>
                </>}
            </div> 
        </div>
    )
}

export default EstudioConectividad2022
