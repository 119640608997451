import React, { useContext } from 'react';
import { MenuContext, MobileContext}  from '../context/DataContext';
import { SearchContext, SearchEventContext } from 'react-ctrl-f'; 

export const SearchComponent = () => {
  const { searchValue, activeCount, totalCount } = useContext(SearchContext);
  const { onSearchChange, onPrev, onNext } = useContext(SearchEventContext);

  const {  openIndice } = useContext(MenuContext)
  const { isMobile } = useContext(MobileContext) 

  const nextSearch = () => {
    onNext();
    openIndice();
  }
 
  return (
    <> 
    <div className="search-component" >
      <i className="fas fa-search"></i>
      <input 
        value={searchValue}
        onChange={onSearchChange}
        onKeyDown={onNext} 
      />
      <button   onClick={onPrev}>
        <i className="far fa-arrow-alt-circle-up"></i>
      </button>
      {searchValue &&<span >
        {activeCount}/{totalCount}
      </span> }
      { !searchValue && <span>0</span>}
       
      <button  onClick={nextSearch}>
        <i className="far fa-arrow-alt-circle-down"></i>
      </button>  
    </div>
    {isMobile && <button onClick={nextSearch}>Buscar</button>}
    </>
  );
};

export default SearchComponent;