import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function EncuestaISPs(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p10"> 
                {myLang === 'es' && <>
                <h2>Encuestas a ISPs</h2>
                <p>El objetivo principal de las encuestas fue conocer:</p>
                <ul>
                    <li>Los CPEs m&aacute;s utilizados para dar IPv6.</li>
                    <li>Los mecanismos de transici&oacute;n de IPv4aaS soportados por dichos CPEs.</li>
                </ul>
                <p>Las encuestas fueron  dirigidas a los ISPs de la regi&oacute;n v&iacute;a Formularios de Google en idiomas Espa&ntilde;ol  y Portugu&eacute;s.</p>
                <p>Se obtuvieron las  siguientes cantidad de respuestas concretas por Idioma:</p>
                <table className='table'>
                    <tr><td>Idioma Encuesta</td><td>Cantidad Respuestas</td></tr>
                    <tr><td>Esta&ntilde;ol</td><td>14</td></tr>
                    <tr><td>Portugu&eacute;s</td><td>14</td></tr>
                </table>
                <p>Ambas encuestas se encuentran disponibles en:</p>
                <ul>
                    <li>Espa&ntilde;ol: <a href="https://forms.gle/zzJTo1FnYBNS2ahJ8"  rel="noreferrer" target="_blank">https://forms.gle/zzJTo1FnYBNS2ahJ8</a></li>
                    <li>Portugu&eacute;s: <a href="https://forms.gle/ddwiLeBnVXkNAyHV9"  rel="noreferrer" target="_blank">https://forms.gle/ddwiLeBnVXkNAyHV9</a></li>
                </ul>

                <h3>Resumen de Resultado de Encuestas</h3>


                <table className='table'>
                    <tr>
                        <td><p >Acceso </p></td>
                        <td><p >Marca </p></td>
                        <td><p >Modelo </p></td>
                        <td><p >Mecanismos <br />
                            de Transici&oacute;n </p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>Sagemcom</p></td>
                        <td><p>F@st 3890 v2</p></td>
                        <td><p>DS-Lite, 464XLAT</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>Sagemcom</p></td>
                        <td><p>F@st 3890 v3</p></td>
                        <td><p>DS-Lite, MAP-T</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>Sagemcom</p></td>
                        <td><p>F@st 3686</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>Cisco</p></td>
                        <td><p>3890</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>RB2011</p></td>
                        <td><p>lw4o6</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Cisco</p></td>
                        <td><p>9001</p></td>
                        <td><p>DS-Lite, MAP-E</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Cisco</p></td>
                        <td><p>1001</p></td>
                        <td><p>DS-Lite, MAP-E</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Cisco</p></td>
                        <td><p>920</p></td>
                        <td><p>DS-Lite, MAP-E</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8245W5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8245Q2</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8546M</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8545M5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8545V5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8546M</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>EG8240H</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>EG8145V5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>EG8245Q</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F623</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F627</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>HITRON</p></td>
                        <td><p>CGNV2</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>UBEE</p></td>
                        <td><p>UBC1310-BC00</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>HITRON</p></td>
                        <td><p>CGNV4-UNE</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>UBEE</p></td>
                        <td><p>UBC1307CB00</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>Nanostation M2</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>Litebean 5AC Gen2</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR850N v3</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>OpenWrt</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>UBEE</p></td>
                        <td><p>DDW365</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>UBEE</p></td>
                        <td><p>DDW3611</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F660 V6</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F660</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F623</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Nexxt</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>ZTE</p></td>
                        <td><p>F660</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>D-Link</p></td>
                        <td><p>DSL-124</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>xDSL</p></td>
                        <td><p>D-Link</p></td>
                        <td><p>DSL-2730E</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>GREATEK</p></td>
                        <td><p>GWR 1200AC</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>Cable</p></td>
                        <td><p>TENDA</p></td>
                        <td><p>AC1200</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>INTELBRAS</p></td>
                        <td><p>ACTION RG1200</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>FIBERHOME</p></td>
                        <td><p>5506-04-FA</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>AirGrid M5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>PowerBean M5</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>FIBERHOME</p></td>
                        <td><p>AN5506-01A</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>FIBERHOME</p></td>
                        <td><p>AN5506-02B</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>hAP Lite</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR840N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>ARCHER C5</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>ARCHER C6</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>Archer C20</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>CHIMA</p></td>
                        <td><p>SGN8668MVWG2 (Custom)</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Fiberhome</p></td>
                        <td><p>AN5506-02-B (B7G)</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Fiberhome</p></td>
                        <td><p>AN5502-FG (F1G)</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Fiberhome</p></td>
                        <td><p>AN5506-04-FG (F4G)</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>AirOS 8</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>AirOS 6</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Intelbr&aacute;s</p></td>
                        <td><p>WOM    5000 MIMO</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR849N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Intelbras</p></td>
                        <td><p>IWR3000N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR949N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>hAP mini</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>hAP AC²</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR840N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>ARCHER C20</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>C5W</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>ARCHER C20</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>ARCHER C5</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>MikroTik</p></td>
                        <td><p>RB951ui-2hnd</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Anlix</p></td>
                        <td><p>Firmware personalizada &quot;Flashbox&quot;</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8245Q2</p></td>
                        <td><p>DS-Lite, MAP-T</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HG8546M</p></td>
                        <td><p>DS-Lite, MAP-T</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>Huawei</p></td>
                        <td><p>HS8546V</p></td>
                        <td><p>DS-Lite, MAP-T</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR840N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>TP-LINK</p></td>
                        <td><p>TL-WR940N</p></td>
                        <td><p>&nbsp;</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>D-Link</p></td>
                        <td><p>Dir-819</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>D-Link</p></td>
                        <td><p>Dir-615</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>HUAWEI</p></td>
                        <td><p>HG8546M</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>HUAWEI</p></td>
                        <td><p>HG8346M</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>HUAWEI</p></td>
                        <td><p>HS8145V</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                    <tr>
                        <td><p>FTTH</p></td>
                        <td><p>HUAWEI</p></td>
                        <td><p>HG8245Q2</p></td>
                        <td><p>DS-Lite</p></td>
                    </tr>
                </table>

                <h3>Informaci&oacute;n obtenida de las encuestas</h3>
                <p>A continuaci&oacute;n, se realizar&aacute;n diferentes extractos de informaci&oacute;n en base a los datos obtenidos de las respuestas a las encuestas realizadas a los ISPs de Am&eacute;rica Latina. Con esta informaci&oacute;n se conocer el principalmente cuales los modelos de CPEs m&aacute;s populares, los fabricantes con mayor presencia y los mecanismos de transici&oacute;n m&aacute;s soportados dentro del esquema de IPv4aaS.</p>

                <h4>Cantidad de ISPs que brindan IPv6 a sus clientes</h4>
                <p>Se detalla la cantidad de ISPs que actualmente est&aacute;n brindando IPv6 a sus clientes finales, de los que respondieron las encuestas.</p>

                <table class="table">
                    <tr><td>¿Actualmente brindan servicio de Internet con IPv6?</td><td>Cantidad</td></tr>
                    <tr><td>No</td><td>14</td></tr>
                    <tr><td>Si</td><td>29</td></tr>
                    <tr><th>Suma Total</th><th>43</th></tr>
                </table>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen8.png" alt="Ver" /></figure>
                </div>
                <p>De un total de 43 ISPs que respondieron las encuestas, 29 de ellos (67.4%) est&aacute;n brindando IPv6 a sus clientes.</p>

                <h4>Mecanismos de Transici&oacute;n IPv4aaS soportados</h4>
                <table className='table'>
                    <tr>
                        <td><p ><em>Mecanismos de Transici&oacute;n</em></p></td>
                        <td><p ><em>Mecanismos de Transici&oacute;n 2</em></p></td>
                        <td><p >Cantidad </p></td>
                        <td><p >Porcentaje </p></td>
                    </tr>
                    <tr>
                        <td><p>DS-Lite</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >35</p></td>
                        <td><p >79,55%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>464XLAT</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>MAP-E</p></td>
                        <td><p >3</p></td>
                        <td><p >6,82%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>MAP-T</p></td>
                        <td><p >4</p></td>
                        <td><p >9,09%</p></td>
                    </tr>
                    <tr>
                        <td><p>lw4o6</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Suma total</strong></p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p ><strong>44</strong></p></td>
                        <td><p ><strong>100,00%</strong></p></td>
                    </tr>
                </table>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen9.png" alt="Ver" /></figure>
                </div>
                <p><strong>DS-Lite</strong> es el mecanismo de transici&oacute;n m&aacute;s soportado, con un <strong>79.5%</strong> de presencia.</p>

                <h4>Detalle de Fabricantes y Modelos de CPEs</h4>
                <p>Se detallan los Fabricantes y cada uno de los sus modelos de CPEs identificados con soporte de alg&uacute;n mecanismo de transici&oacute;n.</p>
                <table className='table'>
                    <tr>
                        <td><p><em>Marca</em></p></td>
                        <td><p><em>Modelo</em></p></td>
                        <td><p>Cantidades </p></td>
                        <td><p>Porcentajes </p></td>
                    </tr>
                    <tr>
                        <td><p>D-Link</p></td>
                        <td><p>Dir-615</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>Dir-819</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>DSL-124</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>DSL-2730E</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total D-Link</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >4</p></td>
                        <td><p >10,81%</p></td>
                    </tr>
                    <tr>
                        <td><p>FIBERHOME</p></td>
                        <td><p>AN5506-01A</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>AN5506-02B</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total FIBERHOME</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >2</p></td>
                        <td><p >5,41%</p></td>
                    </tr>
                    <tr>
                        <td><p>HITRON</p></td>
                        <td><p>CGNV2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>CGNV4-UNE</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total HITRON</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >2</p></td>
                        <td><p >5,41%</p></td>
                    </tr>
                    <tr>
                        <td><p>Huawei</p></td>
                        <td><p>EG8145V5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>EG8240H</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>EG8245Q</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8245Q2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8245W5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8346M</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8545M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8545V5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HG8546M</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HS8145V</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>HS8546V</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total Huawei</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >11</p></td>
                        <td><p >29,73%</p></td>
                    </tr>
                    <tr>
                        <td><p>MikroTik</p></td>
                        <td><p>RB2011</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total MikroTik</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Sagemcom</p></td>
                        <td><p>F@st 3686</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>F@st 3890 v2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>F@st 3890 v3</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total Sagemcom</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >3</p></td>
                        <td><p >8,11%</p></td>
                    </tr>
                    <tr>
                        <td><p>Technicolor</p></td>
                        <td><p>920</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>1001</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>9001</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>DPC3890</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total Technicolor</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >4</p></td>
                        <td><p >10,81%</p></td>
                    </tr>
                    <tr>
                        <td><p>TP-LINK</p></td>
                        <td><p>OpenWrt</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>TL-WR850N v3</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total TP-LINK</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >2</p></td>
                        <td><p >5,41%</p></td>
                    </tr>
                    <tr>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p>AirGrid M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>Litebean 5AC Gen2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>Nanostation M2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>PowerBean M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total Ubiquiti Networks</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >4</p></td>
                        <td><p >10,81%</p></td>
                    </tr>
                    <tr>
                        <td><p>ZTE</p></td>
                        <td><p>F623</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>F627</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>F660</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>&nbsp;</p></td>
                        <td><p>F660 V6</p></td>
                        <td><p >1</p></td>
                        <td><p >2,70%</p></td>
                    </tr>
                    <tr>
                        <td><p>Total ZTE</p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p >4</p></td>
                        <td><p >10,81%</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Suma total</strong></p></td>
                        <td><p>&nbsp;</p></td>
                        <td><p ><strong>37</strong></p></td>
                        <td><p ><strong>100,00%</strong></p></td>
                    </tr>
                </table>

                <h4>Modelos de CPE por Fabricante</h4>
                <table className='table'>
                    <tr>
                        <td><p><em>Marca</em></p></td>
                        <td><p>Cantidad </p></td>
                    </tr>
                    <tr>
                        <td><p>D-Link</p></td>
                        <td><p >4</p></td>
                    </tr>
                    <tr>
                        <td><p>FIBERHOME</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>HITRON</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>Huawei</p></td>
                        <td><p >11</p></td>
                    </tr>
                    <tr>
                        <td><p>MikroTik</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p>Sagemcom</p></td>
                        <td><p >3</p></td>
                    </tr>
                    <tr>
                        <td><p>Technicolor</p></td>
                        <td><p >4</p></td>
                    </tr>
                    <tr>
                        <td><p>TP-LINK</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p >4</p></td>
                    </tr>
                    <tr>
                        <td><p>ZTE</p></td>
                        <td><p >4</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Suma total</strong></p></td>
                        <td><p ><strong>37</strong></p></td>
                    </tr>
                </table>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen10.png" alt="Ver" /></figure>
                </div>
                <p>Se puede observar que, de acuerdo a las encuestas, Huawei es el fabricante con mayor cantidad de modelos de CPEs desplegados en la regi&oacute;n.</p>

                <h4>Cantidad de ISPs por Modelo de CPE</h4>
                <p>A continuaci&oacute;n, se identifican la cantidad de ISPs que utilizan cada Modelo de CPE.</p>
                <table className='table'>
                    <tr>
                        <td><p><em>Modelo</em></p></td>
                        <td><p>Cantidad Operadores </p></td>
                        <td><p>Porcentajes </p></td>
                    </tr>
                    <tr>
                        <td><p>920</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>1001</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>9001</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>AirGrid M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>AN5506-01A</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>AN5506-02B</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>CGNV2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>CGNV4-UNE</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>Dir-615</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>Dir-819</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>DPC3890</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>DSL-124</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>DSL-2730E</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>EG8145V5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>EG8240H</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>EG8245Q</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>F@st 3686</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>F@st 3890 v2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>F@st 3890 v3</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>F623</p></td>
                        <td><p >2</p></td>
                        <td><p >4,55%</p></td>
                    </tr>
                    <tr>
                        <td><p>F627</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>F660</p></td>
                        <td><p >2</p></td>
                        <td><p >4,55%</p></td>
                    </tr>
                    <tr>
                        <td><p>F660 V6</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8245Q2</p></td>
                        <td><p >3</p></td>
                        <td><p >6,82%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8245W5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8346M</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8545M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8545V5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HG8546M</p></td>
                        <td><p >4</p></td>
                        <td><p >9,09%</p></td>
                    </tr>
                    <tr>
                        <td><p>HS8145V</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>HS8546V</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>Litebean 5AC Gen2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>Nanostation M2</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>OpenWrt</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>PowerBean M5</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>RB2011</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p>TL-WR850N v3</p></td>
                        <td><p >1</p></td>
                        <td><p >2,27%</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Suma total</strong></p></td>
                        <td><p ><strong>44</strong></p></td>
                        <td><p ><strong>100,00%</strong></p></td>
                    </tr>
                </table>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen11.png" alt="Ver" /></figure>
                </div>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen12.png" alt="Ver" /></figure>
                </div>
                <p>Huawei es el fabricante con mayor presencia en distintos ISPs por modelo de CPE.</p>

                <h4>Cantidad de ISPs por Fabricante de CPE</h4>
                <p>Se espera identificar el o los fabricantes de CPEs con mayor presencia en los distintos ISPs.</p>
                <table className='table'>
                    <tr>
                        <td><p><em>Marca</em></p></td>
                        <td><p>Cantidad ISPs </p></td>
                    </tr>
                    <tr>
                        <td><p>Huawei</p></td>
                        <td><p >5</p></td>
                    </tr>
                    <tr>
                        <td><p>ZTE</p></td>
                        <td><p >4</p></td>
                    </tr>
                    <tr>
                        <td><p>Ubiquiti Networks</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>Technicolor</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>D-Link</p></td>
                        <td><p >2</p></td>
                    </tr>
                    <tr>
                        <td><p>TP-LINK</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p>Sagemcom</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p>MikroTik</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p>HITRON</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p>FIBERHOME</p></td>
                        <td><p >1</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Suma total</strong></p></td>
                        <td><p ><strong>17</strong></p></td>
                    </tr>
                </table>
                <div>
                    <figure><img src="/images/ipv6-en-equipos-cpe/Imagen13.png" alt="Ver" /></figure>
                </div>
                <p>Aqu&iacute; se puede observar que el fabricante con mayor presencia en ISPs es Huawei, seguido por ZTE.</p>

                <h3>Resumen de Encuestas</h3>
                <p>La informaci&oacute;n valiosa brindada por los ISPs que  participaron de la encuesta la podemos   resumir en:</p>
                <ul>
                    <li><strong>Porcentaje de ISPs que dan IPv6</strong>: El 67% de los  ISPs dan IPv6. Es un n&uacute;mero alto respecto a total de la regi&oacute;n ya que  seguramente participaron m&aacute;s los ISPs que si est&aacute;n brindando.</li>
                    <li><strong>Mecanismo de transici&oacute;n L&iacute;der</strong>: <strong>DS-Lite </strong>es el mecanismo de transici&oacute;n  l&iacute;der y est&aacute; presente en el <strong>79.5%</strong> de  los CPEs identificados.</li>
                    <li><strong>Modelos de CPE por Fabricante</strong>: De todos los  modelos de CPEs identificaron se encontr&oacute; que hay <strong>11 modelos</strong> pertenecientes a <strong>Huawei</strong>,  de un total de 37. De esta manera Huawei posee un <strong>29.7%</strong> de todos los modelos de CPEs declarados.</li>
                    <li><strong>Cantidad de ISPs por Modelo de CPE</strong>: Aqu&iacute; vemos  que el 1º y 2º lugar corresponden a CPEs de Huawei presentes en 4 y 3 ISPs  respectivamente.</li>
                    <li><strong>Cantidad de ISPs por Fabricante</strong>: Nuevamente  Huawei lidera estando presente en el 25% de los ISPs, seguido por ZTE con el  20%.</li>
                </ul>

                <h3>Conclusi&oacute;n de Encuestas</h3>
                <p>Como conclusi&oacute;n podemos obtener que:</p>
                <ul>
                    <li><strong>Mecanismo de transici&oacute;n L&iacute;der</strong>: <strong>DS-Lite</strong> lidera con un <strong>79.5%</strong> entre todos los mecanismos de  transici&oacute;n. En base a esto se pueden <strong>focalizar  los entrenamientos y recomendaciones</strong> en la regi&oacute;n.</li>
                    <li><strong>Fabricantes l&iacute;deres</strong>: Se identifican a<strong> Huawei en 1º lugar seguido por ZTE</strong> como fabricantes l&iacute;deres con mayor presencia de CPEs en distintos ISPs. Esto  podr&iacute;a permitir un <strong>trabajo en conjunto  con dichos fabricantes</strong> para promover la implementaci&oacute;n de los mecanismos de  transici&oacute;n.</li>
                </ul>
            </>}
            </div>
        </div>
    )
}

export default EncuestaISPs
