import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import { Link } from 'react-router-dom'

function OptimizacionIBgp() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div>
                {myLang === 'es' && <IntroContent/>}
                {myLang === 'en'  && <IntroContent/>}
                {myLang === 'pt'  && <IntroContent/>}
            </div>
        </div>
    )
}

function IntroContent() {
    return (
        <>
            <h1>Optimización de redes mediante un mejor iBGP</h1>
            <p>Autores: Carlos Martínez, Claudio Risso, Cristina Mayr, José Restaino </p>
            <p> En este documento se introduce un trabajo de investigación que busca lograr una aproximación sistemática para el problema del diseño de la malla iBGP, aproximación a la que llamamos “Clases de Equivalencia de Prefijos en BGP”. Se muestra cómo esta técnica podría ayudar a un operador a optimizar su red.</p>
            <Link className="buttons button_blue" to="/es/interconexion/optimizacion-de-redes-mediante-un-mejor-ibgp">Leer reporte</Link> 
        </>
    )
}

export default OptimizacionIBgp
