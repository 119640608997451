import React, {useCallback, useContext} from "react"; 
import { SearchProvider } from 'react-ctrl-f';
import '../css/generic.css'
import '../css/reports.css'
import Footer from "./Footer";
import Header from "./Header";
import MenuLateralAllReports from "./MenuLateralAllReports"; 
import { MobileContext, MenuContext } from '../context/DataContext'

export default function Wrapper({ children, url_en, url_es, url_pt, menu_lateral, setSearchTerm, searchTerm, handleSearch, footer}){ 
    const onCustomScroll = useCallback((id, fixedHeaderHeight) => {
        const dom = document.getElementById(id);
        if (dom) {
            const topOfElement =
                dom.getBoundingClientRect().bottom +
                window.pageYOffset -
                fixedHeaderHeight;
            window.scroll({
                top: topOfElement,
                behavior: 'smooth',
            });
        }
        
    }, []);

    const {isMobile} = useContext(MobileContext)
    const { isIndice, openIndice } = useContext(MenuContext)
    
     

    return(
        <> 
        <Header url_es={`${url_es}`} url_en={`${url_en}`} url_pt={`${url_pt}`} />
        <div className="report_work_table"> 
            <div className="report_main_wrapper">
                <div> 
                 
                <SearchProvider
                        value={{
                            fixedHeaderHeight: 190,
                            onScroll: onCustomScroll,
                        }}
                    > 
                        {!isMobile &&<aside className={`lateral_menu_wrapper`}><MenuLateralAllReports menu_lateral={menu_lateral} setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} /></aside>}
                        {isMobile && isIndice && <aside className={`lateral_menu_wrapper mobile_lateral_menu`}><MenuLateralAllReports menu_lateral={menu_lateral} setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch}/></aside>}
                        <article className="report_content_wrapper">
                            <section> 
                                {children}
                            </section>
                        </article>
                        {/* ${isMobile && "hide_lateral_menu"} */}
                         
                </SearchProvider>
                </div>
            </div>
        </div>
        { isMobile && 
            <div className="report_button_menu" onClick={openIndice}>
                <div>
                    {!isIndice && <i className="fas fa-list"></i>}
                    {isIndice && <i className="fas fa-times"></i>}
                </div>
            </div>
        }
         
        { !isMobile ? <Footer footer='reports'  className="master_foot_reports foot_report"  />:  <Footer className="master_foot_reports basic_foot" />}

        </>
    )
}
 