import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Objetivos() {
    const { myLang } = useContext(LangContext)
    return (
        <> 
        <div>
            <div id='p2'>
                {myLang === 'es' && <>
                    <h2>Objetivo</h2>
                    <p>Medir la disponibilidad de la tecnolog&iacute;a&nbsp;<strong>DNS-over-TLS</strong>&nbsp;en&nbsp;<em>resolvers</em>&nbsp;DNS en la regi&oacute;n de Am&eacute;rica Latina y el Caribe.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Goal</h2>
                    <p>To measure the availability of&nbsp;<strong>DNS-over-TLS</strong>&nbsp;technology in DNS resolvers in the region of Latin America and the Caribbean.</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Objetivo</h2>
                    <p>Medir a disponibilidade da tecnologia&nbsp;<strong>DNS-over-TLS</strong>&nbsp;en&nbsp;<em>resolvers</em>&nbsp;na regi&atilde;o da Am&eacute;rica Latina e o Caribe.</p>
                </>}
            </div>
        </div>
        </>
    )
}

export default Objetivos
