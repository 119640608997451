import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext'

function ConsultaFabricantesCPEs() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p11">
                {myLang === 'es' && <>
                    <h2>Consultas a Fabricantes de CPEs</h2>
                    <p>A trav&eacute;s del contacto directo con los fabricantes l&iacute;deres del mercado se desean conocer los modelos de CPEs que mayormente tienen desplegados en la regi&oacute;n y cuales los mecanismos de transici&oacute;n soportados por los mismos.</p>

                    <h3>Fabricantes consultados</h3>
                    <p>Se enviaron consultas a diferentes fabricantes y pudimos obtener colaboraci&oacute;n de los siguientes:</p>
                    <ul>
                        <li>ZTE</li>
                        <li>Sagemcom</li>
                        <li>Technicolor</li>
                    </ul>

                    <h3>Detalle de Informaci&oacute;n por Fabricante</h3>
                    <h4>ZTE</h4>
                    <table className='table'>
                        <tr>
                            <td><p>Fabricante</p></td>
                            <td><p>Tecnolog&iacute;a</p></td>
                            <td><p>Modelo</p></td>
                            <td><p>Mecanismo de Transici&oacute;n</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>FTTH</p></td>
                            <td><p>F660v5.2</p></td>
                            <td><p>DS-Lite / Dual Stack</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>FTTH</p></td>
                            <td><p>F670v1</p></td>
                            <td><p>Dual Stack</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>FTTH</p></td>
                            <td><p>F680v6</p></td>
                            <td><p>Dual Stack</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>FTTH</p></td>
                            <td><p>F668A</p></td>
                            <td><p>DS-Lite / Dual Stack</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>FTTH</p></td>
                            <td><p>F260A</p></td>
                            <td><p>DS-Lite</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>xDSL</p></td>
                            <td><p>H108N</p></td>
                            <td><p>DS-Lite / Dual Stack</p></td>
                        </tr>
                        <tr>
                            <td><p>ZTE</p></td>
                            <td><p>xDSL</p></td>
                            <td><p>H167A</p></td>
                            <td><p>Dual Stack</p></td>
                        </tr>
                    </table>

                    <h4>Sagemcom</h4>
                    <table className='table'>
                        <tr>
                            <td><p>Model</p></td>
                            <td><p>Dual Stack</p></td>
                            <td><p>DS Lite</p></td>
                            <td><p>MAP-T</p></td>
                            <td><p>MAP-E</p></td>
                            <td><p>464XLAT</p></td>
                        </tr>
                        <tr>
                            <td><p>F3284</p></td>
                            <td><p>yes</p></td>
                            <td><p>No</p></td>
                            <td><p>No</p></td>
                            <td><p>no</p></td>
                            <td><p>No</p></td>
                        </tr>
                        <tr>
                            <td><p>F3486</p></td>
                            <td><p>yes</p></td>
                            <td><p>No</p></td>
                            <td><p>No</p></td>
                            <td><p>no</p></td>
                            <td><p>No</p></td>
                        </tr>
                        <tr>
                            <td><p>F3686 v1 and V2</p></td>
                            <td><p>yes</p></td>
                            <td><p>No</p></td>
                            <td><p>no</p></td>
                            <td><p>no</p></td>
                            <td><p>no</p></td>
                        </tr>
                        <tr>
                            <td><p>F3890</p></td>
                            <td><p>yes</p></td>
                            <td><p>No</p></td>
                            <td><p>No plan</p></td>
                            <td><p>No plan</p></td>
                            <td><p>Can be developed on SWAN</p></td>
                        </tr>
                    </table>
                    <p>Si bien Sagemcom presenta soporte de 464XLAT para el modelo de CableModem Fast3890, el mismo se encuentra en fase experimental y cabe destacar que es el &uacute;nico fabricante de CableModems que hoy est&aacute; trabajando en 464XLAT.</p>

                    <h4>Technicolor</h4>
                    <table className='table'>
                        <tr>
                            <td><strong>MODEL</strong></td>
                            <td><strong>Desc</strong></td>
                            <td><strong>Easy Mesh Support</strong></td>
                            <td><strong>IPv6 Support</strong></td>
                            <td><strong>DS-Lite</strong></td>
                            <td><strong>Lightweight 4over6 (lw4o6)</strong></td>
                            <td><strong>464XLAT</strong></td>
                            <td><strong>MAP-T</strong></td>
                            <td><strong>MAP-E</strong></td>
                        </tr>
                        <tr>
                            <td><strong>CGA4233</strong></td>
                            <td>DOCSIS 3.1 802.11n/ac Gateway</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>NO</td>
                            <td>Roadmap</td>
                            <td>Roadmap</td>
                        </tr>
                        <tr>
                            <td><strong>CGA4331</strong></td>
                            <td>DOCSIS 3.1 802.11ax Gateway</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES </td>
                            <td>YES</td>
                            <td>NO</td>
                            <td>Roadmap</td>
                            <td>Roadmap</td>
                        </tr>
                        <tr>
                            <td><strong>FGA2232</strong></td>
                            <td>2.5G GPON<br />802.11n/ac Gateway</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>NO</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td><strong>FGA5330</strong></td>
                            <td>10G GPON<br />802.11ax Gateway</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>NO</td>
                            <td>YES</td>
                            <td>YES</td>
                            <td>YES</td>
                        </tr>
                    </table>
                    <p>Technicolor es el fabricante que presenta la mayor cantidad de mecanismos de transici&oacute;n en sus modelos de CPEs.</p>

                    <h3>Detalle de informaci&oacute;n de fabricantes</h3>
                    <p>Una vez recolectada y consolidada la informaci&oacute;n brindada por los fabricantes de CPEs se pudo armar la siguiente tabla con el detalle de cada uno:</p>
                    <table className='table'>
                        <tr>
                            <td>Fabricante </td>
                            <td>Tecnolog&iacute;a </td>
                            <td>Modelo </td>
                            <td>Mecanismo de Transici&oacute;n </td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3848</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>CGA4233</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>CGA4233</td>
                            <td>lw4o6</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>CGA4331</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>CGA4331</td>
                            <td>lw4o6</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>TG1700</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA2230/2</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA2230/2</td>
                            <td>464XLAT</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA2230/2</td>
                            <td>MAP-T</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA2230/2</td>
                            <td>MAP-E</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA5330</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA5330</td>
                            <td>464XLAT</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA5330</td>
                            <td>MAP-T</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>FTTH</td>
                            <td>FGA5330</td>
                            <td>MAP-E</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>xDSL</td>
                            <td>TG582</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>xDSL</td>
                            <td>TG588</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>xDSL</td>
                            <td>TG589</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>xDSL</td>
                            <td>TG789</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>xDSL</td>
                            <td>DGA1200</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3941</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3925</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3928</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3828</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>DPC3825</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>Cable</td>
                            <td>TC8305</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>FTTH</td>
                            <td>F660v5.2</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>FTTH</td>
                            <td>F670v1</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>FTTH</td>
                            <td>F680v6</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>FTTH</td>
                            <td>F668A</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>FTTH</td>
                            <td>F260A </td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>xDSL</td>
                            <td>H108N</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>xDSL</td>
                            <td>H167A</td>
                            <td>DS-Lite</td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>Cable</td>
                            <td>Fast 3284</td>
                            <td>Sin Informaci&oacute;n</td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>Cable</td>
                            <td>Fast 3486</td>
                            <td>Sin Informaci&oacute;n</td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>Cable</td>
                            <td>Fast 3686 v1</td>
                            <td>Sin Informaci&oacute;n</td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>Cable</td>
                            <td>Fast 3686 v2</td>
                            <td>Sin Informaci&oacute;n</td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>Cable</td>
                            <td>Fast 3890</td>
                            <td>464XLAT</td>
                        </tr>
                    </table>

                    <h3>Mecanismos de Transici&oacute;n soportados</h3>
                    <p>La informaci&oacute;n anterior la podemos resumir en la siguiente tabla que nos muestra los distintos m&eacute;todos de transici&oacute;n soportados y su cantidad en el total de CPEs:</p>
                    <table className='table'>
                        <tr>
                            <td><em>Mecanismo de Transici&oacute;n</em></td>
                            <td>Cantidad </td>
                            <td>Porcentaje </td>
                        </tr>
                        <tr>
                            <td>464XLAT</td>
                            <td>3</td>
                            <td>8,11%</td>
                        </tr>
                        <tr>
                            <td>DS-Lite</td>
                            <td>24</td>
                            <td>64,86%</td>
                        </tr>
                        <tr>
                            <td>lw4o6</td>
                            <td>2</td>
                            <td>5,41%</td>
                        </tr>
                        <tr>
                            <td>MAP-E</td>
                            <td>2</td>
                            <td>5,41%</td>
                        </tr>
                        <tr>
                            <td>MAP-T</td>
                            <td>2</td>
                            <td>5,41%</td>
                        </tr>
                        <tr>
                            <td>Sin Informaci&oacute;n</td>
                            <td>4</td>
                            <td>10,81%</td>
                        </tr>
                        <tr>
                            <td><strong>Suma total</strong></td>
                            <td><strong>37</strong></td>
                            <td><strong>100,00%</strong></td>
                        </tr>
                    </table>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen14.png" alt="Ver" /></figure>
                    </div>
                    <p>De todos los mecanismos de transici&oacute;n, DS-Lite es el l&iacute;der estando presente en el 65% de todos los modelos de CPEs identificados.</p>

                    <h3>Mecanismos de Transici&oacute;n por Fabricante</h3>
                    <p>Aqu&iacute; mostraremos los Mecanismos de Transici&oacute;n soportados por cada Fabricante:</p>
                    <table className='table'>
                        <tr>
                            <td><em>Cantidad</em></td>
                            <td><em>Mecanismo de Transici&oacute;n</em></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><em>Fabricante</em></td>
                            <td>464XLAT </td>
                            <td>DS-Lite </td>
                            <td>lw4o6 </td>
                            <td>MAP-E </td>
                            <td>MAP-T </td>
                            <td>Suma total </td>
                        </tr>
                        <tr>
                            <td>Sagemcom</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Technicolor</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>ZTE</td>
                            <td>&nbsp;</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>1</td>
                        </tr>
                    </table>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen15.png" alt="Ver" /></figure>
                    </div>
                    <p>De acuerdo a esto podemos observar que de estas empresas Technicolor es el fabricante con mayor trabajo en innovaci&oacute;n sobre Mecanismos de Transici&oacute;n siendo el &uacute;nico con soporte para todos los mecanismos esperados.</p>

                    <h3>Resumen de informaci&oacute;n de Fabricantes</h3>
                    <p>Toda esta informaci&oacute;n brindada por los  fabricantes la podemos resumir en:</p>
                    <ul>
                        <li><strong>Mecanismos de Transici&oacute;n soportados</strong>: Sumando  todos los modelos de CPEs, se llega a ver soporte de todos los mecanismos de  transici&oacute;n pero muy pocos re&uacute;nen varios de estos mecanismos.</li>
                        <li><strong>Mecanismo de Transici&oacute;n L&iacute;der</strong>: DS-Lite se  presenta en 1º lugar estando presente en el 64.9% de todos los modelos de CPEs  identificados.</li>
                        <li><strong>Mecanismos de Transici&oacute;n por Fabricante</strong>: <strong>Technicolor </strong>es el fabricante que mayor  cantidad de mecanismos de transici&oacute;n soporta.</li>
                        <ul>
                            <li><strong>FTTH</strong>: En sus CPEs recomendados actualmente  soportan todos los mecanismos de transici&oacute;n requeridos excepto lw4o6.</li>
                            <li><strong>Cable</strong>: En sus CPEs recomendados, soportan  DS-Lite y lw4o6, y tienen en Roadmap el soporte para MAP-T y MAP-E.</li>
                        </ul>
                    </ul>

                    <h3>Conclusi&oacute;n de informaci&oacute;n de Fabricantes</h3>
                    <p>Como conclusi&oacute;n de la informaci&oacute;n provista por los fabricantes tenemos:</p>
                    <ul><li>Mecanismo de transici&oacute;n l&iacute;der: Observamos que DS-Lite est&aacute; presente en casi el 65% de los CPEs informados por los fabricantes permitiendo enfocar acciones de recomendaci&oacute;n y entrenamiento basados en este mecanismo a ISPs de la regi&oacute;n.</li></ul>

                    <h3>Especificaci&oacute;n de CableLabs</h3>
                    <p>CableLabs es una organizaci&oacute;n de Investigaci&oacute;n y Desarrollo sin fines de lucro cuyos miembros son compa&ntilde;&iacute;as de telecomunicaciones relacionadas a la industria del cable. Entre sus funciones est&aacute; la de definir y estandarizar las funcionalidades y protocolos que deben tener cada uno de los componentes de una red de cable.</p>
                    <p>Para el caso de los CPEs, que en una red de cable es un CableModem, si contiene un eRouter (embedded Router), es decir que tiene la capacidad o funcionalidad de funcionar como router, existe un documento denominado “IPv4 and IPv6 eRouter Specification” donde se detalla todo lo relacionado a esto.</p>
                    <p>Dentro de este documento tambi&eacute;n se especifican incluso los mecanismos de transici&oacute;n que debe soportar todo CableModem (CM) que funcione bajo las especificaciones de CableLabs.</p>

                    <p>De acuerdo a esto, CableLabs define <strong>desde el 10/12/2015</strong> bajo el documento  CM-SP-eRouter-I17-151210 que <strong>todo CM con  eRouter debe soportar MAP-T y MAP-E</strong>:<br />
                        <em>&ldquo;...</em><br />
                        <em>MAP defines two types of transport modes: MAP-E  and MAP-T. MAP-E uses [RFC2473] encapsulation as a mechanism for converting the  IPv4 packet within an IPv6 header. MAP-T uses stateless translation as defined  by [RFC6145] to translate the IPv4 header into an IPv6 header.</em><br />
                        <strong><em>The eRouter MUST  support MAP-E as defined in [RFC 7597]. The eRouter MUST support MAP-T as  defined in [RFC 7599]</em></strong><em>.</em><br />
                        <em>The eRouter MUST support configuration of MAP-E  or MAP-T functionality via DHCP options as defined in</em><br />
                        <em>[RFC 7598]. The eRouter MUST support  configuration of MAP-E or MAP-T functionality via TLV202.11 VarBinds as defined  in Annex B.4. The eRouter MUST prefer TLV 202.11 configuration over DHCP  configuration when the eRouter receives both sets of configuration data. The  eRouter is not required to support configuration of both MAP-E and MAP-T  simultaneously.</em><br />
                        <em>…&rdquo;.</em></p>
                    <p>Esta informaci&oacute;n es muy importante porque  independientemente el soporte informado actualmente por cualquier fabricante de  cablemodems, todo Operador de Cable que actualmente compre CableModems con  eRouter tiene derecho de exigir el soporte para MAP-T y MAP-E.</p>

                </>
                }
            </div>
        </div>
    )
}

export default ConsultaFabricantesCPEs
