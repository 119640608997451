import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Motivacion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4">
                {myLang === 'es' && <>
                    <h2>Motivaci&oacute;n</h2>
                    <p>Mejorar los niveles de ciberseguridad de los recursos IP bajo la administraci&oacute;n de LACNIC.</p>
                    <p>Minimizar el posible uso de t&eacute;cnicas de IP spoofing para llevar adelante ataques tanto a la infraestructura que soporta Internet como a las redes de los asociados, a trav&eacute;s de la concientizaci&oacute;n, visibilizaci&oacute;n y aporte de herramientas a la comunidad de LACNIC para abordar esta problem&aacute;tica.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Motivation</h2>
                    <p>To improve the cybersecurity levels of the IP resources under LACNIC's administration.</p>
                    <p>To minimize the potential use of IP spoofing techniques to launch attacks on both the infrastructure that underpins the Internet as well as on our members&rsquo; networks, by raising awareness and providing tools that the LACNIC community can use to address this issue.</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Motiva&ccedil;&atilde;o</h2>
                    <p>Melhorar os n&iacute;veis de seguran&ccedil;a cibern&eacute;tica dos recursos IP administrados pelo LACNIC.</p>
                    <p>Minimizar o poss&iacute;vel uso de t&eacute;cnicas de IP <em>spoofing</em> para realizar ataques tanto na infraestrutura que suporta a Internet quanto nas redes dos associados, por meio da conscientiza&ccedil;&atilde;o, visibilidade e contribui&ccedil;&atilde;o de ferramentas &agrave; comunidade do LACNIC para enfrentar esse problema.</p>
                </>}
            </div>
        </div>
    )
}

export default Motivacion
