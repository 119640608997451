import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Aproximacion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <Contenido/>}
                {myLang === 'en' && <Contenido/>}
                {myLang === 'pt' && <Contenido/>}
            </div>
        </div>
    )
}

function Contenido() {
    return (
        <>
            <h2><MatchText id="match-20">Qué aporta esta nueva aproximación </MatchText></h2>
            <p><MatchText id="match-21"> Las "Clases de Equivalencia de Prefijos en BGP" (o simplemente "clases de prefijos") son una abstracci&oacute;n te&oacute;rica que permite segmentar el conjunto de todos los updates eBGP recibidos por un AS. El resultado es un &ldquo;conjunto de conjuntos de prefijos&rdquo; (i.e. un conjunto de clases identificadas por la combinaci&oacute;n espec&iacute;fica de los Routers de Borde (ASBRs) que recibieron esos updates. Durante la construcci&oacute;n de estas clases se preserva la informaci&oacute;n necesaria para sostener la optimalidad del routing en un AS, incluso ante la p&eacute;rdida total (i.e. falla) de cualquiera de sus ASBRs.</MatchText></p>
            <p><MatchText id="match-22"> En otras palabras, dos prefijos (i.e. rango de direcciones IP) integran una misma clase cuando en estado operativo o luego de la falla de cualquier ASBR, se da que o bien ambos prefijos est&aacute;n instalados simult&aacute;neamente en la tabla de un router o ninguno de los dos lo est&aacute;. El procesamiento de las clases reproduce m&uacute;ltiples escenarios de falla simple en un overlay iBGP full-mesh, para capturar lo que es esencial preservar de entre la informaci&oacute;n de los updates, con el fin de conservar su resiliencia y optimalidad.</MatchText></p>
            <p><MatchText id="match-23"> Al momento de dise&ntilde;ar un cambio en la red es suficiente considerar un solo prefijo de cada clase, dado que el resto de los integrantes de esa clase heredar&aacute; el comportamiento de su representante.</MatchText></p>
            <p><MatchText id="match-24"> Esta abstracci&oacute;n de la informaci&oacute;n reduce notoriamente la cantidad de datos a analizar y simplifica sensiblemente la perspectiva sobre qu&eacute; es lo sustantivo para el correcto intercambio de tr&aacute;fico entre un AS y el resto del mundo.</MatchText></p>
            <figure>
                <img alt="Ver" src="/images/optimizacion-de-redes-mediante-un-mejor-ibgp/figure4.png" />
            </figure>
            <p><MatchText id="match-25"> Las pruebas realizadas en base a datos de ASes reales muestran que el n&uacute;mero de clases es &iacute;nfimo en comparaci&oacute;n al de updates, y muestran adem&aacute;s que la distribuci&oacute;n de prefijos tiende a concentrarse en una fracci&oacute;n menor entre esas clases.&nbsp;</MatchText></p>
            <p><MatchText id="match-26"> El reducir millones de updates a decenas de clases prioritarias abre innumerables opciones para interpretar la informaci&oacute;n y hacer reingenier&iacute;a de las redes.</MatchText></p>
        </>
    )
}
export default Aproximacion
