import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'


export default function Infraestructura() {
    return (
        <div id='p1'>
            <Title />
            <Table />
        </div>
    )
}



function Title() {
    const { myLang } = useContext(LangContext)
    return (
        <h2>
            {myLang === 'es' && 'Infraestructura'}
            {myLang === 'en' && 'Infrastructure'}
            {myLang === 'pt' && 'Infraestrutura'}
        </h2>
    )
}

function Table() {
    const { myLang } = useContext(LangContext)
    return (
        <table className='table'>{myLang === 'es' &&
            <tbody>
                <tr>
                    <th>Pr&aacute;cticas Recomendadas como Mejores Pr&aacute;cticas</th>
                    <th>Enlaces de Respaldo</th>
                </tr>
                <tr>
                    <td>Asegurarse que los enrutadores de las redes de interconexi&oacute;n se conecten entre s&iacute; de forma limpia y eficiente.</td>
                    <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                </tr>
                <tr>
                    <td>Mantener el acceso a una infraestructura diversa, con un mercado de proveedores de servicios de Internet y creadores de contenido, entre otros, competitivo y que cumplan con pr&aacute;cticas de operaci&oacute;n que propicien una interconexi&oacute;n abierta y gratuita.</td>
                    <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                </tr>
                <tr>
                    <td>Mantener una pol&iacute;tica de identificaci&oacute;n constante de aplicaciones basadas en la nube que cumplan con la seguridad &oacute;ptima de operaci&oacute;n y que permitan instalar infraestructura local para optimizar las conexiones mediante el IXP.</td>

                    <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noreferrer">ISOC</a></td>
                </tr>
                <tr>
                    <td>El IXP tiene que encontrar espacio en una ubicaci&oacute;n que proporcione al menos energ&iacute;a y refrigeraci&oacute;n a los dispositivos IXP. Posiblemente el principal valor del IXP es la resiliencia. Por lo general, es valioso para un IXP encontrar una colocaci&oacute;n que pueda ofrecer servicios mejorados, como fuentes de alimentaci&oacute;n redundantes y la presencia de una fuente de alimentaci&oacute;n ininterrumpida (UPS) y un generador de energ&iacute;a. Esto reduce en gran medida los riesgos de tiempo de inactividad para el IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Utilizar al menos 2 dispositivos Ethernet para proporcionar una red plana de Capa 2 para interconectar enrutadores ISP. Un solo dispositivo suele ser suficiente; sin embargo, con esta configuraci&oacute;n, una falla har&iacute;a caer todo el IXP.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Seg&uacute;n el tama&ntilde;o y la cantidad de sitios, el equipo utilizado puede ser una tecnolog&iacute;a de VLAN simple de capa 2 simple hasta una red troncal enrutada con servicios de LAN virtual en la parte superior (ejemplo VxLAN).</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Es muy recomendable que los dispositivos tengan fuentes de alimentaci&oacute;n redundantes. Si hay dos o m&aacute;s fuentes de alimentaci&oacute;n, cada fuente debe conectarse a una alimentaci&oacute;n el&eacute;ctrica separada. De esta forma, una falla en una de las alimentaciones o en una de las fuentes de alimentaci&oacute;n no afectar&iacute;a el funcionamiento del IXP. Una falla completa del hardware del dispositivo detendr&iacute;a todo el Exchange. Para evitar esto tanto como sea posible, los operadores de IXP a menudo deciden confiar en dispositivos modulares, donde es posible tener tarjetas de reenv&iacute;o y CPU redundantes.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Los IXP optan por tener dos dispositivos de peering completamente separados, con una LAN de peering separada. Cada ISP que quiera la redundancia debe tener dos enrutadores o al menos dos interfaces para conectarse a las LAN. Esta configuraci&oacute;n "redundante" es un poco como tener dos IXP en una sola ubicaci&oacute;n; si uno de ellos no est&aacute; disponible por cualquier motivo, el otro puede tomar el relevo.&nbsp;</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Cuando un IXP crece, una configuraci&oacute;n de un solo dispositivo o incluso de dos dispositivos puede volverse insuficiente; entonces puede ser &uacute;til o necesario agregar m&aacute;s dispositivos a la infraestructura. Estos dispositivos se pueden organizar en diferentes topolog&iacute;as, la m&aacute;s com&uacute;n es la topolog&iacute;a "estrella" (es decir, uno o m&aacute;s dispositivos "n&uacute;cleo" a los que se conectan varios dispositivos de "acceso" m&aacute;s peque&ntilde;os) y la topolog&iacute;a "anillo" (donde cada dispositivo est&aacute; conectado a dos dispositivos "vecinos", formando as&iacute; un anillo).</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Para mejorar la redundancia y la diversidad de acceso a su infraestructura, el IXP puede optar por instalar dispositivos de interconexi&oacute;n en m&aacute;s de una ubicaci&oacute;n f&iacute;sica. Esto debe planificarse de modo que el "desastre" en uno de los lugares no afecte las operaciones en otros lugares.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Un Centro de operaciones de red (NOC) de IXP mantiene el hardware de IXP y se ocupa de las solicitudes de los clientes. El NOC puede ser subcontratado o administrado por el propio IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Mantener un acceso restringido a las instalaciones (para minimizar los riesgos de que extra&ntilde;os manipulen el equipo de IXP), equipos de prevenci&oacute;n y extinci&oacute;n de incendios, medidas de prevenci&oacute;n de inundaciones.&nbsp;</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>La administraci&oacute;n de DMZ de la infraestructura IXP central debe realizarse a trav&eacute;s de una zona desmilitarizada separada ("DMZ" o VLAN de administraci&oacute;n), los dispositivos centrales no deben ser accesibles desde Internet para evitar problemas de seguridad. Adem&aacute;s, el monitoreo (SNMP, muestreo de flujo, etc.) se puede realizar en esta VLAN o en una VLAN separada del tr&aacute;fico de interconexi&oacute;n.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Se recomienda que la LAN de la estaci&oacute;n de trabajo del NOC y los servicios DMZ est&eacute;n separados entre s&iacute; por un enrutador, esto nuevamente se hace por razones de seguridad, ya que los hosts infectados en la LAN de las estaciones de trabajo podr&iacute;an da&ntilde;ar los dispositivos centrales en los servicios DMZ a trav&eacute;s de ataques de capa 2.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Se debe acceder a cada dispositivo IXP en una direcci&oacute;n IP, de modo que el NOC pueda usar ssh u otros medios para mantener y configurar el dispositivo. Estas direcciones IP normalmente se ubicar&iacute;an en los servicios DMZ y no son accesibles desde el mundo exterior.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Es posible enrutar el tr&aacute;fico de administraci&oacute;n en caso de que un IXP tambi&eacute;n tenga una infraestructura de enrutamiento junto a su infraestructura de conmutaci&oacute;n. Esto no es obligatorio y tambi&eacute;n se puede hacer con una VLAN de capa 2. Este es un enfoque bastante com&uacute;n y econ&oacute;mico, ya que no se requiere hardware de enrutamiento adicional ni l&iacute;neas dedicadas, y se puede usar la infraestructura IXP existente. La administraci&oacute;n generalmente tiene necesidades de ancho de banda muy bajas, por lo que esto no suele ser un problema en los puertos etiquetados interconectados entre los switches IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Adem&aacute;s de la gesti&oacute;n en banda, se recomienda utilizar tambi&eacute;n algo conocido como gesti&oacute;n fuera de banda, una forma de llegar a la gesti&oacute;n de su equipo en caso de falla de la red.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Si un IXP tiene un socio (o tambi&eacute;n mantiene una red separada) que opera una red con l&iacute;neas diferentes a la que usa el IXP, una forma de conectarse a la consola del IXP es usar administraci&oacute;n fuera de banda sobre IP. Una consola remota a la que se puede acceder a trav&eacute;s de ssh, etc. a trav&eacute;s de IP est&aacute; conectada a un puerto de consola del switch IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Tambi&eacute;n se puede acceder a los dispositivos de consola IP a trav&eacute;s de t&uacute;neles IPSec, aunque ssh puede ser suficiente seguridad para dichos dispositivos.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Las LAN de intercambio de miembros se pueden encontrar en varias variaciones. Cada IXP tiene su propia pol&iacute;tica sobre c&oacute;mo se har&aacute; esto, no existe una 'soluci&oacute;n' real para eso, es m&aacute;s bien una decisi&oacute;n que un IXP debe tomar por su cuenta. Algunas opciones son: - Tener todo el tr&aacute;fico de unidifusi&oacute;n y multicast en la misma VLAN de producci&oacute;n. - Tener todos los protocolos de unidifusi&oacute;n en una VLAN y separatoda la multicast en una VLAN separada. - Separar el tr&aacute;fico IPv4 del tr&aacute;fico IPv6 en VLAN independientes. - Tener VLAN para cada familia de direcciones, lo cual significa que IPv4 unicast, IPv4 multicast, IPv6 unicast e IPv6 multicast vivir&aacute;n en una VLAN dedicada.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/member-peering-lans/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Es una pr&aacute;ctica com&uacute;n que los IXP ofrezcan varios servicios a sus pares (posiblemente detr&aacute;s de un N&uacute;mero AS separado).</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Cualquier ISP que desee participar en un Intercambio de Internet debe tener asignado por un Registro Regional de Internet (RIR) o un Registro Local de Internet (LIR): un n&uacute;mero de Sistema Aut&oacute;nomo y un conjunto de subredes IP. Sin esos elementos, un ISP no podr&iacute;a ejecutar BGP en sus enrutadores y, por lo tanto, establecer intercambios de BGP con otros miembros del IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>La &uacute;nica funci&oacute;n que deben cumplir los enrutadores conectados al IXP (o al menos las interfaces conectadas al IXP) es establecer peerings BGP y reenviar el tr&aacute;fico IP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Los enrutadores ISP no deben ejecutarse en las interfaces conectadas al IXP con los siguientes protocolos:<br /> &nbsp;* Spanning Tree 802.1D. Una mala configuraci&oacute;n del protocolo de &aacute;rbol de expansi&oacute;n podr&iacute;a incluir el interruptor de peering en el &aacute;rbol de expansi&oacute;n y convertirlo en "parte" de la red ISP.<br /> &nbsp;* Proxy ARP RFC1027. Un enrutador configurado con Proxy ARP responde a todas las consultas ARP que pasan por la red. Esto es peligroso, ya que podr&iacute;a provocar el secuestro de paquetes destinados a otros ISP. Tenga en cuenta que en algunos enrutadores, Proxy ARP est&aacute; activado de manera predeterminada, por lo que es posible que deba apagarse de manera proactiva.<br /> &nbsp;* Protocolos de descubrimiento (por ejemplo, CDP, LLDP). Estos tambi&eacute;n se consideran protocolos de gesti&oacute;n internos y deben detenerse en los l&iacute;mites de gesti&oacute;n.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Detr&aacute;s del puerto IXP, solo debe verse una direcci&oacute;n MAC por servicio. Esto protege el intercambio contra posibles bucles que a veces pueden ocurrir si hay un proveedor de capa 2 de terceros que proporciona a m&aacute;s de un miembro conexiones remotas al IXP.</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Por lo general, los enrutadores de los miembros se ubican en el mismo sitio del dispositivo IXP. Sin embargo, varios IXP permiten conexiones "remotas". Si el IXP permite conexiones remotas, ser&aacute; menos f&aacute;cil tener un "control" sobre las conexiones de los ISP. Entonces podr&iacute;a ser necesario agregar algunos controles adicionales en la configuraci&oacute;n del dispositivo.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Independientemente de la implementaci&oacute;n, la topolog&iacute;a de un IXP o el n&uacute;mero de pops, la LAN interconectada siempre debe actuar como una red Ethernet de capa 2 simple.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Por lo general, cada dispositivo de intercambio de tr&aacute;fico alberga una sola LAN de intercambio de tr&aacute;fico donde todos los miembros est&aacute;n conectados. Los puertos pueden estar etiquetados o no etiquetados; no hay diferencia si el dispositivo solo aloja una VLAN de peering p&uacute;blica. Sin embargo, algunos IXP pueden querer ofrecer la configuraci&oacute;n de "VLAN privadas" entre miembros como un servicio; los miembros relevantes deben tener un puerto etiquetado (o un puerto separado) en el dispositivo.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>La configuraci&oacute;n del puerto para aspectos como la velocidad del puerto y el d&uacute;plex completo/semid&uacute;plex debe acordarse con el miembro, teniendo en cuenta los dispositivos que se deben conectar. Algunos dispositivos pueden tener problemas con la negociaci&oacute;n autom&aacute;tica o, por el contrario, con forzar una configuraci&oacute;n particular.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Bloquear &aacute;rbol de expansi&oacute;n en todos los puertos de peering.&nbsp;</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Solo deje pasar los ethertypes permitidos (t&iacute;picamente IPv4, IPv6, ARP).&nbsp;</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Bloqueo de MAC: Esta es una funci&oacute;n disponible en los modelos m&aacute;s recientes, mediante la cual es posible permitir solo una cierta cantidad de direcciones MAC a trav&eacute;s de un puerto. La funci&oacute;n generalmente se puede configurar como "primera llegada" (se permite el MAC de origen de los primeros "n" paquetes, se niegan todos los dem&aacute;s) o "codificado" (los MAC permitidos se configuran expl&iacute;citamente en el dispositivo).&nbsp;</td>

                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Control de tormentas (broadcast/multicast). Una tormenta de broadcast/multicast (como la provocada por un bucle de capa 2) podr&iacute;a inundar la LAN interconectada, por lo que debe solucionarse. Los dispositivos pueden tener diferentes mecanismos de control de tormentas de broadcast/multicast: podr&iacute;a ser posible configurar un "l&iacute;mite de r&aacute;faga" para paquetes de broadcast/multicast (por ejemplo, el puerto se cierra si "n" paquetes de broadcast/multicast llegan a trav&eacute;s del puerto en un determinado cantidad de tiempo), o simplemente para limitar el n&uacute;mero de paquetes de difusi&oacute;n/multicast por segundo permitidos a trav&eacute;s del puerto (los paquetes en exceso se descartan).&nbsp;</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Varios IXP utilizan la llamada "VLAN de cuarentena". Cuando un nuevo miembro se une a Exchange, su puerto se asigna primero a la "VLAN de cuarentena", donde se configura un peering "ficticio". A continuaci&oacute;n, la VLAN se rastrea en busca de tr&aacute;fico "malo" (p. ej., protocolos IGP como OSPF e IS-IS, CDP, etc). El ISP se mueve a la VLAN de peering principal solo despu&eacute;s de que se hayan solucionado todos los problemas detectados en la VLAN de cuarentena. Tenga en cuenta que una VLAN en cuarentena no debe verse como un reemplazo para monitorear la propia LAN interconectada, ya que un ISP puede introducir tr&aacute;fico problem&aacute;tico en una fecha posterior, por ejemplo, despu&eacute;s de las actualizaciones del enrutador.&nbsp;</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Los miembros solicitar&aacute;n una sola direcci&oacute;n IP en un punto de intercambio, pero es posible que tengan la necesidad de transportar m&aacute;s capacidad que la que ofrecen actualmente los proveedores de hardware. El resultado es que agregan m&uacute;ltiples puertos a un puerto l&oacute;gico. Esto se denomina enlace troncal, vinculaci&oacute;n o formaci&oacute;n de un LAG. Cuando se hace din&aacute;micamente, esto se implementa mediante LACP (Protocolo de control de agregaci&oacute;n de enlaces). LACP permite que un dispositivo de red negocie una agrupaci&oacute;n autom&aacute;tica de enlaces mediante el env&iacute;o de paquetes LACP al dispositivo opuesto.</td>
                    <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                </tr>
                <tr>
                    <td>Gestionar el apoyo por parte del gobierno para mejoras de infraestructura cr&iacute;tica, incluida la adopci&oacute;n de IPv6, el uso de DNSSEC y otras tecnolog&iacute;as para mejorar el uso y seguridad de Internet.</td>
                    <td><a href="https://www.internetsociety.org/collaborativesecurity/">ISOC</a></td>
                </tr>
                <tr>
                    <td>Asegurarse de no depender de un Proveedor de Servicios de Internet, ISP troncal para transportar el tr&aacute;fico local para evitar afectar el rendimiento de la conexi&oacute;n entre ambos puntos.</td>

                    <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                </tr>
                <tr>
                    <td>Asegurarse de contar con al menos un CDN con presencia de IXP, para optimizar la ruta, permitiendo la fluidez de los datos dentro de la conexi&oacute;n de la red, evitando ineficiencias.</td>

                    <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                </tr>
                <tr>
                    <td>Promover que todos los peerings en los IXP usen BGP.</td>

                    <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                </tr>
                <tr>
                    <td>Los IXP juegan un rol clave en la protecci&oacute;n del Internet. Una forma de contribuir es creando vecindarios seguros mediante la aplicaci&oacute;n de mejores pr&aacute;cticas para la seguridad del enrutamiento, demostrando su compromiso a la seguridad y sustentanbilidad del ecosistema de Internet. Se puede lograrar mediante el registro en el programa MANRS para IXP</td>

                    <td><a href="https://www.manrs.org/ixps/actions/" target="_blank" rel="noreferrer">ISOC</a></td>
                </tr>
            </tbody>
        }
            {myLang === 'en' &&
                <tbody>
                    <tr>
                        <th>Recommended Best Practices</th>

                        <th>Supporting links</th>
                    </tr>
                    <tr>
                        <td>Make sure that many networks&rsquo; routers are connected together cleanly and efficiently.</td>

                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Maintain access to a diverse infrastructure, with a competitive market of Internet service providers and content creators, among others, who adhere to operational practices that promote open and free interconnection.</td>

                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Maintain a policy of constant identification of cloud-based applications that comply with optimal operational security and allow the installation of local infrastructure to optimize connections through the IXP.</td>

                        <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noreferrer">ISOC</a></td>
                    </tr>
                    <tr>
                        <td>The IXP has to find space in a colocation providing at least power and cooling to the IXP devices. Possibly the main value of the IXP is resilience. It is usually valuable for an IXP to find a colocation that can offer improved services, such as redundant power feeds and the presence of an Uninterruptible Power Supply (UPS) and a power generator. This greatly reduces risks of downtime for the IXP.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Use at least two Ethernet devices to provide a flat Layer 2 network to interconnect ISP routers. A single device is usually sufficient; however, with this configuration, one failure would bring the entire IXP down.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Depending on the size and number of sites, the equipment used can be a plain Layer 2 single VLAN technology up to a routed backbone with virtual LAN services on top (e.g., VxLAN).</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>It is highly recommended for the devices to have redundant power supplies. If there are two or more power feeds, each supply should be connected to a separate feed. In this way, a failure to one of the power feeds or one of the power supplies would not affect the operation of the IXP A complete hardware failure to the device would still bring the entire Exchange to a halt. To avoid this as much as possible, IXP operators often decide to rely on modular devices, where it is possible to have redundant CPU and forwarding cards.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>In some cases, IXPs opt to have two completely separate peering devices, with a separate peering LAN. Every ISP who wants the redundancy should then have two routers or at least two interfaces to be connected to the LANs. This "redundant" setup is a bit like having two IXPs in a single location; if one of them becomes unavailable for whatever reason, the other one can take over.&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>When an IXP grows, a single-device or even double-device configuration may become insufficient; it may then become useful or necessary to add more devices to the infrastructure. These devices can be arranged in different topologies, the most common being the "star" topology (i.e. one or more "core" devices to whom several smaller "access" devices are connected) and the "ring" topology (where every device is connected to two "neighbor" devices, thereby forming a ring).</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>To improve redundancy and diversity of access to its infrastructure, the IXP may choose to install peering devices in more than one physical location. This should be planned so that "disaster" in one of the locations will not affect the operations in other locations.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>An IXP Network Operations Center (NOC) maintains the IXP hardware and deals with customer requests. The NOC can either be outsourced or run by the IXP itself.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Maintain restricted access to the premises (to minimize risks of strangers tampering with the IXP equipment), fire prevention and extinguishing equipment, and flood prevention measures.&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>DMZ management of the core IXP infrastructure should be done via a separate demilitarized zone ("DMZ" or management VLAN), the core devices must not be reachable from the Internet to prevent security issues. Furthermore, the monitoring (SNMP, flow sampling, etc.) can be done in this VLAN or a separate VLAN from the peering traffic.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>It is recommended that the workstation's LAN of the NOC and the services DMZ are separated from each other by a router. This is again for security reasons, as infected hosts in the workstations&rsquo; LAN could harm the core devices in the services DMZ via Layer&nbsp;2 attacks.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Every IXP device is usually reachable on an IP address, so that the NOC can use ssh or other means to maintain and configure the device. These IP addresses would typically be placed into the services DMZ and are not reachable from the outside world.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>It is possible to route management traffic in case an IXP also has routing infrastructure next to its switching-infrastructure. This is not mandatory and can also be done with a Layer 2 VLAN. This is a rather common and cheap approach, as no additional routing hardware or dedicated lines are required, and the existing IXP Infrastructure can be used. Management usually has very low bandwidth needs, so this is usually no issue on interconnected tagged ports between IXP switches.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>In addition to the in-band management, it is recommended to also use something known as out-of-band management, a way to reach the management of your equipment in case of network failure.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>If an IXP has a partner (or also maintains a separate network) that operates a network with different lines from the one used by the IXP, one way to connect to the console of the IXP is to use out-of-band management over IP. A remote console that is accessible via ssh, etc. over IP is connected to a console port of the IXP switch.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>IP console devices may also be reached via IPSec tunnels, although ssh may be enough security for such devices.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>There are different variations of member peering LANs. Every IXP has its own policy on how this will be done; there is no real 'solution' to that, instead, it's a decision that an IXP has to make on its own. Options include: - To have all unicast and multicast traffic on the same production VLAN. - To have all unicast protocols in one VLAN and separate all multicast into a separate VLAN. - To separate IPv4 traffic from IPv6 traffic on separate VLANs - To have individual LANs for each address family, which means that IPv4 unicast, IPv4 multicast, IPv6 unicast and IPv6 multicast will all be living in a dedicated VLAN.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/member-peering-lans/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>It is common practice that IXPs offer various services to their peers (possibly behind a separate AS Number).</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Any ISP wishing to participate to an Internet Exchange must have been assigned by a Regional Internet Registry (RIR) or a Local Internet Registry (LIR): an Autonomous System number, and a set of IP subnets. Without those elements, an ISP would not be able to run BGP on its routers, and therefore establish BGP peerings with other members of the IXP.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>The only function that routers connected to the IXP (or at least the interfaces connected to the IXP) should accomplish is to establish BGP peerings and to forward IP traffic.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>The ISP routers should not run on the interfaces connected to the IXP with the following protocols:<br /> &nbsp;* Spanning Tree 802.1D. A misconfiguration of the spanning tree protocol might include the peering switch in the spanning tree, and make it "part" of the ISP network.<br /> &nbsp;* Proxy ARP RFC1027. A router configured with Proxy ARP answers to all ARP queries passing through the network. This is dangerous, as it could lead to hijacking packets destined to other ISPs. Please note that on some routers Proxy ARP is on by default, so it may have to be proactively shut down.<br /> &nbsp;Discovery protocols (e.g. CDP, LLDP). These are also considered internal management protocols and should stop at management boundaries.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Behind the IXP port, only one MAC address per service should be visible. This protects the exchange against potential loops which can sometimes happen if there is a third-party layer-2 provider who supplies more than one member with remote connections to the IXP.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Typically, the members' routers are colocated at the same site of the IXP device. However, several IXPs allow "remote" connections. If the IXP allows remote connections, it will be less easy to have a "control" over the ISPs' connections. It might then be necessary to add a few supplementary controls in the configuration of the device.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Independent of the implementation, the topology of an IXP or the number of pops, the peering LAN should always act like a plain Layer&nbsp;2 ethernet network.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Typically, each peering device hosts a single peering LAN where all members are connected. Ports might be tagged or untagged; there is no difference if the device only hosts a public peering VLAN. However, some IXPs may want to offer configuration of "private VLANs" between members as a service; the relevant members should then have a tagged port (or a separate port) on the device.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Port configuration for things such as port speed and full duplex/half duplex should be agreed with the member, taking into account the devices that must be connected. Some devices may have trouble with auto-negotiation or, on the contrary, with forcing a particular configuration.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Block Spanning Tree on all peering ports.&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Only let the allowed ethertypes pass (typically IPv4, IPv6, ARP).&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>MAC locking: This is a feature available in most recent models, whereby it is possible to allow only a certain number of MAC addresses through a port. The feature is usually configurable as "first arrival" (the source MAC of the first "n" packets are allowed, all others are denied) or "hardcoded" (the allowed MACs are explicitly configured in the device).&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Storm control (broad/multicast): A broad/multicast storm (such as one caused by a Layer 2 loop) might flood the peering LAN, so it should be taken care of. Devices may have different mechanisms of broad/multicast storm control: it might be possible to configure a "burst limit" for broad/multicast packets (e.g. the port is shut down if "n" broad/multicast packets come through the port in a certain amount of time), or to simply limit the number of broad/multicast packets per second allowed through the port (the exceeding packets are discarded).&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Several IXPs make use of a so-called "Quarantine VLAN". When a new member joins the Exchange, their port is first assigned to the "Quarantine VLAN" where a "dummy" peering is set up. The VLAN is then sniffed for "bad" traffic (e.g. IGP protocols like OSPF and IS-IS, CDP, etc.). The ISP is moved to the main peering VLAN only after all the problems detected on the Quarantine VLAN have been fixed. Please note that a Quarantine VLAN should not be seen as a replacement for monitoring the peering LAN itself, as an ISP may introduce problematic traffic at a later date, e.g. after router upgrades.&nbsp;</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Members will ask for a single IP address at an exchange point, but they might have the need to transport more capacity than currently offered by hardware vendors. The result is that they aggregate multiple ports to a logical port. This is called trunking, bonding, or forming a LAG. When done dynamically, this is implemented by LACP (Link Aggregation Control Protocol). LACP allows a network device to negotiate an automatic bundling of links by sending LACP packets to the opposite device.</td>

                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Manage government support for enhancements to critical infrastructure, including the adoption of IPv6, the use of DNSSEC, and other technologies to improve Internet usability and security.</td>

                        <td><a href="https://www.internetsociety.org/collaborativesecurity/" target="_blank" rel="noreferrer">ISOC</a></td>
                    </tr>
                    <tr>
                        <td>Ensure you do not rely on a backbone Internet Service Provider (ISP) to carry local traffic to avoid affecting the performance of the connection between both points.</td>

                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Ensure you have at least one CDN with IXP presence to optimize the route, allowing data to flow within the network connection and avoiding inefficiencies.</td>

                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Encourage that all peering at IXPs uses BGP.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noreferrer">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>IXPs play a key role in protecting the Internet. One way for IXPs to contribute is to build safe neighborhoods by applying best practices for routing security, demonstrating their commitment to security and sustainability of the Internet ecosystem. This can be achieved by registering in the MANRS IXP Program.</td>

                        <td><a href="https://www.manrs.org/ixps/actions/" target="_blank" rel="noreferrer">ISOC</a></td>
                    </tr>
                </tbody>
            }
            {myLang === 'pt' && <>
                <tbody>
                    <tr>
                        <th>Pr&aacute;ticas recomendadas como melhores pr&aacute;ticas</th>
                        <th>Links de backup</th>
                    </tr>
                    <tr>
                        <td>Garantir que os roteadores das redes de interconex&atilde;o se conectem entre si de forma limpa e eficiente.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Manter o acesso a uma infraestrutura diversa, com um mercado de provedores de servi&ccedil;os da Internet e criadores de conte&uacute;do, entre outros, competitivo e que atenda as pr&aacute;ticas operacionais que promovem uma interconex&atilde;o aberta e gratuita.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Manter uma pol&iacute;tica de identifica&ccedil;&atilde;o constante de aplicativos baseados na nuvem que cumpram uma seguran&ccedil;a operacional ideal e que permitam a instala&ccedil;&atilde;o de infraestrutura local para otimizar as conex&otilde;es mediante o IXP.</td>
                        <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/">ISOC</a></td>
                    </tr>
                    <tr>
                        <td>O IXP precisa encontrar espa&ccedil;o em uma localiza&ccedil;&atilde;o que forne&ccedil;a pelo menos energia e refrigera&ccedil;&atilde;o aos dispositivos IXP. Possivelmente o principal valor do IXP seja a resili&ecirc;ncia. Em geral, para um IXP &eacute; valioso encontrar um local que possa oferecer servi&ccedil;os aprimorados, como fontes de alimenta&ccedil;&atilde;o redundantes e a presen&ccedil;a de uma fonte de alimenta&ccedil;&atilde;o ininterrupta (UPS) e de um gerador de energia. Isto reduz bastante os riscos de tempo de inatividade para o IXP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Usar pelo menos 2 dispositivos Ethernet para fornecer uma rede plana de Camada 2 para interconectar roteadores ISP. Geralmente, um &uacute;nico dispositivo &eacute; suficiente; por&eacute;m, com esta configura&ccedil;&atilde;o, uma falha derrubaria todo o IXP.&nbsp;</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Dependendo do tamanho e do n&uacute;mero de sites, o equipamento usado pode ser uma tecnologia de VLAN simples de camada 2 simples at&eacute; um backbone roteado com servi&ccedil;os de LAN virtual no topo (exemplo VxLAN).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>&Eacute; altamente recomend&aacute;vel que os dispositivos tenham fontes de alimenta&ccedil;&atilde;o redundantes. Se houver duas ou mais fontes de alimenta&ccedil;&atilde;o, cada fonte dever&aacute; ser conectada a uma alimenta&ccedil;&atilde;o el&eacute;trica separada. Desta forma, uma falha em uma das alimenta&ccedil;&otilde;es ou em uma das fontes de alimenta&ccedil;&atilde;o n&atilde;o afetaria o funcionamento do IXP. Uma falha completa do hardware do dispositivo interromperia todo o Exchange. Para evitar isso tanto quanto poss&iacute;vel, as operadoras de IXP muitas vezes decidem confiar em dispositivos modulares, onde &eacute; poss&iacute;vel ter placas de encaminhamento e CPU redundantes.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Os IXP optam por ter dois dispositivos de peering completamente separados, com uma LAN de peering separada. Todo ISP que queira redund&acirc;ncia deve ter dois roteadores ou pelo menos duas interfaces para se conectar &agrave;s LAN. Essa configura&ccedil;&atilde;o &ldquo;redundante&rdquo; &eacute; como ter dois IXP em um &uacute;nico local; se um deles n&atilde;o estiver dispon&iacute;vel por algum motivo, o outro poder&aacute; assumir.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Quando um IXP cresce, a configura&ccedil;&atilde;o de um &uacute;nico dispositivo ou mesmo de dois dispositivos pode se tornar insuficiente; ent&atilde;o pode ser &uacute;til ou necess&aacute;rio adicionar mais dispositivos &agrave; infraestrutura. Esses dispositivos podem ser organizados em diferentes topologias, sendo a mais comum a topologia "estrela" (ou seja, um ou mais dispositivos "n&uacute;cleo" aos quais est&atilde;o conectados v&aacute;rios dispositivos de "acesso&rdquo; menores) e a topologia "anel" (em que cada dispositivo est&aacute; conectado a dois dispositivos "vizinhos", formando assim um anel).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Para melhorar a redund&acirc;ncia e a diversidade de acesso &agrave; sua infraestrutura, o IXP poder&aacute; optar por instalar dispositivos de interconex&atilde;o em mais de um local f&iacute;sico. Isto deve ser planejado para que o &ldquo;desastre&rdquo; em um dos locais n&atilde;o afete as opera&ccedil;&otilde;es em outros locais.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Um Centro de Opera&ccedil;&otilde;es de Rede (NOC) de IXP mant&eacute;m o hardware de IXP e lida com os pedidos dos clientes. O NOC pode ser terceirizado ou gerenciado pelo pr&oacute;prio IXP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Manter um acesso restrito &agrave;s instala&ccedil;&otilde;es (para minimizar os riscos de terceiros interferirem nos equipamentos de IXP), equipamentos de preven&ccedil;&atilde;o e extin&ccedil;&atilde;o de inc&ecirc;ndio, medidas de preven&ccedil;&atilde;o de inunda&ccedil;&otilde;es.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/physical-requirements/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>O gerenciamento de DMZ da infraestrutura IXP central deve ser feito atrav&eacute;s de uma zona desmilitarizada separada ("DMZ" ou VLAN de gerenciamento); os dispositivos centrais n&atilde;o devem ser acess&iacute;veis desde a Internet para evitar problemas de seguran&ccedil;a. Al&eacute;m disso, o monitoramento (SNMP, amostragem de fluxo, etc.) pode ser realizado nesta VLAN ou em uma VLAN separada do tr&aacute;fego de interconex&atilde;o.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Recomenda-se que a LAN da esta&ccedil;&atilde;o de trabalho do NOC e os servi&ccedil;os DMZ estejam separados um do outro por um roteador, isso novamente &eacute; feito por motivos de seguran&ccedil;a, uma vez que os hosts infectados na LAN das esta&ccedil;&otilde;es de trabalho poderiam danificar os dispositivos centrais nos servi&ccedil;os DMZ por meio de ataques de camada 2.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/network-perimeter/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Cada dispositivo IXP deve ser acessado em um endere&ccedil;o IP, para que o NOC possa usar ssh ou outros meios para manter e configurar o dispositivo. Esses endere&ccedil;os IP normalmente estariam localizados nos servi&ccedil;os DMZ e n&atilde;o s&atilde;o acess&iacute;veis desde o mundo exterior.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>&Eacute; poss&iacute;vel rotear o tr&aacute;fego de gerenciamento se um IXP tamb&eacute;m tiver uma infraestrutura de roteamento juntamente com sua infraestrutura de comuta&ccedil;&atilde;o. Isto n&atilde;o &eacute; obrigat&oacute;rio e tamb&eacute;m pode ser feito com uma VLAN de camada 2. Esta &eacute; uma abordagem bastante comum e econ&ocirc;mica, uma vez que n&atilde;o precisa de hardware de roteamento adicional ou linhas dedicadas, e a infraestrutura IXP existente pode ser usada. O gerenciamento em geral, tem necessidades de largura de banda muito baixas, portanto isso geralmente n&atilde;o &eacute; um problema em portas etiquetadas interconectadas entre os switches IXP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/-band-management-network/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Al&eacute;m do gerenciamento em banda, &eacute; recomend&aacute;vel usar tamb&eacute;m algo conhecido como gerenciamento fora de banda, uma forma de gerenciar seu equipamento em caso de falha na rede.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Se um IXP tiver um s&oacute;cio (ou tamb&eacute;m mantiver uma rede separada) que opera uma rede com linhas diferentes daquela usada pelo IXP, uma forma de se conectar ao console do IXP &eacute; usar o gerenciamento fora de banda sobre IP. Um console remoto que pode ser acessado via ssh, etc., via IP est&aacute; conectado a uma porta do console do switch IXP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Os dispositivos de console IP tamb&eacute;m podem ser acessados ​​atrav&eacute;s de t&uacute;neis IPSec, embora o ssh possa ser seguran&ccedil;a suficiente para tais dispositivos.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/out-band-management-network/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>As LAN de troca de membros podem ser encontradas em diversas varia&ccedil;&otilde;es. Cada IXP tem a sua pr&oacute;pria pol&iacute;tica sobre como isso ser&aacute; feito, n&atilde;o existe uma 'solu&ccedil;&atilde;o' real para isso, &eacute; mais uma decis&atilde;o que um IXP deve tomar por conta pr&oacute;pria. Algumas das op&ccedil;&otilde;es s&atilde;o: - Ter todo o tr&aacute;fego unicast e multicast na mesma VLAN de produ&ccedil;&atilde;o. - Ter todos os protocolos unicast em uma VLAN e separar todo o multicast em uma VLAN separada. - Separar o tr&aacute;fego IPv4 do tr&aacute;fego IPv6 em VLAN independentes. - Ter VLAN para cada fam&iacute;lia de endere&ccedil;os, o que significa que IPv4 unicast, IPv4 multicast, IPv6 unicast e IPv6 multicast residir&atilde;o em uma VLAN dedicada.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/member-peering-lans/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>&Eacute; pr&aacute;tica comum que os IXP ofere&ccedil;am v&aacute;rios servi&ccedil;os aos seus pares (possivelmente por tr&aacute;s de um N&uacute;mero AS separado).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Qualquer ISP que queira participar de uma troca da Internet deve ser designado por um Registro Regional da Internet (RIR) ou um Registro Local da Internet (LIR): um n&uacute;mero de Sistema Aut&ocirc;nomo e um conjunto de sub-redes IP. Sem esses elementos, um ISP n&atilde;o poderia executar o BGP em seus roteadores e, portanto, estabelecer trocas de BGP com outros membros do IXP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>A &uacute;nica fun&ccedil;&atilde;o que devem cumprir os roteadores conectados ao IXP (ou pelo menos as interfaces conectadas ao IXP) &eacute; estabelecer peerings BGP e reenviar o tr&aacute;fego IP.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Os roteadores ISP n&atilde;o devem ser executados nas interfaces conectadas ao IXP com os seguintes protocolos:<br /> * Spanning Tree 802.1D. Uma configura&ccedil;&atilde;o incorreta do protocolo spanning tree poderia incluir o switch de peering no spanning tree e torn&aacute;-lo "parte" da rede ISP.<br /> * Proxy ARP RFC1027. Um roteador configurado com Proxy ARP responde a todas as consultas ARP que passam pela rede. Isto &eacute; perigoso porque pode levar ao sequestro de pacotes destinados a outros ISP. Observe que em alguns roteadores, o Proxy ARP est&aacute; habilitado por padr&atilde;o, portanto, pode ser necess&aacute;rio deslig&aacute;-lo de maneira proativa. * Protocolos de descobrimento (por exemplo: CDP, LLDP). Estes tamb&eacute;m s&atilde;o considerados protocolos de gerenciamento internos e devem parar nos limites de gest&atilde;o.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Atr&aacute;s da porta IXP, s&oacute; deve estar vis&iacute;vel um endere&ccedil;o MAC por servi&ccedil;o. Isso protege a troca contra poss&iacute;veis loops que &agrave;s vezes podem ocorrer se houver um provedor de camada 2 de terceiros que fornece conex&otilde;es remotas ao IXP para mais de um membro.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Em geral, os roteadores dos membros est&atilde;o localizados no mesmo lugar do dispositivo IXP. No entanto, v&aacute;rios IXP permitem conex&otilde;es &ldquo;remotas&rdquo;. Se o IXP permitir conex&otilde;es remotas, ser&aacute; menos f&aacute;cil ter &ldquo;controle&rdquo; sobre as conex&otilde;es dos ISP. Ent&atilde;o poderia ser necess&aacute;rio adicionar alguns controles adicionais na configura&ccedil;&atilde;o do dispositivo.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Independentemente da implementa&ccedil;&atilde;o, da topologia de um IXP ou do n&uacute;mero de pops, a LAN interconectada deve sempre atuar como uma rede Ethernet de camada 2 simples.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Em geral, cada dispositivo de troca de tr&aacute;fego hospeda uma &uacute;nica LAN de troca onde todos os membros est&atilde;o conectados. As portas podem ser rotuladas ou n&atilde;o; n&atilde;o faz diferen&ccedil;a se o dispositivo hospeda apenas uma VLAN de peering p&uacute;blica. Por&eacute;m, alguns IXP podem querer oferecer a configura&ccedil;&atilde;o de &ldquo;VLAN privadas&rdquo; entre membros como um servi&ccedil;o; os membros relevantes devem ter uma porta rotulada (ou uma porta separada) no dispositivo.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>A configura&ccedil;&atilde;o da porta para itens como a velocidade da porta e o duplex completo/semiduplex devem ser acordadas com o membro, levando em considera&ccedil;&atilde;o os dispositivos que precisam ser conectados. Alguns dispositivos podem ter problemas com a negocia&ccedil;&atilde;o autom&aacute;tica ou, pelo contr&aacute;rio, com for&ccedil;ar uma configura&ccedil;&atilde;o espec&iacute;fica.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Bloquear a &aacute;rvore de expans&atilde;o em todas as portas de peering.&nbsp;</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>S&oacute; deixe passar os ethertypes permitidos (tipicamente IPv4, IPv6, ARP).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Bloqueio de MAC: esta &eacute; uma fun&ccedil;&atilde;o dispon&iacute;vel nos modelos mais recentes, mediante a qual &eacute; poss&iacute;vel permitir apenas um determinado n&uacute;mero de endere&ccedil;os MAC atrav&eacute;s de uma porta. A fun&ccedil;&atilde;o geralmente pode ser configurada como "primeira chegada" (o MAC de origem dos primeiros "n" pacotes &eacute; permitido, todos os outros s&atilde;o negados) ou "criptografado" (os MAC permitidos s&atilde;o explicitamente configurados no dispositivo).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Controle de tempestade (broadcast/multicast). Uma tempestade de broadcast/multicast (como a causada por um loop de camada 2) poderia inundar a LAN interconectada, pelo que precisa ser resolvido. Os dispositivos podem ter diferentes mecanismos de controle de tempestade de broadcast/multicast: poderia ser poss&iacute;vel configurar um "limite de rajada" para pacotes de broadcast/multicast (por exemplo, a porta &eacute; fechada se "n" pacotes de broadcast/multicast chegam atrav&eacute;s da porta em um determinado per&iacute;odo de tempo), ou simplesmente para limitar o n&uacute;mero de pacotes de difus&atilde;o/multicast por segundo permitidos atrav&eacute;s da porta (pacotes em excesso s&atilde;o descartados).</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>V&aacute;rios IXP usam a chamada &ldquo;VLAN de quarentena&rdquo;. Quando um novo membro se junta ao Exchange, sua porta &eacute; designada primeiro &agrave; "VLAN de quarentena", onde um peering "fict&iacute;cio" &eacute; configurado. A VLAN &eacute; ent&atilde;o verificada em busca de tr&aacute;fego "ruim" (por exemplo, protocolos IGP como OSPF e IS-IS, CDP, etc.). O ISP passa para a VLAN de peering principal somente ap&oacute;s todos os problemas detectados na VLAN de quarentena terem sido resolvidos. Observe que uma VLAN em quarentena n&atilde;o deve ser vista como um substituto para o monitoramento da pr&oacute;pria LAN interconectada, j&aacute; que um ISP pode introduzir tr&aacute;fego problem&aacute;tico em uma data posterior, por exemplo, ap&oacute;s as atualiza&ccedil;&otilde;es do roteador.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Os membros solicitar&atilde;o um &uacute;nico endere&ccedil;o IP em um ponto de troca, mas possivelmente tenham a necessidade de transportar mais capacidade do que os fornecedores de hardware oferecem atualmente. O resultado &eacute; que adicionam m&uacute;ltiplas portas a uma porta l&oacute;gica. Isso &eacute; chamado de entroncamento, liga&ccedil;&atilde;o ou forma&ccedil;&atilde;o de um LAG. Quando se faz de forma din&acirc;mica, isso &eacute; implementado por meio do LACP (Link Aggregation Control Protocol). O LACP permite que um dispositivo de rede negocie o pooling autom&aacute;tico de links enviando pacotes LACP ao dispositivo oposto.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/device-configuration-requirements-ix/">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Gerenciar o apoio governamental para melhorias de infraestrutura cr&iacute;tica, incluindo a adop&ccedil;&atilde;o do IPv6, o uso de DNSSEC e outras tecnologias para melhorar o uso e a seguran&ccedil;a da Internet.</td>
                        <td><a href="https://www.internetsociety.org/collaborativesecurity/">ISOC</a></td>
                    </tr>
                    <tr>
                        <td>Certificar-se de n&atilde;o depender de um provedor de servi&ccedil;os da Internet, ISP troncal para transportar o tr&aacute;fego local para evitar afetar o desempenho da conex&atilde;o entre os dois pontos.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Certificar-se de ter pelo menos um CDN com presen&ccedil;a de IXP, para otimizar a rota, permitindo a fluidez dos dados dentro da conex&atilde;o da rede, evitando inefici&ecirc;ncias.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Promover que todos os peerings nos IXP usem BGP.</td>
                        <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/">CLOUDFARE</a></td>
                    </tr>
                    <tr>
                        <td>Os IXP desempenham um papel fundamental na prote&ccedil;&atilde;o da Internet. Uma forma de contribuir &eacute; criando vizinhan&ccedil;as seguras mediante a aplica&ccedil;&atilde;o de melhores pr&aacute;ticas para a seguran&ccedil;a do roteamento, demonstrando seu compromisso com a seguran&ccedil;a e a sustentabilidade do ecossistema da Internet. Isso pode ser conseguido registrando-se no programa MANRS para IXP.</td>
                        <td><a href="https://www.manrs.org/ixps/actions/">ISOC</a></td>
                    </tr>
                </tbody>
            </>}
        </table>
    )
}