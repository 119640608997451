
import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Metodologia() {
    const { myLang } = useContext(LangContext)

    return (
        <div>
            <div id="p4">
                {myLang === 'es' && <>
                    <h2>Metodologia del Estudio</h2>
                    <p>Este estudio pretende dar cifras concretas de las mejoras que ha significado para nuestra regi&oacute;n la instalaci&oacute;n de copias de los root servers, mediante el an&aacute;lisis hist&oacute;rico de mediciones de rendimiento.</p>
                    <p>Adem&aacute;s gracias a estas cifras es posible indicar qu&eacute; regiones a&uacute;n se mantienen con est&aacute;ndares bajos, lo que permite enfocar mejor los esfuerzos y resultados.</p>

                    <p>Para los datos se utilizar&aacute; la informaci&oacute;n que mantiene la organizaci&oacute;n europea “R&eacute;seaux IP Europ&eacute;en” (RIPE) con su proyecto “RIPE Atlas”, que dispone de sondas de medici&oacute;n instalada por voluntarios en todo el mundo, que est&aacute;n constantemente midiendo y analizando informaci&oacute;n de infraestructura de Internet hacia distintos puntos importantes de la red, tales como los root servers. Se han instalado un poco m&aacute;s de mil sondas de RIPE Atlas en los pa&iacute;ses de nuestra regi&oacute;n, una cifra que es baja considerando la cobertura en otras regiones como Europa y Norteam&eacute;rica, pero que da una visi&oacute;n general considerable.</p>
                    <p>Es importante tambi&eacute;n hacer un llamado a que ojal&aacute; pudi&eacute;ramos tener m&aacute;s voluntarios hospedando sondas RIPE Atlas en nuestros pa&iacute;ses. Las sondas son dispositivos aut&oacute;nomos que est&aacute;n fabricados para ser instalados en conexiones caseras por usuarios normales, que funcionan aut&oacute;nomamente sin supervisi&oacute;n por parte del usuario. El tener m&aacute;s sondas en nuestra regi&oacute;n ayuda a que este y otros estudios que nos benefician se puedan llevar a cabo. Puede tener m&aacute;s informaci&oacute;n en <a href="https://atlas.ripe.net" target="blank">https://atlas.ripe.net</a>.</p>
                    <p>En un detalle m&aacute;s t&eacute;cnico, la medici&oacute;n espec&iacute;fica que se analiz&oacute; en este estudio son consultas normales DNS de tipo UDP por el protocolo IPv4, con clase chaos, tipo TXT y QNAME version.bind; desde cada una de las sondas hacia todos los root servers  {'{a-m}'}.root-servers.net. Las mediciones son recurrentes cada 12 horas, de las cuales se tomaron muestras representativas.</p>

                </>}
                {myLang === 'en' && <>
                    <h2>Research Methodolgy</h2>
                    <p>This goal of this study is to provide specific figures on the improvements that the installation of root server copies has represented for our region, by conducting a historical analysis of performance measurements.</p>
                    <p>In addition, these figures also allow identifying which regions still maintain lower standards, which in turn will allow better focusing the various efforts and results.</p>
                    <p>The data we will use is the information maintained by the European organization Réseaux IP Européen (RIPE) as well as by its RIPE Atlas project, a global network of measurement probes installed worldwide by volunteers and which are constantly measuring and analyzing information they gather from important elements of Internet infrastructure such as root servers. A little over a thousand RIPE Atlas probes have been installed in the countries of our region, a figure that is still low compared to the coverage achieved in other regions such as Europe and North America, but which still provides a relevant overview.</p>
                    <p>It is also important to call on additional volunteers to host RIPE Atlas probes in the countries of Latin America and the Caribbean. The probes are autonomous devices designed to be installed by regular users in residential connections and to operate autonomously without the users’ supervision. Having a larger number of probes in our region will contribute to this and other studies <a href="https://atlas.ripe.net" target="_blank" rel="noreferrer">that will benefit us all</a>.</p>
                    <p>From a more technical point of view, the specific measurements analyzed in this study are the normal DNS queries over UDP for IPv4, using class CHAOS, type TXT and QNAME version. bind from each of the probes to each of the root servers {'{a-m}'}.root-servers.net. Representative samples were taken of these measurements. Each measurement was performed every 12 hours.</p>

                </>}
            </div>

        </div>
    )
}

export default Metodologia