import React, { useContext } from 'react'
import Button from '../../../components/Button'
import { LangContext } from '../../../context/DataContext'

function Resumen() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p1">

                {myLang === 'es' &&
                    <div class="alerts alert_white">
                        <p>Autor: Augusto Mathur&iacute;n</p>
                        <p>Coordinaci&oacute;n y Revisi&oacute;n: Guillermo Cicileo</p>
                        <p>Proyecto: Fortalecimiento de Infraestructura de Internet Regional</p>
                        <p>&Aacute;rea: I+D en Infraestructura de Internet</p>
                        <p>2022</p>
                        <Button color="button_blue" to="/reportes/interconexion/interconexion-bgp-local-lac_es.pdf">Descargar (PDF)</Button>
                    </div>
                }
                {
                    myLang === 'en' &&
                    <div class="alerts alert_white">
                        <p>Author: Augusto Mathur&iacute;n</p>
                        <p>Coordination and Revision: Guillermo Cicileo</p>
                        <p>Project: Strengthening Regional Internet Infrastructure</p>
                        <p>Department: Internet Infrastructure R&D</p>
                        <p>2022</p>
                        <Button color="button_blue" to="/reportes/interconexion/interconexion-bgp-local-lac_en.pdf">DOWNLOAD (PDF)</Button>
                    </div>
                }
                {
                    myLang === 'pt' &&
                    <div class="alerts alert_white">
                        <p>Autor: Augusto Mathur&iacute;n</p>
                        <p>Coordena&ccedil;&atilde;o y revis&atilde;o: Guillermo Cicileo</p>
                        <p>Edi&ccedil;&atilde;o e desenho: Eduardo Preve e Mart&iacute;n Ma&ntilde;ana</p>
                        <p>Projeto: Fortalecimento de Infraestrutura da Internet Regional</p>
                        <p>&Aacute;rea: I+D na infraestrutura da Internet</p>
                        <p>2022</p>
                        <Button color="button_blue" to="/reportes/interconexion/interconexion-bgp-local-lac_pt.pdf">DOWNLOAD (PDF)</Button>  
                    </div>
                }

            </div>

        </div>
    )
}

export default Resumen
