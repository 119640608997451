import React, {  useContext } from 'react';
import MenuLateralReports from '../../../components/MenuLateralReports';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
/* import SearchComponent from '../../../components/SearchComponent'; */

function MenuLateralSoporteIPv6EquiposCPEV1() {
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return (  
            <>    {myLang === 'es' && 
                <nav>
                    <button>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020) <i className="fas fa-angle-down"></i></button>
                    {/* <SearchComponent /> */}
                    <ul className="active">
                        <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen</a></li>
                        <li><a href="#introduccion" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Objetivo</a></li>   
                        <li><a href="#motivo" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Motivo</a></li>
                        <li><a href="#alcance" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Alcance</a></li>
                        <li><a href="#introduccion" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Introducción</a></li>
                        <li><a href="#consideraciones-iniciales" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Consideraciones Iniciales</a></li>
                        <li><a href="#mecanismos-de-transicion" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Mecanismos de Transici&oacute;n</a></li>
                        <li><a href="#ipv4-as-a-service" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>IPv4 as a Service</a></li>
                        <li><a href="#recoleccion-de-informacion" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Recolecci&oacute;n de Informaci&oacute;n</a></li>
                        <li><a href="#encuesta-a-isps" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')}>Encuestas a ISPs</a></li>
                        <li><a href="#consultas-a-fabricantes-de-cpes" className={activeAnchor === 'p11' ? 'active' : null} onClick={() => scrollToAnchor('p11')}>Consultas a Fabricantes de CPEs</a></li>
                        <li><a href="#conclusiones" className={activeAnchor === 'p12' ? 'active' : null} onClick={() => scrollToAnchor('p12')}>Conclusiones</a></li>
                        <li><a href="#referencias" className={activeAnchor === 'p13' ? 'active' : null} onClick={() => scrollToAnchor('p13')}>Referencias</a></li>
                    </ul>
                </nav>
                } 
                {myLang ==='en' && <><MenuLateralReports category="ipv6" /></>}
                {myLang ==='pt' && <><MenuLateralReports category="ipv6" /></>}</> 
                
             
    )
}

export default MenuLateralSoporteIPv6EquiposCPEV1
