import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

/* 
<MatchText id="match-"> */
function Estudios(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-37">Estudios 2020</MatchText></h2>
                    <p>
                        <MatchText id="match-38">Para esta edici&oacute;n del estudio, se comenz&oacute; una campa&ntilde;a de mediciones desde comienzos de septiembre hasta comienzos de noviembre. En ese per&iacute;odo se realizaron 13.000 mediciones desde 26 pa&iacute;ses, con destino a 25 pa&iacute;ses. A su vez, esas 13.000 mediciones provinieron desde 332 diferentes Sistemas Aut&oacute;nomos.</MatchText></p>
                    <h3>
                        <MatchText id="match-39">Resultados</MatchText></h3>
                    <p>
                        <MatchText id="match-40">Una vez obtenidos los datos, se agruparon las mediciones en 3 perspectivas diferentes:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-41">Pa&iacute;s de origen: esta categor&iacute;a contiene todas las mediciones con sondas ubicadas en el pa&iacute;s, pero no incluye aquellas que terminan en el mismo pa&iacute;s. Es decir, las mediciones son &uacute;nicamente salientes del pa&iacute;s.</MatchText></li>
                        <li>
                            <MatchText id="match-42">Pa&iacute;s de destino: esta categor&iacute;a contiene todas las mediciones con puntos de prueba (servidores) ubicados en el pa&iacute;s, pero no incluye aquellas que son originadas en el mismo pa&iacute;s. Es decir, las mediciones son &uacute;nicamente entrantes al pa&iacute;s.</MatchText></li>
                        <li>
                            <MatchText id="match-43">Ambos pa&iacute;ses de origen y de destino: esta categor&iacute;a contiene todas las mediciones cuyo pa&iacute;s de origen y destino es el mismo. Es decir, son mediciones que son internas al pa&iacute;s. Esta categor&iacute;a contiene todas las mediciones con sondas y puntos de prueba ubicados dentro del mismo pa&iacute;s.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-44">Los resultados se pueden visualizar a trav&eacute;s de los siguientes tres gr&aacute;ficos, donde mostramos la mediana de los resultados de cada pa&iacute;s:</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r1.png" /></figure>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r2.png" /></figure>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r3.png" /></figure>

                    <p>
                        <MatchText id="match-45">A simple vista se puede ver que tanto para la latencia saliente (1) como para la entrante, los valores rondan entre 150 y 200 milisegundos. Adem&aacute;s, naturalmente, se puede ver que la latencia interna (3) es menor a la latencia saliente y la entrante.</MatchText></p>
                    <p>
                        <MatchText id="match-46">Otro aspecto que se puede ver de esta campa&ntilde;a es que la cantidad de pa&iacute;ses con sondas activas es mucho mayor que la cantidad de pa&iacute;ses con servidores de pruebas: 37 y 26 respectivamente; esta diferencia hace que el gr&aacute;fico 1 tenga m&aacute;s entradas que los gr&aacute;ficos 2 y 3. Adem&aacute;s, implica que no todas las combinaciones &lt; pa&iacute;s_origen, pa&iacute;s_destino&gt; se encuentren cubiertas por esta campa&ntilde;a de mediciones, al menos hasta el momento.</MatchText></p>
                    <p>
                        <MatchText id="match-47">Cabe hacer una menci&oacute;n especial de algunos pa&iacute;ses que presentan malas latencias en los gr&aacute;ficos anteriores, ya que se debe principalmente a un sesgo en la metodolog&iacute;a de mediciones. Como mencionamos anteriormente, el destino de las mediciones es un pool de servidores en la regi&oacute;n LAC. Debido a que existe una concentraci&oacute;n desigual de esos servidores en la regi&oacute;n (hay m&aacute;s servidores en pa&iacute;ses m&aacute;s grandes), al agendar una nueva medici&oacute;n existe una probabilidad m&aacute;s alta de que sea agendada hacia estos pa&iacute;ses m&aacute;s grandes. Y de hecho los pa&iacute;ses con peores latencias del gr&aacute;fico 1 - Cuba (CU), Islas Turcas y Caicos (TC), Guyana Francesa (GF), Suriname (SR), Guyana (GY), y Venezuela (VE)-, tienen, hasta el momento de este reporte, resultados &uacute;nicamente hacia esos pa&iacute;ses. A medida que pase el tiempo y se agenden m&aacute;s mediciones hacia m&aacute;s pa&iacute;ses, este sesgo ir&aacute; bajando y las mediciones reflejar&aacute;n de mejor forma la realidad de la conectividad regional.</MatchText></p>
                    <p>
                        <MatchText id="match-48">Los gr&aacute;ficos anteriores tambi&eacute;n se pueden visualizar en un mapa:</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r4r5r6.png" /></figure>
                    <p>
                        <MatchText id="match-49">* Latencia - Mediciones originadas en el pa&iacute;s. </MatchText><br />
                        <MatchText id="match-50">** Latencia con destino en el pa&iacute;s. </MatchText><br />
                        <MatchText id="match-51">** Latencia dentro de un mismo pa&iacute;s.</MatchText></p>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-37">Results of the 2020 Study</MatchText></h2>
                    <p>
                        <MatchText id="match-38">For this edition of the study, we began a measurement campaign that ran from early September to early November. During this period, 13,000 measurements were initiated from 26 countries and their destinations were in 25 countries. In turn, 13,000 measurements originated in 332 different autonomous systems.</MatchText></p>
                    <h3>
                        <MatchText id="match-39">Results</MatchText></h3>
                    <p>
                        <MatchText id="match-40">Once the data was obtained, measurements were grouped considering three different categories:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-41">Country of origin: This category contained all the measurements obtained with probes located in the country, excluding those pointing to the same country. In other words, these were only outgoing measurements</MatchText></li>
                        <li>
                            <MatchText id="match-42">Destination country: This category contained all the measurements obtained with vantage points (servers) located in the country, excluding those originating in the same country. In other words, these were only incoming measurements.</MatchText></li>
                        <li>
                            <MatchText id="match-43">Both country of origin and destination: This category contained all the measurements for which the country of origin was the same as their destination country. In other words, these measurements are internal to the country. This category contained all measurements performed with probes and vantage points located in the same country.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-44">The results can be seen in the three charts below, which show the median of the results obtained for each country.</MatchText></p>
                    <div className="col_3">
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r1.png" /></figure></div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r2.png" /></figure></div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r3.png" /></figure></div>
                    </div>

                    <p>
                        <MatchText id="match-45">A first glance shows that the values for both the outgoing latency (1) and the incoming latency are between 150 and 200 milliseconds. The charts also show that that, naturally, the internal latency (3) is lower than the outgoing and incoming latencies.</MatchText></p>
                    <p>
                        <MatchText id="match-46">Another aspect evidenced by this campaign is that the number of countries with active probes is much higher than the number of countries with test servers (37 and 26, respectively). This difference is the reason why the first chart has more entries than charts 2 and 3. In addition, not all &lt; country_of_origin, destination_country &gt; combinations have been covered by this measurement campaign, at least not at this time.</MatchText></p>
                    <p>
                        <MatchText id="match-47">Some countries with poor latencies in the previous charts are worth noting, as this is mainly the result of a bias in the measurement methodology. As mentioned earlier, the destination of the measurements is a pool of servers in the LAC region. Because these servers are not equally distributed across the region (larger countries tend to have more servers), when a new measurement is scheduled there is a higher probability that it will be scheduled for these larger countries. In fact, until the moment of writing this report, the countries that appear in figure 1 with the highest latencies — Cuba (CU), Turks and Caicos Islands (TC), French Guyana (GF), Suriname (SR), Guyana (GY), and Venezuela (VE) —have results exclusively towards those 5 countries. As time goes by and more measurements are scheduled to more countries, this bias will decrease, and measurements will better reflect the reality of regional connectivity.</MatchText></p>
                    <p>
                        <MatchText id="match-48">The information in the charts above can also be represented on a map:</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r4r5r6.png" /></figure>
                    <p>
                        <MatchText id="match-49">*Latency - Measurements originating in the country.</MatchText><br />
                        <MatchText id="match-50">
                            ** Latency with their destination in the country.</MatchText><br />
                        <MatchText id="match-51">
                            *** Latency within a country.</MatchText></p>
                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-37">Estudo 2020</MatchText></h2>
                    <p>
                        <MatchText id="match-38">Para esta edição do estudo, foi feita uma campanha de medição do início de setembro ao início de novembro. Nesse período, foram feitas 13 mil medições desde 26 países, com destino a 25 países. Por sua vez, essas 13 mil medições vieram de 332 Sistemas Autônomos diferentes.</MatchText></p>
                    <h3><MatchText id="match-39">Resultados</MatchText></h3>
                    <p>
                        <MatchText id="match-40">Uma vez que os dados foram obtidos, as medições foram agrupadas em 3 perspectivas diferentes:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-41">País de origem: esta categoria contém todas as medições com sondas localizadas no país, mas não inclui aquelas que terminam no mesmo país. Quer dizer, as medições são apenas de saída do país.</MatchText></li>
                        <li>
                            <MatchText id="match-42">País de destino: esta categoria contém todas as medições com pontos de teste (servidores) localizadas no país, mas não inclui aquelas que são originadas no mesmo país. Quer dizer, as medições são apenas de entrada ao país.</MatchText></li>
                        <li>
                            <MatchText id="match-43">Os dois países de origem e de destino: esta categoria contém todas as medições cujo país de origem e destino é o mesmo. Quer dizer, as medições que são internas ao país. Esta categoria contém todas as medições com sondas e pontos de teste localizados no mesmo país.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-44">Os resultados podem ser visualizados através dos três gráficos a seguir, em que mostramos a média dos resultados para cada país:</MatchText></p>
                    <div>
                        <div><figure> <img alt="Ver" src="/images/conectividad-lac-2020/r1.png" /></figure></div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r2.png" /></figure></div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r3.png" /></figure></div>
                    </div>

                    <p>
                        <MatchText id="match-45">A simples vista, pode se ver que, tanto para a latência de saída (1) quanto para a latência de entrada, os valores estão entre 150 e 200 milissegundos. Além disso, naturalmente, pode-se ver que a latência interna (3) é menor que a latência de saída e de entrada.</MatchText></p>
                    <p>
                        <MatchText id="match-46">Outro aspecto que pode ser verificado nesta campanha é que o número de países com sondas ativas é muito maior do que o número de países com servidores de teste: 37 e 26 respectivamente; esta diferença faz com que o gráfico 1 tenha mais entradas do que os gráficos 2 e 3. Além disso, isso implica que nem todas as combinações país_origem, país_destino &gt; são cobertas por esta campanha de medições, pelo menos até agora.</MatchText></p>
                    <p>
                        <MatchText id="match-47">Deve ser feita uma menção especial de alguns países que apresentam latências ruins nos gráficos anteriores, pois isso se deve principalmente a um viés na metodologia de medição. Como mencionamos anteriormente, o destino das medições é um pool de servidores na região LAC. Devido a que há uma concentração desigual desses servidores na região (há mais servidores em países maiores), ao agendar uma nova medição existe uma probabilidade maior de que seja agendada para esses países maiores. E, de fato, os países com as piores latências do gráfico 1 - Cuba (CU), Ilhas Turks e Caicos (TC), Guiana Francesa (GF), Suriname (SR), Guiana (GY) e Venezuela (VE) - têm, até o momento deste relatório, resultados apenas para esses países. Conforme o tempo passa e mais medições sejam programadas para mais países, esse viés irá diminuindo e as medições refletirão melhor a realidade da conectividade regional.</MatchText></p>
                    <p>
                        <MatchText id="match-48">Os gráficos anteriores também podem ser vistos em um mapa:</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r4r5r6.png" /></figure>
                    <p>
                        <MatchText id="match-49">* Latência - Medições originadas no país.</MatchText><br />
                        <MatchText id="match-50">** Latência com destino no país.</MatchText><br />
                        <MatchText id="match-51">*** Latência dentro de um mesmo país.</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Estudios;