import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Analisis() {
    const { myLang } = useContext(LangContext)
 
    return (
        <div>
            <div id="p8">
                {myLang === 'es' && <>
                    <h2>An&aacute;lisis de implementaci&oacute;n de pol&iacute;ticas <em>antispoofing</em> en la regi&oacute;n LAC</h2>
                    <p>En la presente secci&oacute;n se describe el estado de situaci&oacute;n en la regi&oacute;n respecto a la problem&aacute;tica de IP <em>spoofing</em> en redes que involucran recursos bajo la administraci&oacute;n de LACNIC.</p>
                    <p>Utilizando las fuentes de informaci&oacute;n incluidas en la secci&oacute;n &laquo;Fuentes de informaci&oacute;n disponibles&raquo;, se describen aqu&iacute; los principales hallazgos.</p>
                    <h3>Implementaci&oacute;n de filtros de tipo outbound SAV</h3>
                    <p>Los datos de esta secci&oacute;n fueron obtenidos de &lsaquo;<a href="https://spoofer.caida.org/country_stats.php" target="_blank" rel="noreferrer">https://spoofer.caida.org/country_stats.php</a><u>&rsaquo;</u>, el 17 de octubre de 2022</p>
                    <h3>Observaciones sobre los testeos en la regi&oacute;n</h3>
                    <p>La cantidad de bloques evaluados en la regi&oacute;n fue de 3082. Por cada AS puede haber varios bloques IP evaluados.</p>
                    <table className='table' >
                        <tbody>
                            <tr>
                                <td >
                                    <img src="/images/anti-spoofing/fig6.png" alt="" />
                                    <p>Figura 6 - Se observa que sobre el total de bloques evaluados, un 84,4% implementan filtros de tipo <em>outbound</em> SAV mientras que en el 15,6% restante no.</p>
                                </td>
                                <td >
                                    <img src="/images/anti-spoofing/fig7.png" alt="" />
                                    <p>Figura 7 - Se observa el porcentaje que representan los bloques de cada pa&iacute;s que implementan filtros de tipo <em>outbound</em> SAV sobre el total de bloques testeados que lo implementan (correspondiente al 84,4% de lo que muestra la Figura 6).&nbsp;</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Los gr&aacute;ficos anteriores nos dan una primera visi&oacute;n sobre la implementaci&oacute;n de filtrado de tipo <em>outbound</em> SAV en la regi&oacute;n.</p>
                    <h3>Observaciones sobre la distribuci&oacute;n de los testeos en los pa&iacute;ses de la regi&oacute;n</h3>

                    <p>Para poder evaluar la situaci&oacute;n por pa&iacute;s, es importante tener en cuenta la distribuci&oacute;n de los bloques analizados, es decir, la cantidad de testeos por pa&iacute;s. En la Figura 8 se puede observar la cantidad de pa&iacute;ses de la regi&oacute;n donde se realizaron testeos y la cantidad de pa&iacute;ses donde no hubo ninguno. En la Figura 9 se puede ver el detalle de cantidad de testeos por pa&iacute;s.</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/images/anti-spoofing/fig8.png" alt="" />
                                    <p>Como se observa en la Figura 8, sobre un total de 31 pa&iacute;ses, en 21 se realizaron testeos para verificar la aplicaci&oacute;n de filtros de tipo <em>outbound</em> SAV, mientras que en 10 pa&iacute;ses no hubo testeos.</p>
                                </td>
                                <td>
                                    <img src="/images/anti-spoofing/fig9.png" alt="" />
                                    <p>En la Figura 9 se puede observar que el pa&iacute;s donde m&aacute;s testeos se realizaron fue Brasil (2279 bloques de red testeados), seguido de M&eacute;xico (311 bloques testeados) y Argentina (100 bloques testeados). Tambi&eacute;n se observan los 10 pa&iacute;ses en los que no se realizaron testeos: Bonaire, San Eustaquio y Saba, Belice, Islas Malvinas (Falkland Islands), Guyana, Islas Georgias del Sur y Sandwich del Sur, Hait&iacute;, Nicaragua, Surinam, El Salvador y Saint Martin.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Observaciones sobre la implementaci&oacute;n de filtros de tipo outbound SAV en los bloques testeados en cada pa&iacute;s</h3>
                    <p>En las figuras 10, 11,12 y 13 se puede observar la situaci&oacute;n completa de cada pa&iacute;s. Esas figuras no incluyen aquellos pa&iacute;ses donde no se realizaron testeos (Bonaire, San Eustaquio y Saba, Belice, Islas Malvinas (Falkland Islands), Guyana, Islas Georgias del Sur y Sandwich del Sur, Hait&iacute;, Nicaragua, Surinam, El Salvador, Islas Georgias del Sur y Saint Martin).</p>
                    <p>En cada una de las figuras se muestra, sobre los bloques testeados por pa&iacute;s, la cantidad de bloques de red que no implementan filtros de tipo <em>outbound</em> SAV y la cantidad de bloques de red que s&iacute; lo hacen:</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td ><img src="/images/anti-spoofing/fig10.png" alt="" />
                                    <p><strong>Fig. 10 Situaci&oacute;n de Argentina, Brasil y M&eacute;xico respecto al <em>outbound</em> IP <em>spoofing</em></strong></p>
                                </td>
                                <td ><img src="/images/anti-spoofing/fig11.png" alt="" />
                                    <p><strong>Fig. 11 Situaci&oacute;n de Aruba, Bolivia, Chile, Colombia y Costa Rica respecto al <em>outbound</em> IP <em>spoofing</em></strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td  >
                                    <p>La Figura 10 incluye los tres pa&iacute;ses donde se realizaron m&aacute;s testeos (m&aacute;s de 100 testeos). Se puede observar que en Argentina se implementan filtros de tipo <em>outbound</em> SAV en un 46% de los bloques testeados. Mientras que, en Brasil se aplican filtros de tipo <em>outbound</em> SAV en un 83% del total de bloques testeados y en M&eacute;xico se aplican filtros de tipo outbound SAV en un 96% del total de bloques testeados.</p>
                                </td>
                                <td >
                                    <p>En la Figura 11 se puede observar tambi&eacute;n que gran porcentaje (m&aacute;s del 75%) de los bloques testeados implementan filtros de tipo <em>outbound</em> SAV, tanto en Bolivia como en Chile, Colombia y Costa Rica. Solo en Aruba ocurre que el porcentaje de bloques de red que implementan filtros es m&aacute;s bajo, solo dos (50% de las redes testeadas) lo implementan.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td  ><img src="/images/anti-spoofing/fig12.png" alt="" />
                                    <p><strong>Fig. 12 Situaci&oacute;n de Cuba, Curazao, Dominica, Ecuador, Guyana Francesa, Guatemala y Honduras respecto al <em>outbound IP spoofing</em></strong></p>
                                    <p>&nbsp;</p>
                                </td>
                                <td  ><img src="/images/anti-spoofing/fig13.png" alt="" />
                                    <p><strong>Fig. 13 Situaci&oacute;n de Hait&iacute;, Panam&aacute;, Per&uacute;, Paraguay, Trinidad y Tobago, Uruguay y Venezuela respecto al <em>outbound</em> IP <em>spoofing</em></strong></p>
                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>En la Figura 12 se puede observar que en Cuba, Curazao, Dominica y Guyana Francesa todos los bloques de red testeados implementan filtros de tipo <em>outbound</em> SAV. En Ecuador dichos filtros se implementan en 28 bloques de red, lo cual representa m&aacute;s del 90% de las redes testeadas. Mientras que en Guatemala la implementaci&oacute;n de filtros se da en dos de los tres bloques de red testeados (66%) y en Honduras el porcentaje de implementaci&oacute;n es m&aacute;s bajo, casi del 50% (en siete de los bloques testeados).</p>
                                </td>
                                <td>
                                    <p>La Figura 13 muestra tambi&eacute;n que en todas las redes testeadas en Per&uacute; y Trinidad y Tobago se implementan filtros de tipo <em>outbound</em> SAV; mientras que en Panam&aacute; y Venezuela dichos filtros se implementan casi en la totalidad de las redes testeadas: 24 de 25 y 68 de 69 respectivamente. En Paraguay en 13 de las 18 redes testeadas implementan filtros de tipo <em>outbound</em> SAV y en Uruguay 35 de 38. La implementaci&oacute;n se da en m&aacute;s del 70% en todos estos pa&iacute;ses, y en cuatro de ellos en casi el 100% de los bloques testeados.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Desde el punto de vista de la seguridad, el primer paso para mitigar un problema es conocer el estado de situaci&oacute;n. Surge de la informaci&oacute;n relevada que una propuesta &mdash;a partir de este estudio&mdash; es fomentar la realizaci&oacute;n de tests que permitan visibilizar mejor la situaci&oacute;n de la regi&oacute;n respecto de la implementaci&oacute;n de filtros de tipo <em>inbound</em> y <em>outbound</em> SAV.</p>
                    <p>En este sentido, es importante definir estrategias que permitan realizar testeos en aquellos pa&iacute;ses de la regi&oacute;n donde no se cuenta con informaci&oacute;n y tambi&eacute;n incrementar los testeos en aquellos pa&iacute;ses donde se han testeado pocas redes.</p>
                    <p>Brasil, al momento de obtener el reporte, es el pa&iacute;s que m&aacute;s testeos realizados registra a nivel mundial. En Brasil se analizaron 2279 bloques. Le sigue M&eacute;xico con 311 bloques y Argentina con 100 bloques testeados. En el resto de los pa&iacute;ses se hicieron pruebas en menos de 100 bloques, incluso en algunos solo se teste&oacute; uno.</p>
                    <h3>Implementaci&oacute;n de filtros de tipo inbound SAV</h3>
                    <p>Sobre la problem&aacute;tica relacionada con que las organizaciones permitan la recepci&oacute;n de tr&aacute;fico <em>spoofeado</em> desde internet, en la Figura 14 se puede apreciar la proporci&oacute;n de redes IPv4 /24 por pa&iacute;s que se confirmaron vulnerables por The Closed Resolver Project, dado que no implementan filtros de tipo <em>inbound</em> SAV.</p>
                    <img src="/images/anti-spoofing/fig14.png" alt="" />
                    <p>Fig. 14 - Proporci&oacute;n de redes IPv4 /24 por pa&iacute;s vulnerables al <em>inbound</em> IP <em>spoofing</em>. &lsaquo;<a href="https://closedresolver.korlabs.io/#results" target="_blank" rel="noreferrer">https://closedresolver.korlabs.io/#results</a><u>&rsaquo;,</u> 17 de octubre de 2022</p>
                    <p>De acuerdo a la informaci&oacute;n brindada por esta fuente, en todos los pa&iacute;ses de la regi&oacute;n el porcentaje de redes vulnerables al inbound IP spoofing se encuentra por debajo del 20%. En particular se puede observar que Brasil, Chile, Guyana Francesa, Per&uacute;, Uruguay y Surinam son los que presentan menor porcentaje de redes vulnerables al inbound IP spoofing; mientras que Guyana, Paraguay y Venezuela son los que presentan mayor porcentaje de redes vulnerables al inboud IP spoofing.</p>
                    <h3>Mecanismos para mitigar el IP <em>spoofing</em></h3>
                    <p>La mitigaci&oacute;n del IP<em> spoofing</em> es posible principalmente aplicando filtros de tipo <em>inbound</em> y <em>outbound</em> SAV. Como se mencion&oacute;, la falta de filtros de tipo SAV puede resultar en diferentes amenazas. Los filtros de tipo SAV son indicados inicialmente en la RFC 2827 para abordar la problem&aacute;tica de los ataques de DDoS basados en el uso de servicios reflexivos y amplificables.</p>
                    <p>Sin embargo, es importante entender que la implementaci&oacute;n de este tipo de filtros tambi&eacute;n ayuda a proteger la red de otro tipo de ataques, como los referidos anteriormente.</p>
                    <p>Considerando cuestiones sobre el mantenimiento de los filtros de tipo SAV referidos en la RFC 2827 y a situaciones particulares de redes <em>multihomed</em>, surge como actualizaci&oacute;n la RFC 3704. En esta RFC se proponen diferentes modos de implementar los filtros de tipo SAV. Cada uno de ellos ser&aacute; el m&aacute;s adecuado de usar seg&uacute;n las distintas situaciones. Tienen, adem&aacute;s, diferentes grados de efectividad. Los m&eacute;todos propuestos son:</p>
                    <ul>
                        <li>Filtros de tipo SAV basados en ACL.</li>
                        <li>Distintas versiones de filtros de tipo SAV basadas en mecanismos uRPF (Unicast Reverse Path Forwarding). Los mecanismos uRPF verifican de manera din&aacute;mica si la direcci&oacute;n de origen de un paquete recibido existe en la tabla de enrutamiento. Si no est&aacute; en la tabla de enrutamiento, el paquete se filtra.
                            <ul>
                                <li><em>Strict</em>: verifica que el ruteo a la direcci&oacute;n de origen del paquete se realiza a trav&eacute;s de la misma interfaz de red que la que recibi&oacute; el paquete.</li>
                                <li><em>Feasible</em>: verifica si hay alguna ruta a la direcci&oacute;n de origen del paquete (no necesariamente la mejor) que se realiza a trav&eacute;s de la misma interfaz de red que la que recibi&oacute; el paquete.</li>
                                <li><em>Loose</em>: verifica que exista una ruta a la direcci&oacute;n de origen del paquete. La interfaz es irrelevante.</li>
                                <li><em>Loose</em> ignorando <em>default</em> <em>routes</em>: verifica que exista una ruta a la direcci&oacute;n de origen del paquete, pero sin considerar en la evaluaci&oacute;n posibles <em>default routes</em> La interfaz es irrelevante.</li>
                            </ul>
                        </li>
                    </ul>
                    <h3>Recomendaciones generales </h3>
                    <p>Las siguientes recomendaciones se realizan utilizando las que da la <a href="https://www.rfc-editor.org/rfc/rfc2827" target="_blank" rel="noreferrer">RFC 2827</a>, la <a href="https://www.rfc-editor.org/rfc/rfc3704" target="_blank" rel="noreferrer">RFC 3704</a>, la gu&iacute;a de implementaci&oacute;n <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a> y la documentaci&oacute;n provista por el Anti-<a href="https://www.ripe.net/publications/docs/ripe-431" target="_blank" rel="noreferrer">Spoofing Task Force de RIPE</a>.</p>
                    <p>Con el objetivo de evitar tanto el <em>inbound</em> como el <em>outbound</em> IP <em>spoofing</em>, se recomienda implementar pol&iacute;ticas de filtrado <em>inbound</em> y <em>outbound</em> SAV, utilizando algunos de las siguientes t&eacute;cnicas:</p>
                    <ul>
                        <li>Filtros de tipo SAV basados en ACL</li>
                    </ul>
                    <p>Dependiendo de la cantidad de prefijos, puede resultar un problema el mantenimiento manual de las ACL que implementan tanto el <em>inbound</em> SAV como el <em>outbound</em> SAV.</p>
                    <ul>
                        <li>Filtros de tipo SAV basados en el mecanismo Strict uRPF</li>
                    </ul>
                    <p>Es una excelente alternativa para redes que tienen un ruteo sim&eacute;trico en el que no es necesario realizar el mantenimiento manual de ACL.</p>
                    <ul>
                        <li>Filtros de tipo SAV basados en el mecanismo Feasible uRPF</li>
                    </ul>
                    <p>En la gu&iacute;a de implementaci&oacute;n anti-<em>spoofing</em> de MANRS se indica:</p>
                    <p>Los operadores de red dudan en usar uRPF en el n&uacute;cleo de sus redes, debido al temor de perder accidentalmente tr&aacute;fico v&aacute;lido que ha tomado una ruta inesperada a trav&eacute;s de su red. El mecanismo Feasible uRPF deber&iacute;a resolver estos problemas.</p>
                    <h3>Tips de configuraci&oacute;n para Cisco</h3>
                    <p>A continuaci&oacute;n, se describe una implementaci&oacute;n posible en Cisco de una ACL para evitar el tr&aacute;fico <em>spoofeado</em>.</p>
                    <p>La Figura 15 representa el ejemplo dado en la gu&iacute;a de implementaci&oacute;n <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a>. En dicha gu&iacute;a se muestra la ACL que el ISP debe implementar en la interfaz marcada con un recuadro rojo para evitar que el cliente env&iacute;e tr&aacute;fico IP <em>spoofeado</em> a internet.</p>
                    <img src="/images/anti-spoofing/fig15.png" alt="" />
                    <p>Fig. 15 - Topolog&iacute;a de ejemplo para filtro de tipo inbound SAV basado en ACL</p>
                    <p>Suponiendo que los bloques IP de que dispone el cliente son 192.0.2.0/24 y 2001:db8:1001::/48, la ACL que el ISP configurar&iacute;a para evitar recibir tr&aacute;fico IP <em>spoofeado</em> ser&iacute;a:</p>
                    <pre>
                        <code>
                            <p>ip access-list extended customer1-in-ipv4 <br /> permit ip 192.0.2.0 0.0.0.255 any<br /> !<br /> ipv6 access-list customer1-in-ipv6<br /> permit ipv6 2001:db8:1001::/48 any <br /> !<br /> interface x<br /> ip access-group customer1-in-ipv4 in</p>
                            <p>ipv6 traffic-filter customer1-in-ipv6 in</p>
                        </code>
                    </pre>
                    <p>Para implementar filtros de tipo SAV basados en el mecanismo Strict uRPF, se pueden activar los filtros usando la siguiente configuraci&oacute;n en las interfaces de red:</p>
                    <pre>
                        <code>
                            <p>ip cef</p>
                            <p>ipv6 cef</p>
                            <p>ip verify unicast source reachable-via rx<br /> ipv6 verify unicast source reachable-via rx</p>

                        </code>
                    </pre>
                    <h3>Tips de configuraci&oacute;n para Juniper</h3>
                    <p>A continuaci&oacute;n, se describe la implementaci&oacute;n en Juniper de una ACL para evitar el tr&aacute;fico <em>spoofeado</em>.</p>
                    <p>Utilizando el ejemplo dado en la gu&iacute;a de implementaci&oacute;n <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a>, se muestra la ACL que el ISP puede implementar para evitar que el cliente env&iacute;e tr&aacute;fico <em>spoofeado</em> a internet.</p>
                    <pre>
                        <code>
                            <span>firewall {`{`}</span>
                            <span>family inet {`{`}</span>
                            <span>filter customer1-in-ipv4 {`{`}</span>
                            <span>term allowed-sources {`{`}</span>
                            <span>from {`{`}</span>
                            <span>source-address {`{`}</span>
                            <span>192.0.2.0/24;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>then accept;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>family inet6 {`{`}</span>
                            <span>filter customer1-in-ipv6 {`{`}</span>
                            <span>term allowed-sources {`{`}</span>
                            <span>from {`{`}</span>
                            <span>source-address {`{`}</span>
                            <span>2001:db8:1001::/48;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>then accept;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>interfaces x {`{`}</span>
                            <span>unit 0 {`{`}</span>
                            <span>family inet {`{`}</span>
                            <span>filter {`{`}</span>
                            <span>input customer1-in-ipv4;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>family inet6 {`{`}</span>
                            <span>filter {`{`}</span>
                            <span>input customer1-in-ipv6;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                        </code>
                    </pre>
                    <p>Para implementar filtros de tipo SAV basados en el mecanismo Strict uRPF, se pueden activar en Juniper, usando la siguiente configuraci&oacute;n en las interfaces de red:</p>
                    <pre>
                        <code>
                            <span>family inet {`{`}</span>
                            <span>rpf-check;</span>
                            <span>{`}`}</span>
                            <span>family inet6 {`{`}</span>
                            <span>rpf-check;</span>
                            <span>{`}`}</span>
                        </code>
                    </pre>
                    <p>Para implementar filtros de tipo SAV basados en el mecanismo uRPF modo Feasible, en <a href="https://www.juniper.net/documentation/us/en/software/junos/interfaces-ethernet-switches/static-routing/topics/ref/statement/unicast-reverse-path-edit-routing-options.html" target="_blank" rel="noreferrer">Juniper</a>, es necesario activarlo de manera global:</p>
                    <pre>
                        <code>
                            <span>family inet {`{`}</span>
                            <span>rpf-check;</span>
                            <span>{`}`}</span>
                            <span>family inet6 {`{`}</span>
                            <span>rpf-check;</span>
                            <span>{`}`}</span>
                        </code>
                    </pre>
                    <p>Y tambi&eacute;n activar en la configuraci&oacute;n de las interfaces de red:</p>
                    <pre>
                        <code>
                            <span>family inet {`{`}</span>
                            <span>rpf-check; {`{`}</span>
                            <span>mode loose;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                            <span>family inet6 {`{`}</span>
                            <span>rpf-check; {`{`}</span>
                            <span>mode loose;</span>
                            <span>{`}`}</span>
                            <span>{`}`}</span>
                        </code>
                    </pre>
                    <h3>Tips de configuraci&oacute;n para MikroTik</h3>
                    <p>En MikroTik, la manera de configurar una ACL para evitar que salga a internet tr&aacute;fico <em>spoofeado</em> es definiendo una lista de direcciones IPs permitidas para la organizaci&oacute;n. Esta lista debe considerar todas las IPs y/o bloques de direcciones que podr&iacute;an ser el origen de los paquetes que salen a internet.</p>
                    <p>Crear una regla de <a href="https://help.mikrotik.com/docs/display/ROS/Filter" target="_blank" rel="noreferrer"><em>firewall</em></a> que acepte el tr&aacute;fico que cumpla las siguientes condiciones:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li><a href="https://help.mikrotik.com/docs/display/ROS/Address-lists" target="_blank" rel="noreferrer"><em> Address List</em></a>: La lista de direcciones permitidas</li>
                        <li><em> Interface</em>: Interfaz de WAN que se conecta a internet</li>
                    </ul>
                    <p>Crear una regla de firewall que deniegue lo que cumpla las siguientes condiciones:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li>Interface: Interfaz de WAN que se conecta a internet.</li>
                    </ul>
                    <p>Para implementar filtros de tipo SAV basados en el mecanismo Strict uRPF, se pueden activar en <a href="https://help.mikrotik.com/docs/display/ROS/IP+Settings" target="_blank" rel="noreferrer">MikroTik</a> desde el men&uacute; IP &rarr; Settings &rarr; RP Filter = strict .</p>
                    <p>Nota: El mecanismo Feasible URPF no est&aacute; soportado en la &uacute;ltima versi&oacute;n estable al momento de realizar este informe: RouterOS 7.5.</p>

                </>}
                {myLang === 'en' && <>
                    <h1>Analysis of the implementation of anti-spoofing policies in the LAC region</h1>
                    <p>This section describes the status of the region with respect to IP spoofing in networks involving resources under LACNIC administration.</p>
                    <p>It describes the main findings based on the information sources included in the section titled &ldquo;Available sources of information.&rdquo;</p>
                    <h2>Outbound SAV implementation</h2>
                    <p>The data in this section were obtained from &lsaquo;<a href="https://spoofer.caida.org/country_stats.php">https://spoofer.caida.org/country_stats.php</a><u>&rsaquo;</u> on 17 October 2022</p>
                    <h3>Observations regarding the tests conducted in the region</h3>
                    <p>A total of 3082 blocks were evaluated in the region. For each AS, several IP blocks may have been assessed.</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/images/anti-spoofing/fig6.png" alt="" />
                                    <p>Figure 6 - Out of the total number of blocks that were evaluated, 84.4% implement outbound SAV, while the remaining 15.6% do not.</p>
                                </td>
                                <td>
                                    <img src="/images/anti-spoofing/fig7.png" alt="" />
                                    <p>Figure 7 - Percentage of blocks that implement outbound SAV per country (out of the total of 84.4% shown in Figure 6).&nbsp;</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>The images above provide a first insight into the outbound SAV deployment in the region.</p>
                    <h2>Observations regarding the distribution of the tests among the countries of the region</h2>
                    <p>To assess the situation by country, it is important to consider the distribution of the blocks that were analyzed, i.e., the number of tests per country. Figure 8 shows the number of countries in the region where tests were and were not carried out. Figure 9 breaks down the number of tests by country.</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/images/anti-spoofing/fig8.png" alt="" />
                                    <p>As Figure 8 shows, out of a total of 31 countries, tests to detect outbound SAV were carried out in 21. No tests were conducted in the remaining 10 countries.</p>
                                </td>
                                <td>
                                    <img src="/images/anti-spoofing/fig9.png" alt="" />
                                    <p>Figure 9 shows that Brazil was the country where the highest number of tests were performed (2279 IP blocks), followed by Mexico (311 IP blocks), and Argentina (100 IP blocks). It also shows the 10 countries where no tests were carried out: Bonaire, Saint Eustatius and Saba, Belize, Falkland Islands (Malvinas Islands), Guyana, South Georgia and the South Sandwich Islands, Haiti, Nicaragua, Suriname, El Salvador, and Saint Martin.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Observations regarding the implementation of outbound SAV in the blocks tested in each country</h2>
                    <p>Figures 10, 11, 12, and 13 show the situation in each country. Countries where no testing was conducted are not included in these figures [Bonaire, Saint Eustatius and Saba, Belize, Falkland Islands (Islas Malvinas), Guyana, South Georgia and the South Sandwich Islands, Haiti, Nicaragua, Suriname, El Salvador, South Georgia Islands, and Saint Martin].</p>
                    <p>Each of these figures shows the number of IP blocks that do and do not implement outbound SAV out of the total number of blocks tested in each country:</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/images/anti-spoofing/fig10.png" alt="" />
                                    <p><strong>Figure 10 - Outbound IP spoofing in Argentina, Brazil, and Mexico</strong></p>
                                </td>
                                <td>
                                    <img src="/images/anti-spoofing/fig11.png" alt="" />
                                    <p><strong>Figure 11 - Outbound IP spoofing in Aruba, Bolivia, Chile, Colombia, and Costa Rica</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Figure 10 shows the three countries where the highest number of tests were carried out (more than 100 tests). As the image shows, outbound SAV are implemented in 46% of the blocks tested in Argentina. Likewise, outbound SAV is implemented in 83% of the total number of blocks tested in Brazil and 96% of those tested in Mexico.</p>
                                </td>
                                <td>
                                    <p>Figure 11 also shows that a large percentage (more than 75%) of the networks tested in Bolivia, Chile, Colombia, and Costa Rica implement outbound SAV. The percentage of IP blocks that implement SAV filtering is only lower in Aruba (just two or 50% of the tested networks).</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/images/anti-spoofing/fig12.png" alt="" />
                                    <p><strong>Figure 12 - Outbound IP spoofing in Cuba, Cura&ccedil;ao, Dominica, Ecuador, French Guiana, Guatemala, and Honduras</strong></p>
                                </td>
                                <td>
                                    <img src="/images/anti-spoofing/fig13.png" alt="" />
                                    <p><strong>Figure 13 - Outbound IP spoofing Haiti, Panama, Peru, Paraguay, Trinidad and Tobago, Uruguay, and Venezuela</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Figure 12 shows that all IP blocks tested in Cuba, Cura&ccedil;ao, Dominica, and French Guyana implement outbound SAV. In Ecuador, outbound SAV is implemented in 28 IP blocks, which represents more than 90% of tested networks. Likewise, in Guatemala outbound SAV is implemented in two of the three tested IP blocks (66%), while in Honduras the percentage of implementation is lower, almost 50% (seven of the tested blocks).</p>
                                </td>
                                <td>
                                    <p>Figure 13 also shows that outbound SAV was implemented in all the networks tested in Peru and Trinidad and Tobago, while in Panama and Venezuela outbound SAV was implemented in almost all tested networks: 24 out of 25 and 68 out of 69, respectively. In Paraguay, 13 out of the 18 tested networks implement outbound SAV. In Uruguay, 35 out of 38. Overall, in these countries, outbound SAV was implemented in more than 70% of the networks, while almost 100% of the blocks tested in four of these countries had deployed SAV.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>From a security standpoint, the first step in mitigating a problem is understanding the situation. Based on the information collected in this study, we propose encouraging testing to gain a better understanding of the regional situation in terms of the implementation of inbound and outbound SAV.</p>
                    <p>In this sense, it is important to define strategies that will allow testing in those countries of the region for which there is no available information and to increase testing in those where only a few networks have been tested.</p>
                    <p>At the time of writing this report, Brazil is the country where the highest number of tests have been performed, not only in the region but worldwide. A total of 2,279 blocks have been tested in Brazil. This is followed by Mexico with 311 blocks and Argentina with 100 blocks. In the rest of the countries, tests were carried out on less than 100 blocks, with as few as one block tested in some countries.</p>
                    <h2>Inbound SAV deployment</h2>
                    <p>Regarding for the problems related to organizations that allow the reception of spoofed traffic from the Internet, Figure 14 shows the proportion of /24 IPv4 networks per country that were confirmed as being vulnerable by The Closed Resolver Project, as they do not implement inbound SAV.</p>
                    <img src="/images/anti-spoofing/fig14.png" alt="" />
                    <p>Figure 14 - Percentage of /24 IPv4 networks vulnerable to inbound IP spoofing, by country. &lsaquo;<a href="https://closedresolver.korlabs.io/%23results">https://closedresolver.korlabs.io/#results</a><u>&rsaquo;,</u> 17 October 2022</p>
                    <p>According to the information provided by this source, less than 20% of the networks in the region are vulnerable to inbound IP spoofing. Brazil, Chile, French Guyana, Peru, Uruguay, and Suriname are the countries with the lowest percentage of networks vulnerable to inbound IP spoofing, while Guyana, Paraguay, and Venezuela have the highest percentages of networks vulnerable to inbound IP spoofing.</p>
                    <p><strong>IP spoofing mitigation mechanisms</strong></p>
                    <p>IP spoofing can be mitigated mainly by applying inbound and outbound SAV. As mentioned earlier, failure to deploy SAV may create different threats. SAV was initially specified in RFC 2827 to address the problem of DDoS attacks that use reflective and amplifying services.</p>
                    <p>However, it is important to understand that the implementation of this type of filtering also helps to protect the network from other types of attacks, such as those described earlier in this document.</p>
                    <p>RFC 3704 was created later, considering issues related to the maintenance of the ingress filtering described in RFC 2827 and situations specific to multihomed networks. This RFC proposes different ways to implement SAV. Each will be most appropriate depending on the specific situation. Their effectiveness also varies. Proposed methods include:</p>
                    <ul>
                        <li>ACL-based SAV.</li>
                        <li>Various versions of SAV based on Unicast Reverse Path Forwarding (uRPF) mechanisms. uRPF mechanisms dynamically check whether the source address of a received packet is present in the routing table. Packets whose source address is not present in the routing table are dropped.
                            <ul>
                                <li>Strict mode: Checks that each packet's source address is routed through the same network interface that received the packet.</li>
                                <li>Feasible mode: Checks whether any of the routes to the packet's source address (not necessarily the best route) is through the same network interface that received the packet.</li>
                                <li>Loose mode: Verifies the existence of a route to the packet's source address. The interface is immaterial.</li>
                                <li>Loose mode ignoring default routes: Verifies the existence of a route to the packet's source address, but without considering the default routes that may potentially have been configured. The interface is immaterial.</li>
                            </ul>
                        </li>
                    </ul>
                    <h2>General recommendations</h2>
                    <p>The following recommendations are offered based on those provided by <a href="https://www.rfc-editor.org/rfc/rfc2827" target="_blank" rel="noreferrer">RFC 2827</a>,&nbsp;<a href="https://www.rfc-editor.org/rfc/rfc3704" target="_blank" rel="noreferrer">RFC 3704</a>, the <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">MANRS Anti-Spoofing Implementation Guide</a>, and the documentation provided by the RIPE <a href="https://www.ripe.net/publications/docs/ripe-431" target="_blank" rel="noreferrer">Anti-Spoofing Task Force</a>.</p>
                    <p>The implementation of inbound and outbound SAV policies is recommended to avoid both inbound and outbound IP spoofing. Some of the following techniques may be used:</p>
                    <ul>
                        <li>ACL-based SAV</li>
                    </ul>
                    <p>Depending on the number of prefixes, it may be difficult to manually maintain the ACLs implemented by both inbound and outbound SAV.</p>
                    <ul>
                        <li>SAV filtering based on uRPF Strict mode</li>
                    </ul>
                    <p>This is an excellent option for networks with symmetric routing where manual ACL maintenance is not required.</p>
                    <ul>
                        <li>SAV filtering based on uRPF Feasible mode</li>
                    </ul>
                    <p>According to the MANRS Anti-Spoofing Implementation Guide:</p>
                    <p>Network operators are hesitant to use uRPF in the core of their networks because of the fear of accidentally dropping valid traffic that has taken an unexpected path through their network. uRPF Feasible Path mode should solve such problems.</p>
                    <h2>Configuration tips for Cisco routers</h2>
                    <p>A possible implementation of an ACL in Cisco routers to prevent spoofed traffic is described below.</p>
                    <p>Figure 15 shows the example included in the <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">MANRS Anti-Spoofing Implementation Guide</a>.&nbsp;In this figure, a red square is used to highlight the ACL that the ISP must implement on the interface to prevent the client from sending spoofed IP traffic to the Internet.</p>
                    <img src="/images/anti-spoofing/fig15.png" alt="" />
                    <p>Figure 15 - Sample topology for ACL-based inbound SAV</p>
                    <p>Assuming that the client's IP blocks are 192.0.2.0/24 and 2001:db8:1001::/48, the ACL that the ISP would configure to avoid receiving spoofed IP traffic would be:</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ip access-list extended customer1-in-ipv4 <br /> permit ip 192.0.2.0 0.0.0.255 any<br /> !<br /> ipv6 access-list customer1-in-ipv6<br /> permit ipv6 2001:db8:1001::/48 any <br /> !<br /> interface x<br /> ip access-group customer1-in-ipv4 in</p>
                                    <p>&nbsp;ipv6 traffic-filter customer1-in-ipv6 in</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>To implement SAV based on uRPF Strict mode, filters can be enabled using the following configuration on the network interfaces:</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ip cef</p>
                                    <p>ipv6 cef</p>
                                    <p>ip verify unicast source reachable-via rx<br /> ipv6 verify unicast source reachable-via rx</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Configuration tips for Juniper routers</h2>
                    <p>The implementation of an ACL to prevent spoofed traffic in Juniper routers is described below.</p>
                    <p>Using the example included in the <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">MANRS Anti-Spoofing Implementation Guide</a>,&nbsp;below is the ACL that the ISP can implement to prevent the client from sending spoofed traffic to the internet.</p>
                    <pre>
                <code>
                    <span>firewall {`{`}</span>
                    <span>family inet {`{`}</span>
                    <span>filter customer1-in-ipv4 {`{`}</span>
                    <span>term allowed-sources {`{`}</span>
                    <span>from {`{`}</span>
                    <span>source-address {`{`}</span>
                    <span>192.0.2.0/24;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>then accept;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>filter customer1-in-ipv6 {`{`}</span>
                    <span>term allowed-sources {`{`}</span>
                    <span>from {`{`}</span>
                    <span>source-address {`{`}</span>
                    <span>2001:db8:1001::/48;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>then accept;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>interfaces x {`{`}</span>
                    <span>unit 0 {`{`}</span>
                    <span>family inet {`{`}</span>
                    <span>filter {`{`}</span>
                    <span>input customer1-in-ipv4;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>filter {`{`}</span>
                    <span>input customer1-in-ipv6;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>To implement SAV based on uRPF Strict mode, Juniper router filters can be enabled using the following configuration on the network interfaces:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>To implement SAV based on uRPF Feasible mode in <a href="https://www.juniper.net/documentation/us/en/software/junos/interfaces-ethernet-switches/static-routing/topics/ref/statement/unicast-reverse-path-edit-routing-options.html" target="_blank" rel="noreferrer">Juniper routers</a>, it must be enabled globally:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>It must also be enabled in the network interface configuration:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check; {`{`}</span>
                    <span>mode loose;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check; {`{`}</span>
                    <span>mode loose;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <h2>Configuration tips for MikroTik routers</h2>
                    <p>In MikroTik routers, configuring an ACL to prevent spoofed traffic from reaching the Internet requires the definition of a list of allowed IP addresses for the organization. This list must consider all the IPs and/or address blocks that may originate packets towards the Internet.</p>
                    <p>Create a <a href="https://help.mikrotik.com/docs/display/ROS/Address-lists" target="_blank" rel="noreferrer">firewall</a>&nbsp;rule that accepts traffic that meets the following conditions:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li><em> Address List</em>: <a href="https://help.mikrotik.com/docs/display/ROS/Address-lists" target="_blank" rel="noreferrer">List of allowed IP addresses</a></li>
                        <li><em> Interface</em>: WAN interface that connects to the Internet</li>
                    </ul>
                    <p>Create a firewall rule that drops any traffic that meets the following conditions:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li>Interface: WAN interface that connects to the Internet</li>
                    </ul>
                    <p>To implement SAV based on uRPF Strict mode, <a href="https://help.mikrotik.com/docs/display/ROS/IP+Settings" target="_blank" rel="noreferrer">MikroTik router filters</a> can be enabled in the IP &rarr; Settings &rarr; RP Filter = strict menu.</p>
                    <p>Note: At the time of writing this report, the uRPF Feasible mode is not supported in the latest stable version: RouterOS 7.5.</p>
                </>}
                {myLang === 'pt' && <>
                    <h1>An&aacute;lise de implementa&ccedil;&atilde;o de pol&iacute;ticas <em>antispoofing</em> na regi&atilde;o da ALC</h1>
                    <p>Esta se&ccedil;&atilde;o descreve a situa&ccedil;&atilde;o da regi&atilde;o em rela&ccedil;&atilde;o ao problema de <em>IP spoofing</em> em redes que envolvem recursos administrados pelo LACNIC.</p>
                    <p>Usando as fontes de informa&ccedil;&atilde;o inclu&iacute;das na se&ccedil;&atilde;o &ldquo;Fontes de informa&ccedil;&atilde;o dispon&iacute;veis&rdquo;, as principais conclus&otilde;es s&atilde;o descritas aqui.</p>
                    <h2>Implementa&ccedil;&atilde;o de filtros do tipo outbound SAV</h2>
                    <p>Os dados desta se&ccedil;&atilde;o foram obtidos de: &lsaquo;h<a href="https://spoofer.caida.org/country_stats.php">ttps://spoofer.caida.org/country_stats.php</a><u>&rsaquo;</u>, em 17 de outubro de 2022.</p>
                    <h2>Observa&ccedil;&otilde;es sobre os testes na regi&atilde;o</h2>
                    <p>O n&uacute;mero de blocos avaliados na regi&atilde;o foi de 3082. Para cada AS podem existir v&aacute;rios blocos IP avaliados.</p>
                    <img src="/images/anti-spoofing/fig6.png" alt="" />
                    <p>Figura 6 - Observa-se que do total de blocos avaliados, 84,4% implementam filtros do tipo <em>outbound SAV</em>, enquanto os 15,6% restantes n&atilde;o.</p>
                    <img src="/images/anti-spoofing/fig7.png" alt="" />
                    <p>Figura 7 - Observa-se a percentagem representada pelos blocos de cada pa&iacute;s que implementam filtros do tipo <em>outbound SAV</em> sobre o total de blocos testados que o implementam (correspondente a 84,4% do que &eacute; mostrado na Figura 6).&nbsp;</p>
                    <p>Os gr&aacute;ficos acima mostram uma primeira vis&atilde;o sobre a implementa&ccedil;&atilde;o da filtragem do tipo <em>outbound SAV</em> na regi&atilde;o.</p>
                    <h2>Observa&ccedil;&otilde;es sobre a distribui&ccedil;&atilde;o de testes nos pa&iacute;ses da regi&atilde;o</h2>
                    <p>Para avaliar a situa&ccedil;&atilde;o por pa&iacute;s &eacute; importante levar em conta a distribui&ccedil;&atilde;o dos blocos analisados, isto &eacute;, o n&uacute;mero de testes por pa&iacute;s. A Figura 8 mostra o n&uacute;mero de pa&iacute;ses da regi&atilde;o onde os testes foram realizados e o n&uacute;mero de pa&iacute;ses onde n&atilde;o houve nenhum. A Figura 9 mostra o detalhe do n&uacute;mero de testes por pa&iacute;s.</p>
                    <img src="/images/anti-spoofing/fig8.png" alt="" />
                    <p>Como pode ser observado na Figura 8, de um total de 31 pa&iacute;ses, foram realizados testes em 21 deles para verificar a aplica&ccedil;&atilde;o de filtros do tipo <em>outbound SAV</em>, enquanto em 10 pa&iacute;ses n&atilde;o houve testes.</p>
                    <img src="/images/anti-spoofing/fig9.png" alt="" />
                    <p>Na Figura 9 pode-se observar que o pa&iacute;s onde mais testes foram realizados foi o Brasil (2279 blocos de rede testados), seguido do M&eacute;xico (311 blocos testados) e Argentina (100 blocos testados). Os 10 pa&iacute;ses em que n&atilde;o foram realizados testes tamb&eacute;m s&atilde;o colocados: Bonaire, Santo Eust&aacute;quio e Saba, Belize, Ilhas Malvinas (Falkland Islands), Guiana, Ilhas Ge&oacute;rgia do Sul e Sandwich do Sul, Haiti, Nicar&aacute;gua, Suriname, El Salvador e Saint Martin.</p>
                    <h2>Observa&ccedil;&otilde;es sobre a implementa&ccedil;&atilde;o de filtros do tipo outbound SAV nos blocos testados em cada pa&iacute;s</h2>
                    <p>As Figuras 10, 11, 12 e 13 mostram a situa&ccedil;&atilde;o completa de cada pa&iacute;s. Essas figuras n&atilde;o incluem os pa&iacute;ses onde n&atilde;o foram realizados testes (Bonaire, Santo Eust&aacute;quio e Saba, Belize, Ilhas Malvinas (Falkland Islands), Guiana, Ilhas Ge&oacute;rgia do Sul e Sandwich do Sul, Haiti, Nicar&aacute;gua, Suriname, El Salvador e Saint Martin).</p>
                    <p>Cada uma das figuras mostra, nos blocos testados por pa&iacute;s, o n&uacute;mero de blocos de rede que n&atilde;o implementam filtros do tipo <em>outbound SAV</em> e o n&uacute;mero de blocos de rede que sim fazem:</p>
                    <img src="/images/anti-spoofing/fig10.png" alt="" />
                    <p>Fig. 10 - Situa&ccedil;&atilde;o da Argentina, Brasil e M&eacute;xico em rela&ccedil;&atilde;o ao <em>outbound</em> IP <em>spoofing</em> </p>
                    <p>A Figura 10 inclui os tr&ecirc;s pa&iacute;ses onde mais testes foram realizados (mais de 100 testes). Pode-se observar que na Argentina os filtros do tipo <em>outbound SAV</em> est&atilde;o implementados em 46% dos blocos testados. Enquanto no Brasil, os filtros do tipo <em>outbound SAV</em> s&atilde;o aplicados em 83% do total de blocos testados e no M&eacute;xico os filtros do tipo <em>outbound SAV</em> s&atilde;o aplicados em 96% do total de blocos testados.</p>
                    <img src="/images/anti-spoofing/fig11.png" alt="" />
                    <p>Fig. 11 - Situa&ccedil;&atilde;o da Aruba, Bol&iacute;via, Chile, Col&ocirc;mbia e Costa Rica em rela&ccedil;&atilde;o ao <em>outbound</em> IP <em>spoofing</em></p>
                    <p>Na Figura 11 tamb&eacute;m pode ser visto que uma grande percentagem (mais de 75%) dos blocos testados implementam filtros do tipo <em>outbound SAV</em>, tanto na Bol&iacute;via quanto no Chile, Col&ocirc;mbia e Costa Rica. Somente em Aruba acontece que a percentagem de blocos de rede que implementam filtros &eacute; menor, apenas dois (50% das redes testadas) o implementam.</p>
                    <img src="/images/anti-spoofing/fig12.png" alt="" />
                    <p>Fig. 12 - Situa&ccedil;&atilde;o de Cuba, Cura&ccedil;ao, Dominica, Equador, Guiana Francesa, Guatemala e Honduras em rela&ccedil;&atilde;o ao <em>outbound IP spoofing</em></p>
                    <p>Na Figura 12 pode ser visto que em Cuba, Cura&ccedil;ao, Dominica e Guiana Francesa todos os blocos de rede testados implementam filtros do tipo <em>outbound</em> SAV. No Equador, esses filtros s&atilde;o implementados em 28 blocos de rede, o que representa mais de 90% das redes testadas. Enquanto na Guatemala a implementa&ccedil;&atilde;o de filtros ocorre em dois dos tr&ecirc;s blocos de rede testados (66%) e em Honduras o percentual de implementa&ccedil;&atilde;o &eacute; menor, quase 50% (em sete dos blocos testados).</p>
                    <img src="/images/anti-spoofing/fig13.png" alt="" />
                    <p>Fig. 13 - Situa&ccedil;&atilde;o do Haiti, Panam&aacute;, Peru, Paraguai, Trinidad e Tobago, Uruguai e Venezuela em rela&ccedil;&atilde;o ao <em>outbound</em> IP <em>spoofing</em></p>
                    <p>A Figura 13 tamb&eacute;m mostra que em todas as redes testadas no Peru e Trinidad e Tobago s&atilde;o implementados filtros do tipo <em>outbound SAV</em>; enquanto no Panam&aacute; e na Venezuela os referidos filtros s&atilde;o implementados em quase todas as redes testadas: 24 de 25, e 68 de 69 respectivamente. No Paraguai, 13 das 18 redes testadas implementam filtros do tipo <em>outbound SAV</em> e no Uruguai 35 de 38. A implementa&ccedil;&atilde;o ocorre em mais de 70% em todos esses pa&iacute;ses, e em quatro deles em quase 100% dos blocos testados.</p>
                    <p>Do ponto de vista da seguran&ccedil;a, o primeiro passo para mitigar um problema &eacute; conhecer o status da situa&ccedil;&atilde;o. Das informa&ccedil;&otilde;es levantadas, resulta que uma proposta &mdash; com base neste estudo &mdash; &eacute; promover a realiza&ccedil;&atilde;o de testes que permitam visualizar melhor a situa&ccedil;&atilde;o da regi&atilde;o quanto &agrave; implementa&ccedil;&atilde;o de filtros do tipo <em>inbound</em> e <em>outbound SAV</em>.</p>
                    <p>Nesse sentido, &eacute; importante definir estrat&eacute;gias que permitam realizar testes naqueles pa&iacute;ses da regi&atilde;o onde n&atilde;o h&aacute; informa&ccedil;&atilde;o e tamb&eacute;m aumentar os testes naqueles pa&iacute;ses onde poucas redes foram testadas.</p>
                    <p>O Brasil, no momento da obten&ccedil;&atilde;o do relat&oacute;rio, &eacute; o pa&iacute;s com mais testes realizados no mundo. No Brasil, foram analisados ​​2279 blocos. Depois segue o M&eacute;xico, com 311 blocos e a Argentina com 100 blocos testados. No restante dos pa&iacute;ses foram realizados testes em menos de 100 blocos, inclusive em alguns apenas um foi testado.</p>
                    <h2>Implementa&ccedil;&atilde;o de filtros do tipo inbound SAV</h2>
                    <p>Sobre o problema relacionado a organiza&ccedil;&otilde;es que permitem a recep&ccedil;&atilde;o de tr&aacute;fego<em>spoofeado</em> da Internet, a Figura 14 mostra a propor&ccedil;&atilde;o de redes IPv4/24 por pa&iacute;s que foram confirmadas como vulner&aacute;veis ​​pelo The Closed Resolver Project, pois n&atilde;o implementam filtros do tipo <em>inbound</em> SAV.&nbsp;</p>
                    <img src="/images/anti-spoofing/fig14.png" alt="" />
                    <p>Fig. 14 - Propor&ccedil;&atilde;o de redes IPv4 /24 por pa&iacute;s vulner&aacute;veis ao <em>inbound</em> IP <em>spoofing</em>. &lsaquo;<a href="https://closedresolver.korlabs.io/#results">https://closedresolver.korlabs.io/#results</a><u>&rsaquo;,</u> 17 de outubro de 2022</p>
                    <p>Segundo as informa&ccedil;&otilde;es fornecidas por esta fonte, em todos os pa&iacute;ses da regi&atilde;o, a percentagem de redes vulner&aacute;veis ​​ao <em>inbound IP spoofing</em> &eacute; inferior a 20%. Em particular, pode-se observar que o Brasil, Chile, Guiana Francesa, Peru, Uruguai e Suriname s&atilde;o os que apresentam o menor percentual de redes vulner&aacute;veis ​​ao <em>inbound IP spoofing</em>; enquanto a Guiana, Paraguai e Venezuela s&atilde;o os que t&ecirc;m a maior percentagem de redes vulner&aacute;veis ​​ao <em>inbound IP spoofing</em>.</p>
                    <p>Mecanismos para mitigar o IP <em>spoofing</em></p>
                    <p>A mitiga&ccedil;&atilde;o do IP<em> spoofing</em> &eacute; poss&iacute;vel principalmente se aplicarmos filtros do tipo <em>inbound</em> e <em>outbound</em> SAV. Conforme mencionado, a falta de filtros do tipo SAV pode resultar em diferentes amea&ccedil;as. Os filtros do tipo SAV s&atilde;o indicados inicialmente na RFC 2827 para resolver o problema dos ataques de DDoS baseados no uso de servi&ccedil;os reflexivos e amplific&aacute;veis.</p>
                    <p>No entanto, &eacute; importante compreender que a implementa&ccedil;&atilde;o deste tipo de filtros tamb&eacute;m ajuda a proteger a rede de outro tipo de ataques, como os acima referidos.</p>
                    <p>Considerando quest&otilde;es sobre a manuten&ccedil;&atilde;o dos filtros do tipo SAV referidos na RFC 2827 e a situa&ccedil;&otilde;es particulares de redes <em>multihomed</em>, a RFC 3704 surge como uma atualiza&ccedil;&atilde;o. Nesta RFC s&atilde;o propostas diferentes formas de implementa&ccedil;&atilde;o de filtros do tipo SAV. Cada um deles ser&aacute; o mais adequado para usar de acordo com as diferentes situa&ccedil;&otilde;es. Eles tamb&eacute;m t&ecirc;m diferentes graus de efic&aacute;cia. Os m&eacute;todos propostos s&atilde;o:</p>
                    <ul>
                        <li>Filtros do tipo SAV baseados em ACL.</li>
                        <li>Diferentes vers&otilde;es de filtros do tipo SAV baseados em mecanismos uRPF (Unicast Reverse Path Forwarding). Os mecanismos uRPF verificam dinamicamente se o endere&ccedil;o de origem de um pacote recebido existe na tabela de roteamento. Se n&atilde;o estiver na tabela de roteamento, o pacote &eacute; filtrado.
                            <ul>
                                <li><em>Strict</em>: verifica que o roteamento para o endere&ccedil;o de origem do pacote &eacute; feito atrav&eacute;s da mesma interface de rede que recebeu o pacote.</li>
                                <li><em>Feasible</em>: verifica se existe alguma rota para o endere&ccedil;o de origem do pacote (n&atilde;o necessariamente a melhor) que seja feita atrav&eacute;s da mesma interface de rede que recebeu o pacote.</li>
                                <li><em>Loose</em>: verifica que exista uma rota para o endere&ccedil;o de origem do pacote. A interface &eacute; irrelevante.</li>
                                <li><em>Loose</em> ignorando <em>default</em> <em>routes</em>: verifica que exista uma rota para o endere&ccedil;o de origem do pacote, mas sem considerar na avalia&ccedil;&atilde;o poss&iacute;veis <em>default routes</em> A interface &eacute; irrelevante.</li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Recomenda&ccedil;&otilde;es gerais</h2>
                    <p>As recomenda&ccedil;&otilde;es a seguir s&atilde;o feitas usando aquelas fornecidas pela <a href="https://www.rfc-editor.org/rfc/rfc2827" target="_blank" rel="noreferrer">RFC 2827</a>,&nbsp;a <a href="https://www.rfc-editor.org/rfc/rfc3704" target="_blank" rel="noreferrer">RFC 3704</a>,&nbsp;o guia de implementa&ccedil;&atilde;o <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a>&nbsp;e a documenta&ccedil;&atilde;o fornecida pelo Anti-<a href="https://www.ripe.net/publications/docs/ripe-431" target="_blank" rel="noreferrer">Spoofing Task Force do RIPE</a>.</p>
                    <p>A fim de evitar o <em>inbound</em> e o <em>outbound</em> IP <em>spoofing</em>, &eacute; recomend&aacute;vel implementar pol&iacute;ticas de filtragem <em>inbound</em> e <em>outbound</em> SAV, usando algumas das seguintes t&eacute;cnicas:</p>
                    <ul>
                        <li>Filtros do tipo SAV baseados na ACL</li>
                    </ul>
                    <p>Dependendo do n&uacute;mero de prefixos, a manuten&ccedil;&atilde;o manual das ACL que implementam <em>inbound</em> SAV e <em>outbound</em> SAV pode ser um problema.</p>
                    <ul>
                        <li>Filtros do tipo SAV baseados no mecanismo Strict uRPF</li>
                    </ul>
                    <p>&Eacute; uma excelente alternativa para redes que possuem roteamento sim&eacute;trico em que a manuten&ccedil;&atilde;o manual da ACL n&atilde;o &eacute; necess&aacute;ria.</p>
                    <ul>
                        <li>Filtros do tipo SAV baseados no mecanismo Feasible uRPF</li>
                    </ul>
                    <p>O guia de implementa&ccedil;&atilde;o anti-<em>spoofing</em> de MANRS declara:</p>
                    <p>As operadoras de rede hesitam em usar o uRPF no n&uacute;cleo de suas redes, devido ao medo de perder acidentalmente tr&aacute;fego v&aacute;lido que tomou uma rota inesperada atrav&eacute;s de sua rede. O mecanismo Feasible uRPF deveria resolver estes problemas.</p>
                    <h2>Dicas de configura&ccedil;&atilde;o para Cisco</h2>
                    <p>A seguir, &eacute; descrita uma implementa&ccedil;&atilde;o poss&iacute;vel em Cisco de uma ACL para impedir o tr&aacute;fego <em>spoofeado</em>.</p>
                    <p>A Figura 15 representa o exemplo dado no guia de implementa&ccedil;&atilde;o <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a>.&nbsp;Este guia mostra a ACL que o ISP deve implementar na interface marcada com uma caixa vermelha para evitar que o cliente envie tr&aacute;fego IP <em>spoofeado</em> para a Internet.</p>
                    <img src="/images/anti-spoofing/fig15.png" alt="" />
                    <p>Fig. 15 - Topologia de exemplo para filtro de tipo <em>inbound</em> SAV baseado na ACL</p>
                    <p>Assumindo que os blocos IP dispon&iacute;veis para o cliente s&atilde;o 192.0.2.0/24 e 2001:db8:1001::/48, a ACL que o ISP configuraria para impedir receber tr&aacute;fego IP <em>spoofeado</em> seria:</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ip access-list extended customer1-in-ipv4 <br /> permit ip 192.0.2.0 0.0.0.255 any<br /> !<br /> ipv6 access-list customer1-in-ipv6<br /> permit ipv6 2001:db8:1001::/48 any <br /> !<br /> interface x<br /> ip access-group customer1-in-ipv4 in</p>
                                    <p>&nbsp;ipv6 traffic-filter customer1-in-ipv6 in</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Para implementar filtros do tipo SAV baseados no mecanismo Strict uRPF, os filtros podem ser ativados usando a seguinte configura&ccedil;&atilde;o nas interfaces de rede:</p>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ip cef</p>
                                    <p>ipv6 cef</p>
                                    <p>ip verify unicast source reachable-via rx<br /> ipv6 verify unicast source reachable-via rx</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Dicas de configura&ccedil;&atilde;o para Juniper</h2>
                    <p>A seguir, &eacute; descrita a implementa&ccedil;&atilde;o em Juniper de uma ACL para impedir o tr&aacute;fego <em>spoofeado</em>.</p>
                    <p>Usando o exemplo dado no guia de implementa&ccedil;&atilde;o <a href="https://www.manrs.org/netops/guide/antispoofing/" target="_blank" rel="noreferrer">anti-<em>spoofing</em> de MANRS</a>, mostra-se a ACL que o ISP pode implementar para evitar que o cliente envie tr&aacute;fego <em>spoofeado</em> para a Internet.</p>
                    <pre>
                <code>
                    <span>firewall {`{`}</span>
                    <span>family inet {`{`}</span>
                    <span>filter customer1-in-ipv4 {`{`}</span>
                    <span>term allowed-sources {`{`}</span>
                    <span>from {`{`}</span>
                    <span>source-address {`{`}</span>
                    <span>192.0.2.0/24;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>then accept;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>filter customer1-in-ipv6 {`{`}</span>
                    <span>term allowed-sources {`{`}</span>
                    <span>from {`{`}</span>
                    <span>source-address {`{`}</span>
                    <span>2001:db8:1001::/48;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>then accept;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>interfaces x {`{`}</span>
                    <span>unit 0 {`{`}</span>
                    <span>family inet {`{`}</span>
                    <span>filter {`{`}</span>
                    <span>input customer1-in-ipv4;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>filter {`{`}</span>
                    <span>input customer1-in-ipv6;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>Para implementar filtros do tipo SAV baseados no mecanismo Strict uRPF, estes podem ser ativados no Juniper, usando a seguinte configura&ccedil;&atilde;o nas interfaces de rede:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>Para implementar filtros do tipo SAV baseados no mecanismo uRPF modo Feasible, no <a href="https://www.juniper.net/documentation/us/en/software/junos/interfaces-ethernet-switches/static-routing/topics/ref/statement/unicast-reverse-path-edit-routing-options.html" target="_blank" rel="noreferrer">Juniper</a>, &eacute; necess&aacute;rio ativ&aacute;-lo de forma global:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check;</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <p>E ativar tamb&eacute;m na configura&ccedil;&atilde;o das interfaces de rede:</p>
                    <pre>
                <code>
                    <span>family inet {`{`}</span>
                    <span>rpf-check; {`{`}</span>
                    <span>mode loose;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                    <span>family inet6 {`{`}</span>
                    <span>rpf-check; {`{`}</span>
                    <span>mode loose;</span>
                    <span>{`}`}</span>
                    <span>{`}`}</span>
                </code>
            </pre>
                    <h2>Dicas de configura&ccedil;&atilde;o para MikroTik</h2>
                    <p>No MikroTik, a forma de configurar uma ACL para impedir que o tr&aacute;fego <em>spoofeado</em> v&aacute; para a Internet &eacute; definindo uma lista de endere&ccedil;os IP permitidos para a organiza&ccedil;&atilde;o. Esta lista deve considerar todos os IP e/ou blocos de endere&ccedil;os que poderiam ser a origem dos pacotes que v&atilde;o para a Internet.</p>
                    <p>Criar uma <a href="https://help.mikrotik.com/docs/display/ROS/Filter" target="_blank" rel="noreferrer">regra de <em>firewall</em></a>&nbsp;que aceite o tr&aacute;fego que atenda &agrave;s seguintes condi&ccedil;&otilde;es:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li><em> Address List</em>: <a href="https://help.mikrotik.com/docs/display/ROS/Address-lists" target="_blank" rel="noreferrer">A lista de endere&ccedil;os permitidos</a></li>
                        <li><em> Interface</em>: Interface de WAN que se conecta &agrave; Internet</li>
                    </ul>
                    <p>Criar uma regra de firewall que rejeite o que atender &agrave;s seguintes condi&ccedil;&otilde;es:</p>
                    <ul>
                        <li><em>Chain: forward</em></li>
                        <li>Interface: Interface de WAN que se conecta &agrave; Internet.</li>
                    </ul>
                    <p>Para implementar filtros do tipo SAV baseados no mecanismo Strict uRPF, podem ser ativados no <a href="https://help.mikrotik.com/docs/display/ROS/IP+Settings" target="_blank" rel="noreferrer">MikroTik</a> desde o menu IP &rarr; Settings &rarr; RP Filter = strict&nbsp;</p>
                    <p>Nota: O mecanismo Feasible URPF n&atilde;o &eacute; suportado na vers&atilde;o est&aacute;vel mais recente no momento de redigir este relat&oacute;rio: RouterOS 7.5.</p>

                </>}
            </div>
        </div>
    )
}

export default Analisis
