import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function ResultadosPreliminares() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                {myLang === 'es' && <>
                    <h2>Resultados preliminares</h2>
                    <h3>Comparaci&oacute;n de tama&ntilde;os de tablas BGP</h3>
                    <p>Es posible observar que los IXP con m&aacute;s a&ntilde;os de operaci&oacute;n como los de CABASE (AR) y PIT Chile tienen tablas m&aacute;s grandes (m&aacute;s de 20.000 y 15.000 prefijos respectivamente). En tanto, los IXP creados recientemente, como IXSY (en Yucat&aacute;n, M&eacute;xico) a&uacute;n tienen pocos miembros y por lo tanto sus tablas son m&aacute;s peque&ntilde;as (llega apenas a los 100 prefijos).</p>

                    <h3>Cobertura y zona de influencia del IXP</h3>
                    <p>Los IXP con mayor cobertura se encuentran en Argentina (alcanzan aproximadamente un 75% de los operadores de su pa&iacute;s) y Chile (ronda el 65%). Los IXP de Costa Rica, Guatemala y Paraguay tienen un alcance medio de entre 25% y 35% de los operadores locales alcanzados. Cabe destacar al IXP costarricense (CRIX) el cual tiene una buena cobertura de los prefijos: 71,42% en IPv4 y 45% en IPv6. El IXP ecuatoriano se queda un poco por detr&aacute;s (12%) y luego quedan los IXP mexicanos con menos del 3% de los operadores locales alcanzados.</p>

                    <h3>Caracter&iacute;sticas de los anuncios BGP hacia los IXP</h3>
                    <p>Al calcular la cobertura de prefijos locales alcanzados por cada IXP a comparaci&oacute;n con las tablas globales, las estimaciones a nivel de conjunto con los prefijos tal cual se anuncian; esto puede no reflejar correctamente la cobertura cuando los prefijos se anuncian agregados o desagregados. Por ejemplo, en las tablas de los colectores globales podemos ver que se anuncia el prefijo 190.190.0.0/15 con el AS7303 (Telecom Argentina S.A.) como origen. Mientras que los IXP de CABASE (AEP, NQN, ROS) este anuncio figura en la tabla como dos prefijos distintos: 190.190.0.0/16 y 190.191.0.0/16. Entonces, este prefijo no forma parte de los presentes en ambas tablas. Por lo tanto, a la hora de considerar si el IXP tiene una buena cobertura o no, es m&aacute;s representativo considerar a nivel de AS de origen alcanzados.</p>

                    <h3>IPv6 vs IPv4</h3>
                    <p>En sinton&iacute;a con los resultados del reporte 2020, tambi&eacute;n se puede ver como en los IXP de la regi&oacute;n los prefijos IPv6 a&uacute;n ocupan una peque&ntilde;a fracci&oacute;n de las tablas de ruteo. En CABASE Buenos Aires esos anuncios alcanzan 17% en relaci&oacute;n al protocolo IPv4. Se trata de uno de los valores m&aacute;s bajos aunque puede deberse a su tama&ntilde;o. PIT Chile tambi&eacute;n tiene un porcentaje bajo (20% de prefijos IPv6 respecto a los de IPv4). En los IXP m&aacute;s peque&ntilde;os, estos n&uacute;meros mejoran pero no considerablemente. CRIX (Costa Rica) cuenta con 24% de prefijos IPv6 con respecto a los IPv4 y en el IXP de Paraguay este n&uacute;mero es similar (22%). El IXP de Yucat&aacute;n tiene la relaci&oacute;n m&aacute;s alta con 39%, aunque tambi&eacute;n es cierto que es el punto de intercambio m&aacute;s peque&ntilde;o.</p>
                    <p>Para realizar estas comparaciones, vale la pena destacar que muchos IXP no tienen levantado el protocolo IPv6 en su conexi&oacute;n con PCH, lo que implica que no se cuenta con la informaci&oacute;n de sus tablas de ruteo para este protocolo. Esto reduce la capacidad de an&aacute;lisis ya que disminuye el universo de IXP a comparar.</p>

                    <h3>AS Prepending</h3>
                    <p>BGP es un protocolo que mantiene rutas din&aacute;micas y en constante cambio, con el objetivo de contar con tablas de reenv&iacute;o que representen el "mejor" camino hacia cada direcci&oacute;n de destino. Y con "mejor" nos referimos a un atributo en particular, que en ausencia de otros par&aacute;metros, utiliza la estrategia de seleccionar la ruta que contenga una menor cantidad de sistemas aut&oacute;nomos intermedios para llegar a destino. Es decir que BGP no selecciona el camino m&aacute;s r&aacute;pido, el que tenga mayor capacidad o estabilidad en ese momento, o el menos costoso. Entonces, los operadores de red pueden manipular haciendo las rutas "m&aacute;s largas" para los caminos que menos prefieran; repitiendo m&uacute;ltiples veces el mismo sistema aut&oacute;nomo en la ruta anunciada. A esta pr&aacute;ctica se la conoce como AS <a href="https://blog.apnic.net/2019/10/25/as-prepending-in-bgp" target="blank" rel="noreferrer">prepending</a>.</p>
                    <p>Las rutas de los IXP no deber&iacute;an tener un n&uacute;mero considerable de prependings, ya que sus rutas deber&iacute;an ser las preferibles. En este estudio podemos observar que en general los grandes IXP como los de CABASE en Argentina o el PIT Chile, presentan prependings en sus rutas (repitiendo entre 2 o 4 veces el ASN en la mayor&iacute;a de los casos). Mientras que es poco com&uacute;n ver este fen&oacute;meno en los IXP m&aacute;s peque&ntilde;os como el de Ecuador o Yucat&aacute;n (M&eacute;xico).</p>

                </>}
                {myLang === 'en' && <>
                    <h2>Preliminary Findings</h2>
                    <h3>Comparison of BGP Table Sizes</h3>
                    <p>The data shows that those IXPs that have been in operation the longest, such as CABASE (Argentina) and PIT Chile, have the largest BGP tables (more than 20,000 and 15,000 prefixes, respectively). Likewise, recently created IXPs such as IXSY (Yucatan, Mexico) still have a limited number of members and their BGP tables are consequently smaller (barely 100 prefixes).</p>

                    <h3>IXP Coverage and Area of Influence</h3>
                    <p>The IXPs with the largest coverage are located in Argentina and Chile. Approximately 75% and 65% of the country's operators, respectively, are connected to these IXPs. The IXPs of Costa Rica, Guatemala, and Paraguay reach an average of 25% to 35% of local operators. Costa Rican IXP CRIX is worth noting for the large number of prefixes it covers: 71,42% of all IPv4 and 45% of all IPv6 prefixes. The Ecuadorean IXP is somewhat lagging (12%), while Mexican IXPs reach less than 3% of local operators.</p>

                    <h3>BGP Announcements to IXPs</h3>
                    <p>The calculated number of local prefixes reached by each IXP may not be correct where such prefixes are announced with higher or lower levels of aggregation than those of global tables.</p>
                    <p>For example, a global collector announces prefix 190.190.0.0/15 with AS7303 (Telecom Argentina S.A.) as its origin.</p>
                    <p>However, the CABASE IXPs (AEP, NQN, ROS) announce this as two different prefixes (190.190.0.0/16 and 190.191.0.0/16), with the result that this prefix does not appear in both tables. Therefore, when considering whether an IXP has good coverage, it is more representative to consider the level of origin ASs it reaches.</p>


                    <h3>IPv6 vs IPv4</h3>
                    <p>In line with the results of the 2020 report, this study shows that IPv6 prefixes still occupy a small portion of the regional IXPs’ routing tables. In CABASE Buenos Aires, IPv6 prefix announcements represent just 17% of IPv4 prefix announcements. This is one of the lowest figures, although it should be noted that this may be due to the size of the IXP. PIT Chile also reaches a smaller proportion of IPv6 prefixes.</p>
                    <p>The numbers are slightly better for smaller IXPs. At CRIX (Costa Rica), IPv6 prefixes represent 24% of IPv4 prefixes and the number is similar for the Paraguayan IXP (22%). The IXP in Yucatán has the highest IPv6 to IPv4 ratio (30%), although it must be said that it is the smallest Internet exchange point.</p>
                    <p>Here it is worth noting that many IXPs have not deployed IPv6 in their connections with PCH. In such cases, there is no routing table information available for the IPv6 protocol. This limits the analysis, as it reduces the universe of IXPs used for comparison purposes.</p>

                    <h3>AS Prepending</h3>
                    <p>The BGP protocol maintains dynamic, constantly changing routes. The purpose is to have forwarding tables that represent the best path to each destination address. And when we say ‘the best’ we are referring to a specific attribute which, in the absence of other parameters, selects the route that will allow reaching the destination passing through the least number of intermediate autonomous systems. </p>
                    <p>In other words, BGP does not select the fastest path, nor the path with the greatest capacity or stability at a given moment, nor the least expensive path. As a result, network operators can manipulate the system by creating longer routes for their least preferred paths, repeating the same autonomous system multiple times in the announced route. This practice is known as AS <a href="https://blog.apnic.net/2019/10/25/as-prepending-in-bgp/" target="_blank" rel="noreferrer">prepending</a>.</p>
                    <p>An IXP’s routes should not contain many prependings, as they should be the preferred path. This study shows that larger IXPs, such as those of CABASE in Argentina or PIT Chile, generally include prependings in their routes (in most cases, with ASNs repeated two to four times). On the other hand, it is rare to see this in smaller IXPs, such as those in Ecuador or Yucatan (Mexico).</p>

                </>}
                {myLang === 'pt' && <>
                    <h2>Resultados preliminares</h2>
                    <h3>Compara&ccedil;&atilde;o de tamanhos de tabelas BGP</h3>
                    <p>&eacute; poss&iacute;vel observar que os IXP com mais anos de opera&ccedil;&atilde;o, como os da CABASE (AR) e PIT Chile, possuem tabelas maiores (mais de 20.000 e 15.000 prefixos, respectivamente). Enquanto isso, os IXP criados recentemente, como o IXSY (em Yucatan, M&eacute;xico) ainda t&ecirc;m poucos membros e, portanto, suas tabelas s&atilde;o menores (mal chega aos 100 prefixos).</p>

                    <h3>Cobertura e &aacute;rea de influ&ecirc;ncia do IXP</h3>
                    <p>Os IXP com maior cobertura est&atilde;o na Argentina (atingindo cerca de 75% das operadoras do seu pa&iacute;s) e no Chile (cerca de 65%). Os IXP da Costa Rica, Guatemala e Paraguai t&ecirc;m um escopo m&eacute;dio entre 25% e 35% das operadoras locais alcan&ccedil;adas. Vale destacar o IXP costarriquenho (CRIX), que possui uma boa cobertura dos prefixos: 71.42% no IPv4 e 45% no IPv6. O IXP equatoriano fica um pouco atr&aacute;s (12%), seguido pelos IXP mexicanos, com menos de 3% das operadoras locais alcan&ccedil;adas.</p>

                    <h3>Caracter&iacute;sticas dos an&uacute;ncios BGP para os IXP</h3>
                    <p>O c&aacute;lculo da cobertura dos prefixos locais alcan&ccedil;ados por cada IXP pode n&atilde;o ser refletido corretamente quando os prefixos s&atilde;o anunciados mais agregados ou desagregados do que nas tabelas globais.</p>
                    <p>Por exemplo, em um coletor global podemos ver que o prefixo 190.190.0.0/15 &eacute; anunciado com o AS7303 (Telecom Argentina S.A.) como origem.</p>
                    <p>Enquanto isso, nos IXP da CABASE (AEP, NQN, ROS) este an&uacute;ncio aparece na tabela como dois prefixos diferentes: 190.190.0.0/16 e 190.191.0.0/16. Portanto, esse prefixo n&atilde;o faz parte dos presentes nas duas tabelas. Portanto, ao considerar se o IXP tem boa cobertura, &eacute; mais representativo considerar no n&iacute;vel de AS de origem alcan&ccedil;ado.</p>


                    <h3>IPv6 vs IPv4</h3>
                    <p>Em linha com os resultados do relat&oacute;rio 2020, tamb&eacute;m pode se conferir como nos IXP da regi&atilde;o, os prefixos IPv6 ainda ocupam uma pequena fra&ccedil;&atilde;o das tabelas de roteamento. Na CABASE Buenos Aires esses an&uacute;ncios chegam a 17% em rela&ccedil;&atilde;o ao protocolo IPv4. Trata-se de um dos valores mais baixos, embora possa ser devido ao seu tamanho. O PIT Chile tamb&eacute;m tem um percentual baixo (20% dos prefixos IPv6 em rela&ccedil;&atilde;o ao IPv4).</p>
                    <p>Nos IXP menores, esses n&uacute;meros melhoram, mas n&atilde;o muito. O CRIX (Costa Rica) tem 24% de prefixos IPv6 em rela&ccedil;&atilde;o aos IPv4 e no IXP do Paraguai esse n&uacute;mero &eacute; semelhante (22%). O IXP de Yucat&aacute;n tem o &iacute;ndice mais alto com 39%, embora tamb&eacute;m seja verdade que &eacute; o ponto de troca menor.</p>
                    <p>Para fazer essas compara&ccedil;&otilde;es, vale ressaltar que muitos IXP n&atilde;o possuem o protocolo IPv6 instalado e funcionando em sua conex&atilde;o com o PCH, o que significa que as informa&ccedil;&otilde;es de suas tabelas de roteamento para este protocolo n&atilde;o est&atilde;o dispon&iacute;veis. Isso reduz a capacidade de an&aacute;lise, pois reduz o universo de IXP a serem comparados.</p>

                    <h3>AS Prepending</h3>
                    <p>O BGP &eacute; um protocolo que mant&eacute;m rotas din&acirc;micas e em constante mudan&ccedil;a, com o objetivo de ter tabelas de reenvio que representem o melhor caminho para cada endere&ccedil;o de destino. E com melhor nos referimos a um atributo particular que, na aus&ecirc;ncia de outros par&acirc;metros, usa a estrat&eacute;gia de selecionar a rota que contenha o menor n&uacute;mero de sistemas aut&ocirc;nomos intermedi&aacute;rios para chegar ao destino.</p>
                    <p>Quer dizer, o BGP n&atilde;o seleciona o caminho mais r&aacute;pido, o que tenha maior capacidade ou estabilidade nesse momento, ou o menos caro. Ent&atilde;o, as operadoras de rede podem manipular tornando as rotas mais longas para os caminhos que menos preferem, repetindo o mesmo sistema aut&ocirc;nomo v&aacute;rias vezes na rota anunciada. Essa pr&aacute;tica &eacute; conhecida como <a href="https://blog.apnic.net/2019/10/25/as-prepending-in-bgp/" target="_blank" rel="noreferrer">AS prepending</a>.</p>
                    <p>As rotas dos IXP n&atilde;o deveriam ter um n&uacute;mero significativo de prependings, pois deveriam ser as prefer&iacute;veis. Neste estudo podemos observar que, em geral, os grandes IXP, como os da CABASE na Argentina ou o PIT Chile, apresentam prependings em suas rotas (repetindo o ASN entre duas e quatro vezes na maioria dos casos). Enquanto isso, &eacute; raro ver esse fen&ocirc;meno nos IXP menores, como o do Equador ou Yucat&aacute;n (M&eacute;xico).</p>

                </>}
            </div>

        </div>
    )
}

export default ResultadosPreliminares
