import React, {useContext} from 'react';
import {LangContext} from '../../../context/DataContext';
import Alerts from '../../../components/Alerts'
import Button from '../../../components/Button'
import { MatchText } from 'react-ctrl-f';

export default function Resumen(props) {
    const { myLang } = useContext(LangContext)
    return (
    <div>
        <div>
            {myLang === 'es' && <> 
            <Alerts color="alert_white">
                <p  id="bgp1"><MatchText id="match-1">Área: I+D en Infraestructura de Internet</MatchText> <br />
                <MatchText id="match-2">Proyecto: Fortalecimiento de Infraestructura de Internet Regional</MatchText> <br />
                <MatchText id="match-3">Autor: Augusto Mathurín  </MatchText><br />
                <MatchText id="match-4">   Coordinación/Revisión: Guillermo Cicileo </MatchText>
                </p>
            </Alerts>
            <Button color="button_blue" to="/reportes/interconexion/lacnic-interconexion-bgp-lac-es.pdf" target="_blank">Download (PDF)</Button>
            <p><MatchText id="match-5">Para complementar la información obtenida en el estudio de Conectividad en la región de LAC 2020, se busca conocer la situación actual de las interconexiones entre los países latinoamericanos a partir de una fuente distinta de información: las tablas de ruteo BGP registradas y almacenadas por colectores globales. En este estudio se propone analizar las distintas características de la interconexión de la región, con el fin de comparar características básicas de las publicaciones de rutas de la región, obtener conclusiones sobre el comportamiento de los operadores en la publicación de prefijos, detectar los sitios donde se intercambia el tráfico con mayor cantidad de operadores, conocer los proveedores de tránsito upstream que predominan en cada país, la cantidad promedio de sistemas autónomos por los que pasa el tráfico y el impacto de esto en la estabilidad del ruteo regional, entre otros factores.</MatchText></p>
            </>}
            {/*  */}
            {myLang === 'en' && <> 
            <Alerts color="alert_white">
                <p  id="bgp1">
                <MatchText id="match-1"> Departament: Internet Infrastructure R&D </MatchText><br />
                <MatchText id="match-2"> Project: Strengthening Regional Internet Infrastructure</MatchText><br />
                <MatchText id="match-3">Author: Augusto Mathurín</MatchText><br />
                <MatchText id="match-4">Coordination/Revision: Guillermo Cicileo </MatchText>
                </p>
            </Alerts>
            <Button color="button_blue" to="/reportes/interconexion/lacnic-interconexion-bgp-lac-en.pdf" target="_blank">Download (PDF)</Button>
            <p> <MatchText id="match-5">To complement the information obtained in the Connectivity study in the LAC 2020 region, we seek to know the current situation of interconnections between Latin American countries from a different source of information: the BGP routing tables registered and stored by global collectors. . This study proposes to analyze the different characteristics of the interconnection of the region, in order to compare basic characteristics of route publications in the region, obtain conclusions about the behavior of operators in the publication of prefixes, detect the sites where traffic is exchanged with the largest number of operators, knowing the predominant upstream transit providers in each country, the average number of autonomous systems through which the traffic passes and the impact of this on the stability of regional routing, among other factors.  </MatchText></p>
            </>}
            {/*  */}
            {myLang === 'pt' && <> 
            <Alerts color="alert_white">
                <p  id="bgp1">
                <MatchText id="match-1">Área: I+D na infraestrutura da Internet</MatchText><br />
                <MatchText id="match-2">Projeto: Fortalecimento de Infraestrutura da Internet Regional</MatchText><br />
                <MatchText id="match-3">Autor: Augusto Mathurín</MatchText><br />
                <MatchText id="match-4">Coordenação/revisão: Guillermo Cicileo</MatchText> 
                </p>
            </Alerts>
            <Button color="button_blue" to="/reportes/interconexion/lacnic-interconexion-bgp-lac-pt.pdf" target="_blank">Download (PDF)</Button>
            <p><MatchText id="match-5">Para complementar as informações obtidas no estúdio de Conectividad na região de LAC 2020, em busca da situação atual das interconexões entre os países latinoamericanos a partir de uma fonte distinta de informações: las tablas de ruteo BGP e almacenadas por coletores globais . En este estudio se propone analizar las distintas caracteristicas de la interconexión de la región, con el fin de comparar características básicas de las publicaciones de rutas de la región, obtener conclusões sobre o comportamiento de los operadores em publicación de prefijos, detectar los sitios donde se intercambia el tráfico com prefeito cantidad de operadores, conocer los proveedores de tránsito upstream que predominan en cada país, la cantidad promedio de systems autónomos por los que pasa el tráfico y el impacto de esto en la estabilidad del ruteo regional, entre otros fatores.</MatchText></p>
            </>}
        </div>
    </div>
    );     
} 