import React, { useContext } from 'react';
import { LangContext, ReferenceContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Conclusiones(props) {
    const { myLang } = useContext(LangContext)
    const { scrollToReference } = useContext(ReferenceContext)
    return (
        <div>
            <div id="bgp6">
                {myLang === 'es' && <>
                    <h2>

                        <MatchText id="match-124">Conclusiones y trabajo a futuro</MatchText></h2>
                    <p>

                        <MatchText id="match-125">Al visualizar hacia dónde se conectan los sistemas autónomos de cada país cuando salen al exterior, podemos ver que, en general, menos de un tercio de estos se conecta directamente con otros sistemas autónomos de Latinoamérica y el Caribe. Podemos comprobar esto mismo al ver cómo casi todos los sistemas autónomos que mayor tránsito dan a los países de la región hacia el extranjero están registrados en ARIN o RIPE NCC. Esto implica ciertos problemas. En primer lugar, desfavorece la implementación de aplicaciones altamente interactivas en la región, ya que la latencia es mayor comparada con conexiones con Estados Unidos o Europa. Además, las aplicaciones P2P consumen mucho ancho de banda en el longhaul, en lugar de hacerlo localmente a un costo menor. Por último, desde la perspectiva geopolítica, la conectividad en nuestra región depende fuertemente de países externos.</MatchText></p>
                    <p>

                        <MatchText id="match-126">Todo esto plantea una oportunidad para generar nuevas conexiones entre pares y puntos de intercambio, además de posibles inversiones de empresas de telecomunicaciones que deseen desarrollar negocios de transporte de tráfico regional.</MatchText></p>
                    <p>

                        <MatchText id="match-127">Por otra parte, este estudio nos permitió analizar también datos sobre los prefijos IPv4 e IPv6 anunciados. La fragmentación de los prefijos IPv4, que se puede observar en los países que tienen una longitud promedio de prefijo más cercana a 24, son los que probablemente han sumado nuevos operadores en los últimos años, ya que ingresaron en las últimas fases de agotamiento IPv4. Respecto a IPv6, en este estudio se obtuvieron porcentajes de su despliegue más bajos que en otros estudios realizados,</MatchText><a href="#ref-10" onClick={() => scrollToReference('ref-10')}>(10)</a>

                        <MatchText id="match-128"> pero hay que tener en cuenta que los datos procesados en este reporte solo toman en cuenta los prefijos anunciados y registrados por colectores, y no todos los registrados. Además, los prefijos IPv4 están mucho más desagregados.</MatchText></p>
                    <p>

                        <MatchText id="match-129">Sobre la fuente de los datos utilizados, podemos destacar que es cierto que solo se usó el colector rrc00 para obtener información de ruteo, el cual está ubicado en Europa. Esto pudo haber generado cierto sesgo que haya hecho aumentar la proporción de conexiones a este continente respecto de los demás. En ese sentido, podrían generarse los datasets mediante más de un colector, teniendo en cuenta la diversidad de regiones al seleccionarlo, para comprobar que no haya un número considerable de conexiones no contempladas en este análisis.</MatchText></p>
                    <p>

                        <MatchText id="match-130">Otra fuente de datos que podría revisarse es el registro de los puntos de intercambio (IXP), en el cual actualmente se usan datos recopilados por CAIDA </MatchText><a href="#ref-11" onClick={() => scrollToReference('ref-11')}>(11)</a>

                        <MatchText id="match-130">con PCH como fuente. Como en este caso se analiza la región de Latinoamérica y el Caribe, podría reemplazarse esta fuente por datos provistos por LAC-IX, la Asociación de Puntos de Intercambio de Tráfico de América Latina y el Caribe.</MatchText></p>
                    <p>

                        <MatchText id="match-131">Para continuar con el trabajo propuesto en el futuro, tomando como referencia este estudio, se pueden calcular los mismos datos y hacer reportes similares sobre los otros 4 registros regionales de Internet (AFRINIC, APNIC, ARIN y RIPE NCC), para establecer comparaciones directas. Además, también es posible hacer cálculos retrospectivos para poder comparar la actualidad de la región con su realidad de hace 5 o 10 años.</MatchText></p>
                    <p>

                        <MatchText id="match-132">Es importante continuar con estudios similares, que evalúen la resiliencia y el desarrollo de Internet en nuestra región con base en diversas fuentes. Así como aquí utilizamos como fuente principal las tablas de ruteo provistas por los colectores de RIPE, también podrían analizarse trazas de rutas desde distintos puntos geográficos en todo el continente. Para esto, es necesario disponer de puntos y dispositivos de medición que permitan efectuar este tipo de análisis.</MatchText></p>
                </>}
                {myLang === 'en' && <>
                    <h2>

                        <MatchText id="match-124">Conclusions and Future Work.</MatchText></h2>
                    <p>

                        <MatchText id="match-125"> An analysis of where the autonomous systems of each country connect when they leave their country shows that, generally speaking, less than a third of these autonomous systems connect directly with others in Latin America and the Caribbean. This is also evident in the fact that the autonomous systems that provide the largest transit capacity from the countries of the region to the rest of the world are registered either with ARIN or with the RIPE NCC. This involves certain problems. First, it discourages the implementation of highly interactive applications in the region, as latency is higher compared to connections with the United States or Europe. In addition, P2P applications consume large amounts of bandwidth in long-haul links instead of exchanging traffic locally at a lower cost. Finally, from a geopolitical perspective, in our region, connectivity depends heavily on countries of other regions. All of the above presents an opportunity to generate new connections between peers and Internet exchange points, as well as potential investments by telecommunications companies wishing to develop regional traffic business opportunities.</MatchText></p>
                    <p>

                        <MatchText id="match-126"> In addition, this study also allowed us to analyze data on the IPv4 and IPv6 prefixes that are being announced. The fragmentation of IPv4 prefixes, which can be observed in countries with an average prefix length closer to 24 bits, is probably the result of new operators who appeared in recent years during the final phase of IPv4 exhaustion. As for IPv6, the results of this study showed lower deployment rates than those determined by other studies </MatchText><a href="#ref-10" onClick={() => scrollToReference('ref-10')}>(10)</a>

                        <MatchText id="match-127">. However, it should be noted that the data processed in this report include only the prefixes announced and registered by collectors, not all the prefixes that have been registered. In addition, IPv4 prefixes have a much higher level of disaggregation.</MatchText></p>
                    <p>

                        <MatchText id="match-128"> As for the data source used for this study, we only used the rrc00 collector to obtain routing information, and this collector is located in Europe. This may have created a certain bias, increasing the proportion of connections to this continent compared to the others. In this sense, other datasets could be generated using more than one collector and selecting them taking into account regional diversity so as to verify that the number of connections not considered in this analysis was not significant.</MatchText></p>
                    <p>

                        <MatchText id="match-129"> Another source of data that might be reviewed is the Internet exchange point (IXP) registry, which currently uses data collected by CAIDA </MatchText><a href="#ref-11" onClick={() => scrollToReference('ref-11')}>(11)</a>

                        <MatchText id="match-130"> with PCH as its source. Because this study analyzes the region of Latin America and the Caribbean, this source might be replaced by data provided by LAC-IX, the Latin American and Caribbean Association of Internet Exchange Point Operators.</MatchText></p>
                    <p>

                        <MatchText id="match-131"> As for the work we propose for the future, the same data could be calculated taking this study as a reference and similar reports could be prepared for the other four Regional Internet Registries (AFRINIC, APNIC, ARIN and RIPE NCC). This would allow directly comparing the different regions. It would also be possible to perform calculations backwards in time and compare our current data with the status of our region five or ten years ago.</MatchText></p>
                    <p>

                        <MatchText id="match-132"> It is important to continue to conduct similar studies in order to assess Internet resilience and development in our region based on different sources of information. Just as this study used as its main source of data the routing tables provided by the RIPE NCC route collectors, traceroutes from locations across the continent might also be analyzed. This would require measurement devices and vantage points that allow performing this type of analyses.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2>

                        <MatchText id="match-124">Conclusões e trabalho futuro </MatchText></h2>
                    <p>

                        <MatchText id="match-125">Ao visualizar onde os sistemas autônomos de cada país se conectam quando vão para o exterior, podemos ver que, em geral, menos de um terço deles se conectam diretamente com outros sistemas autônomos da América Latina e o Caribe.</MatchText></p>
                    <p>

                        <MatchText id="match-126">Podemos comprovar isso, ao ver como quase todos os sistemas autônomos que maior tráfego dão aos países da região para o estrangeiro estão registrados no ARIN ou RIPE NCC. Isso envolve certos problemas.</MatchText></p>
                    <p>

                        <MatchText id="match-127">Em primeiro lugar, desestimula a implementação de aplicativos altamente interativos na região, uma vez que a latência é maior em relação às conexões com os Estados Unidos ou a Europa.</MatchText></p>
                    <p>

                        <MatchText id="match-128">Além disso, os aplicativos P2P consomem muita largura de banda no "long-haul", em vez de fazê-lo localmente a um custo menor.</MatchText></p>
                    <p>

                        <MatchText id="match-129">Finalmente, do ponto de vista geopolítico, a conectividade em nossa região depende fortemente de países externos.</MatchText></p>
                    <p>

                        <MatchText id="match-130">Tudo isso representa uma oportunidade de gerar novas conexões entre pares e pontos de troca, além de possíveis investimentos por parte das empresas de telecomunicações que queiram desenvolver negócios de transporte de tráfego regional.</MatchText></p>
                    <p>

                        <MatchText id="match-131">Do outro lado, este estudo também permitiu analisar dados sobre os prefixos IPv4 e IPv6 anunciados. A fragmentação dos prefixos IPv4, que pode ser observada nos países que têm um comprimento médio de prefixo próximo a 24, são os que provavelmente adicionaram novos operadores nos últimos anos, ao entrarem na última fase de esgotamento IPv4.</MatchText></p>
                    <p>

                        <MatchText id="match-132">Em relação ao IPv6, neste estudo foram obtidos percentuais de implementação inferiores aos de outros estudos realizados </MatchText><a href="#ref-10" onClick={() => scrollToReference('ref-10')}>(10)</a>

                        <MatchText id="match-133">, mas deve-se levar em conta que os dados processados neste relatório são apenas os prefixos anunciados e registrados por coletores, e não todos os registrados.</MatchText></p>
                    <p>

                        <MatchText id="match-134">Além disso, os prefixos IPv4 estão muito mais desagregados. Em relação à origem dos dados usados, podemos destacar que é verdade que apenas foi usado o coletor rrc00 para obter informações de roteamento, que está localizado na Europa.</MatchText></p>
                    <p>

                        <MatchText id="match-135">Isso pode ter gerado um certo viés que faça aumentar a proporção de conexões para este continente em relação aos outros.</MatchText></p>
                    <p>

                        <MatchText id="match-136">Nesse sentido, os conjuntos de dados poderiam ser gerados usando mais de um coletor, levando em consideração a diversidade de regiões ao selecioná-lo, para verificar que não haja um número considerável de conexões não contempladas nesta análise.</MatchText></p>
                    <p>

                        <MatchText id="match-137">Outra fonte de dados que pode ser revisada é o cadastro de pontos de troca (IXPs), em que atualmente são utilizados os dados coletados por CAIDA </MatchText><a href="#ref-11" onClick={() => scrollToReference('ref-11')}>(11)</a>

                        <MatchText id="match-138"> com PCH como fonte.</MatchText></p>
                    <p>

                        <MatchText id="match-139">Como neste caso é analisada a região da América Latina e o Caribe, essa fonte poderia ser substituída por dados fornecidos por LAC-IX, a Associação de Pontos de Troca de Tráfego da América Latina e o Caribe.</MatchText></p>
                    <p>

                        <MatchText id="match-140">Continuando com o trabalho proposto para o futuro, tomando este estudo como referência, os mesmos dados podem ser calculados e relatórios semelhantes feitos sobre os outros 4 Registros Regionais da Internet (AFRINIC, APNIC, ARIN e RIPE NCC), a fim de estabelecer comparações diretas.</MatchText></p>
                    <p>

                        <MatchText id="match-141">Além disso, também é possível fazer cálculos "para trás" no tempo, para sermos capazes de nos comparar a nós mesmos como região há apenas 5 ou 10 anos.</MatchText></p>
                    <p>

                        <MatchText id="match-142">É importante continuar fazendo estudos semelhantes que avaliem a resiliência e o desenvolvimento da Internet na nossa região com base em várias fontes.</MatchText></p>
                    <p>

                        <MatchText id="match-143">Assim como aqui usamos as tabelas de roteamento fornecidas pelos coletores do RIPE como fonte principal, também poderiam ser analisados traços de rotas de diferentes pontos geográficos em todo o continente.</MatchText></p>
                    <p>

                        <MatchText id="match-144">Para isso, é necessário ter pontos e dispositivos de medição que permitam fazer esse tipo de análise</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Conclusiones;