import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext";  
import RootServer from '../../Interconexion/RootServer'
import Introduccion from "./Introduccion";
import Resultados from "./Resultados";
import Resumen from './Resumen'
import Metodologia from './Metodologia'
import RootServersSuImportancia from './RootServersSuImportancia'
import TiemposDeRespuestaNacionales from "./TiemposDeRespuestaNacionales";
import TiemposDeRespuestaPromedio from "./TiemposDeRespuestaPromedio";
import Conclusiones from "./Conclusiones";
import SobreElEstudio from "./SobreElEstudio";
import Anexos from "./Anexos"; 

function UsoRootServerLAC() {
    return (
        <>
          <ReportTitle />
          <Resumen />
          <Introduccion />
          <RootServersSuImportancia />
          <Metodologia />
          <Resultados />
          <TiemposDeRespuestaNacionales />
          <TiemposDeRespuestaPromedio />
          <Conclusiones />
          <SobreElEstudio />
          <Anexos /> 
          
        </>
    )
}

function ReportTitle(props){
    const { myLang } = useContext(LangContext)
    return(<>
    { myLang === 'es' && <h1>Uso de root servers del DNS en la región</h1> }
    { myLang === 'en' && <h1>Use of DNS Root Servers in Latin America</h1> }
    { myLang === 'pt' && <RootServer /> }
    </>)
}

export default UsoRootServerLAC
