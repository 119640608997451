import React, { useContext } from 'react';
import { Link} from 'react-router-dom'
import { LangContext } from '../../context/DataContext';

export default function AtaquesDDoS(props) {  
    const { myLang } = useContext(LangContext);
    return (
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Estudio de recursos de Internet presentes en la región de LACNIC con protocolos UDP que posibilitan ataques de tipo DDoS</h1>
                    <p>Autores: Paula Venosa, Nicolas Macia, Guillermo Pereyra</p>
                    <p>Este estudio logró identificar y mitigar vulnerabilidades sobre protocolos utilizados en la región para realizar ataques de denegación de servicios, con lo que logróo una mejora en el nivel de seguridad de Internet.</p>
                    <Link className="buttons button_blue" to="/es/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos">Leer Reporte</Link>
                </>}
                {myLang === 'en' && <>  
                    <h1>Study of Internet Resources in the LACNIC Service Region that Use UDP and Enable DDoS Attacks</h1>
                    <p>Authors: Paula Venosa, Nicolas Macia, Guillermo Pereyra</p>
                    <p>This study managed to identify and mitigate vulnerabilities in the protocols used in the LACNIC service region to conduct denial of service attacks, consequently improving the level of Internet security.</p>
                    <Link className="buttons button_blue" to="/en/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos">Read Report</Link> 
                </>}
                {myLang === 'pt' && <> 
                    <h1>Estudo dos recursos da Internet presentes na região do LACNIC com protocolos UDP que possibilitam ataques do tipo DDoS</h1>
                    <p>Autor: Paula Venosa, Nicolas Macia, Guillermo Pereyra</p>
                    <p>Este estudo conseguiu identificar e mitigar vulnerabilidades em protocolos usados na região para realizar ataques de denegação de serviços, conseguindo uma melhoria no nível de segurança da Internet.</p>
                    <Link className="buttons button_blue" to="/pt/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos">Leia o Relatório</Link>  
                </>}
            </div>
        </div>
    )
} 