import React, {  useContext, useState, useEffect } from 'react';   
import { Link  } from 'react-router-dom'
import { LangContext, MobileContext, MenuContext } from '../context/DataContext';
import Modal from './Modal';
import MenuPrincipal from './MenuPrincipal'
import '../css/header.css'



function Header( props ){ 
    const {url_es, url_en, url_pt} = props; 
    const {handleLanguaje, myLang} =useContext(LangContext)
    const [showModal, setShowModal] = useState(null);
    
    const openLang = () => {
        setShowModal(!showModal)
    }
    useEffect(() => {
        document.body.classList.toggle('modal-open', showModal);
    },[showModal])

    const  handleChangeLanguaje = (value) => {
        handleLanguaje(value);
        setShowModal(!showModal); 
    }

    const [ tooltipShow, setTooltipShow] = useState(null);

    /* header agregar class al desplazarme  */
    
    const { isMobile } = useContext(MobileContext) 
    const { isMenu, openMenu } = useContext(MenuContext) 
     
    return(   
        <> 
            <header>
                <div className="main_wrapper"> 
                    {/* Bars component */} 
                    {isMobile && 
                        <button className="bars" onClick={() => openMenu() } onMouseLeave={() => setTooltipShow(false)} onMouseEnter={() => setTooltipShow('menu')}>
                            <div>
                                <span className="bar_st"></span>
                                <span className="bar_nd"></span>
                                <span className="bar_th"></span>
                            </div>
                            { tooltipShow === 'menu' &&<span className={`tooltip`}>
                                {myLang === 'es' && 'Menú'}
                                {myLang === 'en' && 'Menu'}
                                {myLang === 'pt' && 'Menu'}
                            </span>    }
                        </button>
                    }
                    {/* Logo component */} 
                    {myLang === 'es' && <Link className="logo_link"  to= '/es'><img src="/lacnic.svg" alt="lacnic" className="logo" /></Link>}
                    {myLang === 'en' && <Link className="logo_link"  to= '/en'><img src="/lacnic.svg" alt="lacnic" className="logo" /></Link>}
                    {myLang === 'pt' && <Link className="logo_link"  to= '/pt'><img src="/lacnic.svg" alt="lacnic" className="logo" /></Link>}    
                    {/* Menu component  */}
                    {!isMobile && <MenuPrincipal />}
                    {/* Lang component */}
                    <nav className="lang"> 
                        <ul>   
                            <li> 
                                <button className="button_lang" onClick={openLang} onMouseLeave={() => setTooltipShow(false)} onMouseEnter={() => setTooltipShow('lang')}>
                                    <i className="fas fa-globe-americas" aria-hidden="true"></i>
                                    <span className="my_lang">
                                        { myLang === 'es' && <> Es</> }
                                        { myLang === 'en' && <> En</> }
                                        { myLang === 'pt' && <> Pt</> }
                                    </span>
                                    <span className={`tooltip ${ tooltipShow === 'lang'  && 'active'}`}>
                                        {myLang === 'es' && 'Lenguaje'}
                                        {myLang === 'en' && 'Language'}
                                        {myLang === 'pt' && 'Língua'}
                                    </span>
                                </button>  
                            </li> 
                        </ul> 
                    </nav>
                     
                </div>
            </header>
            {/* Lang modal component */}
            <Modal showModal={showModal} setShowModa={setShowModal} onClick={()=> openLang()}>
                <nav className="nav_menu_lang">
                    <ul>
                        <li>
                            <div>
                                <p>
                                    {myLang === 'es' && 'Lenguaje' }{ myLang === 'en' && 'Language' }{ myLang === 'pt' && 'Língua'}
                                </p>
                                <span onClick={openLang}><i className="fas fa-times"></i></span>
                            </div>
                        </li>
                        <li><Link onClick={ () => handleChangeLanguaje('es')} to={`${url_es}`}>Español</Link></li>
                        <li><Link onClick={ () => handleChangeLanguaje('en')} to={`${url_en}`}>Inglés</Link></li>
                        <li><Link onClick={ () => handleChangeLanguaje('pt')} to={`${url_pt}`}>Portugués</Link></li>
                    </ul>
                </nav>
            </Modal>
            {isMenu && 
                <div className={`modal_menu ${!isMobile && `modal_menu_hide`} `}>
                    <div className="close_menu" onClick={openMenu}>
                        <div>
                            <span></span> 
                            <span></span>
                        </div>
                    </div>
                    <div className="items_menu">  
                        <h2>
                            {myLang === 'es' && 'Informes técnicos'}
                            {myLang === 'en' &&  'Tecnhical Reports'}
                            {myLang === 'pt' &&  'Relatórios técnicos'} 
                        </h2>
                        <MenuPrincipal />
                    </div>
                </div> 
            }
        </>
    )
}

export default Header;



 

