import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';


function Comparando2017(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p8">
                {myLang === 'es' && <>

                    <h2>
                        <MatchText id="match-78">Comparando con 2017</MatchText></h2>
                    <p>
                        <MatchText id="match-79">El mismo estudio se llev&oacute; a cabo durante 2017, del cual se obtuvieron 4 clusters.</MatchText></p>
                    <p>
                        <MatchText id="match-80">En las siguientes dos im&aacute;genes podemos comparar las curvas de latencia en una CDF, similar a lo que vimos anteriormente. En este caso se comparan los clusters de 2017 y 2020, y las latencias corresponden a mediciones entre los pa&iacute;ses de un mismo cluster.</MatchText></p>
                    <p>
                        <MatchText id="match-81">Una primera observaci&oacute;n que podemos hacer es que en 2017 tenemos 4 clusters mientras que en 2020 tenemos 2. Analizando el caso de 2017, ve&iacute;amos que hay un grupo de pa&iacute;ses en el cluster 3 que tiene baja latencia, menos de 74 ms en el 30% de los casos. Luego las latencias se incrementan, pero el 63% de las mediciones de este grupo est&aacute;n por debajo de 125 ms. En este grupo se encuentran pa&iacute;ses como Chile, Argentina, Uruguay, Brasil y Paraguay (ver en la figura cluster 3).</MatchText></p>
                    <img alt="Ver" src="/images/conectividad-lac-2020/r17.png" />
                    <p>
                        <MatchText id="match-82">Hay un segundo grupo de pa&iacute;ses que tambi&eacute;n tiene un 73% de las mediciones por debajo de los 125 ms, e incluso en muchos casos se comporta mejor que el cluster 3. En este grupo se encuentran pa&iacute;ses como Honduras, Guatemala, Venezuela y El Salvador (ver cluster 1). En este caso, al igual que el anterior, hay un 27% de las mediciones que superan los 125 ms y que se alejan de ese valor, llegando incluso a los 300 ms.</MatchText></p>
                    <p>
                        <MatchText id="match-83">Un tercer grupo de pa&iacute;ses compuesto por M&eacute;xico, Rep&uacute;blica Dominicana, Cuba, Belice y Trinidad y Tobago, tiene un comportamiento m&aacute;s uniforme: si bien por debajo de los 100 ms las latencias son levemente superiores a los clusters 1 y 3, por encima de ese valor la curva se comporta de manera similar al cluster 3, pero en este caso podemos observar que m&aacute;s de un 90% de las mediciones est&aacute;n por debajo de los 175 ms (ver cluster 0).</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r18.png" /></figure>
                    <p>
                        <MatchText id="match-84">Por &uacute;ltimo, hay un cuarto grupo de pa&iacute;ses que est&aacute; agrupado en el cluster 2, en el que se encuentran Colombia, Panam&aacute;, Costa Rica, Nicaragua y Bolivia. En este caso, las latencias est&aacute;n por encima de lo visto en los otros clusters, superando los 100 ms en el 70% de los casos.</MatchText></p>
                    <p>
                        <MatchText id="match-85">Con respecto a 2020, lo primero que salta a la vista es una mejora de los tiempos de latencia en el grupo de pa&iacute;ses del cluster 1. Aquellos que estaban en el cluster 3 en el gr&aacute;fico de 2017, ahora los podemos ver en el cluster 1: Chile, Argentina, Brasil, Paraguay. En este cluster aparecen m&aacute;s pa&iacute;ses que antes, fundamentalmente del Caribe, como Jamaica, Bahamas, Islas V&iacute;rgenes, entre otros.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r19.png" /></figure>
                    <p>
                        <MatchText id="match-86">Es importante notar dos cosas: la definici&oacute;n de los pa&iacute;ses que componen el cluster cambia de 2017 a 2020 (se suman pa&iacute;ses del Caribe), y los datos tambi&eacute;n. Por ende, podemos analizar la evoluci&oacute;n en la latencia seg&uacute;n dos criterios:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-87">Analizando el cluster 3 de 2017, comparando los datos de 2017 con los de 2020.</MatchText></li>
                        <li>
                            <MatchText id="match-88">Analizando el cluster 1 de 2020, comparando los datos de 2017 con los de 2020.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-89">Para hacer eso agrupamos los datos bajo diferentes curvas en la CDF anterior.</MatchText></p>
                    <p>
                        <MatchText id="match-90">Podemos ver que en ambos casos hay mejoras en la conectividad del cluster:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-91">Para la definici&oacute;n de 2017 (l&iacute;nea azul vs azul punteada) vemos una clara mejora en las latencias debajo del percentil 75: el percentil 40 se desplaza de 112 ms a 63 ms, representando una mejora de 49 ms.</MatchText></li>
                        <li>
                            <MatchText id="match-92">Para la definici&oacute;n de 2020 (l&iacute;nea roja vs roja punteada) vemos que las curvas tienen un comportamiento muy similar, y que se desplaza levemente hacia la izquierda. Por ejemplo el percentil 50 se desplaza de 138 ms a 103 ms, representando una mejora de 35 ms.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-93">Esto muestra una gran mejora en la interconexi&oacute;n entre ese grupo de pa&iacute;ses, probablemente debido a mayores relaciones de peering y tr&aacute;nsito entre operadores. No obstante, al igual que en 2017, el 20% de latencias m&aacute;s lentas est&aacute; por arriba de los 200 ms y se aleja de ese valor y llega a los 300 ms o m&aacute;s. Esto puede deberse a mediciones desde sistemas aut&oacute;nomos que se encuentran poco conectados a nivel regional y que terminan intercambiando tr&aacute;fico en EEUU o en Europa.</MatchText></p>
                    <p>
                        <MatchText id="match-94">De la misma forma, repetimos la comparaci&oacute;n para el cluster 0 (definici&oacute;n 2020, datos 2020 versus datos 2017). De esta comparaci&oacute;n no se puede decir que haya habido mejoras en los &uacute;ltimos a&ntilde;os. Hay que tener en consideraci&oacute;n que el cluster 0 no es un cluster en s&iacute; mismo, ya que agrupa todos aquellos pa&iacute;ses que no han sido agrupados en un cluster definido. Es altamente probable que a medida que se obtengan m&aacute;s mediciones aparezcan otros clusters con mejor latencia interna.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r20.png" /> </figure>

                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-78">Comparison with 2017</MatchText></h2>


                    <p>
                        <MatchText id="match-79">The same study was conducted during 2017. As a result, dour clusters were obtained.</MatchText></p>
                    <p>
                        <MatchText id="match-80">The following two figures allow us to compare the latency curves in a CDF, just as we did earlier. In this case, the clusters of 2017 and 2020 are compared. Latencies are for measurements between countries of the same cluster.</MatchText></p>


                    <p>
                        <MatchText id="match-81">A first observation shows that, while in 2020 there were four clusters, in 2017 there were only two. An analysis of the data for 2017 shows that there was a group of countries in cluster 3 with low latency, less than 74 ms in 30% of the cases. Latencies then increased, but 63% of the measurements in this group were below 125 ms. This group included countries such as Chile, Argentina, Uruguay, Brazil and Paraguay (see cluster 3 in the graph).</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r17.png" /></figure>
                    <p>
                        <MatchText id="match-82">There was a second group of countries where 73% of the measurements were also below 125 ms and in many cases it performed better than cluster 3. This group included countries such as Honduras, Guatemala, Venezuela and El Salvador (see cluster 1). As in the previous case, 27% of the measurements exceeded 125 ms and others deviated from this value, reaching values as high as 300 ms.</MatchText></p>
                    <p>
                        <MatchText id="match-83">A third group of countries comprising Mexico, the Dominican Republic, Cuba, Belize and Trinidad and Tobago had a more uniform behavior: although below 100 ms latencies were slightly higher than in clusters 1 and 3, above 100 ms the curve behaved similarly to cluster 3. In this case, however, we can observe that more than 90% of the measurements were below 175 ms (see cluster 0).</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r18.png" /></figure>
                    <p>
                        <MatchText id="match-84">Finally, there was a fourth group of countries (cluster 2) that included Colombia, Panama, Costa Rica, Nicaragua and Bolivia. In this case, latencies were higher than in the other clusters and above 100 ms in 70% of the cases.</MatchText></p>
                    <p>
                        <MatchText id="match-85">With respect to 2020, the first thing that stands out is an improvement in latency times in the group of countries in cluster 1. Countries that were in cluster 3 in 2017 are now in cluster 1: Chile, Argentina, Brazil and Paraguay. This cluster now includes a larger number of countries, mostly in the Caribbean, for example, Jamaica, the Bahamas, and the Virgin Islands, among others.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r19.png" /></figure>
                    <p>
                        <MatchText id="match-86">Two things should be noted: the definition of the countries that were included in the cluster changed from 2017 to 2020 (Caribbean countries were added), as did the data. Therefore, we can analyze the evolution of latency based on two criteria:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-87">Analyzing cluster 3 of 2017, comparing the data of 2017 with the data of 2020.</MatchText></li>
                        <li>
                            <MatchText id="match-88">Analyzing cluster 1 of 2020, comparing the data of 2017 with the data of 2020.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-89">To do so, we grouped the data under different curves in the CDF above.</MatchText></p>
                    <p>
                        <MatchText id="match-90">We can see that in both cases there were improvements in the cluster's connectivity:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-91">If we consider the definition of 2017 (solid blue vs. dotted blue line), we can see a clear improvement in latencies below the 75th percentile: the 40th percentile shifts from 112 ms to 63 ms, an improvement of 49 ms.</MatchText></li>
                        <li>
                            <MatchText id="match-92">If we consider the definition of 2020 (solid red vs. dotted red line), we can see that the curves behave very similarly and they shift slightly to the left. For example, the 50th percentile shifts from 138 ms to 103 ms, an improvement of 35 ms.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-93">Likewise, we repeated the comparison for cluster 0 (2020 definition, 2020 data versus 2017 data). This comparison does not allow us to conclude that there were improvements in the past few years. However, it is important to bear in mind that cluster 0 is not actually a cluster but a group of countries that were not placed into a specific cluster. As more measurements are obtained, it is highly likely that other clusters with better internal latency will appear.</MatchText></p>
                    <div>
                        <div>
                            <p>
                                <MatchText id="match-94">In summary, we can see that there is an important group of countries (which includes some of the countries with the largest populations and Internet development in the region) which, in 2020, exhibit a significant improvement with regard to 2017, as the median measured latency improved by 49 ms.</MatchText></p>
                        </div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r20.png" /></figure></div>
                    </div>


                </>}
                {myLang === 'pt' && <>

                    <div>
                        <div>
                            <h2><MatchText id="match-78">Comparando com 2017 </MatchText></h2>
                            <p>
                                <MatchText id="match-79">O mesmo estudo foi feito em 2017, do qual foram obtidos 4 clusters.</MatchText></p>
                            <p>
                                <MatchText id="match-80">Nas próximas duas imagens podemos comparar as curvas de latência em uma CDF, semelhante ao que vimos anteriormente. Nesse caso, são comparados os clusters de 2017 e 2020, e as latências correspondem a medições entre os países de um mesmo cluster.</MatchText></p>
                        </div>
                        <figure><img id="img_clusters" alt="Ver" src="/images/conectividad-lac-2020/r16_pt.jpg" /></figure>
                    </div>

                    <p>
                        <MatchText id="match-81">Uma primeira observação que podemos fazer é que em 2017 temos 4 clusters, enquanto em 2020 temos 2. Analisando o caso de 2017, vimos que existe um grupo de países no cluster 3 que apresenta baixa latência, inferior a 74 ms em 30% dos casos. Então, as latências aumentam, mas 63% das medições deste grupo estão por baixo de 125 ms. Neste grupo estão o Chile, Argentina, Uruguai, Brasil e Paraguai (ver na figura cluster 3).</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r17.png" /></figure>
                    <p>
                        <MatchText id="match-82">Há um segundo grupo de países que também tem 73% das medições por baixo de 125 ms e, inclusive em muitos casos, tem um desempenho melhor do que o cluster 3. Neste grupo se encontram países como a Honduras, Guatemala, Venezuela e El Salvador (ver cluster 1). Nesse caso, como no anterior, há 27% das medições que ultrapassam os 125 ms e se afastam desse valor, chegando inclusive a 300m.</MatchText></p>
                    <p>
                        <MatchText id="match-83">Um terceiro grupo de países formado pelo México, República Dominicana, Cuba, Belize e Trinidad e Tobago, tem um comportamento mais uniforme: embora por baixo dos 100 ms as latências são ligeiramente maiores que os clusters 1 e 3, acima desse valor a curva se comporta de forma semelhante ao cluster 3, mas neste caso podemos ver que mais de 90% das medições estão por baixo dos 175 ms (ver cluster 0).</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r18.png" /></figure>
                    <p>
                        <MatchText id="match-84">Finalmente, existe um quarto grupo de países que se agrupa no cluster 2, que inclui a Colômbia, Panamá, Costa Rica, Nicarágua e Bolívia. Nesse caso, as latências estão acima das observadas nos demais clusters, ultrapassando os 100 ms em 70% dos casos.</MatchText></p>
                    <p>
                        <MatchText id="match-85">Em relação a 2020, a primeira coisa a ser destacada é uma melhoria dos tempos de latência no grupo de países do cluster 1. Aqueles que estavam no cluster 3 no gráfico de 2017, agora podemos vê-los no cluster 1: Chile, Argentina, Brasil e Paraguai. Neste cluster aparecem mais países que antes, principalmente do Caribe, como a Jamaica, Bahamas, Ilhas Virgens, entre outros.</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r19.png" /></figure>
                    <p>
                        <MatchText id="match-86">É importante observar duas coisas: a definição dos países que compõem o cluster muda de 2017 a 2020 (países do Caribe são adicionados) e os dados também. Portanto, podemos analisar a evolução da latência de acordo com dois critérios:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-87">Analisando o cluster 3 de 2017, comparando os dados de 2017 com os de 2020.</MatchText></li>
                        <li>
                            <MatchText id="match-88">Analisando o cluster 1 de 2020, comparando os dados de 2017 com os de 2020.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-89">Para fazer isso, agrupamos os dados em diferentes curvas na CDF anterior.</MatchText></p>
                    <p>
                        <MatchText id="match-90">Podemos ver que em ambos os casos há melhorias na conectividade do cluster:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-91">Para a definição de 2017 (linha azul vs. azul pontilhada), vemos uma melhoria clara nas latências abaixo do percentil 75: o percentil 40 se desloca de 112 ms para 63 ms, representando uma melhoria de 49 ms.</MatchText></li>
                        <li>
                            <MatchText id="match-92">Para a definição de 2020 (linha vermelha vs. vermelha pontilhada), vemos que as curvas têm um comportamento muito semelhante e que se deslocam ligeiramente para a esquerda. Por exemplo, o percentil 50 se desloca de 138 ms para 103 ms, representando uma melhoria de 35 ms.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-93">Da mesma forma, repetimos a comparação para o cluster 0 (definição de 2020, dados de 2020 vs. dados de 2017). A partir dessa comparação, é impossível dizer que houve melhorias nos últimos anos. Deve-se levar em consideração que o cluster 0 não é um cluster em si, pois agrupa todos os países que não foram agrupados em um cluster definido. É altamente provável que, na medida em que sejam obtidas mais medições, outros clusters com melhor latência interna comecem a aparecer.</MatchText></p>
                    <div>
                        <div>
                            <p>
                                <MatchText id="match-94">Em resumo, podemos ver que existe um grupo importante de países (que inclui alguns dos países com mais população e desenvolvimento da Internet na região) que em 2020 apresenta uma melhoria significativa em relação a 2017, em que a mediana da latência medida melhora em 49 ms.</MatchText></p>
                        </div>
                        <div><figure><img alt="Ver" src="/images/conectividad-lac-2020/r20.png" /></figure></div>
                    </div>

                </>}
            </div>
        </div>
    );
}

export default Comparando2017;