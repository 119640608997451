import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Fuentes() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p6">
                {myLang === 'es' && <>
                    <h2>Fuentes de informaci&oacute;n disponibles</h2>
                    <p>Para este estudio se utilizaron fuentes de datos activas que pueden brindar informaci&oacute;n pertinente sobre el estado del IP <em>spoofing</em> en la regi&oacute;n.</p>
                    <p>Estas son:</p>
                    <ul>
                        <li>&lsaquo;<a href="https://www.caida.org/projects/spoofer/">https://www.caida.org/projects/spoofer/</a><u>&rsaquo;</u></li>
                        <li>&lsaquo;<a href="https://closedresolver.korlabs.io/">https://closedresolver.korlabs.io/</a><u>&rsaquo;</u></li>
                    </ul>
                    <h3>El proyecto Spoofer de CAIDA</h3>
                    <p>El proyecto spoofer de <a href="https://www.caida.org/projects/spoofer/" target="_blank" rel="noreferrer">CAIDA</a> es referencia obligada para obtener datos sobre problem&aacute;ticas relacionadas al IP <em>spoofing</em>. Funciona desde 2006 y cuenta con un cliente a partir del cual se pueden ejecutar tests. Con estos testeos se eval&uacute;a si una red permite tanto <em>outbound IP spoofing</em> como <em>inbound IP spoofing</em>.</p>
                    <p>En la siguiente figura se puede observar la arquitectura del <em>spoofer</em> de CAIDA, sus componentes y la interacci&oacute;n:</p>
                    <img src="/images/anti-spoofing/fig1.png" alt="Spoofer CAIDA" />
                    <p>Fig. 1- Spoofer CAIDA &lsaquo;<a href="https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png" target="_blank" rel="noreferrer">https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png</a><u>&rsaquo;</u></p>
                    <p>En el cliente est&aacute; el <em>software</em> que ejecuta los tests para evaluar las pol&iacute;ticas de filtrado de la organizaci&oacute;n. El cliente env&iacute;a paquetes, con una direcci&oacute;n IP de origen <em>spoofeada</em>, a los nodos <em>ark</em> del archipi&eacute;lago de CAIDA.</p>
                    <p>En caso de aplicar pol&iacute;ticas de filtrado, el <em>router</em> de la organizaci&oacute;n descarta los paquetes salientes con direcci&oacute;n IP de origen externa a la red del cliente.</p>
                    <p>Los <em>nodos ark</em> funcionan como <em>honeypots</em>. Si reciben los paquetes generados en el cliente con IP <em>spoofeada</em>, se determina entonces que la organizaci&oacute;n no filtra el <em>outbound</em> IP <em>spoofing</em>. Si los <em>nodos ark</em> no reciben dichos paquetes, significa que la organizaci&oacute;n est&aacute; filtrando el <em>outbound</em> IP <em>spoofing</em>.</p>
                    <p>El <em>host</em> de CAIDA es el dispositivo externo desde el cual se realiza la evaluaci&oacute;n del <em>inbound IP spoofing</em>. Desde all&iacute; se env&iacute;an paquetes con direcciones IP de origen <em>spoofeadas</em> hacia el cliente.</p>
                    <p>En caso de aplicar pol&iacute;ticas de filtrado, el <em>router</em> de la organizaci&oacute;n descarta los paquetes entrantes con direcciones IP de origen que no deber&iacute;an ser aceptadas como direcciones IP internas de la red del cliente.</p>
                    <p>El proyecto brinda para cada pa&iacute;s informaci&oacute;n de la cantidad de bloques IP testeados, la cantidad de bloques que no filtran el <em>outbound IP spoofing</em> y la cantidad de bloques que s&iacute; lo hacen. Tambi&eacute;n provee reportes que muestran datos respecto a la remediaci&oacute;n en cada uno de estos pa&iacute;ses.</p>
                    <p>Entre las ventajas de este proyecto destacamos que:</p>
                    <ul>
                        <li>Brinda al usuario informaci&oacute;n sobre la existencia en la organizaci&oacute;n de filtros de tipo <em>inbound</em> y <em>outbound</em></li>
                        <li>Permite evaluar el IP <em>spoofing,</em> tanto en redes IPv4 como en redes IPv6.</li>
                        <li>La aplicaci&oacute;n cliente que se instala en el dispositivo testea autom&aacute;ticamente la existencia de filtros de tipo <em>inbound</em> y <em>outbound</em> SAV en las redes a las que dicho dispositivo se conecta.</li>
                    </ul>
                    <p>Como desventajas podemos mencionar que:</p>
                    <ul>
                        <li>Los datos p&uacute;blicos muestran solamente el <em>outbound</em> IP <em>spoofing</em>.</li>
                        <li>Requiere la instalaci&oacute;n de una aplicaci&oacute;n en el cliente.</li>
                        <li>Tiene datos parciales de redes IPv4 e IPv6.</li>
                    </ul>
                    <h3>El Proyecto Closed Resolver</h3>
                    <p>En el a&ntilde;o 2019, <a href="https://closedresolver.korlabs.io/" target="_blank" rel="noreferrer">The Closed Resolver Project</a> hizo un relevamiento de todo el espacio de direcciones IPv4 utilizando el DNS para evaluar la presencia de filtros adecuados contra el <em>inbound</em> IP <em>spoofing</em>. Se utiliz&oacute; el DNS para realizar consultas que parecieran provenientes de <em>hosts</em> de la misma organizaci&oacute;n donde funciona el servidor de DNS. Cuando el destinatario de la evaluaci&oacute;n era un servidor DNS recursivo, era posible la detecci&oacute;n del <em>inbound</em> IP <em>spoofing</em> a partir de la secuencia observada en la siguiente figura (<a href="https://arxiv.org/pdf/2002.00441.pdf" target="_blank" rel="noreferrer">extra&iacute;da del art&iacute;culo que describe el objetivo del proyecto</a>).</p>
                    <img src="/images/anti-spoofing/fig2.png" alt="Metodolog&iacute;a de The Closed Resolver Project" />
                    <p>Fig. 2- Metodolog&iacute;a de The Closed Resolver Project para la evaluaci&oacute;n del <em>inbound</em> IP <em>spoofing</em> &lsaquo;https://arxiv.org/pdf/2002.00441.pdf&rsaquo;</p>
                    <p>La metodolog&iacute;a observada en la figura muestra que:</p>
                    <ol>
                        <li>Se realizan requerimientos de DNS a diferentes direcciones IPv4 (1.2.3.5 en el ejemplo) provenientes desde direcciones IP de la propia organizaci&oacute;n (1.2.3.6 en el ejemplo).</li>
                        <li>En caso de que no implemente filtros de tipo <em>inbound</em> SAV ser&aacute; posible el <em>inbound</em> IP <em>spoofing</em> y el paquete llegar&aacute; al destino (1.2.3.5 en el ejemplo).</li>
                        <li>Si en la direcci&oacute;n de destino del requerimiento de DNS funciona un <em>resolver</em> de DNS, este intentar&aacute; resolver la consulta (en el ejemplo, se consulta el registro A de dkIL56.01020305.s1.drakkardns.com). Para ello, el resolver inicialmente contactar&aacute; a un <em>root server </em>que brindar&aacute; la informaci&oacute;n de los Name Servers (NS s) asociados al Top Level Domain (TLD).</li>
                        <li>Seguidamente, se contactar&aacute; con uno de los NSs asociados al TLD correspondiente. La respuesta del TLD brindar&aacute; informaci&oacute;n sobre los NSs asociados al dominio <em>drakkardns.com.</em></li>
                        <li>Dado que el dominio <em>drakkardns.com</em> del ejemplo, es controlado por The Closed Resolver Project, al recibir la consulta se puede confirmar que proviene de una red que no implementa filtros de tipo <em>inbound</em> SAV.</li>
                        <li>Finalmente, el servidor de DNS brinda la informaci&oacute;n solicitada a quien cree que le hizo la consulta (1.2.3.6 en el ejemplo).</li>
                    </ol>
                    <p>Las principales ventajas de este proyecto son:</p>
                    <ul>
                        <li>Tiene datos completos de redes IPv4.</li>
                        <li>No requiere la instalaci&oacute;n de ninguna aplicaci&oacute;n.</li>
                    </ul>
                    <p>Entre las desventajas distinguimos que:</p>
                    <ul>
                        <li>Solamente brinda al usuario informaci&oacute;n de buenas pr&aacute;cticas relacionadas con la aplicaci&oacute;n de filtros de tipo <em>inbound</em></li>
                        <li>Para IPv6 solo se cuenta con datos parciales, pues, al no poder escanear todo el espacio de direcciones, se utiliza el servicio <a href="https://ipv6hitlist.github.io/" target="_blank" rel="noreferrer"><em>hitlist</em></a> para realizar los tests sobre direcciones IPv6 que responden.</li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>Available sources of information</h2>
                    <p>For this study, active data sources were used that could provide relevant information on the status of IP spoofing in the region.</p>
                    <p>These sources were:</p>
                    <ul>
                        <li>&lsaquo;<a href="https://www.caida.org/projects/spoofer/" target="_blank" rel="noreferrer">https://www.caida.org/projects/spoofer/</a><u>&rsaquo;</u></li>
                        <li>&lsaquo;<a href="https://closedresolver.korlabs.io/" target="_blank" rel="noreferrer">https://closedresolver.korlabs.io/</a><u>&rsaquo;</u></li>
                    </ul>
                    <h3>The CAIDA Spoofer Project</h3>
                    <p>The <a href="https://www.caida.org/projects/spoofer/" target="_blank" rel="noreferrer">CAIDA Spoofer</a>&nbsp;Project is a mandatory reference for anyone looking to obtain data on issues related to IP spoofing. It has been running since 2006 and has a client that can be used to perform tests. These tests allow assessing whether a network allows both outbound IP spoofing and inbound IP spoofing.</p>
                    <p>The following image shows the architecture of the CAIDA Spoofer, its components, and their interaction:</p>
                    <img src="/images/anti-spoofing/fig1.png" alt="Spoofer CAIDA" />
                    <p>Figure 1- CAIDA Spoofer &lsaquo;<a href="https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png" >https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png</a><u>&rsaquo;</u></p>
                    <p>The client software performs the tests to assess the organization's filtering policies. The client sends packets with spoofed source IP addresses outbound to the ark nodes in the CAIDA archipelago.</p>
                    <p>If filtering policies are applied, the organization's router drops outbound packets with IP addresses external to the client's network.</p>
                    <p>Ark nodes act like honeypots. If they receive packets that originate in the client with spoofed IP addresses, it can be concluded that the organization does not filter outbound IP spoofing. If the ark nodes do not receive these packets, then the organization is filtering outbound IP spoofing.</p>
                    <p>The CAIDA host is the external device from which the inbound IP spoofing tests are performed. From there, packets with spoofed source IP addresses are sent to the client.</p>
                    <p>When filtering policies are applied, the organization's router drops inbound packets with source IP addresses that are not recognized as internal to the client's network.</p>
                    <p>For each country, the project provides information on the number of IP blocks that have been probed, the number of blocks that are not filtering outbound IP spoofing, and the number of blocks that are. It also provides reports containing evidence of remediation at country level.</p>
                    <p>The advantages of this project include:</p>
                    <ul>
                        <li>It provides the user with information on the existence of inbound and outbound SAV in the organization.</li>
                        <li>It allows assessing IP spoofing, both for IPv4 and IPv6 networks.</li>
                        <li>The client application installed on the device automatically probes the existence of inbound and outbound SAV in the networks to which the device connects.</li>
                    </ul>
                    <p>Its disadvantages include:</p>
                    <ul>
                        <li>Public data shows only outbound IP spoofing.</li>
                        <li>It requires the installation of a client software.</li>
                        <li>It contains partial information for IPv4 and IPv6 networks.</li>
                    </ul>
                    <h3>The Closed Resolver Project</h3>
                    <p>In 2019, <a href="https://closedresolver.korlabs.io/" target="_blank" rel="noreferrer">The Closed Resolver Project</a>&nbsp;scanned the entire IPv4 address space using DNS to assess the presence of proper filters against inbound IP spoofing. DNS was used to send queries that appeared to have originated in hosts within the same organization where the DNS server was running. When the recipient was a recursive DNS server, detecting inbound IP spoofing was possible using the sequence illustrated in the image below (<a href="https://arxiv.org/pdf/2002.00441.pdf" target="_blank" rel="noreferrer">taken from the article that describes the goal of the project</a>).</p>
                    <img src="/images/anti-spoofing/fig2.png" alt="Metodolog&iacute;a de The Closed Resolver Project" />
                    <p>Fig. 2- Methodology used by The Closed Resolver Project to assess inbound IP spoofing &lsaquo;https://arxiv.org/pdf/2002.00441.pdf&rsaquo;</p>
                    <p>The methodology shown in Figure 2 is as follows:</p>
                    <ol>
                        <li>DNS requests are sent to different IPv4 addresses (1.2.3.5 in the example) from IP addresses within the organization itself (1.2.3.6 in the example).</li>
                        <li>If inbound SAV is not implemented, inbound IP spoofing will be possible and the packet will reach its destination (1.2.3.5 in the example).</li>
                        <li>If there is a DNS resolver at the DNS request's destination address, it will try to resolve the query (in the example, the A record of dkIL56.01020305.s1.drakkardns.com is queried). To do so, the resolver will initially contact a root server that will provide information on the name servers (NS) associated with the top-level domain (TLD).</li>
                        <li>Next, one of the name servers associated with the corresponding TLD will be contacted. The TLD&rsquo;s response will provide information on the NS&rsquo;s associated with the drakkardns.com domain.</li>
                        <li>Because the drakkardns.com domain in the example is controlled by The Closed Resolver Project, upon receiving the query it is possible to confirm that it originated in a network that does has not implemented inbound SAV.</li>
                        <li>Finally, the DNS server provides the requested information to whoever it thinks sent the query (1.2.3.6 in the example).</li>
                    </ol>
                    <p>The advantages of this project include:</p>
                    <ul>
                        <li>It contains complete data for IPv4 networks.</li>
                        <li>It does not require the installation of any software.</li>
                    </ul>
                    <p>Its disadvantages include:</p>
                    <ul>
                        <li>It only provides information on good practices related to the application of inbound SAV.</li>
                        <li>Only partial data is available for IPv6. Because it is not possible to scan the entire address space, <a href="https://ipv6hitlist.github.io/" target="_blank" rel="noreferrer">the hitlist</a>&nbsp;service is used to test the IPv6 addresses that respond.</li>
                    </ul>

                </>}
                {myLang === 'pt' && <>
                    <h1>Fontes de informa&ccedil;&otilde;es dispon&iacute;veis</h1>
                    <p>Para este estudo, foram usadas fontes de dados ativas que podem fornecer informa&ccedil;&otilde;es relevantes sobre o estado do <em>IP spoofing</em> na regi&atilde;o.</p>
                    <p>Estas s&atilde;o:</p>
                    <ul>
                        <li>&lsaquo;<a href="https://www.caida.org/projects/spoofer/" target="_blank" rel="noreferrer">https://www.caida.org/projects/spoofer/</a><u>&rsaquo;</u></li>
                        <li>&lsaquo;<a href="https://closedresolver.korlabs.io/" target="_blank" rel="noreferrer">https://closedresolver.korlabs.io/</a><u>&rsaquo;</u></li>
                    </ul>
                    <h2>O projeto Spoofer de CAIDA</h2>
                    <p>O <a href="https://www.caida.org/projects/spoofer/" target="_blank" rel="noreferrer">projeto spoofer de CAIDA</a>&nbsp;&eacute; refer&ecirc;ncia obrigat&oacute;ria para obter dados sobre problemas relacionados com o IP <em>spoofing</em>. Esse est&aacute; em execu&ccedil;&atilde;o desde 2006 e possui um cliente a partir do qual os testes podem ser executados. Com esses testes, &eacute; avaliado se uma rede permite tanto <em>outbound IP spoofing</em> quanto <em>inbound IP spoofing</em>.</p>
                    <p>A figura a seguir mostra a arquitetura do <em>spoofer</em> de CAIDA, seus componentes e a intera&ccedil;&atilde;o:</p>
                    <img src="/images/anti-spoofing/fig1.png" alt="Spoofer CAIDA" />
                    <p>Fig. 1- Spoofer CAIDA &lsaquo;<a href="https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png">https://www.caida.org/projects/spoofer/images/spoofer-diagramer.png</a><u>&rsaquo;</u>&nbsp;</p>
                    <p>No cliente est&aacute; o <em>software</em> que executa os testes para avaliar as pol&iacute;ticas de filtragem da organiza&ccedil;&atilde;o. O cliente envia pacotes, com um endere&ccedil;o IP de origem <em>spoofeado</em>, para os n&oacute;s <em>ark</em> do arquip&eacute;lago de CAIDA.</p>
                    <p>No caso de aplicar pol&iacute;ticas de filtragem, o roteador da organiza&ccedil;&atilde;o descarta os pacotes de sa&iacute;da com endere&ccedil;os IP de origem externos &agrave; rede do cliente.</p>
                    <p>Os <em>n&oacute;s ark</em> funcionam como <em>honeypots</em>. Se receberem os pacotes gerados no cliente com IP <em>spoofeado</em>, determina-se ent&atilde;o que a organiza&ccedil;&atilde;o n&atilde;o filtra o <em>outbound</em> IP <em>spoofing</em>. Se os <em>n&oacute;s ark</em> n&atilde;o receberem esses pacotes, quer dizer que a organiza&ccedil;&atilde;o est&aacute; filtrando o <em>outbound</em> IP <em>spoofing</em>.</p>
                    <p>O <em>host</em> de CAIDA &eacute; o dispositivo externo a partir do qual a avalia&ccedil;&atilde;o do <em>inbound IP spoofing</em> &eacute; feita. Da&iacute;, os pacotes com endere&ccedil;os IP de origem <em>spoofeados</em> s&atilde;o enviados para o cliente.</p>
                    <p>No caso de aplicar pol&iacute;ticas de filtragem, o roteador da organiza&ccedil;&atilde;o descarta pacotes de entrada com endere&ccedil;os IP de origem que n&atilde;o deveriam ser aceitos como endere&ccedil;os IP internos &agrave; rede do cliente.</p>
                    <p>O projeto fornece informa&ccedil;&otilde;es para cada pa&iacute;s sobre o n&uacute;mero de blocos IP testados, o n&uacute;mero de blocos que n&atilde;o filtram o <em>outbound IP spoofing</em> e o n&uacute;mero de blocos que o fazem. Ele tamb&eacute;m fornece relat&oacute;rios mostrando dados sobre a remedia&ccedil;&atilde;o em cada um desses pa&iacute;ses.</p>
                    <p>Entre as vantagens deste projeto destacamos que:</p>
                    <ul>
                        <li>Fornece ao usu&aacute;rio informa&ccedil;&otilde;es sobre a exist&ecirc;ncia na organiza&ccedil;&atilde;o de filtros do tipo <em>inbound</em> e <em>outbound</em></li>
                        <li>Permite avaliar o IP <em>spoofing,</em> tanto nas redes IPv4 quanto nas redes IPv6.</li>
                        <li>O aplicativo cliente que &eacute; instalado no dispositivo testa automaticamente a exist&ecirc;ncia de filtros do tipo <em>inbound</em> e <em>outbound SAV</em> nas redes &agrave;s que esse dispositivo se conecta.</li>
                    </ul>
                    <p>Como desvantagens podemos citar que:</p>
                    <ul>
                        <li>Os dados p&uacute;blicos mostram apenas o <em>outbound</em> IP <em>spoofing</em>.</li>
                        <li>Requer a instala&ccedil;&atilde;o de um aplicativo no cliente.</li>
                        <li>Possui dados parciais de redes IPv4 e IPv6.</li>
                    </ul>
                    <h2>O projeto Closed Resolver</h2>
                    <p>Em 2019, <a href="https://closedresolver.korlabs.io/" target="_blank" rel="noreferrer">The Closed Resolver Project</a>&nbsp;fez um levantamento de todo o espa&ccedil;o de endere&ccedil;os IPv4 usando o DNS para avaliar a presen&ccedil;a de filtros adequados contra o <em>inbound</em> IP <em>spoofing</em>. O DNS foi usado para fazer consultas que parecessem vir de <em>hosts</em> da mesma organiza&ccedil;&atilde;o onde o servidor DNS funciona. Quando o destinat&aacute;rio da avalia&ccedil;&atilde;o era um servidor DNS recursivo, era poss&iacute;vel detectar o <em>inbound IP spoofing</em> a partir da sequ&ecirc;ncia observada na figura a seguir (<a href="https://arxiv.org/pdf/2002.00441.pdf" target="_blank" rel="noreferrer">extra&iacute;da do artigo que descreve o objetivo do projeto</a>).</p>
                    <img src="/images/anti-spoofing/fig2.png" alt="Metodolog&iacute;a de The Closed Resolver Project" />
                    <p>Fig. 2- Metodologia de The Closed Resolver Project para a avalia&ccedil;&atilde;o do <em>inbound</em> IP <em>spoofing</em> &lsaquo;https://arxiv.org/pdf/2002.00441.pdf&rsaquo;&nbsp;</p>
                    <p>A metodologia observada na figura mostra que:</p>
                    <ol>
                        <li>As solicita&ccedil;&otilde;es de DNS s&atilde;o feitas para diferentes endere&ccedil;os IPv4 (1.2.3.5 no exemplo) provenientes de endere&ccedil;os IP da pr&oacute;pria organiza&ccedil;&atilde;o (1.2.3.6 no exemplo).</li>
                        <li>Caso n&atilde;o implemente filtros de tipo <em>inbound</em> SAV ser&aacute; poss&iacute;vel o <em>inbound</em> IP <em>spoofing</em> e o pacote alcan&ccedil;ar&aacute; o destino (1.2.3.5 no exemplo).</li>
                        <li>Se um <em>resolver</em> de DNS funcionar no endere&ccedil;o de destino da solicita&ccedil;&atilde;o de DNS, este tentar&aacute; resolver a consulta (no exemplo, &eacute; consultado o registro A de dkIL56.01020305.s1.drakkardns.com). Para isso, o <em>resolver</em> contatar&aacute; inicialmente um <em>root server</em> que fornecer&aacute; as informa&ccedil;&otilde;es sobre os <em>Name Servers</em> (NS) associados ao <em>Top Level Domain</em> (TLD).</li>
                    </ol>
                </>}
            </div>
        </div>
    )
}

export default Fuentes
