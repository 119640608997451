import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'

function Objetivo(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' && <>
                    <h2><MatchText id="match-10">Objetivo</MatchText></h2>
                    <p><MatchText id="match-11">Mejorar los niveles de ciberseguridad de los recursos IP bajo la administraci&oacute;n de LACNIC, ayudando a la estabilidad y resiliencia de internet, minimizando el posible uso de los protocolos UDP en ataques DDoS.</MatchText></p>
                </>}
                {myLang === 'en' && <>
                    <h2><MatchText id="match-10">Purpose</MatchText></h2>
                    <p><MatchText id="match-11">To improve the cybersecurity levels of the IP resources managed by LACNIC, contributing to Internet stability and resilience by minimizing the potential use of UDP protocols in DDoS attacks.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2><MatchText id="match-10">Objetivo</MatchText></h2>
                    <p><MatchText id="match-11">Melhorar os níveis de segurança cibernética dos recursos IP sob a administração do LACNIC, cooperando para a estabilidade e resiliência da Internet e minimizando o possível uso dos protocolos UDP em ataques DDoS.</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Objetivo;