import React from 'react';
import MenuLateralInterconexionBgp from '../pages/reports/InterconexionBgp/MenuLateralInterconexionBgp'
import MenuLateralConectividadEnLac from '../pages/reports/ContectividadEnLac/MenuLateralConectividadEnLac'
import MenuLateralAtaquesDdos from '../pages/reports/VulnerabilidadAtaquesDdos/MenuLateralAtaquesDdos'
import MenuLateralOptimizacionRedesBgp from '../pages/reports/OptimizacionRedesBgp/MenuLateralOptimizacionRedesBgp';
import MenuLateralSoporteIPv6EquiposCPEV1 from '../pages/reports/IPv6EnEquiposCPE/MenuLateralSoporteIPv6EquiposCPEV1'
import MenuLateralInterconexionBgpLocal from '../pages/reports/InterconexionBgpLocal/MenuLateralInterconexionBgpLocal';
import MenuLateralRootServerLocal from '../pages/reports/UsoRootServerLAC/MenuLateralRootServerLocal';
import MenuLateralAntiSpoofing from '../pages/reports/AntiSpoofing/MenuLateralAntiSpoofing';
import MenuLateralDNSOverTLS from '../pages/reports/DNSOverTLS/MenuLateralDNSOverTLS';
import MenuLateralConectividad2022 from '../pages/reports/Conectividad2022/MenuLateralConectividad2022';
import MenuLateralMejoresPracticasParaIXP from '../pages/reports/MejoresPracticasParaIXP/MenuLateralMejoresPracticasParaIXP';


export default function MenuLateralAllReports(props) {
    const {menu_lateral, setSearchTerm, searchTerm, handleSearch} = props;
    return (
    <> 
    
      {menu_lateral === 'interconexion-bgp-en-lac' && <MenuLateralInterconexionBgp />}
      {menu_lateral === 'conectividad-en-la-region-lac-2020' && <MenuLateralConectividadEnLac />}
      {menu_lateral === 'estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos' && <MenuLateralAtaquesDdos />}
      {menu_lateral === 'optimizacion-de-redes-mediante-un-mejor-ibgp' && <MenuLateralOptimizacionRedesBgp />}
      {menu_lateral === 'soporte-de-ipv6-en-equipos-cpe-version-1' && <MenuLateralSoporteIPv6EquiposCPEV1 />}
      {menu_lateral === 'interconexion-bgp-a-nivel-local' && <MenuLateralInterconexionBgpLocal />}
      {menu_lateral === 'uso-root-server-local' && <MenuLateralRootServerLocal/>}
      {menu_lateral === 'estudio-anti-spoofing-en-la-region' && <MenuLateralAntiSpoofing />}
      {menu_lateral === 'uso-de-dns-over-tls-en-latinoamerica-y-el-caribe' && <MenuLateralDNSOverTLS />}
      {menu_lateral === 'estudio-de-conectividad-2022' && <MenuLateralConectividad2022 menu_lateral={menu_lateral} setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} /> } 
      {menu_lateral === 'mejores-practicas-para-ixp' && <MenuLateralMejoresPracticasParaIXP menu_lateral={menu_lateral} setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} /> }
    </>

    
     
  )
}
