import React from 'react';
import { LangState } from './LangState'; 
import { MobileState } from './MobileState'; 
import { MenuState } from './MenuState';
import { ReferenceState} from './ReferenceState';

export default function MasterState({children}) {
  return (
    <LangState> 
        <MobileState> 
            <MenuState>
              <ReferenceState>
                {children}  
              </ReferenceState>
            </MenuState>
        </MobileState>
    </LangState>
  )
}