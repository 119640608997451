import React, { useContext } from 'react';
import { LangContext } from '../context/DataContext';
import { useParams, useLocation } from 'react-router-dom'
import '../css/index.css';
import NoMatch from '../components/NoMatch'
import IndexContent from './IndexContent';

function Index() {

	const uselocation = useLocation();
	const url = uselocation.pathname;
	const { slug } = useParams();

	const { myLang, handleLanguaje } = useContext(LangContext)

	if (slug === undefined) {
		{ myLang === 'es' && handleLanguaje('es') }
		{ myLang === 'en' && handleLanguaje('en') }
		{ myLang === 'pt' && handleLanguaje('pt') }
		return (
			<IndexContent />
		)
	}
	if (slug === 'es') {
		handleLanguaje('es')
		return (
			<IndexContent />
		)
	} if (slug === 'en') {
		handleLanguaje('en')
		return (
			<IndexContent />
		)
	} if (slug === 'pt') {
		handleLanguaje('pt')
		return (
			<IndexContent />
		)
	} if (url.includes(`/es/`) === true) {
		handleLanguaje('es')
	} if (url.includes(`/en/`) === true) {
		handleLanguaje('en')
	} if (url.includes(`/pt`) === true) {
		handleLanguaje('pt')
	} else return <NoMatch /> 
}
export default Index;
