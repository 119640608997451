import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext";  
import DatosIXP from "./DatosIXP";
import Introduccion from "./Introduccion";
import Objetivos from "./Objetivos";
import Metodologia from "./Metodologia";
import Resumen from "./Resumen";
import ResultadosPreliminares from "./ResultadosPreliminares";
import Conclusiones from "./Conclusiones";

function InterconexionBgpLocal() {
    return (
        <>
            <ReportTitle />
            <Resumen/>
            <Introduccion/> 
            <Objetivos />
            <Metodologia />
            <ResultadosPreliminares />
            <DatosIXP />
            <Conclusiones />
             
        </>
    )
}

function ReportTitle(props){
    const { myLang } = useContext(LangContext)
    return(<>
    { myLang === 'es' && <h1>Interconexión BGP a nivel local en la región de AméricaLatina y el Caribe</h1> }
    { myLang === 'en' && <h1>Local BGP Interconnection in Latin America and the Caribbean</h1> }
    { myLang === 'pt' && <h1>Interconexão BGP no nível local na região da América Latina e o Caribe</h1> }
    </>)
}

export default InterconexionBgpLocal
