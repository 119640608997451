import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
import SearchComponent from '../../../components/SearchComponent';

function MenuLateralConectividadEnLac() { 
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return ( 
    <> 
            {myLang === 'es' && 
            <nav>
                <button>Conectividad en la región LAC: 2020 <i className="fas fa-angle-down"></i></button>
                <SearchComponent />
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen</a></li>
                    <li><a href="#introduccion" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introducción</a></li>   
                    <li><a href="#resumen" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Metodología y estudios anteriores</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Estudios 2020</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Comparando 2020 con 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Latencia agregada comparada</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Clusters de latencia</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Comparando con 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Conclusiones</a></li>
                </ul>
            </nav>
            }
            {myLang === 'en' && 
            <nav>
                <button>Connectivity in the LAC Region in 2020 <i className="fas fa-angle-down"></i></button>
                <SearchComponent />
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Abstract</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introduction</a></li>   
                    <li><a href="#resumen" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Methodology and Prior Studies</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Results of the 2020 Study</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>2020 versus 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>A Comparison of Aggregate Latency</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Latency Clusters</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Comparison with 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Conclusions</a></li>
                </ul>
            </nav>
            }
            {myLang === 'pt' && 
            <nav>
                <button>Conectividade na região da LAC: 2020<i className="fas fa-angle-down"></i></button>
                <SearchComponent />
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Resumo</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introdução</a></li>   
                    <li><a href="#resumen" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Metodologia e estudos anteriores</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Estudo 2020</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Comparando 2020 com 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Latência agregada comparada</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Clusters de latência</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Comparando com 2017</a></li>
                    <li><a href="#resumen" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Conclusões</a></li>
                </ul>
            </nav>
            }
        </>
    );
}

export default MenuLateralConectividadEnLac;