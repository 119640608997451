import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Conclusiones() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p10">
                {myLang === 'es' && <>
                    <h2>Conclusiones</h2>
                    <p>De acuerdo a los datos relevados en el presente estudio, si bien la mayor&iacute;a de las redes testeadas tienen una correcta implementaci&oacute;n de las buenas pr&aacute;cticas asociadas a la mitigaci&oacute;n del IP spoofing en la regi&oacute;n, a&uacute;n resta un porcentaje del universo analizado sin actuar sobre dicha problem&aacute;tica.</p>
                    <p>En este informe se ha unificado terminolog&iacute;a y se ha organizado informaci&oacute;n de diferentes fuentes. Por ello resulta una fuente aut&oacute;noma y relevante para que los asociados puedan comprender la problem&aacute;tica del spoofing de direcciones IP, los mecanismos con los que cuentan para identificar en qu&eacute; estado se encuentran sus redes con respecto a ella y qu&eacute; acciones pueden realizar para mejorar la seguridad de las mismas.</p>
                    <p>Se espera que a partir de este estudio se incrementen de forma significativa las redes que aplican t&eacute;cnicas anti-spoofing y, de esta manera, colaborar con la seguridad, estabilidad y resiliencia de Internet.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Conclusions</h2>
                    <p>According to the data collected by this study, while most of the tested networks have properly implemented good practices associated with the mitigation of IP spoofing in the region, a part of the universe that was analyzed has not yet acted to mitigate this problem.</p>
                    <p>This report has unified terminology and organized information from various sources and is intended to be an autonomous and relevant source of information that will allow members to understand the problem of IP spoofing, the mechanisms they can use to assess the status of their networks with respect to IP spoofing, and the actions they can implement to improve their security.</p>
                    <p>It is expected that this study will lead to a significant increase in the number of networks that apply anti-spoofing techniques, thus contributing to Internet security, stability, and resilience.</p>
                </>}
                {myLang === 'pt' && <> 
                    <h2>Conclus&otilde;es</h2>
                    <p>De acordo com os dados recolhidos neste estudo, embora a maioria das redes testadas tenha uma correta implementa&ccedil;&atilde;o das boas pr&aacute;ticas associadas &agrave; mitiga&ccedil;&atilde;o do IP <em>spoofing</em> na regi&atilde;o, ainda existe uma percentagem do universo analisado sem atuar sobre o referido problema.</p>
                    <p>Neste relat&oacute;rio, a terminologia foi unificada e as informa&ccedil;&otilde;es de diferentes fontes foram organizadas. Por isso, &eacute; uma fonte aut&ocirc;noma e relevante para que os associados possam entender o problema do <em>spoofing</em> de endere&ccedil;os IP, os mecanismos que possuem para identificar o estado de suas redes em rela&ccedil;&atilde;o a este e quais a&ccedil;&otilde;es podem tomar para melhorar a seguran&ccedil;a destas.</p>
                    <p>Espera-se que a partir deste estudo o n&uacute;mero de redes que aplicam t&eacute;cnicas anti-<em>spoofing</em> aumente significativamente e, desta forma, contribua para a seguran&ccedil;a, estabilidade e resili&ecirc;ncia da Internet.</p>
                </>}
            </div>
        </div>
    )
}

export default Conclusiones
