import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
import SearchComponent from '../../../components/SearchComponent';

function MenuLateralAtaquesDdos() {
    const { myLang } = useContext(LangContext)
    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return (
        <>
            {myLang === 'es' &&
            <nav>
                <button>Estudio de recursos de internet de la región LAC que posibilitan ataques de DDoS <i className="fas fa-angle-down"></i></button>
                <SearchComponent/>
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Resumen</a></li> 
                    <li><a href="#objetivo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Objetivo</a></li> 
                    <li><a href="#motivacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Motivación</a></li> 
                    <li><a href="#analisis-preliminar-del-problema" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Análisis preliminar del problema</a></li> 
                    <li><a href="#relevamiento-inicial" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Relevamiento inicial</a></li> 
                    <li><a href="#acciones-realizadas" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Acciones realizadas</a></li> 
                    <li><a href="#resultado-de-las-acciones" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Resultado de las acciones</a></li> 
                    <li><a href="#conclusiones" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Conclusiones</a></li>
                    <li><a href="#referencias" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Referencias</a></li>  
                </ul>
            </nav>}
            {myLang === 'en' &&
            <nav>
                <button>Study of Internet Resources in the LACNIC Service Region that Use UDP and Enable DDoS Attacks <i className="fas fa-angle-down"></i></button>
                <SearchComponent/>
                <ul className="active">
                    <li><a href="#abstract" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Abstract</a></li> 
                    <li><a href="#purpose" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Purpose</a></li> 
                    <li><a href="#motivation" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Motivation</a></li> 
                    <li><a href="#preliminary-analysis-of-the-problem" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Preliminary Analysis of the Problem</a></li> 
                    <li><a href="#initial-survey" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Initial Survey</a></li> 
                    <li><a href="#actions" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Actions</a></li> 
                    <li><a href="#result-of-the-actions" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Result of the Actions</a></li> 
                    <li><a href="#conclusions" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Conclusions</a></li>
                    <li><a href="#references" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>References</a></li>  
                </ul>
            </nav>}
            {myLang === 'pt' &&
            <nav>
                <button>Estudo dos recursos da Internet presentes na região do LACNIC com protocolos UDP que possibilitam ataques do tipo DDoS<i className="fas fa-angle-down"></i></button>
                <SearchComponent/>
                <ul className="active">
                    <li><a href="#resumo" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Resumo</a></li> 
                    <li><a href="#objetivo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Objetivo</a></li> 
                    <li><a href="#motivacao" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Motivação</a></li> 
                    <li><a href="#analise-preliminar-do-problema" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Análise preliminar do problema</a></li> 
                    <li><a href="#resumen" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Levantamento inicial</a></li> 
                    <li><a href="#acoes-realizadas" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Ações realizadas</a></li> 
                    <li><a href="#resultado-das-acoes" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Resultado das ações</a></li> 
                    <li><a href="#conclusoes" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Conclusões</a></li>
                    <li><a href="#referencias" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Referências</a></li>  
                </ul>
            </nav>} 
        </>
    );
}

export default MenuLateralAtaquesDdos;