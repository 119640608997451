import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function AnalisisRegional(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="bgp5">
                {/* español */ myLang === 'es' && <>
                    <h2>
<MatchText id="match-88">Análisis de los datos a nivel regional</MatchText></h2>
                    <p>
<MatchText id="match-89">De forma análoga a los datos calculados por país, podemos calcular datos similares de toda la región.</MatchText></p>
                    <figure><img alt="Ver" src="/images/interconexion-bgp/r107_es.png" /> </figure>
                    <p>
<MatchText id="match-90">Sistemas autónomos extranjeros (upstream) que más países de la región conectan:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-91">AS 12956 (TELXIUS, Telefónica) conecta a 20 países.</MatchText></li>
                        <li>
<MatchText id="match-92">AS 6939 (Hurricane Electric LLC, Hurricane) conecta a 19 países.</MatchText></li>
                        <li>
<MatchText id="match-93">AS 174 (Cogent Communications, Cogent-174) conecta a 18 países.</MatchText></li>
                        <li>
<MatchText id="match-94">AS 23520 (Columbus Networks USA, Inc., Columbus-Networks) conecta a 18 países.</MatchText></li>
                        <li>
<MatchText id="match-95">AS 1299 (Telia Carrier, Telianet) conecta a 17 países.</MatchText></li>
                        <li>
<MatchText id="match-96">AS 6762 (Telecom Italia Sparkle S. p. A., Seabone-Net) conecta a 15 países.</MatchText></li>
                        <li>
<MatchText id="match-97">AS 3356 (Level 3 Parent, LLC, Level 3) conecta a 14 países.</MatchText></li>
                        <li>
<MatchText id="match-98">AS 1239 (Sprint, Sprintlink) conecta a 14 países.</MatchText></li>
                        <li>
<MatchText id="match-99">AS 52468 (Ufinet Panama S. A.) conecta a 12 países.</MatchText></li>
                        <li>
<MatchText id="match-100">AS 3549 (Level 3 Parent, LLC, LVLT-3549) y AS 2914 (NTT America, Inc., NTT-Communications-2914) conectan a 11 países cada uno.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-101">Sistemas autónomos extranjeros (upstream) que conectan a más sistemas autónomos de la región:</MatchText></p>
                    <ul>
                        <li>
<MatchText id="match-102">AS 6939 (Hurricane Electric LLC, Hurricane) conecta a 6736 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-103">AS 6762 (Telecom Italia Sparkle S. p. A., Seabone-Net) conecta a 5239 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-104">AS 3356 (Level 3 Parent, LLC, Level 3) conecta a 4734 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-105">AS 174 (Cogent Communications, Cogent-174) conecta a 4706 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-106">AS 1299 (Telia Carrier, Telianet) conecta a 4496 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-107">AS 12956 (Telxius, Telefónica) conecta a 2958 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-108">AS 3549 (Level 3 Parent, LLC, LVLT-3549) conecta a 2935 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-109">AS 50629 (LWL Com GmbH, LWL Com) conecta a 2624 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-110">AS 3257 (GTT, GTT-Backbone) conecta a 2536 sistemas autónomos.</MatchText></li>
                        <li>
<MatchText id="match-111">AS 39533 (Asympto Networks Kft., Asympto) conecta a 2470 sistemas autónomos.</MatchText></li>
                    </ul>
                    <p>
<MatchText id="match-112">En ambos rankings, predominan los sistemas autónomos de Europa y Estados Unidos. Solo el AS 52468 pertenece a la región (registrado en Panamá).</MatchText></p>

                    <h2>
<MatchText id="match-113">Conexión con las demás regiones</MatchText></h2>
                    <p>
<MatchText id="match-114">A partir de los datos procesados, también es posible conocer hacia qué otras regiones se conectan los países latinoamericanos y evaluar así si el intercambio de tráfico local es adecuado.</MatchText></p>

                    <figure><img alt="Ver" id="r108" src="/images/interconexion-bgp/r108_es.png" /><figcaption>
<MatchText id="match-115">Figura 3: Flujo de los prefijos anunciados hacia afuera de cada país</MatchText></figcaption>  </figure>
                    <p>
<MatchText id="match-116">En la figura 3, podemos observar cómo la mayoría de los prefijos registrados en LACNIC se anuncian hacia las regiones cubiertas por ARIN (América del Norte) y RIPE NCC (Europa).</MatchText></p>
                    <p>
<MatchText id="match-117">En cuanto a las demás regiones, podemos ver que solo 2 países se conectan directamente con AFRINIC (África): Brasil y Colombia. En el caso de APNIC (Asia y el Pacífico), tenemos 7 países que se conectan directamente: Brasil, México, Argentina, Guatemala, Venezuela, Colombia y Ecuador.</MatchText></p>
                    <p>
<MatchText id="match-118">Por último, si bien la mayoría de los países tienen enlaces con otras naciones latinoamericanas, la cantidad de sistemas autónomos conectados es considerablemente más baja que para los casos de ARIN y RIPE NCC. Esto nos demuestra que los países de nuestra región suelen estar fragmentados con poco tráfico entre ellos, lo que, probablemente, se traduce en mayor latencia, ya que es posible que, en muchos casos, la conexión entre dos usuarios latinoamericanos deba pasar por un enlace de Estados Unidos o Europa.</MatchText></p>
                    <p>
<MatchText id="match-119">El mismo análisis se puede realizar procesando los sistemas autónomos conectados hacia las demás regiones, en vez de contar la cantidad de prefijos anunciados.</MatchText></p>
                    <p >
<MatchText id="match-120">En la figura 4, podemos observar la misma tendencia que en la figura 3. Pero aquí se puede contemplar aún más cómo predomina Brasil frente a los demás países de la región. Si continuamos analizando ese estado, podemos ver cómo las regiones de ARIN y RIPE NCC reciben más del triple de redes a conectar respecto de lo que reciben las regiones de AFRINIC, APNIC y LACNIC.</MatchText></p>
                    <p >
<MatchText id="match-121">Dentro del dominio de ARIN y RIPE NCC para el tráfico upstream de los países de nuestra región, podemos destacar que todos los países latinoamericanos tienen al menos un AS conectado a ARIN, menos Guyana Francesa (FR). En el caso de Europa, los únicos territorios sin conexión directa son Surinam (SR), Islas Malvinas (FK) y Guyana (GY).</MatchText></p>

                    <figure>
                        <img alt="Ver" id="r109" src="/images/interconexion-bgp/r109_es.png" />
                        <figcaption>
<MatchText id="match-122">Figura 4: Flujo de los sistemas autónomos que se conectan hacia afuera sus países</MatchText></figcaption>
                    </figure>
                </>}
                {/* inglés */ myLang === 'en' && <>
                    <h2>
<MatchText id="match-88">Regional Data Analysis</MatchText></h2>

                    <p>
<MatchText id="match-89">Just as we calculated the data by country, we can calculate similar data for the region as a whole.</MatchText></p>
                    <figure>
                        <img alt="Ver" src="/images/interconexion-bgp/r107_en.png" />
                    </figure>
                    <p>
<MatchText id="match-90">Autonomous systems abroad (upstream) connecting to the largest number of countries in the region:</MatchText></p>

                    <ol>
                        <li>
<MatchText id="match-91">AS 12956 (TELXIUS, Telefonica) connects to 20 countries.</MatchText></li>
                        <li>
<MatchText id="match-92">AS 6939 (TELXIUS, Telefonica) connects to 19 countries.</MatchText></li>
                        <li>
<MatchText id="match-93">AS 174 (Cogent Communications, COGENT-174) connects to 18 countries.</MatchText></li>
                        <li>
<MatchText id="match-94">AS 23520 (Columbus Networks USA, Inc., COLUMBUS-NETWORKS) connects to 18 countries.</MatchText></li>
                        <li>
<MatchText id="match-95">AS 1299 (Telia Carrier, TELIANET) connects to 17 countries.</MatchText></li>
                        <li>
<MatchText id="match-96">AS 6762 (TELECOM ITALIA SPARKLE S.p.A., SEABONE-NET) connects to 15 countries.</MatchText></li>
                        <li>
<MatchText id="match-97">AS 3356 (Level 3 Parent, LLC, LEVEL3) connects to 14 countries.</MatchText></li>
                        <li>
<MatchText id="match-98">AS 1239 (Sprint, SPRINTLINK) connects to 14 countries.</MatchText></li>
                        <li>
<MatchText id="match-99">AS 52468 (UFINET PANAMA S.A.) connects to 12 countries.</MatchText></li>
                        <li>
<MatchText id="match-100">AS 3549 (Level 3 Parent, LLC, LVLT-3549) and AS 2914 (NTT America, Inc., NTT-COMMUNICATIONS2914) each connect to 11 countries.</MatchText></li>
                    </ol>

                    <p>
<MatchText id="match-101">Autonomous systems abroad (upstream) connecting to the largest number of autonomous systems in the region:</MatchText></p>

                    <ol>
                        <li>
<MatchText id="match-102">AS 6939 (Hurricane Electric LLC, HURRICANE) connects to 6,736 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-103">AS 6762 (TELECOM ITALIA SPARKLE S.p.A., SEABONE-NET) connects to 5,239 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-104">AS 3356 (Level 3 Parent, LLC, LEVEL3) connects to 4,734 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-105">AS 174 (Cogent Communications, COGENT-174) connects to 4,706 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-106">AS 1299 (Telia Carrier, TELIANET) connects to 4,496 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-107">AS 12956 (TELXIUS, Telefonica) connects to 2,958 autonomous systems.</MatchText></li>
                        <li>
<MatchText id="match-108">AS 3549 (Level 3 Parent, LLC, LVLT-3549) connects to 2,935 autonomous systems.</MatchText></li>
<li> <MatchText id="match-109"> AS 50629 (LWL Com GmbH, LWL Com) connects to 2,624 autonomous systems.</MatchText></li>
<li><MatchText id="match-110">AS 3257 (GTT, GTT-Backbone) connects to 2,536 autonomous systems.</MatchText></li>
<li><MatchText id="match-111">AS 39533 (Asympto Networks Kft., Asympto) connects to 2,470 autonomous systems.</MatchText></li>
                    
                    </ol>
                    <p>
<MatchText id="match-112"> Both rankings are dominated by autonomous systems in Europe and the United States. Only AS 52468 belongs to the region (it is registered in Panama).</MatchText></p>

                    <h2>
<MatchText id="match-113">Connection to the Other Regions</MatchText></h2>

                    <p>
<MatchText id="match-114">The processed data also allows us to determine to which other regions the countries of Latin America are connected and therefore assess whether local traffic exchange is adequate.</MatchText></p>

                    <figure>
                        <img alt="Ver" id="r108" src="/images/interconexion-bgp/r108_es.png" />
                        <figcaption>
<MatchText id="match-115">Figure 3: Flow of the prefixes announced outside each country.</MatchText></figcaption>
                    </figure>
                    <p>
<MatchText id="match-116">Figure 3 shows how most of the prefixes registered with LACNIC are announced to the regions serviced by ARIN (North America) and the RIPE NCC (Europe).</MatchText></p>

                    <p>
<MatchText id="match-117">  As for the other regions, it shows that only two countries &mdash;Brazil and Colombia&mdash; connect directly with AFRINIC (Africa). Likewise, seven countries &mdash; Brazil, Mexico, Argentina, Guatemala, Venezuela, Colombia and Ecuador&mdash; connect directly to APNIC (Asia Pacific).</MatchText></p>

                    <p>
<MatchText id="match-118">Finally, while most of the countries have links with other countries in Latin America, the number of connected autonomous systems is considerably lower than for the case of ARIN and the RIPE NCC. This shows that the countries of our region are often fragmented and that there is little traffic between them. This probably translates into higher latency, as the connection between two Latin American users must often transit through links in the United States or Europe.</MatchText></p>

                    <p>
<MatchText id="match-119">Instead of counting the number of announced prefixes, the same analysis can be made by processing the autonomous systems connected to other regions.</MatchText></p>

                    <p>
<MatchText id="match-120">The same trend can be observed in Figures 3 and 4. In Figure 4, however, it is even more evident that Brazil dominates over all the other countries of the region. If we continue to analyze Brazil, we can see how the ARIN and the RIPE NCC service regions receive more than three times as many networks compared to AFRINIC, APNIC and LACNIC.</MatchText></p>
                    <p>
<MatchText id="match-121">
                        As Figure 4 shows, all the countries of Latin America except French Guiana (FR) have at least one AS connected to an AS in the ARIN region, while the only territories not directly connected to the RIPE NCC region are Suriname (SR), the Falkland Islands (FK) and Guyana (GY).</MatchText></p>

                    <figure>
                        <img alt="Ver" id="r109" src="/images/interconexion-bgp/r109_es.png" />
                        <figcaption>
<MatchText id="match-122">Figure 4: Flow of autonomous systems connecting outside the borders of their countries.</MatchText></figcaption>
                    </figure>
                </>}
                {/* portugués */myLang === 'pt' && <>
                    <h2>
<MatchText id="match-88">An&aacute;lise dos dados a n&iacute;vel regional</MatchText></h2>

                    <p>
<MatchText id="match-89">De forma an&aacute;loga aos dados calculados por pa&iacute;s, podemos calcular dados semelhantes para toda a regi&atilde;o.</MatchText></p>

                    <figure><img alt="Ver" src="/images/interconexion-bgp/r107_es.png" /></figure>

                    <p>
<MatchText id="match-90">Sistemas aut&ocirc;nomos estrangeiros (upstream) que mais pa&iacute;ses da regi&atilde;o conectam:</MatchText></p>

                    <ol>
                        <li>
<MatchText id="match-91">AS 12956 (TELXIUS, Telef&ocirc;nica) conecta 20 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-92">AS 6939 (Hurricane Electric LLC, HURRICANE) conecta 19 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-93">AS 174 (Cogent Communications, COGENT-174) conecta 18 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-94">AS 23520 (Columbus Networks USA, Inc., COLUMBUS-NETWORKS) conecta 18 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-95">AS 1299 (Telia Carrier, TELIANET) conecta 17 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-96">AS 6762 (TELECOM ITALIA SPARKLE S.p.A., SEABONE-NET) conecta 15 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-97">AS 3356 (Level 3 Parent, LLC, LEVEL3) conecta 14 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-98">AS 1239 (Sprint, SPRINTLINK) conecta 14 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-99">AS 52468 (UFINET PANAMA S.A.) conecta 12 pa&iacute;ses.</MatchText></li>
                        <li>
<MatchText id="match-100">AS 3549 (Level 3 Parent, LLC, LVLT-3549) y AS 2914 (NTT America, Inc., NTT-COMMUNICATIONS2914) conectam 11 pa&iacute;ses cada um</MatchText></li>
                    </ol>

                    <p>
<MatchText id="match-101">Sistemas aut&ocirc;nomos estrangeiros (upstream) que mais sistemas aut&ocirc;nomos da regi&atilde;o conectam:</MatchText></p>

                    <ul>
                        <li>
<MatchText id="match-102">AS 6939 (Hurricane Electric LLC, HURRICANE) conecta 6736 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-103">AS 6762 (TELECOM ITALIA SPARKLE S.p.A., SEABONE-NET) conecta 5239 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-104">AS 3356 (Level 3 Parent, LLC, LEVEL3) conecta 4734 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-105">AS 174 (Cogent Communications, COGENT-174) conecta 4706 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-106">AS 1299 (Telia Carrier, TELIANET) conecta a 4496 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-107">AS 12956 (TELXIUS, Telefonica) conecta 2958 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-108">AS 3549 (Level 3 Parent, LLC, LVLT-3549) conecta 2935 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-109">AS 50629 (LWLcom GmbH, LWLCOM) conecta 2624 sistemas aut&ocirc;nomos.</MatchText></li>
                        <li>
<MatchText id="match-110">AS 3257 (GTT, GTT-BACKBONE) conecta 2536 sistemas aut&ocirc;nomos. 62</MatchText></li>
                        <li>
<MatchText id="match-111">AS 39533 (Asympto Networks Kft., asympto) conecta 2470 sistemas aut&ocirc;nomos.</MatchText></li>
                    </ul>

                    <p>
<MatchText id="match-112">Em ambos os rankings, os sistemas aut&ocirc;nomos da Europa e dos Estados Unidos predominam. Somente o AS 52468 pertence &agrave; regi&atilde;o (registrado no Panam&aacute;).&nbsp;</MatchText></p>

                    <h2>
<MatchText id="match-113">Conex&atilde;o com as outras regi&otilde;es</MatchText></h2>

                    <p>
<MatchText id="match-114">A partir dos dados processados, tamb&eacute;m &eacute; poss&iacute;vel conhecer com que outras regi&otilde;es os pa&iacute;ses da Am&eacute;rica Latina se conectam e assim avaliar se a troca de tr&aacute;fego local &eacute; adequada.</MatchText></p>



                    <figure>
                        <img alt="Ver" id="r108" src="/images/interconexion-bgp/r108_es.png" />
                        <figcaption>
<MatchText id="match-115">Figura 3: Fluxo dos prefixos anunciados para fora de cada pa&iacute;s.</MatchText></figcaption>
                    </figure>

                    <p>
<MatchText id="match-116">Na figura 3, podemos ver como a maioria dos prefixos registrados no LACNIC s&atilde;o anunciados para as regi&otilde;es cobertas por ARIN (Am&eacute;rica do Norte) e RIPE NCC (Europa).</MatchText><br />
<MatchText id="match-117">

                        Quanto &agrave;s outras regi&otilde;es, podemos ver que apenas 2 pa&iacute;ses se conectam diretamente com AFRINIC (&Aacute;frica): Brasil e Col&ocirc;mbia. No caso de APNIC (&Aacute;sia e o Pac&iacute;fico), h&aacute; 7 pa&iacute;ses que se conectam diretamente: Brasil, M&eacute;xico, Argentina, Guatemala, Venezuela, Col&ocirc;mbia e Equador.
                    </MatchText><br />
<MatchText id="match-118">
                            Finalmente, embora a maioria dos pa&iacute;ses tenha liga&ccedil;&otilde;es com outros pa&iacute;ses da Am&eacute;rica Latina, o n&uacute;mero de sistemas aut&ocirc;nomos conectados &eacute; consideravelmente menor do que para os casos de ARIN e RIPE NCC. Isso monstra que os pa&iacute;ses da nossa regi&atilde;o, costumam estar fragmentados com pouco tr&aacute;fego entre eles. O que provavelmente se traduz em maior lat&ecirc;ncia, j&aacute; que em muitos casos, &eacute; poss&iacute;vel que a conex&atilde;o entre dois usu&aacute;rios da Am&eacute;rica Latina deva passar por um link dos Estados Unidos ou da Europa.
                            A mesma an&aacute;lise pode ser feita processando sistemas aut&ocirc;nomos conectados &agrave;s outras regi&otilde;es, ao inv&eacute;s de contar o n&uacute;mero de prefixos anunciados.&nbsp;
                        </MatchText><br />
<MatchText id="match-119">
                            Na figura 4, podemos observar a mesma tend&ecirc;ncia da figura 3.</MatchText><br />
<MatchText id="match-120">
                            Mas aqui pode-se ver ainda mais como o Brasil predomina em rela&ccedil;&atilde;o aos demais pa&iacute;ses da regi&atilde;o. Se continuarmos analisando o Brasil, podemos ver como as regi&otilde;es ARIN e RIPE NCC recebem mais do triplo de redes para conectar do que as regi&otilde;es AFRINIC, APNIC e LACNIC recebem.
                        </MatchText><br />
<MatchText id="match-121">
                            No dom&iacute;nio do ARIN e RIPE NCC para o tr&aacute;fego upstream dos pa&iacute;ses de nossa regi&atilde;o, podemos destacar que todos os pa&iacute;ses da Am&eacute;rica Latina possuem pelo menos um AS conectado ao ARIN, exceto a Guiana Francesa (FR).
                        </MatchText><br />
<MatchText id="match-122">
                            No caso da Europa, os &uacute;nicos territ&oacute;rios sem conex&atilde;o direta s&atilde;o: Suriname (SR), Ilhas Malvinas (FK) e Guiana (GY).&nbsp;</MatchText></p>

                    <figure><img alt="Ver" id="r109" src="/images/interconexion-bgp/r109_es.png" />
                        <figcaption>
<MatchText id="match-123">Figura 4: Flujo de los sistemas aut&ocirc;nomos que se conectan hacia afuera sus pa&iacute;ses.</MatchText></figcaption>
                    </figure></>}
            </div>
        </div>
    );
}

export default AnalisisRegional;