import React, {useContext} from 'react'
import Button from '../../../components/Button'
import {LangContext} from '../../../context/DataContext'

function Resumen() {
    const {myLang}=useContext(LangContext)
    return (
        <div>
            <div id="p1">
                 
                {myLang==='es'&& 
                <div className='alerts alert_white'> 
                    <p>Autor: Hugo Salgado</p>
                    <p>Mediciones y análisis histórico del comportamiento de los servidores raíz del DNS</p>
                    <Button color="button_blue" to="/reportes/interconexion/informe-rootservers-lac-es.pdf">Descargar (PDF)</Button>
                </div>
                }

                {myLang==='en'&& 
                <div className='alerts alert_white'> 
                    <p>Autor: Hugo Salgado</p>
                    <p>Measurements and a historical analysis of the behavior of DNS Root Servers.</p>
                    <Button color="button_blue" to="/reportes/interconexion/informe-rootservers-lac-en.pdf">Descargar (PDF)</Button>
                </div>
                }
                 
            </div>
        
        </div>
    )
}

export default Resumen

 