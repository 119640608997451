import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';
import ButtonMailto from '../../../components/ButtonMailto'

function Colaborar() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p6">
                {myLang === 'es' && <Contenido />}
                {myLang === 'en' && <Contenido />}
                {myLang === 'pt' && <Contenido />}
            </div>
        </div>
    )
}
function Contenido() {
    return (<>
        <h2><MatchText id="match-37">Cómo colaborar</MatchText> </h2>
        <p><MatchText id="match-38"> &iquest;Qu&eacute; informaci&oacute;n requerimos?</MatchText> </p>
        <p><MatchText id="match-39"> Para nuestro proyecto requerimos las tablas iBGP de cada router de borde (router que implemente sesiones eBGP con otros sistemas aut&oacute;nomos) del sistema aut&oacute;nomo. Necesitamos que dicha informaci&oacute;n se entregue indicando el nombre del router de el cual se extrajo la informaci&oacute;n, o un alias. El prop&oacute;sito es poder identificar qu&eacute; prefijos corresponden a cada router. Nosotros procesaremos las rutas aprendidas por eBGP de dichas tablas para realizar nuestro trabajo.</MatchText> </p>
        <p><MatchText id="match-40"> A continuaci&oacute;n presentamos los procedimientos para obtener la informaci&oacute;n requerida considerando rutas con y sin VRF dentro de las plataformas Junos y Cisco (IOS XR), en caso de requerir el procedimiento para otros fabricantes facilitaremos el procedimiento an&aacute;logo a los presentados. Recomendamos comprimir los archivos resultantes, que son de texto claro.</MatchText> </p>
        <p><MatchText id="match-41"> <em>Reemplazar &ldquo;ASBR&rdquo; por el nombre o alias del router que corresponda. Por ejemplo ASBR_v4 queda SP1_v4.</em></MatchText> </p>
        <h3><MatchText id="match-42">Paso 1: obtenci&oacute;n de los datos del router</MatchText> </h3>
        <h4><MatchText id="match-43">Caso Juniper</MatchText> </h4>
        <p><MatchText id="match-44"> Red con las rutas de Internet en una routing instance</MatchText> </p>
        <p><MatchText id="match-45"> &gt; show route table &lt;routing instance&gt;.inet.0 | save /var/tmp/&lt;ASBR_v4&gt;.txt</MatchText> </p>
        <p><MatchText id="match-46"> &gt; show route table &lt;routing instance&gt;.inet6.0 | save /var/tmp/&lt;ASBR_v6&gt;.txt</MatchText> </p>
        <p><MatchText id="match-47"> Red con las rutas de Internet en la tabla de ruteo global</MatchText> </p>
        <p><MatchText id="match-48"> &gt; show route table inet.0 | save /var/tmp/&lt;ASBR_v4&gt;.txt</MatchText> </p>
        <p><MatchText id="match-49"> &gt; show route table inet6.0 | save /var/tmp/&lt;ASBR_v6&gt;.txt</MatchText> </p>
        <h4><MatchText id="match-50">Caso Cisco</MatchText> </h4>
        <p><MatchText id="match-51"> Red con las rutas de Internet en una VRF</MatchText> </p>
        <p><MatchText id="match-52"> &gt; show bgp vrf &lt;vrf Internet&gt; | file &lt;ASBR_v4&gt;.txt</MatchText> </p>
        <p><MatchText id="match-53"> &gt; show bgp vrf &lt;vrf Internet&gt; | file &lt;ASBR_v6&gt;.txt</MatchText> </p>
        <p><MatchText id="match-54"> Red con las rutas de Internet en la tabla de ruteo global</MatchText> </p>
        <p><MatchText id="match-55"> &gt; show bgp | file &lt;ASBR_v4&gt;.txt</MatchText> </p>
        <p><MatchText id="match-56"> &gt; show bgp ipv6 unicast | file &lt;ASBR_v6&gt;.txt</MatchText> </p>
        <h3><MatchText id="match-57"> Paso 2: descargar el archivo a la computadora local y subir al servidor de LACNIC</MatchText> </h3>
        <p><MatchText id="match-58"> Al momento de descargar el archivo, podemos realizarlo por scp, ftp o el protocolo que tengamos permitido en nuestro servidor de archivos.</MatchText> </p>
        <h4><MatchText id="match-59">Juniper</MatchText> </h4>
        <p><MatchText id="match-60">Desde el Servidor local</MatchText> </p>
        <p><MatchText id="match-61">scp &lt;ASBR&gt;:/var/tmp/&lt;ASBR_v4&gt;.txt /&lt;path destino en el servidor&gt;</MatchText> <br /><MatchText> scp &lt;ASBR&gt;:/var/tmp/&lt;ASBR_v6&gt;.txt /&lt;path destino en el servidor&gt;</MatchText> </p>
        <h4><MatchText id="match-62">Cisco</MatchText> </h4>
        <p><MatchText id="match-63"> Desde el ASBR Cisco</MatchText> </p>
        <p><MatchText id="match-64"> copy harddisk:&lt;ASBR_v4&gt;.txt ftp:://&lt;usuario&gt;@&lt;IP servidor FTP&gt;:&lt;ASBR_v4&gt;</MatchText> </p>
        <p><MatchText id="match-65"> copy harddisk:&lt;ASBR_v6&gt;.txt ftp:://&lt;usuario&gt;@&lt;IP servidor FTP&gt;:&lt;ASBR_v6&gt;</MatchText> </p>
        <h4><MatchText id="match-66">Compresi&oacute;n de los archivos</MatchText> </h4>
        <p><MatchText id="match-67"> Si estamos en un Sistema Operativo Linux, tomamos el directorio donde tengamos todos los archivos recolectados y ejecutamos el siguiente comando.</MatchText> </p>
        <p><MatchText id="match-68"> tar -czvf &lt;ASN del ISP&gt;.gz &lt;directorio donde se encuentran todos los archivos&gt;</MatchText> </p>
        <p><MatchText id="match-69"> sftp</MatchText> </p>
        <h4><MatchText id="match-70">Entrega de los archivos</MatchText> </h4>
        <p><MatchText id="match-71"> Para realizar la entrega de los archivos solicitamos se comuniquen con </MatchText><ButtonMailto label="tecnologia [at] lacnic [.] net" mailto="mailto:tecnologia@lacnic.net"  /><MatchText>. La informaci&oacute;n brindada va a ser utilizada &uacute;nicamente de forma acad&eacute;mica y los datos obtenidos por la investigaci&oacute;n van a ser difundidos de manera gen&eacute;rica sin brindar datos espec&iacute;ficos de las empresas que colaboraron.</MatchText> </p>

    </>)
}
export default Colaborar
