import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css';
import '../../../css/react-ctrl-f.css'; 
import Aproximacion from "./Aproximacion";
import Colaborar from "./Colaborar";
import Descripcion from "./Descripcion";
import EstadoDelTrabajo from "./EstadoDelTrabajo";
import LLamadoAccion from "./LLamadoAccion";
import Referencias from "./Referencias";
import Situacion from "./Situacion";
import Resumen from "./Resumen";

function OptimizacionRedesBgp() {
    return (<> 
        <ReportTitle/>
        <Resumen />
        <Descripcion /> 
        <Situacion /> 
        <Aproximacion /> 
        <EstadoDelTrabajo /> 
        <LLamadoAccion /> 
        <Colaborar /> 
        <Referencias /> 
     </>)
}
function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
      <h1>
          {myLang === 'es' && 'Optimización de redes mediante un mejor iBGP'} 
          {myLang === 'en' && 'Optimización de redes mediante un mejor iBGP'} 
          {myLang === 'pt' && 'Optimización de redes mediante un mejor iBGP'} 
      </h1>
      )
  }
export default OptimizacionRedesBgp
