import React from "react";
import MasterState from "./context/MasterState";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"; 
import "./index.css";
import App from "./App";  
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
     
      <BrowserRouter> 
          <ScrollToTop />
            <MasterState> 
              <App /> 
            </MasterState>
      </BrowserRouter> 
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 
