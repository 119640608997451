import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext';

export default function Gobernanza() {

  return (
    <div id='p4'>
      <Title />
      <Table />
    </div>
  )
}


function Title() {
  const { myLang } = useContext(LangContext)
  return (
    <h2>
      {myLang === 'es' && 'Gobernanza'}
      {myLang === 'en' && 'Governance'}
      {myLang === 'pt' && 'Gobernança'}
    </h2>
  )
}

function Table() {
  const { myLang } = useContext(LangContext)
  return (
    <table className='table'>
      {myLang === 'es' &&
        <tbody>
          <tr>
            <th>Pr&aacute;cticas Recomendadas como Mejores Pr&aacute;cticas</th>
            <th>Enlaces de Respaldo</th>
          </tr>
          <tr>
            <td>Establecer una gobernabilidad para el IXP, que sea abierta, participativa, con toma de decisiones por consenso siempre que sea posible.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer una gobernabilidad para el IXP, que sea transparente, responsable, inclusiva y equitativa, con una toma de decisiones ascendente que no perjudique a ninguna categor&iacute;a de partes interesadas.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Apoyar la interoperabilidad global y una arquitectura abierta y colaborativa.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer una gobernabilidad para el IXP, que sea distribuida y colaborativa, en un ecosistema descentralizado y multipartito que fomenta los enfoques colaborativos y cooperativos.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover liderazgo pol&iacute;tico de alto nivel para desarrollar una estrategia y un plan claros y bien comunicados sobre pol&iacute;ticas de infraestructura y capacidad humana que fomenten un ecosistema propicio para el desarrollo de Internet.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover activamente un enfoque multipartito de la elaboraci&oacute;n de pol&iacute;ticas, que garantice que todas las partes interesadas tengan voz, que los procesos sean abiertos y responsables y que los problemas se aborden con la aportaci&oacute;n y el apoyo de la comunidad.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover que la regulaci&oacute;n de las comunicaciones adopte un enfoque multipartito, especialmente en entornos en r&aacute;pida evoluci&oacute;n en los que la regulaci&oacute;n o las pol&iacute;ticas tradicionales de arriba abajo pueden no anticiparse a los cambios o no reaccionar con rapidez. por ejemplo, en cuestiones emergentes como la neutralidad de la red o el zero-rating.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover la claridad jur&iacute;dica y pol&iacute;tica para los desarrolladores de contenidos locales, los proveedores de alojamiento, las CDN y los centros de datos.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Cumplir con la normativa nacional en cuanto a operaci&oacute;n y mantenerse actualizado en estas normativas.&nbsp;</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer un marco regulatorio o pol&iacute;ticas de funcionamiento del IXP que se encuentre p&uacute;blico.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover un enfoque de seguridad colaborativo para aumentar la confianza generalizada en Internet, junto con leyes de protecci&oacute;n de datos para garantizar la privacidad de los datos sensibles que sustentan los nuevos servicios financieros, sanitarios y de otro tipo que utilizan datos personales.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer la organizaci&oacute;n del IXP con autonom&iacute;a jur&iacute;dica, contituy&eacute;ndose de manera independiente, para contar con amplia capacidad en la toma de desiciones sin influencias externas y con resultados neutrales, es decir, donde las partes interesadas involucradas act&uacute;en sin favoritismos ni sesgos hacia ning&uacute;n participante, esto con el fin de garantizar una mejor distribuci&oacute;n de los beneficios y una relaci&oacute;n directa con la necesidad de fomentar la colaboraci&oacute;n y la confianza entre los diferentes participantes. La utonom&iacute;a jur&iacute;dica en un IXP reduce significativamente las preocupaciones sobre la competencia.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/ixps/">ISOC</a></td>
          </tr>
        </tbody>

      }
      {myLang === 'en' &&
        <tbody>
          <tr>
            <th>Recommended Best Practices</th>

            <th>Supporting links</th>
          </tr>
          <tr>
            <td>Establish governance for the IXP that is open, participative, with consensus-driven decision-making where possible.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Establish governance for the IXP that is transparent, accountable, inclusive, and equitable, with bottom-up decision-making that doesn't disadvantage any category of stakeholder.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Support global interoperability and an open and collaborative architecture.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Establish governance for the IXP that is distributed and collaborative, in a decentralized and multistakeholder ecosystem that encourages collaborative and cooperative approaches.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promote high-level political leadership to develop a clear and well-communicated strategy and plan about specific policies on infrastructure and human capacity that will support an enabling environment for Internet development.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Actively promote a multistakeholder approach to policy development that ensures that all stakeholders have a voice, that processes are open and accountable, and that issues are addressed with community input and support.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Encourage communications regulations to adopt a multistakeholder approach, particularly in rapidly evolving environments where traditional top-down regulations or policies may not anticipate changes or react quickly enough, for example, on emerging issues such as net neutrality or zero-rating.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promote legal and policy clarity for local content developers, hosting providers, CDNs, and datacenters.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Comply with national regulations regarding operations and stay updated on these regulations.&nbsp;</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Establish a publicly available regulatory framework or publicly available operating policies for the IXP.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad">ISOC</a></td>
          </tr>
          <tr>
            <td>Promote a collaborative security approach to increase broad trust in the Internet, along with data protection laws to ensure privacy for sensitive data that underpin new financial, health, and other services using personal data.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>The IXP should be independently constituted and have legal autonomy, with ample decision-making capacity independent of outside influences and with neutral results, i.e., all stakeholders involved should act without favoritism or bias towards any participant. This will guarantee a better distribution of benefits and a direct relationship with the need to foster collaboration and trust between the various participants. An IXP's legal autonomy significantly alleviates concerns about competition.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/ixps/">ISOC</a></td>
          </tr>
        </tbody>
      }
      {myLang === 'pt' &&
        <tbody>
          <tr>
            <th>Práticas recomendadas como melhores práticas</th>

            <th>Links de backup</th>
          </tr>
          <tr>
            <td>Estabelecer uma governan&ccedil;a para o IXP que seja aberta, participativa, com tomada de decis&otilde;es por consenso sempre que poss&iacute;vel.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer uma governan&ccedil;a para o IXP que seja transparente, respons&aacute;vel, inclusiva e equitativa, com uma tomada de decis&otilde;es ascendente que n&atilde;o prejudique nenhuma categoria de partes interessadas.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Apoiar a interoperabilidade global e uma arquitetura aberta e colaborativa.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer uma governan&ccedil;a para o IXP que seja distribu&iacute;da e colaborativa, em um ecossistema descentralizado e com m&uacute;ltiplas partes interessadas que incentive as abordagens colaborativas e cooperativas.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover a lideran&ccedil;a pol&iacute;tica de alto n&iacute;vel para desenvolver uma estrat&eacute;gia e um plano claros e bem comunicados sobre pol&iacute;ticas de infraestrutura e capacidade humana que promovam um ecossistema prop&iacute;cio ao desenvolvimento da Internet.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover ativamente uma abordagem multilateral ao desenvolvimento de pol&iacute;ticas, que garanta que todas as partes interessadas tenham voz, que os processos sejam abertos e respons&aacute;veis ​​e que os problemas sejam abordados com a contribui&ccedil;&atilde;o e o apoio da comunidade.</td>
            <td><a href="https://www.internetsociety.org/resources/doc/2016/internet-governance-why-the-multistakeholder-approach-works/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover a regulamenta&ccedil;&atilde;o das comunica&ccedil;&otilde;es para adotar uma abordagem multilateral, principalmente em ambientes em r&aacute;pida evolu&ccedil;&atilde;o, em que a regulamenta&ccedil;&atilde;o ou as pol&iacute;ticas tradicionais de cima para baixo podem n&atilde;o antecipar mudan&ccedil;as ou n&atilde;o reagir rapidamente, por exemplo, em quest&otilde;es emergentes como a neutralidade da rede ou a classifica&ccedil;&atilde;o zero.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover a clareza jur&iacute;dica e pol&iacute;tica para os desenvolvedores de conte&uacute;do local, os provedores de hospedagem, as CDN e os centros de dados.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Cumprir com a normativa nacional no momento da opera&ccedil;&atilde;o e manter-se atualizado nessas normativas.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer um quadro regulamentar ou pol&iacute;ticas operacionais do IXP que seja p&uacute;blico.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover uma abordagem de seguran&ccedil;a colaborativa para aumentar a confian&ccedil;a generalizada na Internet, juntamente com leis de prote&ccedil;&atilde;o de dados para garantir a privacidade dos dados sens&iacute;veis que sustentam os novos servi&ccedil;os financeiros, de sa&uacute;de e outros servi&ccedil;os que usam dados pessoais.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer a organiza&ccedil;&atilde;o do IXP com autonomia jur&iacute;dica, constituindo-se de forma independente, para ter ampla capacidade na tomada de decis&otilde;es sem influ&ecirc;ncias externas e com resultados neutros, ou seja, onde as partes interessadas envolvidas atuem sem favoritismo ou preconceito para com qualquer participante, isto de forma a garantir uma melhor distribui&ccedil;&atilde;o dos benef&iacute;cios e uma rela&ccedil;&atilde;o direta com a necessidade de fomentar a colabora&ccedil;&atilde;o e a confian&ccedil;a entre os diferentes participantes. A autonomia legal em um IXP reduz significativamente as preocupa&ccedil;&otilde;es com a concorr&ecirc;ncia.</td>
            <td><a href="https://www.internetsociety.org/policybriefs/ixps/">ISOC</a></td>
          </tr>
        </tbody>
      }
    </table>
  )
}
