import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function V1EquiposCPE(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020)</h1>  
                    <Button color="button_blue" to="/es/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-1-2020">Leer reporte</Button>
                </>}
                {myLang === 'en' && <> 
                    <h1>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020)</h1>  
                    <Button color="button_blue" to="/reportes/ipv6/lacnic-informe-cpe-ipv6.pdf">Download (PDF)</Button>
                </>}
                {myLang === 'pt' && <> 
                    <h1>Relatório sobre suporte do IPv6 em equipamentos CPE - Versión 1 (2020) </h1>  
                    <Button color="button_blue" to="/reportes/ipv6/lacnic-informe-cpe-ipv6.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div> 
    );
}

export default V1EquiposCPE;