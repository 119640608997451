import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Introduccion(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                 { myLang === 'es' && 
                 <>
                    <h2>Introducci&oacute;n</h2>
                    <p>El agotamiento de IPv4 es un hecho y las empresas deben adaptarse a los cambios para seguir siendo competitivas.</p>
                    <p>IPv4 e IPv6 por definici&oacute;n no son compatibles por lo que es imprescindible el despliegue de IPv6 junto con alg&uacute;n mecanismo de transici&oacute;n que permita dar continuidad y conectividad a dispositivos y servicios que a&uacute;n contin&uacute;an funcionando solo en IPv4 ante el agotamiento del espacio de direccionamiento p&uacute;blico IPv4.</p>
                    <p>En base a esto, LACNIC desea conocer cu&aacute;les son los mecanismos de transici&oacute;n actualmente recomendados y su soporte dentro de los CPEs m&aacute;s utilizados en la regi&oacute;n.</p>
                 </>
                 }
            </div>
        </div>
    );
}

export default Introduccion;