import React, {useContext} from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function OperadoresDespliegueIPv6(props) {
    const { myLang } = useContext( LangContext )
    return ( 
        <div>
            <div> 
                { myLang === 'es' && <> 
                    <h1>El comportamiento de operadores en el despliegue de IPv6 en América Latina y el Caribe</h1> 
                    <Button color="button_blue" to="/reportes/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-es.pdf">Descargar (PDF)</Button>
                </>}
                { myLang === 'en' && <> 
                    <h1>Operators’ Behavior In Relation To IPv6 Deployment In Latin America And The Caribbean</h1> 
                    <Button color="button_blue" to="/reportes/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-en.pdf">Download (PDF)</Button>
                </>}
                { myLang === 'pt' && <> 
                    <h1>O comportamento das operadoras na implementação do IPv6 na América Latina e o Caribe</h1> 
                    <Button color="button_blue" to="/reportes/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-pt.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div> 
    );
}

export default OperadoresDespliegueIPv6;