import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css'; 
import Alcance from "./Alcance";
import Conclusiones from "./Conclusiones";
import Consideraciones from './Consideraciones';
import ConsultaFabricantesCPEs from "./ConsultaFabricantesCPEs";
import EncuestaISPs from "./EncuestaISPs";
import Introduccion from "./Introduccion";
import IPv4AndServices from './IPv4AndServices'
import MecanismosDeTransicion from './MecanismosDeTransicion'
import Motivo from "./Motivo";
import Objetivo from "./Objetivo";
import RecoleccionInformacion from "./RecoleccionInformacion";
import Referencias from "./Referencias";
import Resumen from "./Resumen";
import V1EquiposCPE from "../../IPv6/V1EquiposCPE";

export default function IPv6EnEquiposCPE() {
 
    return ( 
      <> 
        <ReportTitle />
        <Resumen/>
        <Objetivo />
        <Motivo />
        <Alcance />
        <Introduccion />
        <Consideraciones />
        <MecanismosDeTransicion />
        <IPv4AndServices />
        <RecoleccionInformacion />
        <EncuestaISPs/>
        <ConsultaFabricantesCPEs />
        <Conclusiones />
        <Referencias />
      </>
    )
}
 
function ReportTitle() {
  const { myLang } = useContext(LangContext)
  return (
    <h1>
        {myLang === 'es' && 'Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020)'}
        {myLang === 'en' && <V1EquiposCPE />}
        {myLang === 'pt' && <V1EquiposCPE />}
    </h1>
    )
}