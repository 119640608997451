import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext'; 

function Conclusiones(props) {
    const { myLang } = useContext(LangContext)
 
     

    
    return (
        <div>
            <div id="p12">
                {  myLang === 'es' && <>
                    <h2>Conclusi&oacute;n final</h2> 
                <div className='textoprueba'> 
                    <p>Luego de este trabajo de investigaci&oacute;n sobre  despliegue de CPEs con soporte de IPv6 en la regi&oacute;n y sus mecanismos de  transici&oacute;n soportados donde se realizaron encuestas a ISPs y consultas a  fabricantes, podemos concluir:</p>
                    <ul>
                        <li><strong>Estado de Despliegue de IPv6</strong>: De los ISPs que completaron la encuesta, el 67.4% est&aacute; brindando IPv6 a sus clientes. Parece ser un n&uacute;mero elevado de despliegue pero es claro que hubo una tendencia superior a colaborar por parte de estos que de los que no est&aacute;n brindando.</li>
                        <li><strong>Mecanismo de Transici&oacute;n L&iacute;der</strong>: La informaci&oacute;n brindada por los ISPs y la de los fabricantes coincide en que el mecanismo d  transici&oacute;n l&iacute;der es <strong>DS-Lite</strong>. Esto permite poder realizar acciones de:</li>
                        <ul>
                            <li><strong>Capacitaci&oacute;n a especialistas</strong>: informando c&oacute;mo  realizar una implementaci&oacute;n de dicho mecanismo.</li>
                            <li><strong>Estrategia para tomadores de decisiones</strong>: permitiendo que vean la oportunidad que tienen de realizar el despliegue de este mecanismo de transici&oacute;n al ya tener dispositivos que lo soportan.</li>
                        </ul>
                        <li><strong>Fabricantes con mayor presencia</strong>: De acuerdo a  las encuestas a los ISPs, se observa que <strong>Huawei y ZTE</strong> son los fabricantes con mayor presencia en los ISPs con un 25% y 20%  respectivamente. Esto abre la puerta a buscar oportunidades colaboraci&oacute;n con dichos fabricantes en el despliegue de mecanismos de transici&oacute;n al tener una gran presencia en el mercado.</li>
                        <li><strong>Fabricante m&aacute;s innovador</strong>: encontramos a Technicolor como el fabricante m&aacute;s innovador al presentar soporte en la mayor&iacute;a  de los mecanismos de transici&oacute;n esperados.</li>
                    </ul></div></>
            }
            </div>
        </div>
    )
}

export default Conclusiones
