import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'


function Motivacion(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <>
                    <h2><MatchText id="match-12">Motivación</MatchText></h2>
                    <p><MatchText id="match-13">Los ataques DDoS de amplificación son de los más frecuentes que sufren las organizaciones hoy en día. Aprovechan dos fallas en los protocolos de red:</MatchText></p>
                    <ul>
                        <li><MatchText id="match-14">La información del encabezado IP no está autenticada.</MatchText></li>
                        <li><MatchText id="match-15">UDP no emplea un handshaking.</MatchText></li>
                    </ul>
                    <p><MatchText id="match-16">Estos ataques tienen gran impacto en la comunidad, porque presentan grandes ventajas a los atacantes. En primer lugar, al usar servicios vulnerables como reflectores de tráfico, ocultan inherentemente la dirección del atacante, lo que dificulta la detección del origen del ataque y las posibles acciones de mitigación.</MatchText></p>
                    <p><MatchText id="match-17">En segundo lugar, pequeñas solicitudes a distintos reflectores suelen ser suficientes para desencadenar grandes respuestas. Esto puede ser utilizado para generar y dirigir grandes volúmenes de tráfico hacia una organización víctima objetivo. Dependiendo del protocolo del que un ataque de DDoS se está aprovechando, se pueden lograr factores de amplificación de 100x o más [1][2].</MatchText></p>
                    <p><MatchText id="match-18">Este estudio enfatiza la necesidad de generar conciencia para minimizar la cantidad de dispositivos conectados a internet que, por desconocimiento de quienes los administran, puedan ser usados para montar ataques de DDoS.</MatchText></p>

                </>}
                {myLang === 'en' && <>
                    <h2><MatchText id="match-12">Motivation</MatchText></h2>
                    <p><MatchText id="match-13">Amplification DDoS attacks are one of the most common types of attacks experienced by organizations today. They take advantage of two design flaws in network protocols:</MatchText></p>
                    <ul>
                        <li><MatchText id="match-14">The information in the IP header is not authenticated.</MatchText></li>
                        <li><MatchText id="match-15">UDP has no handshaking dialogues.</MatchText></li>
                    </ul>
                    <p><MatchText id="match-16">These attacks have a major impact on the community, as they afford attackers significant advantages. Firstly, by using vulnerable services as traffic reflectors, these attacks inherently hide the attacker's address, thereby making it difficult to determine the origin of the attack and potential mitigation actions.</MatchText></p>
                    <p><MatchText id="match-17">Secondly, small requests to different reflectors usually suffice to trigger large responses. This can be used to generate and direct large volumes of traffic to a targeted victim organization. Depending on the protocol exploited by a DDoS attack, amplification factors of 100x or more can be achieved [1] [2].</MatchText></p>
                    <p><MatchText id="match-18">This study stresses the need to raise awareness in order to minimize the number of devices connected to the Internet which, due to a lack of knowledge on the part of their administrators, may be used to launch DDoS attacks.</MatchText></p>

                </>}
                {myLang === 'pt' && <>
                    <h2><MatchText id="match-12">Motivação</MatchText></h2>
                    <p><MatchText id="match-13">Hoje, os ataques DDoS de amplificação estão entre os mais comuns que sofrem as organizações. Estes aproveitam duas falhas nos protocolos de rede:</MatchText></p>
                    <ul>
                        <li><MatchText id="match-14">As informações do cabeçalho IP não estão autenticadas.</MatchText></li>
                        <li><MatchText id="match-15">O UDP não usa um handshaking.</MatchText></li>
                    </ul>
                    <p><MatchText id="match-16">Esses ataques têm um grande impacto na comunidade, pois apresentam grandes vantagens para os atacantes. Em primeiro lugar, ao usar serviços vulneráveis como refletores de tráfego, esses ataques ocultam inerentemente o endereço do atacante, dificultando assim a detecção da origem do ataque e as possíveis ações de mitigação.</MatchText></p>
                    <p><MatchText id="match-17">Em segundo lugar, pedidos pequenos a refletores diferentes costumam ser suficientes para desencadear grandes respostas. Isso pode ser usado para gerar e direcionar grandes volumes de tráfego para uma organização vítima-alvo. Dependendo de qual protocolo um ataque de DDoS está se aproveitando, fatores de amplificação de 100x ou mais podem ser alcançados [1][2].</MatchText></p>
                    <p><MatchText id="match-18">Este estudo está focado na necessidade de gerar consciência para minimizar o número de dispositivos conectados à Internet que, por desconhecimento de quem os gerenciam, possam ser usados para montar ataques de DDoS.</MatchText></p>

                </>}
            </div>
        </div>
    );
}

export default Motivacion;