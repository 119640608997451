import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function FuentesDeDatos() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id='p4'>
                {myLang === 'es' && <>
                    <h2>Fuentes de datos</h2>
                    <p>Se utilizaron tres fuentes de datos distintas:</p>
                    <ul>
                        <li><em>Resolvers</em>&nbsp;p&uacute;blicos<br /><br />Se utiliz&oacute; una lista de 33 servicios de&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos; en total, 61 direcciones IPv4 distintas. Estos servicios son entregados por distintas organizaciones de alcance global que permiten realizar consultas desde cualquier lugar de internet. Puede encontrar la lista completa en el Anexo A.<br /><br />A cada uno de ellos se enviaron consultas DoT (puerto 853) para detectar si dan el servicio. Las consultas se realizaron desde todas las sondas Atlas de la regi&oacute;n.</li>
                        <li>Open&nbsp;<em>resolvers</em><br /><br />Se utilizaron las listas de open&nbsp;<em>resolvers</em>&nbsp;IPv4 del proyecto public-dns.info, de todos los pa&iacute;ses de Latinoam&eacute;rica y el Caribe. Adem&aacute;s, se utiliz&oacute; una lista de&nbsp;<em>resolvers</em>&nbsp;IPv6 obtenida por LACNIC en consultas al servidor ra&iacute;z de los reversos del DNS d.in-addr-servers.arpa.</li>
                    </ul>
                    <p>Un&nbsp;<em>open resolver</em>&nbsp;es una direcci&oacute;n IP que responde usando el protocolo DNS cl&aacute;sico en el puerto 53, pero que, a diferencia de los&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos del punto anterior, no es un servicio oficial ni declarado como tal.<br />Por lo general, se trata de servidores DNS o&nbsp;<em>routers</em>&nbsp;dom&eacute;sticos que no pretenden dar servicio a toda internet y que funcionan de forma inadvertida. Los&nbsp;<em>open resolvers</em>, en general, deben evitarse en una red privada. Existen proyectos del &aacute;rea de seguridad que continuamente los escanean e informan a sus responsables para su correcci&oacute;n.<br />A cada una de estas direcciones se les enviaron consultas DoT al puerto 853 para detectar si daban el servicio. Las consultas se realizaron desde una direcci&oacute;n &uacute;nica en el laboratorio de LACNIC.</p>
                    <ul>
                        <li><em>Resolvers</em>&nbsp;de sondas Atlas<br /><br />El proyecto RIPE Atlas es una plataforma de dispositivos llamados&nbsp;<em>sondas</em>, que son hospedados por voluntarios en todo el mundo. Cada una de estas sondas permite hacer mediciones hacia Internet, que se benefician de utilizar las redes de los hu&eacute;spedes y entregan una visi&oacute;n &uacute;nica de los servicios desde distintos lugares del mundo.<br /><br />Se han instalado m&aacute;s de 30.000 de estas sondas y aproximadamente la mitad est&aacute;n activas. De ese total, la regi&oacute;n de Latinoam&eacute;rica y el Caribe tiene un poco m&aacute;s de 1200. De estas, se detectaron m&aacute;s de 300 de forma activa durante la medici&oacute;n. En el Anexo B hay una tabla con la lista de las utilizadas y su distribuci&oacute;n regional.</li>
                    </ul>
                    <p>Cada una de estas sondas usa los&nbsp;<em>resolvers</em>&nbsp;internos de las redes de los propios hu&eacute;spedes. En la mayor&iacute;a de los casos son los&nbsp;<em>resolvers</em>&nbsp;de los ISP donde se alojan. En otros casos, son configurados a mano por los propios hu&eacute;spedes, con&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos. Se enviaron consultas DoT al puerto 853 de todos estos&nbsp;<em>resolvers</em>&nbsp;para detectar la utilizaci&oacute;n del servicio.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Data sources</h2>
                    <p>Three different data sources were used:</p>
                    <ul>
                        <ul>
                            <li>Public&nbsp;<em>Resolvers</em><br /><br />
                                <p>A list of 33 public resolver services totaling 61 different IPv4 addresses was used. These services are provided by different organizations with a global reach that allow queries from anywhere on the Internet. The complete list is included in Appendix A.<br />DoT queries (port 853) were sent to each of these resolvers to determine whether they provide the DoT service. These queries were sent from all Atlas probes in the region.</p>
                            </li>
                            <li>Open&nbsp;<em>resolvers</em><br />
                                <p>The study used the lists of IPv4 open resolvers obtained from the public-dns.info project corresponding to all the countries in Latin America and the Caribbean. It also used a list of IPv6 resolvers obtained by LACNIC by querying the reverse root d.in-addr-servers.arpa DNS server.<br />An open resolver is an IP address that replies using the classic DNS protocol on port 53. However, unlike the public resolvers mentioned in the previous bullet point, it is not an official service or declared as such.<br />Generally speaking, they are DNS servers or home routers that are not intended to serve the entire internet and do so unintentionally. Open resolvers should generally be avoided on private networks. There are projects by the security team that constantly scan for open resolvers and inform those responsible so that they can be corrected.<br />DoT queries were sent to port 853 of each of these addresses to detect whether the DoT service was being provided. These queries were sent from a single LACNIC lab address.</p>
                            </li>
                            <li>Atlas probe&nbsp;<em>Resolvers</em></li>
                        </ul>
                    </ul>
                    <p>The RIPE Atlas project uses devices known as&nbsp;<em>probes</em>, which are hosted by volunteers worldwide. Each of these probes allows performing Internet measurements. They benefit from the use of host networks and provide a unique view of the services from various parts of the world.<br />More than 30,000 of these probes have been deployed, approximately half of which are currently active. Of this total, just over 1,200 are in the region of Latin America and the Caribbean. During our measurements, more than 300 of these were actively detected. Appendix B contains a table with the list of the probes used for the study and their regional distribution.</p>
                    <ul>Each of these probes uses the internal resolvers of the host networks themselves. In most cases, these are the resolvers of the ISPs where they are hosted. In others, they are manually configured by the hosts themselves using public resolvers. DoT queries were sent to port 853 of all these resolvers to detect the use of the service.</ul>

                </>}
                {myLang === 'pt' && <>
                    <h2>Fontes de dados</h2>
                    <p>Foram usadas tr&ecirc;s fontes de dados diferentes:</p>
                    <ul>
                        <li><em>Resolvers</em>&nbsp;p&uacute;blicos<br /><br />Foi usada uma lista de 33 servi&ccedil;os de&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos; no total, 61 endere&ccedil;os IPv4 diferentes. Esses servi&ccedil;os s&atilde;o fornecidos por diferentes organiza&ccedil;&otilde;es com alcance global que permitem consultas desde qualquer lugar na Internet. Confira a lista completa no Anexo A.<br /><br />Consultas DoT (porta 853) foram enviadas a cada um deles para detectar se fornecem o servi&ccedil;o. As consultas foram feitas desde todas as sondas Atlas da regi&atilde;o.</li>
                        <li>Open&nbsp;<em>resolvers</em><br /><br />Foram usadas as listas de open&nbsp;<em>resolvers</em>&nbsp;IPv4 do projeto public-dns.info, de todos os pa&iacute;ses da Am&eacute;rica Latina e o Caribe. Tamb&eacute;m, foi usada uma lista de&nbsp;<em>resolvers</em>&nbsp;IPv6 obtida pelo LACNIC nas consultas ao servidor raiz dos reversos do DNS d.in-addr-servers.arpa.</li>
                    </ul>
                    <p>Um&nbsp;<em>open resolver</em>&nbsp;&eacute; um endere&ccedil;o IP que responde usando o protocolo DNS cl&aacute;ssico na porta 53, mas que, ao contr&aacute;rio dos&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos do ponto anterior, n&atilde;o &eacute; um servi&ccedil;o oficial ou declarado como tal.</p>
                    <p>Normalmente, s&atilde;o servidores DNS ou roteadores dom&eacute;sticos que n&atilde;o pretendem atender toda a Internet e que operam inadvertidamente. Os&nbsp;<em>open resolvers</em>, em geral, devem ser evitados em uma rede privada. Existem projetos da &aacute;rea de seguran&ccedil;a que continuamente os digitalizam e informam os respons&aacute;veis ​​para sua corre&ccedil;&atilde;o.<br />As consultas DoT foram enviadas para cada um desses endere&ccedil;os DoT&nbsp; para a porta 853 para detectar se estes prestavam o servi&ccedil;o. As consultas foram realizadas a partir de um &uacute;nico endere&ccedil;o no laborat&oacute;rio do LACNIC.<br /><br /></p>
                    <ul>
                        <li><em>Resolvers</em>&nbsp;de sondas Atlas<br /><br />O projeto RIPE Atlas &eacute; uma plataforma de dispositivos chamados&nbsp;<em>sondas</em>, que s&atilde;o hospedados por volunt&aacute;rios no mundo todo. Cada uma dessas sondas permite fazer medi&ccedil;&otilde;es para a Internet, que se beneficiam de usar as redes dos h&oacute;spedes e fornecem uma vis&atilde;o &uacute;nica dos servi&ccedil;os desde diferentes partes do mundo.<br /><br />Mais de 30.000 dessas sondas foram instaladas e cerca da metade est&atilde;o ativas. Desse total, a regi&atilde;o da Am&eacute;rica Latina e o Caribe tem pouco mais de 1200. Destas, mais de 300 foram detectadas de forma ativa durante a medi&ccedil;&atilde;o. No Anexo B h&aacute; uma tabela com a lista das usadas e sua distribui&ccedil;&atilde;o regional.</li>
                    </ul>
                    <p>Cada uma dessas sondas usa os&nbsp;<em>resolvers</em>&nbsp;internos das redes dos pr&oacute;prios&nbsp;<em>hosts</em>. Na maioria dos casos, s&atilde;o os&nbsp;<em>resolvers</em>&nbsp;dos ISP onde s&atilde;o hospedados. Em outros casos, s&atilde;o configurados manualmente pelos pr&oacute;prios h&oacute;spedes, com&nbsp;<em>resolvers</em>p&uacute;blicos. Consultas DoT foram enviadas &agrave; porta 853 de todos esses&nbsp;<em>resolvers</em>&nbsp;para detectar o uso do servi&ccedil;o.</p>
                </>}
            </div>
        </div>
    )
}

export default FuentesDeDatos
