import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function DatosPorPais(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="bgp4">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-85">Datos por país</MatchText></h2>
                    <p>
                        <MatchText id="match-86">Para conocer el estado de interconexión de cada país o territorio que forma parte de la cobertura de LACNIC, mostraremos los datos más relevantes obtenidos en la etapa de procesamiento. Es importante destacar que, como los datos se calculan para un instante determinado, en este caso utilizamos los datos generados en julio de 2020 para representar el año.</MatchText></p>
                    <p>
                        <MatchText id="match-87">Solamente se mostrarán a continuación los países que disponen de suficientes recursos asignados como para poder calcular los distintos indicadores. Los territorios cubiertos por LACNIC que no se analizaron en esta sección son San Eustaquio (BQ), Curazao (CW), Islas Malvinas (FK), Islas Sándwich y Georgia del Sur (GS), y San Martín (SX).</MatchText></p>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-85">Data by Country </MatchText></h2>
                    <p>
                        <MatchText id="match-86">In order to determine the interconnection status of each country or territory that is part of the LACNIC service region, we will show the most relevant data obtained during the processing stage. It is important to note that, because the data is calculated for a specific moment in time, in this case we used the data generated in July 2020 as representative of the year.</MatchText></p>
                    <p>
                        <MatchText id="match-87">Only the countries that have been assigned sufficient resources to calculate the various indicators are listed below. Therefore, while Saint Eustatius (BQ), Curacao (CW), the Falkland Islands (FK), South Georgia and the Sandwich Islands (GS), and Sint Maarten (SX) are part of the LACNIC service region, they were not analyzed in this section.</MatchText></p>
                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-85">Dados por país</MatchText></h2>
                    <p>
                        <MatchText id="match-86">Para conhecer o estado de interconexão de cada país ou território que faz parte da cobertura do LACNIC, mostraremos os dados mais relevantes dos dados obtidos na etapa de processamento. É importante apontar que como os dados são calculados para um momento determinado, neste caso usamos os dados gerados em julho de 2020, para representar o ano.</MatchText></p>
                    <p>
                        <MatchText id="match-87">A seguir, são mostrados apenas os países que possuam recursos suficientes designados como para poder calcular os diferentes indicadores. Os territórios abrangidos pelo LACNIC que não foram analisados nesta seção são: Santo Eustáquio (BQ), Curaçao (CW), Ilhas Malvinas (FK), Ilhas Sandwich e Geórgia do Sul (GS), e Saint Martin (SX).</MatchText></p>
                </>}
                <Paises />
            </div>
        </div>
    )
}

function Paises() {
    const { myLang } = useContext(LangContext)
    return (
        <>
            <h3 id="argentina"> 
                <MatchText id="match-001">Argentina</MatchText>
            </h3>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r4_${myLang}.png`} /></figure>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r5_${myLang}.png`} /></figure>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r6_${myLang}.png`} /></figure>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r7_${myLang}.png`} /></figure>
            <h3 id="aruba"> 
                 <MatchText id="match-002">Aruba</MatchText> 
                 
            </h3>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r8_${myLang}.png`} /></figure>
            <figure><img alt="Ver" src={`/images/interconexion-bgp/r9_${myLang}.png`} /></figure>
            <p>
                <MatchText id="match-">Aruba no tiene sistemas autónomos de tránsito.</MatchText></p>
            <figure><img alt="Ver" id="r10" src={`/images/interconexion-bgp/r10_${myLang}.png`} /></figure>
            <h3 id="bolivia">
                <MatchText id="match-003">
                    Bolivia 
                </MatchText></h3>
            <figure><img alt="Ver" id="r11" src={`/images/interconexion-bgp/r11_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r12" src={`/images/interconexion-bgp/r12_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r13" src={`/images/interconexion-bgp/r13_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r14" src={`/images/interconexion-bgp/r14_${myLang}.png`} /></figure>
            <h3 id="brasil">
                 
                    {myLang === 'es' && <MatchText id="match-004">Brasil</MatchText>}
                    {myLang === 'en' && <MatchText id="match-004">Brazil</MatchText>}
                    {myLang === 'pt' && <MatchText id="match-004">Brasil</MatchText>}
            </h3>
            <figure><img alt="Ver" id="r15" src={`/images/interconexion-bgp/r15_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r16" src={`/images/interconexion-bgp/r16_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r17" src={`/images/interconexion-bgp/r17_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r18" src={`/images/interconexion-bgp/r18_${myLang}.png`} /></figure>
            <h3 id="belice">
                <MatchText id="match-005">
                    Belice
                </MatchText></h3>
            <figure><img alt="Ver" id="r19" src={`/images/interconexion-bgp/r19_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r20" src={`/images/interconexion-bgp/r20_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r21" src={`/images/interconexion-bgp/r21_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r22" src={`/images/interconexion-bgp/r22_${myLang}.png`} /></figure>
            <h3 id="chile">
                <MatchText id="match-006">
                    Chile
                </MatchText></h3>
            <figure><img alt="Ver" id="r23" src={`/images/interconexion-bgp/r23_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r24" src={`/images/interconexion-bgp/r24_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r25" src={`/images/interconexion-bgp/r25_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r26" src={`/images/interconexion-bgp/r26_${myLang}.png`} /></figure>
            <h3 id="colombia">
                <MatchText id="match-007">
                     Colombia 
                </MatchText></h3>
            <figure><img alt="Ver" id="r27" src={`/images/interconexion-bgp/r27_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r28" src={`/images/interconexion-bgp/r28_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r29" src={`/images/interconexion-bgp/r29_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r30" src={`/images/interconexion-bgp/r30_${myLang}.png`} /></figure>
            <h3 id="costa-rica">
                <MatchText id="match-008">
                     Costa Rica 
                </MatchText></h3>
            <figure><img alt="Ver" id="r31" src={`/images/interconexion-bgp/r31_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r32" src={`/images/interconexion-bgp/r32_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r33" src={`/images/interconexion-bgp/r33_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r34" src={`/images/interconexion-bgp/r34_${myLang}.png`} /></figure>
            <h3 id="cuba">
                <MatchText id="match-009">Cuba 
                </MatchText></h3>
            <figure><img alt="Ver" id="r35" src={`/images/interconexion-bgp/r35_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r36" src={`/images/interconexion-bgp/r36_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r37" src={`/images/interconexion-bgp/r37_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r38" src={`/images/interconexion-bgp/r38_${myLang}.png`} /></figure>
            <h3 id="republica-dominicana">
                <MatchText id="match-010"> República Dominicana 
                </MatchText></h3>
            <figure><img alt="Ver" id="r39" src={`/images/interconexion-bgp/r39_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r40" src={`/images/interconexion-bgp/r40_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r41" src={`/images/interconexion-bgp/r41_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r42" src={`/images/interconexion-bgp/r42_${myLang}.png`} /></figure>
            <h3 id="ecuador">
                <MatchText id="match-011">
                Ecuador
                </MatchText></h3>
            <figure><img alt="Ver" id="r43" src={`/images/interconexion-bgp/r43_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r44" src={`/images/interconexion-bgp/r44_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r45" src={`/images/interconexion-bgp/r45_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r46" src={`/images/interconexion-bgp/r46_${myLang}.png`} /></figure>
            <h3 id="guyana-francesa">
                <MatchText id="match-012">
                Guyana Francesa
                </MatchText></h3>
            <figure><img alt="Ver" id="r47" src={`/images/interconexion-bgp/r47_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r48" src={`/images/interconexion-bgp/r48_${myLang}.png`} /></figure>
            <p> 
                <MatchText id="match-013">
                Guyana Francesa no tiene sistemas autónomos de tránsito.
                </MatchText></p>
            <figure><img alt="Ver" id="r49" src={`/images/interconexion-bgp/r49_${myLang}.png`} /></figure>
            <h3 id="guatemala">
                <MatchText id="match-014">
                Guatemala
                </MatchText></h3>
            <figure><img alt="Ver" id="r51" src={`/images/interconexion-bgp/r51_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r52" src={`/images/interconexion-bgp/r52_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r53" src={`/images/interconexion-bgp/r53_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r54" src={`/images/interconexion-bgp/r54_${myLang}.png`} /></figure>
            <h3 id="guyana">
                <MatchText id="match-015">
                Guyana
                </MatchText></h3>
            <figure><img alt="Ver" id="r55" src={`/images/interconexion-bgp/r55_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r56" src={`/images/interconexion-bgp/r56_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r57" src={`/images/interconexion-bgp/r57_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r58" src={`/images/interconexion-bgp/r58_${myLang}.png`} /></figure>
            <h3 id="honduras">
                <MatchText id="match-016">
                Honduras
                </MatchText></h3>
            <figure><img alt="Ver" id="r59" src={`/images/interconexion-bgp/r59_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r60" src={`/images/interconexion-bgp/r60_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r61" src={`/images/interconexion-bgp/r61_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r62" src={`/images/interconexion-bgp/r62_${myLang}.png`} /></figure>
            <h3 id="haiti">
                <MatchText id="match-017">
                Haití
                </MatchText></h3>
            <figure><img alt="Ver" id="r63" src={`/images/interconexion-bgp/r63_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r64" src={`/images/interconexion-bgp/r64_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r65" src={`/images/interconexion-bgp/r65_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r66" src={`/images/interconexion-bgp/r66_${myLang}.png`} /></figure>
            <h3 id="mexico">
                <MatchText id="match-018">
                México
                </MatchText></h3>
            <figure><img alt="Ver" id="r67" src={`/images/interconexion-bgp/r67_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r68" src={`/images/interconexion-bgp/r68_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r69" src={`/images/interconexion-bgp/r69_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r70" src={`/images/interconexion-bgp/r70_${myLang}.png`} /></figure>
            <h3 id="nicaragua">
                <MatchText id="match-019">
                Nicaragua
                </MatchText></h3>
            <figure><img alt="Ver" id="r71" src={`/images/interconexion-bgp/r71_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r72" src={`/images/interconexion-bgp/r72_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r73" src={`/images/interconexion-bgp/r73_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r74" src={`/images/interconexion-bgp/r74_${myLang}.png`} /></figure>
            <h3 id="panama">
                <MatchText id="match-020">
                Panamá
                </MatchText></h3>
            <figure><img alt="Ver" id="r75" src={`/images/interconexion-bgp/r75_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r76" src={`/images/interconexion-bgp/r76_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r77" src={`/images/interconexion-bgp/r77_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r78" src={`/images/interconexion-bgp/r78_${myLang}.png`} /></figure>
            <h3 id="peru">
                <MatchText id="match-021">
                Perú
                </MatchText></h3>
            <figure><img alt="Ver" id="r79" src={`/images/interconexion-bgp/r79_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r80" src={`/images/interconexion-bgp/r80_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r81" src={`/images/interconexion-bgp/r81_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r82" src={`/images/interconexion-bgp/r82_${myLang}.png`} /></figure>
            <h3 id="paraguay">
                <MatchText id="match-022">
                Paraguay
                </MatchText></h3>
            <figure><img alt="Ver" id="r83" src={`/images/interconexion-bgp/r83_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r84" src={`/images/interconexion-bgp/r84_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r85" src={`/images/interconexion-bgp/r85_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r86" src={`/images/interconexion-bgp/r86_${myLang}.png`} /></figure>
            <h3 id="surinam">
                <MatchText id="match-023">
                Surinam
                </MatchText></h3>
            <figure><img alt="Ver" id="r87" src={`/images/interconexion-bgp/r87_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r88" src={`/images/interconexion-bgp/r88_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r89" src={`/images/interconexion-bgp/r89_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r90" src={`/images/interconexion-bgp/r90_${myLang}.png`} /></figure>
            <h3 id="el-salvador">
                <MatchText id="match-024">
                El Salvador
                </MatchText></h3>
            <figure><img alt="Ver" id="r91" src={`/images/interconexion-bgp/r91_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r92" src={`/images/interconexion-bgp/r92_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r93" src={`/images/interconexion-bgp/r93_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r94" src={`/images/interconexion-bgp/r94_${myLang}.png`} /></figure>
            <h3 id="trinidad-y-tobago">
                <MatchText id="match-025">
                Trinidad y Tobago
                </MatchText></h3>
            <figure><img alt="Ver" id="r95" src={`/images/interconexion-bgp/r95_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r96" src={`/images/interconexion-bgp/r96_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r97" src={`/images/interconexion-bgp/r97_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r98" src={`/images/interconexion-bgp/r98_${myLang}.png`} /></figure>
            <h3 id="uruguay">
                <MatchText id="match-026">
                Uruguay
                </MatchText></h3>
            <figure><img alt="Ver" id="r99" src={`/images/interconexion-bgp/r99_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r100" src={`/images/interconexion-bgp/r100_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r101" src={`/images/interconexion-bgp/r101_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r102" src={`/images/interconexion-bgp/r102_${myLang}.png`} /></figure>
            <h3 id="venezuela">
                <MatchText id="match-027">
                Venezuela
            </MatchText></h3>
            <figure><img alt="Ver" id="r103" src={`/images/interconexion-bgp/r103_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r104" src={`/images/interconexion-bgp/r104_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r105" src={`/images/interconexion-bgp/r105_${myLang}.png`} /></figure>
            <figure><img alt="Ver" id="r106" src={`/images/interconexion-bgp/r106_${myLang}.png`} /></figure>
        </>
    )
}

export default DatosPorPais;