import React, {useContext} from 'react'
import { LangContext } from '../../../context/DataContext'

function Objetivo() {
    const {myLang} = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' && 
                <><h2>Objetivo</h2>
                <p>El objetivo de este documento es realizar un informe sobre Mecanismos de Transici&oacute;n IPv6 soportados por los CPEs m&aacute;s populares del mercado de Am&eacute;rica Latina y Caribe.</p>
                </>}
            </div>
        </div> 
    )
}

export default Objetivo
