import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function LLamadoAccion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                {myLang === 'es' && <Contenido/>}
                {myLang === 'en' && <Contenido/>}
                {myLang === 'pt' && <Contenido/>}
            </div>
        </div>
    )
}
function Contenido() {
    return (<>
        <h2><MatchText id="match-34">Un llamado a la acción</MatchText> </h2>
        <p><MatchText id="match-35"> El objeto de este llamado es tener acceso a informaci&oacute;n eBGP de m&uacute;ltiples proveedores de Internet, con el fin de enriquecer el conjunto de entrenamiento y por consiguiente la casu&iacute;stica integrada en estas herramientas bajo desarrollo.</MatchText> </p>
        <p><MatchText id="match-36"> El disponer de otros ejemplos permitir&iacute;a: desarrollar "drivers" para interpretar informaci&oacute;n sobre un conjunto de infraestructura m&aacute;s rico que el disponible a la fecha; identificar casos/excepciones a&uacute;n no registrados y que pueden traducirse en bugs sobre los algoritmos actuales; mejorar el entendimiento sobre la diversidad de situaciones en la estructura de otras redes, con el fin de depurar los cortes de informaci&oacute;n y/o desarrollar herramientas que apoyen en la identificaci&oacute;n de debilidades y la resoluci&oacute;n de la mismas.</MatchText> </p>
    </>)
}

export default LLamadoAccion
