import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Consideraciones(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
        <div id="p6">
            {myLang === 'es' && <>
                <h2>
                    Consideraciones Iniciales</h2>
                <p>
                    Dado el estado avanzado de agotamiento de IPv4
                    (<a href="https://www.lacnic.net/agotamiento"  rel="noreferrer"  target="_blank">
                        https://www.lacnic.net/agotamiento
                    </a>) es importante que se trabaje fuertemente:
                </p>
                <ul>
                    <li>
                        <strong>
                            Implementaci&oacute;n de IPv6</strong>
                        : Esto se debe  considerar desde:
                    </li>
                    <ul>
                        <li>
                            <strong>
                                Proveedores de Servicio de Internet</strong> (ISPs):  Los proveedores de servicios de Internet son a quienes m&aacute;s les impacta el  agotamiento de IPv4 por lo que son los m&aacute;s importantes en este proceso.
                        </li>
                        <li>
                            <strong>
                                Proveedores de Contenido</strong>: Es muy importante  que los ISPs provean IPv6 a los usuarios finales pero tambi&eacute;n debe realizarse  desde los proveedores de contenido ya que es lo que m&aacute;s consumen los clientes.
                        </li>
                        <li>
                            <strong>
                                Grandes Clientes Corporativos</strong>: En muchos casos  tienen infraestructura propia y consumen considerables vol&uacute;menes de tr&aacute;fico por  lo que es importante su participaci&oacute;n en este proceso.
                        </li>
                        <li>
                            <strong>
                                Gobierno
                            </strong>: Los gobiernos proveedores de  contenido y de servicios a la comunidad por lo que la incorporaci&oacute;n de IPv6:
                        </li>
                        <ul>
                            <li>
                                Ayuda a que todo el tr&aacute;fico  generado sea a trav&eacute;s del nuevo protocolo.
                            </li>
                            <li>
                                Da un ejemplo y muestra el camino  a seguir.
                            </li>
                            <li>
                                Puede promover y regular la  incorporaci&oacute;n por el resto.
                            </li>
                        </ul>
                    </ul>
                    <li>
                        <strong>
                            Mecanismos de Transici&oacute;n</strong>: Est&aacute;n definidos en  nuevos est&aacute;ndares que se fueron definiendo en lo &uacute;ltimos a&ntilde;os que utilizan  diferentes mecanismos y protocolos que, combinados, permiten que los Servicios,  Infraestructuras, Dispositivos y Aplicaciones que siguen funcionando solo con  IPv4 puedan realizar una transici&oacute;n hacia los nuevos Servicios en IPv6.
                    </li>
                </ul>
            </>
            }
        </div>
        </div>
    )
}

export default Consideraciones
