import React, {  useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
/* import SearchComponent from '../../../components/SearchComponent'; */

function MenuLateralInterconexionBgpLocal() {
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return (  
            <>
                {myLang === 'es' && 
                    <nav>
                        <button>
                            Interconexión BGP a nivel local en la región de AméricaLatina y el Caribe
                            <i className="fas fa-angle-down"></i>
                        </button>
                        {/* <SearchComponent /> */}
                        <ul className="active">
                            <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen</a></li>
                            <li><a href="#introduccion" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introduccion</a></li>   
                            <li><a href="#objetivos" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Objetivos</a></li> 
                            <li><a href="#metodologia" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Metodología</a></li> 
                            <li><a href="#resultados-preliminares" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Resultados preliminares</a></li>  
                            <li><a href="#datos-por-ixp" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Datos por IXP</a></li> 
                            <li><a href="#conclusiones" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conclusiones</a></li> 
                        </ul>
                    </nav>
                }
                {myLang === 'en' && 
                    <nav>
                        <button>
                            Local BGP Interconnection in Latin America and the Caribbean
                            <i className="fas fa-angle-down"></i>
                        </button>
                        {/* <SearchComponent /> */}
                        <ul className="active">
                            <li><a href="#abstract" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Abstract</a></li>
                            <li><a href="#introduction" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introduction</a></li>   
                            <li><a href="#goals" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Goals</a></li> 
                            <li><a href="#methodology" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Methodology</a></li> 
                            <li><a href="#preliminary-findings" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Preliminary Findings</a></li>  
                            <li><a href="#data-by-ixp" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Data by IXP</a></li> 
                            <li><a href="#conclusions" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conclusions</a></li> 
                        </ul>
                    </nav>
                }
                {myLang === 'pt' && 
                    <nav>
                        <button>
                        Interconexão BGP no nível local na região da América Latina e o Caribe
                            <i className="fas fa-angle-down"></i>
                        </button>
                        {/* <SearchComponent /> */}
                        <ul className="active">
                            <li><a href="#resumo" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumo</a></li>
                            <li><a href="#introducao" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Introdução</a></li>   
                            <li><a href="#objetivos" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Objetivos</a></li> 
                            <li><a href="#metodologia" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Metodologia</a></li> 
                            <li><a href="#resultados-preliminares" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Resultados preliminares</a></li>  
                            <li><a href="#dados-por-ixp" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Dados por IXP</a></li> 
                            <li><a href="#conclusoes" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conclusões</a></li> 
                        </ul>
                    </nav>
                }
            </>
             
    );
}

export default MenuLateralInterconexionBgpLocal
