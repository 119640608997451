import React, {useContext} from 'react'
import {LangContext} from '../../../context/DataContext'

function Introduccion() {
    const {myLang}=useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang==='es'&&<>
                <h2>Introducci&oacute;n</h2>
                <p>Este estudio mantiene la l&iacute;nea de investigaci&oacute;n “Interconexi&oacute;n en BGP en la regi&oacute;n de Am&eacute;rica Latina y el <a href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-interconexion-bgp-lac-es.pdf" target="blank" rel="noreferrer">Caribe</a>”  presentado por LACNIC en el a&ntilde;o 2020. El informe parte de la premisa de que el desarrollo de Internet y la calidad en la conectividad de sus usuarios, depende de una buena infraestructura en las comunicaciones y una buena conectividad entre pa&iacute;ses. En Am&eacute;rica Latina a&uacute;n existen deficiencias en este aspecto: la falta de interconexi&oacute;n local entre los distintos operadores de red hace que el tr&aacute;fico entre pa&iacute;ses cercanos, muchas veces debe ser intercambiado en puntos lejanos, ubicados en Estados Unidos o Europa.</p>
                <p>El estudio previo se enfoc&oacute; en conocer la situaci&oacute;n actual de las conexiones entre los pa&iacute;ses latinoamericanos a partir de las tablas de ruteo BGP, registradas y almacenadas por colectores globales de RIPE NCC. Tambi&eacute;n sabemos que los puntos de intercambio de tr&aacute;fico (IXPs) juegan un papel fundamental para mejorar la situaci&oacute;n de interconexi&oacute;n. Por lo tanto, este estudio analiz&oacute; las tablas BGP de los IXP, para poder conocer con mayor detalle que est&aacute; sucediendo a nivel local, y si la conectividad que reflejan estas tablas coinciden con los resultados a nivel global.</p>
                <p>Para comenzar con este estudio, es importante definir a qu&eacute; nos referimos con un IXP; En este caso utilizaremos la definici&oacute;n propuesta por la Internet Exchange <a href="http://www.ix-f.net/ixp-definition.html" target="blank" rel="noreferrer">Federation</a>: “Un IXP (o punto de intercambio de Internet) es una instalaci&oacute;n de red que permite la interconexi&oacute;n entre m&aacute;s de dos sistemas aut&oacute;nomos independientes, principalmente con la finalidad de facilitar el intercambio de tr&aacute;fico en Internet. Un IXP provee interconexi&oacute;n solamente a sistemas aut&oacute;nomos, y no requiere que el tr&aacute;fico que circula entre cualquier par de sistemas aut&oacute;nomos participantes deba pasar por un tercero, ni tampoco alterar o interferir otro tr&aacute;fico.”</p>
                <p>La Asociaci&oacute;n de Puntos de Intercambio de Tr&aacute;fico de Am&eacute;rica Latina y Caribe <a href="https://lac-ix.org " target="blank" rel="noreferrer">(LAC-IX)</a> integra la Federaci&oacute;n de Puntos de Intercambio de Tr&aacute;fico, y es una organizaci&oacute;n sumamente relevante en la promoci&oacute;n de intercambio de tr&aacute;fico en la regi&oacute;n, contribuyendo al desarrollo de nuevos IXPs y apoyando el crecimiento de los existentes.</p>
                
                </>}
                {myLang === 'en' && <>
                    <h2>Introduction</h2>
                    <p>This study follows the same line as the study titled <a href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-interconexion-bgp-lac-es.pdf" target="_blank" rel="noreferrer">“BGP Interconnection in Latin America and the Caribbean”</a> presented by LACNIC in 2020. The report is based on the premise that Internet development and the quality of user connectivity depend on the existence of good communications infrastructure and proper connectivity between countries.</p>
                    <p>In Latin America, certain deficiencies still exist in this regard. The lack of local interconnection between different network operators means that traffic between neighboring countries must often be exchanged at distant locations, typically in the United States or Europe.</p>
                    <p>Accordingly, this study analyzed the IXPs’ BGP routing tables to gain a detailed understanding of what is going on at the local level and whether the connectivity reflected in these tables matches the results obtained at the global level. </p>
                    <p>But first, it is important to define what we mean by IXP. In this case, we will use the definition proposed by the <a href="http://www.ix-f.net/ixp-definition.html" target="_blank" rel="noreferrer">Internet Exchange Federation</a> </p>
                    <p>An Internet Exchange Point (IXP) is a network facility that enables the interconnection of more than two independent Autonomous Systems, primarily for the purpose of facilitating the exchange of Internet traffic. An IXP provides interconnection only for Autonomous Systems and does not require the Internet traffic passing between any pair of participating Autonomous Systems to pass through any third Autonomous System, nor does it alter or otherwise interfere with such traffic.</p>
                    <p>The Latin American and Caribbean Internet Exchange Association (<a href="https://lac-ix.org" target="_blank" rel="noreferrer">LAC-IX</a>) is part of the Internet Exchange Federation and plays a relevant role in the promotion of IXPs in the LAC region. It contributes to the development of new Internet Exchange Points and supports the growth of existing IXPs.</p>
        
                </>}
                {myLang === 'pt' && <>
                    <h2>Introdu&ccedil;&atilde;o</h2>
                    <p>Este estudo mant&eacute;m a linha de pesquisa <a href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-interconexion-bgp-lac-es.pdf" target="_blank" rel="noreferrer">"Interconex&atilde;o no BGP na regi&atilde;o da Am&eacute;rica Latina e o Caribe"</a>,  apresentada pelo LACNIC em 2020. O relat&oacute;rio parte da premissa de que o desenvolvimento da Internet e a qualidade na conectividade dos usu&aacute;rios dependem de uma boa infraestrutura nas comunica&ccedil;&otilde;es e de uma boa conectividade entre pa&iacute;ses.</p>
                    <p>Na Am&eacute;rica Latina ainda existem defici&ecirc;ncias nesse sentido. A falta de interconex&atilde;o local entre as diferentes operadoras de rede faz com que o tr&aacute;fego entre pa&iacute;ses pr&oacute;ximos muitas vezes deva ser trocado em pontos distantes, localizados nos Estados Unidos ou na Europa.</p>
                    <p>Portanto, este estudo analisou as tabelas BGP dos IXP para conhecer melhor o que est&aacute; acontecendo no n&iacute;vel local e se a conectividade refletida por essas tabelas corresponde aos resultados no n&iacute;vel global.</p>
                    <p>Para come&ccedil;ar, &eacute; importante definir o que entendemos por IXP. Neste caso, usaremos a defini&ccedil;&atilde;o proposta pela <a href="http://www.ix-f.net/ixp-definition.html" target="_blank" rel="noreferrer">Internet Exchange Federation</a> </p>
                    <p>Um IXP (ou Ponto de Troca de Tr&aacute;fego) &eacute; uma instala&ccedil;&atilde;o de rede que permite a interconex&atilde;o entre mais de dois sistemas aut&ocirc;nomos independentes, principalmente com o objetivo de facilitar a troca de tr&aacute;fego na Internet. Um IXP fornece interconex&atilde;o apenas para sistemas aut&ocirc;nomos e n&atilde;o exige que o tr&aacute;fego que flui entre qualquer par de sistemas aut&ocirc;nomos participantes passe por um terceiro, nem exige que altere ou interfira com outro tr&aacute;fego</p>
                    <p>A Associa&ccedil;&atilde;o de Pontos de Troca de Tr&aacute;fego da Am&eacute;rica Latina e o Caribe (<a href="https://lac-ix.org" target="_blank" rel="noreferrer">LAC-IX</a>) faz parte da Federa&ccedil;&atilde;o de Pontos de Troca de Tr&aacute;fego e &eacute; uma organiza&ccedil;&atilde;o de grande relev&acirc;ncia na promo&ccedil;&atilde;o da troca de tr&aacute;fego na regi&atilde;o. Contribui para o desenvolvimento de novos IXP e apoia o crescimento dos existentes.</p>
                    
                </>}
            </div>
        
        </div>
    )
}

export default Introduccion
