import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext';  

function MenuLateralDNSOverTLS() { 
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return ( 
    <> 
            {myLang === 'es' && 
            <nav>
                <button>Uso de DNS-over-TLS en Latinoamérica y el Caribe <i className="fas fa-angle-down"></i></button> 
                 
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen</a></li>
                    <li><a href="#objetivo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Objetivo</a></li>   
                    <li><a href="#fundamentacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Fundamentación</a></li>
                    <li><a href="#fuentes-de-datos" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Fuentes de datos</a></li>
                    <li><a href="#metodologia" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Metodología</a></li>
                    <li><a href="#primeros-resultados" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Primeros resultados</a></li> 
                    <li><a href="#conclusiones" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conclusiones</a></li> 
                </ul>
            </nav>
            } 
            {myLang === 'en' && 
            <nav>
                <button>Use of DNS-over-TLS In Latin America and the Caribbean <i className="fas fa-angle-down"></i></button> 
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Summary</a></li>
                    <li><a href="#objetivo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Goal</a></li>   
                    <li><a href="#fundamentacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Rationale</a></li>
                    <li><a href="#fuentes-de-datos" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Data sources</a></li>
                    <li><a href="#metodologia" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Methodology</a></li>
                    <li><a href="#primeros-resultados" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Initial Findings</a></li> 
                    <li><a href="#conclusiones" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conlusions</a></li>  
                </ul>
            </nav>
            } 
            {myLang === 'pt' && 
            <nav>
                <button>Uso do DNS-over-TLS na América Latina e o Caribe <i className="fas fa-angle-down"></i></button> 
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumo</a></li>
                    <li><a href="#objetivo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Objetivo</a></li>   
                    <li><a href="#fundamentacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Fundamentos</a></li>
                    <li><a href="#fuentes-de-datos" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Fontes de dados</a></li>
                    <li><a href="#metodologia" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Metodologia</a></li>
                    <li><a href="#primeros-resultados" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Primeiros resultados</a></li> 
                    <li><a href="#conclusiones" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Conclusões</a></li> 
                </ul>
            </nav>
            } 
        </>
    );
}

export default MenuLateralDNSOverTLS;