import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext';

export default function Operacion() {

  return (
    <div id='p3'>
      <Title />
      <Table />
    </div>
  )
}


function Title() {
  const { myLang } = useContext(LangContext)
  return (
    <h2>
      {myLang === 'es' && 'Operación'}
      {myLang === 'en' && 'Operation'}
      {myLang === 'pt' && 'Operação'}
    </h2>
  )
}

function Table() {
  const { myLang } = useContext(LangContext)
  return (
    <table className="table">
      {myLang === 'es' &&

        <tbody>
          <tr>
            <th>Pr&aacute;cticas Recomendadas como Mejores Pr&aacute;cticas</th>
            <th>Enlaces de Respaldo</th>
          </tr>
          <tr>
            <td>Fomentar la neutralidad de la operaci&oacute;n del IXP, evitando en un 100%, la discriminaci&oacute;n de quien pueda conectarse al Punto de Intercambio de Tr&aacute;fico.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Mantener el costo de participaci&oacute;n en el IXP por debajo del costo de un tr&aacute;nsito equivalente.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Fomentar el crecimiento de miembros creadores de contenido y proveedores de servicios locales.</td>
            <td><a href="https://www.lacnic.net/1557/1/lacnic/lacnic-crea-nueva-posicion-en-centroamerica-para-trabajar-con-las-comunidades-locales-de-internet" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Mantener un equipo de profesionales t&eacute;cnicos con alta capacidad (certificados y capacitados y constante actualizaci&oacute;n) en el conocimiento de las &uacute;ltimas tecnolog&iacute;as, as&iacute; como en la atenci&oacute;n eficaz por parte de estos hacia las consultas de los miembros.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer protocolo de excelencia operativa.</td>
            <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noopener noreferrer">CLOUDFARE</a></td>
          </tr>
          <tr>
            <td>Establecer m&eacute;tricas acerca del fomento de la innovaci&oacute;n local, por parte del IXP.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Analizar&nbsp; y mitigar cuando corresponda altos costos de la electricidad y los equipos para el IXP, proveedores de alojamiento y centros de datos locales.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Establecer par&aacute;metros de medici&oacute;n que cuantifiquen la operaci&oacute;n basada en la excelencia operativa.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Ning&uacute;n miembro debe anunciar la red de intercambio de tr&aacute;fico o cualquier subred de la misma, as&iacute; como tampoco lo har&iacute;a con una red que no le pertenece. La red peering pertenece al Intercambio de Internet.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Cada miembro debe tener asignada una direcci&oacute;n IP (IPv4/IPv6) en la subred de peering. Los ISP configurar&aacute;n sus interfaces de enrutador con esas direcciones y establecer&aacute;n peerings BGP. Com&uacute;nmente se considera &uacute;til que las direcciones IP de interconexi&oacute;n sean p&uacute;blicas (es decir, no direcciones RFC1918) y configuradas con resoluci&oacute;n DNS directa e inversa. Esto facilita la depuraci&oacute;n de la red ISP, ya que traceroutes mostrar&aacute; claramente por qu&eacute; IXP pasa un paquete.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>El IXP deber&iacute;a solicitar direcciones IP p&uacute;blicas de un RIR o LIR. . Los IXP a menudo solicitan direcciones de PI, ya que la mayor&iacute;a de los IXP son PI ("Independientes del proveedor"). Dado que los grupos de direcciones IPv4 no asignadas est&aacute;n actualmente vac&iacute;os o casi vac&iacute;os, los RIR han reservado recursos especiales para infraestructura cr&iacute;tica como los IXP. Por lo tanto, los IXP deben solicitar expl&iacute;citamente el espacio de direcciones de IXP (IPv4 e IPv6).</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Es &uacute;til que un IXP tenga su propio AS y una peque&ntilde;a "subred de servicio", principalmente para monitorear y solucionar problemas. Desde su AS, el IXP anunciar&aacute; su red, donde podr&aacute; alojar un sitio web, un servidor de correo electr&oacute;nico y posiblemente otros servicios para los miembros. Al emparejarse con todos los miembros, este AS puede proporcionar informaci&oacute;n de enrutamiento &uacute;til para los miembros, como un looking glass.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Otro tema en el que existen diferentes pr&aacute;cticas es la resoluci&oacute;n DNS inversa de las direcciones de subred interconectadas: algunos IXP prefieren mantener la resoluci&oacute;n inversa bajo su propio dominio (como isprouter.ixp.net para cada direcci&oacute;n ISP), mientras que otros IXP ven la interfaz del enrutador del miembro como perteneciente al dominio ISP y configurar el DNS inverso con nombres como ixp-switch.isp.com. Es obvio que en el &uacute;ltimo caso, el ISP tendr&iacute;a que estar involucrado para la resoluci&oacute;n de DNS inverso.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Si un IXP tiene una cantidad apreciable de miembros que tienen pol&iacute;ticas de peering abiertas, puede ser &uacute;til hospedar un servidor de ruta en el Exchange. Los miembros con una pol&iacute;tica de interconexi&oacute;n abierta solo tendr&aacute;n que interconectarse con el servidor de ruta para interconectarse entre s&iacute;. Los servidores de ruta, sin embargo, son "puntos &uacute;nicos de falla": la falla de un servidor de ruta provocar&iacute;a la ca&iacute;da de todos los peerings. La redundancia se puede lograr ejecutando m&aacute;s de un servidor de ruta, a veces incluso con diferentes demonios de enrutamiento para tener tambi&eacute;n redundancia de software. Los servidores de ruta tambi&eacute;n son una gran mejora con respecto a la escalabilidad. El n&uacute;mero de sesiones BGP necesarias en una malla completa aumenta con el cuadrado de los miembros. Tener servidores de ruta en servicio lleva esto a una funci&oacute;n lineal mucho m&aacute;s escalable.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Por lo general, los IXP monitorean las estad&iacute;sticas de tr&aacute;fico (bits entrantes y salientes) de cada puerto y las suman todas para crear una estad&iacute;stica agregada. Esto es &uacute;til para ver las tendencias y patrones del tr&aacute;fico de Internet en el IXP y, a veces, para detectar problemas (las ca&iacute;das de tr&aacute;fico o una diferencia importante entre el tr&aacute;fico entrante y saliente suelen ser signos de problemas). Las herramientas m&aacute;s utilizadas para el control del tr&aacute;fico son MRTG y la RRDTool relacionada.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Adem&aacute;s de las estad&iacute;sticas de tr&aacute;fico, hay varias otras cosas que se pueden monitorear en un punto de intercambio. Puede ser de inter&eacute;s, por ejemplo, hacer ping a las interfaces del enrutador para ver si est&aacute;n activas y verificar su tiempo de ida y vuelta (RTT); configurar trampas SNMP para recibir una advertencia temprana si un puerto deja de funcionar; para analizar consultas ARP en la LAN interconectada; o para monitorear paquetes de transmisi&oacute;n para detectar tr&aacute;fico no deseado, peerings inactivos y dem&aacute;s.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Todos los IXP tienen diferentes conjuntos de software para monitorear el intercambio. Algunas herramientas disponibles p&uacute;blicamente de uso com&uacute;n son Nagios, Smokeping, ARPWatch. LINX, el Intercambio de Internet de Londres, ha desarrollado una herramienta espec&iacute;ficamente para monitorear los paquetes de transmisi&oacute;n de LAN interconectadas IXP, llamada IXP-Watch.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Varios intercambios tambi&eacute;n tienen herramientas personalizadas en uso, para monitorear cosas particulares como ARP proxy o trampas SNMP, o para enviar un SMS de alarma a los tel&eacute;fonos NOC. Todas estas herramientas pueden estar integradas en alg&uacute;n marco com&uacute;n; la mayor&iacute;a de los IXP tienen diferentes marcos, seg&uacute;n sus necesidades particulares. Muchos IXP proporcionan estad&iacute;sticas a sus miembros que muestran la cantidad de tr&aacute;fico intercambiado por peering. Estas estad&iacute;sticas se pueden proporcionar si la plataforma de peering exporta datos estad&iacute;sticos/de flujo de muestra. Estos datos basados ​​en la direcci&oacute;n MAC de origen y destino y el n&uacute;mero de bytes son la base para calcular y mostrar informaci&oacute;n de interconexi&oacute;n en vivo.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Se recomienda IXP Manager como herramienta para administrar el IXP, principalmente para IXP nuevos, peque&ntilde;os y medianos</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Mantener actualizado el documento de mejores pr&aacute;cticas del IXP, basado en las mejores pr&aacute;cticas internacionales, estableciendo tiempos de actualizaci&oacute;n y revisi&oacute;n del documento.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Mantener una operaci&oacute;n administrativa y financiera basado en las normativas locales.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Garantizar que no imponen restricciones a trav&eacute;s de la concesi&oacute;n de licencias o la regulaci&oacute;n de la capacidad de los operadores para conectarse y establecer pares en un IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Elaborar plan de operaci&oacute;n en el que se mida la sostenibilidad econ&oacute;mica del IXP y se propicie el crecimiento constante, basado en las mejores pr&aacute;cticas internas e internacionales.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Fomentar la visibilidad del IXP mediante su registro en plataformas globales como PeeringDB e IXPDB.</td>
            <td><a href="https://ixpdb.euro-ix.net/en/" target="_blank" rel="noopener noreferrer">IXP-DP</a></td>
          </tr>
        </tbody>

      }
      {myLang === 'en' &&
        <tbody>
          <tr>
            <th>Recommended Best Practices</th>

            <th>Supporting links</th>
          </tr>
          <tr>
            <td>Promote the neutrality of the IXP's operation, ensuring there is no discrimination on who can connect to the IXP.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Keep the cost to participate in the IXP below the cost to pay for equivalent transit.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Encourage the growth of content creator and local ISP members.</td>
            <td><a href="https://www.lacnic.net/1557/1/lacnic/lacnic-crea-nueva-posicion-en-centroamerica-para-trabajar-con-las-comunidades-locales-de-internet" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Maintain a team of highly skilled technical professionals who are certified, trained, and constantly updated on the latest technologies and who can also effectively address member queries.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Implement an operational excellence protocol.</td>
            <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noopener noreferrer">CLOUDFARE</a></td>
          </tr>
          <tr>
            <td>Implement metrics to assess the IXP's promotion of local innovation.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Consider and mitigate where appropriate the cost to IXPs, hosting providers, and datacenters of electricity and equipment.&nbsp;</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Establish measurement parameters to quantify operation based on operational excellence.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>No member should advertise the peering network or any subnet thereof, just as it would not advertise a network that does not belong to them. The peering network belongs to the Internet Exchange.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Each member must be assigned an IP address (IPv4/IPv6) in the peering subnet. ISPs will configure their router interfaces with those addresses and set up BGP peerings. It is commonly considered useful that the peering IP addresses are public (i.e. not RFC1918 addresses) and configured with forward and reverse DNS resolution. This eases ISP network debugging, as traceroutes will clearly show which IXP a packet passes through.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>The IXP should ask for public IP addresses from an RIR or LIR . The IXPs often ask for PI addresses, since most IXPs are PI ("Provider Independent") themselves. Since the pools of unassigned IPv4 addresses are currently empty or nearly empty, RIRs have reserved special resources for critical infrastructure like IXPs. Therefore, IXPs should explicitly ask for IXP address space (IPv4 and IPv6).</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>It is useful for an IXP to have its own AS and a small "service subnet", mainly for monitoring and troubleshooting. From its AS, the IXP will announce its network, where it can host a website, an email server, and possibly other services for the members. By peering with all the members, this AS may provide useful routing information for the members, such as a looking glass.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Another topic where different practices exist is the reverse DNS resolution of peering subnet addresses: some IXPs prefer to keep the reverse resolution under their own domain (with a form such as isprouter.ixp.net for each ISP address), while other IXPs see the router interface of the member as belonging to the ISP domain and configure the reverse DNS with names such as ixp-switch.isp.com. It is obvious that in the latter case, the ISP would have to be involved for forward DNS resolution.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>If an IXP has a considerable number of members with open peering policies, it can be useful to host a route server at the Exchange. Members with an open peering policy will then only have to peer with the route server in order to peer with each other. However, route servers are "single points of failure", which means that failure of a route server would bring all peerings down. Redundancy can be achieved by running more than one route server, sometimes even with different routing daemons to have software-redundancy as well. Route servers are also a great improvement with regard to scalability. The number of necessary BGP sessions in a full-mesh increases with the square of members. Having route servers in service brings this to a far more scalable linear function.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Typically, IXPs monitor the traffic statistics (incoming and outgoing bits) of each port and sum all those up to create an aggregated statistic. This is helpful to see the trends and patterns of Internet traffic at the IXP, and sometimes to detect problems (traffic drops or a significant difference between incoming and outgoing traffic are often signs of problems). The most widely used tools for traffic monitoring are MRTG and the related RRDTool.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Apart from traffic statistics, there are several other things that can be monitored in an Exchange Point. It may be of interest, for example, to ping router interfaces to see if they are alive and to check their round-trip time (RTT); to configure SNMP traps in order to get an early warning if a port goes down; to analyze ARP queries in the peering LAN; or to monitor broadcast packets to detect undesired traffic, dead peerings, and such.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>All IXPs have different sets of software to monitor the exchange. Some commonly used publicly available tools are Nagios, Smokeping, and ARPWatch. LINX, the London Internet Exchange, has developed a tool called IXP-Watch specifically to monitor the broadcast packets of IXP peering LANs.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Several exchanges also have custom tools in use to monitor particular things such as proxy ARP or SNMP traps, or to send an alarm SMS to NOC phones. All these tools may be integrated in some common framework; most IXPs have different frameworks, depending on their particular needs. Many IXPs provide statistics to theirs members that show the amount of traffic exchanged per peering. These statistics can be provided if the peering plattform exports statistical/sampled flow data. This data based on source and destination mac-address and number of bytes is the basis to calculate and show live peering information.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>IXP Manager is recommended, particularly for new, small, and medium-sized IXPs.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Keep the IXP best practices document updated according to international best practices; to do so, establish a document update and review schedule.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Maintain administrative and financial operations in line with local regulations.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Ensure they do not place constraints through licensing or regulation on operators&rsquo; ability to connect and peer at an IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Develop an operation plan that measures the economic sustainability of the IXP and promotes constant growth, based on best internal and international practices.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Promote the IXP's visibility by registering it on global platforms such as PeeringDB and IXPDB.</td>
            <td><a href="https://ixpdb.euro-ix.net/en/" target="_blank" rel="noopener noreferrer">IXP-DP</a></td>
          </tr>
        </tbody>}
      {myLang === 'pt' &&
        <tbody>
          <tr>
            <th>Práticas recomendadas como melhores práticas</th>

            <th>Links de backup</th>
          </tr>
          <tr>
            <td>Promover a neutralidade da opera&ccedil;&atilde;o do IXP, evitando 100% de discrimina&ccedil;&atilde;o de quem possa se conectar ao Ponto de Troca de Tr&aacute;fego.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Manter o custo de participa&ccedil;&atilde;o no IXP por baixo do custo de um tr&acirc;nsito equivalente.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Incentivar o crescimento de membros criadores de conte&uacute;do e provedores de servi&ccedil;os locais.</td>
            <td><a href="https://www.lacnic.net/1557/1/lacnic/lacnic-crea-nueva-posicion-en-centroamerica-para-trabajar-con-las-comunidades-locales-de-internet" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Manter uma equipe de profissionais t&eacute;cnicos de alta capacidade (certificados, treinados e em constante atualiza&ccedil;&atilde;o) no conhecimento das mais recentes tecnologias, bem como no atendimento efetivo &agrave;s d&uacute;vidas dos associados.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer protocolo de excel&ecirc;ncia operacional.</td>
            <td><a href="https://www.cloudflare.com/es-es/learning/cdn/glossary/internet-exchange-point-ixp/" target="_blank" rel="noopener noreferrer">CLOUDFARE</a></td>
          </tr>
          <tr>
            <td>Estabelecer m&eacute;tricas sobre a promo&ccedil;&atilde;o da inova&ccedil;&atilde;o local por parte do IXP.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Analisar e mitigar, quando apropriado, os altos custos da eletricidade e dos equipamentos para o IXP, provedores de hospedagem e centros de dados locais.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Estabelecer par&acirc;metros de medi&ccedil;&atilde;o que quantifiquem a opera&ccedil;&atilde;o com base na excel&ecirc;ncia operacional.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Nenhum membro deve anunciar a rede de troca de tr&aacute;fego ou qualquer sub-rede dela, nem anunciar uma rede que n&atilde;o lhe perten&ccedil;a. A rede peering pertence &agrave; troca da Internet.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/technical-requirements-ask-members/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Cada membro deve ter designado um endere&ccedil;o IP (IPv4/IPv6) na sub-rede de peering. Os ISP configurar&atilde;o suas interfaces de roteador com esses endere&ccedil;os e estabelecer&atilde;o peerings BGP. Comumente se considera &uacute;til que os endere&ccedil;os IP de interconex&atilde;o sejam p&uacute;blicos (ou seja, n&atilde;o endere&ccedil;os RFC1918) e configurados com resolu&ccedil;&atilde;o DNS direta e inversa. Isso facilita a depura&ccedil;&atilde;o da rede ISP, uma vez que traceroutes mostrar&atilde;o claramente por que o IXP passa um pacote.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>O IXP deveria solicitar endere&ccedil;os IP p&uacute;blicos de um RIR ou LIR. .Os IXP costumam solicitar endere&ccedil;os PI, j&aacute; que a maioria dos IXP s&atilde;o PI ("Independentes do fornecedor").&nbsp; Como os grupos de endere&ccedil;os IPv4 n&atilde;o designados est&atilde;o atualmente vazios ou quase vazios, os RIR reservaram recursos especiais para infraestrutura cr&iacute;tica, como os IXP. Portanto, os IXP devem solicitar explicitamente o espa&ccedil;o de endere&ccedil;os de IXP (IPv4 e IPv6).</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>&Eacute; &uacute;til para um IXP ter seu pr&oacute;prio AS e uma pequena &ldquo;sub-rede de servi&ccedil;o&rdquo;, principalmente para monitoramento e solu&ccedil;&atilde;o de problemas.<br /> Desde seu AS, o IXP anunciar&aacute; sua rede, onde poder&aacute; hospedar um site, um servidor de e-mail e possivelmente outros servi&ccedil;os para os membros.<br /> Ao emparelhar com todos os membros, este AS pode fornecer informa&ccedil;&otilde;es &uacute;teis de roteamento aos membros, como um looking glass.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Outro t&oacute;pico no que existem diferentes pr&aacute;ticas &eacute; na resolu&ccedil;&atilde;o DNS inversa dos endere&ccedil;os de sub-redes interconectadas: alguns IXP preferem manter a resolu&ccedil;&atilde;o inversa em seu pr&oacute;prio dom&iacute;nio (como isprouter.ixp.net para cada endere&ccedil;o ISP), enquanto outros IXP v&ecirc;em a interface do roteador do membro como pertencente ao dom&iacute;nio ISP e configuram o DNS inverso com nomes como ixp-switch.isp.com. &Eacute; &oacute;bvio que neste &uacute;ltimo caso, o ISP teria que estar envolvido para a resolu&ccedil;&atilde;o de DNS inverso.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Se um IXP tiver um n&uacute;mero significativo de membros com pol&iacute;ticas de peering abertas, poder&aacute; ser &uacute;til hospedar um servidor de rota no Exchange. Os membros com uma pol&iacute;tica de interconex&atilde;o aberta s&oacute; precisar&atilde;o fazer peering com o servidor de rota para interconectar-se entre si. Os servidores de rota, no entanto, s&atilde;o "pontos &uacute;nicos de falha": a falha de um servidor de rota causaria a queda de todos os peerings. A redund&acirc;ncia pode ser alcan&ccedil;ada executando mais de um servidor de rota, &agrave;s vezes at&eacute; com diferentes daemons de roteamento para tamb&eacute;m ter redund&acirc;ncia de software. Os servidores de rota tamb&eacute;m representam uma grande melhoria em termos de escalabilidade. O n&uacute;mero de sess&otilde;es BGP necess&aacute;rias em uma malha completa aumenta com o quadrado dos membros. Ter servidores de rota em servi&ccedil;o leva a uma fun&ccedil;&atilde;o linear muito mais escal&aacute;vel.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Normalmente, os IXP monitoram as estat&iacute;sticas de tr&aacute;fego (bits de entrada e sa&iacute;da) de cada porta e somam todas elas para criar uma estat&iacute;stica agregada. Isto &eacute; &uacute;til para ver as tend&ecirc;ncias e padr&otilde;es do tr&aacute;fego da Internet no IXP e, &agrave;s vezes, para detectar problemas (quedas de tr&aacute;fego ou uma diferen&ccedil;a significativa entre o tr&aacute;fego de entrada e de sa&iacute;da s&atilde;o frequentemente sinais de problemas). As ferramentas mais usadas para o controle do tr&aacute;fego s&atilde;o MRTG e a RRDTool relacionada.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Al&eacute;m das estat&iacute;sticas de tr&aacute;fego, h&aacute; diversas outras coisas que podem ser monitoradas em um ponto de troca. Pode ser interessante, por exemplo, fazer ping nas interfaces do roteador para ver se elas est&atilde;o ativas e verificar seu tempo de ida e volta (RTT); para configurar traps SNMP para receber um aviso antecipado se uma porta cair; para analisar consultas ARP na LAN interconectada; ou para monitorar pacotes de transmiss&atilde;o para detectar tr&aacute;fego indesejado, peerings inativos e assim por diante.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Todos os IXP possuem diferentes conjuntos de software para monitorar a troca. Algumas ferramentas comumente usadas e dispon&iacute;veis publicamente s&atilde;o Nagios, Smokeping, ARPWatch. A LINX, a troca da Internet de Londres, desenvolveu uma ferramenta espec&iacute;fica para monitorar os pacotes de transmiss&atilde;o de LAN interconectadas IXP, chamada IXP-Watch.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>V&aacute;rios interc&acirc;mbios tamb&eacute;m possuem ferramentas personalizadas em uso, para monitorar coisas espec&iacute;ficas como ARP proxy ou traps SNMP, ou para enviar um SMS de alarme para telefones NOC. Todas estas ferramentas podem estar integradas em alguma estrutura comum; a maioria dos IXP possui estruturas diferentes, dependendo de suas necessidades espec&iacute;ficas. Muitos IXP fornecem estat&iacute;sticas aos seus membros mostrando a quantidade de tr&aacute;fego trocado por peering. Estas estat&iacute;sticas podem ser fornecidas se a plataforma de peering exportar dados estat&iacute;sticos/de fluxo de amostra. Esses dados baseados no endere&ccedil;o MAC de origem e destino e no n&uacute;mero de bytes s&atilde;o a base para calcular e exibir informa&ccedil;&otilde;es de interconex&atilde;o em tempo real.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Recomenda-se o IXP Manager como ferramenta para gerenciar o IXP, principalmente para IXP novos, pequenos e m&eacute;dios.</td>
            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/technical-recommendations/ixp-management/" target="_blank" rel="noopener noreferrer">EURO-IX</a></td>
          </tr>
          <tr>
            <td>Manter atualizado o documento de melhores pr&aacute;ticas do IXP, com base nas melhores pr&aacute;ticas internacionais, estabelecendo tempos de atualiza&ccedil;&atilde;o e revis&atilde;o do documento.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Manter uma opera&ccedil;&atilde;o administrativa e financeira baseada nas regulamenta&ccedil;&otilde;es locais.</td>
            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noopener noreferrer">LACNIC</a></td>
          </tr>
          <tr>
            <td>Garantir que n&atilde;o imp&otilde;em restri&ccedil;&otilde;es por meio do licenciamento ou regulamenta&ccedil;&atilde;o da capacidade dos operadores para se conectar e estabelecer pares em um IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Elaborar um plano de opera&ccedil;&atilde;o no qual seja medida a sustentabilidade econ&ocirc;mica do IXP e se promova o crescimento constante, baseado nas melhores pr&aacute;ticas internas e internacionais.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/" target="_blank" rel="noopener noreferrer">ISOC</a></td>
          </tr>
          <tr>
            <td>Promover a visibilidade do IXP registrando-o em plataformas globais como PeeringDB e IXPDB.</td>
            <td><a href="https://ixpdb.euro-ix.net/en/" target="_blank" rel="noopener noreferrer">IXP-DP</a>rrer</td>
          </tr>
        </tbody>
      }
    </table>
  )
}
