import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';


function Metodologia(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <>
                    <h2>

                        <MatchText id="match-21">Metodología y estudios anteriores</MatchText></h2>
                    <p>
                        <MatchText id="match-22">LACNIC ha llevado a cabo estudios similares durante años anteriores (pueden ver los estudios para los años  </MatchText><a href="https://labs.lacnic.net/connectivity-in-the-lac-region/" target="_blank" rel="noreferrer">
                            <MatchText id="match-23">2016  </MatchText></a>
                        <MatchText id="match-24">(en inglés) y </MatchText> <a href="http://slides.lacnic.net/wp-content/uploads/2017/09/lacnic24-analisis-de-clusters-de-latencia-en-la-region-lac-v2.pdf" target="_blank" rel="noreferrer">
                            <MatchText id="match-25">2017 </MatchText></a>
                        <MatchText id="match-26">) A grandes rasgos, estos estudios dan una vista panorámica sobre la región en cada año, y muestran sensibles mejoras en las mediciones de año-sobre-año.</MatchText></p>
                    <p>
                        <MatchText id="match-27">La metodología para realizar las mediciones es la siguiente:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-28">Desde todos los países de la región LAC, se programan mediciones cada hora.</MatchText></li>
                        <li>

                            <MatchText id="match-29">Estas mediciones son pings ICMP, donde se envían 10 paquetes por cada medición.</MatchText></li>
                        <li>
                            <MatchText id="match-30">El destino de los paquetes es una dirección IP aleatoria de un pool de direcciones IP conocidas.</MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-31">Este pool de direcciones IP se compone por todos los servidores Speedtest de la región. Esto permite tener una gran cantidad de puntos de prueba, ubicados en muchísimas redes de la región, con un uptime considerablemente bueno (es altamente probable que la dirección IP sea alcanzable al momento de realizar la medición).</MatchText></li>
                            </ul>
                        </li>
                        <li>

                            <MatchText id="match-32">Se recolectan los datos y se realiza post-procesamiento.</MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-33">La información de geolocalización se obtiene de </MatchText><a href="https://www.maxmind.com/en/geoip2-services-and-databases" target="_blank" rel="noreferrer">

                                        <MatchText id="match-34">Maxmind</MatchText></a>.</li>
                                <li>
                                    <MatchText id="match-35">La información de routing (Sistema Autónomo, prefixo bajo el que se anuncia) se obtiene de </MatchText><a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris" target="_blank" rel="noreferrer">

                                        <MatchText id="match-36">RIPE RIS</MatchText></a>.</li>
                            </ul>
                        </li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-21">Methodology and Prior Studies</MatchText></h2>
                    <p>
                        <MatchText id="match-22">LACNIC has conducted similar studies in previous years [click here to read the studies conducted in</MatchText><a href="https://labs.lacnic.net/connectivity-in-the-lac-region/" target="_blank" rel="noreferrer">
                            <MatchText id="match-23">2016&nbsp;</MatchText></a>
                        <MatchText id="match-24">(in Spanish) and&nbsp;</MatchText><a href="http://slides.lacnic.net/wp-content/uploads/2017/09/lacnic24-analisis-de-clusters-de-latencia-en-la-region-lac-v2.pdf" target="_blank" rel="noreferrer">
                            <MatchText id="match-25">2017</MatchText></a>
                        <MatchText id="match-26">&nbsp;]. Generally speaking, these studies provide an overview of the region each year and show significant improvements in the measurements year after year.</MatchText></p>
                    <p>
                        <MatchText id="match-27">The measurements were performed using the following methodology:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-28">In every country of the LAC region, measurements were scheduled to run every hour.</MatchText></li>
                        <li>
                            <MatchText id="match-29">These measurements were ICMP pings, with 10 packets sent for each measurement.</MatchText></li>
                        <li>
                            <MatchText id="match-30">The packets&rsquo; destination was a random IP address selected from a pool of known IP addresses.</MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-31">This pool of IP addresses was comprised of all the Speedtest servers in the region. This allowed having a large number of vantage points located in multiple networks across the region, with a reasonably good uptime (it was highly probable that the IP address would be reachable at the time of performing the measurement).</MatchText></li>
                            </ul>
                        </li>
                        <li>
                            <MatchText id="match-32">The results were collected and later processed.</MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-33">Geolocation information was obtained from&nbsp;</MatchText><a href="https://www.maxmind.com/en/geoip2-services-and-databases" target="_blank" rel="noreferrer">
                                        <MatchText id="match-34">Maxmind.</MatchText></a></li>
                                <li>
                                    <MatchText id="match-35">Routing information (autonomous system, announced prefix) was obtained from the RIPE Routing Information Service</MatchText><a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris" target="_blank" rel="noreferrer">
                                        <MatchText id="match-36">RIPE RIS.</MatchText></a></li>
                            </ul>
                        </li>
                    </ul>
                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-21">Metodologia e estudos anteriores</MatchText></h2>
                    <p>
                        <MatchText id="match-22">O LACNIC já fez estudos semelhantes durante anos anteriores (confira os estudos dos anos de</MatchText><a href="https://labs.lacnic.net/connectivity-in-the-lac-region/" target="_blank" rel="noreferrer">
                            <MatchText id="match-23">2016</MatchText> </a>
                        <MatchText id="match-24">(em inglês)) y </MatchText><a data-cke-saved-href="http://slides.lacnic.net/wp-content/uploads/2017/09/lacnic24-analisis-de-clusters-de-latencia-en-la-region-lac-v2.pdf" href="http://slides.lacnic.net/wp-content/uploads/2017/09/lacnic24-analisis-de-clusters-de-latencia-en-la-region-lac-v2.pdf" target="_blank" rel="noreferrer">
                            <MatchText id="match-25">2017</MatchText></a>
                        <MatchText id="match-26">) Em linhas gerais, esses estudos fornecem uma visão panorâmica da região a cada ano, e mostram melhorias perceptíveis nas medições ano após ano.</MatchText></p>
                    <p>
                        <MatchText id="match-27">A metodologia para fazer as medições é a seguinte:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-28">Desde todos os países da região da LAC, são programadas medições a cada hora.</MatchText></li>
                        <li>
                            <MatchText id="match-29">Essas medições são pings ICMP, onde 10 pacotes são enviados para cada medição.</MatchText></li>
                        <li>
                            <MatchText id="match-30">O destino dos pacotes é um endereço IP aleatório de um pool de endereços IP conhecidos. </MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-31">Esse pool de endereços IP é composto por todos os servidores Speedtest da região. Isso permite ter um grande número de pontos de teste, localizados em várias redes da região, com um uptime consideravelmente bom (é altamente provável que o endereço IP esteja acessível no momento da medição).</MatchText></li>
                            </ul>
                        </li>
                        <li>
                            <MatchText id="match-32">Os dados são coletados e o pós-processamento é realizado </MatchText>
                            <ul>
                                <li>
                                    <MatchText id="match-33">As informações de geolocalização é obtida de </MatchText><a href="https://www.maxmind.com/en/geoip2-services-and-databases" target="_blank" rel="noreferrer">
                                        <MatchText id="match-34">Maxmind</MatchText></a>.</li>
                                <li>
                                    <MatchText id="match-35">As informações de roteamento (Sistema Autônomo, prefixo sob o qual é anunciado) são obtidas de</MatchText> <a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris" target="_blank" rel="noreferrer">
                                        <MatchText id="match-36"> RIPE RIS</MatchText></a>.</li>
                            </ul>
                        </li>
                    </ul>

                </>}
            </div>
        </div>
    )
}

export default Metodologia;