import React, {useState, useEffect} from 'react';
import { MenuContext } from './DataContext';  

export const MenuState = ( {children} ) => {  
     
    const [isMenu, setMenu] = useState(null)

    const openMenu = () => { 
			setMenu(!isMenu) 
    }
 

    const [isIndice, setIndice] = useState(null)
     

    const openIndice = () => {  
        document.body.classList.toggle('out_indice');  
        setTimeout(function(){
            setIndice(!isIndice)
		},300);  
    }  
 
    useEffect(() => { 
        window.addEventListener("resize", () => { 
            if (window.innerWidth > 870)  document.body.classList.remove('out_indice');
        }, false);
    }, []);
      
 
    const [activeAnchor, setActiveAnchor] = useState(null);
 

    const scrollToAnchor = (anchorName) => { 
        if (anchorName) { 
            // encuentra el ancla
            let anchorElement = document.getElementById(anchorName);
            setActiveAnchor(anchorName)  
            openIndice(); 
            // Si existe el punto de anclaje correspondiente al id, salta al punto de anclaje
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        } 
    } 
     
     

    return (
        <MenuContext.Provider value={
             {isMenu, openMenu, isIndice, openIndice, scrollToAnchor, activeAnchor }
          }>
             {children}
        </MenuContext.Provider>
    );
}

 