import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext'; 
import Button from '../../../components/Button';

function Resumen() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p0">
                <div className='alerts alert_white'>

                    {myLang === 'es' && <>
                        <p>Autores: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                        <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-esstudio-antispoofing_es.pdf" target="_blank">Descargar (PDF)</Button>
                    </>} 
                    {myLang === 'en' && <>
                        <p>Author: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                        <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-esstudio-antispoofing_en.pdf" target="_blank">Download (PDF)</Button>
                    </>} 
                    {myLang === 'pt' && <>
                        <p>Autores: Paula Venosa, Nicolás Macia, Guillermo Pereyra</p>
                        <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-esstudio-antispoofing_pt.pdf" target="_blank">Download (PDF)</Button>
                    </>} 

                </div>

            </div>
        </div>
    )
}
 

export default Resumen
