import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function V2EquiposCPE(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Informe sobre soporte de IPv6 en equipos CPE - Versión 2 (2021)</h1>  
                    <Button color="button_blue" to="/reportes/ipv6/informe-cpes-ipv6-2020-v7.docx.pdf">Descargar (PDF)</Button>
                </>}
                {myLang === 'en' && <> 
                    <h1>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2021)</h1>  
                    <Button color="button_blue" to="/reportes/ipv6/informe-cpes-ipv6-2020-v7.docx.pdf">Download (PDF)</Button>
                </>}
                {myLang === 'pt' && <> 
                    <h1>Relatório sobre suporte do IPv6 em equipamentos CPE - Versión 2 (2021) </h1>  
                    <Button color="button_blue" to="/reportes/ipv6/informe-cpes-ipv6-2020-v7.docx.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div> 
    );
}

export default V2EquiposCPE;