import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Conclusiones() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id='p7'>
                {myLang === 'es' && <>
                    <h2>Conclusiones</h2>
                    <p>Los resultados de este estudio muestran un inicio t&iacute;mido del uso de DoT en Latinoam&eacute;rica y el Caribe, con una tasa de&nbsp;<em>resolvers</em>&nbsp;abiertos que ofrecen DoT de un 7,9% en IPv6 y de un 1,2% en IPv4.<br />Las razones de la baja adopci&oacute;n se encuentran principalmente en que la tecnolog&iacute;a est&aacute; en sus primeros pasos y en que probablemente hay limitaciones en las fuentes de datos utilizadas en el estudio. Ac&aacute; se asume que un&nbsp;<em>resolver</em>&nbsp;que entrega DoT estar&iacute;a utilizando la misma direcci&oacute;n IP en la que entrega el servicio DNS cl&aacute;sico, solo que utilizando un puerto distinto. Quiz&aacute;s existen despliegues que prefieren separar estas direcciones, las que no ser&iacute;an detectadas en este estudio.<br />Por otro lado, detectamos problemas en, aproximadamente, entre un 3,5% y 13,3% de fallas desde sondas en la regi&oacute;n LAC hacia&nbsp;<em>resolvers</em>&nbsp;conocidos que ofrecen DoT. Esto indica que existen bloqueos en las redes que impedir&iacute;an tener un servicio que alcance al 100% de los clientes.</p>
                    <p>Anexo A, lista de&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>
                                    Nombre del servicio
                                </th>
                                <th>
                                    Direcci&oacute;n es IPv4
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Verisign
                                </td>
                                <td>
                                    64.6.64.6 64.6.65.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Google Public DNS
                                </td>
                                <td>
                                    8.8.8.8 8.8.4.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Cloudflare
                                </td>
                                <td>
                                    1.1.1.1 1.0.0.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Comodo Secure DNS
                                </td>
                                <td>
                                    8.26.56.26 8.20.247.20
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Norton ConnectSafe
                                </td>
                                <td>
                                    199.85.126.10 199.85.127.10
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SafeDNS
                                </td>
                                <td>
                                    195.46.39.39 195.46.39.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SmartViper
                                </td>
                                <td>
                                    208.76.50.50 208.76.51.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Dyn
                                </td>
                                <td>
                                    216.146.35.35 216.146.36.36
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Alternate DNS
                                </td>
                                <td>
                                    198.101.242.72 23.253.163.53
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    UncensoredDNS
                                </td>
                                <td>
                                    91.239.100.100 89.233.43.71
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    puntCAT
                                </td>
                                <td>
                                    109.69.8.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CNNIC SDNS
                                </td>
                                <td>
                                    1.2.4.8 210.2.4.8
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AliDNS
                                </td>
                                <td>
                                    223.5.5.5 223.6.6.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSPod Public DNS+
                                </td>
                                <td>
                                    119.29.29.29 119.28.28.28
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OneDNS
                                </td>
                                <td>
                                    117.50.11.11 117.50.22.22
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenDNS
                                </td>
                                <td>
                                    208.67.222.222 208.67.220.220
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Level 3
                                </td>
                                <td>
                                    209.244.0.3 209.244.0.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Quad9
                                </td>
                                <td>
                                    9.9.9.9 149.112.112.112
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNS.WATCH
                                </td>
                                <td>
                                    84.200.69.80 84.200.70.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    GreenTeamDNS
                                </td>
                                <td>
                                    81.218.119.11 209.88.198.133
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenNIC
                                </td>
                                <td>
                                    185.121.177.177 169.239.202.202
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Freenom World
                                </td>
                                <td>
                                    80.80.80.80 80.80.81.81
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FreeDNS
                                </td>
                                <td>
                                    37.235.1.174 37.235.1.177
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Yandex.DNS
                                </td>
                                <td>
                                    77.88.8.8 77.88.8.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Hurricane Electric
                                </td>
                                <td>
                                    74.82.42.42
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Neustar
                                </td>
                                <td>
                                    156.154.70.1 156.154.71.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Baidu Public DNS
                                </td>
                                <td>
                                    180.76.76.76
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    114DNS
                                </td>
                                <td>
                                    114.114.114.114 114.114.115.115
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSpai
                                </td>
                                <td>
                                    101.226.4.6 218.30.118.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CleanBrowsing
                                </td>
                                <td>
                                    185.228.168.9 185.228.169.9
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AdGuard DNS
                                </td>
                                <td>
                                    94.140.14.14 94.140.15.15
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CIRA Canadian Shield Family
                                </td>
                                <td>
                                    149.112.121.30 149.112.122.30
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Datos obtenidos desde&nbsp;<a href="https://www.publicdns.xyz">https://www.publicdns.xyz</a></p>
                    <h3>Anexo B, sondas de RIPE Atlas participantes</h3>
                    <p>Mediciones n&uacute;mero 46817987, 46817989 y 46817993</p>
                    <p><img src="/images/uso-dns-over-tls/fig5.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig6.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig7.png" alt="fig" /></p>
                </>}
                {myLang === 'en' && <>
                    <h2>Conlusions</h2>
                    <p>The results of this study show a timid start to the use of DoT in Latin America and the Caribbean, with 7.9% of open resolvers currently offering IPv6 DoT and 1.2% offering IPv4 DoT.<br />The reasons for the low adoption rates are mainly the fact that the technology is in its early stages and that there are probably limitations in the data sources used in this study. Here, it is assumed that a resolver that provides DoT is using the same IP address to which the classic DNS service delivers, except that it is using a different port. Perhaps some deployments prefer to separate these addresses, something that would not be detected by this study.<br />On the other hand, we detected problems in approximately 3.5% to 13.3% of failures from probes in the LAC region to known resolvers offering DoT. This means that there are network blocks in place that do not allow a service to reach 100% of the clients.</p>
                    <p>Appendix A: List of Public Resolvers</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>
                                    Name of service
                                </th>
                                <th>
                                    IPv4&nbsp;Address - es
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Verisign
                                </td>
                                <td>
                                    64.6.64.6 64.6.65.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Google Public DNS
                                </td>
                                <td>
                                    8.8.8.8 8.8.4.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Cloudflare
                                </td>
                                <td>
                                    1.1.1.1 1.0.0.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Comodo Secure DNS
                                </td>
                                <td>
                                    8.26.56.26 8.20.247.20
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Norton ConnectSafe
                                </td>
                                <td>
                                    199.85.126.10 199.85.127.10
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SafeDNS
                                </td>
                                <td>
                                    195.46.39.39 195.46.39.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SmartViper
                                </td>
                                <td>
                                    208.76.50.50 208.76.51.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Dyn
                                </td>
                                <td>
                                    216.146.35.35 216.146.36.36
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Alternate DNS
                                </td>
                                <td>
                                    198.101.242.72 23.253.163.53
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    UncensoredDNS
                                </td>
                                <td>
                                    91.239.100.100 89.233.43.71
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    puntCAT
                                </td>
                                <td>
                                    109.69.8.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CNNIC SDNS
                                </td>
                                <td>
                                    1.2.4.8 210.2.4.8
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AliDNS
                                </td>
                                <td>
                                    223.5.5.5 223.6.6.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSPod Public DNS+
                                </td>
                                <td>
                                    119.29.29.29 119.28.28.28
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OneDNS
                                </td>
                                <td>
                                    117.50.11.11 117.50.22.22
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenDNS
                                </td>
                                <td>
                                    208.67.222.222 208.67.220.220
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Level 3
                                </td>
                                <td>
                                    209.244.0.3 209.244.0.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Quad9
                                </td>
                                <td>
                                    9.9.9.9 149.112.112.112
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNS.WATCH
                                </td>
                                <td>
                                    84.200.69.80 84.200.70.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    GreenTeamDNS
                                </td>
                                <td>
                                    81.218.119.11 209.88.198.133
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenNIC
                                </td>
                                <td>
                                    185.121.177.177 169.239.202.202
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Freenom World
                                </td>
                                <td>
                                    80.80.80.80 80.80.81.81
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FreeDNS
                                </td>
                                <td>
                                    37.235.1.174 37.235.1.177
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Yandex.DNS
                                </td>
                                <td>
                                    77.88.8.8 77.88.8.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Hurricane Electric
                                </td>
                                <td>
                                    74.82.42.42
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Neustar
                                </td>
                                <td>
                                    156.154.70.1 156.154.71.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Baidu Public DNS
                                </td>
                                <td>
                                    180.76.76.76
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    114DNS
                                </td>
                                <td>
                                    114.114.114.114 114.114.115.115
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSpai
                                </td>
                                <td>
                                    101.226.4.6 218.30.118.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CleanBrowsing
                                </td>
                                <td>
                                    185.228.168.9 185.228.169.9
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AdGuard DNS
                                </td>
                                <td>
                                    94.140.14.14 94.140.15.15
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CIRA Canadian Shield Family
                                </td>
                                <td>
                                    149.112.121.30 149.112.122.30
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <p>Data obtained from&nbsp;<a href="https://www.publicdns.xyz">https://www.publicdns.xyz</a></p>
                    <h3>Appendix B: Participating RIPE Atlas Probes</h3>
                    <p>Measurements No. 46817987, 46817989, and 46817993</p>
                    <p><img src="/images/uso-dns-over-tls/fig5.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig6.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig7.png" alt="fig" /></p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Conclus&otilde;es</h2>
                    <p>Os resultados deste estudo mostram um in&iacute;cio t&iacute;mido do uso de DoT na Am&eacute;rica Latina e o Caribe, com uma taxa de&nbsp;<em>resolvers</em>&nbsp;abertos que oferecem DoT de 7,9% no IPv6 e 1,2% no IPv4.<br />Os motivos para a baixa ado&ccedil;&atilde;o s&atilde;o principalmente que a tecnologia est&aacute; ainda nas suas primeiras etapas e que provavelmente existem limita&ccedil;&otilde;es nas fontes de dados usadas no estudo. Aqui &eacute; assumido que um&nbsp;<em>resolver</em>&nbsp;que entrega DoT estaria usando o mesmo endere&ccedil;o IP que o servi&ccedil;o DNS cl&aacute;ssico entrega, s&oacute; que usando uma porta diferente. Talvez existam implementa&ccedil;&otilde;es que prefiram separar esses endere&ccedil;os, os que n&atilde;o seriam detectados neste estudo.<br />Do outro lado, detectamos problemas em aproximadamente 3,5-13,3% de falhas desde sondas na regi&atilde;o ALC para&nbsp;<em>resolvers</em>&nbsp;conhecidos que oferecem DoT. Isso indica que existem bloqueios nas redes que impediriam ter um servi&ccedil;o que chegue a 100% dos clientes.</p>
                    <p>Anexo A, lista de&nbsp;<em>resolvers</em>&nbsp;p&uacute;blicos</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>
                                    Nome do servi&ccedil;o
                                </th>
                                <th>
                                    Endere&ccedil;o es IPv4
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Verisign
                                </td>
                                <td>
                                    64.6.64.6 64.6.65.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Google Public DNS
                                </td>
                                <td>
                                    8.8.8.8 8.8.4.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Cloudflare
                                </td>
                                <td>
                                    1.1.1.1 1.0.0.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Comodo Secure DNS
                                </td>
                                <td>
                                    8.26.56.26 8.20.247.20
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Norton ConnectSafe
                                </td>
                                <td>
                                    199.85.126.10 199.85.127.10
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SafeDNS
                                </td>
                                <td>
                                    195.46.39.39 195.46.39.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SmartViper
                                </td>
                                <td>
                                    208.76.50.50 208.76.51.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Dyn
                                </td>
                                <td>
                                    216.146.35.35 216.146.36.36
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Alternate DNS
                                </td>
                                <td>
                                    198.101.242.72 23.253.163.53
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    UncensoredDNS
                                </td>
                                <td>
                                    91.239.100.100 89.233.43.71
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    puntCAT
                                </td>
                                <td>
                                    109.69.8.51
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CNNIC SDNS
                                </td>
                                <td>
                                    1.2.4.8 210.2.4.8
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AliDNS
                                </td>
                                <td>
                                    223.5.5.5 223.6.6.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSPod Public DNS+
                                </td>
                                <td>
                                    119.29.29.29 119.28.28.28
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OneDNS
                                </td>
                                <td>
                                    117.50.11.11 117.50.22.22
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenDNS
                                </td>
                                <td>
                                    208.67.222.222 208.67.220.220
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Level 3
                                </td>
                                <td>
                                    209.244.0.3 209.244.0.4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Quad9
                                </td>
                                <td>
                                    9.9.9.9 149.112.112.112
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNS.WATCH
                                </td>
                                <td>
                                    84.200.69.80 84.200.70.40
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    GreenTeamDNS
                                </td>
                                <td>
                                    81.218.119.11 209.88.198.133
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OpenNIC
                                </td>
                                <td>
                                    185.121.177.177 169.239.202.202
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Freenom World
                                </td>
                                <td>
                                    80.80.80.80 80.80.81.81
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FreeDNS
                                </td>
                                <td>
                                    37.235.1.174 37.235.1.177
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Yandex.DNS
                                </td>
                                <td>
                                    77.88.8.8 77.88.8.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Hurricane Electric
                                </td>
                                <td>
                                    74.82.42.42
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Neustar
                                </td>
                                <td>
                                    156.154.70.1 156.154.71.1
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Baidu Public DNS
                                </td>
                                <td>
                                    180.76.76.76
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    114DNS
                                </td>
                                <td>
                                    114.114.114.114 114.114.115.115
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    DNSpai
                                </td>
                                <td>
                                    101.226.4.6 218.30.118.6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CleanBrowsing
                                </td>
                                <td>
                                    185.228.168.9 185.228.169.9
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AdGuard DNS
                                </td>
                                <td>
                                    94.140.14.14 94.140.15.15
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CIRA Canadian Shield Family
                                </td>
                                <td>
                                    149.112.121.30 149.112.122.30
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Dados obtidos de&nbsp;<a href="https://www.publicdns.xyz">https://www.publicdns.xyz</a></p>
                    <h3>Anexo B, sondas de RIPE Atlas participantes</h3>
                    <p>Medi&ccedil;&otilde;es n&uacute;mero 46817987, 46817989 e 46817993</p>
                    <p><img src="/images/uso-dns-over-tls/fig5.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig6.png" alt="fig" /></p>
                    <p><img src="/images/uso-dns-over-tls/fig7.png" alt="fig" /></p>
                </>}
            </div>
        </div>
    )
}

export default Conclusiones
