import React, { useContext } from 'react';
import { LangContext, ReferenceContext } from '../../../context/DataContext';


function Referencias(props) {
    const {myLang} = useContext(LangContext)
    const {  activeReference } = useContext(ReferenceContext)
    return (
        <div> 
            <div id="bgp7" className='alerts alert_white'> 
                <h2>
                    {myLang === 'es' && <>Referencias</> }
                    {myLang === 'en' && <>References</> }
                    {myLang === 'pt' && <>Referências</> } 
                </h2>
                <ol className="reference_component">
                    <li id="ref-1"> <a href="https://simon.lacnic.net/" rel="noreferrer" target="_blank" className={activeReference === 'ref-1' ? 'active' : null}>https://simon.lacnic.net/</a></li>
                    <li id="ref-2"> <a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris/ris-raw-data" rel="noreferrer" target="_blank" className={activeReference === 'ref-2' ? 'active' : null}>https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris/ris-raw-data</a></li>
                    <li id="ref-3"><a href="https://tools.ietf.org/html/rfc6396.html" rel="noreferrer" target="_blank" className={activeReference === 'ref-3' ? 'active' : null}>https://tools.ietf.org/html/rfc6396.html</a></li>
                    <li id="ref-4"><a href="https://bgpstream.caida.org/docs/api/pybgpstream" rel="noreferrer" target="_blank" className={activeReference === 'ref-4' ? 'active' : null}>https://bgpstream.caida.org/docs/api/pybgpstream</a></li>
                    <li id="ref-5"><a href="https://bgpstream.caida.org/docs/tools/bgpreader" rel="noreferrer" target="_blank" className={activeReference === 'ref-5' ? 'active' : null}>https://bgpstream.caida.org/docs/tools/bgpreader</a></li>
                    <li id="ref-6"><a href="https://www.nro.net/" rel="noreferrer" target="_blank" className={activeReference === 'ref-6' ? 'active' : null}>https://www.nro.net/</a></li>
                    <li id="ref-7"><a href="https://www.caida.org/data/ixps/" rel="noreferrer" target="_blank" className={activeReference === 'ref-7' ? 'active' : null}>https://www.caida.org/data/ixps/</a></li>
                    <li id="ref-8"><a href="https://www.pch.net/" rel="noreferrer" target="_blank" className={activeReference === 'ref-8' ? 'active' : null}>https://www.pch.net/</a></li>
                    <li id="ref-9"><a href="https://lac-ix.org/ixpdb/" rel="noreferrer" target="_blank" className={activeReference === 'ref-9' ? 'active' : null}>https://lac-ix.org/ixpdb/</a></li>
                    <li id="ref-10"><a href="https://www.google.com/intl/en/ipv6/statistics.html#tab=per-country-ipv6-adoption&tab=per-country-ipv6-adoption" rel="noreferrer" target="_blank" className={activeReference === 'ref-10' ? 'active' : null}>https://www.google.com/intl/en/ipv6/statistics.html#tab=per-country-ipv6-adoption&amp;tab=per-country-ipv6-adoption</a></li>
                    <li id="ref-11"><a href="https://www.caida.org/home/" rel="noreferrer" target="_blank" className={activeReference === 'ref-11' ? 'active' : null}>https://www.caida.org/home/</a></li>
                </ol> 
          </div> 
        </div>
     );
}

export default Referencias;