import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import Alerts from '../../../components/Alerts'
import Button from '../../../components/Button'
import { MatchText } from 'react-ctrl-f';

export default function Resumen(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p1">
                {myLang === 'es' && <>
                    <Alerts color="alert_white">
                        <p>
                            <MatchText id="match-1"> Área: CSIRT de LACNIC </MatchText>
                            <br />
                            <MatchText id="match-2">Proyecto: SRR</MatchText>
                            <br />
                            <MatchText id="match-3"> Autor: LACNIC CSIRT  </MatchText>
                            <br />
                            <MatchText id="match-4"> Coordinación/Revisión: Graciela Martínez</MatchText>

                        </p>
                    </Alerts>
                    <Button color="button_blue" to="/reportes/ciberseguridad/estudio-de-recursos-de-internet-presentes-en-la-region-de-lacnic-con-protocolos-udp-que-posibilitan-ataques-de-tipo-ddos.pdf" target="_blank">Download (PDF)</Button>
                    <p>
                        <MatchText id="match-5">Este estudio logró identificar y mitigar vulnerabilidades sobre protocolos utilizados en la región para realizar ataques de denegación de servicios, con lo que logró una mejora en el nivel de seguridad de internet.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-6">Asimismo, propició la interacción entre el CSIRT de LACNIC y sus asociados en temas de seguridad, fortaleciendo el rol del equipo para compartir herramientas que permitan a las organizaciones que integran LACNIC dar un mejor servicio.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-7">La investigación identificó vectores que se usan en los ataques y, entre ellos, una lista de protocolos sobre UDP que pueden amplificar el tamaño de su respuesta. También posibilitó al CSIRT de LACNIC trabajar directamente con organizaciones afectadas y reducir sus riesgos.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-8">Este informe refleja el trabajo realizado para relevar el problema relacionado con la presencia de servicios del User Datagram Protocol (UDP) amplificables, que pueden ser utilizados para ataques de DDoS (denegación de servicio distribuido) en América Latina y el Caribe.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-9">El trabajo describe la metodología aplicada, los resultados obtenidos de un relevamiento inicial, las primeras recomendaciones para la mitigación y un análisis de situación posterior, en función de esas acciones.</MatchText>
                    </p>

                </>}
                {/*  */}
                {myLang === 'en' && <>
                    <Alerts color="alert_white">
                        <p>
                            <MatchText id="match-1">Departament:  CSIRT of LACNIC</MatchText>
                            <br />
                            <MatchText id="match-2">Project: SSR</MatchText>
                            <br />
                            <MatchText id="match-3">Author: LACNIC CSIRT</MatchText>
                            <br />
                            <MatchText id="match-4">Coordination/Revision: Guillermo Cicileo</MatchText>

                        </p>
                    </Alerts>
                    <Button color="button_blue" to="/reportes/ciberseguridad/study-of-internet-resources-in-the-lacnic-service-region-that-use-udp-and-enable-ddos-attacks.pdf" target="_blank">Download (PDF)</Button>
                    <p>
                        <MatchText id="match-5">This study managed to identify and mitigate vulnerabilities in the protocols used in the LACNIC service region to conduct denial of service attacks, consequently improving the level of Internet security.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-6">It also promoted interaction between LACNIC CSIRT and its members on security issues, strengthening LACNIC CSIRT's role in sharing tools that will allow LACNIC member organizations to provide better services.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-7">The study identified the vectors used in DDoS attacks, including a list of protocols over UDP that can amplify the magnitude of an attack. It also allowed LACNIC CSIRT to work directly with the affected organizations to help them reduce their risks.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-8">This report describes the study conducted to survey the presence of scalable User Datagram Protocol (UDP) services which can be used to perform distributed denial of service (DDoS) attacks in Latin America and the Caribbean.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-9">It includes the methodology applied for the study, presents the results of an initial survey, and offers a first series of recommended actions to mitigate these attacks. It also includes a subsequent situation analysis after the implementation of these actions.</MatchText>
                    </p>

                </>}
                {/*  */}
                {myLang === 'pt' && <>
                    <Alerts color="alert_white">
                        <p>

                            <MatchText id="match-1">Área: CSIRT de LACNIC</MatchText>
                            <br />

                            <MatchText id="match-2">Projeto: SSR</MatchText>
                            <br />

                            <MatchText id="match-3">Autor: LACNIC CSIRT</MatchText>
                            <br />

                            <MatchText id="match-4">Coordenação/revisão: Graciela Martínez</MatchText>

                        </p>
                    </Alerts>
                    <Button color="button_blue" to="/reportes/ciberseguridad/estudo-dos-recursos-da-internet-presentes-na-regiao-do-lacnic-com-protocolos-udp-que-possibilitam-ataques-do-tipo-ddos.pdf" target="_blank">Download (PDF)</Button>
                    <p><MatchText id="match-5"> Este estudo conseguiu identificar e mitigar vulnerabilidades em protocolos usados na região para realizar ataques de denegação de serviços, conseguindo uma melhoria no nível de segurança da Internet.
                    </MatchText></p>  <p>
                    <MatchText id="match-6"> Também fomentou a interação entre o CSIRT do LACNIC e seus associados em questões de segurança, fortalecendo o papel da equipe de compartilhar ferramentas que permitam às organizações que fazem parte do LACNIC prestar um melhor serviço.
                    </MatchText></p>  <p><MatchText id="match-7">
                        A pesquisa identificou vetores usados nos ataques, e entre eles, uma lista de protocolos sobre UDP que podem amplificar o tamanho de sua resposta. Também permitiu ao CSIRT do LACNIC trabalhar diretamente com as organizações afetadas e reduzir seus riscos.
                        </MatchText></p>  <p><MatchText id="match-8">
                        A pesquisa identificou vetores usados nos ataques, e entre eles, uma lista de protocolos sobre UDP que podem amplificar o tamanho de sua resposta. Também permitiu ao CSIRT do LACNIC trabalhar diretamente com as organizações afetadas e reduzir seus riscos.
                        </MatchText></p>  <p><MatchText id="match-9">
                        O trabalho descreve a metodologia aplicada, os resultados obtidos de um levantamento inicial, as primeiras recomendações para a mitigação e uma análise de situação posterior, com base nessas ações.
                        </MatchText></p>        
                </>}
            </div>
        </div>
    );
} 