import React from 'react'
import Button from '../../../components/Button'

function Conectividad2022En({styles}) {
    return (<> 
        <h1>Analysis of the paths followed by traffic within each country of LAC region</h1>
        <div><div id="p1" class="alerts alert_white">
            <p>Author: Agustín Formoso y Guillermo Cicileo</p>
            <p>Coordination/Revision: Área de Comunicaciones</p>
            <p>Edition: Septiembre 2023</p>
            <p>Departament: Área de Tecnología</p>
            <Button color="button_blue" to="/reportes/interconexion/lacnic-estudio_trafico_ pais_lac-en.pdf">Download (PDF)</Button>
        </div></div>
        
        
        <h2 id="resumen_select">Executive Summary</h2>
        <p>This document is part of a series that analyzes the measurements conducted by LACNIC in the LAC region during 2022. This document covers <strong>internal measurements within a country. i.e., traffic that has its origin and destination in the same country.</strong><br />
            Traceroutes were used to perform active traffic measurements. Traceroutes were measured from different points of the network to IP addresses in each country, for a total of approximately  <a href="#5500redes">5500 networks</a>. Various analyses were carried out based on this data. These analyzes are available to operators in the region and to other researchers who wish to delve deeper into the topic. The <a href="#anexo3grafos">data is also available</a> to anyone who wishes to conduct a more in-depth analysis.<br />
            Generally speaking, better latencies were measured in 2022 than in the 2020 campaign. Compared to the 2020 study, the current work adds the <a href="#perspectivacaminos">paths perspective</a>, in other words, the intermediate points traversed by the traffic.<br />
            Countries that are home to IXPs or large operators maintain more than 90% of their traffic within the country. In these cases, latency is typically less than 40 ms, and when <a href="#caminoslatencias">paths and latencies</a> are considered simultaneously, a reasonable picture emerges. This study also notes <a href="#fugatrafico">traffic leaks</a>, and where such leaks occur.<br />
            Zooming in to ASN level, it presents a ranking of each country with <a href="#redcentral">the most central networks</a>, i.e., those that captured the majority of the measurements. This ranking includes the networks of each country as well as those of other countries and those operating in multiple countries, and is led by established IXPs. In addition, <a href="#anexo3grafos">connectivity graphs</a> were created that show the relationships between different networks within each country.<br />
            One of the sections is dedicated to analyzing IXPs. It shows that IXPs typically route more than 40% of the measured traffic, that the traffic that traverses an IXP has a <a href="#aspath">shorter as-path</a>, and that they have <a href="#impactolatencia">a positive impact on latency</a> compared to traffic that does not traverse an IXP.<br />
            In addition, three annexes are provided with details of the destinations of outgoing traceroutes (ASNs), the routes with segments outside the country, and the connectivity graphs for each country.
        </p>
        <ul>
            <li><a href="#anexo1">Annex 1: Outgoing traceroute destinations (ASNs)</a></li>
            <li><a href="#anexo2">Annex 2: Routes with segments outside the country</a></li>
            <li><a href="#anexo3grafos">Annex 3: LAC country graphs</a></li>
        </ul>
        
        
        <h2 id="introduccion_select">Introduction: Regional Traffic Path Analyses</h2>
        <p>LACNIC has conducted several types of Internet measurements at the country level and especially at the regional level. One aspect we are interested in measuring is the connectivity between networks operating in the countries of the region, which is why we conducted studies based on latency. These studies showed a significant improvement in terms of connectivity times both within each country and between different countries. For example, see the study titled <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">Connectivity in the LAC Region in 2020</a> Connectivity in the LAC Region in 2020 in the Technical Reports section of the LACNIC website.<br />
            Following community interest and inquiries about the results of these studies based on latency measurements, we conducted further studies based on information available from the regional routing tables: <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-en-lac">BGP Interconnection in the Region of Latin America and the Caribbean</a> and <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">Local BGP Interconnection in Latin America and the Caribbean</a>. These studies provided us with an overview of connectivity at the routing level within each country and at the regional level, complimenting the previous information.<br />
            For this new study, we sought to actively measure traffic behavior using traceroutes. These traceroutes are measured from different points on the network to IP addresses in each country. Among other things, these results allow us to analyze which ASNs are the most central within each country, whether path lengths are reasonable, whether they remain within each country or interconnections are made abroad, and whether there are local IXPs in the country.
        </p>
        
        
        <h2 id="datosusados_select">Data Used in this Study</h2>
        <h3 id="fuente">Data Sources</h3>
        <p>About the data sources used in this study:</p>
        <ul>
            <li><strong>LACNIC Geofeeds:</strong> A service provided through MiLACNIC that allows members to add geolocation information for their IP blocks <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic">(+ info)</a>.</li>
            <li><strong>Registry Information</strong>: RIRs specify where IP addresses are registered, particularly those that appear in the dataset used in this study. The data is published by each RIR in the form of “delegated” files <a target='_blank' rel="noreferrer" href="https://ftp.ripe.net/pub/stats/">(+ info)</a>.</li>
            <li><strong>PeeringDB</strong>: A freely available, user-maintained, database of networks <a target='_blank' rel="noreferrer" href="https://www.peeringdb.com/">(+ info)</a>. Specifically, the following datasets were used:
                <ul>
                    <li>IX, with information about IXPs.</li>
                    <li>IXPFX, or “IX prefix”, with information about which prefixes are used in each IXP.</li>
                    <li>The two data sources above allow us to obtain the IXPs traversed by the measurement traceroutes.</li>
                </ul>
            </li>
            <li><strong>AS Names</strong>: Contains the asns.txt dataset that lists the common names by which the ASNs are known and the countries where they are being used. These labels are used in different visualizations and tables <a target='_blank' rel="noreferrer" href="https://bgp.potaroo.net/cidr/autnums.html">(+ info)</a>.</li>
            <li><strong>AS Population</strong>: Estimated customer populations per ASN, provided by APNIC. This data is useful to estimate the impact that the operation of an ASN has on end users. This data is only used in the section on <a href="#traceroutes">Traceroutes routed through another country</a><a target='_blank' rel="noreferrer" href="https://stats.labs.apnic.net/aspop">(+ info)</a>.</li>
            <li><strong>RIPE IPmap</strong>: A platform that provides geolocation information for IP addresses inferred through active measurements <a target='_blank' rel="noreferrer" href="https://ipmap.ripe.net/"> (+ info)</a>.</li>
            <li><strong>RIPE RIS</strong>: A routing data collection platform which, in addition to other large amounts of data, allows users to see which ASN originates a prefix on the Internet <a target='_blank' rel="noreferrer" href="https://ris.ripe.net/">(+ info)</a>.</li>
            <li><strong>Speedchecker</strong>: An active measurement platform that allows launching pings and traceroutes from probes in the region <a target='_blank' rel="noreferrer" href="https://www.speedchecker.com/">(+ info)</a>.</li>
        </ul>
        
        
        <h4 id="plataforma">Measurement Platforms</h4>
        <p>In previous studies, measurement platforms that could potentially be used for this type of study have been analyzed (see Introduction in <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">Connectivity in the LAC Region in 2020)</a>. The conclusion was that RIPE Atlas and Speedchecker were the most appropriate.<br />
            RIPE Atlas and Speedchecker are complementary platforms, as they cover different types of networks using comparable mechanisms (both measure through the same protocols). While Speedchecker covers more networks than RIPE Atlas, the former does not replace the latter; it is expected that, if RIPE Atlas coverage improves, it will do so by covering networks (or segments of networks) that are not yet covered by the other platform. The networks covered by both platforms can be grouped as follows:</p>
        
        
        <div class="row">
            <div class="columna" ><figure><figcaption>ASNs de la región LAC cubiertos por la campaña de mediciones</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico01_s_i.png"  alt="grafico01_s_i" /></figure></div>
            <div class="columna" ><figure><figcaption>ASNs de la región LAC cubiertos (y no cubiertos) por la campaña de mediciones</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico01_s_d.png"  alt="grafico01_s_d" /></figure></div>
        </div>
        <p>Given what has been discussed above about coverage and consistency with studies conducted in previous years, the 2022 measurement campaign was implemented through Speedchecker, without eliminating the option of carrying out a similar campaign with RIPE Atlas.</p>
        
        <h3 id="consideraciones">Considerations about the Data</h3>
        <p>The data was analyzed with certain considerations in mind:</p>
        <ul>
            <li>Although the goal of the report was to cover the countries in the LAC region, at the end of the measurement campaign there were not enough results available for all the countries.</li>
            <ul>
                <li>At the time of the measurements, probes had not been deployed in the following countries:  Netherlands Antilles (AN), Aruba (AW), Belize (BZ), Cuba (CU), Falkland Islands (FK), South Georgia and the South Sandwich Islands (GS), and Suriname (SR).</li>
                <li>In the following countries, results were insufficient (less than 10) and have been omitted in some sections of the document: French Guiana (GF, 9 results), Guyana (GY, 5 results).</li>
            </ul>
            <li>Measurements showed that some networks operate at a global level. In this study, these networks do not have a specific country of registration; however, under geographic location, they were assigned the code <strong>WW</strong> (worldwide). These networks are as follows:
                <ul >
                    <li>AS16625 AKAMAI-AS</li>
                    <li>AS1299 TWELVE99 Telia Company AB</li>
                    <li>AS3356 LEVEL3</li>
                    <li>AS13335 CLOUDFLARENET</li>
                    <li>AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.</li>
                    <li>AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL</li>
                    <li>AS3549 LVLT-3549</li>
                    <li>AS7195 EDGEUNO SAS</li>
                    <li>AS18747 IFX18747</li>
                    <li>AS23520 COLUMBUS-NETWORKS</li>
                    <li>AS3257 GTT-BACKBONE GTT Communications Inc.</li>
                    <li>AS174 COGENT-174</li>
                    <li>AS1239 SPRINTLINK</li>
                </ul>
            </li>
        </ul>
        
        
        
        <h2 id="5500redes_select">Scope of the Measurement Campaign</h2>
        
        <div class="row">
            <div class="columna_left"><p id="p_padding">The measurement campaign covered a total of 5,588 networks. These networks either hosted a probe (measurement origin), hosted an IP address (measurement destination), or were in the path to be traversed by the measurement (intermediate network, neither the origin nor the destination of the measurement). It was observed that 511 networks did not have a measurement probe or a destination IP address, in other words, they are “intermediate” networks surveyed by the measurement campaign. The following graph shows the distribution of the networks.</p></div>
            <div class="columna_right"><figure class="figure_c"><figcaption>ASNs vistos por la campaña de mediciones</figcaption><img style={styles.images}class="columna-imgc" src="/images/estudio-de-conectividad-2022/grafico02.png" alt="grafico02" /></figure></div>
        </div>
        
        
        
        
        <h2 id="impactolatencia_select">Latency</h2>
        <h3 id="compara">Comparison between 2020 and 2022</h3>
        
        <div class="row">
            <div class="columna"><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico03.png" alt="grafico03" /></div>
            <div class="columna"><p>In 2020 a <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-conectividad-lac-es.pdf">latency study was conducted in the LAC region</a> which measured the internal latency of several countries in the region. Because both studies share similar platforms and methodologies, their results can be compared. The image on the left is a comparison of the latency measurements obtained in 2020 vs 2022. How do they compare?</p>
                <p>A first observation is that many of the countries had lower latencies in 2022 than in 2020, particularly those countries with higher latencies. On average, the improvement was 44.4 ms.</p>
                <p>Panama is a case worth noting. There, <a target='_blank' rel="noreferrer" href="https://blog.lacnic.net/programa-frida/proyecto-de-sondas-en-panama-para-medir-la-latencia">a project to deploy measurement probes</a> began after the 2020 study for the purpose of improving the observability of regional connectivity. A comparison of the latencies in both years shows an improvement of ~34 ms.</p>
            </div>
        </div>
        
        
        <div class="row">
            <div class="columna"><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico04.png" alt="grafico04" /></div>
            <div class="columna"><p>Given that this measurement campaign is based on traceroutes, it is possible to compare the number of hops that remain within a country against those that abandon (and then re-enter) the country. Without any major surprises, measurements are in line with expectations: the latency of the hops leaving the country is greater than the latency of internal hops by a wide margin.</p>
                <p>However, hops that abandon the country are an indicator of suboptimal routing, as the destination of these packets is an IP address in the same country.</p>
                <p>In the <a href="#caminoslatencias">Latencies and Paths</a> section we will delve deeper into the difference in RTT between external and internal hops, and how this relates to the percentage of hops that exit a country.</p>
            </div>
        </div>
        
        
        
        <h2 id="perspectivacaminos_select">Paths</h2>
        <h3 id="traceroutes">Traceroutes Routed through Another Country</h3>
        
        <div class="row">
            <div class="columna"><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico05.png" alt="grafico05" /></div>
            <div class="columna"><p class="p_padding">A comparison of the paths that remained within a country against those that left the country allows certain observations.</p>
                <p class="p_padding">First, we can compare the percentage of hops and traceroutes that leave each country. A hop is considered outgoing to a country if its IP address is not registered in the country of origin; a traceroute is considered outgoing if at least one hop of that traceroute is outgoing.</p>
                <p class="p_padding">In addition to the country where an IP address (hop) is registered, latency is also considered. This criterion is detailed in the <a href="#geolocalizacion">Geolocation Connections</a> section, which explains that the reason for this is to minimize the number of resources registered outside a country but operating within it (low latency). For example, an IP address registered in the United States but 20 ms (or less) from a probe located in Argentina will be considered to be located in Argentina.</p>
                <p class="p_padding">The graph shows large countries where IXPs are present, such as Argentina, Brazil, or Chile, and where the percentage of outgoing traceroutes is low (2.7%, 5.6%, and 0.4%, respectively).</p>
                <p class="p_padding">It also shows countries where large operators are present and these percentages are low, such as Mexico and Uruguay (6.1% and 3.1%, respectively).</p>
                <p class="p_padding">The ranking of countries with the highest percentage of traceroutes routed outside the country was topped by Guyana, Honduras, Panama, Venezuela, El Salvador, Guatemala, and the Dominican Republic, with values above 10%. An analysis of ASNs with traceroutes traversing other countries shows the following: in Guyana, 100% of the ASNs observed by the experiment abandon the country; in Honduras, 66%; in Panama, 50%; in Venezuela, 41%, and in El Salvador, 25%. These “border crossings” are detailed in the table of <a href="#fugatrafico">ASNs directing traceroutes through another country</a>, which shows ASNs that decide to direct traceroutes through another country (those that route more than 5% of all traceroutes originated in that country).</p>
                <p class="p_padding">Another resource presented in this document is the table of <a href="#fugatrafico">ASNs that appear most often in traceroutes, by country</a>. It details all the ASNs that are seen the most in traceroutes launched from each country, not only those that cross a national border.</p>
                <p class="p_padding">Note that one of the goals of this study is to describe as best as possible the outcomes and observations resulting from the experiment. <strong>Readers have a better understanding of their local environment, so we invite them to use this study as a trigger to obtain a better interpretation of the data.</strong></p>
            </div>
        </div>
        
        
        
        
        <div class="row">
            <div class="columna">
                <p class="p_padding">Keep in mind that this document analyzes measurements that have their origin and destination in the same country. It is interesting to know the <strong>number of ASNs</strong> which must send a packet within one country but end up routing it through another, after which it returns to the country where it originated. This is the percentage of ASNs in each country that route packets through an ASN located outside the country, where 100% represents all the ASNs in that country observed in the experiment. The following graph shows the countries ranked based on this metric.</p>
                <figure class="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                    Cantidad de ASNs
                </figcaption><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico06.png" alt="grafico06" />
                </figure></div>
            <div class="columna"><p class="p_padding">Having an estimate of the number of users per ASN also allows estimating what percentage of each <strong>country's population</strong> falls within the cases mentioned above. These are shown in the next graph. </p>
                <div><figure class="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                    Porcentaje de la población
                </figcaption><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico07.png" alt="grafico07" /></figure></div>
                <p class="p_padding">Chile, Trinidad and Tobago, and Panama are of particular interest: even though some of their ASNs route outside these countries, because the number of users served by these ASNs is extremely low or inexistent, the percentage of the population ends up being zero.</p>
                <p class="p_padding">They are followed in the ranking by some cases with a population of approximately 3%.</p>
            </div></div>
        
        <p>Typically, countries where the percentage is high (more than 3% of the country's population) have a large component in one or at most two ASNs. These characteristics can be attributed to:
        </p><ul>
            <li>Colombia with greater participation of UFINET PANAMÁ yandTV AZTECA SUCURSAL COLOMBIA  (3 y 2% resp.)</li>
            <li>Uruguay with Telefónica Móviles del Uruguay</li>
            <li>Venezuela with TELEFóNICA VENEZOLANA</li>
            <li>Guayana with E-Networks</li>
            <li>Paraguay with Núcleo</li>
            <li>Mexico with Uninet</li>
            <li>Costa Rica with Instituto Costarricense de Electricidad and Telecom.</li>
            <li>Perú with Telefónica del Perú</li>
            <li>Honduras with Telefónica Celular and CABLECOLOR (27 y 14%)</li>
            <li>Guatemala with Telgu and COMCEL GUATEMALA (39 y 18%)</li>
            <li>República Dominicana with Compañía Dominicana de Teléfonos and ALTICE DOMINICANA (64 y 23%)</li>
        </ul><p></p>
        
        
        <h3 id="fugatrafico">Tables</h3>
        
        <p>Given that the measurements have their origin and destination in the same country, it is interesting to determine the countries through which the measurements exit the country. Two tables were created. The first one shows the countries where the measurements originated; the second, the ASNs from which the measurements exited the country. The second table can be viewed as an extension of the first. In both cases, the table shows the rows (countries or ASNs) that represent more than 5% of the total number of traceroutes leaving that country. Percentages are calculated based on the following:</p>
        <ul>
            <ol>1.	An outgoing traceroute is one that has at least one hop that first passes through an IP geolocated in the same country, and its next hop goes through an IP address geolocated in another country. For more information, see the <a href="https://www.reddisegno.com.ar/lacnic/2023/geolocalizacion">Geolocation</a> section.</ol>
            <ol>2.	To calculate the percentage, all outgoing traceroutes are counted and compared to the total number of traceroutes that originated in that country.</ol>
        </ul>
        
        <p>Note that, when geolocating networks that operate in multiple countries, they are assigned the code <strong>WW</strong>. For more information, go to the section titled <a href="#consideraciones">Considerations about the Data.</a></p>
        <p>The first table (country level) is included below. <a href="#anexo1">Annex 1: Outgoing Traceroute Destinations (ASNs)</a> shows the table in greater detail (ASN level).</p>
        <p>Reference:<br />
            COUNTRY: Country where the measurements originated. It is also the measurements’ destination country.<br />
            INTERMEDIATE_COUNTRY: Country traversed by the measurements.
        </p>
        
        <figure class="tabla_small">
        
            <table class="table">
                <thead>
                    <tr><th>COUNTRY</th><th>INTERMEDIATE_COUNTRY</th><th>PERCENTAGE</th></tr></thead>
                <tbody><tr><td>AR</td><td>WW</td><td>94%</td></tr>
                    <tr><td>BR</td><td>WW<br />US</td><td>82%<br />9%</td></tr>
                    <tr><td>CL</td><td>WW</td><td>100%</td></tr>
                    <tr><td>CO</td><td>WW</td><td>100%</td></tr>
                    <tr><td>CR</td><td>WW<br />GT</td><td>88%<br />12%</td></tr>
                    <tr><td>DO</td><td>WW</td><td>100%</td></tr>
                    <tr><td>EC</td><td>WW</td><td>100%</td></tr>
                    <tr><td>SV</td><td>WW</td><td>100%</td></tr>
                    <tr><td>GT</td><td>WW<br />US</td><td>87%<br />13%</td></tr>
                    <tr><td>GY</td><td>WW</td><td>100%</td></tr>
                    <tr><td>HN</td><td>WW</td><td>100%</td></tr>
                    <tr><td>MX</td><td>WW<br />US</td><td>55%<br />44%</td></tr>
                    <tr><td>PA</td><td>WW</td><td>100%</td></tr>
                    <tr><td>PY</td><td>BR<br />WW</td><td>50%<br />50%</td></tr>
                    <tr><td>PE</td><td>WW</td><td>100%</td></tr>
                    <tr><td>TT</td><td>WW<br />GD<br />JM</td><td>67%<br />25%<br />8%</td></tr>
                    <tr><td>UY</td><td>WW<br />BR</td><td>75%<br />25%</td></tr>
                    <tr><td>VE</td><td>WW<br />CO<br />US</td><td>69%<br />15%<br />15%</td></tr>
                </tbody>
            </table>
        </figure>
        
        <h3 id="redcentral">ASNs Observed Most Frequently in Traceroutes, by Country</h3>
        <p>Based on the measurements, it is possible to determine which networks are observed most frequently in traceroutes within a country, regardless of whether such networks are internal to the country or other networks traversed by the traceroutes. This metric can provide an idea of the importance of each network within the country. To do this, all traceroutes involving each network are counted and divided by the number of traceroutes launched from that country, to obtain a percentage. A ranking is then prepared based on this percentage and filters are applied to include only those networks that represent more than 5%. The results are included below (note that the percentages should not total 100%, as the same traceroute involves several networks).</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/asn_1.png"  alt="asn_1" /></p>
        
        <h2 id="caminoslatencias_select">Latency and Paths</h2>
        
        <p>Measurements reveal two important findings:</p>
        <ul>
            <li>When sending packets to a destination within the same country, some countries route a part of these packets through another country.</li>
            <li>This often leads to increased latency compared to packets routed locally within the country.</li>
        </ul>
        <p>The following graph illustrates these two variables, with the x-axis representing the difference in Round-Trip-Time (RTT) between packets that exit the country and packets that do not, while the y-axis represents the percentage of packets that exit the country.</p>
        
        <p></p><figure><figcaption>Latencia en países de LAC<br />
            Hops que salen vs. hops que permanecen dentro de cada país
        </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico08.png" class="columna-grafos"  alt="grafico08" /></figure><p></p>
        
        <ul>
            <li>x-axis: Difference in RTT between hops that leave the country and those that do not. High values reflect a penalty when packets exit the country. Low values suggest similar latencies.</li>
            <li>y-axis: Percentage of hops that exit the country. Typically, this number should be low. The cases of Bolivia, French Guyana, and Nicaragua are worth noting, as they did not have any hops outside the country.</li>
        </ul>
        
        <p>Based on the above, countries can be grouped into the following categories:</p>
        <ul>
            <li>Upper right quadrant: Countries that have an RTT penalty when leaving the country as well as high percentages. This quadrant is empty; connectivity at the country level should be reviewed.</li>
            <li>Lower right quadrant: Countries where a penalty is paid when routes exit the country, but this is infrequent. These are routes that exit the country and have <strong>high latency</strong></li>
            <li>Upper left quadrant: These countries pay a low penalty in terms of latency but have many <strong>outgoing routes</strong>.</li>
            <li>Lower left quadrant: A low penalty is paid, but this penalty is rare; this traffic could be considered <strong>optimal routing</strong>.</li>
        
        </ul>
        
        <p>Similar observations can be made if instead of the number of outgoing packets, the graph shows the number of outgoing traceroutes. The distribution of the countries is similar, the major difference being that the percentage is higher (the traceroute is considered to exit the country if even a single hop is outside the country, hence the higher percentages):</p>
        
        <p></p><figure><figcaption>Latencia en países de LAC<br />
            Traceroutes que salen vs. traceroutes que permanecen dentro de cada país
        </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico09.png" class="columna-grafos"  alt="grafico09" /></figure><p></p>
        
        
        <h2 id="grafos_select">Graphs</h2>
        <p>Graphing measurement results is useful, as it allows applying known methods. For example, loops or circuits can be detected. Based on the traceroute results, a graph was created for each country, where:</p>
        <ul>
            <li>Each node represents a network, either an ASN or an IXP.</li>
            <li>Each edge of the graph represents the hop on a traceroute between consecutive ASNs.</li>
        </ul>
        
        <p>The following is an example traceroute that was part of the measurement campaign. It should be noted that each row corresponds to a hop of the traceroute, in other words, the asn_origin and asn_destination columns represent the origin and destination of that hop.</p>
        <p>The traceroute's origin ASN is the asn_origin of the first hop, while the traceroute's destination ASN is the asn_destination of the last hop (last row). In the following case, the traceroute is launched from AS 6057 and its destination is AS <strong>19422</strong>.</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/traceroute1.png"   alt="traceroute1"/></p>
        
        
        <p >Example of a traceroute from the measurement campaign,
            with its origin and destination ASNs highlighted in bold.
        </p>
        
        <p>The traceroute above provides the following information:</p>
        <ul>
            <li>It originates from the probe with the address 167.57.114.53, announced by ASN 6057.</li>
            <li>Its destination is 200.58.155.34, announced by ASN 19422.</li>
            <li>The traceroute traverses ASNs 6057 ➔ 12956 ➔ 19422.</li>
            <li>It has 11 hops and a variable RTT.</li>
        </ul>
        
        <p>The traceroute above contains more information than necessary to build the latency graph. Since we are looking for hops between ASNs, we can discard any hops that do not connect two ASNs. In addition, we will maintain the RTT difference between ASNs, i.e., RTTn+1 - RTTn. This allows reducing the amount of information for a traceroute, maintaining pairs of hops (rows 1-2, 3-4, etc.):</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/traceroute2.png"  alt="traceroute2" /></p>
        
        
        
        <p >Example of a traceroute reduced to the necessary data,
            which will be included in the corresponding country graph.
        </p>
        
        <p>Based on this information, a graph with the following characteristics can be created:</p>
        <ul>
            <li>3 nodes 6057, 12956, and 19422</li>
            <li>2 edges: 6057 ➔ 12956 and 12956 ➔ 19422</li>
            <li>An RTT value is added to the edges, which will be used by the graph library to calculate the position of the nodes when coloring them: nodes with lower RTT will be located closer to each other. This is the RTT difference between hopn and hopn+1.
                <ul>
                    <li>Between 6057 ➔ 12956, the difference in RTT is 14.2 ms - 9.3 ms = 4.9 ms</li>
                    <li>Between 12956 ➔ 19422 it is 15.1 - 14.2 = 0.9 ms</li>
                </ul>
        
            </li>
        </ul>
        
        <p>A color scale can also be created where each color represents the country where the node is located (ASN). Nodes are marked with a colored circle, while white circles represent an IXP. The ASN ➔ country relationship was determined as follows:</p>
        <ul>
            <ol>1. Based on the IP address of each traceroute hop, we queried:
                <ul>
                    <ol>a. The whois for the country where the address was registered,</ol>
                    <ol>b. The RIPE RIS for the ASN announcing the address to the Internet.</ol>
                </ul>
            </ol>
            <ol>2. With an IP address and an ASN for points 1.a and 1.b, it can be concluded that the ASN is assigned in that country.
                <ul><ol>a. If there are multiple IP addresses announced by the same ASN and all these IP addresses are registered in the same country, then the ASN is still located in the only country obtained from 1.a. However, if the ASN announces addresses registered in different countries, for example, in countries XX and YY, then the ASN is marked as assigned in XX|YY.</ol></ul>
            </ol>
            <ol>3. In addition, if a country cannot be determined for an ASN after applying the method above, a potaroo.net database is used which contains the country where the ASN is estimated to operate. ASNs localized this way, for example to country XX, are identified as XX*.</ol>
        </ul>
        
        
        
        <p></p><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico10.png" class="columna-grafos" alt="grafico10" /></figure><p></p>
        
        <p>This analysis was applied to all countries in the region and the results are shown in <a href="#anexo3grafos">Annex 3: Graphs for the Countries in LAC.</a></p>
        
        <h2 id="ixp_select">Internet Exchange Points</h2>
        <h3 id="presencia">Presence</h3>
        
        <p>By observing the traceroutes we can determine what percentage of the traceroutes launched in one country traverse IXPs located in the same country. To do so, we compare the IP addresses seen in the traceroutes against the IP addresses assigned to IXPs registered in PeeringDB. It should be noted that, if the IXPs of a given country did not register their networks in PeeringDB, they will not be considered and will not be counted as IXPs. If we count the number of traceroutes that traverse an IXP in that country, we obtain the following:</p>
        <p><b>IXPs seen by traceroutes in the measurement campaign</b></p>
        <p>AR AR-IX Cabase (Argentina)<br />
            BO PIT Bolivia (La Paz)<br />
            BR IX.br (PTT.br)<br />
            CL PIT Santiago - PIT Chile (Santiago)<br />
            CO NAP Colombia (Bogota)<br />
            CR CRIX (San Jose)<br />
            EC NAP.EC - UIO (Quito/UIO)<br />
            HN IXP-HN (Tegucigalpa)<br />
            PA InteRed Panama (Panama)<br />
            PE Peru IX (PIT Peru sac) - Lima (Lima)<br />
            PY IXpy (San Lorenzo)<br />
            TT TTIX (Barataria)
        </p>
        
        <p></p><figure class="figure_c figure_left"><figcaption>Presencia de IXPs<br />
            Traceroutes que pasan por el IXP
        </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico11.png" class="columna-img"  alt="grafico11" /></figure><p></p>
        
        <p>As the graph shows, typically between 40 and 50% of the measured traffic traverses an IXP. Two cases that should be noted are those of Brazil and Chile, where we expected to obtain a much higher number of routes through the IXP. These cases do not speak so much about the presence of an IXP, but rather about the bias of the measurement platform.</p>
        
        <h3>Latencies</h3>
        
        <div class="row">
            <div class="columna"><figure class="figure_c figure_left"><figcaption>Comparación de latencia<br />
                Traceroutes internos a cada país
            </figcaption><img style={styles.images}class="columna-img" src="/images/estudio-de-conectividad-2022/grafico12.png" alt="grafico12" /></figure></div>
            <div class="columna"><p>It is also useful to analyze the latency of traceroutes that traverse an IXP and compare this with the latency of those that do not. In this case, the RTT of the last hop of the traceroute was considered. A graph of these values is shown on the left.</p>
                <p>A first glance shows that traceroutes that traverse an IXP have lower latencies. The exception where the highest latencies are those of traceroutes that traverse an IXP is Chile, where the latencies differ by very little (17.0 vs. 15.6 for traceroutes that traverse and do not traverse an IXP, respectively).</p>
                <p>Two cases worth noting are Panama and Trinidad and Tobago, where the latencies of traceroutes that traverse an IXP are lower and in line with the rest of the region (black bars). However, the latencies of traceroutes that do not traverse an IXP are noticeably high (gray bars).</p>
            </div>
        </div>
        
        
        <h3 id="latencia">Presence and Latencies</h3>
        
        <p>In this section we will perform an analysis similar to the one we performed in the <a href="https://www.reddisegno.com.ar/lacnic/2023/caminoslatencias" target='_blank' rel="noreferrer">Latencies and Paths</a> section, i.e., considering two variables and plotting them on a Cartesian coordinate system. This allows for a quick analysis of the region and dividing the cases into four quadrants.</p>
        <p>The <strong>difference in RTT</strong> between the traceroutes that traverse an IXP and those that do not allows quantifying the impact of an IXP on the local ecosystem. In addition, the <strong>percentage of traceroutes that do not traverse an IXP</strong> with respect to the total number of traceroutes that are launched allows quantifying the frequency of this behavior. These two metrics provide an idea of:</p>
        <ul>
            <li>Whether a high penalty is paid (RTT difference)</li>
            <li>How frequently this penalty is paid (percentage of traceroutes)</li>
        </ul>
        <p>If these two metrics are plotted with the RTT difference on the x-axis and the percentage of traceroutes that do not traverse an IXP on the y-axis, different cases can be quickly detected:</p>
        <ul>
            <li>Upper right quadrant: Fortunately, there are no countries in this quadrant. These would be cases where little traffic traverses the local IXP, and the traffic that does so pays a very high latency penalty.</li>
            <li>Lower right quadrant: For example, Panama (PA). A lot of traffic traverses the IXP and anyone who decides not to do so pays a latency penalty.</li>
            <li>Upper left quadrant: For example, Peru (PE). Not much traffic traverses the IXP. However, the penalty for not doing so is very low.</li>
            <li>Lower left quadrant: For example, Bolivia (BO). Much of the traffic traverses the IXP. However, the penalty for not doing so is not very high.</li>
        </ul>
        
        <p></p><figure><figcaption>Latencia  en países de LAC<br />
            Traceroutes que pasan por un IXP vs. Los que no lo hacen
        </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico13.png" class="imagen_wide"  alt="grafico13" /></figure><p></p>
        
        
        <h3 id="aspath">Number of Hops and Networks</h3>
        
        <p>Two metrics can be determined based on the traceroutes: the number of hops in a traceroute, and the number of networks it traverses. There are two types of networks: ASNs and IXPs. To determine the type of network, two data sources are considered: reverse IP to ASN lookup according to RIPE RIS, and IXP information from PeeringDB.</p>
        <p>The graph on the left is obtained by applying the same concept to the number of hops in each traceroute. This information shows that traceroutes that traverse an IXP are typically shorter, but with some caveats. In Chile, Colombia, Costa Rica, Ecuador, and Trinidad and Tobago there are more hops in traceroutes that use the IXP. As discussed in the previous section, with the exception of Chile, these five countries have better latency values when using the IXP, so the number of hops is not very useful for measuring impact.</p>
        
        <div class="row">
            <div class="columna" ><figure><figcaption>Número de hops <br />
                Traceroutes internos a cada país
            </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico14.png" class="imagen" alt="grafico14" /></figure></div>
            <div class="columna" ><figure><figcaption>Redes<br />
                Traceroutes internos a cada país
            </figcaption>
                <img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico15.png" class="imagen" alt="grafico15" /></figure></div>
        </div>
        
        <p>Similarly, the number of networks (whether ASNs or IXPs) traversed by the traceroutes can be graphed.</p>
        <p>The initial expectation is that the influence of an IXP will be visible in the countries where an IXP is present. It should be noted that this metric includes the IXP traversed by the traceroute. To limit the number to just the ASNs, we must subtract 1. For instance, Costa Rica (CR) has an average of 2.4 networks; by subtracting 1, this becomes 1.4 ASNs.</p>
        <p>The first observation is that there were instances where traceroutes involving the local IXP featured more networks than those with no IXP involvement. The first case is that of Honduras, where the number of networks that go through IXP-HN (Tegucigalpa) is reported as 1.0. The reason for this is that all other IP addresses for the traceroutes traversing the IXP are private addresses, and it is not possible to determine an ASN for them. This is why, in this case, the only network that counts is that of the IXP. The same problem might be happening in other cases and skewing the data.</p>
        <p>This inconsistency led to the question of whether data quality might be improved. Could we apply filters to ensure that the data we work with is more reliable when counting networks? A simple rule was applied:</p>
        <ul>
            <li>For traceroutes involving the IXP, a minimum of three networks must be observed, including the IXP network: ASN1 --&gt; IXP --&gt; ASN2.</li>
            <li>For traceroutes not involving the IXP, this minimum is reduced to two: ASN1 --&gt; ASN2.</li>
        </ul>
        <p>Once this restriction is applied, the data is more in line with expectations (similar number of networks in both cases). The case of Honduras mentioned above is automatically dropped.</p>
        <p>In this case it was also decided to include an additional metric: the number of networks in a traceroute that traverses an IXP, excluding the IXP. In other words, this metric counts only the ASNs involved in the traceroute and is represented by the white bars in the graph.</p>
        
        
        <p></p><figure><figcaption>Redes<br />
            Traceroutes internos a cada país
        </figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico16.png" class="imagen" alt="grafico16" /></figure><p></p>
        
        <p>Once filtered, the data shows that traceroutes generally involve a smaller number of ASNs, as they involve the local IXP.</p>
        <p>Some cases that should be noted:</p>
        <ul>
            <li>In the case of Haiti, a single sample was obtained that passed through HIX Haiti (Port-au-Prince), so it was dropped and removed from the graph.</li>
            <li>In Peru, only five traceroutes traversed Perú IX (PIT Peru sac) - Lima (Lima), originating from two networks (out of a total of 8 seen by the measurement campaign).</li>
        </ul>
        
        <h3 id="ejemplo">Example: Argentina</h3>
        
        <p>In the previous sections we saw that it is common to have lower latencies and a smaller number of hops in those traceroutes that traverse an IXP. Let's consider the example of Argentina, a country with a strong IXP presence and a considerable number of measurements. A look at the details of the dataset shows the following: </p>
        <p>In terms of latency, the median RTT of the slowest traceroutes (99th percentile) is 175 ms for those that traverse an IXP and 215 ms for those that do not. Even though we are talking about the slowest cases, a difference of 40 ms is not trivial. The typical traceroute (50th percentile) has a median of 21.3 ms if it goes through an IXP, and 24.4 if it does not. This is a 3 ms difference. Below 12 ms there is no significant difference. This occurs approximately at the 20th percentile, which means that the 20% of the fastest traceroutes have similar latency values when comparing those that go through an IXP against those that do not.</p>
        <p>In terms of the number of hops, in the worst-case scenario (99th percentile), traceroutes require 19 hops to reach their destination if they traverse an IXP. If they do not go through an IXP, they require 21. The typical case (50th percentile) is 9 hops if they go through an IXP, and 11 if they don't. For the shortest traceroutes (7th percentile, less than 6 hops) there are no differences.</p>
        <p>In terms of the number of networks traversed by the traceroutes, very similar values are noted. Two additional observations:</p>
        <ul>
            <li>Traceroutes that traverse an IXP include a minimum of three networks: the origin network, the IXP, and the destination network.</li>
            <li>Traceroutes that do not traverse an IXP include a minimum of two: this is the case of a direct connection.</li>
        </ul>
        
        
        <div class="row">
            <div class="columna"><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico17a.png" class="imgdos" alt="grafico17a" /></div>
            <div class="columna"><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico17b.png" class="imgdos" alt="grafico17b" /></div>
            <div class="columna"><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico17c.png" class="imgdos" alt="grafico17c" /></div>
        </div>
        
        <p>The clearest conclusion is that the measurements that pass through an IXP in Argentina have measurably better latency and number of hops. For a typical traceroute in this measurement campaign, the improvement is ~18% in terms of hops and ~12% in terms of latency. For shorter or lower latency traceroutes (less than 7 hops or less than 20 ms) the improvement is almost zero.</p>
        
        <h2 id="geolocalizacion_select">Geolocation</h2>
        <h3 id="fuentedato">Data Sources</h3>
        <p>The main geolocation source for this study were the <a href="https://ftp.ripe.net/pub/stats/" target='_blank' rel="noreferrer">delegated-extended files</a> provided by the different RIRs. These files list the country where the legal entity responsible for an IP block is incorporated, and this in itself is a source of errors. This is why it was necessary to make some corrections.</p>
        <p>A secondary geolocation source was <a href="https://ipmap.ripe.net/" target='_blank' rel="noreferrer">RIPE IPmap</a>, where the geolocation of IP addresses is based on active measurements by Atlas probes. Geographic location can be inferred for IP addresses that respond with a sufficiently low ping to a probe with a known location.</p>
        <p>Another secondary source of data was <a href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic" target='_blank' rel="noreferrer">LACNIC's Geofeeds</a> service, where prefix holders can specify the geographic location where the addresses are being used.</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico18.png" class="imagen"  alt="grafico18" /></p>
        
        
        <p>The three geolocation sources are included based on the following criteria, with the sources listed in descending order of precision:</p>
        <ul>
            <ol>1. First, the information from the <a href="#consideraciones">Considerations about the Data</a>, section, which are IP addresses belonging to global networks operating in multiple countries.</ol>
            <ol>2. Next, the information from Geofeeds, considered to be the most reliable source of information, as it reflects the operators’ statements of where the IP address blocks are being used.</ol>
            <ol>3. If no information is available in Geofeeds, the information from IPmap is considered.</ol>
            <ol>4. Similarly, information is taken from registry files.</ol>
        </ul>
        
        
        <h3 id="correccion">Corrections: Delta RTT</h3>
        
        <p>When analyzing the data, very low latency values between distant countries were observed, for example, measurements of 5 ms between Argentina and the United States. This led to an analysis of the latency of hops in the same traceroute, as follows:</p>
        <ul>
            <ol>1. Each traceroute is observed from hop #1 to the last hop, analyzing each pair of adjacent hops (1-2, 2-3, 3-4, etc.).</ol>
            <ol>2. Comparisons of pairs of hops that belong to the same country are discarded.</ol>
            <ol>3. The difference in RTT, or Delta RTT, between the two hops of each pair of hops is calculated as RTTn+1 - RTTn. This means that, if the first hop responds with 3 ms and the second with 4 ms, Delta RTT is 4 ms - 3 ms = 1 ms.  If the first hop responds with 4 ms and the second with 3 ms, Delta RTT is -1 ms. This is possible because the hops that are further away do not necessarily respond with higher latency values due to various factors, including the fact that measurements are not taken at exactly the same moment, the load level of the routers varies, and others.</ol>
            <ol>4. The Delta RTT values allow determining whether the IPs at the ends of the link are close to each other in terms of latency. In the section titled <a href="#determinando">Determining the Latency Threshold</a> we will discuss how proximity is defined in the context of this study. Based on this metric, the following modification can be introduced:
                <ul>
                    <ol>a. For IP addresses at the ends of the link with known geolocation obtained by the methods listed above, the countries where they are registered can be compared.</ol>
                    <ol>b. If these are different countries and have a very low latency, the registry information is considered not to reflect the latency, and the country of hopn+1 is overwritten with that of the country of hopn.</ol>
                </ul>
            </ol>
        </ul>
        
        <p>After incorporating the concept of Delta RTT, it is placed in fourth place on the list of geolocation sources to be queried (more reliable than registry information but less reliable than IPmap). This is what the final list looks like (the percentage of geolocated IPs is included between parentheses):</p>
        <ul>
            <ol>1. Global networks (26%)</ol>
            <ol>2. Geofeeds (1.7%)</ol>
            <ol>3. IPmap (10%)</ol>
            <ol>4. Delta RTT (1.9%)</ol>
            <ol>5. Registry (60%)</ol>
        </ul>
        
        <p>This shows that geolocation for 40% of the IPs does not come from registry files, which represents a considerable improvement in data quality.</p>
        
        <h4 id="determinando">Determining the Latency Threshold</h4>
        
        <p>So far, we have talked about the concept of low latency, but how low must the latency be to consider that IPn+1 is in the same country as IPn? One possible reference is the threshold that RIPE IPmap uses to determine that an IP address is very close to a RIPE Atlas probe: 10 ms.</p>
        <p>Before determining a threshold, what do national latency profiles show? Let's compare the latency as a function of the location of the IP addresses, using information from reliable sources (Geofeeds and IPmap). The latency profiles of some countries are included below, specifying whether the IP address remains in the country or not.</p>
        
        <p>Traceroute graph for AR</p>
        
        <div class="row">
            <div class="columna" ><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico19a.png" class="imgdos" alt="grafico19a" /></div>
            <div class="columna" ><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico19b.png" class="imgdos" alt="grafico19b" /></div>
            <div class="columna" ><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico19c.png" class="imgdos" alt="grafico19c" /></div>
            <div class="columna" ><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico19d.png" class="imgdos" alt="grafico19d" /></div>
        </div>
        
        <p>These profiles share similar characteristics: a main latency component corresponding to measurements that remain within the country (orange bars), and the remaining measurements with one or more components. In almost all countries the main component is usually clearly delimited and below 50 ms. This serves as a first estimate of a latency threshold that can help distinguish whether an RTT is within a country or not.</p>
        <p>An analysis of the measurements of the region as a whole shows that the primary component is centered around 17.4 ms (median). In order to have a simple and easy-to-remember criterion, the threshold for this measurement campaign was set at <strong>20 ms</strong>.</p>
        <p>An open question is whether a uniform threshold should be applied to every country, given their substantial variation in size, number of networks, and other characteristics.</p>
        <p>Another open question is whether the threshold for RTTs remaining within a country should be the same as the threshold for RTTs exiting the country. In other words, will there be an RTT1 for measurements that remain and an RTT2 for measurements that exit the country? Examples of this include countries such as Brazil and Argentina, where the measurements that remain in the country tend to be under 50 ms and those that leave the country are typically above 100 ms.</p>
        <p>After applying the Delta RTT criterion across the entire set of measurements, it became evident that this approach provided information that supplemented the other methods. The following graph shows the number of unique IP addresses for which each method adds geolocation information. As the graph shows, out of the three sources, IPmap contributes the most in terms of geolocation. It also shows that the contributions of each source are complementary, with little overlap (IP addresses for which two different sources provide a result).</p>
        <p>An example where delta RTT had an impact is the case of Mexico, a country that is very close to the United States in terms of latency. Of the geolocation corrections applied, 16% of the addresses registered in the United States were corrected to Mexico. It was also noted that 52% of hops go through operators such as Cogent (AS174, with 8.2% of cases in that interval) or Level 3 (AS3356 and AS3549, with 6.1%). Similar observations were made for the other countries. In the case of Argentina, 55% of cases were in that interval (worth noting are the cases of Telecom Italia with 2.3% and Level 3 with 1.4%), while in Colombia it was 37% (Level 3 with 11.3% and GTT Communications with 3.7% ).</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico20.png" class="imagen"  alt="grafico20" /></p>
        
        <h2 id="conclusion_select">Conclusions</h2>
        
        
        <p>While not the primary objective of this research, a comparison between the 2020 and 2023 results revealed that the latency values for 2023 are notably better for most countries.</p>
        <p>As for the traffic maintained locally within a country, in most cases percentages exceed 90%, which is a good number. In larger countries such as Argentina, Brazil, and Mexico, these percentages were high (97%, 94%, and 94% respectively). The presence of IXPs and large operators contributes to the high percentages. Only in certain specific cases the percentages were under 90%.</p>
        <p>If we also take into account the latency introduced when sending traffic abroad (and then having it return to the country), few countries introduce alarming latencies. Generally speaking, no traffic leaks through other countries, and when it does, the latency that is introduced is not cause for concern.</p>
        <p>An analysis of the centrality of the networks, i.e., the amount of traffic that traverses each network, showed that <strong>IXPs and the main networks are at the top of the ranking</strong> in each country.</p>
        <p><strong>Local IXPs in different countries are visible both in terms of the amount of traffic as well as in terms of performance</strong>. Additionally, the traffic that circulated through an IXP had lower latencies than the rest of the traffic.</p>
        <p>With an increasingly globalized and optimized Internet, finding reliable geolocation sources is extremely important. RIR registry information is not precise enough to geolocate Internet infrastructure. The study considers various geolocation sources and adds one of its own. Overall, the precision of 40% of results is improved.</p>
        
        <h2 id="anexo1_select">Annex 1: Outgoing Traceroute Destinations (ASNs)</h2>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/anexo1.png"  alt="anexo1"/></p>
        
        
        
        <h2 id="anexo2_select">Annex 2: Routes with Segments outside the Country</h2>
        <h3 id="a2res">Summary of Segments</h3>
        
        <p>The following is a list of traceroutes that exit the country. In other words, traceroutes that originated in an ASN, traversed through a foreign country, and then re-entered the same ASN. These cases might be an indication of suboptimal routing.</p>
        <p>The tables are presented in a condensed format to better display all the information. Description of the columns:</p>
        <p><b>ip_o:</b> ip_origin, probe IP address<br />
            <b>ip_d:</b> ip_destination, hop IP address<br />
            <b>asn_o:</b> asn_origin, probe ASN<br />
            <b>asn_d:</b> asn_destination, hop ASN<br />
            <b>co:</b> country_origin, country where the probe is located<br />
            <b>cd:</b> country_destination,country where the hop is located<br />
            <b>h:</b> hop_number, hop number<br />
            <b>r:</b> RTT
        </p>
        
        
        <p>ASNs in <strong>AR</strong> with a route that traverses another country</p>
        <p>AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA)</p>
        
        
        
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta0.png"  alt="ruta0"/></p>
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta1.png"  alt="ruta1"/></p>
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta2.png"  alt="ruta2"/></p>
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta3.png"  alt="ruta3"/></p>
        
        
        <p>ASNs in <strong>CO</strong> with a route that traverses another country</p>
        <p>AS265688 (SINERGY SOLUCIONES INTEGRALES) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS265688 (SINERGY SOLUCIONES INTEGRALES)</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta4.png"  alt="ruta4" /></p>
        
        
        
        <p>ASNs in <strong>PE</strong> with a route that traverses another country</p>
        <p>AS6147 (Telefonica del Peru S.A.A.) --&gt; AS12956 (TELXIUS TELEFONICA GLOBAL SOLUTIONS SL, WW) --&gt; AS6147 (Telefonica del Peru S.A.A.)</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta5.png"  alt="ruta5"/></p>
        
        
        
        <p>ASNs in <strong>UY</strong> with a route that traverses another country</p>
        
        <p>Note: This case was analyzed together with the operators of <br />
            AS 6057. An attempt was made to reproduce the path with RIPE<br />
            Atlas probes, but this was not possible because the traceroute.<br />
            had no segments.
        </p>
        
        <p>AS6057 (Administracion Nacional de Telecomunicaciones) --&gt; AS10429 (TELEFONICA BRASIL S.A, BR) --&gt; AS26599 (TELEFONICA BRASIL S.A, BR) --&gt; AS8167 (V tal, BR) --&gt; AS6057 (Administracion Nacional de Telecomunicaciones)</p>
        
        <p><img style={styles.images}src="/images/estudio-de-conectividad-2022/ruta6.png"  alt="ruta6" /></p>
        
        
        
        <h2 id="anexo3grafos_select">Annex 3: Graphs for the Different Countries in LAC</h2>
        <h3 id="a3des">Download</h3>
        
        <p>The data supporting the graphs in this document (graphs with origin and destination in the same country) can be found in the downloads section of the <a href="https://simon.lacnic.net/static/simon_app/conectividad-2022/" target='_blank' rel="noreferrer">LACNIC website</a>. These are CSV files where each line corresponds to an edge of the graph, and contains the following information:</p>
        <ul>
            <li>Origin network: Given a traceroute with hopn and hopn+1, this network is the ASN or IXP corresponding to hopn.</li>
            <li>Destination network: The same, but for hopn+1.</li>
            <li>RTT (diff): Ddifference between RTTn+1 and RTTn.</li>
        </ul>
        <p>Readers’ collaboration is of great interest in terms of successive analyses of the data or their visualization.</p>
        
        
        <h3 id="a3repr">Visual Representation</h3>
        
        <p>This section shows a visual representation of the graphs calculated during the study. The methodology used to prepare the graphs can be found in the <a href="#grafos">Graphs</a> section.</p>
        
        <table class="table" id="t1">
            <thead><tr><th>ARGENTINA (AR)</th></tr></thead>
            <tbody>
                <tr><td><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico21.png" class="imagen_wide" alt="" /></figure></td></tr>
                <tr><td>
                    <div class="row">
                        <div class="columna">
                            Graph centrality (top 20)<br />
                            1.	AR-IX Cabase<br />
                            2.	AS7303 Telecom Argentina S.A.<br />
                            3.	AS10834 Telefonica de Argentina<br />
                            4.	AS3549 LVLT-3549<br />
                            5.	AS262589 InterNexa Global Network<br />
                            6.	AS19037 AMX Argentina S.A.<br />
                            7.	AS3356 LEVEL3<br />
                            8.	AS11664 Techtel LMDS Comunicaciones Interactivas S.A.<br />
                            9.	AS11014 CPS<br />
                            10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                        </div>
                        <div class="columna">11.	AS16814 NSS S.A.<br />
                            12.	AS22927 Telefonica de Argentina<br />
                            13.	AS27747 Telecentro S.A.<br />
                            14.	AS52361 ARSAT - Empresa Argentina de Soluciones Satelitales S.A.<br />
                            15.	AS52360 CABASE Camara Arg de Base de Datos y Serv en Linea<br />
                            16.	AS174 COGENT-174<br />
                            17.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            18.	AS265862 BM SOLUCIONES S.R.L.<br />
                            19.	AS20207 Gigared S.A.<br />
                            20.	AS52376 CABASE Camara Arg de Base de Datos y Serv en Linea
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t2">
            <thead><tr><th>BOLIVIA (BO)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for BR</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico22.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 20)<br />
                            1.	PIT Bolivia<br />
                            2.	AS27839 Comteco Ltda<br />
                            3.	AS26210 AXS Bolivia S. A.<br />
                            4.	AS6568 Entel S.A. - EntelNet<br />
                            5.	AS25620 COTAS LTDA.<br />
                            6.	AS27882 Telefonica Celular de Bolivia S.A.<br />
                            7.	AS262159 Digital TV CABLE DE EDMUND S.R.L.<br />
                            8.	AS52250 Ag para el Desarrollo de la Sociedad de la Inf en Bolivia - ADSIB<br />
                            9.	AS267685 SIRIO TELECOMUNICACIONES S.R.L
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t3">
            <thead><tr><th>BRASIL (BR)</th></tr></thead>
            <tbody>
                <tr><td><figure><figcaption>Traceroute graph for BO</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico23.png" class="imagen_wide" alt="" /></figure></td></tr>
                <tr><td>
                    <div class="row">
                        <div class="columna">
                            Graph centrality (top 20)<br />
                            1.	IX.br (PTT.br) São Paulo<br />
                            2.	AS16735 ALGAR TELECOM SA<br />
                            3.	AS3356 LEVEL3<br />
                            4.	AS4230 CLARO S.A.<br />
                            5.	IX.br (PTT.br) Porto Alegre<br />
                            6.	AS8167 V tal<br />
                            7.	AS26599 TELEFONICA BRASIL S.A<br />
                            8.	AS3549 LVLT-3549<br />
                            9.	IX.br (PTT.br) Fortaleza<br />
                            10.	AS26615 TIM SA
                        </div>
                        <div class="columna">11.	IX.br (PTT.br) Rio de Janeiro<br />
                            12.	AS28283 Adylnet Telecom<br />
                            13.	AS10429 TELEFONICA BRASIL S.A<br />
                            14.	AS28573 Claro NXT Telecomunicacoes Ltda<br />
                            15.	IX.br (PTT.br) Curitiba<br />
                            16.	AS25933 Vogel Solucoes em Telecom e Informatica SA<br />
                            17.	Equinix São Paulo<br />
                            18.	AS268886 WILLYNET PROVEDOR<br />
                            19.	AS14840 BR Digital<br />
                            20.	AS53062 GGNET TELECOM BACKBONE
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t4">
            <thead><tr><th>CHILE (CH)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for CL</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico24.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	PIT Santiago - PIT Chile<br />
                            2.	AS7004 CTC Transmisiones Regionales S.A.<br />
                            3.	AS7418 TELEFONICA CHILE S.A.<br />
                            4.	AS14259 Gtd Internet S.A.<br />
                            5.	AS6535 Telmex Servicios Empresariales S.A.<br />
                            6.	AS6429 Telmex Chile Internet S.A.<br />
                            7.	AS22047 VTR BANDA ANCHA S.A.<br />
                            8.	AS3549 LVLT-3549<br />
                            9.	AS265662 TLINK SPA<br />
                            10.	AS27651 ENTEL CHILE S.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t5">
            <thead><tr><th>COLOMBIA (CO)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for CO</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico25.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	NAP Colombia<br />
                            2.	AS262186 TV AZTECA SUCURSAL COLOMBIA<br />
                            3.	AS13489 EPM Telecomunicaciones S.A. E.S.P.<br />
                            4.	AS3549 LVLT-3549<br />
                            5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                            6.	AS19429 ETB - Colombia<br />
                            7.	AS52468 UFINET PANAMA S.A.<br />
                            8.	AS14080 Telmex Colombia S.A.<br />
                            9.	AS10299 EMPRESAS MUNICIPALES DE CALI E.I.C.E. E.S.P.<br />
                            10.	AS23520 COLUMBUS-NETWORKS
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        <table class="table" id="t6">
            <thead><tr><th>COSTA RICA (CR)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for CR</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico26.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	CRIX<br />
                            2.	AS52468 UFINET PANAMA S.A.<br />
                            3.	AS262197 MILLICOM CABLE COSTA RICA S.A.<br />
                            4.	AS23520 COLUMBUS-NETWORKS<br />
                            5.	AS52228 Cable Tica<br />
                            6.	AS11830 Instituto Costarricense de Electricidad y Telecom.<br />
                            7.	AS1299 TWELVE99 Telia Company AB<br />
                            8.	AS262202 Telefonica de Costa Rica TC, SA<br />
                            9.	AS263762 COOPERATIVA DE ELECTRIFICACION RURAL DE GUANACASTE R.L.<br />
                            10.	AS28022 CRISP S.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t7">
            <thead><tr><th>REPúBLICA DOMINICANA (DO)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for DO</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico27.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS23520 COLUMBUS-NETWORKS<br />
                            2.	AS28118 ALTICE DOMINICANA S.A.<br />
                            3.	AS6400 Compania Dominicana de Telefonos S. A.<br />
                            4.	AS64126 DOMINICAN CABLE GROUP DCG, S.R.L.<br />
                            5.	AS1299 TWELVE99 Telia Company AB<br />
                            6.	AS264821 COMCAST-SRL<br />
                            7.	AS174 COGENT-174<br />
                            8.	AS264605 TELEVIADUCTO S.R.L.<br />
                            9.	AS270098 WI-FI DOMINICANA, E.I.R.L.<br />
                            10.	AS12066 ALTICE DOMINICANA S.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t8">
            <thead><tr><th>ECUADOR (EC)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for EC</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico28.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 20)<br />
                            1.	NAP.EC - UIO<br />
                            2.	AS26613 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                            3.	AS264668 NEDETEL S.A.<br />
                            4.	NAP.EC - GYE<br />
                            5.	AS27947 Telconet S.A<br />
                            6.	AS61468 CEDIA<br />
                            7.	AS27738 Ecuadortelecom S.A.<br />
                            8.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                            9.	AS263238 Eliana Vanessa Morocho Ona<br />
                            10.	AS23487 CONECEL<br />
                            11.	AS14522 Satnet<br />
                            12.	AS1299 TWELVE99 Telia Company AB<br />
                            13.	AS28006 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                            14.	AS3356 LEVEL3<br />
                            15.	AS27668 ETAPA EP<br />
                            16.	AS27757 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                            17.	AS263792 IN.PLANET S. A<br />
                            18.	AS52458 WISP INTERNET ECUADOR<br />
                            19.	AS265711 KOLVECH S.A. TELECOMVAS<br />
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t9">
            <thead><tr><th>GUYANA FRANCESA (GF)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for GF</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico29.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS2200 FR-RENATER Reseau National de telecommunications pour la Technologie<br />
                            2.	AS21351 CANALPLUSTELECOM Canal + Telecom SAS<br />
                            3.	AS263175 GUYACOM
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t10">
            <thead><tr><th>GUATEMALA (GT)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for GT</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico30.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            2.	AS23243 COMCEL GUATEMALA S.A.<br />
                            3.	AS174 COGENT-174<br />
                            4.	AS52468 UFINET PANAMA S.A.<br />
                            5.	AS14754 Telgua<br />
                            6.	AS6453 AS6453<br />
                            7.	AS3356 LEVEL3<br />
                            8.	AS27742 Amnet Telecomunicaciones S.A.<br />
                            9.	AS267715 RED CENTROAMERICANA DE TELECOMUNICACIONES S.A, SUCURSAL GUATEMALA, SOCIEDAD EXTRANJERA<br />
                            10.	AS1299 TWELVE99 Telia Company AB
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        <table class="table" id="t11">
            <thead><tr><th>GUYANA (GY)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for GY</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico31.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS19863 Guyana Telephone &amp; Telegraph Co.<br />
                            2.	AS23520 COLUMBUS-NETWORKS<br />
                            3.	AS264694 EGOVERNMENT UNIT<br />
                            4.	AS52253 E-Networks Inc.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t12">
            <thead><tr><th>HONDURAS (HN)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for HN</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico32.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS52262 Telefonica Celular S.A<br />
                            2.	AS27932 Redes y Telecomunicaciones<br />
                            3.	AS1299 TWELVE99 Telia Company AB<br />
                            4.	AS23520 COLUMBUS-NETWORKS<br />
                            5.	AS27884 CABLECOLOR S.A.<br />
                            6.	AS7087 Administracion de Redes Colomsat S.A.<br />
                            7.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            8.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                            9.	AS1239 SPRINTLINK<br />
                            10.	AS20299 Newcom Limited
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        <table class="table" id="t13">
            <thead><tr><th>HAITÍ (HT)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for HT</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico33.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS27759 ACCESS HAITI S.A.<br />
                            2.	AS52260 Telecommunications de Haiti Teleco<br />
                            3.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                            4.	AS33576 DIG001<br />
                            5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                            6.	AS1299 TWELVE99 Telia Company AB
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t4">
            <thead><tr><th>MEXICO (MX)</th></tr></thead>
            <tbody>
                <tr><td><figure><figcaption>Traceroute graph for MX</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico34.png" class="imagen_wide" alt="" /></figure></td></tr>
                <tr><td>
                    <div class="row">
                        <div class="columna">
                            Graph centrality (top 20)<br />
                            1.	AS8151 Uninet S.A. de C.V.<br />
                            2.	AS32098 TRANSTELCO-INC<br />
                            3.	AS18734 Operbes, S.A. de C.V.<br />
                            4.	AS174 COGENT-174<br />
                            5.	AS3356 LEVEL3<br />
                            6.	AS1299 TWELVE99 Telia Company AB<br />
                            7.	AS6503 Axtel, S.A.B. de C.V.<br />
                            8.	AS22884 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                            9.	AS3549 LVLT-3549<br />
                            10.	AS11172 Alestra, S. de R.L. de C.V.
                        </div>
                        <div class="columna">111.	AS17072 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                            12.	AS13999 Mega Cable, S.A. de C.V.<br />
                            13.	AS6453 AS6453<br />
                            14.	AS28469 AT&amp;T COMUNICACIONES DIGITALES S DE RL<br />
                            15.	AS23520 COLUMBUS-NETWORKS<br />
                            16.	AS6461 ZAYO-6461<br />
                            17.	AS28548 Cablevision, S.A. de C.V.<br />
                            18.	AS11888 Television Internacional, S.A. de C.V.<br />
                            19.	AS7438 Pegaso PCS, S.A. de C.V.<br />
                            20.	AS28545 Cablemas Telecomunicaciones SA de CV
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t15">
            <thead><tr><th>NICARAGUA (NI)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for NI</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico35.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS14754 Telgua<br />
                            2.	AS52242 Yota De Nicaragua<br />
                            3.	AS25607 IBW Communications
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        <table class="table" id="t16">
            <thead><tr><th>PANAMA (PA)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for PA</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico36.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	InteRed Panama<br />
                            2.	AS1299 TWELVE99 Telia Company AB<br />
                            3.	AS23520 COLUMBUS-NETWORKS<br />
                            4.	AS18809 Cable Onda<br />
                            5.	AS11556 Cable &amp; Wireless Panama<br />
                            6.	AS27930 Shadwell International Inc<br />
                            7.	AS52468 UFINET PANAMA S.A.<br />
                            8.	AS21599 Cable Onda<br />
                            9.	AS28005 Digicel Panama, S.A<br />
                            10.	AS262191 COLUMBUS NETWORKS COLOMBIA<br />
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t17">
            <thead><tr><th>PERÚ (PE)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for PE</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico37.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS3132 Red Cientifica Peruana<br />
                            2.	AS6147 Telefonica del Peru S.A.A.<br />
                            3.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            4.	AS262210 VIETTEL PERU S.A.C.<br />
                            5.	AS61482 CONVERGIA<br />
                            6.	AS262253 ECONOCABLE MEDIA SAC<br />
                            7.	AS269857 FIBER DIGITAL S.R.L<br />
                            8.	AS27843 OPTICAL TECHNOLOGIES S.A.C.<br />
                            9.	AS12252 America Movil Peru S.A.C.<br />
                            10.	AS21575 ENTEL PERU S.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t18">
            <thead><tr><th>PARAGUAY (PY)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for PY</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico38.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS23201 Telecel S.A.<br />
                            2.	IX.br (PTT.br) São Paulo<br />
                            3.	AS27768 COMPANIA PARAGUAYA DE COMUNICACIONES S.A. COPACO S.A.<br />
                            4.	AS61512 GIG@NET SOCIEDAD ANONIMA<br />
                            5.	AS3356 LEVEL3<br />
                            6.	AS266831 MONGELOS ARCE MARCIALDELTA NETWORKS<br />
                            7.	AS52468 UFINET PANAMA S.A.<br />
                            8.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            9.	AS21928 T-MOBILE-AS21928<br />
                            10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        
        <table class="table" id="t19">
            <thead><tr><th>EL SALVADOR (SV)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for SV</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico39.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS6453 AS6453<br />
                            2.	AS1299 TWELVE99 Telia Company AB<br />
                            3.	AS23520 COLUMBUS-NETWORKS<br />
                            4.	AS3491 BTN-ASN<br />
                            5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                            6.	AS14754 Telgua<br />
                            7.	AS27773 MILLICOM CABLE EL SALVADOR S.A. DE C.V.<br />
                            8.	AS33576 DIG001<br />
                            9.	AS262199 Columbus Networks El Salvador SA de CV<br />
                            10.	AS6461 ZAYO-6461
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        <table class="table" id="t20">
            <thead><tr><th>TRINIDAD Y TOBAGO (TT)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for TT</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico40.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS27665 Columbus Communications Trinidad Limited.<br />
                            2.	AS23520 COLUMBUS-NETWORKS<br />
                            3.	AS5639 Telecommunication Services of Trinidad and Tobago<br />
                            4.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                            5.	AS27924 AMPLIA COMMUNICATIONS LTD.<br />
                            6.	AS264811 AIR LINK COMMUNICATIONS<br />
                            7.	AS27789 GREENDOT<br />
                            8.	AS393629 GDGR<br />
                            9.	AS1299 TWELVE99 Telia Company AB<br />
                            10.	AS263222 RVR INTERNATIONAL LIMITED
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        
        <table class="table" id="t21">
            <thead><tr><th>URUGUAY (UY)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for UY</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico41.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS6057 Administracion Nacional de Telecomunicaciones<br />
                            2.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                            3.	AS19422 Telefonica Moviles del Uruguay SA<br />
                            4.	AS28000 LACNIC - Latin American and Caribbean IP address<br />
                            5.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                            6.	AS10429 TELEFONICA BRASIL S.A<br />
                            7.	AS26599 TELEFONICA BRASIL S.A<br />
                            8.	AS8167 V tal<br />
                            9.	AS61455 LACTLD - LATIN AMERICAN AND CARIBBEAN TLD ASSOCIATION<br />
                            10.	AS1797 Uruguay
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        
        
        <table class="table" id="t22">
            <thead><tr><th>VENEZUELA (VE)</th></tr></thead>
            <tbody>
                <tr><td>
                    <div class="row">
                        <div class="columna" ><figure><figcaption>Traceroute graph for VE</figcaption><img style={styles.images}src="/images/estudio-de-conectividad-2022/grafico42.png" class="imagen_wide" alt="" /></figure><br />
                        </div>
                        <div class="columna">Graph centrality (top 10)<br />
                            1.	AS3549 LVLT-3549<br />
                            2.	AS6306 TELEFONICA VENEZOLANA, C.A.<br />
                            3.	AS263703 VIGINET C.A<br />
                            4.	AS8048 CANTV Servicios, Venezuela<br />
                            5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                            6.	AS1299 TWELVE99 Telia Company AB<br />
                            7.	AS61461 Airtek Solutions C.A.<br />
                            8.	AS3356 LEVEL3<br />
                            9.	FL-IX <br />
                            10.	AS11562 Net Uno, C.A.
                        </div>
                    </div>
                </td></tr>
            </tbody>
        </table>
        </>
    )
}

export default Conectividad2022En
