import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Metodologia() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id='p5'>
                {myLang === 'es' && <>
                    <h2>Metodolog&iacute;a</h2>
                    <h3><strong>Sondas RIPE Atlas</strong></h3>
                    <p>En el caso de las mediciones utilizando la plataforma RIPE Atlas, primero se obtuvo el listado de sondas localizadas en Latinoam&eacute;rica. Mediante la interfaz API y su m&eacute;todo de consulta, se aplic&oacute; un filtro por pa&iacute;ses (todos los de la regi&oacute;n, ver Anexo B).<br />Luego se gener&oacute; una medici&oacute;n nueva de tipo DoT con la lista de sondas obtenida y preguntando por el nombre<a href="http://www.lacnic.net" target="_blank" rel="noreferrer">&nbsp;www.lacnic.net</a>, tipo A, clase IN; con recursividad.</p>
                    <h3><strong>Open&nbsp;</strong><strong>resolvers</strong></h3>
                    <p>Por cada direcci&oacute;n IP a analizar se envi&oacute; una consulta DNS por el nombre&nbsp;<a href="http://www.lacnic.net" target="_blank" rel="noreferrer">www.lacnic.net</a>, tipo A, clase IN, de tipo recursiva, utilizando TCP al puerto 853, se realiza una negociaci&oacute;n de inicio TLS con el perfil de privacidad oportun&iacute;stico (RFC7858, secci&oacute;n 4.1) y se acepta cualquier certificado sin verificaci&oacute;n.<br />La conexi&oacute;n tuvo un&nbsp;<em>timeout</em>&nbsp;de cinco segundos, con tres reintentos del paquete SYN inicial. En los casos en que no hubo respuesta, se hizo la misma consulta con DNS cl&aacute;sico, utilizando UDP al puerto 53 (Do53) para verificar la condici&oacute;n de&nbsp;<em>open resolver</em>&nbsp;con DNS plano.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Methodology</h2>
                    <h3><strong>RIPE Atlas</strong>&nbsp;Probes</h3>
                    <p>In the case of measurements using the RIPE Atlas platform, the first step was to obtain the list of probes located in Latin America. Using the API interface and its query method, a country filter was applied (all the countries in the region, see Appendix B).<br />A new DoT measurement was created using this list of probes and querying for&nbsp;<a href="http://www.lacnic.net/" target="_blank" rel="noreferrer">&nbsp;www.lacnic.ne</a><u>&rsaquo;</u>, type A, class IN, with recursion.</p>
                    <h3><strong>Open&nbsp;</strong><strong>resolvers</strong></h3>
                    <p>For each IP address to be analyzed, a type A, class IN, recursive DNS query for&nbsp;<a href="http://www.lacnic.net/" target="_blank" rel="noreferrer">www.lacnic.net</a>&nbsp;was sent to port 853 over TCP, a TLS handshake was performed with the Opportunistic Privacy Profile (RFC7858, Section 4.1), and any certificate was accepted without verification.<br />The connection had a five-second timeout, with three retries for the initial SYN packet. In cases where there was no reply, the same query was performed with classic DNS, using UDP on port 53 (Do53) to verify the open resolver status with plain DNS.</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Metodologia</h2>
                    <h3><strong>Sondas RIPE Atlas</strong></h3>
                    <p>No caso das medi&ccedil;&otilde;es com a plataforma RIPE Atlas, obteve-se primeiro a lista de sondas localizadas na Am&eacute;rica Latina. Utilizando a interface API e seu m&eacute;todo de consulta, foi aplicado um filtro por pa&iacute;s (todos os da regi&atilde;o, ver Anexo B).<br />Em seguida, uma nova medi&ccedil;&atilde;o do tipo DoT foi gerada com a lista de sondas obtidas e perguntando pelo nome<a href="http://www.lacnic.net/">&nbsp;&lsaquo;www.lacnic.net</a><u>&rsaquo;</u>, tipo A, classe IN; com recursividade.</p>
                    <h3><strong>Open&nbsp;</strong><strong>resolvers</strong></h3>
                    <p>Por cada endere&ccedil;o IP a ser analisado, uma consulta DNS foi enviada pelo nome &lsaquo;<a href="http://www.lacnic.net/">www.lacnic.net</a><u>&rsaquo;</u>, tipo A, classe IN, de tipo recursiva, usando TCP &agrave; porta 853, uma negocia&ccedil;&atilde;o de inicializa&ccedil;&atilde;o TLS &eacute; realizada com o perfil de privacidade oportunista (RFC7858, se&ccedil;&atilde;o 4.1) e qualquer certificado &eacute; aceito sem verifica&ccedil;&atilde;o.<br />A conex&atilde;o atingiu o&nbsp;<em>timeout</em>&nbsp;de cinco segundos, com tr&ecirc;s novas tentativas do pacote SYN inicial. Nos casos em que n&atilde;o houve resposta, a mesma consulta foi feita com DNS cl&aacute;ssico, usando UDP para a porta 53 (Do53) para verificar a condi&ccedil;&atilde;o de&nbsp;<em>open resolver</em>&nbsp;com DNS simples.</p>
                </>}
            </div>
        </div>
    )
}

export default Metodologia
