import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Mecanismos() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>&iquest;C&oacute;mo determinar si su organizaci&oacute;n implementa filtros de tipo SAV?</h2>
                    <p>Para saber r&aacute;pidamente si una organizaci&oacute;n implementa adecuadamente filtros de tipo <em>inbound</em> y <em>outbound</em> SAV, se podr&iacute;a evaluar de forma manual, verificando si son posibles las siguientes situaciones:</p>
                    <ul>
                        <li><em>Inbound</em> IP <em>spoofing</em>: si es posible enviar desde internet hacia la organizaci&oacute;n tr&aacute;fico con direcciones IP de origen pertenecientes a bloques IP de la organizaci&oacute;n.</li>
                        <li><em>Outbound</em> IP <em>spoofing</em>: si es posible enviar desde la organizaci&oacute;n hacia internet tr&aacute;fico con direcciones IP de origen pertenecientes a bloques IP que no sean de la organizaci&oacute;n.</li>
                    </ul>
                    <h3>Mecanismo manual de detecci&oacute;n del IP spoofing</h3>
                    <p>Evaluar manualmente si es posible realizar IP <em>spoofing</em> es una forma pr&aacute;ctica de entender y corroborar el problema. Para detectar manualmente si una organizaci&oacute;n permite tanto el <em>inbound</em> IP <em>spoofing</em> como el <em>outbound</em> IP <em>spoofing</em>, necesitamos contar con:</p>
                    <ul>
                        <li>Una herramienta para generar tr&aacute;fico manipulado. Para esto se utilizar&aacute;&nbsp;<a href="http://www.hping.org/" target="_blank" rel="noreferrer">http://www.hping.org/</a></li>
                        <li>Una herramienta para monitorear la llegada del tr&aacute;fico manipulado. Para esto se utilizar&aacute; <a href="https://www.tcpdump.org/" target="_blank" rel="noreferrer">Tcpdump</a>.&nbsp;</li>
                        <li>Un dispositivo de red, conectado a la red de la organizaci&oacute;n que se va a evaluar, que tenga permitida la recepci&oacute;n sin filtros de tr&aacute;fico a un servicio determinado (por ej.: TCP/443).</li>
                        <li>Un dispositivo de red conectado a un sistema aut&oacute;nomo externo a la organizaci&oacute;n a evaluar.</li>
                    </ul>
                    <h4>Verificaci&oacute;n manual de inbound IP spoofing</h4>
                    <p>La falta de filtros de tipo <em>inbound</em> SAV permiten el <em>inbound</em> IP <em>spoofing</em>. Esto se puede verificar manualmente generando tr&aacute;fico <em>spoofeado</em> en un dispositivo conectado a un sistema aut&oacute;nomo externo usando como direcciones IP origen a direcciones IP propias de la organizaci&oacute;n.</p>
                    <p>El tr&aacute;fico debe estar dirigido a un dispositivo conectado a la red de la organizaci&oacute;n. En este &uacute;ltimo dispositivo es donde podremos concluir, en caso de observar la llegada del tr&aacute;fico manipulado, que es posible el <em>inbound</em> IP <em>spoofing</em>.</p>
                    <p>Los pasos para realizar la verificaci&oacute;n manual ser&iacute;an:</p>
                    <p><strong>Dispositivo conectado a un sistema aut&oacute;nomo externo</strong></p>
                    <pre>
                        <code>
                            <p># Asumiendo que</p>
                            <p># la IP del dispositivo conectado a un sistema aut&oacute;nomo externo es y.y.y.y</p>
                            <p># la IP del dispositivo en la organizaci&oacute;n tiene la IP x.x.x.1</p>
                            <p># la IP que se usar&aacute; como IP de origen en la prueba ser&aacute; la direcci&oacute;n x.x.x.2</p>
                            <p># Test de verificaci&oacute;n inicial: Para verificar que el tr&aacute;fico llega</p>
                            <p><strong>hping -S -p 443 -c 1 x.x.x.1</strong></p>
                            <p># Test para verificar el inbound IP spoofing</p>
                            <p><strong>hping -S -p 443 -c 1 -a x.x.x.2 x.x.x.1</strong></p>
                        </code></pre>
                    <p><strong>Dispositivo conectado a la organizaci&oacute;n a evaluar</strong></p>
                    <pre>
                        <code>
                            <p># Asumiendo que la interfaz de red es la eth0 y usando filtros para observar</p>
                            <p># solamente el tr&aacute;fico de inter&eacute;s</p>
                            <p><strong>tcpdump -n -i eth0 host y.y.y.y or host x.x.x.2</strong></p>
                        </code></pre>
                    <p>Dependiendo de la actividad mostrada por el comando <strong>tcpdump</strong> se podr&aacute; concluir si es posible el <em>inbound</em> IP spoofing. En base a las observaciones, los posibles escenarios son:</p>
                    <ul>
                        <li>Es posible el <em>inbound IP spoofing</em>: Para concluir con esto, con el comando <em>tcpdump</em> se deber&iacute;a poder observar el tr&aacute;fico asociado al test de verificaci&oacute;n inicial y el tr&aacute;fico asociado al test del <em>inbound IP spoofing</em>.</li>
                    </ul>
                    <pre><code>
                        <p>&nbsp;IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                        <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                        <p>&nbsp;IP x.x.x.2.1899 &gt; x.x.x.1.443: Flags [S], seq 883275884, length 0</p>
                        <p>&nbsp;IP x.x.x.1.443 &gt; x.x.x.2.1899: Flags [R.], seq 0, ack 883275885, length 0</p>
                    </code></pre>
                    <ul>
                        <li>No es posible el <em>inbound IP spoofing</em>: Para concluir con esto, con el comando <em>tcpdump</em> se deber&iacute;a poder observar solo el tr&aacute;fico asociado al test de verificaci&oacute;n inicial.</li>
                    </ul>
                    <p>&nbsp;IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <ul>
                        <li>No se puede determinar: En caso de no observar el tr&aacute;fico asociado al test de verificaci&oacute;n inicial, podemos concluir que existe un filtro de red que evita que podamos hacer la verificaci&oacute;n en el dispositivo x.x.x.1, utilizando el puerto determinado (en el ejemplo, el puerto TCP/443).</li>
                    </ul>
                    <h4>Verificaci&oacute;n manual de outbound IP spoofing</h4>
                    <p>De manera an&aacute;loga, la falta de filtros de tipo <em>outbound</em> SAV permite el <em>outbound IP spoofing</em>. Esto se puede verificar manualmente generando tr&aacute;fico <em>spoofeado</em> en un dispositivo conectado a la organizaci&oacute;n evaluada, usando como direcciones IP de origen a direcciones IP que no sean de la organizaci&oacute;n.</p>
                    <p>El tr&aacute;fico debe estar dirigido a un dispositivo conectado a un sistema aut&oacute;nomo externo. En este &uacute;ltimo dispositivo es donde podremos concluir, en caso de observar la llegada del tr&aacute;fico manipulado, que es posible el <em>outbound IP spoofing</em>.</p>
                    <p>Los pasos para la verificaci&oacute;n manual ser&iacute;an:</p>
                    <p><strong>Dispositivo conectado a la organizaci&oacute;n a evaluar</strong></p>
                    <pre><code>
                        <p># Asumiendo que</p>
                        <p># la IP del dispositivo en la organizaci&oacute;n tiene la IP x.x.x.1</p>
                        <p># la IP del dispositivo conectado a un sistema aut&oacute;nomo externo es y.y.y.y</p>
                        <p># la IP que se usar&aacute; como IP de origen en la prueba ser&aacute; la direcci&oacute;n z.z.z.z</p>
                        <p># Test de verificaci&oacute;n inicial: Para verificar que el tr&aacute;fico llega</p>
                        <p><strong>hping -S -p 443 -c 1 y.y.y.y</strong></p>
                        <p># Test para verificar el outbound IP spoofing</p>
                        <p><strong>hping -S -p 443 -c 1 -a z.z.z.z y.y.y.y</strong></p>
                    </code></pre>
                    <p><strong>Dispositivo conectado a un sistema aut&oacute;nomo externo</strong></p>
                    <pre><code>
                        <p># Asumiendo que la interfaz de red es la eth0 y usando filtros para observar</p>
                        <p># solamente el tr&aacute;fico de inter&eacute;s</p>
                        <p><strong>tcpdump -n -i eth0 host x.x.x.1 or host z.z.z.z</strong></p>
                    </code></pre>
                    <p>Dependiendo de la actividad mostrada por el comando <strong>tcpdump,</strong> se podr&aacute; concluir si es posible el <em>outbound IP spoofing</em>. En base a las observaciones, los posibles escenarios que se podr&aacute;n dar son:</p>
                    <ul>
                        <li>Es posible el <em>outbound IP spoofing</em>: Para concluir esto, con el comando <em>tcpdump</em> se deber&iacute;a poder observar el tr&aacute;fico asociado al test de verificaci&oacute;n inicial y el tr&aacute;fico asociado al test del <em>outbound IP spoofing</em>.</li>
                    </ul>
                    <pre><code>
                        <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                        <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                        <p>&nbsp;IP z.z.z.z.1899 &gt; y.y.y.y.443: Flags [S], seq 883275884, length 0</p>
                        <p>&nbsp;IP y.y.y.y.443 &gt; z.z.z.z.1899: Flags [R.], seq 0, ack 883275885, length 0</p>
                        <p>&nbsp;</p>
                    </code></pre>
                    <ul>
                        <li>No es posible el <em>outbound</em> IP <em>spoofing</em>: Para concluir esto, con el comando <em>tcpdump</em> se deber&iacute;a poder observar solo el tr&aacute;fico asociado al test de verificaci&oacute;n inicial.</li>
                    </ul>
                    <pre><code>
                        <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                        <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    </code></pre>
                    <ul>
                        <li>No se puede determinar: En caso de no observar el tr&aacute;fico asociado al test de verificaci&oacute;n inicial, podemos concluir que existe un filtro de red que evita que podamos hacer la verificaci&oacute;n.</li>
                    </ul>
                    <h3>Mecanismo autom&aacute;tico de detecci&oacute;n de IP spoofing</h3>
                    <p>La evaluaci&oacute;n manual del IP <em>spoofing </em>es una forma pr&aacute;ctica para entender la problem&aacute;tica con el objetivo de mitigarla. Sin embargo, el uso de herramientas que automatizan su detecci&oacute;n es la opci&oacute;n recomendada para tener una visi&oacute;n completa y exhaustiva del problema. Hoy contamos con dos herramientas que ayudan a realizar la detecci&oacute;n del IP <em>spoofing</em> de manera autom&aacute;tica.</p>
                    <h4>The Closed Resolved Project</h4>
                    <p>El proyecto The Closed Resolved Project es un portal web que brinda informaci&oacute;n sobre la posibilidad de realizar <em>inbound IP spoofing</em>. Se utiliza la direcci&oacute;n IP del visitante para informar sobre los resultados.</p>
                    <h4>Spoofer</h4>
                    <p>Es una herramienta que brinda el proyecto Spoofer de <a href="https://www.caida.org/projects/spoofer/#download-client-software">CAIDA</a>. Este proyecto es la fuente principal de datos sobre la problem&aacute;tica del IP <em>spoofing</em>. La herramienta que brinda el proyecto es la que permite obtener los datos que se brindan a la comunidad.</p>
                    <p>Para poder hacer los tests, se debe instalar el <em>software</em> llamado <em><a href="https://www.caida.org/projects/spoofer/#download-client-software" target="_blank" rel="noreferrer">spoofer</a>.</em> Los tests tienen en cuenta una gran cantidad de situaciones particulares para evaluar tanto el <em>inbound IP spoofing</em> como el <em>outbound IP spoofing</em>.</p>
                    <p>Spoofer eval&uacute;a la posibilidad de realizar IP <em>spoofing</em> tanto con tr&aacute;fico IPv4 como con tr&aacute;fico IPv6. Adem&aacute;s, eval&uacute;a si es posible utilizar como IP de origen direcciones IP que pertenecen a bloques IP no ruteables, como, por ejemplo, <a href="http://www.ietf.org/rfc/rfc1918.txt" target="_blank" rel="noreferrer">bloques IP privados</a> en IPv4 o <a href="https://www.ietf.org/rfc/rfc4193.txt" target="_blank" rel="noreferrer">ULA</a> en IPv6.</p>
                    <p>Si bien se dispone de una interfaz gr&aacute;fica, la herramienta funciona como un servicio que hace tests de manera peri&oacute;dica, permitiendo evaluar distintas redes a las que el usuario se conecta.</p>
                    <p>En la Figura 3 se puede ver c&oacute;mo se presenta la herramienta al usuario. Se puede ver que el usuario dispone de la funcionalidad para iniciar nuevos tests. Tambi&eacute;n puede ver un resumen de tests realizados y acceder al reporte.</p>
                    <img src="/images/anti-spoofing/fig3.png" alt="Interfaz gr&aacute;fica del cliente Spoofer de CAIDA" />
                    <p>Fig. 3 - Interfaz gr&aacute;fica del cliente Spoofer de CAIDA</p>
                    <p>Los reportes generados tienen detalles sobre el <em>inbound</em> y el <em>outbound</em> IP <em>spoofing</em>. En la Figura 4 se puede ver de manera resumida el resultado de tests para IPv4, IPv6, uso de direcciones no ruteables, uso de direcciones ruteables y si es posible tanto el <em>inbound</em> como el <em>outband</em> IP <em>spoofing</em>. En la Figura 5 se puede visualizar la cadena de sistemas aut&oacute;nomos por la que se rutean los paquetes <em>spoofeados</em> en las pruebas realizadas.</p>
                    <img src="/images/anti-spoofing/fig4.png" alt=" Secci&oacute;n resumen del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 4- Secci&oacute;n resumen del reporte del cliente Spoofer de CAIDA</p>
                    <img src="/images/anti-spoofing/fig5.png" alt="Secci&oacute;n IPv4 AS route del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 5- Secci&oacute;n IPv4 AS route del reporte del cliente Spoofer de CAIDA</p>
                </>}
                {myLang === 'en' && <>
                    <h2>How to determine whether your organization implements SAV</h2>
                    <p>To quickly determine whether an organization is properly implementing inbound and outbound SAV, a manual assessment may be performed. This would include checking whether the following situations are possible:</p>
                    <ul>
                        <li>Inbound IP spoofing: sending traffic with source IP addresses that are part of the IP address blocks assigned to the organization from the Internet to the organization.</li>
                        <li>Outbound IP spoofing: sending traffic with source IP addresses that are part of the IP address blocks assigned to the organization from the organization to the Internet.</li>
                    </ul>
                    <h3>Manual IP spoofing detection mechanism</h3>
                    <p>Manually assessing whether IP spoofing is possible is a convenient way to understand and check the problem. To manually detect if an organization allows both inbound and outbound IP spoofing, we need:</p>
                    <ul>
                        <li>A tool for generating custom traffic. In this case, <a href="http://www.hping.org/" target="_blank" rel="noreferrer">Hping3</a> will be used<em>.</em></li>
                        <li>A tool to monitor the arrival of the custom traffic. In this case, <a href="https://www.tcpdump.org/" target="_blank" rel="noreferrer">Tcpdump</a> will be used.</li>
                        <li>A network device connected to the network of the organization to be evaluated and which can receive unfiltered traffic to a specific service (for example: TCP/443).</li>
                        <li>A network device connected to an autonomous system that is external to the organization to be evaluated.</li>
                    </ul>
                    <h4>Manual inbound IP spoofing check</h4>
                    <p>The lack of inbound SAV enables inbound IP spoofing. This can be manually verified by generating spoofed traffic on a device connected to an external autonomous system using the organization's own IP addresses as source IP addresses.</p>
                    <p>The traffic must be directed to a device connected to the organization's network. If the custom traffic reaches this last device, we can conclude that inbound IP spoofing is possible.</p>
                    <p>The steps to perform the manual verification are as follows:</p>
                    <p><strong>Device connected to an external autonomous system</strong></p>
                    <p># Assuming that</p>
                    <p># the IP of the device connected to an external autonomous system is y.y.y.y</p>
                    <p># the IP of the device in the organization is x.x.x.1</p>
                    <p># the IP that will be used as the source IP for the test is x.x.x.2</p>
                    <p># Initial verification test: to check that the traffic arrives</p>
                    <p><strong>hping -S -p 443 -c 1 x.x.x.1</strong></p>
                    <p># Inbound IP spoofing test</p>
                    <p><strong>hping -S -p 443 -c 1 -a x.x.x.2 x.x.x.1</strong></p>
                    <p><strong>Device connected to the organization to be assessed</strong></p>
                    <p># Assuming that the network interface is eth0 and using filters to observe</p>
                    <p># only the traffic of interest</p>
                    <p><strong>tcpdump -n -i eth0 host y.y.y.y or host x.x.x.2</strong></p>
                    <p>Depending on the activity shown by the <strong>tcpdump</strong> command, we may be able to conclude whether inbound IP spoofing is possible. Based on these observations, the following scenarios are possible:</p>
                    <ul>
                        <li>Inbound IP spoofing is possible: This can be concluded if the tcpdump command returns the traffic associated with the initial verification test as well as the traffic associated with the inbound IP spoofing test.</li>
                    </ul>
                    <p>&nbsp;IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <p>&nbsp;IP x.x.x.2.1899 &gt; x.x.x.1.443: Flags [S], seq 883275884, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; x.x.x.2.1899: Flags [R.], seq 0, ack 883275885, length 0&nbsp;</p>
                    <ul>
                        <li>Inbound IP spoofing is not possible: This can be concluded if the tcpdump command returns only the traffic associated with the initial verification test.</li>
                    </ul>
                    <p>&nbsp;IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <ul>
                        <li>Impossible to determine: If the command does not return the traffic associated with the initial verification test, we can conclude that a network filter is preventing us from performing the verification on device x.x.x.1, using the specified port (in the example, port TCP/443).</li>
                    </ul>
                    <h4>Manual outbound IP spoofing check</h4>
                    <p>Similarly, the lack of outbound SAV enables outbound IP spoofing. This can be verified manually by generating spoofed traffic on a device connected to the organization that is being assessed, using IP addresses that do not belong to the organization as source IP addresses.</p>
                    <p>The traffic must be directed to a device connected to an external autonomous system. If the custom traffic reaches this last device, we can conclude that outbound IP spoofing is possible.</p>
                    <p>The steps for the manual verification are as follows:</p>
                    <p><strong>Device connected to the organization to be assessed</strong></p>
                    <p># Assuming that</p>
                    <p># the IP of the device in the organization is x.x.x.1</p>
                    <p># the IP of the device connected to an external autonomous system is y.y.y.y</p>
                    <p># the IP that will be used as the source IP for the test is z.z.z.z</p>
                    <p># Initial verification test: to check that the traffic arrives</p>
                    <p><strong>hping -S -p 443 -c 1 y.y.y.y</strong></p>
                    <p># Outbound IP spoofing test</p>
                    <p><strong>hping -S -p 443 -c 1 -a z.z.z.z y.y.y.y</strong></p>
                    <p><strong>Device connected to an external autonomous system</strong></p>
                    <p># Assuming that the network interface is eth0 and using filters to observe</p>
                    <p># only the traffic of interest</p>
                    <p><strong>tcpdump -n -i eth0 host x.x.x.1 or host z.z.z.z</strong></p>
                    <p>Depending on the activity shown by the <strong>tcpdump</strong> command, we may be able to conclude whether outbound IP spoofing is possible. Based on these observations, the following scenarios are possible:</p>
                    <ul>
                        <li>Outbound IP spoofing is possible: This can be concluded if the tcpdump command returns the traffic associated with the initial verification test as well as the traffic associated with the outbound IP spoofing test.</li>
                    </ul>
                    <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <p>&nbsp;IP z.z.z.z.1899 &gt; y.y.y.y.443: Flags [S], seq 883275884, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; z.z.z.z.1899: Flags [R.], seq 0, ack 883275885, length 0</p>
                    <ul>
                        <li>Outbound IP spoofing is not possible: This can be concluded if the tcpdump command returns only the traffic associated with the initial verification test.</li>
                    </ul>
                    <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <ul>
                        <li>Impossible to determine: If the command does not return the traffic associated with the initial verification test, we can conclude that a network filter is preventing us from performing the verification.</li>
                    </ul>
                    <h3>Automatic IP spoofing detection mechanism</h3>
                    <p>A manual assessment of IP spoofing is a practical way to understand the problem with the aim of mitigating it. However, the use of tools that automate IP spoofing detection is recommended for a full, in-depth understanding of the problem. There are currently two tools that help to detect IP spoofing automatically.</p>
                    <h4>The Closed Resolver Project</h4>
                    <p>The Closed Resolver Project is a web portal that provides information on networks that are vulnerable to inbound IP spoofing. The visitor's IP address is used to report the results.</p>
                    <h4>Spoofer</h4>
                    <p>This tool is provided by the <a href="https://www.caida.org/projects/spoofer/#download-client-software" target="_blank" rel="noreferrer">CAIDA Spoofer Project</a>.&nbsp;This project is the main source of data on IP spoofing. The tool provided by the project allows obtaining the data that is offered to the community.</p>
                    <p>To perform the tests, the <a href="https://www.caida.org/projects/spoofer/#download-client-software" target="_blank" rel="noreferrer">spoofer</a> program must be installed<em>.</em>&nbsp;The tests take into account a large number of particular situations to assess both inbound and outbound IP spoofing</p>
                    <p>Spoofer assesses the ability to perform IP spoofing with both IPv4 and IPv6 traffic. It also assesses whether it is possible to use IP addresses that are part of non-routable IP blocks (e.g., <a href="http://www.ietf.org/rfc/rfc1918.txt" target="_blank" rel="noreferrer">private IP blocks</a>&nbsp;in <a href="https://www.ietf.org/rfc/rfc4193.txt" target="_blank" rel="noreferrer">IPv4 or ULA</a>&nbsp;in IPv6) as source IP addresses.</p>
                    <p>While it also has a graphical interface, the tool performs periodic tests, thus allowing the assessment of the different networks to which the user connects.</p>
                    <p>Figure 3 shows the CAIDA Spoofer graphical user interface, which includes an option for users to start new tests, as well as a summary of the tests that have been performed and the option to access the full report.</p>
                    <img src="/images/anti-spoofing/fig3.png" alt="Interfaz gr&aacute;fica del cliente Spoofer de CAIDA" />
                    <p>Fig. 3 - CAIDA Spoofer client GUI</p>
                    <p>The reports contain details on inbound and outbound IP spoofing. Figure 4 shows a summary of the test results for IPv4, IPv6, use of non-routable addresses, use of routable addresses and whether inbound and outbound IP spoofing are possible. Figure 5 shows the series of autonomous systems through which the spoofed packets were routed in the tests that were carried out.</p>
                    <img src="/images/anti-spoofing/fig4.png" alt=" Secci&oacute;n resumen del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 4- Summary section of the CAIDA Spoofer client report</p>
                    <img src="/images/anti-spoofing/fig5.png" alt="Secci&oacute;n IPv4 AS route del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 5- IPv4 AS route section of the CAIDA Spoofer client report</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Como determinar se sua organiza&ccedil;&atilde;o implementa filtros do tipo SAV?</h2>
                    <p>Para saber rapidamente se uma organiza&ccedil;&atilde;o implementa adequadamente filtros do tipo <em>inbound</em> e <em>outbound</em> SAV, poderia ser avaliado manualmente, verificando se as seguintes situa&ccedil;&otilde;es s&atilde;o poss&iacute;veis:</p>
                    <ul>
                        <li><em>Inbound</em> IP <em>spoofing</em>: se for poss&iacute;vel enviar da Internet para a organiza&ccedil;&atilde;o, tr&aacute;fego com endere&ccedil;os IP de origem pertencentes a blocos IP da organiza&ccedil;&atilde;o.</li>
                        <li><em>Outbound</em> IP <em>spoofing</em>: se for poss&iacute;vel enviar da organiza&ccedil;&atilde;o para a Internet, tr&aacute;fego com endere&ccedil;os IP de origem pertencentes a blocos IP que n&atilde;o sejam da organiza&ccedil;&atilde;o.</li>
                    </ul>
                    <h2>Mecanismo manual de detec&ccedil;&atilde;o do IP spoofing</h2>
                    <p>Avaliar manualmente se fazer IP <em>spoofing</em> &eacute; poss&iacute;vel &eacute; uma forma conveniente de entender e verificar o problema. Para detectar manualmente se uma organiza&ccedil;&atilde;o permite tanto o <em>inbound</em> IP <em>spoofing</em> quanto o <em>outbound</em> IP <em>spoofing</em>, precisamos ter:</p>
                    <ul>
                        <li>Uma ferramenta para gerar <a href="http://www.hping.org/" target="_blank" rel="noreferrer">tr&aacute;fego manipulado</a>. Para isso ser&aacute; usado o</li>
                        <li>Uma ferramenta para monitorar a chegada do tr&aacute;fego manipulado. Para isso ser&aacute; usado o <a href="https://www.tcpdump.org/" target="_blank" rel="noreferrer">Tcpdump</a>.</li>
                        <li>Um dispositivo de rede, conectado &agrave; rede da organiza&ccedil;&atilde;o a ser avaliada, que tenha permiss&atilde;o para receber sem filtros de tr&aacute;fego um servi&ccedil;o determinado (por exemplo: TCP/443).</li>
                        <li>Um dispositivo de rede conectado a um sistema aut&ocirc;nomo externo &agrave; organiza&ccedil;&atilde;o a ser avaliada.</li>
                    </ul>
                    <h3>Verifica&ccedil;&atilde;o manual de inbound IP spoofing</h3>
                    <p>A falta de filtros do tipo <em>inbound</em> SAV permite o <em>inbound</em> IP <em>spoofing</em>. Isso pode ser verificado manualmente gerando tr&aacute;fego <em>spoofeado</em> em um dispositivo conectado a um sistema aut&ocirc;nomo externo usando endere&ccedil;os IP pr&oacute;prios da organiza&ccedil;&atilde;o como endere&ccedil;os IP de origem.</p>
                    <p>O tr&aacute;fego deve ser direcionado a um dispositivo conectado &agrave; rede da organiza&ccedil;&atilde;o. Neste &uacute;ltimo dispositivo &eacute; onde podemos concluir, caso observemos a chegada do tr&aacute;fego manipulado, que &eacute; poss&iacute;vel o<em> inbound IP spoofing.</em></p>
                    <p>Os passos para fazer a verifica&ccedil;&atilde;o manual seriam:</p>
                    <p><strong>Dispositivo conectado a um sistema aut&ocirc;nomo externo</strong></p>
                    <p># Assumindo que</p>
                    <p># o IP do dispositivo conectado a um sistema aut&ocirc;nomo externo &eacute; y.y.y.y</p>
                    <p># o IP do dispositivo na organiza&ccedil;&atilde;o tem o IP x.x.x.1</p>
                    <p># o IP que ser&aacute; usado como IP de origem no teste ser&aacute; o endere&ccedil;o x.x.x.2</p>
                    <p># Teste de verifica&ccedil;&atilde;o inicial: Para verificar que o tr&aacute;fego chega</p>
                    <p><strong>hping -S -p 443 -c 1 x.x.x.1</strong></p>
                    <p># Teste para verificar o inbound IP spoofing</p>
                    <p><strong>hping -S -p 443 -c 1 -a x.x.x.2 x.x.x.1</strong></p>
                    <p><strong>Dispositivo conectado &agrave; organiza&ccedil;&atilde;o a ser avaliada</strong></p>
                    <p># Assumindo que a interface de rede seja eth0 e usando filtros para observar</p>
                    <p># apenas o tr&aacute;fego de interesse</p>
                    <p><strong>tcpdump -n -i eth0 host y.y.y.y ou host x.x.x.2</strong></p>
                    <p>Dependendo da atividade mostrada pelo comando <strong>tcpdump</strong> pode-se concluir se o <em>inbound IP spoofing</em> &eacute; poss&iacute;vel. Com base nas observa&ccedil;&otilde;es, os cen&aacute;rios poss&iacute;veis s&atilde;o:</p>
                    <ul>
                        <li>O <em>inbound IP spoofing</em> &eacute; poss&iacute;vel: Para concluir, com o comando <em>tcpdump</em> deveria ser poss&iacute;vel observar o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial e o tr&aacute;fego associado ao teste do <em>inbound IP spoofing.</em></li>
                    </ul>
                    <p><em>&nbsp;</em>IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <p>&nbsp;IP x.x.x.2.1899 &gt; x.x.x.1.443: Flags [S], seq 883275884, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; x.x.x.2.1899: Flags [R.], seq 0, ack 883275885, length 0</p>
                    <ul>
                        <li>O <em>inbound IP spoofing</em> n&atilde;o &eacute; poss&iacute;vel: Para concluir isto, com o comando <em>tcpdump</em> deveria ser poss&iacute;vel observar somente o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial.</li>
                    </ul>
                    <p>&nbsp;IP y.y.y.y.7323 &gt; x.x.x.1.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP x.x.x.1.443 &gt; y.y.y.y.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <ul>
                        <li>N&atilde;o &eacute; poss&iacute;vel determinar: Se n&atilde;o observarmos o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial, podemos concluir que existe um filtro de rede que nos impede de fazer a verifica&ccedil;&atilde;o no dispositivo x.x.x.1, usando a porta determinada (no exemplo, porta TCP/443).</li>
                    </ul>
                    <h3>Verifica&ccedil;&atilde;o manual de outbound IP spoofing</h3>
                    <p>De forma an&aacute;loga, a falta de filtros do tipo <em>outbound</em> SAV permite o <em>outbound</em> <em>IP</em> <em>spoofing</em>. Isso pode ser verificado manualmente gerando tr&aacute;fego <em>spoofeado</em> em um dispositivo conectado &agrave; organiza&ccedil;&atilde;o avaliada, usando endere&ccedil;os IP que n&atilde;o sejam da organiza&ccedil;&atilde;o como endere&ccedil;os IP de origem.</p>
                    <p>O tr&aacute;fego deve ser direcionado a um dispositivo conectado a um sistema aut&ocirc;nomo externo. Neste &uacute;ltimo dispositivo &eacute; onde podemos concluir, caso observemos a chegada do tr&aacute;fego manipulado, que &eacute; poss&iacute;vel o<em> outbound IP spoofing.</em></p>
                    <p>Os passos para fazer a verifica&ccedil;&atilde;o manual seriam:</p>
                    <p><strong>Dispositivo conectado &agrave; organiza&ccedil;&atilde;o a ser avaliada</strong></p>
                    <p># Assumindo que</p>
                    <p># o IP do dispositivo na organiza&ccedil;&atilde;o tem o IP x.x.x.1</p>
                    <p># o IP do dispositivo conectado a um sistema aut&ocirc;nomo externo &eacute; y.y.y.y</p>
                    <p># o IP que ser&aacute; usado como IP de origem no teste ser&aacute; o endere&ccedil;o z.z.z.z</p>
                    <p># Teste de verifica&ccedil;&atilde;o inicial: Para verificar que o tr&aacute;fego chega</p>
                    <p><strong>hping -S -p 443 -c 1 y.y.y.y</strong></p>
                    <p># Teste para verificar o outbound IP spoofing</p>
                    <p><strong>hping -S -p 443 -c 1 -a z.z.z.z y.y.y.y</strong></p>
                    <p><strong>Dispositivo conectado a um sistema aut&ocirc;nomo externo</strong></p>
                    <p># Assumindo que a interface de rede seja eth0 e usando filtros para observar</p>
                    <p># apenas o tr&aacute;fego de interesse</p>
                    <p><strong>tcpdump -n -i eth0 host x.x.x.1 ou host z.z.z.z</strong></p>
                    <p>Dependendo da atividade mostrada pelo comando <strong>tcpdump</strong> pode-se concluir se o <em>outbound IP spoofing</em> &eacute; poss&iacute;vel. Com base nas observa&ccedil;&otilde;es, os cen&aacute;rios poss&iacute;veis s&atilde;o:</p>
                    <ul>
                        <li>O <em>outbound IP spoofing</em> &eacute; poss&iacute;vel: Para concluir isto, com o comando <em>tcpdump</em> deveria ser poss&iacute;vel observar o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial e o tr&aacute;fego associado ao teste do <em>outbound IP spoofing.</em></li>
                    </ul>
                    <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <p>&nbsp;IP z.z.z.z.1899 &gt; y.y.y.y.443: Flags [S], seq 883275884, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; z.z.z.z.1899: Flags [R.], seq 0, ack 883275885, length 0</p>
                    <ul>
                        <li>O <em>outbound</em> IP <em>spoofing</em> n&atilde;o &eacute; poss&iacute;vel: Para concluir isto, com o comando <em>tcpdump</em> deveria ser poss&iacute;vel observar somente o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial.</li>
                    </ul>
                    <p>&nbsp;IP x.x.x.1.7323 &gt; y.y.y.y.443: Flags [S], seq 45611324, length 0</p>
                    <p>&nbsp;IP y.y.y.y.443 &gt; x.x.x.1.7323: Flags [R.], seq 0, ack 45611325, length 0</p>
                    <ul>
                        <li>N&atilde;o &eacute; poss&iacute;vel determinar: Se n&atilde;o observarmos o tr&aacute;fego associado ao teste de verifica&ccedil;&atilde;o inicial, podemos concluir que existe um filtro de rede que nos impede de fazer a verifica&ccedil;&atilde;o.</li>
                    </ul>
                    <h2>Mecanismo autom&aacute;tico de detec&ccedil;&atilde;o do IP spoofing</h2>
                    <p>A avalia&ccedil;&atilde;o manual do IP <em>spoofing </em>&eacute; uma forma pr&aacute;tica de entender o problema com o objetivo de mitig&aacute;-lo. No entanto, o uso de ferramentas que automatizam a sua detec&ccedil;&atilde;o &eacute; a op&ccedil;&atilde;o recomendada para se ter uma vis&atilde;o completa e exaustiva do problema. Hoje temos duas ferramentas que ajudam a detectar o IP <em>spoofing</em> de forma autom&aacute;tica.</p>
                    <h3>The Closed Resolved Project</h3>
                    <p>O projeto <em>The Closed Resolved Project</em> &eacute; um portal da web que fornece informa&ccedil;&otilde;es sobre a possibilidade de fazer <em>inbound IP spoofing</em>. &Eacute; usado o endere&ccedil;o IP do visitante para informar dos resultados.</p>
                    <h3>Spoofer</h3>
                    <p>&Euml; uma ferramenta fornecida pelo projeto <a href="https://www.caida.org/projects/spoofer/#download-client-software" target="_blank" rel="noreferrer">Spoofer de CAIDA</a>.&nbsp;Este projeto &eacute; a principal fonte de dados sobre o problema do IP <em>spoofing</em>. A ferramenta oferecida pelo projeto &eacute; a que permite obter os dados que s&atilde;o fornecidos &agrave; comunidade.</p>
                    <p>Para <a href="https://www.caida.org/projects/spoofer/#download-client-software" target="_blank" rel="noreferrer">executar os testes</a>, o <em>software</em> chamado <em>spoofer</em> deve ser instalado.&nbsp;Os testes levam em conta um grande n&uacute;mero de situa&ccedil;&otilde;es particulares para avaliar tanto o <em>inbound IP spoofing</em> quanto o <em>outbound IP spoofing</em>.</p>
                    <p>O <em>Spoofer</em> avalia a possibilidade de fazer <em>IP spoofing</em> tanto com tr&aacute;fego IPv4 quanto com tr&aacute;fego IPv6. Al&eacute;m disso, avalia se &eacute; poss&iacute;vel usar endere&ccedil;os IP pertencentes a blocos IP n&atilde;o rote&aacute;veis, como IP de origem, por exemplo, <a href="http://www.ietf.org/rfc/rfc1918.txt" target="_blank" rel="noreferrer">blocos IP privados</a>&nbsp;no <a href="https://www.ietf.org/rfc/rfc4193.txt" target="_blank" rel="noreferrer">IPv4 ou ULA</a>&nbsp;no IPv6.</p>
                    <p>Apesar de possuir uma interface gr&aacute;fica, a ferramenta funciona como um servi&ccedil;o que faz testes peri&oacute;dicos, permitindo a avalia&ccedil;&atilde;o das diferentes redes &agrave;s quais o usu&aacute;rio se conecta.</p>
                    <p>A Figura 3 mostra como a ferramenta &eacute; apresentada ao usu&aacute;rio. Aparece que o usu&aacute;rio disp&otilde;e da funcionalidade para iniciar novos testes. Tamb&eacute;m pode ver um resumo dos testes realizados e acessar o relat&oacute;rio.</p>
                    <img src="/images/anti-spoofing/fig3.png" alt="Interfaz gr&aacute;fica del cliente Spoofer de CAIDA" />
                    <p>Fig. 3 - Interface gr&aacute;fica do cliente Spoofer de CAIDA</p>
                    <p>Os relat&oacute;rios gerados t&ecirc;m detalhes sobre o <em>inbound</em> e o <em>outbound</em> IP <em>spoofing</em>. A Figura 4 mostra um resumo dos resultados do teste para IPv4, IPv6, uso de endere&ccedil;os n&atilde;o rote&aacute;veis, uso de endere&ccedil;os rote&aacute;veis ​​e, se poss&iacute;vel, tanto o <em>inbound</em> quanto o <em>outbound</em> IP <em>spoofing</em>. A Figura 5 mostra a cadeia de sistemas aut&ocirc;nomos por onde os pacotes <em>spoofeados</em> s&atilde;o roteados nos testes realizados.</p>
                    <img src="/images/anti-spoofing/fig4.png" alt=" Secci&oacute;n resumen del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 4- Se&ccedil;&atilde;o resumo do relat&oacute;rio do cliente Spoofer de CAIDA</p>
                    <img src="/images/anti-spoofing/fig5.png" alt="Secci&oacute;n IPv4 AS route del reporte del cliente Spoofer de CAIDA" />
                    <p>Fig. 5- Se&ccedil;&atilde;o IPv4 AS route do relat&oacute;rio do cliente Spoofer de CAIDA</p> 
                </>}
            </div>
        </div>
    )
}

export default Mecanismos
