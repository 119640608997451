import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Conclusiones() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p8">
                {myLang === 'es' && <>
                    <h2>Conclusiones</h2>
                    <p>Los resultados muestran una clara evoluci&oacute;n hacia un mejor alcanzabilidad a los root servers continuado desde el a&ntilde;o 2013. Los momentos de cambios evidencian la instalaci&oacute;n de copias, lo que fortalece estas iniciativas e invitan a seguir su despliegue.</p>
                    <p>En t&eacute;rminos generales podemos decir que un 58% de los pa&iacute;ses de la regi&oacute;n tienen al menos 1 root server alcanzable bajo los 50 ms de RTT, lo que es una cifra aceptable en los tiempos de respuesta esperables para una consulta DNS. Si buscamos respuestas bajo los 85 ms, ya tenemos una cobertura del 84% de pa&iacute;ses, que representa la totalidad de zonas con mediciones relevantes para este estudio.</p>
                    <p>Es de esperar que estas cifras sigan mejorando. La instalaci&oacute;n de nuevas copias de root servers, tanto por iniciativas de +RAICES como la gesti&oacute;n de los propios administradores de root servers, as&iacute; como tecnolog&iacute;as tales como “Decreasing Access Time to Root Servers by Running One on Loopback” (<a href="https://tools.ietf.org/html/rfc7706" target="blank" rel="noreferrer">RFC 7706</a>) que permite tener espejos internos de la zona ra&iacute;z del DNS, hagan mejorar la experiencia de usuario en la navegaci&oacute;n de Internet.</p>
                    <p>Este estudio fue presentado durante la conferencia LACNOG 2019 realizada en el mes de octubre en Panam&aacute;. Tuvo muy buena acogida entre los operadores de servicios Internet presentes en la reuni&oacute;n, indicando que tanto las cifras a nivel regional como de pa&iacute;s, as&iacute; como la identificaci&oacute;n de lugares con a&uacute;n mal servicio, permite gestionar de mejor manera la instalaci&oacute;n de copias y justificar las inversiones dentro de cada organismo.</p>
                    <p>Se espera continuar el an&aacute;lisis con un an&aacute;lisis de “radio de cobertura” de cada nodo en la regi&oacute;n, incluir informaci&oacute;n de instalaci&oacute;n provista por cada administrador de root server, y en un futuro ampliar el alcance bajando en el &aacute;rbol DNS desde los root servers hacia los ccTLDs de la regi&oacute;n.</p>

                </>}
                {myLang === 'en' && <>
                    <h2>Conclusions</h2>
                    <p>The results show a clear and continuous evolution towards improved route server reachability since 2013. Moments of change mark the installation of root server copies, which strengthen these initiatives and encourage the region to continue deploying new instances.</p>
                    <p>In general, we can say that 58% of the countries in the region have at least one root server that is reachable with an RTT lower than 50 ms, an acceptable response time for a DNS query. An analysis of response times under 85 ms shows that 84% of the countries are already covered, a figure that represents all the areas with relevant measurements in this study.</p>
                    <p>Likewise, these figures are expected to continue to improve. The installation of new root server copies — by both the +RAICES initiatives as well as by the root server operators themselves — and technologies such as Decreasing Access Time to Root Servers by Running One on Loopback (<a href="https://tools.ietf.org/html/rfc7706" target="_blank" rel="noreferrer">RFC 7706</a>) which allow having internal mirrors of the DNS root zone will improve the user experience in Internet browsing.</p>
                    <p>This study was presented during the LACNOG 2019 conference held in Panama in the month of October. It was very well-received by the Internet service providers present at the meeting, who observed that the figures at both regional and national level as well as the possibility of identifying locations with poor quality of service will allow them to better handle the installation of root server copies and to justify the investments within each organization.</p>
                    <p>We expect to continue this report with an analysis of the coverage radius of each node within the region, including the installation data provided by each root server administrator and, in the future, expanding the scope of the study by moving down the DNS tree, beginning at root server level and working our way down to the ccTLDs of the region.</p>

                </>}
            </div>

        </div>
    )
}

export default Conclusiones
