import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css' 
import Resumen from "./Resumen";  
import Introduccion from "./Introduccion";
import Metodologia from "./Metodologia";
import Estudios from "./Estudios";
import Comparacion from "./Comparacion";
import LatenciaAgregadaComparada from "./LatenciaAgregadaComparada";
import ClustersDeLatencia from "./ClustersDeLatencia";
import Conclusiones from "./Conclusiones";
import Comparando2017 from "./Comparando2017";

export default function ConectividadEnLac(props) {
    return ( 
        <> 
          <ReportTitle />
          <Resumen /> 
          <Introduccion /> 
          <Metodologia /> 
          <Estudios /> 
          <Comparacion /> 
          <LatenciaAgregadaComparada /> 
          <ClustersDeLatencia /> 
          <Comparando2017 /> 
          <Conclusiones />  
        </>
      )
} 
  
  function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
      <h1>
          {myLang === 'es' && 'Conectividad en la región LAC: 2020'}
          {myLang === 'en' && 'Connectivity in the LAC Region in 2020'}
          {myLang === 'pt' && 'Conectividade na região da LAC: 2020'}
      </h1>
      )
  }