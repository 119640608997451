import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext'; 
import {Link} from 'react-router-dom' 

function DNSOverTLSIntro() {
    const { myLang } = useContext(LangContext)
    return (
        <div> 
            <div>
                { myLang === 'es' && <> 
                    <h1>Uso de DNS-over-TLS en Latinoamérica y el Caribe</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Medir la disponibilidad de la tecnolog&iacute;a&nbsp;<strong>DNS-over-TLS</strong>&nbsp;en&nbsp;<em>resolvers</em>&nbsp;DNS en la regi&oacute;n de Am&eacute;rica Latina y el Caribe.</p>
                    <Link className="buttons button_blue" to="/es/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe">Leer Reporte</Link>
                </>}
                { myLang === 'en' && <> 
                    <h1>Use of DNS-over-TLS In Latin America and the Caribbean</h1>
                    <p>Author: Hugo Salgado</p>
                    <p>To measure the availability of DNS-over-TLS technology in DNS resolvers in the region of Latin America and the Caribbean.</p>
                    <Link className="buttons button_blue" to="/en/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe">Read Report</Link> 
                </> }
                { myLang === 'pt' && <> 
                    <h1>Uso do DNS-over-TLS na América Latina e o Caribe</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Medir a disponibilidade da tecnologia DNS-over-TLS en resolvers na região da América Latina e o Caribe.</p>
                    <Link className="buttons button_blue" to="/pt/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe">Leia o Relatório</Link>  
                </>}
            </div> 
        </div>
    )
}

export default DNSOverTLSIntro
