import React from 'react';
import '../css/call-to-action.css'

function Alerts({children, color}) {
    return (
        <div className={`alerts ${color}`}>
            {children}
        </div>
    );
}

export default Alerts;