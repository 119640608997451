import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Anexos() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p10">
                {myLang === 'es' && <>
                    <h2>Anexos</h2>
                    <h3>Anexo A, ubicación de información completa</h3>
                    <p>Los gráficos indicados en este estudio desde cada país se encuentran disponibles en el sitio web que el proyecto +RAICES de LACNIC dispuso para este estudio, en:		<a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="_blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a></p>
                    <h3>Anexo B, información detallada de mediciones RIPE Atlas</h3>
                    <p>Las mediciones recurrentes utilizadas para este estudio corresponden a los “measurement ID” 10209, 10210, 10211, 10212, 10213, 10204, 10214, 10215, 10205, 10216, 10201, 10208 y 10206 (“IPv4 DNS UDP version.bind”).</p>
                    <p>Las sondas utilizadas totalizan 1024 históricamente (no todas activas en este momento), disponibles desde los países mx, gt, bz, hn, sv, ni, cr, pa, cu, ky, jm, ht, do, pr, vi, bq, gp, dm, mq, lc, vc, bb, tt, cw, co, ve, gy, sr, gf, br, ec, pe, bo, cl, ar, uy y py.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Annexes</h2>
                    <h3>Annex A. Availability of Complete Data Sets</h3>
                    <p>The charts for each country mentioned in this document are available at the website created by LACNIC's +RAICES Project for this study:		<a href="https://www.lacnic.net/993/1/lacnic/proyecto-+raices" target="_blank" rel="noreferrer">https://www.lacnic.net/993/1/lacnic/proyecto-+raices</a></p>
                    <h3>Annex B. Detailed Information on the RIPE Atlas Measurements</h3>
                    <p>The recurring measurements used for this study correspond to measurement IDS 10209, 10210, 10211, 10212, 10213, 10204, 10214, 10215, 10205, 10216, 10201, 10208 and 10206 (IPv4 DNS UPD version.bind).</p>
                    <p>Historically, a total of 1024 probes were used (not all of them active at this moment), available in the following countries: mx, gt, bz, hn, sv, ni, cr, pa, cu, ky, jm, ht, do, pr, vi, bq, gp, dm, mq, lc, vc, bb, tt, cw, co, ve, gy, sr, gf, br, ec, pe, bo, cl, ar, uy and py.</p>

                </>}
            </div>

        </div>
    )
}

export default Anexos
