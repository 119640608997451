import React, { useContext } from 'react';
import {NavLink} from 'react-router-dom'
import { LangContext, MenuContext } from '../context/DataContext'

export default function MenuPrincipal() {
    const { myLang } = useContext(LangContext)

    const { openMenu } = useContext(MenuContext) 

     
    return (
        <nav className="menu" onClick={openMenu}>
            <ul>
                <li>
                    {myLang === 'es' && <NavLink to="/es/ciberseguridad">Ciberseguridad</NavLink>}
                    {myLang === 'en' && <NavLink to="/en/ciberseguridad">Cibersecurity</NavLink>}
                    {myLang === 'pt' && <NavLink to="/pt/ciberseguridad">Cibersegurança</NavLink>}
                </li>
                <li>
                    {myLang === 'es' && <NavLink to="/es/interconexion">Interconexión</NavLink>}
                    {myLang === 'en' && <NavLink to="/en/interconexion">Interconnection</NavLink>}
                    {myLang === 'pt' && <NavLink to="/pt/interconexion">Interconexão</NavLink>}
                </li>
                <li>
                    {myLang === 'es' && <NavLink to="/es/ipv6">IPv6</NavLink>}
                    {myLang === 'en' && <NavLink to="/en/ipv6">IPv6</NavLink>}
                    {myLang === 'pt' && <NavLink to="/pt/ipv6">IPv6</NavLink>}
                </li>
            </ul>
        </nav>
    )
}
