import React, {useState, useEffect} from 'react';

export default function DocumentTitle({children}) {
    const [documentTitle, setDocumentTitle] = useState(children);

    useEffect(() => {
        document.title = documentTitle
    }, [setDocumentTitle]);
    return (
        <>{children}</>
    )
}