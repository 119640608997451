import React, {useState, useEffect} from 'react';
import { MobileContext } from './DataContext';  

export const MobileState = ( {children} ) => {   
 
    const [isMobile, setIsMobile] = useState(window.innerWidth < 870);
        
    useEffect(() => {
        window.addEventListener("resize", () => {
             
            const ismobile = window.innerWidth < 870;
            if (ismobile !== isMobile) setIsMobile(ismobile)  ;
            
        }, false);
    }, [isMobile]);

    return (
        <MobileContext.Provider value={
             {isMobile}
          }>
             {children}
        </MobileContext.Provider>
    );
}

 