import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'

function Relevamiento(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-55">Relevamiento inicial</MatchText></h2>
                    <p>
                        <MatchText id="match-56">Como se entendió en la fase preliminar del estudio, es pertinente analizar y evaluar la problemática en la región, haciendo foco en la detección, concientización y mitigación de instancias de servicios vulnerables de los protocolos: NTP, DNS, CharGen y SSDP.</MatchText></p>
                    <p>
                        <MatchText id="match-57">En la evaluación se relevaron distintas fuentes de información. Una vez logrados los resultados, se hizo un análisis y en función de ello se definieron las acciones de mitigación.</MatchText></p>
                    <h3>
                        <MatchText id="match-58">Fuentes de información utilizadas en el relevamiento</MatchText></h3>
                    <p>
                        <MatchText id="match-59">Para el estudio, además de la realización de pruebas propias, se utilizó la información provista tanto por Shadowserver como por Shodan. Se utilizó como fuente primaria Shadowserver, porque proviene del proyecto SCANNING, que identifica, entre otros problemas, la presencia de recursos en internet implementando protocolos amplificables y reflexivos tratados en este estudio.</MatchText></p>
                    <p>
                        <MatchText id="match-60">Una ventaja significativa en el uso de Shadowserver como fuente de información es que el proyecto no se limita simplemente a realizar el escaneo de los servicios, sino que evalúa si estos son vulnerables al permitir ser utilizados en ataques de amplificación.</MatchText></p>
                    <p>
                        <MatchText id="match-61">En el caso del protocolo CharGen, en el que la sola exposición del servicio es suficiente para considerarlo vulnerable, se utilizó Shodan como fuente primaria, puesto que se estableció contacto con Shodan y fuimos informados sobre la posibilidad de buscar instancias de dicho servicio corriendo sobre UDP. Para ello se realizó la búsqueda de la siguiente manera: «port:19 shodan.module:newline-udp».</MatchText></p>
                    <p>
                        <MatchText id="match-62">La experiencia resultó por demás interesante, puesto que las instancias TCP de dicho servicio no son vulnerables por no ser TCP un protocolo reflexivo (como sí es UDP).</MatchText></p>
                    <p>
                        <MatchText id="match-63">Adicionalmente, se realizaron pruebas manuales para corroborar la información provista por las fuentes de información utilizadas. También, para analizar posibles situaciones que despiertan interrogantes, como la observada respecto del servicio SSDP informado por Shadowserver, que detecta la presencia de dicho protocolo en diferentes puertos UDP.</MatchText></p>
                    <h3>
                        <MatchText id="match-64">Resultados preliminares</MatchText></h3>
                    <p>
                        <MatchText id="match-65">De los reportes obtenidos se definieron distintas vistas para presentar la información de las problemáticas, utilizando diferentes sumarizaciones: problemas por sistema autónomo, problemas por país. Dado que la sumarización por país no refleja cuestiones propias de cada uno, sino que simplemente va de la mano del tamaño de estos, se prefirió hacer el análisis de cada una de las problemáticas previstas en los protocolos analizados por sistema autónomo.</MatchText></p>
                    <p>
                        <MatchText id="match-66">En el siguiente gráfico se muestran los porcentajes de servicios CharGen vulnerables por ASN. El total de servicios vulnerables hallados para toda la región fue de 918.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r7_es.png" /> </figure>
                    <p>
                        <MatchText id="match-67">En el siguiente gráfico se muestran los porcentajes de servidores NTP por ASN que admiten consultas tipo READVAR (Mode 6). El total de servidores vulnerables hallados para toda la región fue de 226.984.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r8_es.png" /> </figure>
                    <p>
                        <MatchText id="match-68">En el siguiente gráfico se muestran los porcentajes de servidores NTP por ASN que admiten consultas tipo MONLIST (Mode 7). El total de servidores vulnerables hallados para toda la región fue de 2598.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r9_es.png" /> </figure>
                    <p>
                        <MatchText id="match-69">En el siguiente gráfico se muestran los porcentajes de servicios SSDP vulnerables por ASN. El total de servicios vulnerables hallados para toda la región fue de 369.039.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r10_es.png" /> </figure>
                    <p>
                        <MatchText id="match-70">En el siguiente gráfico se muestran los porcentajes de DNS open resolvers por ASN. El total de DNS open resolvers hallados para toda la región fue de 122.679.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r11_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-71">Análisis</MatchText></h3>
                    <p>
                        <MatchText id="match-72">El procesamiento de la información de manera gráfica permitió pensar una estrategia para el reporte de las problemáticas, maximizando las vulnerabilidades que se gestionarán. Por eso, a partir de estos, se realizaron las siguientes observaciones y determinaciones:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-73">En base a los datos obtenidos sobre los protocolos NTPversion y NTPmonitor se decidió realizar reportes por separado de estas problemáticas, de manera de gestionar adecuadamente los problemas de cada tipo.</MatchText></li>
                        <li>
                            <MatchText id="match-74">Se observó que en el caso del protocolo SSDP existe una disparidad muy grande entre el sistema autónomo que más problemas presenta, respecto al resto. Esto incluso provoca que el país al que pertenece dicha organización figure en el Top 20 de las estadísticas de Shadowserver por los problemas en este ASN.</MatchText></li>
                        <li>
                            <MatchText id="match-75">Aprovechando que un gran porcentaje de vulnerabilidades detectadas para los protocolos CharGen, SSDP y NTP se concentraba en unas pocas organizaciones, se propuso realizar el reporte a aquellas tres con más problemas hallados (por protocolo) considerando:</MatchText></li>
                        <li>
                            <MatchText id="match-76">
                                <ul>
                                    <li>
                                        <MatchText id="match-77">Maximizar el porcentaje de vulnerabilidades a gestionar.</MatchText></li>
                                    <li>
                                        <MatchText id="match-78">Incluir organizaciones de distintos países para abarcar e incluir en posteriores fases de concienciación diferentes regiones geográficas del área de cobertura de LACNIC.</MatchText></li>
                                </ul>
                            </MatchText></li>
                        <li>
                            <MatchText id="match-79">Para el caso de DNS open resolvers, dada la uniformidad observada en la cantidad de ocurrencias detectadas por organización, se propuso realizar el reporte a las cinco organizaciones con más instancias de DNS open resolvers, considerando incluir, al igual que en el caso anterior, organizaciones de distintos países.</MatchText></li>
                        <li>
                            <MatchText id="match-80">Respecto al protocolo SSDP, cabe mencionar que los reportes de Shadowserver mostraron la inclusión de dispositivos corriendo en otros puertos, de los cuales el puerto 1900 no fue el más utilizado.</MatchText></li>
                    </ul>

                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-55">Initial Survey</MatchText></h2>
                    <p>
                        <MatchText id="match-56">During the preliminary phase of the study, the decision was made to analyze and evaluate the problem in our region, focusing on the detection, awareness, and mitigation of vulnerable instances of the NTP, DNS, CharGen, and SSDP protocols.</MatchText></p>
                    <p>
                        <MatchText id="match-57">First, different sources of information were surveyed. Once the information had been gathered, an analysis was conducted and mitigation actions were defined.</MatchText></p>
                    <h3>
                        <MatchText id="match-58">Information Sources Used in the Survey</MatchText></h3>
                    <p>
                        <MatchText id="match-59">In addition to conducting our own tests, the study also used information provided by both Shadowserver and Shodan. Shadowserver&rsquo;s SCANNING project was used as our primary source of information as, among other issues, it identifies the presence of Internet resources that implement amplifiable and reflective protocols as discussed in this study.</MatchText></p>
                    <p>
                        <MatchText id="match-60">A significant advantage in using Shadowserver as a source of information is that the project is not limited to scanning the services, but rather assesses whether they are vulnerable and can be used in amplification attacks.</MatchText></p>
                    <p>
                        <MatchText id="match-61">In the case of the CharGen protocol where the mere exposure of the service is enough to consider it vulnerable, Shodan was used as the primary source of information, as we contacted Shodan and were informed about the possibility of searching for instances of the service running over UDP . This search was performed as follows: «port:19 shodan.module:newline-udp».</MatchText></p>
                    <p>
                        <MatchText id="match-62">The experience was extremely interesting, as the TCP instances of this service are not vulnerable because TCP is not a reflective protocol (while UDP is).</MatchText></p>
                    <p>
                        <MatchText id="match-63">In addition, manual tests were performed to verify the information provided by the sources listed above. Manual tests were also performed to analyze situations that might potentially raise questions, such as the one observed regarding the SSDP service reported by Shadowserver which detects the presence of the protocol on different UDP ports.</MatchText></p>
                    <h3>
                        <MatchText id="match-64">Preliminary Findings</MatchText></h3>
                    <p>
                        <MatchText id="match-65">We summarized the data of the reports we obtained in different ways, namely, vulnerable servers and services by autonomous system and by country. Considering that summarizing the data by country does not reflect the issues specific to each country but instead relates exclusively to their size, we preferred to analyze the issues related to each protocol by autonomous system.</MatchText></p>
                    <p>
                        <MatchText id="match-66">The following chart shows the percentage of vulnerable CharGen services by ASN. A total of 918 vulnerable services were found for the region as a whole.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r7_es.png" /> </figure>
                    <p>
                        <MatchText id="match-67">The following chart shows the percentage of NTP servers by ASN that support READVAR (Mode 6) queries. A total of 226,984 vulnerable servers were found for the region as a whole.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r8_es.png" /> </figure>
                    <p>
                        <MatchText id="match-68">The following chart shows the percentages of NTP servers by ASN that support MONLIST (Mode 7) queries. A total of 2,598 vulnerable servers were found for the region as a whole.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r9_es.png" /> </figure>
                    <p>
                        <MatchText id="match-69">The following chart shows the percentages of vulnerable SSDP services by ASN. A total of 369,039 vulnerable services were found for the region as a whole.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r10_es.png" /> </figure>
                    <p>
                        <MatchText id="match-70">The following chart shows the percentage of open DNS resolvers by ASN. A total of 122,679 open DNS resolvers were found for the region as a whole.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r11_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-71">Analysis</MatchText></h3>
                    <p>
                        <MatchText id="match-72">By processing the information in a graphic format, we were able to design a strategy for reporting the vulnerable targets we detected, thus maximizing the number of vulnerabilities we would be able to manage. The following observations and determinations are based on the charts above:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-73">Based on the data obtained for the NTPversion and NTPmonitor protocols, the decision was made to report each type of issue separately so that they could be properly managed.</MatchText></li>
                        <li>
                            <MatchText id="match-74">In the case of the SSDP protocol, a very large disparity was observed between the autonomous system with the highest number of vulnerable targets and the others. This autonomous system even places the country where the organization is located on Shadowserver's list of Top 20 countries with open SSDP.</MatchText></li>
                        <li>
                            <MatchText id="match-75">Taking advantage of the fact that a large percentage of the vulnerabilities detected for the CharGen, SSDP, and NTP protocols were concentrated in a just few organizations, the decision was made to report to the three organizations where the highest number of vulnerable targets was found (per protocol), with the following considerations:</MatchText></li>
                        <li>
                            <MatchText id="match-76">
                                <ul>
                                    <li>
                                        <MatchText id="match-77">Maximizing the percentage of vulnerabilities that could be managed.</MatchText></li>
                                    <li>
                                        <MatchText id="match-78">○ Including organizations from different countries so as to cover different geographic regions in the area serviced by LACNIC and including them in subsequent awareness raising stages.</MatchText></li>
                                </ul>
                            </MatchText></li>
                        <li>
                            <MatchText id="match-79">In the case of open DNS resolvers, given the uniformity observed in the number of cases detected per organization, the decision was made to report to the five organizations with the highest number of open DNS resolvers, also including organizations from different countries.</MatchText></li>
                        <li>
                            <MatchText id="match-80">In the case of the SSDP protocol, it should be noted that Shadowserver reports included devices running on different ports, and that port 1900 was not the port that was used the most.</MatchText></li>
                    </ul>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-55">Levantamento inicial</MatchText></h2>
                    <p>
                        <MatchText id="match-56">Assim como foi entendido na fase preliminar do estudo, resulta pertinente analisar e avaliar o problema na região, com foco na detecção, conscientização e mitigação de instâncias de serviços vulneráveis dos protocolos: NTP, DNS, CharGen e SSDP.</MatchText></p>
                    <p>
                        <MatchText id="match-57">Na avaliação, foram levantadas diferentes fontes de informação. Alcançados os resultados, procedeu-se a uma análise e, a partir desta, foram definidas as ações de mitigação.</MatchText></p>
                    <h3>
                        <MatchText id="match-58">Fontes de informação usadas no levantamento</MatchText></h3>
                    <p>
                        <MatchText id="match-59">Para o estudo, além de realizarmos nossos próprios testes, usamos informações fornecidas pelo Shadowserver e pelo Shodan. O Shadowserver foi usado como fonte primária, uma vez que vem do projeto SCANNING que identifica, entre outros problemas, a presença de recursos na Internet implementando protocolos amplificáveis e reflexivos tratados neste estudo.</MatchText></p>
                    <p>
                        <MatchText id="match-60">Uma vantagem significativa no uso do Shadowserver como fonte de informação é que o projeto não é limitado simplesmente a escanear os serviços, mas sim avaliar se estes são vulneráveis ao permitir que sejam usados em ataques de amplificação.</MatchText></p>
                    <p>
                        <MatchText id="match-61">No caso do protocolo CharGen, em que a mera exposição do serviço é suficiente para considerá-lo vulnerável, foi usado o Shodan como fonte primária, visto que o Shodan foi contactado e fomos informados sobre a possibilidade de procurarmos instâncias do referido serviço rodando no UDP. Para isso, a procura foi realizada da seguinte forma: «port:19 shodan.module:newline-udp».</MatchText></p>
                    <p>
                        <MatchText id="match-62">A experiência foi extremamente interessante uma vez que as instâncias TCP deste serviço não são vulneráveis por não ser, o TCP, um protocolo reflexivo (como sim é o UDP).</MatchText></p>
                    <p>
                        <MatchText id="match-63">Adicionalmente, foram realizados testes manuais para corroborar as informações fornecidas pelas fontes de informação usadas. Também para analisar possíveis situações que suscitem dúvidas, como a observada no que diz respeito ao serviço SSDP reportado pela Shadowserver, que detecta a presença do referido protocolo em diferentes portas UDP.</MatchText></p>
                    <h3>
                        <MatchText id="match-64">Resultados preliminares</MatchText></h3>
                    <p>
                        <MatchText id="match-65">A partir dos relatórios obtidos, foram definidas diferentes visões para apresentar a informação sobre os problemas, usando sumarizações diferentes: problemas por sistema autônomo, problemas por país. Dado que a sumarização por país não reflete questões próprias de cada um, só vai de mãos dadas com o tamanho deles, optou-se por analisar cada um dos problemas previstos nos protocolos analisados por sistema autónomo.</MatchText></p>
                    <p>
                        <MatchText id="match-66">O gráfico a seguir mostra as porcentagens de serviços CharGen vulneráveis por ASN. O total de serviços vulneráveis encontrados para toda a região foi de 918.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r7_es.png" /> </figure>
                    <p>
                        <MatchText id="match-67">O gráfico a seguir mostra as porcentagens de servidores NTP por ASN que admitem consultas tipo READVAR (Modo 6). O total de servidores vulneráveis encontrados para toda a região foi de 226.984.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r8_es.png" /> </figure>
                    <p>
                        <MatchText id="match-68">O gráfico a seguir mostra as porcentagens de servidores NTP por ASN que admitem consultas tipo MONLIST (Modo 7). O total de servidores vulneráveis encontrados para toda a região foi de 2598.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r9_es.png" /> </figure>
                    <p>
                        <MatchText id="match-69">O gráfico a seguir mostra as porcentagens de serviços SSDP vulneráveis por ASN. O total de serviços vulneráveis encontrados para toda a região foi de 369.039.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r10_es.png" /> </figure>
                    <p>
                        <MatchText id="match-70">O gráfico a seguir mostra as porcentagens de DNS open resolvers por ASN. O total de DNS open resolvers encontrados para toda a região foi de 122.679.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r11_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-71">Análise</MatchText></h3>
                    <p>
                        <MatchText id="match-72">O tratamento das informações de forma gráfica permitiu pensar em uma estratégia para relatar os problemas, maximizando as vulnerabilidades que vão ser geridas. Portanto, com base nesses dados, foram feitas as seguintes observações e determinações:</MatchText></p>
                    <ul>
                        <li>
                            <MatchText id="match-73">Com base nos dados obtidos sobre os protocolos NTPversion e NTPmonitor, optou-se por redigir relatórios separados sobre estes problemas, a fim de que os problemas de cada tipo sejam gerenciados adequadamente.</MatchText></li>
                        <li>
                            <MatchText id="match-74">Observou-se que, no caso do protocolo SSDP, existe uma disparidade muito grande entre o sistema autônomo que mais problemas apresenta, em comparação com o resto. Isso até faz com que o país a que esta organização pertence apareça no Top 20 das estatísticas de Shadowserver pelos problemas neste ASN.</MatchText></li>
                        <li>
                            <MatchText id="match-75">Aproveitando o fato de que uma grande porcentagem das vulnerabilidades detectadas para os protocolos CharGen, SSDP e NTP estava concentrada em umas poucas organizações, foi proposto fazer o relatório às três com mais problemas encontrados (por protocolo) considerando:</MatchText></li>
                        <li>
                            <MatchText id="match-76">
                                <ul>
                                    <li>
                                        <MatchText id="match-77">Maximizar a porcentagem de vulnerabilidades a serem gerenciadas.</MatchText></li>
                                    <li>
                                        <MatchText id="match-78">Incluir organizações de diferentes países para cobrir e incluir nas fases subsequentes de sensibilização as diferentes regiões geográficas da área de cobertura do LACNIC.</MatchText></li>
                                </ul>
                            </MatchText></li>
                        <li>
                            <MatchText id="match-79">No caso dos DNS open resolvers, dada a uniformidade observada no número de ocorrências detectadas por organização, foi proposto fazer o relatório das cinco organizações com mais instâncias de DNS open resolvers, considerando incluir, como no caso anterior, organizações de diversos países.</MatchText></li>
                        <li>
                            <MatchText id="match-80">Em relação ao protocolo SSDP, vale ressaltar que os relatórios d Shadowserver mostraram a inclusão de dispositivos rodando em outras portas, das quais a porta 1900 não foi a mais usada.</MatchText></li>
                    </ul>

                </>}
            </div>
        </div>
    );
}

export default Relevamiento;