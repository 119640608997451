import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';
import Button from '../../../components/Button';

function Resumen() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p0">
                {myLang === 'es' && <Contenido />}
                {myLang === 'en' && <Contenido />}
                {myLang === 'pt' && <Contenido />}

            </div>
        </div>
    )
}
function Contenido() {
    return (<div className='alerts alert_white'>
        <p><MatchText id="match-1">Optimizaci&oacute;n de redes mediante un mejor iBGP</MatchText> </p>
        <p><MatchText id="match-2">Claudio Risso , Facultad de Ingenier&iacute;a, UDELAR (Uruguay)</MatchText> </p>
        <p><MatchText id="match-3" >Jos&eacute; Restaino, ANTEL (Uruguay)</MatchText> </p>
        <p><MatchText id="match-4" >Carlos Mart&iacute;nez, LACNIC (Registro de Direcciones de Internet para Am&eacute;rica Latina y el Caribe)</MatchText> </p>
        <p><MatchText id="match-5" >Cristina Mayr, Facultad de Ingenier&iacute;a, Universidad de Montevideo (Uruguay)</MatchText> </p>
        <p><MatchText id="match-6" >C&oacute;mo citar esta publicaci&oacute;n: Risso, C., Restaino, J., Mart&iacute;nez, C., Mayr, C. (2022). LACNIC (Registro de Direcciones de Internet para Am&eacute;rica Latina y el Caribe).</MatchText> </p>
        <Button color="button_blue" to="/reportes/interconexion/optimizacion-de-redes-mediante-un-mejor-ibgp-es.pdf">Download (PDF)</Button>
         
        </div> 
    )
}

export default Resumen
