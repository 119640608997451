import React, { useContext,forwardRef , useRef} from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css';  
import Conclusiones from "./Conclusiones";
import FuentesDeDatos from "./FuentesDeDatos";
import Fundamentacion from "./Fundamentacion";
import Metodologia from "./Metodologia";
import Objetivos from "./Objetivos";
import Resultados from "./Resultados";
import Resumen from "./Resumen";


const DNSOverTLS = ({ applyHighlightToChildren }) => {
  const resultRefs = useRef([]);

  // Función para generar la referencia para un componente dado
  const generateRef = (index) => (ref) => {
    resultRefs.current[index] = ref;
  };
  return (
    <>
      
      <ReportTitle applyHighlightToChildren={applyHighlightToChildren}  ref={generateRef(0)}/>
      <Resumen applyHighlightToChildren={applyHighlightToChildren} />
      <Objetivos   applyHighlightToChildren={applyHighlightToChildren}/>
      <Fundamentacion  applyHighlightToChildren={applyHighlightToChildren} />
      <FuentesDeDatos  applyHighlightToChildren={applyHighlightToChildren} />
      <Metodologia applyHighlightToChildren={applyHighlightToChildren}  />
      <Resultados applyHighlightToChildren={applyHighlightToChildren} />
      <Conclusiones applyHighlightToChildren={applyHighlightToChildren} /></> 
      
  );
};
  
const ReportTitle = forwardRef(({ applyHighlightToChildren }, ref)  => {
  const { myLang } = useContext(LangContext);

  return (
    <div ref={ref}>
    {applyHighlightToChildren([
    <div  > 
      {myLang === 'es' && <h1 key="1">Uso de DNS-over-TLS en Latinoamérica y el Caribe</h1>}
      {myLang === 'en' && <h1>Use of DNS-over-TLS In Latin America and the Caribbean</h1>}
      {myLang === 'pt' && <h1>Uso do DNS-over-TLS na América Latina e o Caribe</h1>}
    </div>
    ])}</div>
  );
});
 
 

export default DNSOverTLS