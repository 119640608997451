import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext';

function ResumenMejoresPractivasParaIXP() {
    const { myLang } = useContext(LangContext)

     
    return (
        <div> {myLang === 'es' && <p>
        Estas recomendaciones de mejores prácticas para IXP es una guía elaborada por LACNIC, con el apoyo de <a href="https://socium.cr/" target="_blank" rel="noreferrer">SOCIUM.CR</a>. Este documento de referencia para Puntos de Intercambio de Tráfico se encuentra agrupado en cinco categorías: Infraestructuras, servicios, operaciones, gobernanza y comunidad. Para LACNIC es fundamental continuar promoviendo y colaborando con otras entidades regionales que permitan fortalecer la infraestructura crítica de Internet. 
        </p>}
        {myLang === 'en' && <p>
        These recommended best practices for IXPs are a guide prepared by LACNIC with the support of <a href="https://socium.cr/" target="_blank" rel="noreferrer">SOCIUM.CR</a>. The document is intended to serve as a reference for Internet Exchange Points and is divided into five categories: Infrastructure, Service, Operation, Governance, and Community. LACNIC believes it is essential to continue promoting and collaborating with other regional entities to strengthen critical Internet infrastructure. 
        </p>}
        {myLang === 'pt' && <p>
        Estas recomendações de melhores práticas para IXP são um guia elaborado pelo LACNIC, com o apoio de <a href="https://socium.cr/" target="_blank" rel="noreferrer">SOCIUM.CR</a>. Este documento de referência para Pontos de Troca de Tráfego está agrupado em cinco categorias: Infraestruturas, serviços, operações, governança e comunidade. Para o LACNIC é fundamental continuar promovendo e colaborando com outras entidades regionais que permitam fortalecer a infraestrutura crítica da Internet. 
        </p>}
        </div>
         
    )
}

export default ResumenMejoresPractivasParaIXP
