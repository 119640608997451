import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function DatosIXP() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p6">
                {myLang === 'es' && <>
                    <h2>Datos por IXP</h2>
                    <h3>Argentina</h3>


                    <table className="table">
                        <tr>
                            <td><strong>CABASE Buenos Aires (AEP)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados: </strong>1976</td>
                            <td><strong>Prefijos (IPv4): </strong>17741</td>
                            <td><strong>Prefijos (IPv6): </strong>3071</td>
                        </tr>
                    </table>


                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                                Cobertura (%) sobre los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure6.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (AEP)</p>
                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>822</td>
                            <td>10473</td>
                            <td>463</td>
                            <td>23.22</td>
                            <td>41.73</td>
                            <td>4.48</td>
                            <td>21409</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.32</td>
                            <td>34</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>692</td>
                            <td>2007</td>
                            <td>828</td>
                            <td>22.96</td>
                            <td>36.47</td>
                            <td>7.3</td>
                            <td>4842</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>1</td>
                            <td>22.23</td>
                            <td>48</td>
                            <td>6.12</td>
                            <td>529</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>16</td>
                            <td>23.5</td>
                            <td>45.25</td>
                            <td>3.42</td>
                            <td>72</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>1</td>
                            <td>23.64</td>
                            <td>29</td>
                            <td>4.52</td>
                            <td>23</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>29</td>
                            <td>5</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>28</td>
                            <td>4.86</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.9</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>4.62</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.12</td>
                            <td>398</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>3.73</td>
                            <td>1246</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>60</td>
                            <td>205</td>
                            <td>11</td>
                            <td>23.59</td>
                            <td>40.27</td>
                            <td>4.45</td>
                            <td>421</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>1</td>
                            <td>194</td>
                            <td>3347</td>
                            <td>1652</td>
                            <td>22.68</td>
                            <td>43.84</td>
                            <td>3.53</td>
                            <td>8386</td>
                        </tr>
                        <tr>
                            <td><strong>other**</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>122</td>
                            <td>533</td>
                            <td>91</td>
                            <td>23.08</td>
                            <td>40.93</td>
                            <td>4</td>
                            <td>1157</td>
                        </tr>
                    </table>

                    <p>* Los IXP de CABASE se conectan a una Central de interconexi&oacute;n (AS52376)</p>
                    <p>** Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>


                    <table className="table">
                        <tr>
                            <td><strong>CABASE Rosario    (ROS)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>1745</td>
                            <td><strong>Prefijos    (IPv4): </strong>17765</td>
                            <td><strong>Prefijos    (IPv6): </strong>33*</td>
                        </tr>
                    </table>
                    <p>* PCH no tiene peering ipv6 activado con este IXP. Esto no significa que el protocolo no est&eacute; funcionando.</p>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >
                                Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure8.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (ROS)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>1**</td>
                            <td>822</td>
                            <td>10476</td>
                            <td>0</td>
                            <td>23.22</td>
                            <td>&nbsp;</td>
                            <td>4.98</td>
                            <td>10476</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>487</td>
                            <td>2007</td>
                            <td>0</td>
                            <td>22.96</td>
                            <td>&nbsp;</td>
                            <td>7.95</td>
                            <td>2007</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>0</td>
                            <td>22.23</td>
                            <td>&nbsp;</td>
                            <td>6.63</td>
                            <td>264</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>0</td>
                            <td>23.5</td>
                            <td>&nbsp;</td>
                            <td>4.04</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6.4</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>3</td>
                            <td>6</td>
                            <td>0</td>
                            <td>22.67</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.62</td>
                            <td>199</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>0</td>
                            <td>20.12</td>
                            <td>&nbsp;</td>
                            <td>4.23</td>
                            <td>622</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>59</td>
                            <td>205</td>
                            <td>0</td>
                            <td>23.59</td>
                            <td>&nbsp;</td>
                            <td>4.99</td>
                            <td>205</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>192</td>
                            <td>3368</td>
                            <td>33</td>
                            <td>22.69</td>
                            <td>48</td>
                            <td>4.12</td>
                            <td>3440</td>
                        </tr>
                        <tr>
                            <td>other***</td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>103</td>
                            <td>533</td>
                            <td>0</td>
                            <td>23.08</td>
                            <td>&nbsp;</td>
                            <td>4.53</td>
                            <td>533</td>
                        </tr>
                    </table>

                    <p>** Los IXP de CABASE se conectan a una Central de interconexi&oacute;n (AS52376)</p>
                    <p>*** Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>



                    <table className="table">
                        <tr>
                            <td><strong>CABASE Bah&iacute;a Blanca (IXPBB)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados: </strong>4568</td>
                            <td><strong>Prefijos (IPv4): </strong>35884</td>
                            <td><strong>Prefijos (IPv6): </strong>3082</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea    de influencia</td>
                            <td>ASN    Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >
                                Cobertura (%) sobre los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure10.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumen de las tablas de ruteo (IXPBB)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>823</td>
                            <td>10513</td>
                            <td>474</td>
                            <td>23.24</td>
                            <td>42.24</td>
                            <td>5.94</td>
                            <td>10987</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>9.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>776</td>
                            <td>2769</td>
                            <td>826</td>
                            <td>22.77</td>
                            <td>36.45</td>
                            <td>8.1</td>
                            <td>3595</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>5</td>
                            <td>0</td>
                            <td>23.6</td>
                            <td>&nbsp;</td>
                            <td>6.2</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>45</td>
                            <td>406</td>
                            <td>1</td>
                            <td>22.67</td>
                            <td>48</td>
                            <td>6.57</td>
                            <td>407</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>58</td>
                            <td>16</td>
                            <td>24.24</td>
                            <td>45.25</td>
                            <td>4.93</td>
                            <td>74</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>14</td>
                            <td>1</td>
                            <td>23.71</td>
                            <td>29</td>
                            <td>5.67</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>86</td>
                            <td>1</td>
                            <td>23.77</td>
                            <td>29</td>
                            <td>4.55</td>
                            <td>87</td>
                        </tr>
                        <tr>
                            <td><strong>GT</strong></td>
                            <td>0</td>
                            <td>15</td>
                            <td>111</td>
                            <td>0</td>
                            <td>23.82</td>
                            <td>&nbsp;</td>
                            <td>6.09</td>
                            <td>111</td>
                        </tr>
                        <tr>
                            <td><strong>HN</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.69</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>183</td>
                            <td>1</td>
                            <td>23.73</td>
                            <td>28</td>
                            <td>9.68</td>
                            <td>184</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>5</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.62</td>
                            <td>&nbsp;</td>
                            <td>7</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>6.2</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>18</td>
                            <td>204</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>7.63</td>
                            <td>204</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>624</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>5.22</td>
                            <td>626</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>49</td>
                            <td>894</td>
                            <td>0</td>
                            <td>22.9</td>
                            <td>&nbsp;</td>
                            <td>5.61</td>
                            <td>894</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>630</td>
                            <td>4956</td>
                            <td>11</td>
                            <td>22.44</td>
                            <td>40.27</td>
                            <td>6.49</td>
                            <td>4967</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>0</td>
                            <td>1358</td>
                            <td>10966</td>
                            <td>1653</td>
                            <td>22.18</td>
                            <td>43.84</td>
                            <td>5.72</td>
                            <td>12619</td>
                        </tr>
                        <tr>
                            <td><strong>Other**</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>148</td>
                            <td>1</td>
                            <td>22.12</td>
                            <td>56</td>
                            <td>6.74</td>
                            <td>149</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>770</td>
                            <td>3898</td>
                            <td>91</td>
                            <td>21.57</td>
                            <td>40.93</td>
                            <td>5.84</td>
                            <td>3989</td>
                        </tr>
                    </table>


                    <p>* Los IXP de CABASE se conectan a una Central de interconexi&oacute;n (AS52376)</p>
                    <p>** Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>

                    <h3>Chile</h3>
                    <table className="table">
                        <tr>
                            <td><strong>PIT Chile (ARI)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>1819</td>
                            <td><strong>Prefijos    (IPv4): </strong>15559</td>
                            <td><strong>Prefijos    (IPv6): </strong>41*</td>
                        </tr>
                    </table>

                    <p>* PCH no tiene peering ipv6 activado con este IXP. Esto no significa que el protocolo no est&eacute; funcionando.</p>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure12.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (ARI)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>0</td>
                            <td>365</td>
                            <td>3139</td>
                            <td>0</td>
                            <td>23.41</td>
                            <td>&nbsp;</td>
                            <td>5.8</td>
                            <td>3139</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>235</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>699</td>
                            <td>2517</td>
                            <td>0</td>
                            <td>23.05</td>
                            <td>&nbsp;</td>
                            <td>7.23</td>
                            <td>2517</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>1</td>
                            <td>281</td>
                            <td>4837</td>
                            <td>0</td>
                            <td>22.95</td>
                            <td>&nbsp;</td>
                            <td>3.9</td>
                            <td>4837</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>3</td>
                            <td>25</td>
                            <td>0</td>
                            <td>23.52</td>
                            <td>&nbsp;</td>
                            <td>3.24</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.12</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>22</td>
                            <td>301</td>
                            <td>0</td>
                            <td>23.09</td>
                            <td>&nbsp;</td>
                            <td>3.73</td>
                            <td>301</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>24</td>
                            <td>307</td>
                            <td>0</td>
                            <td>22.03</td>
                            <td>&nbsp;</td>
                            <td>4.4</td>
                            <td>307</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>10</td>
                            <td>611</td>
                            <td>0</td>
                            <td>20.07</td>
                            <td>&nbsp;</td>
                            <td>3.09</td>
                            <td>611</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>208</td>
                            <td>2801</td>
                            <td>41</td>
                            <td>22.61</td>
                            <td>48</td>
                            <td>4.51</td>
                            <td>2889</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>112</td>
                            <td>519</td>
                            <td>0</td>
                            <td>23.51</td>
                            <td>&nbsp;</td>
                            <td>5.31</td>
                            <td>519</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>61</td>
                            <td>211</td>
                            <td>0</td>
                            <td>23.56</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>211</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>other**</td>
                            <td>0</td>
                            <td>3</td>
                            <td>14</td>
                            <td>0</td>
                            <td>21.93</td>
                            <td>&nbsp;</td>
                            <td>3.71</td>
                            <td>14</td>
                        </tr>
                    </table>

                    <p>** Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>


                    <table className="table">
                        <tr>
                            <td><strong>PIT Chile (SCL)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>1897</td>
                            <td><strong>Prefijos    (IPv4): </strong>17525</td>
                            <td><strong>Prefijos    (IPv6): </strong>3525</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure12_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure13_1.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure14.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumen de las tablas de ruteo (SCL)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>5</td>
                            <td>367</td>
                            <td>3148</td>
                            <td>98</td>
                            <td>23.39</td>
                            <td>38.93</td>
                            <td>4.1</td>
                            <td>9905</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>4.08</td>
                            <td>940</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>2</td>
                            <td>745</td>
                            <td>2580</td>
                            <td>364</td>
                            <td>23.05</td>
                            <td>35.8</td>
                            <td>5.47</td>
                            <td>11522</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>87</td>
                            <td>283</td>
                            <td>4923</td>
                            <td>811</td>
                            <td>22.97</td>
                            <td>46.59</td>
                            <td>2.32</td>
                            <td>18688</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>3</td>
                            <td>32</td>
                            <td>21</td>
                            <td>23.59</td>
                            <td>45.9</td>
                            <td>1.63</td>
                            <td>201</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>2</td>
                            <td>24</td>
                            <td>40</td>
                            <td>4</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.07</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>2</td>
                            <td>22</td>
                            <td>301</td>
                            <td>8</td>
                            <td>23.09</td>
                            <td>37</td>
                            <td>2.11</td>
                            <td>1028</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>1</td>
                            <td>24</td>
                            <td>307</td>
                            <td>24</td>
                            <td>22.03</td>
                            <td>42.08</td>
                            <td>2.82</td>
                            <td>971</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>2</td>
                            <td>10</td>
                            <td>611</td>
                            <td>1</td>
                            <td>20.07</td>
                            <td>48</td>
                            <td>1.42</td>
                            <td>1837</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.33</td>
                            <td>43</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>1</td>
                            <td>65</td>
                            <td>213</td>
                            <td>13</td>
                            <td>23.6</td>
                            <td>40.77</td>
                            <td>3.11</td>
                            <td>906</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>22</td>
                            <td>225</td>
                            <td>4574</td>
                            <td>2061</td>
                            <td>22.49</td>
                            <td>44.45</td>
                            <td>2.16</td>
                            <td>24450</td>
                        </tr>
                        <tr>
                            <td>other*</td>
                            <td>1</td>
                            <td>7</td>
                            <td>18</td>
                            <td>13</td>
                            <td>22.33</td>
                            <td>38.77</td>
                            <td>2.31</td>
                            <td>89</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>5</td>
                            <td>115</td>
                            <td>541</td>
                            <td>109</td>
                            <td>23.56</td>
                            <td>47.42</td>
                            <td>2.86</td>
                            <td>2253</td>
                        </tr>
                    </table>

                    <p>* Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>

                    <h3>Costa Rica</h3>

                    <table className="table">
                        <tr>
                            <td><strong>Punto de    Intercambio Neutro de Internet (CRIX)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC*</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>59</td>
                            <td><strong>Prefijos    (IPv4): </strong>643</td>
                            <td><strong>Prefijos    (IPv6): </strong>156</td>
                        </tr>
                    </table>

                    <p>* Este IXP cuenta con colector tanto de LACNIC como PCH.</p>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure16.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (CRIX)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>40 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>13 </td>
                            <td>32 </td>
                            <td>492 </td>
                            <td>44 </td>
                            <td>23.45 </td>
                            <td>43.27 </td>
                            <td>1.69 </td>
                            <td>1072 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>85 </td>
                            <td>4 </td>
                            <td>23.76 </td>
                            <td>48 </td>
                            <td>1.98 </td>
                            <td>178 </td>
                        </tr>
                        <tr>
                            <td>NI </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>37 </td>
                            <td>2 </td>
                            <td>23.89 </td>
                            <td>40 </td>
                            <td>1 </td>
                            <td>78 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>VE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>32 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>17 </td>
                            <td>26 </td>
                            <td>101 </td>
                            <td>23.12 </td>
                            <td>46.94 </td>
                            <td>2.76 </td>
                            <td>254 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td><strong>Punto de    Intercambio Neutro de Internet (SJO)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>76</td>
                            <td><strong>Prefijos    (IPv4): </strong>3743</td>
                            <td><strong>Prefijos    (IPv6): </strong>162</td>
                        </tr>
                    </table>

                    <p>* Este IXP cuenta con colector tanto de LACNIC como PCH.</p>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure18.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (SJO)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>48</td>
                            <td>4</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>40</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>25</td>
                            <td>46</td>
                            <td>3556</td>
                            <td>51</td>
                            <td>23.38</td>
                            <td>46.12</td>
                            <td>1.18</td>
                            <td>3607</td>
                        </tr>
                        <tr>
                            <td>GT</td>
                            <td>2</td>
                            <td>3</td>
                            <td>88</td>
                            <td>4</td>
                            <td>23.78</td>
                            <td>48</td>
                            <td>1.95</td>
                            <td>92</td>
                        </tr>
                        <tr>
                            <td>NI</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>23.67</td>
                            <td>&nbsp;</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>1</td>
                            <td>37</td>
                            <td>2</td>
                            <td>23.89</td>
                            <td>40</td>
                            <td>1.05</td>
                            <td>39</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>VE</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            <td>23.33</td>
                            <td>32</td>
                            <td>1</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>4</td>
                            <td>18</td>
                            <td>51</td>
                            <td>100</td>
                            <td>23.53</td>
                            <td>46.93</td>
                            <td>1.95</td>
                            <td>151</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1.25</td>
                            <td>4</td>
                        </tr>
                    </table>

                    <h3>Ecuador</h3>

                    <table className="table">
                        <tr>
                            <td><strong>APROSVA (APROSVA)</strong><br />
                                https://aprosva.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>4</td>
                            <td><strong>Prefijos    (IPv4): </strong>7</td>
                            <td><strong>Prefijos    (IPv6): </strong>2</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure19.png" alt="Ver" /></td>
                            <td><em>(Este IXP no presenta prependings en sus tablas)</em></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure20.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (GYE)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>3 </td>
                            <td>3 </td>
                            <td>6 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>32 </td>
                            <td>1 </td>
                            <td>14 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>1 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td><strong>IXP Ecuador (GYE)</strong><br />
                                https://www.ixp.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>24</td>
                            <td><strong>Prefijos    (IPv4): </strong>106</td>
                            <td><strong>Prefijos    (IPv6): </strong>41</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure21.png" alt="Ver" /></td>
                            <td><em>(Este IXP no presenta prependings en sus tablas)</em></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure22.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (GYE)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>1 </td>
                            <td>20 </td>
                            <td>59 </td>
                            <td>0 </td>
                            <td>23.85 </td>
                            <td>&nbsp;</td>
                            <td>1.76 </td>
                            <td>59 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>47 </td>
                            <td>41 </td>
                            <td>23.96 </td>
                            <td>48 </td>
                            <td>1.1 </td>
                            <td>88 </td>
                        </tr>
                    </table>


                    <h3>Guatemala</h3>

                    <table className="table">
                        <tr>
                            <td><strong>IXP Guatemala (IXPGT)</strong><br />
                                http://www.ixp.gt </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: GT</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>2672</td>
                            <td><strong>Prefijos    (IPv4): </strong>18341</td>
                            <td><strong>Prefijos    (IPv6): </strong>31</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure23_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure23_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure24.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (IXPGT)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>0 </td>
                            <td>29 </td>
                            <td>184 </td>
                            <td>0 </td>
                            <td>23.11 </td>
                            <td>&nbsp;</td>
                            <td>5.35 </td>
                            <td>184 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>92 </td>
                            <td>783 </td>
                            <td>0 </td>
                            <td>22.28 </td>
                            <td>&nbsp;</td>
                            <td>5.46 </td>
                            <td>783 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>22 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>1 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>8 </td>
                            <td>143 </td>
                            <td>0 </td>
                            <td>23.48 </td>
                            <td>&nbsp;</td>
                            <td>4.61 </td>
                            <td>143 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>30 </td>
                            <td>0 </td>
                            <td>24.93 </td>
                            <td>&nbsp;</td>
                            <td>4.8 </td>
                            <td>30 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>4 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>0 </td>
                            <td>15 </td>
                            <td>86 </td>
                            <td>0 </td>
                            <td>23.77 </td>
                            <td>&nbsp;</td>
                            <td>4.52 </td>
                            <td>86 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>15 </td>
                            <td>112 </td>
                            <td>4 </td>
                            <td>23.82 </td>
                            <td>36 </td>
                            <td>2.14 </td>
                            <td>116 </td>
                        </tr>
                        <tr>
                            <td>HN </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>13 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>13 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>180 </td>
                            <td>0 </td>
                            <td>23.72 </td>
                            <td>&nbsp;</td>
                            <td>9.75 </td>
                            <td>180 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>22.33 </td>
                            <td>&nbsp;</td>
                            <td>4.33 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>6 </td>
                            <td>0 </td>
                            <td>23.33 </td>
                            <td>&nbsp;</td>
                            <td>2.83 </td>
                            <td>6 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>47 </td>
                            <td>884 </td>
                            <td>0 </td>
                            <td>22.88 </td>
                            <td>&nbsp;</td>
                            <td>5.61 </td>
                            <td>884 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>583 </td>
                            <td>4781 </td>
                            <td>0 </td>
                            <td>22.4 </td>
                            <td>&nbsp;</td>
                            <td>6.53 </td>
                            <td>4781 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>0 </td>
                            <td>1179 </td>
                            <td>7706 </td>
                            <td>27 </td>
                            <td>21.96 </td>
                            <td>44.37 </td>
                            <td>6.09 </td>
                            <td>7733 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>11 </td>
                            <td>34 </td>
                            <td>0 </td>
                            <td>21.29 </td>
                            <td>&nbsp;</td>
                            <td>6.32 </td>
                            <td>34 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>668 </td>
                            <td>3392 </td>
                            <td>0 </td>
                            <td>21.34 </td>
                            <td>&nbsp;</td>
                            <td>5.9 </td>
                            <td>3392 </td>
                        </tr>
                    </table>

                    <p>* Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>

                    <h3>M&eacute;xico</h3>

                    <table className="table">
                        <tr>
                            <td><strong>IXP Yucat&aacute;n    (IXSY)</strong><br />
                                https://ixsy.org.mx- </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>10</td>
                            <td><strong>Prefijos    (IPv4): </strong>84</td>
                            <td><strong>Prefijos    (IPv6): </strong>33</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td > Cobertura (%) sobre los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure26.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumen de las tablas de ruteo (IXSY)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>8 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>80 </td>
                            <td>33 </td>
                            <td>23.98 </td>
                            <td>45.76 </td>
                            <td>1.94 </td>
                            <td>226 </td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td><strong>IXP M&eacute;xico (MEX)</strong><br />
                                https://ixp.mx </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>295</td>
                            <td><strong>Prefijos    (IPv4): </strong>2090</td>
                            <td><strong>Prefijos    (IPv6): </strong>147</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >Cobertura (%) sobre los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumen de las tablas de ruteo (MEX)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Regi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>4 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>23.8 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>5 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>11 </td>
                            <td>0 </td>
                            <td>23.64 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>11 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>4 </td>
                            <td>10 </td>
                            <td>302 </td>
                            <td>0 </td>
                            <td>22.72 </td>
                            <td>&nbsp;</td>
                            <td>1.25 </td>
                            <td>322 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>55 </td>
                            <td>191 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>191 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>135 </td>
                            <td>1195 </td>
                            <td>134 </td>
                            <td>23.07 </td>
                            <td>46.88 </td>
                            <td>1.54 </td>
                            <td>1464 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>23 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>1 </td>
                            <td>84 </td>
                            <td>366 </td>
                            <td>13 </td>
                            <td>23.37 </td>
                            <td>48 </td>
                            <td>1.96 </td>
                            <td>387 </td>
                        </tr>
                    </table>

                    <p>* Referencia a recursos que no pudieron ser identificados con el registro delegated.</p>

                    <h3>Paraguay</h3>

                    <table className="table">
                        <tr>
                            <td><strong>IXP Paraguay    (IXPY)</strong><br />
                                https://www.ix.py </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fuente</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS    observados: </strong>169</td>
                            <td><strong>Prefijos    (IPv4): </strong>2378</td>
                            <td><strong>Prefijos    (IPv6): </strong>539</td>
                        </tr>
                    </table>

                    <table className="table">
                        <tr>
                            <td>&aacute;rea de influencia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td >Cobertura (%) sobre    los anuncios del pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td ><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumen de las tablas de ruteo (IXPY)</p>

                    <table className="table">
                        <tr>
                            <td>Pa&iacute;s o Re <br />
                                gi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes Origen</td>
                            <td>Prefijos de origen</td>
                            <td>Long. prom. prefijo</td>
                            <td>Long. prom. path </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>1 </td>
                            <td>115 </td>
                            <td>1624 </td>
                            <td>480 </td>
                            <td>23.14 </td>
                            <td>46.69 </td>
                            <td>2.85 </td>
                            <td>4208 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>3 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>23.6 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>20 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>7 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>1 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>PE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>5 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PY </td>
                            <td>16 </td>
                            <td>24 </td>
                            <td>628 </td>
                            <td>38 </td>
                            <td>22.45 </td>
                            <td>40.58 </td>
                            <td>1.3 </td>
                            <td>1332 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>49 </td>
                            <td>5 </td>
                            <td>23.39 </td>
                            <td>44.4 </td>
                            <td>3.94 </td>
                            <td>108 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>51 </td>
                            <td>9 </td>
                            <td>23.92 </td>
                            <td>47.78 </td>
                            <td>1.68 </td>
                            <td>120 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>5 </td>
                            <td>8 </td>
                            <td>7 </td>
                            <td>23.88 </td>
                            <td>44.29 </td>
                            <td>3 </td>
                            <td>30 </td>
                        </tr>
                    </table>
                </>}
                {myLang === 'en' && <>
                    <h2>Data by IXP</h2>
                    <h3>Argentina</h3>


                    <table class="table">
                        <tr>
                            <td><strong>CABASE Buenos Aires (AEP)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed</strong>1976</td>
                            <td><strong>Prefixes (IPv4)</strong>17741</td>
                            <td><strong>Prefixes (IPv6)</strong>3071</td>
                        </tr>
                    </table>


                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure6.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (AEP)</p>
                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>822</td>
                            <td>10473</td>
                            <td>463</td>
                            <td>23.22</td>
                            <td>41.73</td>
                            <td>4.48</td>
                            <td>21409</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.32</td>
                            <td>34</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>692</td>
                            <td>2007</td>
                            <td>828</td>
                            <td>22.96</td>
                            <td>36.47</td>
                            <td>7.3</td>
                            <td>4842</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>1</td>
                            <td>22.23</td>
                            <td>48</td>
                            <td>6.12</td>
                            <td>529</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>16</td>
                            <td>23.5</td>
                            <td>45.25</td>
                            <td>3.42</td>
                            <td>72</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>1</td>
                            <td>23.64</td>
                            <td>29</td>
                            <td>4.52</td>
                            <td>23</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>29</td>
                            <td>5</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>28</td>
                            <td>4.86</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.9</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>4.62</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.12</td>
                            <td>398</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>3.73</td>
                            <td>1246</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>60</td>
                            <td>205</td>
                            <td>11</td>
                            <td>23.59</td>
                            <td>40.27</td>
                            <td>4.45</td>
                            <td>421</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>1</td>
                            <td>194</td>
                            <td>3347</td>
                            <td>1652</td>
                            <td>22.68</td>
                            <td>43.84</td>
                            <td>3.53</td>
                            <td>8386</td>
                        </tr>
                        <tr>
                            <td><strong>other**</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>122</td>
                            <td>533</td>
                            <td>91</td>
                            <td>23.08</td>
                            <td>40.93</td>
                            <td>4</td>
                            <td>1157</td>
                        </tr>
                    </table>

                    <p>* CABASE IXPs connect to a central interconnection point (AS52376).</p>
                    <p>** Reference to resources that could not be identified based on the delegated file.</p>


                    <table class="table">
                        <tr>
                            <td><strong>CABASE Rosario (ROS)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Country: AR </strong></td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>1745</td>
                            <td><strong>Prefixes (IPv4)</strong>17765</td>
                            <td><strong>Prefixes (IPv6)</strong>33*</td>
                        </tr>
                    </table>
                    <p>* PCH has not enabled IPv6 peering with this IXP. This does not mean that the protocol is not working.</p>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure8.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (ROS)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>1**</td>
                            <td>822</td>
                            <td>10476</td>
                            <td>0</td>
                            <td>23.22</td>
                            <td>&nbsp;</td>
                            <td>4.98</td>
                            <td>10476</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>487</td>
                            <td>2007</td>
                            <td>0</td>
                            <td>22.96</td>
                            <td>&nbsp;</td>
                            <td>7.95</td>
                            <td>2007</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>0</td>
                            <td>22.23</td>
                            <td>&nbsp;</td>
                            <td>6.63</td>
                            <td>264</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>0</td>
                            <td>23.5</td>
                            <td>&nbsp;</td>
                            <td>4.04</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6.4</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>3</td>
                            <td>6</td>
                            <td>0</td>
                            <td>22.67</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.62</td>
                            <td>199</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>0</td>
                            <td>20.12</td>
                            <td>&nbsp;</td>
                            <td>4.23</td>
                            <td>622</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>59</td>
                            <td>205</td>
                            <td>0</td>
                            <td>23.59</td>
                            <td>&nbsp;</td>
                            <td>4.99</td>
                            <td>205</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>192</td>
                            <td>3368</td>
                            <td>33</td>
                            <td>22.69</td>
                            <td>48</td>
                            <td>4.12</td>
                            <td>3440</td>
                        </tr>
                        <tr>
                            <td>other***</td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>103</td>
                            <td>533</td>
                            <td>0</td>
                            <td>23.08</td>
                            <td>&nbsp;</td>
                            <td>4.53</td>
                            <td>533</td>
                        </tr>
                    </table>

                    <p>** CABASE IXPs connect to a central interconnection point (AS52376).</p>
                    <p>*** Reference to resources that could not be identified based on the delegated file.</p>



                    <table class="table">
                        <tr>
                            <td><strong>CABASE Bah&iacute;a Blanca (IXPBB)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed</strong>4568</td>
                            <td><strong>Prefixes (IPv4)</strong>35884</td>
                            <td><strong>Prefixes (IPv6)</strong>3082</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure10.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Routing Table Summary (IXPBB)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>823</td>
                            <td>10513</td>
                            <td>474</td>
                            <td>23.24</td>
                            <td>42.24</td>
                            <td>5.94</td>
                            <td>10987</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>9.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>776</td>
                            <td>2769</td>
                            <td>826</td>
                            <td>22.77</td>
                            <td>36.45</td>
                            <td>8.1</td>
                            <td>3595</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>5</td>
                            <td>0</td>
                            <td>23.6</td>
                            <td>&nbsp;</td>
                            <td>6.2</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>45</td>
                            <td>406</td>
                            <td>1</td>
                            <td>22.67</td>
                            <td>48</td>
                            <td>6.57</td>
                            <td>407</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>58</td>
                            <td>16</td>
                            <td>24.24</td>
                            <td>45.25</td>
                            <td>4.93</td>
                            <td>74</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>14</td>
                            <td>1</td>
                            <td>23.71</td>
                            <td>29</td>
                            <td>5.67</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>86</td>
                            <td>1</td>
                            <td>23.77</td>
                            <td>29</td>
                            <td>4.55</td>
                            <td>87</td>
                        </tr>
                        <tr>
                            <td><strong>GT</strong></td>
                            <td>0</td>
                            <td>15</td>
                            <td>111</td>
                            <td>0</td>
                            <td>23.82</td>
                            <td>&nbsp;</td>
                            <td>6.09</td>
                            <td>111</td>
                        </tr>
                        <tr>
                            <td><strong>HN</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.69</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>183</td>
                            <td>1</td>
                            <td>23.73</td>
                            <td>28</td>
                            <td>9.68</td>
                            <td>184</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>5</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.62</td>
                            <td>&nbsp;</td>
                            <td>7</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>6.2</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>18</td>
                            <td>204</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>7.63</td>
                            <td>204</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>624</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>5.22</td>
                            <td>626</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>49</td>
                            <td>894</td>
                            <td>0</td>
                            <td>22.9</td>
                            <td>&nbsp;</td>
                            <td>5.61</td>
                            <td>894</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>630</td>
                            <td>4956</td>
                            <td>11</td>
                            <td>22.44</td>
                            <td>40.27</td>
                            <td>6.49</td>
                            <td>4967</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>0</td>
                            <td>1358</td>
                            <td>10966</td>
                            <td>1653</td>
                            <td>22.18</td>
                            <td>43.84</td>
                            <td>5.72</td>
                            <td>12619</td>
                        </tr>
                        <tr>
                            <td><strong>Other**</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>148</td>
                            <td>1</td>
                            <td>22.12</td>
                            <td>56</td>
                            <td>6.74</td>
                            <td>149</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>770</td>
                            <td>3898</td>
                            <td>91</td>
                            <td>21.57</td>
                            <td>40.93</td>
                            <td>5.84</td>
                            <td>3989</td>
                        </tr>
                    </table>


                    <p>* CABASE IXPs connect to a central interconnection point (AS52376).</p>
                    <p>** Reference to resources that could not be identified based on the delegated file.</p>

                    <h3>Chile</h3>
                    <table class="table">
                        <tr>
                            <td><strong>PIT Chile (ARI)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>1819</td>
                            <td><strong>Prefixes (IPv4)</strong>15559</td>
                            <td><strong>Prefixes (IPv6)</strong>41*</td>
                        </tr>
                    </table>

                    <p>* PCH has not enabled IPv6 peering with this IXP. This does not mean that the protocol is not working.</p>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure12.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (ARI)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>0</td>
                            <td>365</td>
                            <td>3139</td>
                            <td>0</td>
                            <td>23.41</td>
                            <td>&nbsp;</td>
                            <td>5.8</td>
                            <td>3139</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>235</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>699</td>
                            <td>2517</td>
                            <td>0</td>
                            <td>23.05</td>
                            <td>&nbsp;</td>
                            <td>7.23</td>
                            <td>2517</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>1</td>
                            <td>281</td>
                            <td>4837</td>
                            <td>0</td>
                            <td>22.95</td>
                            <td>&nbsp;</td>
                            <td>3.9</td>
                            <td>4837</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>3</td>
                            <td>25</td>
                            <td>0</td>
                            <td>23.52</td>
                            <td>&nbsp;</td>
                            <td>3.24</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.12</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>22</td>
                            <td>301</td>
                            <td>0</td>
                            <td>23.09</td>
                            <td>&nbsp;</td>
                            <td>3.73</td>
                            <td>301</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>24</td>
                            <td>307</td>
                            <td>0</td>
                            <td>22.03</td>
                            <td>&nbsp;</td>
                            <td>4.4</td>
                            <td>307</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>10</td>
                            <td>611</td>
                            <td>0</td>
                            <td>20.07</td>
                            <td>&nbsp;</td>
                            <td>3.09</td>
                            <td>611</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>208</td>
                            <td>2801</td>
                            <td>41</td>
                            <td>22.61</td>
                            <td>48</td>
                            <td>4.51</td>
                            <td>2889</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>112</td>
                            <td>519</td>
                            <td>0</td>
                            <td>23.51</td>
                            <td>&nbsp;</td>
                            <td>5.31</td>
                            <td>519</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>61</td>
                            <td>211</td>
                            <td>0</td>
                            <td>23.56</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>211</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>other**</td>
                            <td>0</td>
                            <td>3</td>
                            <td>14</td>
                            <td>0</td>
                            <td>21.93</td>
                            <td>&nbsp;</td>
                            <td>3.71</td>
                            <td>14</td>
                        </tr>
                    </table>

                    <p>** Reference to resources that could not be identified based on the delegated file.</p>


                    <table class="table">
                        <tr>
                            <td><strong>PIT Chile (SCL)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>1897</td>
                            <td><strong>Prefixes (IPv4)</strong>17525</td>
                            <td><strong>Prefixes (IPv6)</strong>3525</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure12_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure13_1.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure14.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Routing Table Summary (SCL)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>5</td>
                            <td>367</td>
                            <td>3148</td>
                            <td>98</td>
                            <td>23.39</td>
                            <td>38.93</td>
                            <td>4.1</td>
                            <td>9905</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>4.08</td>
                            <td>940</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>2</td>
                            <td>745</td>
                            <td>2580</td>
                            <td>364</td>
                            <td>23.05</td>
                            <td>35.8</td>
                            <td>5.47</td>
                            <td>11522</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>87</td>
                            <td>283</td>
                            <td>4923</td>
                            <td>811</td>
                            <td>22.97</td>
                            <td>46.59</td>
                            <td>2.32</td>
                            <td>18688</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>3</td>
                            <td>32</td>
                            <td>21</td>
                            <td>23.59</td>
                            <td>45.9</td>
                            <td>1.63</td>
                            <td>201</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>2</td>
                            <td>24</td>
                            <td>40</td>
                            <td>4</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.07</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>2</td>
                            <td>22</td>
                            <td>301</td>
                            <td>8</td>
                            <td>23.09</td>
                            <td>37</td>
                            <td>2.11</td>
                            <td>1028</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>1</td>
                            <td>24</td>
                            <td>307</td>
                            <td>24</td>
                            <td>22.03</td>
                            <td>42.08</td>
                            <td>2.82</td>
                            <td>971</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>2</td>
                            <td>10</td>
                            <td>611</td>
                            <td>1</td>
                            <td>20.07</td>
                            <td>48</td>
                            <td>1.42</td>
                            <td>1837</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.33</td>
                            <td>43</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>1</td>
                            <td>65</td>
                            <td>213</td>
                            <td>13</td>
                            <td>23.6</td>
                            <td>40.77</td>
                            <td>3.11</td>
                            <td>906</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>22</td>
                            <td>225</td>
                            <td>4574</td>
                            <td>2061</td>
                            <td>22.49</td>
                            <td>44.45</td>
                            <td>2.16</td>
                            <td>24450</td>
                        </tr>
                        <tr>
                            <td>other*</td>
                            <td>1</td>
                            <td>7</td>
                            <td>18</td>
                            <td>13</td>
                            <td>22.33</td>
                            <td>38.77</td>
                            <td>2.31</td>
                            <td>89</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>5</td>
                            <td>115</td>
                            <td>541</td>
                            <td>109</td>
                            <td>23.56</td>
                            <td>47.42</td>
                            <td>2.86</td>
                            <td>2253</td>
                        </tr>
                    </table>

                    <p>* Reference to resources that could not be identified based on the delegated file.</p>

                    <h3>Costa Rica</h3>

                    <table class="table">
                        <tr>
                            <td><strong>Neutral Internet Exchange Point (CRIX)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC*</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>59</td>
                            <td><strong>Prefixes (IPv4)</strong>643</td>
                            <td><strong>Prefixes (IPv6)</strong>156</td>
                        </tr>
                    </table>

                    <p>* This IXP has both LACNIC and PCH collectors.</p>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure16.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (CRIX)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>40 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>13 </td>
                            <td>32 </td>
                            <td>492 </td>
                            <td>44 </td>
                            <td>23.45 </td>
                            <td>43.27 </td>
                            <td>1.69 </td>
                            <td>1072 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>85 </td>
                            <td>4 </td>
                            <td>23.76 </td>
                            <td>48 </td>
                            <td>1.98 </td>
                            <td>178 </td>
                        </tr>
                        <tr>
                            <td>NI </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>37 </td>
                            <td>2 </td>
                            <td>23.89 </td>
                            <td>40 </td>
                            <td>1 </td>
                            <td>78 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>VE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>32 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>17 </td>
                            <td>26 </td>
                            <td>101 </td>
                            <td>23.12 </td>
                            <td>46.94 </td>
                            <td>2.76 </td>
                            <td>254 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>Neutral Internet Exchange Point (SJO)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>76</td>
                            <td><strong>Prefixes (IPv4)</strong>3743</td>
                            <td><strong>Prefixes (IPv6)</strong>162</td>
                        </tr>
                    </table>

                    <p>* This IXP has both LACNIC and PCH collectors.</p>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure18.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (SJO)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>48</td>
                            <td>4</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>40</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>25</td>
                            <td>46</td>
                            <td>3556</td>
                            <td>51</td>
                            <td>23.38</td>
                            <td>46.12</td>
                            <td>1.18</td>
                            <td>3607</td>
                        </tr>
                        <tr>
                            <td>GT</td>
                            <td>2</td>
                            <td>3</td>
                            <td>88</td>
                            <td>4</td>
                            <td>23.78</td>
                            <td>48</td>
                            <td>1.95</td>
                            <td>92</td>
                        </tr>
                        <tr>
                            <td>NI</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>23.67</td>
                            <td>&nbsp;</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>1</td>
                            <td>37</td>
                            <td>2</td>
                            <td>23.89</td>
                            <td>40</td>
                            <td>1.05</td>
                            <td>39</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>VE</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            <td>23.33</td>
                            <td>32</td>
                            <td>1</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>4</td>
                            <td>18</td>
                            <td>51</td>
                            <td>100</td>
                            <td>23.53</td>
                            <td>46.93</td>
                            <td>1.95</td>
                            <td>151</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1.25</td>
                            <td>4</td>
                        </tr>
                    </table>

                    <h3>Ecuador</h3>

                    <table class="table">
                        <tr>
                            <td><strong>APROSVA (APROSVA)</strong><br />
                                https://aprosva.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>4</td>
                            <td><strong>Prefixes (IPv4)</strong>7</td>
                            <td><strong>Prefixes (IPv6)</strong>2</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure19.png" alt="Ver" /></td>
                            <td><em>(This IXP has no prependings in its tables)</em></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure20.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (GYE)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>3 </td>
                            <td>3 </td>
                            <td>6 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>32 </td>
                            <td>1 </td>
                            <td>14 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>1 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Ecuador (GYE)</strong><br />
                                https://www.ixp.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>24</td>
                            <td><strong>Prefixes (IPv4)</strong>106</td>
                            <td><strong>Prefixes (IPv6)</strong>41</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure21.png" alt="Ver" /></td>
                            <td><em>(This IXP has no prependings in its tables)</em></td>
                        </tr>
                        <tr>
                            <td> Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure22.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (GYE)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>1 </td>
                            <td>20 </td>
                            <td>59 </td>
                            <td>0 </td>
                            <td>23.85 </td>
                            <td>&nbsp;</td>
                            <td>1.76 </td>
                            <td>59 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>47 </td>
                            <td>41 </td>
                            <td>23.96 </td>
                            <td>48 </td>
                            <td>1.1 </td>
                            <td>88 </td>
                        </tr>
                    </table>


                    <h3>Guatemala</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Guatemala (IXPGT)</strong><br />
                                http://www.ixp.gt </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: GT</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>2672</td>
                            <td><strong>Prefixes (IPv4)</strong>18341</td>
                            <td><strong>Prefixes (IPv6)</strong>31</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure23_1.png" alt="Ver" /></td>
                            <td><em><img src="/images/interconexion-bgp-local-en-lac/figure23_2.png" alt="Ver" /></em></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure24.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (IXPGT)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>0 </td>
                            <td>29 </td>
                            <td>184 </td>
                            <td>0 </td>
                            <td>23.11 </td>
                            <td>&nbsp;</td>
                            <td>5.35 </td>
                            <td>184 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>92 </td>
                            <td>783 </td>
                            <td>0 </td>
                            <td>22.28 </td>
                            <td>&nbsp;</td>
                            <td>5.46 </td>
                            <td>783 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>22 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>1 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>8 </td>
                            <td>143 </td>
                            <td>0 </td>
                            <td>23.48 </td>
                            <td>&nbsp;</td>
                            <td>4.61 </td>
                            <td>143 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>30 </td>
                            <td>0 </td>
                            <td>24.93 </td>
                            <td>&nbsp;</td>
                            <td>4.8 </td>
                            <td>30 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>4 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>0 </td>
                            <td>15 </td>
                            <td>86 </td>
                            <td>0 </td>
                            <td>23.77 </td>
                            <td>&nbsp;</td>
                            <td>4.52 </td>
                            <td>86 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>15 </td>
                            <td>112 </td>
                            <td>4 </td>
                            <td>23.82 </td>
                            <td>36 </td>
                            <td>2.14 </td>
                            <td>116 </td>
                        </tr>
                        <tr>
                            <td>HN </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>13 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>13 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>180 </td>
                            <td>0 </td>
                            <td>23.72 </td>
                            <td>&nbsp;</td>
                            <td>9.75 </td>
                            <td>180 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>22.33 </td>
                            <td>&nbsp;</td>
                            <td>4.33 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>6 </td>
                            <td>0 </td>
                            <td>23.33 </td>
                            <td>&nbsp;</td>
                            <td>2.83 </td>
                            <td>6 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>47 </td>
                            <td>884 </td>
                            <td>0 </td>
                            <td>22.88 </td>
                            <td>&nbsp;</td>
                            <td>5.61 </td>
                            <td>884 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>583 </td>
                            <td>4781 </td>
                            <td>0 </td>
                            <td>22.4 </td>
                            <td>&nbsp;</td>
                            <td>6.53 </td>
                            <td>4781 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>0 </td>
                            <td>1179 </td>
                            <td>7706 </td>
                            <td>27 </td>
                            <td>21.96 </td>
                            <td>44.37 </td>
                            <td>6.09 </td>
                            <td>7733 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>11 </td>
                            <td>34 </td>
                            <td>0 </td>
                            <td>21.29 </td>
                            <td>&nbsp;</td>
                            <td>6.32 </td>
                            <td>34 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>668 </td>
                            <td>3392 </td>
                            <td>0 </td>
                            <td>21.34 </td>
                            <td>&nbsp;</td>
                            <td>5.9 </td>
                            <td>3392 </td>
                        </tr>
                    </table>

                    <p>* Reference to resources that could not be identified based on the delegated file.</p>

                    <h3>M&eacute;xico</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Yucat&aacute;n    (IXSY)</strong><br />
                                https://ixsy.org.mx- </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>10</td>
                            <td><strong>Prefixes (IPv4)</strong>84</td>
                            <td><strong>Prefixes (IPv6)</strong>33</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure26.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Routing Table Summary (IXSY)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>8 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>80 </td>
                            <td>33 </td>
                            <td>23.98 </td>
                            <td>45.76 </td>
                            <td>1.94 </td>
                            <td>226 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>IXP M&eacute;xico (MEX)</strong><br />
                                https://ixp.mx </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>295</td>
                            <td><strong>Prefixes (IPv4)</strong>2090</td>
                            <td><strong>Prefixes (IPv6)</strong>147</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Routing Table Summary (MEX)</p>

                    <table class="table">
                        <tr>
                            <td>Country or region</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>4 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>23.8 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>5 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>11 </td>
                            <td>0 </td>
                            <td>23.64 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>11 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>4 </td>
                            <td>10 </td>
                            <td>302 </td>
                            <td>0 </td>
                            <td>22.72 </td>
                            <td>&nbsp;</td>
                            <td>1.25 </td>
                            <td>322 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>55 </td>
                            <td>191 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>191 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>135 </td>
                            <td>1195 </td>
                            <td>134 </td>
                            <td>23.07 </td>
                            <td>46.88 </td>
                            <td>1.54 </td>
                            <td>1464 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>23 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>1 </td>
                            <td>84 </td>
                            <td>366 </td>
                            <td>13 </td>
                            <td>23.37 </td>
                            <td>48 </td>
                            <td>1.96 </td>
                            <td>387 </td>
                        </tr>
                    </table>

                    <p>* Reference to resources that could not be identified using the delegated file.</p>

                    <h3>Paraguay</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Paraguay (IXPY)</strong><br />
                                https://www.ix.py </td>
                        </tr>
                        <tr>
                            <td><strong>Country</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Source</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>Number of ASs observed:</strong>169</td>
                            <td><strong>Prefixes (IPv4)</strong>2378</td>
                            <td><strong>Prefixes (IPv6)</strong>539</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>Area of influence</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>Proportion of the country's announcements covered by the IXP (%)</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Routing Table Summary (IXPY)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s o Re <br />
                                gi&oacute;n</td>
                            <td>Number of peer ASs</td>
                            <td>Number of origin ASs</td>
                            <td>Number of origin prefixes</td>
                            <td>Average prefix length</td>
                            <td>Average path length </td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>1 </td>
                            <td>115 </td>
                            <td>1624 </td>
                            <td>480 </td>
                            <td>23.14 </td>
                            <td>46.69 </td>
                            <td>2.85 </td>
                            <td>4208 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>3 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>23.6 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>20 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>7 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>1 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>PE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>5 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PY </td>
                            <td>16 </td>
                            <td>24 </td>
                            <td>628 </td>
                            <td>38 </td>
                            <td>22.45 </td>
                            <td>40.58 </td>
                            <td>1.3 </td>
                            <td>1332 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>49 </td>
                            <td>5 </td>
                            <td>23.39 </td>
                            <td>44.4 </td>
                            <td>3.94 </td>
                            <td>108 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>51 </td>
                            <td>9 </td>
                            <td>23.92 </td>
                            <td>47.78 </td>
                            <td>1.68 </td>
                            <td>120 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>5 </td>
                            <td>8 </td>
                            <td>7 </td>
                            <td>23.88 </td>
                            <td>44.29 </td>
                            <td>3 </td>
                            <td>30 </td>
                        </tr>
                    </table>
                </>}
                {myLang === 'pt' && <>
                    <h2>Dados por IXP</h2>
                    <h3>Argentina</h3>


                    <table class="table">
                        <tr>
                            <td><strong>CABASE Buenos Aires (AEP)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>1976</td>
                            <td><strong>Prefixos (IPv4)</strong>17741</td>
                            <td><strong>Prefixos (IPv6)</strong>3071</td>
                        </tr>
                    </table>


                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure5_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure6.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (AEP)</p>
                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>822</td>
                            <td>10473</td>
                            <td>463</td>
                            <td>23.22</td>
                            <td>41.73</td>
                            <td>4.48</td>
                            <td>21409</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.32</td>
                            <td>34</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>692</td>
                            <td>2007</td>
                            <td>828</td>
                            <td>22.96</td>
                            <td>36.47</td>
                            <td>7.3</td>
                            <td>4842</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>1</td>
                            <td>22.23</td>
                            <td>48</td>
                            <td>6.12</td>
                            <td>529</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>16</td>
                            <td>23.5</td>
                            <td>45.25</td>
                            <td>3.42</td>
                            <td>72</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>1</td>
                            <td>23.64</td>
                            <td>29</td>
                            <td>4.52</td>
                            <td>23</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>29</td>
                            <td>5</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>28</td>
                            <td>4.86</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.9</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>4.62</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.12</td>
                            <td>398</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>3.73</td>
                            <td>1246</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>60</td>
                            <td>205</td>
                            <td>11</td>
                            <td>23.59</td>
                            <td>40.27</td>
                            <td>4.45</td>
                            <td>421</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>1</td>
                            <td>194</td>
                            <td>3347</td>
                            <td>1652</td>
                            <td>22.68</td>
                            <td>43.84</td>
                            <td>3.53</td>
                            <td>8386</td>
                        </tr>
                        <tr>
                            <td><strong>other**</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>4.5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>122</td>
                            <td>533</td>
                            <td>91</td>
                            <td>23.08</td>
                            <td>40.93</td>
                            <td>4</td>
                            <td>1157</td>
                        </tr>
                    </table>

                    <p>* Os IXP da CABASE se conectam a uma central de interconex&atilde;o (AS52376).</p>
                    <p>** Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated.</p>


                    <table class="table">
                        <tr>
                            <td><strong>CABASE Rosario (ROS)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s: AR</strong></td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:1745</td>
                            <td><strong>Prefixos (IPv4)</strong>17765</td>
                            <td><strong>Prefixos (IPv6)</strong>33*</td>
                        </tr>
                    </table>
                    <p>* PCH n&atilde;o tem peering IPv6 ativado com este IXP. Isso n&atilde;o significa que o protocolo n&atilde;o esteja funcionando.</p>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure7_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure8.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (ROS)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>1**</td>
                            <td>822</td>
                            <td>10476</td>
                            <td>0</td>
                            <td>23.22</td>
                            <td>&nbsp;</td>
                            <td>4.98</td>
                            <td>10476</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>8.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>487</td>
                            <td>2007</td>
                            <td>0</td>
                            <td>22.96</td>
                            <td>&nbsp;</td>
                            <td>7.95</td>
                            <td>2007</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>0</td>
                            <td>37</td>
                            <td>264</td>
                            <td>0</td>
                            <td>22.23</td>
                            <td>&nbsp;</td>
                            <td>6.63</td>
                            <td>264</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>2</td>
                            <td>28</td>
                            <td>0</td>
                            <td>23.5</td>
                            <td>&nbsp;</td>
                            <td>4.04</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6.4</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>3</td>
                            <td>6</td>
                            <td>0</td>
                            <td>22.67</td>
                            <td>&nbsp;</td>
                            <td>5.33</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>17</td>
                            <td>199</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>6.62</td>
                            <td>199</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>11</td>
                            <td>622</td>
                            <td>0</td>
                            <td>20.12</td>
                            <td>&nbsp;</td>
                            <td>4.23</td>
                            <td>622</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>2</td>
                            <td>10</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>59</td>
                            <td>205</td>
                            <td>0</td>
                            <td>23.59</td>
                            <td>&nbsp;</td>
                            <td>4.99</td>
                            <td>205</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>192</td>
                            <td>3368</td>
                            <td>33</td>
                            <td>22.69</td>
                            <td>48</td>
                            <td>4.12</td>
                            <td>3440</td>
                        </tr>
                        <tr>
                            <td>other***</td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>0</td>
                            <td>23</td>
                            <td>&nbsp;</td>
                            <td>5</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>103</td>
                            <td>533</td>
                            <td>0</td>
                            <td>23.08</td>
                            <td>&nbsp;</td>
                            <td>4.53</td>
                            <td>533</td>
                        </tr>
                    </table>

                    <p>** Os IXP da CABASE se conectam a uma central de interconex&atilde;o (AS52376).</p>
                    <p>*** Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated.</p>



                    <table class="table">
                        <tr>
                            <td><strong>CABASE Bah&iacute;a Blanca (IXPBB)</strong><br />
                                https://ixp.cabase.org.ar </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: AR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>4568</td>
                            <td><strong>Prefixos (IPv4)</strong>35884</td>
                            <td><strong>Prefixos (IPv6)</strong>3082</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure9_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure10.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumo das tabelas de roteamento  (IXPBB)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td><strong>AR</strong></td>
                            <td>1*</td>
                            <td>823</td>
                            <td>10513</td>
                            <td>474</td>
                            <td>23.24</td>
                            <td>42.24</td>
                            <td>5.94</td>
                            <td>10987</td>
                        </tr>
                        <tr>
                            <td><strong>BO</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>17</td>
                            <td>0</td>
                            <td>23.29</td>
                            <td>&nbsp;</td>
                            <td>9.82</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td><strong>BR</strong></td>
                            <td>0</td>
                            <td>776</td>
                            <td>2769</td>
                            <td>826</td>
                            <td>22.77</td>
                            <td>36.45</td>
                            <td>8.1</td>
                            <td>3595</td>
                        </tr>
                        <tr>
                            <td><strong>BZ</strong></td>
                            <td>0</td>
                            <td>2</td>
                            <td>5</td>
                            <td>0</td>
                            <td>23.6</td>
                            <td>&nbsp;</td>
                            <td>6.2</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td><strong>CL</strong></td>
                            <td>0</td>
                            <td>45</td>
                            <td>406</td>
                            <td>1</td>
                            <td>22.67</td>
                            <td>48</td>
                            <td>6.57</td>
                            <td>407</td>
                        </tr>
                        <tr>
                            <td><strong>CO</strong></td>
                            <td>0</td>
                            <td>11</td>
                            <td>58</td>
                            <td>16</td>
                            <td>24.24</td>
                            <td>45.25</td>
                            <td>4.93</td>
                            <td>74</td>
                        </tr>
                        <tr>
                            <td><strong>CR</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>14</td>
                            <td>1</td>
                            <td>23.71</td>
                            <td>29</td>
                            <td>5.67</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td><strong>EC</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>86</td>
                            <td>1</td>
                            <td>23.77</td>
                            <td>29</td>
                            <td>4.55</td>
                            <td>87</td>
                        </tr>
                        <tr>
                            <td><strong>GT</strong></td>
                            <td>0</td>
                            <td>15</td>
                            <td>111</td>
                            <td>0</td>
                            <td>23.82</td>
                            <td>&nbsp;</td>
                            <td>6.09</td>
                            <td>111</td>
                        </tr>
                        <tr>
                            <td><strong>HN</strong></td>
                            <td>0</td>
                            <td>1</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.69</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>MX</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>183</td>
                            <td>1</td>
                            <td>23.73</td>
                            <td>28</td>
                            <td>9.68</td>
                            <td>184</td>
                        </tr>
                        <tr>
                            <td><strong>PA</strong></td>
                            <td>0</td>
                            <td>5</td>
                            <td>13</td>
                            <td>0</td>
                            <td>23.62</td>
                            <td>&nbsp;</td>
                            <td>7</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td><strong>PE</strong></td>
                            <td>0</td>
                            <td>4</td>
                            <td>6</td>
                            <td>4</td>
                            <td>22.67</td>
                            <td>32</td>
                            <td>6.2</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td><strong>PY</strong></td>
                            <td>0</td>
                            <td>18</td>
                            <td>204</td>
                            <td>0</td>
                            <td>23.54</td>
                            <td>&nbsp;</td>
                            <td>7.63</td>
                            <td>204</td>
                        </tr>
                        <tr>
                            <td><strong>UY</strong></td>
                            <td>0</td>
                            <td>12</td>
                            <td>624</td>
                            <td>2</td>
                            <td>20.12</td>
                            <td>48</td>
                            <td>5.22</td>
                            <td>626</td>
                        </tr>
                        <tr>
                            <td><strong>afrinic</strong></td>
                            <td>0</td>
                            <td>49</td>
                            <td>894</td>
                            <td>0</td>
                            <td>22.9</td>
                            <td>&nbsp;</td>
                            <td>5.61</td>
                            <td>894</td>
                        </tr>
                        <tr>
                            <td><strong>apnic</strong></td>
                            <td>0</td>
                            <td>630</td>
                            <td>4956</td>
                            <td>11</td>
                            <td>22.44</td>
                            <td>40.27</td>
                            <td>6.49</td>
                            <td>4967</td>
                        </tr>
                        <tr>
                            <td><strong>arin</strong></td>
                            <td>0</td>
                            <td>1358</td>
                            <td>10966</td>
                            <td>1653</td>
                            <td>22.18</td>
                            <td>43.84</td>
                            <td>5.72</td>
                            <td>12619</td>
                        </tr>
                        <tr>
                            <td><strong>Other**</strong></td>
                            <td>0</td>
                            <td>16</td>
                            <td>148</td>
                            <td>1</td>
                            <td>22.12</td>
                            <td>56</td>
                            <td>6.74</td>
                            <td>149</td>
                        </tr>
                        <tr>
                            <td><strong>ripencc</strong></td>
                            <td>0</td>
                            <td>770</td>
                            <td>3898</td>
                            <td>91</td>
                            <td>21.57</td>
                            <td>40.93</td>
                            <td>5.84</td>
                            <td>3989</td>
                        </tr>
                    </table>


                    <p>* Os IXP da CABASE se conectam a uma central de interconex&atilde;o (AS52376).</p>
                    <p>** Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated.</p>

                    <h3>Chile</h3>
                    <table class="table">
                        <tr>
                            <td><strong>PIT Chile (ARI)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:1819</td>
                            <td><strong>Prefixos (IPv4)</strong>15559</td>
                            <td><strong>Prefixos (IPv6)</strong>41*</td>
                        </tr>
                    </table>

                    <p>* PCH n&atilde;o tem peering IPv6 ativado com este IXP. Isso n&atilde;o significa que o protocolo n&atilde;o esteja funcionando.</p>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure11_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure12.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (ARI)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>0</td>
                            <td>365</td>
                            <td>3139</td>
                            <td>0</td>
                            <td>23.41</td>
                            <td>&nbsp;</td>
                            <td>5.8</td>
                            <td>3139</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>235</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>699</td>
                            <td>2517</td>
                            <td>0</td>
                            <td>23.05</td>
                            <td>&nbsp;</td>
                            <td>7.23</td>
                            <td>2517</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>1</td>
                            <td>281</td>
                            <td>4837</td>
                            <td>0</td>
                            <td>22.95</td>
                            <td>&nbsp;</td>
                            <td>3.9</td>
                            <td>4837</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>0</td>
                            <td>3</td>
                            <td>25</td>
                            <td>0</td>
                            <td>23.52</td>
                            <td>&nbsp;</td>
                            <td>3.24</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>0</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>5.12</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>0</td>
                            <td>22</td>
                            <td>301</td>
                            <td>0</td>
                            <td>23.09</td>
                            <td>&nbsp;</td>
                            <td>3.73</td>
                            <td>301</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>0</td>
                            <td>24</td>
                            <td>307</td>
                            <td>0</td>
                            <td>22.03</td>
                            <td>&nbsp;</td>
                            <td>4.4</td>
                            <td>307</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>0</td>
                            <td>10</td>
                            <td>611</td>
                            <td>0</td>
                            <td>20.07</td>
                            <td>&nbsp;</td>
                            <td>3.09</td>
                            <td>611</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>1</td>
                            <td>208</td>
                            <td>2801</td>
                            <td>41</td>
                            <td>22.61</td>
                            <td>48</td>
                            <td>4.51</td>
                            <td>2889</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>0</td>
                            <td>112</td>
                            <td>519</td>
                            <td>0</td>
                            <td>23.51</td>
                            <td>&nbsp;</td>
                            <td>5.31</td>
                            <td>519</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>0</td>
                            <td>61</td>
                            <td>211</td>
                            <td>0</td>
                            <td>23.56</td>
                            <td>&nbsp;</td>
                            <td>5.83</td>
                            <td>211</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>6</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>other**</td>
                            <td>0</td>
                            <td>3</td>
                            <td>14</td>
                            <td>0</td>
                            <td>21.93</td>
                            <td>&nbsp;</td>
                            <td>3.71</td>
                            <td>14</td>
                        </tr>
                    </table>

                    <p>** Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated.</p>


                    <table class="table">
                        <tr>
                            <td><strong>PIT Chile (SCL)</strong><br />
                                https://www.pitchile.cl </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CL</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:1897</td>
                            <td><strong>Prefixos (IPv4)</strong>17525</td>
                            <td><strong>Prefixos (IPv6)</strong>3525</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure12_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure13_1.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure14.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumo das tabelas de roteamento (SCL)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR</td>
                            <td>5</td>
                            <td>367</td>
                            <td>3148</td>
                            <td>98</td>
                            <td>23.39</td>
                            <td>38.93</td>
                            <td>4.1</td>
                            <td>9905</td>
                        </tr>
                        <tr>
                            <td>BO</td>
                            <td>0</td>
                            <td>17</td>
                            <td>235</td>
                            <td>0</td>
                            <td>23.19</td>
                            <td>&nbsp;</td>
                            <td>4.08</td>
                            <td>940</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>2</td>
                            <td>745</td>
                            <td>2580</td>
                            <td>364</td>
                            <td>23.05</td>
                            <td>35.8</td>
                            <td>5.47</td>
                            <td>11522</td>
                        </tr>
                        <tr>
                            <td>BZ</td>
                            <td>0</td>
                            <td>1</td>
                            <td>4</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td>CL</td>
                            <td>87</td>
                            <td>283</td>
                            <td>4923</td>
                            <td>811</td>
                            <td>22.97</td>
                            <td>46.59</td>
                            <td>2.32</td>
                            <td>18688</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>3</td>
                            <td>32</td>
                            <td>21</td>
                            <td>23.59</td>
                            <td>45.9</td>
                            <td>1.63</td>
                            <td>201</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>0</td>
                            <td>2</td>
                            <td>11</td>
                            <td>0</td>
                            <td>23.64</td>
                            <td>&nbsp;</td>
                            <td>3.25</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>MX</td>
                            <td>0</td>
                            <td>5</td>
                            <td>8</td>
                            <td>2</td>
                            <td>24</td>
                            <td>40</td>
                            <td>4</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>3</td>
                            <td>8</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.07</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>PE</td>
                            <td>2</td>
                            <td>22</td>
                            <td>301</td>
                            <td>8</td>
                            <td>23.09</td>
                            <td>37</td>
                            <td>2.11</td>
                            <td>1028</td>
                        </tr>
                        <tr>
                            <td>PY</td>
                            <td>1</td>
                            <td>24</td>
                            <td>307</td>
                            <td>24</td>
                            <td>22.03</td>
                            <td>42.08</td>
                            <td>2.82</td>
                            <td>971</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>2</td>
                            <td>10</td>
                            <td>611</td>
                            <td>1</td>
                            <td>20.07</td>
                            <td>48</td>
                            <td>1.42</td>
                            <td>1837</td>
                        </tr>
                        <tr>
                            <td>afrinic</td>
                            <td>0</td>
                            <td>3</td>
                            <td>11</td>
                            <td>0</td>
                            <td>24</td>
                            <td>&nbsp;</td>
                            <td>3.33</td>
                            <td>43</td>
                        </tr>
                        <tr>
                            <td>apnic</td>
                            <td>1</td>
                            <td>65</td>
                            <td>213</td>
                            <td>13</td>
                            <td>23.6</td>
                            <td>40.77</td>
                            <td>3.11</td>
                            <td>906</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>22</td>
                            <td>225</td>
                            <td>4574</td>
                            <td>2061</td>
                            <td>22.49</td>
                            <td>44.45</td>
                            <td>2.16</td>
                            <td>24450</td>
                        </tr>
                        <tr>
                            <td>other*</td>
                            <td>1</td>
                            <td>7</td>
                            <td>18</td>
                            <td>13</td>
                            <td>22.33</td>
                            <td>38.77</td>
                            <td>2.31</td>
                            <td>89</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>5</td>
                            <td>115</td>
                            <td>541</td>
                            <td>109</td>
                            <td>23.56</td>
                            <td>47.42</td>
                            <td>2.86</td>
                            <td>2253</td>
                        </tr>
                    </table>

                    <p>* Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated. </p>

                    <h3>Costa Rica</h3>

                    <table class="table">
                        <tr>
                            <td><strong>Ponto de troca neutro da Internet (CRIX)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC*</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:59</td>
                            <td><strong>Prefixos (IPv4)</strong>643</td>
                            <td><strong>Prefixos (IPv6)</strong>156</td>
                        </tr>
                    </table>

                    <p>* Este IXP tem um coletor, tanto do LACNIC quanto do PCH.</p>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure15_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure16.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (CRIX)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>40 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>13 </td>
                            <td>32 </td>
                            <td>492 </td>
                            <td>44 </td>
                            <td>23.45 </td>
                            <td>43.27 </td>
                            <td>1.69 </td>
                            <td>1072 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>85 </td>
                            <td>4 </td>
                            <td>23.76 </td>
                            <td>48 </td>
                            <td>1.98 </td>
                            <td>178 </td>
                        </tr>
                        <tr>
                            <td>NI </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>37 </td>
                            <td>2 </td>
                            <td>23.89 </td>
                            <td>40 </td>
                            <td>1 </td>
                            <td>78 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>VE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>&nbsp;</td>
                            <td>32 </td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>17 </td>
                            <td>26 </td>
                            <td>101 </td>
                            <td>23.12 </td>
                            <td>46.94 </td>
                            <td>2.76 </td>
                            <td>254 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>Ponto de troca neutro da Internet (SJO)</strong><br />
                                https://www.crix.cr </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: CR</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:76</td>
                            <td><strong>Prefixos (IPv4)</strong>3743</td>
                            <td><strong>Prefixos (IPv6)</strong>162</td>
                        </tr>
                    </table>

                    <p>* Este IXP tem um coletor, tanto do LACNIC quanto do PCH.</p>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure17_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure18.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (SJO)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR</td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>&nbsp;</td>
                            <td>48</td>
                            <td>4</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>CO</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>40</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>CR</td>
                            <td>25</td>
                            <td>46</td>
                            <td>3556</td>
                            <td>51</td>
                            <td>23.38</td>
                            <td>46.12</td>
                            <td>1.18</td>
                            <td>3607</td>
                        </tr>
                        <tr>
                            <td>GT</td>
                            <td>2</td>
                            <td>3</td>
                            <td>88</td>
                            <td>4</td>
                            <td>23.78</td>
                            <td>48</td>
                            <td>1.95</td>
                            <td>92</td>
                        </tr>
                        <tr>
                            <td>NI</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>0</td>
                            <td>23.67</td>
                            <td>&nbsp;</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>PA</td>
                            <td>1</td>
                            <td>1</td>
                            <td>37</td>
                            <td>2</td>
                            <td>23.89</td>
                            <td>40</td>
                            <td>1.05</td>
                            <td>39</td>
                        </tr>
                        <tr>
                            <td>UY</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>VE</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            <td>23.33</td>
                            <td>32</td>
                            <td>1</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>arin</td>
                            <td>4</td>
                            <td>18</td>
                            <td>51</td>
                            <td>100</td>
                            <td>23.53</td>
                            <td>46.93</td>
                            <td>1.95</td>
                            <td>151</td>
                        </tr>
                        <tr>
                            <td>ripencc</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>1</td>
                            <td>24</td>
                            <td>48</td>
                            <td>1.25</td>
                            <td>4</td>
                        </tr>
                    </table>

                    <h3>Ecuador</h3>

                    <table class="table">
                        <tr>
                            <td><strong>APROSVA (APROSVA)</strong><br />
                                https://aprosva.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:4</td>
                            <td><strong>Prefixos (IPv4)</strong>7</td>
                            <td><strong>Prefixos (IPv6)</strong>2</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure19.png" alt="Ver" /></td>
                            <td><em>(Este IXP n&atilde;o apresenta prependings em suas tabelas)</em></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure20.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (GYE)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>3 </td>
                            <td>3 </td>
                            <td>6 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>32 </td>
                            <td>1 </td>
                            <td>14 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>24 </td>
                            <td>48 </td>
                            <td>1 </td>
                            <td>4 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Ecuador (GYE)</strong><br />
                                https://www.ixp.ec </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: EC</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:24</td>
                            <td><strong>Prefixos (IPv4)</strong>106</td>
                            <td><strong>Prefixos (IPv6)</strong>41</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure21.png" alt="Ver" /></td>
                            <td> <em>(Este IXP n&atilde;o apresenta prependings em suas tabelas)</em></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure22.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p> Resumo das tabelas de roteamento  (GYE) </p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>1 </td>
                            <td>20 </td>
                            <td>59 </td>
                            <td>0 </td>
                            <td>23.85 </td>
                            <td>&nbsp;</td>
                            <td>1.76 </td>
                            <td>59 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>47 </td>
                            <td>41 </td>
                            <td>23.96 </td>
                            <td>48 </td>
                            <td>1.1 </td>
                            <td>88 </td>
                        </tr>
                    </table>


                    <h3>Guatemala</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Guatemala (IXPGT)</strong><br />
                                http://www.ixp.gt </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: GT</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:2672</td>
                            <td><strong>Prefixos (IPv4)</strong>18341</td>
                            <td><strong>Prefixos (IPv6)</strong>31</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure23_1.png" alt="Ver" /></td>
                            <td> <img src="/images/interconexion-bgp-local-en-lac/figure23_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure24.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (IXPGT)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>0 </td>
                            <td>29 </td>
                            <td>184 </td>
                            <td>0 </td>
                            <td>23.11 </td>
                            <td>&nbsp;</td>
                            <td>5.35 </td>
                            <td>184 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>92 </td>
                            <td>783 </td>
                            <td>0 </td>
                            <td>22.28 </td>
                            <td>&nbsp;</td>
                            <td>5.46 </td>
                            <td>783 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>22 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>1 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>8 </td>
                            <td>143 </td>
                            <td>0 </td>
                            <td>23.48 </td>
                            <td>&nbsp;</td>
                            <td>4.61 </td>
                            <td>143 </td>
                        </tr>
                        <tr>
                            <td>CO </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>30 </td>
                            <td>0 </td>
                            <td>24.93 </td>
                            <td>&nbsp;</td>
                            <td>4.8 </td>
                            <td>30 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>4 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>EC </td>
                            <td>0 </td>
                            <td>15 </td>
                            <td>86 </td>
                            <td>0 </td>
                            <td>23.77 </td>
                            <td>&nbsp;</td>
                            <td>4.52 </td>
                            <td>86 </td>
                        </tr>
                        <tr>
                            <td>GT </td>
                            <td>1 </td>
                            <td>15 </td>
                            <td>112 </td>
                            <td>4 </td>
                            <td>23.82 </td>
                            <td>36 </td>
                            <td>2.14 </td>
                            <td>116 </td>
                        </tr>
                        <tr>
                            <td>HN </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>13 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>13 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>180 </td>
                            <td>0 </td>
                            <td>23.72 </td>
                            <td>&nbsp;</td>
                            <td>9.75 </td>
                            <td>180 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>3 </td>
                            <td>0 </td>
                            <td>22.33 </td>
                            <td>&nbsp;</td>
                            <td>4.33 </td>
                            <td>3 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>6 </td>
                            <td>0 </td>
                            <td>23.33 </td>
                            <td>&nbsp;</td>
                            <td>2.83 </td>
                            <td>6 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>47 </td>
                            <td>884 </td>
                            <td>0 </td>
                            <td>22.88 </td>
                            <td>&nbsp;</td>
                            <td>5.61 </td>
                            <td>884 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>583 </td>
                            <td>4781 </td>
                            <td>0 </td>
                            <td>22.4 </td>
                            <td>&nbsp;</td>
                            <td>6.53 </td>
                            <td>4781 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>0 </td>
                            <td>1179 </td>
                            <td>7706 </td>
                            <td>27 </td>
                            <td>21.96 </td>
                            <td>44.37 </td>
                            <td>6.09 </td>
                            <td>7733 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>11 </td>
                            <td>34 </td>
                            <td>0 </td>
                            <td>21.29 </td>
                            <td>&nbsp;</td>
                            <td>6.32 </td>
                            <td>34 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>668 </td>
                            <td>3392 </td>
                            <td>0 </td>
                            <td>21.34 </td>
                            <td>&nbsp;</td>
                            <td>5.9 </td>
                            <td>3392 </td>
                        </tr>
                    </table>

                    <p>* Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated. </p>

                    <h3>M&eacute;xico</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Yucat&aacute;n  (IXSY)</strong><br />
                                https://ixsy.org.mx- </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:10</td>
                            <td><strong>Prefixos (IPv4)</strong>84</td>
                            <td><strong>Prefixos (IPv6)</strong>33</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure25_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure26.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumo das tabelas de roteamento  (IXSY)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>8 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>80 </td>
                            <td>33 </td>
                            <td>23.98 </td>
                            <td>45.76 </td>
                            <td>1.94 </td>
                            <td>226 </td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td><strong>IXP M&eacute;xico (MEX)</strong><br />
                                https://ixp.mx </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: PCH</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:295</td>
                            <td><strong>Prefixos (IPv4)</strong>2090</td>
                            <td><strong>Prefixos (IPv6)</strong>147</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>

                    <p>Resumo das tabelas de roteamento  (MEX)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s ou regi&atilde;o</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>4 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>23.8 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>5 </td>
                        </tr>
                        <tr>
                            <td>BZ </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>CR </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>11 </td>
                            <td>0 </td>
                            <td>23.64 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>11 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>4 </td>
                            <td>10 </td>
                            <td>302 </td>
                            <td>0 </td>
                            <td>22.72 </td>
                            <td>&nbsp;</td>
                            <td>1.25 </td>
                            <td>322 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>4 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>4 </td>
                        </tr>
                        <tr>
                            <td>afrinic </td>
                            <td>0 </td>
                            <td>2 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>apnic </td>
                            <td>0 </td>
                            <td>55 </td>
                            <td>191 </td>
                            <td>0 </td>
                            <td>23.69 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>191 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>135 </td>
                            <td>1195 </td>
                            <td>134 </td>
                            <td>23.07 </td>
                            <td>46.88 </td>
                            <td>1.54 </td>
                            <td>1464 </td>
                        </tr>
                        <tr>
                            <td>other* </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>2 </td>
                            <td>0 </td>
                            <td>23 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>1 </td>
                            <td>84 </td>
                            <td>366 </td>
                            <td>13 </td>
                            <td>23.37 </td>
                            <td>48 </td>
                            <td>1.96 </td>
                            <td>387 </td>
                        </tr>
                    </table>

                    <p>* Refer&ecirc;ncia a recursos que n&atilde;o puderam ser identificados com o registro delegated.</p>

                    <h3>Paraguai</h3>

                    <table class="table">
                        <tr>
                            <td><strong>IXP Paraguay (IXPY)</strong><br />
                                https://www.ix.py </td>
                        </tr>
                        <tr>
                            <td><strong>Pa&iacute;s</strong>: MX</td>
                            <td><strong>&nbsp;</strong></td>
                            <td><strong>Fonte</strong>: LACNIC</td>
                        </tr>
                        <tr>
                            <td><strong>AS observados</strong>:169</td>
                            <td><strong>Prefixos (IPv4)</strong>2378</td>
                            <td><strong>Prefixos (IPv6)</strong>539</td>
                        </tr>
                    </table>

                    <table class="table">
                        <tr>
                            <td>&aacute;rea de influ&ecirc;ncia</td>
                            <td>ASN Prependings</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_1.png" alt="Ver" /></td>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure27_2.png" alt="Ver" /></td>
                        </tr>
                        <tr>
                            <td>
                            Cobertura (%) sobre os an&uacute;ncios do pa&iacute;s</td>
                        </tr>
                        <tr>
                            <td><img src="/images/interconexion-bgp-local-en-lac/figure28.png" alt="Ver" /></td>
                        </tr>
                    </table>


                    <p>Resumo das tabelas de roteamento  (IXPY)</p>

                    <table class="table">
                        <tr>
                            <td>Pa&iacute;s o Re <br />
                                gi&oacute;n</td>
                            <td>ASes Peers</td>
                            <td>ASes origem</td>
                            <td>Prefixos de origem</td>
                            <td>Comprimento m&eacute;dio prefixo</td>
                            <td>Comprimento m&eacute;dio path</td>
                            <td>Paths</td>
                        </tr>
                        <tr>
                            <td>IPv4</td>
                            <td>IPv6</td>
                            <td>IPv4</td>
                            <td>IPv6</td>
                        </tr>
                        <tr>
                            <td>AR </td>
                            <td>1 </td>
                            <td>115 </td>
                            <td>1624 </td>
                            <td>480 </td>
                            <td>23.14 </td>
                            <td>46.69 </td>
                            <td>2.85 </td>
                            <td>4208 </td>
                        </tr>
                        <tr>
                            <td>BR </td>
                            <td>0 </td>
                            <td>3 </td>
                            <td>10 </td>
                            <td>0 </td>
                            <td>23.6 </td>
                            <td>&nbsp;</td>
                            <td>2 </td>
                            <td>20 </td>
                        </tr>
                        <tr>
                            <td>CL </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>7 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>MX </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>3 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PA </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>5 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>1 </td>
                            <td>10 </td>
                        </tr>
                        <tr>
                            <td>PE </td>
                            <td>0 </td>
                            <td>1 </td>
                            <td>1 </td>
                            <td>0 </td>
                            <td>24 </td>
                            <td>&nbsp;</td>
                            <td>5 </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>PY </td>
                            <td>16 </td>
                            <td>24 </td>
                            <td>628 </td>
                            <td>38 </td>
                            <td>22.45 </td>
                            <td>40.58 </td>
                            <td>1.3 </td>
                            <td>1332 </td>
                        </tr>
                        <tr>
                            <td>UY </td>
                            <td>0 </td>
                            <td>9 </td>
                            <td>49 </td>
                            <td>5 </td>
                            <td>23.39 </td>
                            <td>44.4 </td>
                            <td>3.94 </td>
                            <td>108 </td>
                        </tr>
                        <tr>
                            <td>arin </td>
                            <td>2 </td>
                            <td>9 </td>
                            <td>51 </td>
                            <td>9 </td>
                            <td>23.92 </td>
                            <td>47.78 </td>
                            <td>1.68 </td>
                            <td>120 </td>
                        </tr>
                        <tr>
                            <td>ripencc </td>
                            <td>0 </td>
                            <td>5 </td>
                            <td>8 </td>
                            <td>7 </td>
                            <td>23.88 </td>
                            <td>44.29 </td>
                            <td>3 </td>
                            <td>30 </td>
                        </tr>
                    </table>
                </>}
            </div>

        </div >
    )
}

export default DatosIXP
