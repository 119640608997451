import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'


function IPv4AndServices(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p8">
                {myLang === 'es' && <>
                    <h2>IPv4 as a Service</h2>
                    <p>Dado el estado de agotamiento del espacio de direccionamiento p&uacute;blico de IPv4 y el avance tecnol&oacute;gico de los &uacute;ltimos a&ntilde;os, se lleg&oacute; al punto de considerar ya realizado el despliegue de IPv6 en las distintas tecnolog&iacute;as de acceso.</p>
                    <p>Bajo dicha condici&oacute;n surgen ciertos Mecanismos de Transici&oacute;n, de los denominados IPv4-as-a-Service (IPv4aaS), los cuales consideran a IPv4 como un servicio adicional al servicio b&aacute;sico de acceso a Internet sobre IPv6.</p>
                    <p>Dentro de los mecanismos de transici&oacute;n de esta categor&iacute;a podemos considerar a:</p>
                    <ul>
                        <li>464XLAT</li>
                        <li>Dual-Stack Lite (DS-Lite)</li>
                        <li>Lightweight 4over6 (lw4o6)</li>
                        <li>MAP-E</li>
                        <li>MAP-T</li>
                    </ul>

                    <p>Existen algunos documentos que describen las caracter&iacute;sticas que deben tener los CPEs para poder operar con estas funcionalidades:</p>
                    <ul>
                        <li><a href="https://tools.ietf.org/html/rfc7084" target="_blank" rel="noreferrer">RFC 7084</a> - Basic Requirements for IPv6  Customer Edge Routers.</li>
                        <li><a href="https://tools.ietf.org/html/rfc8585" target="_blank" rel="noreferrer">RFC 8585</a> - Requirements for IPv6 Customer  Edge Routers to Support IPv4-as-a-Service</li>
                        <li><a href="https://www.rfc-editor.org/rfc/rfc9096.html" target="_blank" rel="noreferrer">RFC 9096</a> - Improving the Reaction of  Customer Edge Routers to IPv6 Renumbering Events</li>
                    </ul>
                    <p>A continuaci&oacute;n, se describe el funcionamiento de cada uno de estos mecanismos ya que ser&aacute;n el ser&aacute;n el foco principal de la investigaci&oacute;n sobre el soporte de los CPEs respecto a estos.</p>

                    <h3>464XLAT</h3>
                    <p>Definido en <a href="https://tools.ietf.org/html/rfc6877" target="_blank" rel="noreferrer">RFC 6877</a> - Combination of Stateful and Stateless  Translation.</p>
                    <p>Est&aacute; compuesto por  dos componentes principales:</p>
                    <ul>
                        <li><strong>Provider-side translator</strong> (<strong>PLAT</strong>): Corresponde a un NAT64 Stateful (<a href="https://tools.ietf.org/html/rfc6146" target="_blank" rel="noreferrer">RFC6146</a>)  donde realiza una traducci&oacute;n N:1 de IPv6 a IPv4 p&uacute;blica y viceversa,  manteniendo el estado de las sesiones.</li>
                        <li><strong>Customer-side translator</strong> (<strong>CLAT</strong>): Es un NAT46 Stateless que cumple con la <a href="https://tools.ietf.org/html/rfc6145" target="_blank" rel="noreferrer">RFC 6145</a> - IP/ICMP Translation Algorithm. Realiza una traducci&oacute;n 1:1 de una IPv4 privada  a una IPv6 de alcance global  y  viceversa. La funcionalidad de CLAT puede estar incluida tanto en un dispositivo  final m&oacute;vil como en un CPE.</li>
                    </ul>

                    <p><strong>Esquema en una Red M&oacute;vil:</strong></p>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen1.png" alt="Ver" /></figure>
                    </div>

                    <p><strong>Esquema en una Red Fija:</strong></p>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen2.png" alt="Ver" /></figure>
                    </div>

                    <p>En la actualidad se est&aacute; trabajando en un documento llamado <a href="https://datatracker.ietf.org/doc/draft-palet-v6ops-464xlat-opt-cdn-caches/" target="_blank" rel="noreferrer">464XLAT Optimization</a> que propone alternativas  para evitar tener que utilizar el NAT64 para contenido que se encuentre en IPv6</p>

                    <h3>Dual-Stack Lite (DS-Lite)</h3>
                    <p>Definido en <a href="https://tools.ietf.org/html/rfc6333" target="_blank" rel="noreferrer">RFC 6333</a> - Dual-Stack Lite Broadband  Deployments Following IPv4 Exhaustion.</p>
                    <p>En <a href="https://tools.ietf.org/html/rfc6908" target="_blank" rel="noreferrer">RFC 6908</a> se definen consideraciones para el  despliegue de DS-Lite</p>
                    <p>Este mecanismo tiene dos componentes  principales:</p>
                    <ul>
                        <li><strong>Basic Bridging BroadBand</strong> (<strong>B4</strong>): Esta funcionalidad embebida en el CPE permite encapsular y  enviar el tr&aacute;fico IPv4 del cliente a trav&eacute;s de un t&uacute;nel IPv6 hasta el AFTR.</li>
                        <li><strong>Address  Family Transition Router</strong> (<strong>AFTR</strong>): Aqu&iacute; el tr&aacute;fico del cliente es  desencapsulado del t&uacute;nel IPv6. El AFTR tiene la funcionalidad de Carrier Grade  NAT donde traduce la IPv4 privada del cliente a una IPv4 p&uacute;blica compartida  entre varios clientes</li>
                    </ul>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen3.png" alt="Ver" /></figure>
                        <figcaption>Disponible en: <a href="https://www.zte.com.cn/global/about/magazine/zte-technologies/2011/4/en_544/244367" target="_blank" rel="noreferrer"> https://www.zte.com.cn/global/about/magazine/zte-technologies/2011/4/en_544/244367</a></figcaption>
                    </div>


                    <h3>Lightweight 4over6 (lw4o6)</h3>
                    <p>Lightweight 4over6 es una extensi&oacute;n a DS-Lite y  hereda sus dos componentes de principales aunque con ligeros cambios:</p>
                    <ul>
                        <li><strong>Lightweight Basic Bridging BroadBand</strong> (<strong>lwB4</strong>):</li>
                        <li><strong>Lightweight Address Family Transition Router</strong> (<strong>lwAFTR</strong>):</li>
                    </ul>
                    <p>Est&aacute; definido  en la RFC 7596 - Lightweight 4over6: An Extension to the Dual-Stack Lite  Architecture.</p>
                    <p>La principal diferencia contra DS-Lite es que en  Lightweight 4over6 se mueve la funcionalidad de Carrier Grade NAT al lwB4 en el  CPE haciendo uso de la t&eacute;cnica llamada A+P (&ldquo;The Address plus Port (A+P)  Approach to the IPv4 Address Shortage&rdquo;, RFC 6346) permitiendo compartir una  misma IPv4 p&uacute;blica partiendo el espacio de puertos en m&uacute;ltiples CPEs de  clientes.</p>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen4.png" alt="Ver" /></figure>
                        <figcaption>Disponible en: <a href="https://slideplayer.com/slide/10818043/" target="_blank" rel="noreferrer"> https://slideplayer.com/slide/10818043/</a></figcaption>
                    </div>

                    <h3>MAP-E</h3>
                    <p>MAP-E es  Mapping Address and Port with Encapsulation.</p>
                    <p>Est&aacute; definido en la RFC 7597.</p>
                    <p>Tiene 2 componentes principales:</p>
                    <ul>
                        <li><strong>MAP Customer Edge</strong> (<strong>CE</strong>): Al igual que en Lightweight 4over6, es una funcionalidad  embebida en el CPE y se encarga de realizar un NAT44 entre la IPv4 privada del  Host y una IPv4 p&uacute;blica con una porci&oacute;n de puertos preasignada. Adicionalmente  encapsula el tr&aacute;fico en un t&uacute;nel IPv6 sin estado hasta el MAP-BR.</li>
                        <li><strong>MAP Border Relay</strong> (<strong>BR</strong>): Desencapsula el tr&aacute;fico del t&uacute;nel IPv6 y env&iacute;a el tr&aacute;fico con  la IPv4 p&uacute;blica hacia Internet.</li>
                    </ul>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen5.png" alt="Ver" /></figure>
                        <figcaption>Disponible en: <a href="https://es.slideshare.net/akiranakagawa3/20150304-apricot2015apnicfukuoka" target="_blank" rel="noreferrer">https://es.slideshare.net/akiranakagawa3/20150304-apricot2015apnicfukuoka</a></figcaption>
                    </div>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen6.png" alt="Ver" /></figure>
                        <figcaption>Disponible en: <a href="https://www.broadband-forum.org/download/TR-242.pdf" target="_blank" rel="noreferrer">https://www.broadband-forum.org/download/TR-242.pdf</a></figcaption>
                    </div>

                    <h3>MAP-T</h3>
                    <p>MAP-T  significa Mapping of Address and Port using Translation y est&aacute; especificado en  el RFC 7599.</p>
                    <p>Sus componentes principales son:</p>
                    <ul>
                        <li><strong>MAP Customer Edge</strong> (<strong>CE</strong>): Como en MAP-E, se realiza una traducci&oacute;n de NAT44.  Adicionalmente realiza un NAT46 sin estado y el tr&aacute;fico es enviado al BR.</li>
                        <li><strong>MAP Border Relay</strong> (<strong>BR</strong>): Aqu&iacute; el tr&aacute;fico es recibido del CE es recibido y mediante un  NAT64 sin estado se traduce a la IPv4 p&uacute;blica correspondiente basada en la  informaci&oacute;n del NAT44 del CE.</li>
                    </ul>
                    <div>
                        <figure><img src="/images/ipv6-en-equipos-cpe/Imagen7.png" alt="Ver" /></figure>
                        <figcaption>Disponible en: <a href="https://community.cisco.com/t5/service-providers-documents/in-line-map-t-solution-explanation-with-vsm-for-exception/ta-p/3750505" target="_blank" rel="noreferrer">https://community.cisco.com/t5/service-providers-documents/in-line-map-t-solution-explanation-with-vsm-for-exception/ta-p/3750505</a></figcaption>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default IPv4AndServices
