import React, {useContext} from 'react'
import { LangContext } from '../../../context/DataContext'

function Alcance(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4">
            { myLang === 'es' && <>
                <h2>Alcance</h2>
                <p>El alcance de este estudio est&aacute; orientado los CPEs de las siguientes tecnolog&iacute;as de acceso de redes fijas:</p>
                <ul>
                    <li>ADSL</li>
                    <li>DOCSIS</li>
                    <li>FTTH</li>
                </ul>
                <p>El estudio es realizado sobre los CPEs m&aacute;s populares que hoy en d&iacute;a ya soportan IPv6 y haya conocimiento sobre casos de implementaci&oacute;n en la regi&oacute;n de las tecnolog&iacute;as de acceso identificadas.</p>
            </>}
            </div>
        </div>
    )
}

export default Alcance
