import React, { useState, useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 
import SearchComponent from '../../../components/SearchComponent';


function MenuLateralInterconexionBgp() {
    const { myLang } = useContext(LangContext)
    const { activeAnchor, scrollToAnchor } = useContext(MenuContext)
    
    const [clickMenu, establecerClickMenu] = useState();
    const abrirMenuLateral = (menu) => { 
        if ( menu === '') {
            establecerClickMenu()
        }
        if ( menu === 'metodologia') {
            scrollToAnchor('bgp3')
            establecerClickMenu(menu);
            scrollToAnchor('a0')
        }
        if ( menu === 'datos') {
            scrollToAnchor('bgp4')
            establecerClickMenu(menu);
            scrollToAnchor('b0') 
        }
         
    };  

    return (<> 
         
            {myLang === 'es' && 
                <nav>
                    <button>Interconexión en BGP en la región de América Latina y el Caribe <i className="fas fa-angle-down"></i></button>
                    <SearchComponent />
                    
                    <ul className="active">
                        <li onClick={() => abrirMenuLateral('')}> <a href="#resumen" className={activeAnchor === 'bgp1' ? 'active' : null} onClick={() => scrollToAnchor('bgp1')} >Resumen</a></li>
                        <li onClick={() => abrirMenuLateral('')}><a href="#introducción" className={activeAnchor === 'bgp2' ? 'active' : null} onClick={() => scrollToAnchor('bgp2')} >Introducción</a></li>
                        <li>
                        <a href="#metodologia" className={activeAnchor === 'bgp3' ? 'active' : null} onClick={() => abrirMenuLateral('metodologia')} >Metodología</a>
                            <ul className={`${clickMenu === 'metodologia' && 'active'}`}>
                                <li> <a href="#objetivos-planteados" className={activeAnchor === 'a1' ? 'active' : null} onClick={() => scrollToAnchor('a1')} >Objetivos planteados</a></li>
                                <li> <a href="#fuentes-de-datos" className={activeAnchor === 'a2' ? 'active' : null} onClick={() => scrollToAnchor('a2')} >Fuentes de datos</a></li>
                                <li> <a href="#procesamiento-de-la-informacion" className={activeAnchor === 'a3' ? 'active' : null} onClick={() => scrollToAnchor('a3')} >Procesamiento de la información</a></li>
                                <li> <a href="#datasets-generados" className={activeAnchor === 'a4' ? 'active' : null} onClick={() => scrollToAnchor('a4')} >Datasets generados</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#datos-por-pais" className={activeAnchor === 'bgp4' ? 'active' : null} onClick={() => abrirMenuLateral('datos')} >Datos por país</a>
                            <ul className={`${clickMenu === 'datos' && 'active'}`}>
                                <li> <a href="#argentina" className={activeAnchor === 'argentina' ? 'active' : null} onClick={() => scrollToAnchor('argentina')} >Argentina</a></li>
                                <li> <a href="#aruba" className={activeAnchor === 'aruba' ? 'active' : null} onClick={() => scrollToAnchor('aruba')} >Aruba</a></li>
                                <li> <a href="#bolivia" className={activeAnchor === 'bolivia' ? 'active' : null} onClick={() => scrollToAnchor('bolivia')} >Bolivia</a></li>
                                <li> <a href="#brasil" className={activeAnchor === 'brasil' ? 'active' : null} onClick={() => scrollToAnchor('brasil')} >Brasil</a></li>
                                <li> <a href="#chile" className={activeAnchor === 'chile' ? 'active' : null} onClick={() => scrollToAnchor('chile')} >Chile</a></li>
                                <li> <a href="#colombia" className={activeAnchor === 'colombia' ? 'active' : null} onClick={() => scrollToAnchor('colombia')} >Colombia</a></li>
                                <li> <a href="#costa-rica" className={activeAnchor === 'costa-rica' ? 'active' : null} onClick={() => scrollToAnchor('costa-rica')} >Costa Rica</a></li>
                                <li> <a href="#cuba" className={activeAnchor === 'cuba' ? 'active' : null} onClick={() => scrollToAnchor('cuba')} >Cuba</a></li>
                                <li> <a href="#republica-dominicana" className={activeAnchor === 'republica-dominicana' ? 'active' : null} onClick={() => scrollToAnchor('republica-dominicana')} >República Dominicana </a></li>
                                <li> <a href="#ecuador" className={activeAnchor === 'ecuador' ? 'active' : null} onClick={() => scrollToAnchor('ecuador')} >Ecuador</a></li>
                                <li> <a href="#guyana-francesa" className={activeAnchor === 'guyana-francesa' ? 'active' : null} onClick={() => scrollToAnchor('guyana-francesa')} >Guyana Francesa</a></li>
                                <li> <a href="#guatemala" className={activeAnchor === 'guatemala' ? 'active' : null} onClick={() => scrollToAnchor('guatemala')} >Guatemala</a></li>
                                <li> <a href="#guyana" className={activeAnchor === 'guyana' ? 'active' : null} onClick={() => scrollToAnchor('guyana')} >Guyana</a></li>
                                <li> <a href="#honduras" className={activeAnchor === 'honduras' ? 'active' : null} onClick={() => scrollToAnchor('honduras')} >Honduras</a></li>
                                <li> <a href="#haiti" className={activeAnchor === 'haiti' ? 'active' : null} onClick={() => scrollToAnchor('haiti')} >Haití</a></li>
                                <li> <a href="#mexico" className={activeAnchor === 'mexico' ? 'active' : null} onClick={() => scrollToAnchor('mexico')} >México</a></li>
                                <li> <a href="#nicaragua" className={activeAnchor === 'nicaragua' ? 'active' : null} onClick={() => scrollToAnchor('nicaragua')} >Nicaragua</a></li>
                                <li> <a href="#panamá" className={activeAnchor === 'panamá' ? 'active' : null} onClick={() => scrollToAnchor('panamá')} >Panamá</a></li>
                                <li> <a href="#perú" className={activeAnchor === 'perú' ? 'active' : null} onClick={() => scrollToAnchor('perú')} >Perú</a></li>
                                <li> <a href="#paraguay" className={activeAnchor === 'paraguay' ? 'active' : null} onClick={() => scrollToAnchor('paraguay')} >Paraguay</a></li>
                                <li> <a href="#surinam" className={activeAnchor === 'surinam' ? 'active' : null} onClick={() => scrollToAnchor('surinam')} >Surinam</a></li>
                                <li> <a href="#el-salvador" className={activeAnchor === 'el-salvador' ? 'active' : null} onClick={() => scrollToAnchor('el-salvador')} >El Salvador</a></li>
                                <li> <a href="#trinidad-y-tobago" className={activeAnchor === 'trinidad-y-tobago' ? 'active' : null} onClick={() => scrollToAnchor('trinidad-y-tobago')} >Trinidad y Tobago</a></li>
                                <li> <a href="#uruguay" className={activeAnchor === 'uruguay' ? 'active' : null} onClick={() => scrollToAnchor('uruguay')} >Uruguay</a></li>
                                <li> <a href="#venezuela" className={activeAnchor === 'venezuela' ? 'active' : null} onClick={() => scrollToAnchor('venezuela')} >Venezuela</a></li> 
                            
                            
                            </ul>
                        
                        </li>
                             
                        <li onClick={() => abrirMenuLateral('')}><a href="#analisis-de-los-datos-a-nivel-regional" className={activeAnchor === 'bgp5' ? 'active' : null} onClick={() => scrollToAnchor('bgp5')} >Análisis de los datos a nivel regional</a></li>
                        <li onClick={() => abrirMenuLateral('')}><a href="#conclusiones-y-trabajo-a-futuro" className={activeAnchor === 'bgp6' ? 'active' : null} onClick={() => scrollToAnchor('bgp6')} >Conclusiones y trabajo a futuro</a></li>
                        <li onClick={() => abrirMenuLateral('')}><a href="#referencias" className={activeAnchor === 'bgp7' ? 'active' : null} onClick={() => scrollToAnchor('bgp7')} >Referencias</a></li>
                    </ul>
                </nav>
            }
            {myLang === 'en' && 
                <nav>
                    <button>BGP Interconnection in the Region of Latin America and the Caribbean <i className="fas fa-angle-down"></i></button>
                    <SearchComponent />
                    <ul className="active">
                        <li><a href="#abstract" className={activeAnchor === 'bgp1' ? 'active' : null} onClick={() => scrollToAnchor('bgp1')} >Abstract</a></li>
                        <li><a href="#introduction" className={activeAnchor === 'bgp2' ? 'active' : null} onClick={() => scrollToAnchor('bgp2')} >Introduction</a></li>
                        <li>
                        <a href="#methodology" className={activeAnchor === 'bgp3' ? 'active' : null} onClick={() => abrirMenuLateral('metodologia')} >Methodology</a> 
                            <ul className={`${clickMenu === 'metodologia' && 'active'}`}>
                                <li> <a href="#objectives" className={activeAnchor === 'a1' ? 'active' : null} onClick={() => scrollToAnchor('a1')} >Objectives</a></li>
                                <li> <a href="#data-sources" className={activeAnchor === 'a2' ? 'active' : null} onClick={() => scrollToAnchor('a2')} >Data Sources</a></li>
                                <li> <a href="#data-processing" className={activeAnchor === 'a3' ? 'active' : null} onClick={() => scrollToAnchor('a3')} >Data Processing</a></li>
                                <li> <a href="#generated-datasets" className={activeAnchor === 'a4' ? 'active' : null} onClick={() => scrollToAnchor('a4')} >Generated Datasets</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#data-by-country" className={activeAnchor === 'bgp4' ? 'active' : null} onClick={() => abrirMenuLateral('datos')} >Data by Country</a>
                            <ul className={`${clickMenu === 'datos' && 'active'}`}>
                                <li> <a href="#argentina" className={activeAnchor === 'argentina' ? 'active' : null} onClick={() => scrollToAnchor('argentina')} >Argentina</a></li>
                                <li> <a href="#aruba" className={activeAnchor === 'aruba' ? 'active' : null} onClick={() => scrollToAnchor('aruba')} >Aruba</a></li>
                                <li> <a href="#bolivia" className={activeAnchor === 'bolivia' ? 'active' : null} onClick={() => scrollToAnchor('bolivia')} >Bolivia</a></li>
                                <li> <a href="#brasil" className={activeAnchor === 'brasil' ? 'active' : null} onClick={() => scrollToAnchor('brasil')} >Brasil</a></li>
                                <li> <a href="#chile" className={activeAnchor === 'chile' ? 'active' : null} onClick={() => scrollToAnchor('chile')} >Chile</a></li>
                                <li> <a href="#colombia" className={activeAnchor === 'colombia' ? 'active' : null} onClick={() => scrollToAnchor('colombia')} >Colombia</a></li>
                                <li> <a href="#costa-rica" className={activeAnchor === 'costa-rica' ? 'active' : null} onClick={() => scrollToAnchor('costa-rica')} >Costa Rica</a></li>
                                <li> <a href="#cuba" className={activeAnchor === 'cuba' ? 'active' : null} onClick={() => scrollToAnchor('cuba')} >Cuba</a></li>
                                <li> <a href="#republica-dominicana" className={activeAnchor === 'republica-dominicana' ? 'active' : null} onClick={() => scrollToAnchor('republica-dominicana')} >República Dominicana </a></li>
                                <li> <a href="#ecuador" className={activeAnchor === 'ecuador' ? 'active' : null} onClick={() => scrollToAnchor('ecuador')} >Ecuador</a></li>
                                <li> <a href="#guyana-francesa" className={activeAnchor === 'guyana-francesa' ? 'active' : null} onClick={() => scrollToAnchor('guyana-francesa')} >Guyana Francesa</a></li>
                                <li> <a href="#guatemala" className={activeAnchor === 'guatemala' ? 'active' : null} onClick={() => scrollToAnchor('guatemala')} >Guatemala</a></li>
                                <li> <a href="#guyana" className={activeAnchor === 'guyana' ? 'active' : null} onClick={() => scrollToAnchor('guyana')} >Guyana</a></li>
                                <li> <a href="#honduras" className={activeAnchor === 'honduras' ? 'active' : null} onClick={() => scrollToAnchor('honduras')} >Honduras</a></li>
                                <li> <a href="#haiti" className={activeAnchor === 'haiti' ? 'active' : null} onClick={() => scrollToAnchor('haiti')} >Haití</a></li>
                                <li> <a href="#mexico" className={activeAnchor === 'mexico' ? 'active' : null} onClick={() => scrollToAnchor('mexico')} >México</a></li>
                                <li> <a href="#nicaragua" className={activeAnchor === 'nicaragua' ? 'active' : null} onClick={() => scrollToAnchor('nicaragua')} >Nicaragua</a></li>
                                <li> <a href="#panamá" className={activeAnchor === 'panamá' ? 'active' : null} onClick={() => scrollToAnchor('panamá')} >Panamá</a></li>
                                <li> <a href="#perú" className={activeAnchor === 'perú' ? 'active' : null} onClick={() => scrollToAnchor('perú')} >Perú</a></li>
                                <li> <a href="#paraguay" className={activeAnchor === 'paraguay' ? 'active' : null} onClick={() => scrollToAnchor('paraguay')} >Paraguay</a></li>
                                <li> <a href="#surinam" className={activeAnchor === 'surinam' ? 'active' : null} onClick={() => scrollToAnchor('surinam')} >Surinam</a></li>
                                <li> <a href="#el-salvador" className={activeAnchor === 'el-salvador' ? 'active' : null} onClick={() => scrollToAnchor('el-salvador')} >El Salvador</a></li>
                                <li> <a href="#trinidad-y-tobago" className={activeAnchor === 'trinidad-y-tobago' ? 'active' : null} onClick={() => scrollToAnchor('trinidad-y-tobago')} >Trinidad y Tobago</a></li>
                                <li> <a href="#uruguay" className={activeAnchor === 'uruguay' ? 'active' : null} onClick={() => scrollToAnchor('uruguay')} >Uruguay</a></li>
                                <li> <a href="#venezuela" className={activeAnchor === 'venezuela' ? 'active' : null} onClick={() => scrollToAnchor('venezuela')} >Venezuela</a></li> 
                            
                            
                            </ul>
                        </li>
                        <li><a href="#regional-data-analysis" className={activeAnchor === 'bgp5' ? 'active' : null} onClick={() => scrollToAnchor('bgp5')} >Regional Data Analysis</a></li>
                        <li><a href="#conclusions-and-future-work" className={activeAnchor === 'bgp6' ? 'active' : null} onClick={() => scrollToAnchor('bgp6')} >Conclusions and Future Work</a></li>
                        <li><a href="#references" className={activeAnchor === 'bgp7' ? 'active' : null} onClick={() => scrollToAnchor('bgp7')} >References</a></li>
                    </ul>
                </nav>
            }
            {myLang === 'pt' && 
                <nav>
                    <button>Interconexão no BGP na região da América Latina e o Caribe<i className="fas fa-angle-down"></i></button>
                    <SearchComponent />
                    <ul className="active">
                        <li><a href="#resumo" className={activeAnchor === 'bgp1' ? 'active' : null} onClick={() => scrollToAnchor('bgp1')} >Resumo</a></li>
                        <li><a href="#introducao" className={activeAnchor === 'bgp2' ? 'active' : null} onClick={() => scrollToAnchor('bgp2')} >Introdução</a></li>
                        <li>
                        <a href="#metodologia" className={activeAnchor === 'bgp3' ? 'active' : null} onClick={() => abrirMenuLateral('metodologia')} >Metodologia</a>
                            <ul className={`${clickMenu === 'metodologia' && 'active'}`}>
                                <li> <a href="#objetivos-definidos" className={activeAnchor === 'a1' ? 'active' : null} onClick={() => scrollToAnchor('a1')} >Objetivos definidos</a></li>
                                <li> <a href="#fontes-de-dados" className={activeAnchor === 'a2' ? 'active' : null} onClick={() => scrollToAnchor('a2')} >Fontes de dados</a></li>
                                <li> <a href="#processamento-das-informacoes" className={activeAnchor === 'a3' ? 'active' : null} onClick={() => scrollToAnchor('a3')} >Processamento das informações</a></li>
                                <li> <a href="#datasets-gerados" className={activeAnchor === 'a4' ? 'active' : null} onClick={() => scrollToAnchor('a4')} >Datasets gerados</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#dados-por-pais" className={activeAnchor === 'bgp4' ? 'active' : null} onClick={() => abrirMenuLateral('datos')} >Dados por país</a>
                            <ul className={`${clickMenu === 'datos' && 'active'}`}>
                                <li> <a href="#argentina" className={activeAnchor === 'argentina' ? 'active' : null} onClick={() => scrollToAnchor('argentina')} >Argentina</a></li>
                                <li> <a href="#aruba" className={activeAnchor === 'aruba' ? 'active' : null} onClick={() => scrollToAnchor('aruba')} >Aruba</a></li>
                                <li> <a href="#bolivia" className={activeAnchor === 'bolivia' ? 'active' : null} onClick={() => scrollToAnchor('bolivia')} >Bolivia</a></li>
                                <li> <a href="#brasil" className={activeAnchor === 'brasil' ? 'active' : null} onClick={() => scrollToAnchor('brasil')} >Brasil</a></li>
                                <li> <a href="#chile" className={activeAnchor === 'chile' ? 'active' : null} onClick={() => scrollToAnchor('chile')} >Chile</a></li>
                                <li> <a href="#colombia" className={activeAnchor === 'colombia' ? 'active' : null} onClick={() => scrollToAnchor('colombia')} >Colombia</a></li>
                                <li> <a href="#costa-rica" className={activeAnchor === 'costa-rica' ? 'active' : null} onClick={() => scrollToAnchor('costa-rica')} >Costa Rica</a></li>
                                <li> <a href="#cuba" className={activeAnchor === 'cuba' ? 'active' : null} onClick={() => scrollToAnchor('cuba')} >Cuba</a></li>
                                <li> <a href="#republica-dominicana" className={activeAnchor === 'republica-dominicana' ? 'active' : null} onClick={() => scrollToAnchor('republica-dominicana')} >República Dominicana </a></li>
                                <li> <a href="#ecuador" className={activeAnchor === 'ecuador' ? 'active' : null} onClick={() => scrollToAnchor('ecuador')} >Ecuador</a></li>
                                <li> <a href="#guyana-francesa" className={activeAnchor === 'guyana-francesa' ? 'active' : null} onClick={() => scrollToAnchor('guyana-francesa')} >Guyana Francesa</a></li>
                                <li> <a href="#guatemala" className={activeAnchor === 'guatemala' ? 'active' : null} onClick={() => scrollToAnchor('guatemala')} >Guatemala</a></li>
                                <li> <a href="#guyana" className={activeAnchor === 'guyana' ? 'active' : null} onClick={() => scrollToAnchor('guyana')} >Guyana</a></li>
                                <li> <a href="#honduras" className={activeAnchor === 'honduras' ? 'active' : null} onClick={() => scrollToAnchor('honduras')} >Honduras</a></li>
                                <li> <a href="#haiti" className={activeAnchor === 'haiti' ? 'active' : null} onClick={() => scrollToAnchor('haiti')} >Haití</a></li>
                                <li> <a href="#mexico" className={activeAnchor === 'mexico' ? 'active' : null} onClick={() => scrollToAnchor('mexico')} >México</a></li>
                                <li> <a href="#nicaragua" className={activeAnchor === 'nicaragua' ? 'active' : null} onClick={() => scrollToAnchor('nicaragua')} >Nicaragua</a></li>
                                <li> <a href="#panamá" className={activeAnchor === 'panamá' ? 'active' : null} onClick={() => scrollToAnchor('panamá')} >Panamá</a></li>
                                <li> <a href="#perú" className={activeAnchor === 'perú' ? 'active' : null} onClick={() => scrollToAnchor('perú')} >Perú</a></li>
                                <li> <a href="#paraguay" className={activeAnchor === 'paraguay' ? 'active' : null} onClick={() => scrollToAnchor('paraguay')} >Paraguay</a></li>
                                <li> <a href="#surinam" className={activeAnchor === 'surinam' ? 'active' : null} onClick={() => scrollToAnchor('surinam')} >Surinam</a></li>
                                <li> <a href="#el-salvador" className={activeAnchor === 'el-salvador' ? 'active' : null} onClick={() => scrollToAnchor('el-salvador')} >El Salvador</a></li>
                                <li> <a href="#trinidad-y-tobago" className={activeAnchor === 'trinidad-y-tobago' ? 'active' : null} onClick={() => scrollToAnchor('trinidad-y-tobago')} >Trinidad y Tobago</a></li>
                                <li> <a href="#uruguay" className={activeAnchor === 'uruguay' ? 'active' : null} onClick={() => scrollToAnchor('uruguay')} >Uruguay</a></li>
                                <li> <a href="#venezuela" className={activeAnchor === 'venezuela' ? 'active' : null} onClick={() => scrollToAnchor('venezuela')} >Venezuela</a></li> 
                            
                            
                            </ul>
                        </li>
                        <li><a href="#analise-dos-dados-a-nivel-regional" className={activeAnchor === 'bgp5' ? 'active' : null} onClick={() => scrollToAnchor('bgp5')} >Análise dos dados a nível regional</a></li>
                        <li><a href="#conclusoes-e-trabalho-futuro" className={activeAnchor === 'bgp6' ? 'active' : null} onClick={() => scrollToAnchor('bgp6')} >Conclusões e trabalho futuro</a></li>
                        <li><a href="#referencias" className={activeAnchor === 'bgp7' ? 'active' : null} onClick={() => scrollToAnchor('bgp7')} >Referências</a></li>
                    </ul>
                </nav>
            }
        </>
    );
}

export default MenuLateralInterconexionBgp;