import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function ClustersDeLatencia(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-73">Clusters de latencia</MatchText></h2>
                    <p>
                        <MatchText id="match-74">En base a los tiempos de latencia registrados entre pa&iacute;ses, y de la misma forma de los estudios llevados a cabo en a&ntilde;os anteriores, agrupamos aquellos pa&iacute;ses que se encuentran m&aacute;s cerca uno del otro que del resto de la regi&oacute;n.</MatchText></p>
                    <p>
                        <MatchText id="match-75">Cuando corrimos el an&aacute;lisis de clusters sin supervisi&oacute;n, este retorn&oacute; un total de 4 clusters, de los cuales 2 tienen menos de 20 mediciones, por lo que tuvimos que tener algunas consideraciones. Una vez filtrados los datos, tuvimos 2 clusters que se muestran en los mapas de abajo, cada uno en un color diferente:</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r131415.png" /></figure>
                    <p>
                        <MatchText id="match-76">Podemos ver que hay un grupo de pa&iacute;ses en la zona sur de Am&eacute;rica, que comprende Brasil, Uruguay, Paraguay, Argentina, Chile, entre otros pa&iacute;ses. Este es el cluster #1 y es el que tiene mejor interconexi&oacute;n, con una latencia interna de 68 ms (mediana). Estos grupos est&aacute;n interconectados a trav&eacute;s de conexiones de fibra &oacute;ptica desplegadas por distintos carriers que permite una alta integraci&oacute;n. Por esa raz&oacute;n, los tiempos de latencia son bajos entre s&iacute;.</MatchText></p>
                    <p>
                        <MatchText id="match-77">El cluster #0 est&aacute; compuesto por la mayor parte de pa&iacute;ses de Centroam&eacute;rica, algunos pa&iacute;ses de Sudam&eacute;rica, y algunos pa&iacute;ses del Caribe. Este tiene una latencia interna de 129 ms (2.8 veces la del cluster #1). La definici&oacute;n de los dos clusters se puede ver en la imagen a la derecha.</MatchText></p>
                    <figure><img alt="ver" src="/images/conectividad-lac-2020/r16_es.jpg" /></figure>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-73">Latency Clusters</MatchText></h2>
                    <p>
                        <MatchText id="match-74">Based on the latency times between different countries, just as for the studies carried out in previous years, we grouped the countries that are closer to each other than to the rest of the region.</MatchText></p>
                    <p>
                        <MatchText id="match-75">The unsupervised cluster analysis returned a total of four clusters, two of which have less than 20 measurements, so we had to make some considerations. After the data was filtered, we had the two clusters shown in the maps below, each in a different color.</MatchText></p>
                    <figure> <img alt="Ver" src="/images/conectividad-lac-2020/r131415.png" /></figure>
                    <p>
                        <MatchText id="match-76">We can see that there is a group of countries in the southern part of the American continent, which includes Brazil, Uruguay, Paraguay, Argentina, and Chile, among other countries. This is cluster #1 and it is the one with the best interconnection, with an internal latency of 68 ms (median). These groups are interconnected via fiber optic cables deployed by different carriers which allow a high degree of integration. This is the reason why latency times between them are low.</MatchText></p>
                    <div>
                        <div>
                            <p>
                                <MatchText id="match-77">Cluster #0 comprises mostly countries of Central America, some countries of South America, and some countries of the Caribbean. It has an internal latency of 129 ms (2,8 times higher than cluster #1). The image on the right shows the definition of these two clusters.</MatchText></p>
                        </div>
                        <div><figure> <img alt="Ver" id="img_clusters" src="/images/conectividad-lac-2020/r16_en.jpg" /></figure> </div>
                    </div>
                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-73">Clusters de latência</MatchText></h2>
                    <p>
                        <MatchText id="match-74">Com base nos tempos de latência registrados entre países, e da mesma forma em que foram realizados os estudos em anos anteriores, agrupamos os países que estão mais próximos uns dos outros do que do resto da região.</MatchText></p>
                    <p>
                        <MatchText id="match-75">Quando executamos a análise de clusters não supervisionados, esta retornou um total de 4 clusters, dos quais 2 têm menos de 20 medições, pelo que tivemos que ter algumas considerações. Depois de os dados serem filtrados, tivemos 2 clusters mostrados nos mapas abaixo, cada um em uma cor diferente:</MatchText></p>
                    <figure><img alt="Ver" src="/images/conectividad-lac-2020/r131415.png" /></figure>
                    <p>
                        <MatchText id="match-76">Podemos ver que existe um grupo de países na zona sul da América, que inclui o Brasil, Uruguai, Paraguai, Argentina, Chile, entre outros países. Esse é o cluster #1 é o que tem a melhor interconexão, com uma latência interna de 68 ms (mediana). Esses grupos estão interconectados por meio de conexões de fibra ótica implantadas por diferentes carriers que permite uma alta integração. Por esse motivo, os tempos de latência são baixos entre si.</MatchText></p>
                    <div>
                        <div>
                            <p>
                                <MatchText id="match-77">O Cluster #0 é formado pela maioria dos países da América Central, alguns países da América do Sul e alguns países do Caribe. Isso tem uma latência interna de 129 ms (2,8 vezes a do cluster #1). A definição dos dois clusters pode ser vista na imagem à direita.</MatchText></p>
                        </div>
                    </div>
                    <figure> <img id="img_clusters" alt="Ver" src="/images/conectividad-lac-2020/r16_pt.jpg" /></figure>
                </>}
            </div>
        </div>
    );
}

export default ClustersDeLatencia;