import React, { useContext } from "react";
import { LangContext } from "../../../context/DataContext"; 
import '../../../css/reports.css';  
import Abstract from './Abstract'
import Analisis from "./Analisis";
import Conclusiones from "./Conclusiones";
import Recomendaciones from "./Recomendaciones";
import Fuentes from "./Fuentes";
import Motivacion from "./Motivacion";
import Mecanismos from "./Mecanismos";
import IPSpoofing from "./IPSpoofing";
import Resumen from "./Resumen";
import Objetivos from "./Objetivos";
import AntiSpoofingData from "./AntiSpoofingData";

export default function AntiSpoofing(props) {

    return ( <>
        <ReportTitle />
        <AntiSpoofingData />
        <Resumen /> 
        <Abstract />
        <Objetivos /> 
        <Motivacion/> 
        <IPSpoofing/> 
        <Fuentes/> 
        <Mecanismos/> 
        <Analisis/> 
        <Recomendaciones/>
        <Conclusiones/>
    </>)
}

function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
        <h1>
            {myLang === 'es' && 'Estudio anti-spoofing en la región'}
            {myLang === 'en' && 'Study on Anti-spoofing in the Region'}
            {myLang === 'pt' && 'Estudo anti-spoofing na região'}
        </h1>
    )
}







