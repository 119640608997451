import React, { useContext } from 'react';
import { LangContext } from '../context/DataContext';
import '../css/footer.css'

export default function Footer({className, footer}) { 
    return ( <>
        {footer === 'reports' ? 
        <footer className={`${className}`}>
             
            <div>
                <div></div>
                <div>
                <FootNavigation />
                <FootLegal />
                <FootSocial />
                <p className="foot_iso"><a href="https://lsqa.com.uy/Institucional/Clientes"   target="_blank" rel="noreferrer" >SYSTEM CERTIFICATION ISO 9001 LSQA</a></p>
                </div>
            </div>
        </footer> : <> 
        <footer className={`${className}`}>
            <div> 
                <FootNavigation />
                <FootLegal />
                <FootSocial />
                <p className="foot_iso"><a href="https://lsqa.com.uy/Institucional/Clientes"   target="_blank" rel="noreferrer" >SYSTEM CERTIFICATION ISO 9001 LSQA</a></p>
            </div>
        </footer></>}
    </>)
}
 

function FootNavigation() {
    const { myLang } = useContext(LangContext)
    return (
        <nav className="foot_navigation">
            { myLang === 'es' && <> 
                <ul>
                    <li><p>Organización</p></li>
                    <li><a href="https://www.lacnic.net/966/1/lacnic/acerca-de-lacnic">Acerca de LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/491/1/lacnic/casa-de-internet">Casa de Internet</a></li>
                    <li><a href="https://www.lacnic.net/18/1/lacnic/reporte-anual">Reporte Anual</a></li>
                    <li><a href="https://www.lacnic.net/490/1/lacnic/empleo">Empleo</a></li>
                </ul>
                <ul>
                    <li><p>Contactar</p></li>
                    <li><a href="https://www.lacnic.net/3907/1/lacnic/sugerencias">Sugerencias</a></li>
                    <li><a href="https://www.lacnic.net/997/1/lacnic/listas-de-discusion">Listas de Discusión</a></li>
                    <li><a href="https://www.lacnic.net/492/1/lacnic/contacto">Contacto</a></li>
                    <li><a href="https://www.lacnic.net/1126/1/lacnic/faq">FAQ</a></li>
                </ul>
                <ul>
                    <li><p>The Number Resource Organization</p></li>
                    <li><a href="https://www.lacnic.net/5296/1/lacnic/nro-news">NRO News</a></li>
                    <li><a href="https://www.lacnic.net/2409/1/lacnic/comite-de-revision">Comité de Revisión</a></li>
                    <li><a href="https://www.lacnic.net/998/1/lacnic/aso-nro">ASO, NRO</a></li>
                </ul>
                <ul>
                    <li><p>Ciberseguridad</p></li>
                    <li><a href="https://www.lacnic.net/5297/1/lacnic/lacnic-csirt">LACNIC CSIRT</a></li>
                    <li><a href="https://www.lacnic.net/4660/1/lacnic/informacion-para-leas">Información para LEAs</a></li>
                    <li><a href="https://www.lacnic.net/5298/1/lacnic/reportar-incidente">Reportar Incidente</a></li>
                </ul> 
            </>} 
            { myLang === 'en' && <> 
                <ul>
                    <li><p>Organization</p></li>
                    <li><a href="https://www.lacnic.net/1004/2/lacnic/about-lacnic">About LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/629/2/lacnic/casa-de-internet">Casa de Internet</a></li>
                    <li><a href="https://www.lacnic.net/78/2/lacnic/annual-report">Annual Report</a></li>
                    <li><a href="https://www.lacnic.net/628/2/lacnic/job-opportunities">Job Opportunities</a></li>
                </ul>
                <ul>
                    <li><p>Contact us</p></li>
                    <li><a href="https://www.lacnic.net/3908/2/lacnic/suggestions">Suggestions</a></li>
                    <li><a href="https://www.lacnic.net/1035/2/lacnic/discussion-list">Discussion list</a></li>
                    <li><a href="https://www.lacnic.net/630/2/lacnic/contact-us">Contact us</a></li>
                    <li><a href="https://www.lacnic.net/1127/2/lacnic/faq">FAQ</a></li>
                </ul>
                <ul>
                    <li><p>The Number Resource Organization</p></li>
                    <li><a href="https://www.lacnic.net/5296/2/lacnic/nro-news">NRO News</a></li>
                    <li><a href="https://www.lacnic.net/2410/2/lacnic/review-committee">Review Committee</a></li>
                    <li><a href="https://www.lacnic.net/1036/2/lacnic/aso-nro">ASO, NRO</a></li>
                </ul>
                <ul>
                    <li><p>Cybersecurity</p></li>
                    <li><a href="https://www.lacnic.net/5297/2/lacnic/lacnic-csirt">LACNIC CSIRT</a></li>
                    <li><a href="https://www.lacnic.net/4661/2/lacnic/information-for-leas">Information for LEAs</a></li>
                    <li><a href="https://www.lacnic.net/5318/2/lacnic/incident-reporting">Incident Reporting</a></li>
                </ul> 
        </>}{myLang === 'pt' && <>
                <ul>
                    <li><p>Organização</p></li>
                    <li><a href="https://www.lacnic.net/1042/3/lacnic/acerca-do-lacnic">Acerca do LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/767/3/lacnic/casa-da-internet">Casa da Internet</a></li>
                    <li><a href="https://www.lacnic.net/48/3/lacnic/relatorio-anual">Relatório anual</a></li>
                    <li><a href="https://www.lacnic.net/766/3/lacnic/trabalho">Trabalho</a></li>
                </ul>
                <ul>
                    <li><p>Contato</p></li>
                    <li><a href="https://www.lacnic.net/3909/3/lacnic/sugestão">Sugestão</a></li>
                    <li><a href="https://www.lacnic.net/1073/3/lacnic/listas-de-discussão">Listas de discussão</a></li>
                    <li><a href="https://www.lacnic.net/768/3/lacnic/contate-nos">Contate-nos</a></li>
                    <li><a href="https://www.lacnic.net/1128/3/lacnic/faq">FAQ</a></li>
                </ul>
                <ul>
                    <li><p>The Number Resource Organization</p></li>
                    <li><a href="https://www.lacnic.net/5296/3/lacnic/nro-news">NRO News</a></li>
                    <li><a href="https://www.lacnic.net/2411/3/lacnic/comitê-de-revisão">Comitê de Revisão</a></li>
                    <li><a href="https://www.lacnic.net/1074/3/lacnic/aso-nro">ASO, NRO</a></li>
                </ul>
                <ul>
                    <li><p>Segurança</p></li>
                    <li><a href="https://www.lacnic.net/5297/3/lacnic/lacnic-csirt">LACNIC CSIRT</a></li>
                    <li><a href="https://www.lacnic.net/4662/3/lacnic/informacões-para-as-leas">Informações para as LEAs</a></li>
                    <li><a href="https://www.lacnic.net/5319/3/lacnic/relatorio-do-incidente">Relatório do Incidente</a></li>
                </ul>
            </>}
        </nav>)

}

function FootLegal() {
    const { myLang } = useContext(LangContext)
    return (
        <nav className="foot_legal">
            {myLang === 'es' && <>
                    <ul>
                        <li><p><a href="https://www.lacnic.net/967/1/lacnic/politica-de-calidad-de-lacnic">Política de Calidad de LACNIC</a></p></li>
                        <li><p><a href="https://www.lacnic.net/2923/1/lacnic/politica-de-privacidad">Política de privacidad</a></p></li>
                        <li><p><a href="https://www.lacnic.net/615/1/lacnic/transparencia">Transparencia</a></p></li>
                        <li><p><a href="https://www.lacnic.net/5299/1/lacnic/archivo">Archivo</a></p></li>
                    </ul>
                </>
            }{ myLang === 'en' && <>
                    <ul>
                        <li><p><a href="https://www.lacnic.net/1005/2/lacnic/lacnic-quality-policy">LACNIC Quality Policy</a></p></li>
                        <li><p><a href="https://www.lacnic.net/2924/2/lacnic/privacy-policy">Privacy Policy</a></p></li>
                        <li><p><a href="https://www.lacnic.net/753/2/lacnic/transparency">Transparency</a></p></li>
                        <li><p><a href="https://www.lacnic.net/5299/2/lacnic/archivo">Archivo</a></p></li>
                    </ul>

                </>
            } { myLang === 'pt' && <>
                    <ul>
                        <li><p><a href="https://www.lacnic.net/1043/3/lacnic/politica-de-qualidade-do-lacnic">Política de Qualidade do LACNIC</a></p></li>
                        <li><p><a href="https://www.lacnic.net/2925/3/lacnic/politica-de-privacidade">Política de privacidade</a></p></li>
                        <li><p><a href="https://www.lacnic.net/890/3/lacnic/transparência">Transparência</a></p></li>
                        <li><p><a href="https://www.lacnic.net/5299/3/lacnic/archivo">Archivo</a></p></li>
                    </ul>
                </>
            }</nav>)
}
function FootSocial() {
    const { myLang } = useContext(LangContext)
    return (
        <nav className="foot_social">
            <p className="es">
                {myLang === 'es' && 'Seguinos en'}
                {myLang === 'en' && 'Follow us'}
                {myLang === 'pt' && 'Siga-nos'}
            </p>
            <ul>
                <li><a href="https://twitter.com/lacnic"   target="_blank" rel="noreferrer" ><i className="fab fa-twitter" aria-hidden="true"></i><span>Twitter</span></a></li>
                <li><a href="https://facebook.com/lacnic"   target="_blank" rel="noreferrer" ><i className="fab fa-facebook" aria-hidden="true"></i><span>Facebook</span></a></li>
                <li><a href="https://www.instagram.com/lacnic/?hl=es-la"   target="_blank" rel="noreferrer" ><i className="fab fa-instagram" aria-hidden="true"></i><span>Instagram</span></a></li>
                <li><a href="https://uy.linkedin.com/company/lacnic"   target="_blank" rel="noreferrer" ><i className="fab fa-linkedin-in" aria-hidden="true"></i><span>LinkedIn</span></a></li>
                <li><a href="https://www.youtube.com/user/lacnicstaff"   target="_blank" rel="noreferrer" ><i className="fab fa-youtube" aria-hidden="true"></i><span>YouTube</span></a></li>
                <li><a href="https://www.lacnic.net/podcast"   target="_blank" rel="noreferrer" ><i className="fas fa-podcast" aria-hidden="true"></i><span>LACNIC Podcast</span></a></li>
            </ul>
        </nav>
    )
}