import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function DNSAnycast(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div> 
            <div>
                { myLang === 'es' && <> 
                    <h1>Tiempo de respuesta a la nube DNS anycast de LACTLD desde Latinoamérica y el Caribe</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Este estudio analiza el tiempo de respuesta desde distintos países de la región LAC hacia la nube DNS anycast de LACTLD, en especial, los que corresponden a países de nuestra región (ccTLD- country code TLD).</p>
                    <Button color="button_blue" to="/reportes/interconexion/lacnic-estudio-tiempo-respuesta-nube-dns-anycast-lactld-lac.pdf">Descargar (PDF)</Button>
                </>}
                { myLang === 'en' && <> 
                    <h1>Response Times from Latin America and the Caribbean to the LACTLD Anycast DNS Cloud</h1>
                    <p>Author: Hugo Salgado</p>
                    <p>This study analyzes response times from different countries in the LAC region to the LACTLD anycast DNS cloud, especially from countries in our region (ccTLD).</p>
                    <Button color="button_blue" to="/reportes/interconexion/response-times-from-lac-to-the-lactld-anycast-dns-cloud.pdf">Download (PDF)</Button>
                </> }
                { myLang === 'pt' && <> 
                    <h1>Tempo de resposta à nuvem DNS anycast do LACTLD desde a América Latina e o Caribe</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Este estudo analisa o tempo de resposta desde diferentes países da região da LAC para a nuvem DNS anycast do LACTLD, principalmente aqueles correspondentes a países da nossa região (ccTLD).</p>
                    <Button color="button_blue" to="/reportes/interconexion/tempo-de-resposta-a-nuvem-dns-anycast-do-lactld-desde-a-america-latina-e-o-caribe.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div>
    );
}

export default DNSAnycast;