import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Abstract() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' && <>
                    <h2>Abstract</h2>
                    <p>Este informe constituye una &uacute;nica fuente de informaci&oacute;n para entender de qu&eacute; se trata el IP <em>spoofing</em> y las problem&aacute;ticas asociadas al mismo. Constituye un punto de partida para generar conciencia en los asociados de LACNIC respecto a esta tem&aacute;tica, as&iacute; como en toda la comunidad.</p>
                    <p>&nbsp;A lo largo del informe se brindan los resultados del relevamiento realizado sobre la presencia de redes que permiten realizar IP <em>Spoofing</em> y que pueden ser utilizadas para realizar ataques en Am&eacute;rica Latina y el Caribe. Se describen los ataques m&aacute;s comunes, adem&aacute;s de la metodolog&iacute;a aplicada, las fuentes consultadas, los resultados recogidos en un relevamiento inicial, las principales recomendaciones para su correcci&oacute;n y las conclusiones obtenidas.</p>
                    <p>Tambi&eacute;n se ponen a disposici&oacute;n de la comunidad datos globales sobre el cumplimiento de buenas pr&aacute;cticas para mitigar el IP <em>spoofing </em>en la regi&oacute;n, seg&uacute;n las fuentes consultadas<em> Spoofer de CAIDA</em> y <em>Closed Resolver Project</em>.</p>
                </>}
                {myLang === 'en' && <>
                    <h2>Abstract</h2>
                    <p>This report is intended as a single source of information to understand IP spoofing and its related issues. It is a starting point to raise awareness on this topic, both among LACNIC members as well as throughout the community.</p>
                    <p>The report presents the results of the survey conducted to determine the existence of networks that enable IP spoofing, and which can be used to launch attacks in Latin America and the Caribbean. It describes the most common attacks, in addition to the methodology that was applied, the sources that were examined, the results that were obtained after an initial survey, the most relevant recommendations for their correction, and the conclusions of the study.</p>
                    <p>It also presents the community with global data on compliance with good practices to mitigate IP spoofing in the region, based on data obtained from the CAIDA Spoofer and the Closed Resolver Project.</p>
                </>}
                {myLang === 'pt' && <>
                    <h2>Resumo</h2>
                    <p>Este relat&oacute;rio constitui uma fonte &uacute;nica de informa&ccedil;&atilde;o para entender o que &eacute; o IP <em>spoofing</em> e os problemas associados a ele. Constitui um ponto de partida para conscientizar os associados do LACNIC, bem como a comunidade toda, sobre esta quest&atilde;o.</p>
                    <p>&nbsp;Ao longo do relat&oacute;rio, s&atilde;o apresentados os resultados da pesquisa realizada sobre a presen&ccedil;a de redes que permitem fazer IP <em>Spoofing</em> e que podem ser usadas para realizar ataques na Am&eacute;rica Latina e o Caribe. S&atilde;o descritos os ataques mais comuns, bem como a metodologia aplicada, as fontes consultadas, os resultados coletados em um levantamento inicial, as principais recomenda&ccedil;&otilde;es para a sua corre&ccedil;&atilde;o e as conclus&otilde;es obtidas.</p>
                    <p>Dados globais sobre o cumprimento de boas pr&aacute;ticas para mitigar o IP <em>spoofing</em> na regi&atilde;o tamb&eacute;m s&atilde;o disponibilizados &agrave; comunidade, segundo as fontes consultadas: <em>Spoofer de CAIDA</em> e <em>Closed Resolver Project</em>.</p>
                </>}
            </div>
        </div>
    )
}

export default Abstract


