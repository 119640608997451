import React, {useContext} from 'react';
import {LangContext} from '../../../context/DataContext'; 
import Button from '../../../components/Button' 

export default function Resumen(props) {
    const { myLang } = useContext(LangContext)
    return (
    <div>
        <div id="p1">
             
            {myLang === 'es' && <>  
                <div className='alerts alert_white'> 
                    <p>Autor: Alejandro D’Egidio</p>   
                    <p>Versión: 7</p>
                    <p>Fecha: 16/11/2019</p> 
                    <Button color="button_blue" to="/reportes/ipv6/lacnic-informe-cpe-ipv6.pdf">Descargar (PDF)</Button> 
                </div>
            </>} 
             
        </div>
    </div>
    );     
} 