import React from 'react';
import {AnimatePresence, motion} from 'framer-motion'; 
import '../css/modal.css'

export default function Modal({children, onClick, showModal}) { 
    return (  
        <AnimatePresence exitBeforeEnter>
            {showModal &&(
                <motion.div className="main_modal" >    
                    <div className="main_modal_before" onClick={onClick}></div>
                    <motion.div  className="main_modal_content">
                        {children}   
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence> 
    ) 
               
}