
import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Resultados() {
	const { myLang } = useContext(LangContext)
	return (
		<div>
			<div id="p5">
				{myLang === 'es' && <>
					<h2>Resultados</h2>
					<h3>Tiempos de respuesta regionales hacia cada root server</h3>
					<p>En un primer an&aacute;lisis, se promediaron los tiempos de respuesta (RTT, “round trip time”) hist&oacute;ricos desde todas las sondas de la regi&oacute;n hacia cada una de las letras de los root servers.</p>
					<p>Pese a ser una medida bastante gruesa y con bastantes particularidades debido a la diversidad de la regi&oacute;n, de todas formas permite tener una primera visi&oacute;n aproximada de la evoluci&oacute;n de cada letra, y es posible identificar inmediatamente las letras con mejor y peor desempe&ntilde;o.</p>
					<p>En los siguientes gr&aacute;ficos, cada punto y color representa una medida promedio de cada sonda, a trav&eacute;s del tiempo. En el eje X est&aacute; el tiempo, y en el Y se encuentra el tiempo de respuesta en milisegundos, cortado en los 300 ms, una medida que representa un tiempo l&iacute;mite de espera habitual.</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure2.png"
							alt="Ver" /></figure>
						<figcaption>Figura 2, tiempo de respuesta hist&oacute;rico desde LAC hacia root server F.</figcaption>
					</div>


					<p>Ac&aacute; vemos las medidas hacia el root server F. De inmediato es posible darse cuenta que en los primeros a&ntilde;os, hacia la izquierda del gr&aacute;fico, eran pocas las sondas disponibles. Luego a partir del a&ntilde;o 2014 ya se ven bastantes, pero dispersas en su respuesta, lo que indica que exist&iacute;a una alta variabilidad. Ya acerc&aacute;ndonos al a&ntilde;o 2019 vemos sin embargo que se comienzan a concentrar en la parte baja del gr&aacute;fico, lo que indica un buen resultado, ya que los tiempos de respuesta bajan y al mismo tiempo se pierde variabilidad, lo que demuestra estabilidad en las respuestas. En general se debe considerar que mientras m&aacute;s abajo y concentrado es un escenario mucho mejor. Tambi&eacute;n se pueden identificar momentos importantes, como por ejemplo una figura vertical en el primer trimestre del a&ntilde;o 2017, donde hubo una s&uacute;bita mejora. Eso de seguro representa alguna instalaci&oacute;n de una copia del root server F en nuestra regi&oacute;n, que ser&aacute; luego contrastada con datos oficiales obtenidos desde Internet Systems Consortium, el organismo que administra la letra F.</p>
					<p>En seguida viene un gr&aacute;fico similar para la letra L, administrada por Internet Corporation for Assigned Names and Numbers (ICANN), que tambi&eacute;n ha destacado por su continuo esfuerzo en desplegar copias en todo el mundo:</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure3.png"
							alt="Ver" /></figure>
						<figcaption>Figura 3, tiempo de respuesta hist&oacute;rico desde LAC hacia root server L.</figcaption>
					</div>

					<p>Ac&aacute; tambi&eacute;n se detecta una mejora continua y m&aacute;s estable a trav&eacute;s del tiempo, llegando a un promedio bajo los 50 ms en el a&ntilde;o 2017. Sin embargo se puede detectar un cluster cerca de los 150 ms en cierta regi&oacute;n, que deber&iacute;a ser analizada con detalle.</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure4.png"
							alt="Ver" /></figure>
						<figcaption>Figura 4, tiempo de respuesta hist&oacute;rico desde LAC hacia root server I.</figcaption>
					</div>

					<p>En el caso de I, se detecta un momento importante a mediados del a&ntilde;o 2016 donde hubo una mejora destacable, pero que fue revertida en el primer trimestre del 2017. Es importante indicar que estos casos podr&iacute;an representar problemas de ca&iacute;das de nodos o bien de ruteos poco eficientes, que ocasionar&iacute;an tiempos de respuesta altos durante unos momentos, pero que fueron resueltos adecuadamente hacia mediados del 2017.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure5.png"
							alt="Ver" /></figure>
						<figcaption>Figura 5, tiempo de respuesta hist&oacute;rico desde LAC hacia root server K.</figcaption>
					</div>

					<p>El root server K, de la organizaci&oacute;n RIPE NSS, es otro ejemplo de un buen despliegue y mejoras en la regi&oacute;n, como se nota en la figura previa con una concentraci&oacute;n progresiva de los puntos hacia abajo y la derecha.</p>
					<p>Por &uacute;ltimo, se presenta el caso del root server M, administrado por WIDE Project (Jap&oacute;n), que es el m&aacute;s alejado de la regi&oacute;n, como se puede apreciar en la gr&aacute;fica por su forma muy arriba en el eje Y:</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure6.png"
							alt="Ver" /></figure>
						<figcaption>Figura 6, tiempo de respuesta hist&oacute;rico desde LAC hacia root server M.</figcaption>
					</div>

					<p>Es importante sin embargo hacer notar que no es necesario que todas las letras sean cercanas. Un usuario final termina siendo atendido por el mejor root server que encuentre, por lo que aunque es siempre importante y m&aacute;s robusto tener la mayor cantidad de letras cercanas, tampoco existe problema si algunas de ellas est&aacute;n lejos. Cada organismo administrador de un root server puede tener distintas estrategias de despliegue mundial, apuntando a distintas regiones de servicio y distintas tecnolog&iacute;as, asunto que al contrario de ser un problema es una fortaleza del sistema distribuido del DNS, al permitir diversidad y evitar puntos de falla &uacute;nicos.</p>
					<p>El resto de gr&aacute;ficos hacia las otras letras se puede encontrar en el an&aacute;lisis completo indicado en el Anexo A.</p>
				</>}
				{myLang === 'en' && <>
					<h2>Results</h2>
					<h3>Regional Response Times for Each Root Server</h3>


					<p>A first analysis averaged the response times — the historical round-trip times (RTT) — of all the probes in the region when querying each root server.</p>
					<p>Despite being quite a rough measurement and one with many peculiarities due to the diversity of the region, it provides a first overview of the evolution of each root server and allows immediately identifying the letters with the best and worst performances.</p>
					<p>In the following charts, each point and color represent an average measurement for each probe over time. The x-axis represents time, while the y-axis represents response times in milliseconds up to 300 ms, a number that represents a typical waiting time limit.</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure2.png"
							alt="Ver" /></figure>
						<figcaption>Figure 2: Historical response times from LAC to the F-root server.</figcaption>
					</div>


					<p>This scatter diagram shows the measurements for the F-root server and immediately allows us to see that during the early years (towards the left of the chart) very few probes were available. Then, beginning in 2014, the figure shows more probes, yet their response times are scattered, which means there was a high variability. Finally, as the data approaches 2019, the points begin to concentrate in the lower part of the chart, which indicates a good result, as it represents lower response times and less variability, which in turn is indicative of the stability of the responses. In general, the lower and more concentrated the points on the scatter diagram, the better the scenario. It is also possible to identify important moments, such as a vertical line in the first trimester of 2017 resulting from a sudden improvement. This likely represents the installation of a copy of the F-root server in our region, a fact that will be verified against official data obtained from Internet Systems Consortium, the corporation that operates the F-root server.</p>
					<p>Below is a similar chart for the L-root server operated by the Internet Corporation for Assigned Names and Numbers (ICANN), an organization that is also noted for its constant efforts to deploy root server instances worldwide.</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure3.png"
							alt="Ver" /></figure>
						<figcaption>Figure 3: Historical response times from LAC to the L-root server.</figcaption>
					</div>

					<p>This chart also shows a constant and more stable evolution over time, with response times achieving an average of less than 50 ms in 2017. However, a cluster is detected close to 150 ms in a specific region that warrants further analysis.</p>

					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure4.png"
							alt="Ver" /></figure>
						<figcaption>Figure 4: Historical response times from LAC to the I-root server.</figcaption>
					</div>

					<p>In the case of the I-root server, the scatter diagram shows an important moment in mid-2016 when there was a notable improvement which later reverted in the third quarter of 2017. It is important to note that these situations may represent issues involving node failures or inefficient routing that might have increased response times for a few moments, but that were adequately solved by mid-2017.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure5.png"
							alt="Ver" /></figure>
						<figcaption>Figure 5: Historical response times from LAC to the K-root server.</figcaption>
					</div>

					<p>The K-root server operated by the RIPE NCC is another example of a good deployment and improvements in the region, as shown in the figure above with a progressive concentration of the points towards the lower right section of the chart.</p>
					<p>Finally, Figure 6 presents the case of the M-root server operated by the WIDE Project (Japan), which is the one furthest from the region as can be appreciated by the distance above the y-axis in the chart.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure6.png"
							alt="Ver" /></figure>
						<figcaption>Figure 6: Historical response times from LAC to the M-root server.</figcaption>
					</div>

					<p>However, it should be noted that it is not necessary for all the root servers (A to M) to be in close proximity. End users are served by the best root server they find, so, while it is always important and more robust to have as many root servers identified with different letters as possible, there is also no problem if some of these root servers are located far away. Each organization operating a root server can have different global deployment strategies targeting different service regions and different technologies. Instead of this being a problem, it is one of the strengths of the distributed DNS system, as it allows for diversity and avoids single points of failure.</p>
					<p>The charts for the remaining root servers can be found in the full analysis for which a link is provided in Annex A.</p>

				</>}
			</div>

		</div>
	)
}

export default Resultados
