import React, { useState, useContext } from "react";
import { LangContext } from "../context/DataContext";
import { NavLink } from "react-router-dom";

export default function MenuLateralReports(props) {

    const [clickMenu, establecerClickMenu] = useState(props.category);

    const abrirMenuLateral = (menu) => {
        establecerClickMenu(menu);
    };
    
     
     

    const { myLang } = useContext(LangContext); 
    return ( 
        <aside className={`lateral_menu_wrapper`}> 
            <nav>   
                {myLang === 'es' && <> 
                    <button onClick={() => abrirMenuLateral('ciberseguridad')} className={clickMenu === 'ciberseguridad' ? 'arrow_rotate' : null}>Ciberseguridad <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'ciberseguridad' ? 'active' : null}>
                        <li><NavLink to={`/es/ciberseguridad/estudio-anti-spoofing-en-la-region`}>Estudio anti-spoofing en la región</NavLink></li>
                        <li><NavLink to={`/es/ciberseguridad/una-historia-de-exito-en-ciberseguridad`}>Una historia de éxito en ciberseguridad</NavLink></li>
                        <li><NavLink to= {`/es/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos`}>Estudio de recursos de Internet presentes en la regióon de LACNIC con protocolos UDP que posibilitan ataques de tipo DDoS</NavLink></li>
                        <li><NavLink to={`/es/ciberseguridad/dns-open-resolvers-con-ipv4-que-involucran-recursos-bajo-la-administracion-de-lacnic`}>DNS open resolvers con IPv4 que involucran recursos bajo la administración de LACNIC</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('interconexion')} className={clickMenu === 'interconexion' ? 'arrow_rotate' : null}>Interconexión <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'interconexion' ? 'active' : null}>
                        <li><NavLink to={`/es/interconexion/mejores-practicas-para-ixp`}>Mejores prácticas para IXP</NavLink></li>
                        <li><NavLink to={`/es/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac`}>Análisis de los caminos del tráfico dentro de cada país de la región LAC</NavLink></li>
                        <li><NavLink to={`/es/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe`}>Uso de DNS-over-TLS en Latinoamérica y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/interconexion/optimizacion-de-redes-mediante-un-mejor-ibgp`}>Optimización de redes mediante un mejor iBGP</NavLink></li>
                        <li><NavLink to={`/es/interconexion/interconexion-bgp-en-lac`}>Interconexión BGP a nivel local en la región de AméricaLatina y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe`}>Interconexión BGP a nivel local en la región de AméricaLatina y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/interconexion/tiempo-de-respuesta-a-la-nube-dns-anycast-de-lactld-desde-latinoamerica-y-el-caribe`}>Tiempo de respuesta a la nube DNS anycast de LACTLD desde Latinoamérica y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/interconexion/conectividad-en-la-region-lac-2020`}>Conectividad en la región LAC: 2020</NavLink></li>
                        <li><NavLink to={`/es/interconexion/seguridad-en-el-ruteo-de-america-latina-y-el-caribe`}>Seguridad en el ruteo de América Latina y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/interconexion/uso-de-root-servers-del-dns-en-la-region`}>Uso de root servers del DNS en la región</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('ipv6')} className={clickMenu === 'ipv6' ? 'arrow_rotate' : null}>IPv6 <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'ipv6' ? 'active' : null}>
                        <li><NavLink to={`/es/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-2016-2020`}>Estadísticas y tendencias: el despliegue de IPv6 En América Latina y el Caribe 2016-2020</NavLink></li>
                        <li><NavLink to={`/es/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe`}>El comportamiento de operadores en el despliegue de IPv6 en América Latina y el Caribe</NavLink></li>
                        <li><NavLink to={`/es/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-1-2020`}>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020)</NavLink></li>
                        <li><NavLink to={`/es/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-2-2021`}>Informe sobre soporte de IPv6 en equipos CPE - Versión 2 (2021)</NavLink></li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <button onClick={() => abrirMenuLateral('ciberseguridad')} className={clickMenu === 'ciberseguridad' ? 'arrow_rotate' : null}>Cibersecurity <i className="fas fa-angle-down"></i></button> 
                    <ul className={clickMenu === 'ciberseguridad' ? 'active' : null}>
                        <li><NavLink to={`/en/ciberseguridad/estudio-anti-spoofing-en-la-region`}>Study on Anti-spoofing in the Region</NavLink></li>
                        <li><NavLink to={`/en/ciberseguridad/una-historia-de-exito-en-ciberseguridad`}>A Cybersecurity Success Story</NavLink></li>
                        <li><NavLink to= {`/en/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos`}>Study of Internet Resources in the LACNIC Service Region that Use UDP and Enable DDoS Attacks</NavLink></li>
                        <li><NavLink to={`/en/ciberseguridad/dns-open-resolvers-con-ipv4-que-involucran-recursos-bajo-la-administracion-de-lacnic`}>DNS IPv4 Open Resolvers Hosted on LACNIC-Managed Number Resources</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('interconexion')} className={clickMenu === 'interconexion' ? 'arrow_rotate' : null}>Interconnection <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'interconexion' ? 'active' : null}>
                        <li><NavLink to={`/en/interconexion/mejores-practicas-para-ixp`}>Best Practices for IXPs</NavLink></li>
                        <li><NavLink to={`/en/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac`}>Analysis of the paths followed by traffic within each country of LAC region</NavLink></li>
                        <li><NavLink to={`/en/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe`}>Use of DNS-over-TLS In Latin America and the Caribbean</NavLink></li>
                        <li><NavLink to={`/en/interconexion/optimizacion-de-redes-mediante-un-mejor-ibgp`}>Optimización de redes mediante un mejor iBGP</NavLink></li>
                        <li><NavLink to={`/en/interconexion/interconexion-bgp-en-lac`}>BGP Interconnection in the Region of Latin America and the Caribbean</NavLink></li>
                        <li><NavLink to={`/en/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe`}>Local BGP Interconnection in Latin America and the Caribbean</NavLink></li>
                        <li><NavLink to={`/en/interconexion/tiempo-de-respuesta-a-la-nube-dns-anycast-de-lactld-desde-latinoamerica-y-el-caribe`}>Response Times from Latin America and the Caribbean to the LACTLD Anycast DNS Cloud</NavLink></li>
                        <li><NavLink to={`/en/interconexion/conectividad-en-la-region-lac-2020`}>Connectivity in the LAC Region in 2020</NavLink></li> 
                        <li><NavLink to={`/en/interconexion/seguridad-en-el-ruteo-de-america-latina-y-el-caribe`}>Routing Security in Latin America and the Caribbean</NavLink></li>
                        <li><NavLink to={`/en/interconexion/uso-de-root-servers-del-dns-en-la-region`}>Use of DNS Root Servers in Latin America</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('ipv6')} className={clickMenu === 'ipv6' ? 'arrow_rotate' : null}>IPv6 <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'ipv6' ? 'active' : null}>
                        <li><NavLink to={`/en/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-2016-2020`}>Statistics And Trends: IPv6 Deployment In Latin America And The Caribbean 2016-2020</NavLink></li>
                        <li><NavLink to={`/en/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe`}>Operators’ Behavior In Relation To IPv6 Deployment In Latin America And The Caribbean</NavLink></li>
                        <li><NavLink to={`/en/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-1-2020`}>Informe sobre soporte de IPv6 en equipos CPE - Versión 1 (2020)</NavLink></li>
                        <li><NavLink to={`/en/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-2-2021`}>Informe sobre soporte de IPv6 en equipos CPE - Versión 2 (2021)</NavLink></li>
                    </ul>
                </>}
                {myLang === 'pt' && <>
                    <button onClick={() => abrirMenuLateral('ciberseguridad')} className={clickMenu === 'ciberseguridad' ? 'arrow_rotate' : null}>Cibersegurança <i className="fas fa-angle-down"></i></button> 
                    <ul className={clickMenu === 'ciberseguridad' ? 'active' : null}>
                        <li><NavLink to={`/pt/ciberseguridad/estudio-anti-spoofing-en-la-region`}>Estudo anti-spoofing na região</NavLink></li>
                        <li><NavLink to={`/pt/ciberseguridad/una-historia-de-exito-en-ciberseguridad`}>Una historia de éxito en ciberseguridad [espanhol]</NavLink></li>
                        <li><NavLink to= {`/pt/ciberseguridad/estudio-de-recursos-de-internet-de-la-region-lac-que-posibilitan-ataques-de-ddos`}>Estudo dos recursos da Internet presentes na região do LACNIC com protocolos UDP que possibilitam ataques do tipo DDoS</NavLink></li>
                        <li><NavLink to={`/pt/ciberseguridad/dns-open-resolvers-con-ipv4-que-involucran-recursos-bajo-la-administracion-de-lacnic`}>DNS open resolvers com IPv4 que envolvam recursos sob a administração do LACNIC</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('interconexion')} className={clickMenu === 'interconexion' ? 'arrow_rotate' : null}>Interconexão <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'interconexion' ? 'active' : null}>
                        <li><NavLink to={`/pt/interconexion/mejores-practicas-para-ixp`}>Melhores práticas para IXP </NavLink></li>
                        <li><NavLink to={`/pt/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac`}>Análise das rotas do tráfego dentro de cada país da região LAC</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe`}>Uso do DNS-over-TLS na América Latina e o Caribe</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/optimizacion-de-redes-mediante-un-mejor-ibgp`}>Optimización de redes mediante un mejor iBGP</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/interconexion-bgp-en-lac`}>Interconexão no BGP na região da América Latina e o Caribe</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe`}>Interconexão BGP no nível local na região da América Latina e o Caribe</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/tiempo-de-respuesta-a-la-nube-dns-anycast-de-lactld-desde-latinoamerica-y-el-caribe`}>Tempo de resposta à nuvem DNS anycast do LACTLD desde a América Latina e o Caribe</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/conectividad-en-la-region-lac-2020`}>Conectividade na região da LAC: 2020</NavLink></li> 
                        <li><NavLink to={`/pt/interconexion/seguridad-en-el-ruteo-de-america-latina-y-el-caribe`}>Routing Security in Latin America and the Caribbeane</NavLink></li>
                        <li><NavLink to={`/pt/interconexion/uso-de-root-servers-del-dns-en-la-region`}>Uso de root servers del DNS en la región</NavLink></li>
                    </ul>
                    <button onClick={() => abrirMenuLateral('ipv6')} className={clickMenu === 'ipv6' ? 'arrow_rotate' : null}>IPv6 <i className="fas fa-angle-down"></i></button>
                    <ul className={clickMenu === 'ipv6' ? 'active' : null}>
                        <li><NavLink to={`/pt/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-2016-2020`}>Estatísticas e tendências: a implementação do IPv6 na América Latina E O Caribe 2016-2020</NavLink></li>
                        <li><NavLink to={`/pt/ipv6/el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe`}>O comportamento das operadoras na implementação do IPv6 na América Latina e o Caribe</NavLink></li>
                        <li><NavLink to={`/pt/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-1-2020`}>Relatório sobre suporte do IPv6 em equipamentos CPE - Versión 1 (2020)</NavLink></li>
                        <li><NavLink to={`/pt/ipv6/informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-2-2021`}>Relatório sobre suporte do IPv6 em equipamentos CPE - Versión 2 (2021)</NavLink></li>
                    </ul>
                </>}
            </nav> 
        </aside> 
    )
}