import React, { useContext, useState, useRef, useEffect } from 'react';
import { LangContext } from '../../context/DataContext';
import { useParams, useLocation } from "react-router-dom"
import InterconexionBgpEnLaRegion from './InterconexionBgpEnLaRegion'
import SecondBGPLac from './SecondBgpLac';
import Reports from '../../components/Reports';
import DNSAnycast from './DNSAnycast';
import NoMatch from '../../components/NoMatch';
import RuteoLAC from './RuteoLAC';
import RootServer from './RootServer';
import InterconexionBgp from '../reports/InterconexionBgp/InterconexionBgp'
import Wrapper from '../../components/Wrapper';
import ConectividadEnLac from '../reports/ContectividadEnLac/ConectividadEnLac';
import ConectividadRegional from './ConectividadRegional'
import OptimizacionIBgp from './OptimizacionIBgp';
import OptimizacionRedesBgp from '../reports/OptimizacionRedesBgp/OptimizacionRedesBgp'
import InterconexionBgpLocal from '../reports/InterconexionBgpLocal/InterconexionBgpLocal'
import UsoRootServerLAC from '../reports/UsoRootServerLAC/UsoRootServerLAC';
import DNSOverTLSIntro from './DNSOverTLSIntro';
import DNSOverTLS from '../reports/DNSOverTLS/DNSOverTLS';
import EstudioConectividad2022 from './EstudioConectividad2022';
import Conectividad2022 from '../reports/Conectividad2022/Conectividad2022';
import ResumenMejoresPracticasIXP from './ResumenMejoresPracticasIXP';
import MejoresPracticasParaIXP from '../reports/MejoresPracticasParaIXP/MejoresPracticasParaIXP';

export default function Interconexion(props) {

    const uselocation = useLocation();
    const url = uselocation.pathname;
    const { handleLanguaje } = useContext(LangContext);

    if (url.includes(`/es/`) === true) {
        handleLanguaje('es')
        return (
            <ContentReport />
        )
    } if (url.includes(`/en/`) === true) {
        handleLanguaje('en')
        return (
            <ContentReport />
        )
    } if (url.includes(`/pt/`) === true) {
        handleLanguaje('pt')
        return (
            <ContentReport />
        )
    }
}

function ContentReport() {
    const { slug } = useParams(); 
    /* const [searchTerm, setSearchTerm] = useState('');
    const resultRefs = useRef([]);
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
  
    const generateRef = (index) => (ref) => {
      resultRefs.current[index] = ref;
    };
   

    const handleSearch = (e) => {
        e.preventDefault();
        // Lógica de búsqueda aquí
        console.log('Búsqueda:', searchTerm);
      
        // Encontrar todos los índices que coinciden
        const foundIndices = resultRefs.current.reduce((indices, ref, index) => {
          if (ref && ref.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
            indices.push(index);
          }
          return indices;
        }, []);
      
        // Desplazarse al siguiente resultado o al primer resultado si es el último
        if (foundIndices.length > 0) {
          const nextIndex = (currentResultIndex + 1) % foundIndices.length;
          resultRefs.current[foundIndices[nextIndex]]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
          setCurrentResultIndex(nextIndex);
        }
      };
      
      
  
      const highlightText = (text) => {
        // Verifica si hay un término de búsqueda antes de aplicar el resaltado
        if (searchTerm.trim() === '') {
          return text;
        }
      
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.split(regex).map((part, index) =>
          regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
      };
  
    const applyHighlightToChildren = (children) => {
      return React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ref: generateRef(index),
            children: applyHighlightToChildren(child.props.children),
          });
        } else if (typeof child === 'string') {
          return highlightText(child);
        } else {
          return child;
        }
      });
    };
  
    // Limpiar el índice del resultado actual cuando cambie el término de búsqueda
    useEffect(() => {
      setCurrentResultIndex(0);
    }, [searchTerm]);

     */
    const [searchTerm, setSearchTerm] = useState('');
  const resultRefs = useRef([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);

  const generateRef = (index) => (ref) => {
    if (ref) {
      resultRefs.current[index] = ref;
      ref.key = `result-${index}`;
    }
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    // Lógica de búsqueda aquí
    console.log('Búsqueda:', searchTerm);
  
    // Encontrar todos los índices que coinciden
    const foundIndices = resultRefs.current.reduce((indices, ref, index) => {
      if (ref && ref.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        indices.push(index);
      }
      return indices;
    }, []);
  
    // Desplazarse al siguiente resultado o al primer resultado si es el último
    if (foundIndices.length > 0) {
      const nextIndex = (currentResultIndex + 1) % foundIndices.length;
      resultRefs.current[foundIndices[nextIndex]]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      setCurrentResultIndex(nextIndex);
    }
  };
  

  const applyHighlightToChildren = (children) => {
    return React.Children.map(children, (child, index) => {
      const uniqueKey = `highlight-${index}`;
  
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          key: uniqueKey,
          ref: generateRef(index),
          children: applyHighlightToChildren(child.props.children),
        });
      } else if (typeof child === 'string') {
        return highlightText(child, uniqueKey);
      } else {
        return child;
      }
    });
  };
  
  const highlightText = (text, key) => {
    // Verifica si hay un término de búsqueda antes de aplicar el resaltado
    if (searchTerm.trim() === '') {
      return text;
    }
  
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <span key={`${key}-${index}`} className="highlight">{part}</span> : part
    );
  };

  // Limpiar el índice del resultado actual cuando cambie el término de búsqueda
  useEffect(() => {
    setCurrentResultIndex(0);
  }, [searchTerm]);
    if (slug === undefined) {
        return (
            <ReportMainWrapper>
                <div className="report_list">
                    <ResumenMejoresPracticasIXP />
                    <EstudioConectividad2022 setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} highlightText={highlightText}  />
                    <DNSOverTLSIntro setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} highlightText={highlightText} />
                    <OptimizacionIBgp />
                    <InterconexionBgpEnLaRegion />
                    <SecondBGPLac />
                    <DNSAnycast />
                    <ConectividadRegional />
                    <RuteoLAC />
                    <RootServer />
                </div>
            </ReportMainWrapper>
        )
    }
    if (slug === 'analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac') {
        return (
            <><ReportMainWrapperInterconexion setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} menu_lateral='estudio-de-conectividad-2022'>
                <Conectividad2022  applyHighlightToChildren={applyHighlightToChildren} resultRefs={resultRefs} />
            </ReportMainWrapperInterconexion> </>
        )
    }
    if (slug === 'uso-de-dns-over-tls-en-latinoamerica-y-el-caribe') {
        return (
            <ReportMainWrapperInterconexion setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch} menu_lateral='uso-de-dns-over-tls-en-latinoamerica-y-el-caribe'>
                <DNSOverTLS  applyHighlightToChildren={applyHighlightToChildren} />
            </ReportMainWrapperInterconexion>
        )
    }
    if (slug === 'optimizacion-de-redes-mediante-un-mejor-ibgp') {
        return (
            <ReportMainWrapperInterconexion menu_lateral='optimizacion-de-redes-mediante-un-mejor-ibgp'>
                <OptimizacionRedesBgp />
            </ReportMainWrapperInterconexion>
        )
    } if (slug === 'interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe') {
        return (
            <ReportMainWrapperInterconexion menu_lateral="interconexion-bgp-a-nivel-local">
                <InterconexionBgpLocal />
            </ReportMainWrapperInterconexion>
        )
    } if (slug === 'tiempo-de-respuesta-a-la-nube-dns-anycast-de-lactld-desde-latinoamerica-y-el-caribe') {
        return (
            <ReportMainWrapper>
                <DNSAnycast />
            </ReportMainWrapper>
        )
    } if (slug === 'seguridad-en-el-ruteo-de-america-latina-y-el-caribe') {
        return (
            <ReportMainWrapper>
                <RuteoLAC />
            </ReportMainWrapper>
        )
    } if (slug === 'uso-de-root-servers-del-dns-en-la-region') {
        return (
            <ReportMainWrapperInterconexion menu_lateral='uso-root-server-local'>
                <UsoRootServerLAC />
            </ReportMainWrapperInterconexion>
        )
    } if (slug === 'interconexion-bgp-en-lac') {
        return (
            <ReportMainWrapperInterconexion menu_lateral='interconexion-bgp-en-lac'>
                <InterconexionBgp />
            </ReportMainWrapperInterconexion>
        )
    }
    if (slug === 'conectividad-en-la-region-lac-2020') {
        return (
            <ReportMainWrapperInterconexion menu_lateral='conectividad-en-la-region-lac-2020'>
                <ConectividadEnLac />
            </ReportMainWrapperInterconexion>
        )
    }
    if (slug === 'mejores-practicas-para-ixp') {
      return (
          <ReportMainWrapperInterconexion menu_lateral='mejores-practicas-para-ixp'>
              <MejoresPracticasParaIXP />
          </ReportMainWrapperInterconexion>
      )
  }


    else return <NoMatch />
}

function ReportMainWrapperInterconexion({ children, menu_lateral, setSearchTerm, searchTerm, handleSearch }) {
    const { slug } = useParams();
    return (
        <Wrapper
            url_es={`/es/interconexion/${slug === undefined ? '' : slug}`}
            url_en={`/en/interconexion/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/interconexion/${slug === undefined ? '' : slug}`}
            menu_lateral={menu_lateral}
            setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearch={handleSearch}
        >
            <ReportTitle />
            {children}
        </Wrapper>
    )
}

function ReportMainWrapper({ children }) {
    const { slug } = useParams();
    return (
        <Reports category="interconexion"
            url_es={`/es/interconexion/${slug === undefined ? '' : slug}`}
            url_en={`/en/interconexion/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/interconexion/${slug === undefined ? '' : slug}`}>
            <ReportTitle />
            {children}
        </Reports>
    )
}

function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
        <p>
            {myLang === 'es' && <> Informes técnicos, Interconexión </>}
            {myLang === 'en' && <> Technical Reports, Interconnection </>}
            {myLang === 'pt' && <> Relatórios técnicos, Interconexão </>}
        </p>
    )
}