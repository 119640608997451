import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext';

export default function Comunidad() {

  return (
    <div id='p5'>
      <Title />
      <Table />
    </div>
  )
}


function Title() {
  const { myLang } = useContext(LangContext)
  return (
    <h2>
      {myLang === 'es' && 'Comunidad'}
      {myLang === 'en' && 'Community'}
      {myLang === 'pt' && 'Comunidade'}
    </h2>
  )
}

function Table() {
  const { myLang } = useContext(LangContext)
  return (
    <table className='table'>
      {myLang === 'es' && <>
        <tbody>
          <tr>
            <th>Prácticas Recomendadas como Mejores Prácticas</th>
            <th>Enlaces de Respaldo</th>
          </tr>
          <tr>
            <td>Participar de forma proactiva en los grupos locales y regionales referentes en el &aacute;mbito de las tecnolog&iacute;as para estar actualizado con respecto a nuevas tendencias en tecnolog&iacute;as, mejores pr&aacute;cticas y actualizaci&oacute;n de conocimiento, as&iacute; c&oacute;mo, mantener una red de trabajo con diversos aliados que fomenten el crecimiento y robustez del servicio brindado por el IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Dise&ntilde;ar servicios en l&iacute;nea que sirvan a los ciudadanos y, al mismo tiempo, impulsen su uso, aumenten la cualificaci&oacute;n y el empleo de los programadores locales y los contenidos se alojen en centros de datos locales.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Organizar eventos locales de formaci&oacute;n y foros de peering que ayuden con el fortalecimiento de la comunidad t&eacute;cnica y promuevan las buenas pr&aacute;cticas entre sus miembros para la protecci&oacute;n de Internet.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/">ISOC</a></td>
          </tr>
          <tr>
            <td>Eliminar las barreras de entrada para el funcionamiento de los IXP y el peering, y promover el desarrollo de la comunidad de abajo arriba, el desarrollo de la comunidad y el apoyo a los IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Animar a todos los proveedores de acceso y contenidos de su pa&iacute;s, incluidos los operadores tradicionales, a participar en los debates sobre peering, interconexi&oacute;n y IXP impulsados por la comunidad. El personal de redes y TI de las administraciones p&uacute;blicas necesita formaci&oacute;n avanzada, por lo que tambi&eacute;n deber&iacute;a anim&aacute;rseles a participar en estas actividades e iniciativas de formaci&oacute;n t&eacute;cnico-comunitaria.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Ayudar a promover un ecosistema vibrante, que incluya una protecci&oacute;n equilibrada de la propiedad intelectual, una fiscalidad justa y el acceso a la financiaci&oacute;n, para que los empresarios de &eacute;xito y una mano de obra cualificada se queden y no se marchen a agrupaciones de alta tecnolog&iacute;a.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
        </tbody>
      </>}
      {myLang === 'en' && <>
        <tbody>
          <tr>
            <th>Recommended Best Practices</th>
            <th>Supporting links</th>
          </tr>
          <tr>
            <td>Proactively engage in local and regional technology groups to keep up with new trends, best practices, and knowledge, and maintain a network of allies to promote growth and robustness of the service provided by the IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Design online services to serve citizens while also driving use, increasing skills and employment for local programmers, and content hosted at local datacenters.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Organize local training events and peering forums that help to strengthen the technical community, and promote best practices among their members to protect the Internet.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/">ISOC</a></td>
          </tr>
          <tr>
            <td>Remove barriers to entry for IXP operation and peering, and promote bottom-up community development and support for IXPs.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Encourage all access and content providers in their country, including incumbent operators, to participate in community-driven peering, interconnection, and IXP discussions. Government network and IT staff need advanced training, and should also be encouraged to take part in these technical community training activities and initiatives.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Help to promote a vibrant ecosystem, including balanced intellectual property protection, fair taxation, and access to finance, so that successful entrepreneurs and a newly skilled workforce will stay and not depart for established high-tech clusters.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
        </tbody>
      </>}
      {myLang === 'pt' && <>
        <tbody>
          <tr>
            <th>Práticas recomendadas como melhores práticas</th> 
            <th>Links de backup</th>
          </tr>
          <tr>
            <td>Participar de forma proativa de grupos locais e regionais de refer&ecirc;ncia no dom&iacute;nio das tecnologias para estar atualizado em rela&ccedil;&atilde;o &agrave;s novas tend&ecirc;ncias em tecnologias, melhores pr&aacute;ticas e atualiza&ccedil;&atilde;o de conhecimentos, bem como manter uma rede de trabalho com diversos aliados que promovam o crescimento e robustez do servi&ccedil;o prestado pelo IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2021/06/Internet-Peering-in-Asia-Pacific-EN.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Desenhar servi&ccedil;os on-line que atendam aos cidad&atilde;os e, ao mesmo tempo, impulsionem o seu uso, aumentem as qualifica&ccedil;&otilde;es e o emprego dos programadores locais e os conte&uacute;dos se hospedem em centros de dados locais.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Organizar eventos locais de forma&ccedil;&atilde;o e f&oacute;runs de peering que ajudem a fortalecer a comunidade t&eacute;cnica e a promover as boas pr&aacute;ticas entre seus membros para a prote&ccedil;&atilde;o da Internet.</td>
            <td><a href="https://www.internetsociety.org/es/blog/2021/06/estrategias-exitosas-para-crear-y-hacer-crecer-los-ixp/">ISOC</a></td>
          </tr>
          <tr>
            <td>Remover as barreiras de entrada para o funcionamento dosIXP e o peering; e promover o desenvolvimento da comunidade de baixo para cima, o desenvolvimento da comunidade e o apoio aos IXP.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Encorajar todos os provedores de acesso e conte&uacute;dos do seu pa&iacute;s, incluindo as operadoras tradicionais, a participarem dos debates sobre peering, interconex&atilde;o e IXP impulsionados pela comunidade. O pessoal de redes e TI das administra&ccedil;&otilde;es p&uacute;blicas precisa de forma&ccedil;&atilde;o avan&ccedil;ada, pelo que deveriam ser encorajados a participarem nessas atividades e iniciativas de forma&ccedil;&atilde;o t&eacute;cnico-comunit&aacute;ria.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
          <tr>
            <td>Ajudar a promover um ecossistema vibrante, que inclua uma prote&ccedil;&atilde;o equilibrada da propriedade intelectual, uma tributa&ccedil;&atilde;o justa e o acesso ao financiamento, para que os empres&aacute;rios bem-sucedidos e a m&atilde;o de obra qualificada permane&ccedil;am e n&atilde;o partam para agrupamentos de alta tecnologia.</td>
            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf">ISOC</a></td>
          </tr>
        </tbody>
      </>}
    </table>
  )
}
