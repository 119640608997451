import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Objetivos() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p3">
                {myLang === 'es' && <>
                    <h2>Objetivos</h2>
                    <p>Este estudio propone analizar las distintas caracter&iacute;sticas de la interconexi&oacute;n de la regi&oacute;n, bas&aacute;ndose en el procesamiento de tablas de ruteo BGP de IXP que operan en pa&iacute;ses de Latinoam&eacute;rica y el Caribe, con el fin de comparar caracter&iacute;sticas b&aacute;sicas de las publicaciones de rutas de la regi&oacute;n; obtener conclusiones sobre el comportamiento de los operadores en la publicaci&oacute;n de prefijos; conocer la cantidad de sistemas aut&oacute;nomos publicados en los IXP, qu&eacute; porcentaje de los prefijos de un pa&iacute;s se ven en el IXP  y su impacto en la estabilidad del ruteo regional, entre otros factores.</p>
                    <p>De esta manera se determinaron un conjunto de par&aacute;metros para comparar el estado actual y la evoluci&oacute;n de los IXP regionales, con los siguientes objetivos:</p>
                    <ul>
                        <li>AS path promedio, m&aacute;ximo y m&iacute;nimo hacia todos los AS de cada pa&iacute;s de LACNIC. Tambi&eacute;n hacia cada regi&oacute;n RIR.</li>
                        <li>Datos an&aacute;logos a los de las tablas globales para comparar resultados.</li>
                        <li>Cu&aacute;ntos prefijos se publican en los IXP, tanto en IPv4 como en IPv6.</li>
                        <li>Cu&aacute;ntos ASN est&aacute;n publicando sus rutas en el IXP.</li>
                        <li>Qu&eacute; cantidad de prefijos y/o ASN que se ven en las tablas globales de BGP tambi&eacute;n aparecen en las tablas locales del IXP.</li>
                        <li>Cu&aacute;les de los IXP cuentan con datos BGP p&uacute;blicos.</li>
                        <li>Cu&aacute;ntos operadores de cada pa&iacute;s est&aacute;n conectados a un IXP.</li>
                        <li>Prependings presentes en las tablas.</li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>Goals</h2>
                    <p>This study seeks to analyze various characteristics of regional interconnection by processing the BGP routing tables of IXPs operating in Latin America and the Caribbean. Its purpose is to compare basic characteristics of the routes published in the region, draw meaningful conclusions on the behavior of operators in publishing prefixes, and determine, among others, the number of autonomous systems published at the IXPs, the proportion of each country's prefixes connected to each IXP, and their impact on regional routing stability.</p>
                    <p>With this in mind, we determined certain parameters to compare the current status and evolution of regional IXPs:</p>
                    <ul>
                        <li>Average, maximum, and minimum AS path to all the ASs in every country in the LACNIC service region and to each RIR region.</li>
                        <li>Data similar to the data contained in global routing tables to allow the comparison of results.</li>
                        <li>How many IPv4 and IPv6 prefixes are published by the IXPs.</li>
                        <li>How many ASNs are announcing their routes at an IXP.</li>
                        <li>How many prefixes and/or ASNs are visible on the global BGP routing tables as well as on local IXP’s routing tables.</li>
                        <li>Which IXPs have public BGP information.</li>
                        <li>How many operators in each country are connected to an IXP.</li>
                        <li>Number of prependings in the routing tables.</li>
                    </ul>
                </>}
                {myLang === 'pt' &&
                    <>
                        <h2>Objetivos</h2>
                        <p>Este estudo prop&otilde;e analisar as diferentes caracter&iacute;sticas da interconex&atilde;o da regi&atilde;o, com base no processamento de tabelas de roteamento BGP de IXP que operam em pa&iacute;ses da Am&eacute;rica Latina e o Caribe. Tem por objetivo comparar caracter&iacute;sticas b&aacute;sicas das publica&ccedil;&otilde;es de rotas da regi&atilde;o, obter conclus&otilde;es sobre o comportamento das operadoras na publica&ccedil;&atilde;o de prefixos, conhecer a quantidade de sistemas aut&ocirc;nomos publicados nos IXP, qual a porcentagem dos prefixos de um pa&iacute;s que s&atilde;o vistos no IXP e seu impacto na estabilidade do roteamento regional, entre outros fatores.</p>
                        <p>Desta forma, foram determinados par&acirc;metros para comparar o estado atual e a evolu&ccedil;&atilde;o dos IXP regionais, com os seguintes objetivos:</p>

                        <ul>
                            <li>AS Path m&eacute;dio, m&aacute;ximo e m&iacute;nimo para todos os AS de cada pa&iacute;s do LACNIC. Tamb&eacute;m para cada regi&atilde;o RIR.</li>
                            <li>Dados an&aacute;logos a tabelas globais para comparar resultados.</li>
                            <li>Quantos prefixos s&atilde;o publicados nos IXP, tanto no IPv4 quanto no IPv6.</li>
                            <li>Quantos ASN est&atilde;o publicando suas rotas no IXP.</li>
                            <li>Quantos prefixos e/ou ASN vistos nas tabelas globais BGP tamb&eacute;m aparecem nas tabelas locais do IXP.</li>
                            <li>Quais dos IXP t&ecirc;m dados BGP p&uacute;blicos.</li>
                            <li>Quantas operadoras de cada pa&iacute;s est&atilde;o conectadas a um IXP.</li>
                            <li>Prependings presentes nas tabelas.</li>
                        </ul>

                    </>
                }
            </div>

        </div>
    )
}

export default Objetivos
