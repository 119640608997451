import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function TiemposDeRespuestaNacionales() {
	const { myLang } = useContext(LangContext)
	return (
		<div>
			<div id="p6">
				{myLang === 'es' && <>
					<h2>Tiempos de respuesta nacionales hacia cada root server</h2>
					<p>Un segundo an&aacute;lisis consider&oacute; la misma medida de tiempo de respuesta RTT pero desagregado por pa&iacute;s. Al igual que en los gr&aacute;ficos anteriores, cada punto y color representa una medida promedio de cada sonda, a trav&eacute;s del tiempo. En el eje X est&aacute; el tiempo, y en el Y se encuentra el tiempo de respuesta en milisegundos, cortado en los 300 ms.</p>
					<p>El disponer este grado de detalle permite identificar de mejor manera el momento y ubicaci&oacute;n de cada copia. Sin embargo ac&aacute; se hace mucho m&aacute;s evidente la diferencia de cobertura de las sondas RIPE Atlas en cada pa&iacute;s, debido a que existen algunos como Argentina, Brasil, Chile, M&eacute;xico y Uruguay con una penetraci&oacute;n mejor y que por lo mismo permiten mejores an&aacute;lisis, pero otros con una densidad muy baja de sondas, en las cuales es muy dif&iacute;cil identificar o extrapolar resultados.</p>
					<p>Se presentan ac&aacute; los resultados m&aacute;s interesantes. Para un resultado completo de cada pa&iacute;s, consultar la informaci&oacute;n en el Anexo A.</p>
					<p>El siguiente gr&aacute;fico representa los tiempos de respuesta de las sondas en Brasil hacia el root server E:</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure7.png"
							alt="Ver" /></figure>
						<figcaption>Figura 7, tiempo de respuesta hist&oacute;rico desde Brasil hacia root server E.</figcaption>
					</div>

					<p>Es muy claro en la figura que a fines del a&ntilde;o 2017 hubo una mejora notable en los tiempos de respuesta, pasando de un promedio de ~150 ms a menos de 50 ms, lo que seguramente representa la instalaci&oacute;n de una copia de E en el pa&iacute;s.</p>
					<p>Otro caso interesante de destacar es el acceso a B desde Chile:</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure8.png"
							alt="Ver" /></figure>
						<figcaption>Figura 8, tiempo de respuesta hist&oacute;rico desde Chile hacia root server B.</figcaption>
					</div>

					<p>En esta figura se nota claramente la mejora a mediados del a&ntilde;o 2019, que est&aacute; corroborado por el hecho que la organizaci&oacute;n Information Sciences Institute de la University of Southern California, administradora del root server B, instal&oacute; por primera vez un nodo fuera de USA en esta fecha, justamente en una ciudad en el norte de Chile.</p>
					<p>Por &uacute;ltimo, ac&aacute; se ve claramente el beneficio para un pa&iacute;s de la instalaci&oacute;n de un nodo en su territorio. A mediados del a&ntilde;o 2015, LACNIC di&oacute; inicio al proyecto +RAICES con la instalaci&oacute;n de una copia del root server F en Montevideo:</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure9.png"
							alt="Ver" /></figure>
						<figcaption>Figura 9, tiempo de respuesta hist&oacute;rico desde Uruguay hacia root server F.</figcaption>
					</div>
				</>}
				{myLang === 'en' && <>
					<h2>National Response Times for Each Root Server</h2>
					<p>A second analysis also considered response time measurements (RTT), but in this case the data was disaggregated by country. Just as in the preceding charts, each point and color represent an average measurement for each probe over time. The x-axis represents time, while the y-axis represents response times in milliseconds up to 300 ms.</p>
					<p>This level of detail allows better identifying when and where each copy was deployed. However, in this case, the difference in the coverage achieved by RIPE Atlas probes in each country is much more evident: in certain countries such as Argentina, Brazil, Chile, Mexico and Uruguay penetration rates are higher and therefore allow a better analysis, while in others probe density is very low so it is very difficult to identify or extrapolate results.</p>
					<p>For the full results for each country, see the information provided in Annex A.</p>
					<p>The following figure shows the response times of the probes installed in Brazil when querying the E-root server.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure7.png"
							alt="Ver" /></figure>
						<figcaption>Figure 7: Historical response times from LAC to the E-root server.</figcaption>
					</div>

					<p>This figure clearly shows that response times experienced a notable improvement in late 2017, decreasing from an average of 150 ms to less than 50 ms, which is most likely the result of the installation of an E-root server copy in the country.</p>
					<p>Another interesting case worth noting is access to the B-root server from Chile.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure8.png"
							alt="Ver" /></figure>
						<figcaption>Figure 8: Historical response times from Chile to the B-root server.</figcaption>
					</div>

					<p>This figure clearly shows an improvement in mid-2019, corroborated by the fact that the Information Sciences Institute of the University of Southern California — operator of the B-root server — first installed a node outside the United States on this date, precisely in a city in northern Chile.</p>
					<p>Finally, Figure 9 clearly shows how a country benefits from the installation of a node in its territory. In mid-2015, LACNIC launched its +RAICES Project with the installation of a copy of the F-root server in Montevideo.</p>
					<div>
						<figure><img src="/images/uso-root-server-en-lac/figure9.png"
							alt="Ver" /></figure>
						<figcaption>Figure 9: Historical response times from Uruguay to the F-root server.</figcaption>
					</div>

				</>}
			</div>

		</div>
	)
}

export default TiemposDeRespuestaNacionales
