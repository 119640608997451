import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function EstadisticasTendencias2020(props) {
    const { myLang } = useContext( LangContext )
    return ( 
        <div>
            <div> 
                { myLang === 'es' && <> 
                    <h1>Estadísticas y tendencias: el despliegue de IPv6 En América Latina y el Caribe 2016-2020 </h1>
                    <p>Autores: Consultora SMC+ Digital Public Affairs (EEUU).</p>
                    <p>El presente trabajo es parte de la investigación sobre despliegue de IPv6 en América Latina y el Caribe 2020/2021. Ese estudio, creado y liderado por LACNIC, estuvo a cargo de la consultora SMC+ Digital Public Affairs (EEUU). </p>
                    <Button color="button_blue" to="/reportes/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-lac-2016-2020-es.pdf">Descargar (PDF)</Button>
                </>}
                { myLang === 'en' && <> 
                    <h1>Statistics And Trends: IPv6 Deployment In Latin America And The Caribbean 2016-2020  </h1>
                    <p>Author: SMC+ Digital Public Affairs (USA)</p>
                    <p>This paper is part of the Study on IPv6 Deployment in Latin America and the Caribbean 2020-2021. The study was created and led by LACNIC and conducted by SMC+ Digital Public Affairs, a consultancy firm based in the United States.</p>
                    <Button color="button_blue" to="/reportes/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-lac-2016-2020-en.pdf">Download (PDF)</Button>
                </>}
                { myLang === 'pt' && <> 
                    <h1>Estatísticas e tendências: a implementação do IPv6 na América Latina E O Caribe 2016-2020 </h1>
                    <p>Autor: SMC+ Digital Public Affairs (EUA)</p>
                    <p>Este trabalho faz parte da pesquisa sobre a implementação do IPv6 na América Latina e o Caribe 2020/2021. Este estudo, elaborado e liderado por LACNIC, esteve sob a responsabilidade da consultoria SMC + Digital Public Affairs (EUA).</p>
                    <Button color="button_blue" to="/reportes/ipv6/estadisticas-y-tendencias-el-despliegue-de-ipv6-en-lac-2016-2020-pt.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div> 
    );
}

export default EstadisticasTendencias2020;