import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

export default function DNSOpenResolvers() { 
    const { myLang } = useContext(LangContext);
    return (
        <div>
            <div> 
                {myLang === 'es' && <>
                    <h1>DNS open resolvers con IPv4 que involucran recursos bajo la administración de LACNIC</h1>
                    <p>Autores: CSIRT CEDIA, LACNIC CSIRT</p>
                    <p>El CSIRT de LACNIC y el CSIRT de CEDIA realizaron un estudio para identificar servidores de DNS abiertos asociados a una dirección IPv4, para informar a los asociados que tienen asignados estos recursos de la situación, sugerir alternativas para corregir la configuración de estos e intentar disminuir de forma significativa la cantidad de open resolvers en nuestra región.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-dns-open-resolvers-ipv4-es.pdf">Descargar (PDF)</Button>
                </>}
                {myLang === 'en' && <>
                    <h1>DNS IPv4 Open Resolvers Hosted on LACNIC-Managed Number Resources</h1>
                    <p>Author: CEDIA CSIRT and LACNIC CSIRT</p>
                    <p>LACNIC CSIRT and CEDIA CSIRT conducted a study to identify open DNS servers associated with an IPv4 address in order to inform the members who were assigned these resources of the situation, suggest alternatives to correct the configuration of their servers, and try to significantly reduce the number of open resolvers in our region.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-dns-open-resolvers-ipv4-en.pdf">Download (PDF)</Button>
                </>}
                {myLang === 'pt' && <>
                    <h1>DNS open resolvers com IPv4 que envolvam recursos sob a administração do LACNIC</h1>
                    <p>Autor: CSIRT CEDIA, LACNIC CSIRT</p>
                    <p>O CSIRT do LACNIC e o CSIRT de CEDIA realizaram um estudo para identificar servidores de DNS abertos associados a um endereço IPv4, a fim de informar os associados que possuem esses recursos designados da situação, assim como sugerir alternativas para corrigir a sua configuração e tentar reduzir significativamente o número de open resolvers na nossa região.</p>
                    <Button color="button_blue" to="/reportes/ciberseguridad/lacnic-dns-open-resolvers-ipv4-pt.pdf">Download (PDF)</Button>
                </>}
            </div>
        </div>
    )
}