import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import {Link} from 'react-router-dom' 

function ConectividadRegional(props) {
    const { myLang } = useContext( LangContext )
    return ( 
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Conectividad en la región LAC: 2020</h1>
                    <p>Autor: Agustín Formoso</p>
                    <p>Cuando hablamos de conectividad, ¿de qué estamos hablando? Desde que las redes se conectan unas con las otras, los operadores han estado trabajando para que la conectividad entre ellas sea mejor. Este artículo presenta un estudio de conectividad y explica cómo se midió la conectividad de la región LAC y cómo ha evolucionado durante los últimos años.</p>
                    <Link className="buttons button_blue" to="/es/interconexion/conectividad-en-la-region-lac-2020">Leer Reporte</Link>
                </>}
                {myLang === 'en' && <> 
                    <h1>Connectivity in the LAC Region in 2020</h1>
                    <p>Author: Agustín Formoso</p>
                    <p>We often talk about connectivity, but what is it that we are actually talking about? From the moment networks were connected to one other, operators have been working to improve this connectivity between them. In this article, we will present a connectivity study explaining how we measure connectivity in the region of Latin America and the Caribbean and how this has evolved in recent years.</p>
                    <Link className="buttons button_blue" to="/en/interconexion/conectividad-en-la-region-lac-2020">Read Report</Link> 
                </>}
                {myLang === 'pt' && <> 
                    <h1>Conectividade na região da LAC: 2020</h1>
                    <p>Autor: Agustín Formoso</p>
                    <p>Do que falamos, quando falamos em conectividade? Desde que as redes estão conectadas umas com as outras, as operadoras têm trabalhado para melhorar a conectividade entre elas. Este artigo apresenta uma pesquisa sobre conectividade e explica como foi medida a conectividade da região da LAC e como ela evoluiu nos últimos anos.</p>
                    <Link className="buttons button_blue" to="/pt/interconexion/conectividad-en-la-region-lac-2020">Leia o Relatório</Link>  
                </>}
            </div> 
        </div>  
    );
}

export default ConectividadRegional;