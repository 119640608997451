import React, { useContext, forwardRef } from 'react';
import Button from '../../../components/Button';
import { LangContext } from '../../../context/DataContext'; 
 
const Resumen = forwardRef(({ applyHighlightToChildren }, ref) => {
    const { myLang } = useContext(LangContext);
     
    return (
        <>{applyHighlightToChildren([ 
        <div>
            <div id='p1' className="alerts alert_white">
                {myLang === 'es' && <>
                    <p>Autor: Hugo Salgado</p> 
                    <p>Octubre de 2022</p>
                    <Button color="button_blue" to="/reportes/interconexion/uso-de-dns-over-tls-en-lac-es.pdf">DESCARGAR (PDF)</Button>
                </>}
                {myLang === 'en' && <>
                    <p>Author: Hugo Salgado</p> 
                    <p>October2022</p>
                    <Button color="button_blue" to="/reportes/interconexion/uso-de-dns-over-tls-en-lac-en.pdf">DOWNLOAD (PDF)</Button>
                </>}
                {myLang === 'pt' && <>
                    <p>Autor: Hugo Salgado</p> 
                    <p>Outubro de 2022</p>
                    <Button color="button_blue" to="/reportes/interconexion/uso-de-dns-over-tls-en-lac-pt.pdf">DOWNLOAD (PDF)</Button>
                </>}
            </div>
        </div>
        ])}</>
    );
});

export default Resumen;
