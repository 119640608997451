import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

export default function Servicio() {
    return (
        <div id='p2'>
            <Title />
            <Table />
        </div>
    )
}

function Title() {
    const { myLang } = useContext(LangContext)
    return (
        <h2>
            {myLang === 'es' && 'Servicio'}
            {myLang === 'en' && 'Service'}
            {myLang === 'pt' && 'Serviço'}
        </h2>
    )
}

function Table() {
    const { myLang } = useContext(LangContext)
    return (
        <table className="table">
            {myLang === 'es' &&

                <tbody>
                    <tr>
                        <th>Pr&aacute;cticas Recomendadas como Mejores Pr&aacute;cticas</th>
                        <th>Enlaces de Respaldo</th>
                    </tr>
                    <tr>
                        <td>Elaborar protocolos de respuesta de incidentes ante eventualidades en el IXP y ante eventualidades para con sus miembros.</td>
                        <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                    </tr>
                    <tr>
                        <td>Establecer par&aacute;metros que midan la capacidad de respuesta ante eventualidades de seguridad interna del IXP.</td>
                        <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                    </tr>
                    <tr>
                        <td>Establecer par&aacute;metros que midan la capacidad de respuesta ante eventualidades de los miembros del IXP.</td>
                        <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                    </tr>
                    <tr>
                        <td>Ofrecer servicios como NTP, servidores de ruta, servidores DNS de dominio de nivel superior y servidores ra&iacute;z DNS . Esto generalmente se hace en un AS-Number separado, para que los servicios se comporten como un cliente IXP propio.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Brindar servicios de Looking Glass con una vista interactiva de las tablas de enrutamiento desde un enrutador de peering.&nbsp;</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Ofrecer estad&iacute;sticas, que pueden contener gr&aacute;ficos de tr&aacute;fico de puertos, vistas de tr&aacute;fico de interconexi&oacute;n, matrices de interconexi&oacute;n, etc.&nbsp;</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Informar en el sitio web sobre pol&iacute;ticas de conexi&oacute;n, precios y otros temas de inter&eacute;s.&nbsp;</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Para el p&uacute;blico en general, se recomienda incluir una sesi&oacute;n en el sitio web del IXP, en el que se explique: Qu&eacute; es un IXP, historia y trayectoria del IXP y qu&eacute; se encuentra haciendo actualmente o cu&aacute;l es su estado actual, por qu&eacute; las entidades se conectan a &eacute;l y c&oacute;mo esto ayuda a la Internet en general. Un glosario de t&eacute;rminos y enlaces a otros sitios relacionados tambi&eacute;n puede ser un valor agregado para su sitio web.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/your-audience/general-public/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>El idioma que se ofrecer&aacute; en el sitio web y en la atenci&oacute;n del IXP, depende en gran medida del grupo objetivo al que el IXP quiera dirigirse, pero, en general, y para atraer a participantes internacionales tambi&eacute;n, se debe poder seleccionar una versi&oacute;n en ingl&eacute;s.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Una &uacute;nica fuente de informaci&oacute;n f&aacute;cil de recordar es una ventaja. Por lo tanto, se recomienda tener una p&aacute;gina web para proporcionar informaci&oacute;n de alcance p&uacute;blico y ofrecer m&aacute;s informaci&oacute;n privilegiada relacionada con los participantes y IXP despu&eacute;s de iniciar sesi&oacute;n en un &aacute;rea del portal de la p&aacute;gina web.</td>
                        <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                    </tr>
                    <tr>
                        <td>Conectar&nbsp; servicios de entidades p&uacute;blicas&nbsp; a los IXP locales para incentivar la conexi&oacute;n de los ISP y ofrecer a los ciudadanos un acceso m&aacute;s fiable a los servicios p&uacute;blicos.</td>
                        <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noreferrer">ISOC</a></td>
                    </tr>
                    <tr>
                        <td>Formar parte activa de los comit&eacute;s nacionales de respuesta y prevenci&oacute;n de incidentes.</td>
                        <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                    </tr>
                    <tr>
                        <td>Mantener informados a sus miembros acerca de la operaci&oacute;n administrativa y financiera del IXP para fomentar la transparencia, creando al menos dos informes anuales que puedan permanecer accesibles a los miembros.</td>
                        <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                    </tr>
                    <tr>
                        <td>Contar con una plataforma din&aacute;mica que contenga informaci&oacute;n (dividida para el p&uacute;blico en general y para los miembros del IXP).</td>
                        <td><a href="https://www.ixpmanager.org/about" target="_blank" rel="noreferrer">IXP Manager - About</a></td>
                    </tr>
                </tbody>


            }
            {
                myLang === 'en' &&
                <>
                    <tbody>
                        <tr>
                            <th>Recommended Best Practices</th>

                            <th>Supporting links</th>
                        </tr>
                        <tr>
                            <td>Develop incident response protocols within the IXP and with its members.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Establish parameters to measure the IXP's capacity to respond to internal security events.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Establish parameters to measure the IXP's capacity to respond to security events affecting their members.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Provide services like NTP, Route Servers, Top Level Domain DNS Servers, and DNS-Root-Servers. This is usually done in a separate AS-Number, so that the services behave like an IXP customer of its own.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Provide Looking Glass services with an interactive view on the routing-tables from a peering router.&nbsp;</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Offer various forms of statistics, which may contain port traffic graphs, peering traffic views, peering matrices, etc.&nbsp;</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Inform about connection policies, pricing, and other topics of interest on the IXP's website.&nbsp;</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>For the general public, it is recommended to include on the IXP's website a section explaining what an IXP is, the story/history of the IXP and what it is doing today, why entities connect to it, and how this helps the Internet at large. A glossary of terms and links to other related sites may also be a value addition to your website.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/your-audience/general-public/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Which languages to offer on the IXP's website and support strongly depends on the target group the IXP wishes to address, but generally and to attract international participants, an English version should also be selectable.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>An easy to remember single source of information is an advantage. Therefore, it is recommended to have one webpage to provide information with public scope and offer more IXP participant-related and inside information after login within a portal area of the webpage.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Connect e-government services to local IXPs to provide an incentive for ISPs to connect and provide more reliable access to government services for citizens.</td>
                            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noreferrer">ISOC</a></td>
                        </tr>
                        <tr>
                            <td>Actively participate in national incident response and prevention committees.</td>
                            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Keep members informed about the administrative and financial operation of the IXP to promote transparency, preparingat least two annual reports that can remain accessible to members.</td>
                            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Have a dynamic platform with information (separated into information for the public in general and information for IXP members).</td>
                            <td><a href="https://www.ixpmanager.org/about" target="_blank" rel="noreferrer">IXP Manager - About</a></td>
                        </tr>
                    </tbody>
                </>
            }
            {
                myLang === 'pt' &&
                <>
                    <tbody>
                        <tr>
                            <th>Práticas recomendadas como melhores práticas</th>

                            <th>Links de backup</th>
                        </tr>
                        <tr>
                            <td>Elaborar protocolos de resposta a incidentes perante eventualidades no IXP, bem como eventualidades com seus membros.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Estabelecer par&acirc;metros que me&ccedil;am a capacidade de resposta perante eventualidades de seguran&ccedil;a interna do IXP.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Estabelecer par&acirc;metros que me&ccedil;am a capacidade de resposta perante eventualidades dos membros do IXP.</td>
                            <td><a href="https://www.lacnic.net/innovaportal/file/3135/1/manrs-ixpp.pdf" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Oferecer servi&ccedil;os como NTP, servidores de rota, servidores DNS de dom&iacute;nio de n&iacute;vel superior e servidores raiz DNS. Isso geralmente &eacute; feito em um AS-Number separado, para que os servi&ccedil;os se comportem como um cliente IXP pr&oacute;prio.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Fornecer servi&ccedil;os de Looking Glass com uma vis&atilde;o interativa das tabelas de roteamento desde um roteador de peering.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Oferecer estat&iacute;sticas, que podem conter gr&aacute;ficos de tr&aacute;fego de portas, visualiza&ccedil;&otilde;es de tr&aacute;fego de interconex&atilde;o, matrizes de interconex&atilde;o, etc.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Informar no site sobre pol&iacute;ticas de conex&atilde;o, pre&ccedil;os e outros temas de interesse.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-services-dmz/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Para o p&uacute;blico em geral, recomenda-se a inclus&atilde;o de uma sess&atilde;o no site do IXP, explicando: o que &eacute; um IXP, hist&oacute;ria e trajet&oacute;ria do IXP e o que est&aacute; fazendo atualmente ou qual &eacute; seu status atual, por que as entidades se conectam a ele e como isso ajuda a Internet em geral. Um gloss&aacute;rio de termos e links para outros sites relacionados tamb&eacute;m pode ser um valor agregado para seu site.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/your-audience/general-public/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>O idioma oferecido no site e no atendimento do IXP depende em grande parte do grupo-alvo ao que o IXP pretende abordar, mas, em geral, e para atrair tamb&eacute;m participantes internacionais, deve ser poss&iacute;vel selecionar um vers&atilde;o em ingl&ecirc;s.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Uma fonte de informa&ccedil;&otilde;es &uacute;nica e f&aacute;cil de lembrar &eacute; uma vantagem. Portanto, &eacute; recomend&aacute;vel ter uma p&aacute;gina web para fornecer informa&ccedil;&otilde;es de divulga&ccedil;&atilde;o p&uacute;blica e oferecer mais informa&ccedil;&otilde;es privilegiadas relacionadas aos participantes e ao IXP ap&oacute;s fazer login em uma &aacute;rea do portal da p&aacute;gina web.</td>
                            <td><a href="https://www.euro-ix.net/en/forixps/set-ixp/ixp-bcops/ixp-website/other-considerations/" target="_blank" rel="noreferrer">EURO-IX</a></td>
                        </tr>
                        <tr>
                            <td>Conectar servi&ccedil;os de entidades p&uacute;blicas a IXP locais para incentivar a conex&atilde;o dos ISP e oferecer aos cidad&atilde;os um acesso mais confi&aacute;vel aos servi&ccedil;os p&uacute;blicos.</td>
                            <td><a href="https://www.internetsociety.org/wp-content/uploads/2017/08/bp-EnablingEnvironment-20170411-en.pdf" target="_blank" rel="noreferrer">ISOC</a></td>
                        </tr>
                        <tr>
                            <td>Ser parte ativa dos comit&ecirc;s nacionais de resposta e preven&ccedil;&atilde;o de incidentes.</td>
                            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC - MANRS</a></td>
                        </tr>
                        <tr>
                            <td>Manter os seus associados informados sobre a opera&ccedil;&atilde;o administrativa e financeira do IXP para promover a transpar&ecirc;ncia, criando pelo menos dois relat&oacute;rios anuais que possam permanecer acess&iacute;veis aos membros.</td>
                            <td><a href="https://www.lacnic.net/3886/1/lacnic/manrs-nos-ayuda-a-la-prevencion-de-incidentes-de-seguridad" target="_blank" rel="noreferrer">LACNIC</a></td>
                        </tr>
                        <tr>
                            <td>Dispor de uma plataforma din&acirc;mica que contenha informa&ccedil;&otilde;es (divididas para o p&uacute;blico em geral e para os associados do IXP).</td>
                            <td><a href="https://www.ixpmanager.org/about" target="_blank" rel="noreferrer">IXP Manager - About</a></td>
                        </tr>
                    </tbody>
                </>
            }
        </table>
    )
}