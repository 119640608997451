import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext';  

function MenuLateralAntiSpoofing() { 
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return ( 
    <> 
            {myLang === 'es' && 
            <nav>
                <button>Estudio anti-spoofing en la región <i className="fas fa-angle-down"></i></button> 
                <ul className="active">
                    <li><a href="#resumen" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumen ejecutivo</a></li>
                    <li><a href="#abstract" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Abstract</a></li>   
                    <li><a href="#objetivo" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Objetivo</a></li>
                    <li><a href="#motivación" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Motivación</a></li>
                    <li><a href="#ip-spoofing" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>IP Spoofing</a></li>
                    <li><a href="#fuentes" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Fuentes de informaci&oacute;n disponibles</a></li>
                    <li><a href="#sav" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>&iquest;C&oacute;mo determinar si su organizaci&oacute;n implementa filtros de tipo SAV?</a></li>
                    <li><a href="#analisis" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>An&aacute;lisis de implementaci&oacute;n de pol&iacute;ticas <em>antispoofing</em> en la regi&oacute;n LAC</a></li>
                    <li><a href="#recomendaciones" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Recomendaciones</a></li>
                    <li><a href="#conclusiones" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')}>Conclusiones</a></li>
                </ul>
            </nav>
            } 
            {myLang === 'en' && 
            <nav>
                <button>Study on Anti-spoofing in the Region <i className="fas fa-angle-down"></i></button> 
                <ul className="active">
                <li><a href="#executive-summary" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Executive Summary</a></li>
                    <li><a href="#abstract" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Abstract</a></li>   
                    <li><a href="#goal" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Goal</a></li>
                    <li><a href="#motivation" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Motivation</a></li>
                    <li><a href="#ip-spoofing" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>IP Spoofing</a></li>
                    <li><a href="#sources" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Available sources of information</a></li>
                    <li><a href="#sav" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>How to determine whether your organization implements SAV</a></li>
                    <li><a href="#analysis" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Analysis of the implementation of anti-spoofing policies in the LAC region</a></li>
                    <li><a href="#recommendations" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Recommendations</a></li>
                    <li><a href="#conclusions" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')}>Conclusions</a></li>
                </ul>
            </nav>
            } 
            {myLang === 'pt' && 
            <nav>
                <button>Estudio anti-spoofing en la región <i className="fas fa-angle-down"></i></button> 
                <ul className="active">
                    <li><a href="#resumo-executivo" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Resumo executivo</a></li>
                    <li><a href="#resumo" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Resumo</a></li>   
                    <li><a href="#objetivo" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Objetivo</a></li>
                    <li><a href="#motivacao" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Motivação</a></li>
                    <li><a href="#ip-spoofing" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>IP Spoofing</a></li>
                    <li><a href="#fontes" className={activeAnchor === 'p6' ? 'active' : null} onClick={() => scrollToAnchor('p6')}>Fontes de informações disponíveis</a></li>
                    <li><a href="#sav" className={activeAnchor === 'p7' ? 'active' : null} onClick={() => scrollToAnchor('p7')}>Como determinar se sua organização implementa filtros do tipo SAV?</a></li>
                    <li><a href="#analise" className={activeAnchor === 'p8' ? 'active' : null} onClick={() => scrollToAnchor('p8')}>Análise de implementação de políticas antispoofing na região da ALC</a></li>
                    <li><a href="#recomendacoes" className={activeAnchor === 'p9' ? 'active' : null} onClick={() => scrollToAnchor('p9')}>Recomendações</a></li>
                    <li><a href="#conclusoes" className={activeAnchor === 'p10' ? 'active' : null} onClick={() => scrollToAnchor('p10')}>Conclusões</a></li>
                </ul>
            </nav>
            } 
        </>
    );
}

export default MenuLateralAntiSpoofing;