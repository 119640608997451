import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Metodologia() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p4">
                {myLang === 'es' && <>
                    <h2>Metodolog&iacute;a</h2>
                    <h3>Fuente de datos</h3>

                    <p>Existen proyectos que colectan la informaci&oacute;n sobre las tablas de ruteo BGP vistas desde distintos puntos a nivel global, tales como como los proyectos <a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris/ris-raw-data" target="_blank" rel="noreferrer">RIS</a> de RIPE NCC o <a href="http://www.routeviews.org/routeviews/index.php/collectors/" target="_blank" rel="noreferrer">Route Views</a> de la Universidad de Oregon. Estos proyectos cuentan con colectores BGP instalados en distintas partes del mundo, generalmente ubicados en sitios con gran cantidad de operadores, los cuales establecen sesiones BGP con el colector y publican todas sus rutas BGP. De esa forma se puede contar con una visi&oacute;n de lo que pasa en las tablas globales de BGP.</p>
                    <p>A nivel local, sin embargo, la situaci&oacute;n es distinta. En particular, en la regi&oacute;n de LAC hay pocos colectores de los proyectos mencionados, por lo que la informaci&oacute;n sobre el comportamiento de BGP a nivel de la regi&oacute;n y de cada pa&iacute;s es poco conocida. Las tablas de BGP de los operadores no pueden accederse externamente, por lo que los peerings o interconexiones privadas no se pueden determinar de una manera directa. No obstante, una parte importante del tr&aacute;fico de cada pa&iacute;s se concentra en los IXP, por lo que es posible a partir de all&iacute; obtener informaci&oacute;n de interconexi&oacute;n a nivel local.  </p>
                    <p>El trabajo utiliz&oacute; dos fuentes distintas para obtener las tablas BGP de distintos IXP que operan en territorios comprendidos por LACNIC. En primer lugar, <a href="https://www.pch.net" target="_blank" rel="noreferrer" >PCH</a>, que opera con colectores de rutas en m&aacute;s de 100 IXPs alrededor del mundo. Los datos obtenidos se publican con el fin de aportar a la comunidad de investigadores y operadores de red. El portal mantiene un repositorio con dos tipos distintos de informaci&oacute;n complementaria. Para los fines de procesamiento de este estudio nos interesan las im&aacute;genes o snapshots diarias del resultado del comando “show ip bgp” que se obtiene en cada uno de los routers colectores de PCH. As&iacute;, se obtiene el estado exacto de la tabla de ruteo BGP en el momento exacto que se toma la snapshot.</p>
                    <p>Por otro lado, LACNIC como parte de un acuerdo con las organizaciones LAC-IX e <a href="https://www.internetsociety.org" target="_blank" rel="noreferrer" >Internet Society</a> est&aacute; apoyando a los IXP de la regi&oacute;n y fortaleciendo su infraestructura. En ese marco se han instalado servicios en los IXP dentro de los cuales est&aacute;n los servidores de rutas centrales (route servers) y colectores BGP conectados a ellos, lo que permite obtener las tablas BGP tal como las ven el resto de los miembros del IXP. Esta informaci&oacute;n es volcada peri&oacute;dicamente en archivos mediante un full dump cuatro veces al d&iacute;a y mediante una actualizaci&oacute;n cada cinco minutos de los mensajes update de BGP.</p>
                    <div>
                        <figcaption>Figura 1: Pa&iacute;ses comprendidos en este estudio en base a cantidad de IXP con los que se cuenta informaci&oacute;n.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure1.png" alt="Ver" /></figure>
                        <figcaption>Fuente: Elaboraci&oacute;n propia.</figcaption>
                    </div>

                    <h3>Procesamiento de la informaci&oacute;n</h3>
                    <p>Para lograr los objetivos planteados, se realiz&oacute; el procesamiento de la informaci&oacute;n de manera similar a la metodolog&iacute;a utilizada en el reporte 2020. En este caso hubo que tener en cuenta las diferencias respecto a utilizar tablas BGP locales (provenientes de IXP) y no de colectores globales. No obstante, la clasificaci&oacute;n de los AS es mucho m&aacute;s simple: el AS de origen sigue siendo relevante para conocer el alcance de cada IXP, pero adem&aacute;s nos interesa el AS peer, es decir el par adyacente que le anuncia la ruta al IXP.</p>
                    <div>
                        <figcaption>Figura 2: Representaci&oacute;n del anuncio BGP de una ruta que se propaga entre sistemas aut&oacute;nomos hasta llegar a un IXP.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure2.png" alt="Ver" /></figure>
                        <figcaption>Fuente: Elaboraci&oacute;n propia.</figcaption>
                    </div>

                    <p>En la figura 2, se puede observar el AS-1, el cual anuncia sus prefijos mediante BGP al AS-2 y este propaga dicho anuncio hacia los dem&aacute;s sistemas aut&oacute;nomos hasta llegar al IXP. De este anuncio interesan el AS-1 (origen) y AS-N (peer). Cabe aclarar que cada AS puede estar registrado en un territorio distinto. Esta informaci&oacute;n es de suma importancia para conocer c&oacute;mo se interconectan los operadores de red en la regi&oacute;n, aunque para fines pr&aacute;cticos, el estudio se centrar&aacute; en el pa&iacute;s asociado al AS de origen (el naranja en la figura) y el peer (el rojo) de cada ruta.</p>
                    <p>Para obtener esta informaci&oacute;n, se procesan las tablas de enrutamiento de cada IXP  como se menciona en  la secci&oacute;n de Fuente de Datos. Independientemente de utilizar tablas provistas por PCH o por el repositorio de LACNIC, en ambos caso es posible obtener el conjunto de pares de prefijo y as-path de la siguiente forma:</p>
                    <p>Prefijo/longitud | AS-N … AS-3 AS-2 AS-1</p>
                    <p>Se observa el AS path de derecha a izquierda, ya que interesa conocer el sistema aut&oacute;nomo de origen del prefijo, e ir obteniendo a partir de &eacute;ste los dem&aacute;s sistemas que son relevantes seg&uacute;n se reflej&oacute; en la figura 2.</p>
                    <p>Como ya se indic&oacute;, interesa el primer y &uacute;ltimo prefijo de cada ruta, aunque la longitud de la ruta tambi&eacute;n ser&aacute; &uacute;til. Adem&aacute;s, para darle sentido a esta informaci&oacute;n, es necesario asociar cada prefijo y sistema aut&oacute;nomo a un pa&iacute;s. Por lo que se utilizar&aacute; el archivo de estad&iacute;sticas delegated-extended que los RIRs publican, para poder lograr esta asociaci&oacute;n entre recursos y territorio. As&iacute;, cada entrada puede interpretarse como:</p>
                    <p>[Pa&iacute;s del prefijo] | [Pa&iacute;s AS-N] … [Pa&iacute;s AS-3] [Pa&iacute;s AS-2] [Pa&iacute;s AS-1]</p>
                    <p>En este caso, interesa cada pa&iacute;s de los AS-1, as&iacute; se conoce el alcance de cada IXP. Es probable que muchas rutas terminen con AS de origen en el mismo pa&iacute;s del IXP, pero tambi&eacute;n se interconectar&aacute; con otros pa&iacute;ses en funci&oacute;n de su magnitud. En este estudio, importa la interconexi&oacute;n a los pa&iacute;ses cubiertos por LACNIC, pero tambi&eacute;n se van a registrar a los AS de origen que est&aacute;n registrados en AFRINIC, APNIC, ARIN, y RIPE NCC, agrup&aacute;ndolos en su regi&oacute;n correspondiente.</p>
                    <p>Para realizar todo el procesamiento de esta informaci&oacute;n, se recurre a distintas librer&iacute;as disponibles para el lenguaje Python 3. Incluyendo la herramienta <a href="https://bgpstream.caida.org/docs/api/pybgpstream" target="_blank" rel="noreferrer">pyBGPStream</a> para procesar las im&aacute;genes en formato MRT almacenadas en LACNIC. Adem&aacute;s, se utilizan nuevamente los archivos delegated-extended consolidados, que contienen una actualizaci&oacute;n diaria del estado de la distribuci&oacute;n de los recursos num&eacute;ricos de Internet (Direcciones IPv4 e IPv6, y N&uacute;meros de Sistemas Aut&oacute;nomos) para poder asociar dichos identificadores, presentes en las tablas BGP, a los distintos pa&iacute;ses. El formato de este archivo est&aacute; estandarizado por la Number Resource Organization <a href="https://www.nro.net" target="_blank" rel="noreferrer">(NRO)</a>.</p>

                    <h3>Datasets generados</h3>
                    <div>
                        <figcaption>Figura 3: Fuentes y flujo de procesamiento de datos para conocer acerca de la interconexi&oacute;n utilizando colectores globales.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure3.png" alt="Ver" /></figure>
                        <figcaption>Fuente: Elaboraci&oacute;n propia.</figcaption>
                    </div>

                    <p>Todos los datos necesarios para cumplir con los objetivos planteados inicialmente, se almacenan en datasets lo cual abre la posibilidad de realizar otros estudios m&aacute;s profundos o con otros enfoques. Se mantiene el sistema de trabajo utilizado en el estudio 2020 y esquematizado en la figura 3: se estableci&oacute; el desarrollo de un conjunto de scripts que realizan el procesamiento en etapas con resultados parciales hasta obtener un dataset final con los datos resumidos. De hecho, los datasets generados en ese estudio son parte de la fuente de datos para generar estos nuevos datasets.</p>
                    <div>
                        <figcaption>Figura 4: Fuentes y flujo de procesamiento de datos para conocer acerca de la interconexi&oacute;n de IXP.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure4.png" alt="Ver" /></figure>
                        <figcaption>Fuente: Elaboraci&oacute;n propia.</figcaption>
                    </div>

                    <p>En la figura 4, puede observarse el flujo de datos y procesamiento que se realiza en este nuevo estudio. Su resultado es un conjunto de datasets que contienen informaci&oacute;n sobre el ruteo de los distintos IXP de Latinoam&eacute;rica, en un determinado d&iacute;a. Estos datasets son:</p>

                    <table className='table'>
                        <tr><td>
                            <p>bgp-table-{'<ixp>-<t>'}.csv</p>
                            <p>Como la fuente de las tablas de ruteo de los IXP se puede encontrar en distintos formatos, en este dataset se almacena toda la tabla solo con la informaci&oacute;n que nos es relevante: prefijo, as-path, pa&iacute;s del prefijo, pa&iacute;ses de cada AS del as-path.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-routing-{'<ixp>-<t>'}.csv</p>
                            <p>Indica todos los prefijos anunciados por cada AS de origen que son alcanzados por el IXP, tambi&eacute;n registra a trav&eacute;s de cual AS peer llegan.</p>
                        </td></tr>
                        <tr><td>
                            <p>aspath-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Registra una tabla de frecuencias en base a la longitud de los as-path, hacia cada pa&iacute;s (o regi&oacute;n fuera de LACNIC).</p>
                        </td></tr>
                        <tr><td>
                            <p>prepend-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Registra una tabla de frecuencias en base a la longitud de prependings presentes en la tabla del IXP.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-{'<ixp>-<t>'}.csv</p>
                            <p>Muestra a nivel de conjunto la intersecci&oacute;n de AS del pa&iacute;s que est&aacute;n presentes en la tabla del IXP contra  los que est&aacute;n presentes en la tabla de los colectores globales. Se hace la misma comparaci&oacute;n de conjuntos tambi&eacute;n para los prefijos IPv4 e IPv6.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Resume la informaci&oacute;n de country-coverage-{'<ixp>-<t>'}.csv mostrando solo la cantidad de recursos en cada caso.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Agrupa informaci&oacute;n de ixp-routing-{'<ixp>-<t>'}.csv y aspath-freq-{'<ixp>-<t>'}.csv; para obtener un conjunto de datos relevantes sobre el IXP respecto a cada pa&iacute;s.</p>
                        </td></tr>
                    </table>

                    <p>Estos datos resultantes, poseen toda la informaci&oacute;n necesaria que se analizar&aacute; en las siguientes secciones de este informe. La fecha en la que se obtuvieron los datos para su procesamiento fue el 1 de noviembre de 2021.</p>

                    <h3>Descarga de los datasets</h3>
                    <p>El c&oacute;digo de todos los scripts est&aacute; publicado en el mismo repositorio de c&oacute;digo github utilizado para el estudio <a href="https://github.com/LACNIC/bgplac-toolkit" target="_blank" rel="noreferrer">2020</a>.</p>

                </>}
                {myLang === 'en' && <>
                    <h2>Methodology</h2>
                    <h3>Data Sources</h3>

                    <p>Projects such as the RIPE NCC's Routing Information Service (<a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris/ris-raw-data" target="_blank" rel="noreferrer">RIS</a>) and the University of <a href="http://www.routeviews.org/routeviews/index.php/collectors/" target="_blank" rel="noreferrer"> Oregon's Route-Views</a> collect information about the BGP routing tables as seen from various locations worldwide. These projects have installed BGP collectors in various parts of the world —typically at locations with a large number of operators— that establish BGP sessions with the collector and publish all their BGP routes. This allows seeing what is going on in the global BGP tables.</p>
                    <p>The situation, however, is different at the local level. In particular, the number of such collectors in Latin America and the Caribbean is limited, so there is very little available information regarding BGP behavior at the regional level and within each country in the LAC region.</p>
                    <p>The operators’ BGP tables cannot be accessed externally, so peering relationships or private interconnections cannot be determined directly. However, IXPs concentrate a significant portion of each country's traffic, and this makes it possible to gather information on local interconnection.</p>
                    <p>The study used two different sources to obtain the BGP tables of different IXPs operating in the LACNIC service region. First, Packet Clearing House (<a href="https://www.pch.net" target="_blank" rel="noreferrer">PCH</a>),  which operates route collectors at more than one hundred IXPs around the world. Data from these route collectors is made available publicly for the benefit of the Internet's operational and research communities. The portal maintains two different, but complementary, kinds of data from these route collectors. For the purpose of this study, we are interested in the daily snapshots of the results of “show ip bgp” on each PCH route collector. These capture the state of the routing table at the moment in time that the snapshot is taken.</p>

                    <p>In addition, as part of an agreement with LAC-IX and the <a href="https://www.internetsociety.org" target="_blank" rel="noreferrer">Internet Society</a>, LACNIC is supporting IXPs in the region and strengthening their infrastructure. Within this framework, services including route servers and BGP collectors connected to them have been installed at various IXPs. This allows obtaining the BGP tables as they are seen by the rest of the members of the IXP. A full dump of this information is performed four times a day, with BGP update messages every five minutes.</p>
                    <div>
                        <figcaption>Figure 1: Countries included in this study based on the number of IXPs (according to available information).</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure1.png" alt="Ver" /></figure>
                        <figcaption>Source: Prepared by the authors</figcaption>
                    </div>

                    <h3>Data Processing</h3>
                    <p>The methodology used to process the data was similar to the methodology used for the 2020 report. In this case, the fact that local BGP tables (obtained from IXPs) were used rather than global collectors had to be considered. However, the classification of the Autonomous Systems is much simpler: the origin AS is still important for determining the reach of each IXP, yet we are also interested in the peer AS, i.e., the adjacent pair that announces the route to the IXP.</p>
                    <div>
                        <figcaption>Figure 2: Representation of the BGP announcement of a route that is propagated from one autonomous system to another until reaching an IXP</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure2.png" alt="Ver" /></figure>
                        <figcaption>Source: Prepared by the authors</figcaption>
                    </div>

                    <p>Figure 2 shows AS-1, which announces its prefixes via BGP to AS-2 which, in turn, propagates this announcement to the other autonomous systems until reaching the IXP. In this announcement, AS-1 (origin) and AS-N (peer) are of interest. It should be noted that these ASs may be registered in different countries. While this information is extremely important to understand how network operators interconnect in the region, for practical purposes the study will focus on the country associated with the origin AS (orange) and peer (red) AS of each route.</p>
                    <p>To obtain this information, each IXP's routing tables are processed as mentioned in the Data Sources section. No matter whether the tables provided by PCH or those in the LACNIC repository are used, the set of prefix and AS path pairs can be obtained as follows:</p>
                    <p>Prefix/length | AS-N … AS-3 AS-2 AS-1</p>
                    <p>We will analyze the AS path from right to left, as we wish to determine the origin autonomous system and, from there, the other relevant systems as described in Figure 2.</p>
                    <p>As mentioned earlier, we are interested in the first and the last prefixes of each route, although route length will also be useful. Additionally, in order to make sense of this information, each prefix and autonomous system must be mapped to a country. Therefore, to establish the link between resources and countries, we will use the delegated-extended statistics file published by the RIRs. Thus, each entry can be interpreted as follows:</p>
                    <p>[Country of the prefix] | [Country of AS-N] … [Country of AS-3] [Country of AS-2] [Country of AS-1]</p>
                    <p>In this case, all the countries liked to an AS-1 is of interest, as they allow determining the reach of each IXP. Many routes are likely to end with an origin AS in the same country where the IXP is located but, depending on their size, they will also interconnect with other countries. This study is concerned with interconnection to the countries in the LACNIC service region, yet the origin ASs in the AFRINIC, APNIC, ARIN, and RIPE NCC regions will also be recorded and grouped by region.</p>
                    <p>This information is processed using different libraries available for Python 3, including <a href="https://bgpstream.caida.org/docs/api/pybgpstream" target="_blank" rel="noreferrer">pyBGPStream</a> to process the images stored at LACNIC in MRT format.</p>
                    <p>In addition, the consolidated delegated-extended files —which contain a daily updated report of the distribution of Internet number resources (IPv4 and IPv6 addresses, and Autonomous System Numbers)— are used once again. This allows linking the identifiers in the BGP tables to the different countries. The Number Resource Organization (NRO) has standardized the format of this <a href="https://www.nro.net" target="_blank" rel="noreferrer">file</a>.</p>


                    <h3>Generated Datasets</h3>
                    <div>
                        <figcaption>Figure 3: Data sources and data processing flow to analyze interconnection using global collectors.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure3.png" alt="Ver" /></figure>
                        <figcaption>Source: BGP Interconnection in Latin America and the Caribbean 2020, LACNIC</figcaption>
                    </div>

                    <p>The data required to meet the goals stated at the beginning of this study are stored in datasets. This opens up the possibility of conducting additional, more in-depth studies or using different approaches. The methodology used in the 2020 study and outlined in Figure 3 was maintained.</p>
                    <p>A set of scripts was developed to process the data in stages, obtaining partial results until producing a final dataset with summary data. In fact, the datasets generated in the 2020 study are part of the data sources used to generate these new datasets.</p>
                    <div>
                        <figcaption>Figure 4: Data sources and data processing flow used to analyze IXP interconnection.</figcaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure4.png" alt="Ver" /></figure>
                        <figcaption>Source: Prepared by the authors</figcaption>
                    </div>

                    <p>Figure 4 shows the data flow and processing used in this new study. The result is a group of datasets containing routing information for various Latin American IXPs on a specific date. These datasets are as follows:</p>

                    <table>
                        <tr><td>
                            <p>bgp-table-{'<ixp>-<t>'}.csv</p>
                            <p>Given that IXP routing table sources can be found in various formats, this dataset stores the entire table with only the relevant information: prefix, AS path, country of the prefix, country of each AS that is part of the AS path.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-routing-{'<ixp>-<t>'}.csv</p>
                            <p>Specifies all the prefixes announced by each origin AS that are reached by the IXP. It also records the peer AS through which they arrive.</p>
                        </td></tr>
                        <tr><td>
                            <p>aspath-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Records a table of frequencies based on the AS path lengths to each country (or region outside the LACNIC service region).</p>
                        </td></tr>
                        <tr><td>
                            <p>prepend-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Records a table of frequencies based on the length of the prependings in the IXP's table.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-{'<ixp>-<t>'}.csv</p>
                            <p>Shows the set of the country’s ASs that appear in both the IXP's table and the global collectors’ table. The same comparison is performed for IPv4 and IPv6 prefixes.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Summarizes the information in country-coverage-{'<ixp>-<t>'}.csv, displaying only the number of resources in each case.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Groups the information in ixp-routing-{'<ixp>-<t>'}.csv and aspath-freq-{'<ixp>-<t>'}.csv to obtain a set of relevant data about the IXP in relation to each country.</p>
                        </td></tr>
                    </table>

                    <p>The resulting data has all the necessary information and will be analyzed in the following sections of this report. It was obtained on 1st November 2021.</p>

                    <h3>Downloading the Datasets</h3>
                    <p>The code for all the scripts is published in the same GitHub code repository used for the 2020<a href="https://github.com/LACNIC/bgplac-toolkit" target="_blank" rel="noreferrer">study</a>.</p>
                    <p>Additionally, LACNIC generates datasets corresponding to the first day of each month and makes them publicly available for download. The data used to generate the results of this study can be downloaded at <a href="https://ix.labs.lacnic.net/20211101" target="_blank" rel="noreferrer">https://ix.labs.lacnic.net/20211101</a></p>

                </>}
                {myLang === 'pt' && <>
                    <h2>Metodologia</h2>
                    <h3>Fontes de dados</h3>

                    <p>Existem projetos que coletam informa&ccedil;&otilde;es sobre as tabelas de roteamento BGP vistas de diferentes pontos no n&iacute;vel global, como os projetos (<a href="https://www.ripe.net/analyse/internet-measurements/routing-information-service-ris/ris-raw-data" target="_blank" rel="noreferrer">RIS</a>) de RIPE NCC ou <a href="http://www.routeviews.org/routeviews/index.php/collectors/" target="_blank" rel="noreferrer">Route Views</a> da Universidade de Oregon. Esses projetos t&ecirc;m coletores BGP instalados em diferentes partes do mundo, geralmente localizados em sites com um grande n&uacute;mero de operadoras, que estabelecem sess&otilde;es BGP com o coletor e publicam todas as suas rotas BGP. Dessa forma, pode se ter uma vis&atilde;o do que est&aacute; acontecendo nas tabelas globais do BGP.</p>
                    <p>No n&iacute;vel local, por&eacute;m, a situa&ccedil;&atilde;o &eacute; diferente. Em particular, na regi&atilde;o da Am&eacute;rica Latina e o Caribe h&aacute; poucos coletores dos projetos mencionados, de modo que as informa&ccedil;&otilde;es sobre o comportamento do BGP no n&iacute;vel da regi&atilde;o e de cada pa&iacute;s s&atilde;o pouco conhecidas.</p>
                    <p>As tabelas do BGP das operadoras n&atilde;o podem ser acessadas externamente, portanto, os peerings ou interconex&otilde;es privadas n&atilde;o podem ser determinados de forma direta. No entanto, uma parte importante do tr&aacute;fego de cada pa&iacute;s est&aacute; concentrada nos IXP, de modo que &eacute; poss&iacute;vel, a partir da&iacute;, obter informa&ccedil;&otilde;es de interconex&atilde;o no n&iacute;vel local.</p>
                    <p>O trabalho usou duas fontes diferentes para obter as tabelas BGP de diferentes IXP que atuam nos territ&oacute;rios de abrang&ecirc;ncia do LACNIC. Em primeiro lugar, <a href="https://www.pch.net" target="_blank" rel="noreferrer">PCH</a>, que opera com coletores de rotas em mais de cem IXP ao redor do mundo. Os dados obtidos s&atilde;o publicados a fim de contribuir com a comunidade de pesquisadores e operadores de rede. O portal mant&eacute;m um reposit&oacute;rio com dois tipos diferentes de informa&ccedil;&otilde;es complementares. Para fins de processamento deste estudo, estamos interessados nas imagens ou snapshots di&aacute;rios do resultado do comando show ip bgp, que &eacute; obtido em cada um dos roteadores coletores de PCH. Assim, o estado exato da tabela de roteamento BGP &eacute; obtido no momento exato em que o snapshot &eacute; tirado.</p>

                    <p>Do outro lado, o LACNIC, como parte de um acordo com as organiza&ccedil;&otilde;es LAC-IX e <a href="https://www.internetsociety.org" target="_blank" rel="noreferrer">Internet Society</a>, est&aacute; apoiando os IXP da regi&atilde;o e fortalecendo a sua infraestrutura. Nesse contexto, foram instalados servi&ccedil;os nos IXP, incluindo os servidores de rotas centrais (route servers) e coletores BGP conectados a eles, o que permite obter as tabelas BGP, assim como s&atilde;o vistas pelo restante dos membros do IXP. Essas informa&ccedil;&otilde;es s&atilde;o colocadas periodicamente em arquivos por meio de um full dump, quatro vezes ao dia, com atualiza&ccedil;&atilde;o a cada cinco minutos das mensagens update do BGP.</p>
                    <div>
                        <figurecaption>Figura 1: Pa&iacute;ses inclu&iacute;dos neste estudo com base no n&uacute;mero de IXP, de acordo com as informa&ccedil;&otilde;es dispon&iacute;veis.</figurecaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure1.png" alt="Ver" /></figure>
                        <figurecaption>Fonte: elabora&ccedil;&atilde;o pr&oacute;pria</figurecaption>
                    </div>

                    <h3>Processamento das informa&ccedil;&otilde;es</h3>
                    <p>A metodologia de tratamento das informa&ccedil;&otilde;es foi semelhante &agrave; usada no relat&oacute;rio de 2020. Neste caso, tiveram que ser levadas em considera&ccedil;&atilde;o as diferen&ccedil;as quanto ao uso de tabelas BGP locais (provenientes de IXP) e n&atilde;o de coletores globais. No entanto, a classifica&ccedil;&atilde;o dos AS &eacute; muito mais simples: o AS de origem ainda &eacute; relevante para conhecer o escopo de cada IXP, mas tamb&eacute;m nos interessa o AS peer, ou seja, o par adjacente que lhe anuncia a rota ao IXP.</p>
                    <div>
                        <figurecaption>Figura 2: Representa&ccedil;&atilde;o do an&uacute;ncio BGP de uma rota que se propaga entre sistemas aut&ocirc;nomos at&eacute; chegar a um IXP.</figurecaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure2.png" alt="Ver" /></figure>
                        <figurecaption>Fonte: elabora&ccedil;&atilde;o pr&oacute;pria</figurecaption>
                    </div>

                    <p>Na figura 2 pode ser observado o AS-1, que anuncia seus prefixos por meio do BGP para o AS-2, e este propaga o referido an&uacute;ncio para os outros sistemas aut&ocirc;nomos at&eacute; chegar ao IXP. Deste an&uacute;ncio interessam o AS-1 (origem) e AS-N (peer). Deve-se esclarecer que cada AS pode estar registrado em um territ&oacute;rio diferente. Estas informa&ccedil;&otilde;es s&atilde;o extremamente importantes para conhecer como as operadoras de rede se interconectam na regi&atilde;o, embora, para fins pr&aacute;ticos, o estudo ser&aacute; focado no pa&iacute;s associado ao AS de origem (o laranja na figura) e ao peer (o vermelho) de cada rota.</p>
                    <p>Para obter essas informa&ccedil;&otilde;es, s&atilde;o processadas as tabelas de roteamento de cada IXP, conforme mencionado na se&ccedil;&atilde;o de fonte de dados. Independentemente de usar tabelas fornecidas pelo PCH ou pelo reposit&oacute;rio do LACNIC, em ambos os casos &eacute; poss&iacute;vel obter o conjunto de pares de prefixo e As-path da seguinte forma:</p>
                    <p>Prefixo/comprimento | AS-N … AS-3 AS-2 AS-1</p>
                    <p>WObserva-se o AS-path &agrave; esquerda, pois nos interessa conhecer o sistema aut&ocirc;nomo de origem do prefixo, e a partir dele ir obtendo os demais sistemas que s&atilde;o relevantes, conforme refletido na figura 2.</p>
                    <p>Como apontado, interessam o primeiro e o &uacute;ltimo prefixo de cada rota, embora o comprimento da rota tamb&eacute;m seja &uacute;til. Al&eacute;m disso, para dar sentido a essas informa&ccedil;&otilde;es, precisamos associar cada prefixo e sistema aut&ocirc;nomo a um pa&iacute;s. Portanto, usaremos o arquivo de estat&iacute;sticas delegated-extended que os RIR publicam, a fim de obter essa associa&ccedil;&atilde;o entre recursos e territ&oacute;rio. Assim, cada entrada pode ser interpretada como:</p>
                    <p>[Pa&iacute;s do prefixo] | [Pa&iacute;s AS-N] … [Pa&iacute;s AS-3] [Pa&iacute;s AS-2] [Pa&iacute;s AS-1]</p>
                    <p>Neste caso, interessa cada pa&iacute;s dos AS-1. Assim &eacute; conhecido o escopo de cada IXP. &eacute; prov&aacute;vel que muitas rotas acabem com AS de origem no mesmo pa&iacute;s que o IXP, mas tamb&eacute;m se interconectar&atilde;o com outros pa&iacute;ses dependendo de sua magnitude. Neste estudo, a interconex&atilde;o com os pa&iacute;ses cobertos pelo LACNIC &eacute; importante, mas tamb&eacute;m ser&atilde;o registrados os AS de origem que est&atilde;o registrados no AFRINIC, APNIC, ARIN e RIPE NCC, agrupando-os em sua regi&atilde;o correspondente.</p>
                    <p>Para processar essas informa&ccedil;&otilde;es, s&atilde;o usadas diferentes bibliotecas dispon&iacute;veis para a linguagem Python 3, incluindo a ferramenta <a href="https://bgpstream.caida.org/docs/api/pybgpstream" target="_blank" rel="noreferrer">pyBGPStream</a> para processar as imagens em formato MRT, armazenadas no LACNIC.</p>
                    <p>Al&eacute;m disso, s&atilde;o usados novamente os arquivos delegated-extended consolidados, que cont&eacute;m uma atualiza&ccedil;&atilde;o di&aacute;ria do status da aloca&ccedil;&atilde;o dos recursos de numera&ccedil;&atilde;o da Internet (endere&ccedil;os IPv4 e IPv6 e N&uacute;meros do Sistemas Aut&ocirc;nomos). Desta forma, os identificadores presentes nas tabelas BGP podem ser associados aos diferentes pa&iacute;ses. O formato deste arquivo est&aacute; padronizado pela <a href="https://www.nro.net" target="_blank" rel="noreferrer">Number Resource Organization (NRO)</a>.</p>

                    <h3>Generated Datasets</h3>
                    <div>
                        <figurecaption>Figura 3: Fontes e fluxo de processamento de dados para conhecer sobre a interconex&atilde;o usando coletores globais</figurecaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure3.png" alt="Ver" /></figure>
                        <figurecaption>Fonte: «Interconex&atilde;o no BGP na regi&atilde;o da Am&eacute;rica Latina e o Caribe», 2020, LACNIC</figurecaption>
                    </div>

                    <p>Todos os dados necess&aacute;rios para cumprir com os objetivos colocados inicialmente s&atilde;o armazenados em datasets, o que abre s possibilidade de fazer outros estudos mais profundos ou com outras abordagens. O sistema de trabalho usado no estudo de 2020 e esquematizado na Figura 3 &eacute; mantido.</p>
                    <p>Foi estabelecido o desenvolvimento de um conjunto de scripts que realizam o processamento em etapas com resultados parciais, at&eacute; obter um dataset final com os dados resumidos. De fato, os datasets gerados neste estudo fazem parte da fonte de dados para gerar estes novos datasets.</p>
                    <div>
                        <figurecaption>Figura 4: Fontes e fluxo de processamento de dados para conhecer sobre a interconex&atilde;o  de IXP.</figurecaption>
                        <figure><img src="/images/interconexion-bgp-local-en-lac/figure4.png" alt="Ver" /></figure>
                        <figurecaption>Fonte: elabora&ccedil;&atilde;o pr&oacute;pria</figurecaption>
                    </div>

                    <p>Na figura 4, pode ser observado o fluxo de dados y processamento realizado neste novo estudo. O resultado &eacute; um conjunto de datasets que cont&ecirc;m informa&ccedil;&otilde;es sobre o roteamento dos diferentes IXP da Am&eacute;rica Latina, em um determinado dia. Esses datasets s&atilde;o:</p>

                    <table>
                        <tr><td>
                            <p>bgp-table-{'<ixp>-<t>'}.csv</p>
                            <p>Como a fonte das tabelas de roteamento dos IXP pode ser encontrada em diferentes formatos, neste dataset &eacute; armazenada toda a tabela apenas com as informa&ccedil;&otilde;es relevantes: prefixo, As-path, pa&iacute;s do prefixo, pa&iacute;ses de cada AS do As-path.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-routing-{'<ixp>-<t>'}.csv</p>
                            <p>Lista todos os prefixos anunciados por cada AS de origem que s&atilde;o alcan&ccedil;ados pelo IXP. Tamb&eacute;m registra por qual par de AS peer eles chegam.</p>
                        </td></tr>
                        <tr><td>
                            <p>aspath-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Registra uma tabela de frequ&ecirc;ncias com base no comprimento dos As-path para cada pa&iacute;s (ou regi&atilde;o fora do LACNIC).</p>
                        </td></tr>
                        <tr><td>
                            <p>prepend-freq-{'<ixp>-<t>'}.csv</p>
                            <p>Registra uma tabela de frequ&ecirc;ncias com base no comprimento de prependings presentes na tabela do IXP.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-{'<ixp>-<t>'}.csv</p>
                            <p>Mostra no n&iacute;vel de conjunto a interse&ccedil;&atilde;o dos AS do pa&iacute;s que est&atilde;o presentes na tabela do IXP contra aqueles que est&atilde;o presentes na tabela dos coletores globais. A mesma compara&ccedil;&atilde;o de conjuntos tamb&eacute;m &eacute; feita para os prefixos IPv4 e IPv6.</p>
                        </td></tr>
                        <tr><td>
                            <p>country-coverage-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Resume as informa&ccedil;&otilde;es de country-coverage-{'<ixp>-<t>'}.csv, mostrando apenas o n&uacute;mero de recursos para cada caso.</p>
                        </td></tr>
                        <tr><td>
                            <p>ixp-summary-{'<ixp>-<t>'}.csv</p>
                            <p>Re&uacute;ne informa&ccedil;&otilde;es de ixp-routing-{'<ixp>-<t>'}.csv e aspath-freq-{'<ixp>-<t>'}.csv; para obter um conjunto de dados relevantes sobre o IXP para cada pa&iacute;s.</p>
                        </td></tr>
                    </table>

                    <p>Esses dados resultantes cont&ecirc;m todas as informa&ccedil;&otilde;es necess&aacute;rias que ser&atilde;o analisadas nas pr&oacute;ximas se&ccedil;&otilde;es deste relat&oacute;rio. Estes foram obtidos em 1 de novembro de 2021.</p>

                    <h3>Download dos datasets</h3>
                    <p>O c&oacute;digo de todos os scripts &eacute; publicado no mesmo reposit&oacute;rio de c&oacute;digo github usado para o <a href="https://github.com/LACNIC/bgplac-toolkit" target="_blank" rel="noreferrer">estudo 2020</a>.</p>
                    <p>Al&eacute;m disso, o LACNIC gera os datasets correspondentes ao primeiro dia de cada m&ecirc;s e est&atilde;o dispon&iacute;veis publicamente para download. Os dados usados para gerar os resultados deste estudo podem ser baixados em<a href="https://ix.labs.lacnic.net/20211101" target="_blank" rel="noreferrer">https://ix.labs.lacnic.net/20211101</a></p>

                </>}
            </div>

        </div>
    )
}

export default Metodologia
