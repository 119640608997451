import React, {useContext} from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 

function RuteoLAC(props) {
    const { myLang } = useContext(LangContext)
    return (
         <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Seguridad en el ruteo de América Latina y el Caribe</h1>
                    <p>Autor: Augusto Mathurin</p>
                    <p>Reporte del <a href="https://fortproject.net/"  target="_blank" rel="noreferrer" >Proyecto FORT</a>, iniciativa conjunta de LACNIC y NIC.MX que busca aumentar la seguridad y la resiliencia de los sistemas de enrutamiento.</p>
                    <Button color="button_blue" to="/reportes/interconexion/fort-informe-seguridad-ruteo-es.pdf">Descargar (PDF)</Button>
                </>}
                {myLang === 'en' && <> 
                    <h1>Routing Security in Latin America and the Caribbean</h1>
                    <p>Author: Augusto Mathurín</p>
                    <p>This report is promoted by the <a href="https://fortproject.net/?0">FORT Project</a> , a joint initiative of LACNIC and NIC.MX, which seeks to improve routing system security and resilience.</p>
                    <Button color="button_blue" to="/reportes/interconexion/fort-informe-seguridad-ruteo-es.pdf">Download (PDF)</Button>
                </>}
                {myLang === 'pt' && <> 
                    <h1>Routing Security in Latin America and the Caribbean</h1>
                    <p>Autor: Augusto Mathurín</p>
                    <p>Relatório do <a href="https://fortproject.net/?0">Projeto FORT</a>, iniciativa feita em parceria entre o LACNIC e o NIC.MX que busca aumentar a segurança e a resiliência.</p>
                    <Button color="button_blue" to="/reportes/interconexion/fort-informe-seguridad-ruteo-es.pdf">Download (PDF)</Button>
                </>}
            </div> 
        </div>
            
    );
}

export default RuteoLAC;