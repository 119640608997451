import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Referencias(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p9">
                <h2>
                    {myLang === 'es' && <>Referencias</> }
                    {myLang === 'en' && <>References</> }
                    {myLang === 'pt' && <>Referências</> } 
                </h2>
                <ol>
                    <li id="referencia1"><a href="https://us-cert.cisa.gov/ncas/alerts/TA14-017A" target="_blank" rel="noreferrer">https://us-cert.cisa.gov/ncas/alerts/TA14-017A</a></li>
                    <li id="referencia2"><a href="https://christian-rossow.de/articles/Amplification_DDoS.php" target="_blank" rel="noreferrer">https://christian-rossow.de/articles/Amplification_DDoS.php</a></li>
                    <li id="referencia3"><a href="https://us-cert.cisa.gov/ncas/alerts/TA14-017A/" target="_blank" rel="noreferrer">https://us-cert.cisa.gov/ncas/alerts/TA14-017A</a></li>
                    <li id="referencia4"><a href="https://blogs.akamai.com/sitr/2019/09/new-ddos-vector-observed-in-the-wild-wsd-attacks-hitting-35gbps.html" target="_blank" rel="noreferrer">https://blogs.akamai.com/sitr/2019/09/new-ddos-vector-observed-in-the-wild-wsd-attacks-hitting-35gbps.html</a></li>
                    <li id="referencia5"><a href="https://sensorstechforum.com/es/ws-discovery-protocol-ddos/" target="_blank" rel="noreferrer">https://sensorstechforum.com/es/ws-discovery-protocol-ddos/</a></li>
                    <li id="referencia6"><a href="https://www.cloudflare.com/es-es/learning/ddos/memcached-ddos-attack/" target="_blank" rel="noreferrer">https://www.cloudflare.com/es-es/learning/ddos/memcached-ddos-attack/</a></li>
                    <li id="referencia7"><a href="https://blogs.akamai.com/2018/02/memcached-udp-reflection-attacks.html" target="_blank" rel="noreferrer">https://blogs.akamai.com/2018/02/memcached-udp-reflection-attacks.html</a></li>
                    <li id="referencia8"><a href="https://www.akamai.com/us/en/resources/our-thinking/threat-advisories/ddos-reflection-attack-memcached-udp.jsp" target="_blank" rel="noreferrer">https://www.akamai.com/us/en/resources/our-thinking/threat-advisories/ddos-reflection-attack-memcached-udp.jsp</a></li>
                    <li id="referencia9"><a href="https://dd80b675424c132b90b3-e48385e382d2e5d17821a5e1d8e4c86b.ssl.cf1.rackcdn.com/external/fbi-private-industry-notification-20200721-002.pdf" target="_blank" rel="noreferrer">https://dd80b675424c132b90b3-e48385e382d2e5d17821a5e1d8e4c86b.ssl.cf1.rackcdn.com/external/fbi-private-industry-notification-20200721-002.pdf</a></li>
                    <li id="referencia10"><a href="https://www.variot.eu/2020/09/01/scanning-for-accessible-coap-devices/" target="_blank" rel="noreferrer">https://www.variot.eu/2020/09/01/scanning-for-accessible-coap-devices/</a></li>
                    <li id="referencia11"><a href="https://www.shadowserver.org/news/accessible-coap-report-scanning-for-exposed-constrained-application-protocol-services/" target="_blank" rel="noreferrer">https://www.shadowserver.org/news/accessible-coap-report-scanning-for-exposed-constrained-application-protocol-services/</a></li>
                    <li id="referencia12"><a href="https://scan.shadowserver.org/" target="_blank" rel="noreferrer">https://scan.shadowserver.org/</a></li>
                    <li id="referencia13"><a href="https://www.shadowserver.org/news/the-scannings-will-continue-until-the-internet-improves/" target="_blank" rel="noreferrer">https://www.shadowserver.org/news/the-scannings-will-continue-until-the-internet-improves/</a></li>
                    <li id="referencia14"><a href="https://www.shodan.io/" target="_blank" rel="noreferrer">https://www.shodan.io/</a></li>
                    <li id="referencia15"><a href="https://search.censys.io/" target="_blank" rel="noreferrer">https://search.censys.io/</a></li>
                </ol>
            </div>
        </div>
    );
}

export default Referencias;