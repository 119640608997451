import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Referencias() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <Contenido />}
                {myLang === 'en' && <Contenido />}
                {myLang === 'pt' && <Contenido />}
            </div>
        </div>
    )
}
function Contenido() {
    return (<>
        <h2><MatchText id="match-72">Referencias</MatchText></h2>
        <div className='alerts alert_white'> 
        <p><em><MatchText id="match-73">CIDR Report (2022, marzo). Active BGP entries (FIB). </MatchText>
            <a href="https://www.cidr-report.org/cgi-bin/plota?file=%2fvar%2fdata%2fbgp%2fas2.0%2fbgp%2dactive%2etxt&amp;descr=Active%20BGP%20entries%20%28FIB%29&amp;ylabel=Active%20BGP%20entries%20%28FIB%29&amp;with=step">
                <MatchText id="match-74"> CIDR Report</MatchText></a>
        </em></p>
        <p><em><MatchText id="match-75">Cristina Mayr, Claudio Risso, and Eduardo Gramp&iacute;n. 2021. Crafting optimal and resilient iBGP-IP/MPLS overlays for transit backbone networks. Opt. Switch. Netw. 42, C (Nov 2021). DOI:</MatchText></em>
            <a href="https://doi.org/10.1016/j.osn.2021.100635"><em><MatchText id="match-68">https://doi.org/10.1016/j.osn.2021.100635</MatchText></em></a><em>.</em></p>
        <ol>
            <li><em><MatchText id="match-76"> Mayr, E. Gramp&iacute;n, C. Risso, Optimal Route Reflection Topology Design, in: Proceedings of the 10th Latin America Networking Conference, LANC &rsquo;18, ACM, New York, NY, USA, 2018, pp. 65&ndash;72. doi:10.1145/3277103. 3277124.&nbsp;</MatchText></em></li>
            <li><em><MatchText id="match-77">Mayr, C. Risso, E. Gramp&iacute;n, A Combinatorial Optimization Framework for the Design of resilient iBGP Overlays, in: 2019 15th International Conference on the Design of Reliable Communication Networks (DRCN), 2019, pp. 6&ndash;10. doi:10.1109/DRCN.2019.8713744.&nbsp;</MatchText></em></li>
            <li><em><MatchText id="match-78">Mayr, C. E. Risso, E. Gramp&iacute;n, Designing an Optimal and Resilient iBGP Overlay with extended ORRTD, in: Fifth International Conference on Machine Learning, Optimization, and Data Science (LOD), Springer International Publishing, 2019, pp. 409&ndash;421.</MatchText></em></li>
            <li><em><MatchText id="match-79">Risso, C. Mayr, E. Gramp&iacute;n, A combined bgp and ip/mpls resilient transit backbone design, in: 11th International Workshop on Resilient Networks Design and Modeling (RNDM19), 2019, pp. 1&ndash;8. doi:10.1109/RNDM48015. 2019.8949099.&nbsp;</MatchText></em></li>
        </ol>
        <p><em><MatchText id="match-80">Huston, G. (2022, enero). BGP in 2021 &ndash; The BGP Table. APNIC. </MatchText>
            <a href="https://blog.apnic.net/2022/01/06/bgp-in-2021-the-bgp-table/&nbsp;"><MatchText id="match-81">https://blog.apnic.net/2022/01/06/bgp-in-2021-the-bgp-table/&nbsp;</MatchText>
            </a></em>
        </p></div></>)
}
export default Referencias
