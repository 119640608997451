import React, {useContext} from 'react' 
import { LangContext } from '../../../context/DataContext';
import Conectividad2022Es from './Conectividad2022Es';
import Conectividad2022Pt from './Conectividad2022Pt'; 
import Conectividad2022En from './Conectividad2022En';

const styles = { 
    images: {
      textAlign: "center",
      width: "550px", 
    }, 
  };

function Conectividad2022({ applyHighlightToChildren }) {
    const { myLang } = useContext(LangContext) 
    return (
        <div >
            {applyHighlightToChildren([
                <div> 
                    {myLang === 'es' && <Conectividad2022Es styles={styles} />}
                    {myLang === 'pt' && <Conectividad2022Pt styles={styles} />}
                    {myLang === 'en' && <Conectividad2022En styles={styles} />}
                </div>
            ])}
        </div >
    )
}

export default Conectividad2022
