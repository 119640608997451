import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import Button from '../../components/Button' 


function RootServer(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div> 
                {myLang === 'es' && <> 
                    <h1>Uso de root servers del DNS en la región</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Mediciones y análisis histórico del comportamiento de los servidores raíz del DNS</p>
                    <Button color="button_blue" to="/es/interconexion/uso-de-root-servers-del-dns-en-la-region">Leer Reporte</Button>
                </>}
                {myLang === 'en' && <> 
                    <h1>Use of DNS Root Servers in Latin America</h1>
                    <p>Author: Hugo Salgado</p>
                    <p>Measurements and a historical analysis of the behavior of DNS Root Servers.</p>
                    <Button color="button_blue" to="/en/interconexion/uso-de-root-servers-del-dns-en-la-region">READ REPORT</Button>
                </>}
                {myLang === 'pt' && <> 
                    <h1>Uso de root servers del DNS en la región</h1>
                    <p>Autor: Hugo Salgado</p>
                    <p>Medições e análise histórica do comportamento dos servidores raiz do DNS.</p>
                    <Button color="button_blue" to="/reportes/interconexion/informe-rootservers-lac-en.pdf">Download [inglês] (PDF)</Button>
                    <Button color="button_blue" to="/reportes/interconexion/informe-rootservers-lac-es.pdf">Download [espanhol] (PDF)</Button>
                </>}
            </div> 
        </div> 
    );
}

export default RootServer;