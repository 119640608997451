import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';

function Recomendaciones() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p9">
                {myLang === 'es' && <>
                    <h2>Recomendaciones</h2>
                    <p>En suma, para evitar la suplantaci&oacute;n de direcciones IP se recomienda:</p>
                    <ul>
                        <li>Evaluar el estado de la aplicaci&oacute;n de filtros de tipo <em>inbound</em> y <em>outbound</em> SAV en los recursos asignados con la herramienta <em>spoofer</em> del proyecto Spoofer de caida.</li>
                        <li>Implementar filtros de tipo <em>inbound</em> y <em>outbound</em></li>
                        <li>A partir del conocimiento dado por este estudio, fomentar los testeos y la aplicaci&oacute;n de las buenas pr&aacute;cticas recomendadas para mitigar los problemas asociados al IP <em>spoofing</em>.</li>
                        <li>Utilizar estad&iacute;sticas provistas por el proyecto Spoofer de caida para darle mayor visibilidad a la problem&aacute;tica en la comunidad y para generar conciencia.</li>
                        <li>Analizar alternativas para incentivar la realizaci&oacute;n de testeos, de forma de motivar a los asociados a sumarse a la iniciativa.</li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>Recommendations</h2>
                    <p>The following recommendations are offered to avoid IP address spoofing:</p>
                    <ul>
                        <li>Assess the status of inbound and outbound SAV in the assigned resources using the tool provided by the CAIDA Spoofer Project.</li>
                        <li>Implement inbound and outbound SAV.</li>
                        <li>Based on the insights gained from this study, encourage testing and the implementation of best practices recommended for mitigating the issues associated with IP spoofing.</li>
                        <li>Use the statistics provided by the CAIDA Spoofer Project to bring visibility to the problem and raise awareness among the community.</li>
                        <li>Analyze alternatives to encourage testing and motivate members to join the initiative.</li>
                    </ul>
                </>}
                {myLang === 'pt' && <>
                    <h2>Recomenda&ccedil;&otilde;es</h2>
                    <p>Resumindo, para evitar a falsifica&ccedil;&atilde;o de endere&ccedil;os IP, recomenda-se:</p>
                    <ul>
                        <li>Avaliar o status da aplica&ccedil;&atilde;o de filtros de tipo <em>inbound</em> e <em>outbound</em> SAV nos recursos designados com a ferramenta <em>spoofer</em> do projeto Spoofer de CAIDA.</li>
                        <li>Implementar filtros do tipo <em>inbound</em> e <em>outbound</em></li>
                        <li>Com base no conhecimento fornecido por este estudo, incentivar os testes e a aplica&ccedil;&atilde;o das boas pr&aacute;ticas recomendadas para mitigar os problemas associados ao IP <em>spoofing</em>.</li>
                        <li>Usar estat&iacute;sticas fornecidas pelo projeto <em>Spoofer</em> de CAIDA para dar maior visibilidade ao problema na comunidade e aumentar a conscientiza&ccedil;&atilde;o.</li>
                        <li>Analisar alternativas de incentivo &agrave; realiza&ccedil;&atilde;o de testes, a fim de motivar os associados a aderirem &agrave; iniciativa.</li>
                    </ul>
                </>}
            </div>
        </div>
    )
}

export default Recomendaciones
