import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f'

function Resultados(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-99">Resultado de las acciones</MatchText></h2>
                    <p>
                        <MatchText id="match-100">Las acciones se dirigieron a mitigar las vulnerabilidades encontradas. Como parte de este trabajo se realizó una presentación contando la iniciativa y los resultados preliminares, preservando la confidencialidad de la información en lo que hace a la identidad de las organizaciones, en el First Symposium realizado en el marco de LACNIC 36.</MatchText></p>
                    <p>
                        <MatchText id="match-101">De las doce organizaciones contactadas, solo dos respondieron e interactuaron con el equipo para mitigar las vulnerabilidades reportadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r17_es.png" /> </figure>
                    <p>
                        <MatchText id="match-102">En el siguiente gráfico se puede visualizar el porcentaje de casos resueltos que corresponden a una de las organizaciones que dio respuesta y llevó a cabo acciones que mitigaron las vulnerabilidades reportadas referentes al protocolo SSDP.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r18_es.png" /> </figure>
                    <p>
                        <MatchText id="match-103">La otra organización que respondió, como puede observarse en el siguiente gráfico, mitigó la totalidad de las vulnerabilidades de tipo CharGen reportadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r19_es.png" /> </figure>
                    <p>
                        <MatchText id="match-104">Si bien solo algunas organizaciones respondieron e interactuaron con el equipo a partir de nuestros reportes, al concentrarse gran porcentaje de los problemas en pocos ASN, se logró tener impacto en la mitigación del problema</MatchText></p>
                    <p>
                        <MatchText id="match-105">Una de las organizaciones a las que se reportó accionó en favor de la mitigación del problema CharGen, incluso solicitando que hiciéramos un nuevo chequeo. Esto nos permitió corroborar que se habían mitigado las vulnerabilidades reportadas. Como consecuencia, el país al que pertenece esa organización dejó de aparecer en los reportes públicos de Shadowserver sobre CharGen. El estudio considera ese resultado como un caso de éxito.</MatchText></p>
                    <p>
                        <MatchText id="match-106">Por último, se relevaron distintos protocolos analizados durante la investigación después de las acciones realizadas.</MatchText></p>
                    <h3>
                        <MatchText id="match-107">Sobre las problemáticas en el protocolo SSDP</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-108">La organización con la que coordinamos acciones para explicar la problemática mejoró sensiblemente en relación con la cantidad de dispositivos expuestos. Dado que no informaron sobre sus acciones, no podemos asegurar si esa mejora responde a acciones de mitigación realizadas.</MatchText></li>
                        <li>
                            <MatchText id="match-109">En otra organización, también se observó un incremento de mejora en la misma magnitud.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-110">En el siguiente gráfico se reflejan las variaciones de las organizaciones seleccionadas:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r20_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-111">Sobre las problemáticas en el protocolo NTP tipo NTPMonitor</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-112">En lo referido al problema de NTPMonitor, se observaron cambios levemente desfavorables
                                En el siguiente gráfico se reflejan las variaciones de las organizaciones seleccionadas:</MatchText><figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r21_es.png" /> </figure> </li>
                    </ul>
                    <h3>
                        <MatchText id="match-113">Sobre las problemáticas en el protocolo NTP tipo NTPVersion</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-114">En lo referido al problema NTPVersion se observó que, si bien algunas de las organizaciones contactadas no respondieron formalmente a los reportes realizados, dos de ellas mejoraron respecto de la cantidad de dispositivos NTPVersion expuestos a internet</MatchText></li>
                        <li>
                            <MatchText id="match-115">Por otro lado, en el Top 4 se observó la aparición de una organización que en el relevamiento inicial tenía una cantidad despreciable de dispositivos NTPVersion expuestos a internet.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-116">En el siguiente gráfico se reflejan las variaciones de las organizaciones seleccionadas incluyendo la nueva organización observada en el relevamiento final:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r22_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-117">Sobre las problemáticas en el protocolo DNS tipo open resolver</MatchText></h3>
                    <p>
                        <MatchText id="match-118">En lo referido a la problemática asociada al DNS, debido a la exposición a internet de servidores que funcionan como open resolvers, se observaron mejoras considerables en dos de las cinco organizaciones a las que se informó sobre la situación.</MatchText></p>
                    <p>
                        <MatchText id="match-119">Al igual que en el caso de NTPVersion, se observó la aparición en el Top 8 de una organización que en el relevamiento inicial tenía una baja cantidad de dispositivos DNS OpenResolver expuestos a internet.</MatchText></p>
                    <p>
                        <MatchText id="match-120">En el siguiente gráfico se reflejan las variaciones de las organizaciones seleccionadas, incluyendo la nueva organización observada en el relevamiento final:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r23_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-121">Sobre las problemáticas en el protocolo CharGen</MatchText></h3>
                    <p>
                        <MatchText id="match-122">Como se mencionó, pudimos trabajar coordinadamente con uno de los miembros de LACNIC para mitigar el 100% de los servicios CharGen expuestos a internet en su ASN.</MatchText></p>
                    <p>
                        <MatchText id="match-123">Si bien el resto de las organizaciones contactadas no respondieron formalmente a los reportes realizados, pudimos observar que en estas los servicios de CharGen expuestos se vieron reducidos levemente.</MatchText></p>
                    <p>
                        <MatchText id="match-124">En el siguiente gráfico se reflejan las variaciones de las organizaciones seleccionadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r24_es.png" /> </figure>


                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-99">Result of the Actions</MatchText></h2>
                    <p>
                        <MatchText id="match-100">The goal of the actions above was to mitigate detected vulnerabilities. Our work included a presentation to explain the initiative and its preliminary results during the FIRST Symposium held within the framework of LACNIC 36. The confidentiality of the identity of the organizations was maintained.</MatchText></p>
                    <p>
                        <MatchText id="match-101">Of the twelve organizations that were contacted, only two replied and interacted with the team to mitigate the reported vulnerabilities.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r17_es.png" /> </figure>
                    <p>
                        <MatchText id="match-102">The following chart shows the percentage of cases that were solved. This number represents the organization that replied and implemented actions to mitigate the reported SSDP protocol related vulnerabilities.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r18_es.png" /> </figure>
                    <p>
                        <MatchText id="match-103">As shown in the following graph, the other organization that replied mitigated all of the reported CharGen vulnerabilities.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r19_es.png" /> </figure>
                    <p>
                        <MatchText id="match-104">Although only a few organizations replied and interacted with the team, the fact that a large part of the issues were concentrated in a few ASNs made it possible to have an impact on mitigating the problem.</MatchText></p>
                    <p>
                        <MatchText id="match-105">Una de las organizaciones a las que se reportó accionó en favor de la mitigación del problema CharGen, incluso solicitando que hiciéramos un nuevo chequeo. Esto nos permitió corroborar que se habían mitigado las vulnerabilidades reportadas. Como consecuencia, el país al que pertenece esa organización dejó de aparecer en los reportes públicos de Shadowserver sobre CharGen. El estudio considera ese resultado como un caso de éxito.</MatchText></p>
                    <p>
                        <MatchText id="match-106">Finally, different protocols analyzed during the study were surveyed after implementing the actions above.</MatchText></p>
                    <h3>
                        <MatchText id="match-107">About the Issues Relating to the SSDP Protocol</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-108">The organization with which we coordinated actions to explain the problem significantly decreased their number of exposed devices. Since they did not report their own actions, we cannot be sure if this improvement was the result of mitigation actions.</MatchText></li>
                        <li>
                            <MatchText id="match-109">A similar improvement was observed in another organization.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-110">The following chart reflects the variations experienced by the selected organizations:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r20_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-111">About the Issues Relating to the NTPMonitor-Type NTP Protocol</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-112">Slightly negative changes were observed in relation to the number of issues relating to NTPMonitor.
                                The following chart reflects the variations experienced by the selected organizations:<figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r21_es.png" /> </figure>
                            </MatchText></li>
                    </ul>
                    <h3>
                        <MatchText id="match-113">About the Issues Relating to the NTPVersion-Type NTP Protocol</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-114">As for the issues relating to NTPVersion, we noted that, although some of the organizations we contacted did not formally reply to our reports, two of them improved in terms of the number of NTPVersion devices exposed to the Internet.</MatchText></li>
                        <li>
                            <MatchText id="match-115">On the other hand, one organization which had an insignificant number of NTPVersion devices exposed to the Internet when the initial survey was conducted later appeared among the Top 4.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-116">The following chart reflects the variations experienced by the selected organizations, including the organization observed during the final survey:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r22_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-117">About the Issues Relating to the Open Resolver Type DNS Protocol</MatchText></h3>
                    <p>
                        <MatchText id="match-118">Because servers operating as open resolvers are exposed to the Internet, considerable improvements were observed in terms of DNS related issues for two of the five organizations that were informed about the situation.</MatchText></p>
                    <p>
                        <MatchText id="match-119">Just as in the case of NTPVersion, one organization which had a very low number of open DNS resolvers exposed to the Internet when the initial survey was conducted now appeared among the Top 8.</MatchText></p>
                    <p>
                        <MatchText id="match-120">The following chart reflects the variations experienced by the selected organizations, including the organization observed during the final survey:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r23_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-121">About the Issues Relating to the CharGen Protocol</MatchText></h3>
                    <p>
                        <MatchText id="match-122">As mentioned earlier, we were able to work in coordination with one LACNIC member to mitigate the issue in 100% of the CharGen services in their ASN exposed to the Internet.</MatchText></p>
                    <p>
                        <MatchText id="match-123">Although the rest of the organizations we contacted did not formally respond to our reports, we noted that he number of exposed CharGen services decreased slightly.</MatchText></p>
                    <p>
                        <MatchText id="match-124">The following chart reflects the variations experienced by the selected organizations.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r24_es.png" /> </figure>

                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-99">Resultado das ações</MatchText></h2>
                    <p>
                        <MatchText id="match-100">As ações tiveram como objetivo mitigar as vulnerabilidades encontradas. Como parte deste trabalho, foi feita uma apresentação explicando a iniciativa e os resultados preliminares, preservando a confidencialidade das informações sobre a identidade das organizações, no I Simpósio realizado no âmbito do LACNIC 36.</MatchText></p>
                    <p>
                        <MatchText id="match-101">Das doze organizações contatadas, apenas duas responderam e interagiram com a equipe para mitigar as vulnerabilidades relatadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r17_es.png" /> </figure>
                    <p>
                        <MatchText id="match-102">O gráfico a seguir mostra a porcentagem de casos resolvidos que correspondem a uma das organizações que responderam e realizaram ações que mitigaram as vulnerabilidades relatadas em relação ao protocolo SSDP.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r18_es.png" /> </figure>
                    <p>
                        <MatchText id="match-103">A outra organização que respondeu, como pode ser visto no gráfico a seguir, mitigou todas as vulnerabilidades do tipo CharGen relatadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r19_es.png" /> </figure>
                    <p>
                        <MatchText id="match-104">Embora apenas algumas organizações responderam e interagiram com a equipe com base em nossos relatórios, ao concentrar uma grande porcentagem dos problemas em poucos ASN, foi possível ter um impacto na mitigação do problema.</MatchText></p>
                    <p>
                        <MatchText id="match-105">Una de las organizaciones a las que se reportó accionó en favor de la mitigación del problema CharGen, incluso solicitando que hiciéramos un nuevo chequeo. Esto nos permitió corroborar que se habían mitigado las vulnerabilidades reportadas. Como consecuencia, el país al que pertenece esa organización dejó de aparecer en los reportes públicos de Shadowserver sobre CharGen. El estudio considera ese resultado como un caso de éxito.</MatchText></p>
                    <p>
                        <MatchText id="match-106">Finalmente, foram levantados diferentes protocolos analisados durante a pesquisa após as ações realizadas.</MatchText></p>
                    <h3>
                        <MatchText id="match-107">Sobre os problemas no protocolo SSDP</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-108">A organização com a qual coordenamos ações para explicar o problema melhorou significativamente em relação ao número de dispositivos expostos. Como não informaram sobre suas ações, não podemos ter certeza se essa melhoria responde às ações de mitigação realizadas.</MatchText></li>
                        <li>
                            <MatchText id="match-109">Em outra organização, também foi observado um aumento na melhoria da mesma magnitude.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-110">O gráfico a seguir reflete as variações das organizações selecionadas:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r20_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-111">Sobre os problemas no protocolo NTP tipo NTPMonitor</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-112">Em relação ao problema de NTPMonitor, foram observadas mudanças ligeiramente desfavoráveis.
                                O gráfico a seguir reflete as variações das organizações selecionadas<figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r21_es.png" /> </figure>
                            </MatchText></li>
                    </ul>
                    <h3>
                        <MatchText id="match-113">Sobre os problemas no protocolo NTP tipo NTPVersion</MatchText></h3>
                    <ul>
                        <li>
                            <MatchText id="match-114">Em relação ao problema de NTPVersion observou-se que, embora algumas das organizações contatadas não responderam formalmente aos relatórios realizados, duas delas melhoraram no que diz respeito ao número de dispositivos NTPVersion expostos à Internet.</MatchText></li>
                        <li>
                            <MatchText id="match-115">Do outro lado, no Top 4 observou-se o surgimento de uma organização que no levantamento inicial apresentava um número desprezível de dispositivos NTPVersion expostos à Internet.</MatchText></li>
                    </ul>
                    <p>
                        <MatchText id="match-116">O gráfico a seguir reflete as variações das organizações selecionadas, incluindo a nova organização observada no levantamento final:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r22_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-117">Sobre os problemas no protocolo DNS tipo open resolver</MatchText></h3>
                    <p>
                        <MatchText id="match-118">Em relação aos problemas associados ao DNS, devido à exposição à Internet de servidores que funcionam como open resolvers, foram observadas melhorias consideráveis em duas das cinco organizações informadas sobre a situação.</MatchText></p>
                    <p>
                        <MatchText id="match-119">Assim como no caso da NTPVersion, apareceu no Top 8 uma organização que no relevamento inicial tinha um baixo número de dispositivos DNS Open Resolver expostos à Internet.</MatchText></p>
                    <p>
                        <MatchText id="match-120">O gráfico a seguir reflete as variações das organizações selecionadas, incluindo a nova organização observada no levantamento final:</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r23_es.png" /> </figure>
                    <h3>
                        <MatchText id="match-121">Sobre os problemas no protocolo CharGen</MatchText></h3>
                    <p>
                        <MatchText id="match-122">Conforme mencionado, pudemos trabalhar em coordenação com um dos associados do LACNIC para mitigar 100% dos serviços CharGen expostos à Internet em seu ASN.</MatchText></p>
                    <p>
                        <MatchText id="match-123">Embora as outras organizações contatadas não responderam formalmente aos relatórios realizados, pudemos observar que nelas, os serviços de CharGen expostos foram ligeiramente reduzidos.</MatchText></p>
                    <p>
                        <MatchText id="match-124">O gráfico a seguir reflete as variações das organizações selecionadas.</MatchText></p>
                    <figure><img alt="Ver" src="/images/vulnerabilidades-ataques-ddos/r24_es.png" /> </figure>

                </>}
            </div>
        </div>
    );
}

export default Resultados;