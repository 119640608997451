import React, { useState, useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 

function MenuLateralConectividad2022(/* {setSearchTerm, searchTerm, handleSearch} */) {
    const { myLang } = useContext(LangContext)
    const { activeAnchor, scrollToAnchor } = useContext(MenuContext)
    
    const [clickMenu, establecerClickMenu] = useState();
    const abrirMenuLateral = (menu) => { 
        if ( menu === '') {
            establecerClickMenu()
        }
        if ( menu === 'anexo3grafos_select') {
            scrollToAnchor('anexo3grafos_select')
            establecerClickMenu(menu);
            scrollToAnchor('anexo3grafos_select')
        }
         
         
    };  
    return (<> 
         
        {myLang === 'es' && 
            <nav>
                <button>Análisis de los caminos del tráfico dentro de cada país de la región LAC <i className="fas fa-angle-down"></i></button>
                {/* <form onSubmit={handleSearch}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button type="submit" disabled={!searchTerm.trim()}>Buscar</button>
                </form> */}
                <ul className="active">
                    <li onClick={() => abrirMenuLateral('')}><a href="#resumen" className={activeAnchor === 'resumen_select' ? 'active' : null} onClick={() => scrollToAnchor('resumen_select')} >Resumen Ejecutivo</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#introduccion" className={activeAnchor === 'introduccion_select' ? 'active' : null} onClick={() => scrollToAnchor('introduccion_select')} >Introducción: análisis de caminos del tráfico regional</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#datosusados" className={activeAnchor === 'datosusados_select' ? 'active' : null} onClick={() => scrollToAnchor('datosusados_select')} >Datos usados en este estudio</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#5500redes" className={activeAnchor === '5500redes_select' ? 'active' : null} onClick={() => scrollToAnchor('5500redes_select')} >Cobertura de la campaña de mediciones</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#impactolatencia" className={activeAnchor === 'impactolatencia_select' ? 'active' : null} onClick={() => scrollToAnchor('impactolatencia_select')} >Latencias</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#perspectivacaminos" className={activeAnchor === 'perspectivacaminos_select' ? 'active' : null} onClick={() => scrollToAnchor('perspectivacaminos_select')} >Caminos</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#caminoslatencias" className={activeAnchor === 'caminoslatencias_select' ? 'active' : null} onClick={() => scrollToAnchor('caminoslatencias_select')} >Latencias y caminos</a></li> 
                    <li onClick={() => abrirMenuLateral('')}><a href="#grafos" className={activeAnchor === 'grafos_select' ? 'active' : null} onClick={() => scrollToAnchor('grafos_select')} >Grafos</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#ixp" className={activeAnchor === 'ixp_select' ? 'active' : null} onClick={() => scrollToAnchor('ixp_select')} >IXP</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#geolocalizacion" className={activeAnchor === 'geolocalizacion_select' ? 'active' : null} onClick={() => scrollToAnchor('geolocalizacion_select')} >Geolocalización</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#conclusion" className={activeAnchor === 'conclusion_select' ? 'active' : null} onClick={() => scrollToAnchor('conclusion_select')} >Conclusiones</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo1" className={activeAnchor === 'anexo1_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo1_select')} >Anexo 1: Destinos de traceroutes salientes (ASNs)</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo2" className={activeAnchor === 'anexo2_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo2_select')} >Anexo 2: Rutas con circuitos por el extranjero</a></li>
                    <li>
                        <a href="#anexo3" className={activeAnchor === 'anexo3grafos_select' ? 'active' : null} onClick={() => abrirMenuLateral('anexo3grafos_select')} >Anexo 3: Grafos de los países de LAC</a>
                        <ul className={`${clickMenu === 'anexo3grafos_select' && 'active'}`}>
                            <li> <a href="#argentina" className={activeAnchor === 't1' ? 'active' : null} onClick={() => scrollToAnchor('t1')} >Argentina</a></li>
                             <li> <a href="#bolivia" className={activeAnchor === 't2' ? 'active' : null} onClick={() => scrollToAnchor('t2')} >Bolivia</a></li>
                            <li> <a href="#brasil" className={activeAnchor === 't3' ? 'active' : null} onClick={() => scrollToAnchor('t3')} >Brasil</a></li>
                            <li> <a href="#chile" className={activeAnchor === 't4' ? 'active' : null} onClick={() => scrollToAnchor('t4')} >Chile</a></li>
                            <li> <a href="#colombia" className={activeAnchor === 't5' ? 'active' : null} onClick={() => scrollToAnchor('t5')} >Colombia</a></li>
                            <li> <a href="#costa-rica" className={activeAnchor === 't6' ? 'active' : null} onClick={() => scrollToAnchor('t6')} >Costa Rica</a></li>
                             <li> <a href="#republica-dominicana" className={activeAnchor === 't7' ? 'active' : null} onClick={() => scrollToAnchor('t7')} >República Dominicana </a></li>
                            <li> <a href="#ecuador" className={activeAnchor === 't8' ? 'active' : null} onClick={() => scrollToAnchor('t8')} >Ecuador</a></li>
                            <li> <a href="#guyana-francesa" className={activeAnchor === 't9' ? 'active' : null} onClick={() => scrollToAnchor('t9')} >Guyana Francesa</a></li>
                            <li> <a href="#guatemala" className={activeAnchor === 't10' ? 'active' : null} onClick={() => scrollToAnchor('t10')} >Guatemala</a></li>
                            <li> <a href="#guyana" className={activeAnchor === 't11' ? 'active' : null} onClick={() => scrollToAnchor('t11')} >Guyana</a></li>
                            <li> <a href="#honduras" className={activeAnchor === 't12' ? 'active' : null} onClick={() => scrollToAnchor('t12')} >Honduras</a></li>
                            <li> <a href="#haiti" className={activeAnchor === 't13' ? 'active' : null} onClick={() => scrollToAnchor('t13')} >Haití</a></li>
                            <li> <a href="#mexico" className={activeAnchor === 't14' ? 'active' : null} onClick={() => scrollToAnchor('t14')} >México</a></li>
                            <li> <a href="#nicaragua" className={activeAnchor === 't15' ? 'active' : null} onClick={() => scrollToAnchor('t15')} >Nicaragua</a></li>
                            <li> <a href="#panama" className={activeAnchor === 't16' ? 'active' : null} onClick={() => scrollToAnchor('t16')} >Panamá</a></li>
                            <li> <a href="#peru" className={activeAnchor === 't17' ? 'active' : null} onClick={() => scrollToAnchor('t17')} >Perú</a></li>
                            <li> <a href="#paraguay" className={activeAnchor === 't18' ? 'active' : null} onClick={() => scrollToAnchor('t18')} >Paraguay</a></li>
                             <li> <a href="#el-salvador" className={activeAnchor === 't19' ? 'active' : null} onClick={() => scrollToAnchor('t19')} >El Salvador</a></li>
                            <li> <a href="#trinidad-y-tobago" className={activeAnchor === 't20' ? 'active' : null} onClick={() => scrollToAnchor('t20')} >Trinidad y Tobago</a></li>
                            <li> <a href="#uruguay" className={activeAnchor === 't21' ? 'active' : null} onClick={() => scrollToAnchor('t21')} >Uruguay</a></li>
                            <li> <a href="#venezuela" className={activeAnchor === 't22' ? 'active' : null} onClick={() => scrollToAnchor('t22')} >Venezuela</a></li> 
                        
                        
                        </ul> 
                    </li> 
                </ul>
            </nav>
        }
        {myLang === 'en' && 
            <nav>
                <button>Analysis of the paths followed by traffic within each country of LAC region<i className="fas fa-angle-down"></i></button>
                 
                <ul className="active">
                <li onClick={() => abrirMenuLateral('')}><a href="#resumen" className={activeAnchor === 'resumen_select' ? 'active' : null} onClick={() => scrollToAnchor('resumen_select')} >Executive Summary</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#introduccion" className={activeAnchor === 'introduccion_select' ? 'active' : null} onClick={() => scrollToAnchor('introduccion_select')} >Introduction: Regional Traffic Path Analyses</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#datosusados" className={activeAnchor === 'datosusados_select' ? 'active' : null} onClick={() => scrollToAnchor('datosusados_select')} >Data Used in this Study</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#5500redes" className={activeAnchor === '5500redes_select' ? 'active' : null} onClick={() => scrollToAnchor('5500redes_select')} >Scope of the Measurement Campaign</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#impactolatencia" className={activeAnchor === 'impactolatencia_select' ? 'active' : null} onClick={() => scrollToAnchor('impactolatencia_select')} >Latency</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#perspectivacaminos" className={activeAnchor === 'perspectivacaminos_select' ? 'active' : null} onClick={() => scrollToAnchor('perspectivacaminos_select')} >Paths</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#caminoslatencias" className={activeAnchor === 'caminoslatencias_select' ? 'active' : null} onClick={() => scrollToAnchor('caminoslatencias_select')} >Latency and Paths</a></li> 
                    <li onClick={() => abrirMenuLateral('')}><a href="#grafos" className={activeAnchor === 'grafos_select' ? 'active' : null} onClick={() => scrollToAnchor('grafos_select')} >Graphs</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#ixp" className={activeAnchor === 'ixp_select' ? 'active' : null} onClick={() => scrollToAnchor('ixp_select')} >Internet Exchange Points</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#geolocalizacion" className={activeAnchor === 'geolocalizacion_select' ? 'active' : null} onClick={() => scrollToAnchor('geolocalizacion_select')} >Geolocation</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#conclusion" className={activeAnchor === 'conclusion_select' ? 'active' : null} onClick={() => scrollToAnchor('conclusion_select')} >Conclusions</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo1" className={activeAnchor === 'anexo1_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo1_select')} >Annex 1: Outgoing Traceroute Destinations (ASNs)</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo2" className={activeAnchor === 'anexo2_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo2_select')} >Annex 2: Routes with Segments outside the Country</a></li>
                    <li>
                        <a href="#anexo3" className={activeAnchor === 'anexo3grafos_select' ? 'active' : null} onClick={() => abrirMenuLateral('anexo3grafos_select')} >Annex 3: Graphs for the Different Countries in LAC</a>
                        <ul className={`${clickMenu === 'anexo3grafos_select' && 'active'}`}>
                            <li> <a href="#argentina" className={activeAnchor === 't1' ? 'active' : null} onClick={() => scrollToAnchor('t1')} >Argentina</a></li>
                             <li> <a href="#bolivia" className={activeAnchor === 't2' ? 'active' : null} onClick={() => scrollToAnchor('t2')} >Bolivia</a></li>
                            <li> <a href="#brasil" className={activeAnchor === 't3' ? 'active' : null} onClick={() => scrollToAnchor('t3')} >Brasil</a></li>
                            <li> <a href="#chile" className={activeAnchor === 't4' ? 'active' : null} onClick={() => scrollToAnchor('t4')} >Chile</a></li>
                            <li> <a href="#colombia" className={activeAnchor === 't5' ? 'active' : null} onClick={() => scrollToAnchor('t5')} >Colombia</a></li>
                            <li> <a href="#costa-rica" className={activeAnchor === 't6' ? 'active' : null} onClick={() => scrollToAnchor('t6')} >Costa Rica</a></li>
                             <li> <a href="#republica-dominicana" className={activeAnchor === 't7' ? 'active' : null} onClick={() => scrollToAnchor('t7')} >República Dominicana </a></li>
                            <li> <a href="#ecuador" className={activeAnchor === 't8' ? 'active' : null} onClick={() => scrollToAnchor('t8')} >Ecuador</a></li>
                            <li> <a href="#guyana-francesa" className={activeAnchor === 't9' ? 'active' : null} onClick={() => scrollToAnchor('t9')} >Guyana Francesa</a></li>
                            <li> <a href="#guatemala" className={activeAnchor === 't10' ? 'active' : null} onClick={() => scrollToAnchor('t10')} >Guatemala</a></li>
                            <li> <a href="#guyana" className={activeAnchor === 't11' ? 'active' : null} onClick={() => scrollToAnchor('t11')} >Guyana</a></li>
                            <li> <a href="#honduras" className={activeAnchor === 't12' ? 'active' : null} onClick={() => scrollToAnchor('t12')} >Honduras</a></li>
                            <li> <a href="#haiti" className={activeAnchor === 't13' ? 'active' : null} onClick={() => scrollToAnchor('t13')} >Haití</a></li>
                            <li> <a href="#mexico" className={activeAnchor === 't14' ? 'active' : null} onClick={() => scrollToAnchor('t14')} >México</a></li>
                            <li> <a href="#nicaragua" className={activeAnchor === 't15' ? 'active' : null} onClick={() => scrollToAnchor('t15')} >Nicaragua</a></li>
                            <li> <a href="#panama" className={activeAnchor === 't16' ? 'active' : null} onClick={() => scrollToAnchor('t16')} >Panamá</a></li>
                            <li> <a href="#peru" className={activeAnchor === 't17' ? 'active' : null} onClick={() => scrollToAnchor('t17')} >Perú</a></li>
                            <li> <a href="#paraguay" className={activeAnchor === 't18' ? 'active' : null} onClick={() => scrollToAnchor('t18')} >Paraguay</a></li>
                             <li> <a href="#el-salvador" className={activeAnchor === 't19' ? 'active' : null} onClick={() => scrollToAnchor('t19')} >El Salvador</a></li>
                            <li> <a href="#trinidad-y-tobago" className={activeAnchor === 't20' ? 'active' : null} onClick={() => scrollToAnchor('t20')} >Trinidad y Tobago</a></li>
                            <li> <a href="#uruguay" className={activeAnchor === 't21' ? 'active' : null} onClick={() => scrollToAnchor('t21')} >Uruguay</a></li>
                            <li> <a href="#venezuela" className={activeAnchor === 't22' ? 'active' : null} onClick={() => scrollToAnchor('t22')} >Venezuela</a></li> 
                        
                        
                        </ul> 
                    </li> 
                </ul>
            </nav>
        }
        {myLang === 'pt' && 
            <nav>
                <button>Análise das rotas do tráfego dentro de cada país da região LAC <i className="fas fa-angle-down"></i></button>
                 
                <ul className="active">
                <li onClick={() => abrirMenuLateral('')}><a href="#resumen" className={activeAnchor === 'resumen_select' ? 'active' : null} onClick={() => scrollToAnchor('resumen_select')} >Resumo Executivo</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#introduccion" className={activeAnchor === 'introduccion_select' ? 'active' : null} onClick={() => scrollToAnchor('introduccion_select')} >Introdução: análise de caminhos do tráfego regional</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#datosusados" className={activeAnchor === 'datosusados_select' ? 'active' : null} onClick={() => scrollToAnchor('datosusados_select')} >Dados usados neste estudo</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#5500redes" className={activeAnchor === '5500redes_select' ? 'active' : null} onClick={() => scrollToAnchor('5500redes_select')} >Cobertura da campanha de medições</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#impactolatencia" className={activeAnchor === 'impactolatencia_select' ? 'active' : null} onClick={() => scrollToAnchor('impactolatencia_select')} >Latências</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#perspectivacaminos" className={activeAnchor === 'perspectivacaminos_select' ? 'active' : null} onClick={() => scrollToAnchor('perspectivacaminos_select')} >Caminhos</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#caminoslatencias" className={activeAnchor === 'caminoslatencias_select' ? 'active' : null} onClick={() => scrollToAnchor('caminoslatencias_select')} >Latências e caminhos</a></li> 
                    <li onClick={() => abrirMenuLateral('')}><a href="#grafos" className={activeAnchor === 'grafos_select' ? 'active' : null} onClick={() => scrollToAnchor('grafos_select')} >Grafos</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#ixp" className={activeAnchor === 'ixp_select' ? 'active' : null} onClick={() => scrollToAnchor('ixp_select')} >IXP</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#geolocalizacion" className={activeAnchor === 'geolocalizacion_select' ? 'active' : null} onClick={() => scrollToAnchor('geolocalizacion_select')} >Geolocalização</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#conclusion" className={activeAnchor === 'conclusion_select' ? 'active' : null} onClick={() => scrollToAnchor('conclusion_select')} >Conclusões</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo1" className={activeAnchor === 'anexo1_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo1_select')} >Anexo 1: Destinos de traceroutes de saída (ASN)</a></li>
                    <li onClick={() => abrirMenuLateral('')}><a href="#anexo2" className={activeAnchor === 'anexo2_select' ? 'active' : null} onClick={() => scrollToAnchor('anexo2_select')} >Anexo 2: Rotas com circuitos no exterior</a></li>
                    <li>
                        <a href="#anexo3" className={activeAnchor === 'anexo3grafos_select' ? 'active' : null} onClick={() => abrirMenuLateral('anexo3grafos_select')} >Anexo 3: Grafos dos países da ALC</a>
                        <ul className={`${clickMenu === 'anexo3grafos_select' && 'active'}`}>
                            <li> <a href="#argentina" className={activeAnchor === 't1' ? 'active' : null} onClick={() => scrollToAnchor('t1')} >Argentina</a></li>
                             <li> <a href="#bolivia" className={activeAnchor === 't2' ? 'active' : null} onClick={() => scrollToAnchor('t2')} >Bolivia</a></li>
                            <li> <a href="#brasil" className={activeAnchor === 't3' ? 'active' : null} onClick={() => scrollToAnchor('t3')} >Brasil</a></li>
                            <li> <a href="#chile" className={activeAnchor === 't4' ? 'active' : null} onClick={() => scrollToAnchor('t4')} >Chile</a></li>
                            <li> <a href="#colombia" className={activeAnchor === 't5' ? 'active' : null} onClick={() => scrollToAnchor('t5')} >Colombia</a></li>
                            <li> <a href="#costa-rica" className={activeAnchor === 't6' ? 'active' : null} onClick={() => scrollToAnchor('t6')} >Costa Rica</a></li>
                             <li> <a href="#republica-dominicana" className={activeAnchor === 't7' ? 'active' : null} onClick={() => scrollToAnchor('t7')} >República Dominicana </a></li>
                            <li> <a href="#ecuador" className={activeAnchor === 't8' ? 'active' : null} onClick={() => scrollToAnchor('t8')} >Ecuador</a></li>
                            <li> <a href="#guyana-francesa" className={activeAnchor === 't9' ? 'active' : null} onClick={() => scrollToAnchor('t9')} >Guyana Francesa</a></li>
                            <li> <a href="#guatemala" className={activeAnchor === 't10' ? 'active' : null} onClick={() => scrollToAnchor('t10')} >Guatemala</a></li>
                            <li> <a href="#guyana" className={activeAnchor === 't11' ? 'active' : null} onClick={() => scrollToAnchor('t11')} >Guyana</a></li>
                            <li> <a href="#honduras" className={activeAnchor === 't12' ? 'active' : null} onClick={() => scrollToAnchor('t12')} >Honduras</a></li>
                            <li> <a href="#haiti" className={activeAnchor === 't13' ? 'active' : null} onClick={() => scrollToAnchor('t13')} >Haití</a></li>
                            <li> <a href="#mexico" className={activeAnchor === 't14' ? 'active' : null} onClick={() => scrollToAnchor('t14')} >México</a></li>
                            <li> <a href="#nicaragua" className={activeAnchor === 't15' ? 'active' : null} onClick={() => scrollToAnchor('t15')} >Nicaragua</a></li>
                            <li> <a href="#panama" className={activeAnchor === 't16' ? 'active' : null} onClick={() => scrollToAnchor('t16')} >Panamá</a></li>
                            <li> <a href="#peru" className={activeAnchor === 't17' ? 'active' : null} onClick={() => scrollToAnchor('t17')} >Perú</a></li>
                            <li> <a href="#paraguay" className={activeAnchor === 't18' ? 'active' : null} onClick={() => scrollToAnchor('t18')} >Paraguay</a></li>
                             <li> <a href="#el-salvador" className={activeAnchor === 't19' ? 'active' : null} onClick={() => scrollToAnchor('t19')} >El Salvador</a></li>
                            <li> <a href="#trinidad-y-tobago" className={activeAnchor === 't20' ? 'active' : null} onClick={() => scrollToAnchor('t20')} >Trinidad y Tobago</a></li>
                            <li> <a href="#uruguay" className={activeAnchor === 't21' ? 'active' : null} onClick={() => scrollToAnchor('t21')} >Uruguay</a></li>
                            <li> <a href="#venezuela" className={activeAnchor === 't22' ? 'active' : null} onClick={() => scrollToAnchor('t22')} >Venezuela</a></li> 
                        
                        
                        </ul> 
                    </li> 
                </ul>
            </nav>
        }
    </>)
}

export default MenuLateralConectividad2022
