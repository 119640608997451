import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Introduccion(props) {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p2">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-7">Introducción</MatchText></h2>
                    <p>
                        <MatchText id="match-8">El término conectividad es utilizado a menudo en la industria de Internet, pero su significado puede variar según el contexto en el que se esté abordando. Puede ser medida según su capacidad de ancho de banda, su número de saltos, o bien, en el caso de este artículo, según su latencia. En este sentido, decimos que dos lugares se encuentran bien conectados cuando la latencia entre ellos es baja, es decir, el tiempo que le lleva a un mensaje ir de origen a destino es bajo.</MatchText></p>
                    <p>
                        <MatchText id="match-9">Desde LACNIC buscamos conocer en mayor detalle las características de la interconexión de redes en América Latina y el Caribe, con el fin de que los operadores cuenten con información que les sirva a sus estrategias de crecimiento. A partir de ahí, es muy útil realizar mediciones de conectividad que abarquen toda la región, incluida toda la región de El Caribe y no sólo la región LAC como región de RIR. Típicamente las mediciones de conectividad se realizan entre un origen y un destino, o entre pocos orígenes y pocos destinos; por lo general, las mediciones se lanzan desde nodos en nuestra propia infraestructura, o hacia nuestra propia infraestructura. Pero para obtener mediciones de conectividad que abarquen toda la región es necesario lanzar mediciones desde redes de terceros, lo cual es un desafío que requiere la colaboración de muchos actores. A continuación se ofrece un listado de plataformas que ofrecen la posibilidad de lanzar mediciones desde redes de terceros, y una breve explicación de sus características:</MatchText></p>
                    <ul>
                        <li><a href="https://atlas.ripe.net/" target="_blank" rel="noreferrer">
                            <MatchText id="match-10">RIPE Atlas </MatchText></a>
                            <MatchText id="match-11">es un claro ejemplo, donde los usuarios que colaboran deciden hospedar una sonda (puede ser de hardware o de software) y mantenerla conectada a Internet. De esa forma otros usuarios de la plataforma pueden utilizar esa sonda para lanzar mediciones. Si bien la presencia de sondas de RIPE Atlas en LAC
                            </MatchText><a href="https://simon.lacnic.net/atlas/" target="_blank" rel="noreferrer">


                                <MatchText id="match-12">ha ido mejorando</MatchText> </a>


                            <MatchText id="match-13">a lo largo del tiempo, aún no hay presencia suficiente como para realizar estudios que abarquen toda la región.</MatchText></li>
                        <li><a href="https://www.caida.org/projects/ark/" target="_blank" rel="noreferrer">

                            <MatchText id="match-14">CAIDA Ark</MatchText></a>


                            <MatchText id="match-15"> , o Archipielago, es una plataforma similar a RIPE Atlas, basada en Raspberry Pi generación 2. Lamentablemente, sólo cuenta con 11 sondas en 10 sistemas autónomos de la región LAC.</MatchText></li>
                        <li><a href="https://www.measurementlab.net/" target="_blank" rel="noreferrer">

                            <MatchText id="match-16">M-Lab</MatchText> </a>,

                            <MatchText id="match-17">
                                o Measurement Lab, es una plataforma que ofrece una serie de herramientas para medir diferentes parámetros de red. Lamentablemente, estas herramientas no ponen foco en la medición de latencia entre redes de terceros, sino en mediciones de ancho de banda a través de NDT (Network Diagnosis Tools) entre el cliente y los servidores de M-Lab (que no son muchos en la región LAC).</MatchText></li>
                        <li>

                            <MatchText id="match-18">Por otra parte </MatchText><a href="https://www.broadbandspeedchecker.co.uk/" target="_blank" rel="noreferrer">


                                <MatchText id="match-19">Speedchecker</MatchText></a>


                            <MatchText id="match-20">, una plataforma con similares funcionalidades, ofrece mejor cobertura en la región LAC. A diferencia de RIPE Atlas, esta plataforma cuenta únicamente con sondas de software, lo que hace que el sistema sea más inestable que las demás. Sin embargo, dado que en estudios anteriores habíamos utilizado esta plataforma, decidimos utilizarla nuevamente y así poder comparar los resultados con mayor seguridad.</MatchText></li>
                    </ul>
                </>}
                {myLang === 'en' && <>
                    <h2>

                        <MatchText id="match-7">Introduction</MatchText></h2>
                    <p>

                        <MatchText id="match-8">The term connectivity is often used in the Internet industry, yet its meaning may vary depending on the context. Connectivity can be measured based on bandwidth capacity, number of hops, or, in the case of this article, latency. In this sense, when we say that two locations are well connected, it means that the latency between them is low, i.e., the time it takes for a message to travel from source to destination is short</MatchText></p>
                    <p>

                        <MatchText id="match-9">We at LACNIC wish to understand in greater detail the characteristics of network interconnection in Latin America and the Caribbean so that operators will have access to information they can leverage when designing their growth strategies. With this in mind, it is very useful to perform connectivity measurements that cover the whole region, including the entire Caribbean region and not just the LACNIC service region. Connectivity measurements are typically performed between one origin and one destination, or between a few origins and a few destinations. Measurements are generally initiated from nodes in our own infrastructure, or towards our own infrastructure. However, in order to obtain connectivity measurements that cover the entire region, it is necessary to initiate measurements from third-party networks, a challenge that requires the collaboration of multiple actors. Below is a list of platforms that offer the possibility of initiating measurements from third-party networks, along with a brief explanation of their characteristics.</MatchText></p>
                    <ul>
                        <li><a href="https://atlas.ripe.net/" target="_blank" rel="noreferrer">

                            <MatchText id="match-10">RIPE Atlas </MatchText></a>

                            <MatchText id="match-11">
                                is a well-known example that encourages users who wish to collaborate to host a probe (hardware or software) and keep it connected to the Internet. This allows other users of the platform to use these probes to initiate measurements. While the number of RIPE Atlas probes in LAC
                            </MatchText><a href="https://simon.lacnic.net/atlas/" target="_blank" rel="noreferrer">

                                <MatchText id="match-12">has been increasing </MatchText></a>


                            <MatchText id="match-13">over time, their presence is still insufficient to conduct studies covering the entire region.</MatchText></li>
                        <li><a href="https://www.caida.org/projects/ark/" target="_blank" rel="noreferrer">

                            <MatchText id="match-14">CAIDA Ark </MatchText></a>


                            <MatchText id="match-15">, o Archipielago, es una plataforma similar a RIPE Atlas, basada en Raspberry Pi generación 2. Lamentablemente, sólo cuenta con 11 sondas en 10 sistemas autónomos de la región LAC.</MatchText></li>
                        <li><a href="https://www.measurementlab.net/" target="_blank" rel="noreferrer">

                            <MatchText id="match-16">M-LAB</MatchText></a>


                            <MatchText id="match-17">, o Measurement Lab, es una plataforma que ofrece una serie de herramientas para medir diferentes parámetros de red. Lamentablemente, estas herramientas no ponen foco en la medición de latencia entre redes de terceros, sino en mediciones de ancho de banda a través de NDT (Network Diagnosis Tools) entre el cliente y los servidores de M-Lab (que no son muchos en la región LAC).</MatchText></li>
                        <li>

                            <MatchText id="match-18">On the other hand</MatchText><a href="https://www.broadbandspeedchecker.co.uk/" target="_blank" rel="noreferrer">

                                <MatchText id="match-19">Speedchecker</MatchText> </a>


                            <MatchText id="match-20">, is a platform with similar functionalities that offers better coverage in the LAC region. Unlike RIPE Atlas, this platform uses only software probes, which makes the system more unstable than the others. However, given that we had used this platform in prior studies, we decided to use it once again so we would be able to compare results with greater confidence.</MatchText></li>
                    </ul>

                </>}
                {myLang === 'pt' && <>
                    <h2>

                        <MatchText id="match-7">Introdução</MatchText></h2>
                    <p>

                        <MatchText id="match-8">O termo conectividade é frequentemente usado na indústria da Internet, mas seu significado pode variar dependendo do contexto em que está sendo abordado. Pode ser medida de acordo com sua capacidade de largura de banda, seu número de saltos ou, no caso deste artigo, de acordo com sua latência. Nesse sentido, dizemos que dois lugares estão bem conectados quando a latência entre eles é baixa, ou seja, o tempo que uma mensagem leva para ir da origem ao destino é baixo.</MatchText></p>
                    <p>

                        <MatchText id="match-9">No LACNIC, buscamos conhecer com mais detalhes as características da interconexão de redes na América Latina e o Caribe, afim de que as operadoras tenham informações que sirvam às suas estratégias de crescimento. A partir daí, é muito útil realizar medições de conectividade que abranjam a região toda, incluindo toda a região do Caribe e não apenas a região da LAC como região do RIR. Normalmente, as medições de conectividade são feitas entre uma origem e um destino, ou entre poucas origens e poucos destinos; as medições são geralmente lançadas a partir de nós em nossa própria infraestrutura, ou para nossa própria infraestrutura. Para poder obter medições de conectividade que abranjam a região toda é preciso lançar medições desde redes de terceiros, o que é um desafio que requer a colaboração de muitos atores. Segue abaixo uma lista de plataformas que oferecem a possibilidade de lançar medições desde redes de terceiros, e uma breve explicação de suas características:</MatchText></p>
                    <ul>
                        <li><a href="https://atlas.ripe.net/" target="_blank" rel="noreferrer">

                            <MatchText id="match-10">RIPE Atlas </MatchText></a>


                            <MatchText id="match-11"> é um exemplo claro, em que os usuários colaboradores decidem hospedar uma sonda (pode ser de hardware ou software) e mantê-la conectada à Internet. Assim, outros usuários da plataforma podem usar essa sonda para lançar medições. Embora a presença de sondas do RIPE Atlas na LAC  </MatchText>
                            <a href="https://simon.lacnic.net/atlas/" target="_blank" rel="noreferrer">

                                <MatchText id="match-12">tenha melhorado</MatchText></a>


                            <MatchText id="match-13">ao longo do tempo, ainda não há presença suficiente como para fazer estudos que abranjam a região toda.</MatchText></li>
                        <li><a href="https://www.caida.org/projects/ark/" target="_blank" rel="noreferrer">

                            <MatchText id="match-14">CAIDA Ark</MatchText> </a>


                            <MatchText id="match-15"> , o Archipielago, es una plataforma similar a RIPE Atlas, basada en Raspberry Pi generación 2. Lamentablemente, sólo cuenta con 11 sondas en 10 sistemas autónomos de la región LAC.</MatchText></li>
                        <li><a href="https://www.measurementlab.net/" target="_blank" rel="noreferrer">

                            <MatchText id="match-16">M-LAB</MatchText> </a>


                            <MatchText id="match-17">, o Measurement Lab, es una plataforma que ofrece una serie de herramientas para medir diferentes parámetros de red. Lamentablemente, estas herramientas no ponen foco en la medición de latencia entre redes de terceros, sino en mediciones de ancho de banda a través de NDT (Network Diagnosis Tools) entre el cliente y los servidores de M-Lab (que no son muchos en la región LAC).</MatchText></li>
                        <li>

                            <MatchText id="match-18">Do outro lado,</MatchText>
                            <a href="https://www.broadbandspeedchecker.co.uk/" target="_blank" rel="noreferrer">

                                <MatchText id="match-19">Speedchecker</MatchText> </a>


                            <MatchText id="match-20">, uma plataforma com funcionalidades semelhantes, oferece melhor cobertura na região da LAC. Ao contrário do RIPE Atlas, esta plataforma possui apenas sondas de software, o que torna o sistema mais instável que os outros. No entanto, como já tínhamos usado essa plataforma em estudos anteriores, decidimos usála novamente e assim podermos comparar os resultados com maior segurança.</MatchText></li>
                    </ul>
                </>}
            </div>


        </div>
    );
}

export default Introduccion;