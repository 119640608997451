import React, { useContext } from 'react';
import { LangContext, ReferenceContext } from '../../../context/DataContext';
import { MatchText } from 'react-ctrl-f';

function Metodologia(props) {
    const { myLang } = useContext(LangContext)
    const { scrollToReference } = useContext(ReferenceContext)
    return (
        <div>
            <div id="bgp3">
                {myLang === 'es' && <>
                    <h2>
                        <MatchText id="match-10">Metodología</MatchText>
                    </h2>
                    <h3 id="a1">
                        <MatchText id="match-11">Objetivos planteados</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-12">En este estudio se propone analizar las distintas características de la interconexión de la región, con base en el procesamiento de tablas de ruteo BGP globales, con el fin de comparar características básicas de las publicaciones de rutas de la región, obtener conclusiones sobre el comportamiento de los operadores en la publicación de prefijos, detectar los sitios donde se intercambia el tráfico con mayor cantidad de operadores, conocer los proveedores de tránsito upstream que predominan en cada país, la cantidad promedio de sistemas autónomos por los que pasa el tráfico y el impacto de esto en la estabilidad del ruteo regional, entre otros factores.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-14">Así, determinamos un conjunto de parámetros que permitirán comparar el estado actual y la evolución de la Internet regional, con los siguientes objetivos:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-15"> Conocer la naturaleza de los sistemas autónomos de cada país, cuántos sistemas autónomos brindan servicio de tránsito localmente y cuáles conectan con el resto del mundo.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-16"> Obtener datos generales sobre las tablas de la región, como cantidad de prefijos anunciados, longitud promedio de los prefijos IPv4 e IPv6, longitud promedio de los AS path.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-17"> Obtener otros datos que puedan contribuir a evaluar el desarrollo de Internet, como cantidad de IXP.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-18"> Conocer la interconexión, tanto entre países como dentro de cada uno.</MatchText>
                        </li>
                    </ul>
                    <h3 id="a2">
                        <MatchText id="match-19">Fuentes de datos</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-20">Para analizar las tablas de ruteo BGP globales, nos valdremos del Servicio de Información de Enrutamiento (RIS, del inglés, «Routing Information Service») </MatchText>
                        <a href="#ref-2" onClick={() => scrollToReference('ref-2')}>(2)</a>
                        <MatchText id="match-21">  de RIPE NCC. El RIS colecta y almacena información de rutas en Internet obtenida de distintas partes del mundo. Esta red global está compuesta por colectores (Remote Route Collectors) que, por lo general, están ubicados en IXP.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-22">Los colectores almacenan la información en el formato MRT descrito en el RFC 6396.</MatchText>
                        <a href="#ref-3" onClick={() => scrollToReference('ref-3')}>(3)</a>
                        <MatchText id="match-23">  Cada colector almacena todos los paquetes BGP intercambiados cada 15 minutos (updates) y un dump de sus tablas de ruteo cada 8 horas (ribs).</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-24"> Hasta el momento, RIPE cuenta con 20 colectores que actualmente se encuentran en operaciones:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-25"> rrc00, en Ámsterdam, Países Bajos</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-26"> rrc01, en Londres, Reino Unido</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-27"> rrc04, en Ginebra, Suiza</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-28"> rrc05, en Viena, Austria</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-29"> rrc06, en Otemachi, Japón</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-30"> rrc07, en Estocolmo, Suecia</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-31"> rrc10, en Milán, Italia</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-32"> rrc11, en Nueva York, Estados Unidos</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-33"> rrc12, en Frankfurt, Alemania</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-34"> rrc13, en Moscú, Rusia</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-35"> rrc14, en Palo Alto, Estados Unidos</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-36"> rrc15, en San Pablo, Brasil</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-37"> rrc16, en Miami, Estados Unidos</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-38"> rrc18, en Barcelona, España</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-39"> rrc19, en Johannesburgo, Sudáfrica</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-40"> rrc20, en Zurich, Suiza</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-41"> rrc21, en París, Francia</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-42"> rrc22, en Bucarest, Rumania</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-43"> rrc23, en Singapur</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-44"> rrc24, en Montevideo, Uruguay</MatchText>
                        </li>
                    </ul>
                    <p>
                        <MatchText id="match-45">Para realizar este estudio, utilizamos los dumps de las tablas de ruteo (ribs) del colector rrc00. El procesamiento se realizó utilizando la herramienta PyBGPStream: </MatchText>
                        <a href="#ref-4" onClick={() => scrollToReference('ref-4')}>(4)</a>
                        <MatchText id="match-46">  una librería Python para usar BGPReader </MatchText>
                        <a href="#ref-5" onClick={() => scrollToReference('ref-5')}>(5)</a>
                        <MatchText id="match-47">  de CAIDA BGPStream, la cual permite procesar, de forma sencilla y eficiente, los datos publicados por los colectores de RIPE.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-48">Además de conocer las tablas de ruteo para procesar las rutas de Internet, necesitamos asociar los recursos a los distintos países para poder hacer un estudio teniendo en cuenta los latinoamericanos. Para lograr esta asociación, utilizaremos el archivo delegated-extended. Este registro contiene una actualización diaria del estado de la distribución de los recursos numéricos de Internet (direcciones IPv4 e IPv6 y números de sistemas autónomos). Este archivo es generado por cada registro regional bajo un formato estandarizado por la Number Resource Organization (NRO) </MatchText>
                        <a href="#ref-6" onClick={() => scrollToReference('ref-6')}>(6)</a>
                        <MatchText id="match-49">  y luego consolidado en un único archivo.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-50">Por último, para conocer los puntos de intercambio (IXP) que tiene cada país o territorio, utilizaremos un dataset generado por CAIDA,</MatchText>
                        <a href="#ref-7" onClick={() => scrollToReference('ref-7')}>(7)</a>
                        <MatchText id="match-51">  que utiliza PCH </MatchText>
                        <a href="#ref-8" onClick={() => scrollToReference('ref-8')}>(8)</a>
                        <MatchText id="match-52">  como fuente de estos datos. Esta fuente cuenta con un número menor de IXP al compararla con otras referencias, como IXPDB, </MatchText>
                        <a href="#ref-9" onClick={() => scrollToReference('ref-9')}>(9)</a>
                        <MatchText id="match-53">  pero por el momento es la única que dispone de un registro de datos hacia atrás y en un formato fácilmente obtenible.</MatchText>
                    </p>

                    <h3 id="a3">
                        <MatchText id="match-54">Procesamiento de la información</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-55">Para lograr los objetivos planteados, es clave definir cómo clasificar los sistemas autónomos en cada país, para luego procesar nuestras fuentes de datos propuestas y poder obtener los parámetros e indicadores deseados. El ejemplo a continuación nos permitirá establecer estas clasificaciones.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-56">Figura 1: Representación del anuncio BGP de una ruta que se propaga entre sistemas autónomos que están dentro y fuera de un país o territorio</MatchText>
                    </p>
                    <figure><img alt="Ver" src="/images/interconexion-bgp/r1.png" /> </figure>
                    <p>
                        <MatchText id="match-57">En la figura 1, se puede observar el AS-1, el cual anuncia sus prefijos mediante BGP al AS-2, y este propaga dicho anuncio hacia los demás sistemas autónomos. Aquí es importante clasificar el rol que cumple cada uno, es por eso que se han dividido en 3 colores. El AS-1 (naranja) es el que anuncia prefijos, por lo tanto, lo clasificaremos como un AS de origen dentro de este país. Los sistemas azules (AS-2, AS-3 y AS-4) son los que dan tránsito: conectan a AS-1 dentro del territorio del país. Por último, el AS-5 (rojo) continúa dando tránsito, pero es de origen extranjero. A este tipo de sistemas autónomos los clasificaremos como upstream y son los que le dan conectividad a un país. El anuncio puede continuar propagándose por otros sistemas autónomos, pero esos caminos ya no son relevantes a los fines del análisis local en el país del AS de origen.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-58">Para obtener esta información, procesaremos las tablas de enrutamiento globales, almacenadas y publicadas por el colector rrc00 de RIPE NCC. De cada entrada de su tabla, lo que nos interesa es el conjunto de pares de prefijo y AS path de la siguiente forma:</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-59">Prefijo 1/longitud | AS-X … AS-3 AS-2 AS-1</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-60">Observamos el AS path de derecha a izquierda, ya que nos interesa conocer el sistema autónomo de origen del prefijo e ir obteniendo, a partir de este, los demás sistemas que son relevantes, según se reflejó en la figura 1.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-61">Pero, para darle sentido a esta información, necesitamos asociar cada prefijo y sistema autónomo a un país, por lo que utilizaremos el archivo de estadísticas delegated-extended que los RIR publican, para poder lograr esta asociación entre recursos y territorio. Así, cada entrada puede interpretarse como:</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-62">[País del prefijo] | [País ASX] … [País AS-3] [País AS-2] [País AS-1]</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-63">Aquí ya podemos ir filtrando todas las entradas de la tabla de ruteo, para quedarnos solo con las que son de prefijos registrados en países cubiertos por LACNIC. Luego nos queda analizar el AS path, de derecha a izquierda. Si el país del AS-1 coincide con el del prefijo, es una ruta que nos interesa analizar y consideramos ese AS-1 como un sistema autónomo de origen del país. Luego pasamos al AS-2. Si este también pertenece al mismo país, entonces es considerado un AS de tránsito y se pasa a analizar el AS-3 con el mismo procedimiento que el AS-2. Por el contrario, si pertenece a otro país, se considera un AS upstream y ya no es necesario continuar analizando la cadena del AS path.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-64">Si aplicamos esta lógica al ejemplo de la figura 1, obtenemos el siguiente resultado:</MatchText>
                    </p>
                    <figure><img alt="Ver" src="/images/interconexion-bgp/r2_es.png" /> </figure>

                    <p>
                        <MatchText id="match-65">Es decir, a partir de esa entrada de la tabla de ruteo analizada, podemos asociar a ese país el AS-1 como origen, AS-2, AS-3 y AS-4 como tránsito y AS-5 como upstream.</MatchText>
                    </p>
                    {/*  */}
                    <h3 id="a4">
                        <MatchText id="match-66">Datasets generados</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-67">Todos los datos necesarios para cumplir con objetivos planteados inicialmente se almacenan en datasets, lo cual abre las posibilidades de realizar otros estudios más profundos o con otros enfoques. Por lo tanto, se estableció el desarrollo de un conjunto de scripts que realizan el procesamiento en etapas con resultados parciales, hasta obtener un dataset final con los datos resumidos, que son, a su vez, la principal fuente de este informe.</MatchText>
                    </p>

                    <figure>
                        <img alt="Ver" src="/images/interconexion-bgp/r3.png" />
                        <figcaption> 
                                <MatchText id="match-68">Figura 2: Fuentes, datos y flujo de procesamiento de datos para obtener la información deseada</MatchText>
                            
                        </figcaption>
                    </figure>
                    <p>
                        <MatchText id="match-69">En la figura 2, puede observarse el flujo de datos y procesamiento que se realiza. Su resultado es un conjunto de datasets que contienen información sobre el ruteo de los distintos países de Latinoamérica, en un determinado día. Estos datasets son:</MatchText>
                    </p>
                    <dl className="descriptive_list">
                        <div>
                            <dt>
                                <MatchText id="match-70">country-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-71"> Por cada país, lista los ASN según su clasificación (origen, tránsito o upstream) y, además, diferencia según protocolo IP.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-72">prefix-data-&lt; t&gt;.csv  </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-73">Indica datos de cada prefijo anunciado y registrado en LACNIC, como el país y ASN de origen, y la cantidad de rutas y saltos en total.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-74">as-data-&lt; t&gt;.csv  </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-75">Registra datos por cada AS de la región, como los prefijos que anuncia y su relación con otros sistemas autónomos.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-76">country-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-77">Resume la información de country-data-.csv, indicando la cantidad de sistemas autónomos por país según su clasificación (origen, tránsito o upstream).</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-78">prefix-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-79">Agrupa los datos registrados en prefix-data-.csv por país, lo cual permite obtener, de cada uno, datos generales, como longitud promedio de prefijos y de AS path, cantidad total de prefijos anunciados, etc.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-80">ixp-data-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-81">Indica la cantidad de puntos de intercambio registrados en cada país.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-82">country-routing-stat-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-83">Agrupa información de country-summary-.csv, prefix-summary-&lt; t&gt;.csv y ixp-data-summary-.csv, para obtener un conjunto de datos relevantes sobre cada país, respecto a su desarrollo de Internet.</MatchText>
                            </dd>
                        </div>
                    </dl>
                    <p>
                        <MatchText id="match-84">Estos datos resultantes poseen toda la información necesaria que analizaremos en las siguientes secciones de este informe.</MatchText>
                    </p>
                </>}
                {myLang === 'en' && <>
                    <h2>
                        <MatchText id="match-10">Methodology</MatchText>
                    </h2>
                    <h3 id="a1">
                        <MatchText id="match-11">Objectives</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-12">This study seeks to analyze various characteristics of regional interconnection based on the processing of global BGP routing tables for the purpose of comparing the basic characteristics of route announcements in the region, deriving conclusions regarding operators&rsquo; behavior when publishing their prefixes, detecting locations where traffic is exchanged with the largest number of operators, determining the dominant upstream transit providers in each country, the average number of autonomous systems traversed by traffic flows and their impact on regional routing stability, among other factors.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-13">Therefore, we determined a set of parameters that would allow us to compare the current status and evolution of the regional Internet with the following objectives:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-14"> To understand the nature of the autonomous systems in each country, how many autonomous systems provide local transit service, and which are connected to the rest of the world.
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-15"> To obtain general data on regional routing tables, e.g., the number of announced prefixes, the average length of IPv4 and IPv6 prefixes, and the average length of AS paths.
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-16"> To obtain additional data that can help assess Internet development, including the number of IXPs.
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-17"> To obtain data on interconnection, both between different countries as well as within each country.</MatchText>
                        </li>
                    </ul>
                    <h3 id="a2">
                        <MatchText id="match-18">Data Sources</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-19">Global BGP routing tables will be analyzed using RIPE NCC's RIS </MatchText>
                        <a href="#ref-2" onClick={() => scrollToReference('ref-2')}>(2)</a>
                        <MatchText id="match-20"> service. The Routing Information Service (RIS)  collects and stores Internet routing information obtained from different parts of the world. This global network is comprised of Remote Route Collectors that are typically located at the IXPs.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-21">
                            These collectors store the information in the MRT format described in RFC 6396 </MatchText>
                        <a href="#ref-3" onClick={() => scrollToReference('ref-3')}>(3)</a>
                        <MatchText id="match-22">. Every 15 minute, each collector stores all the BGP packets that have been exchanged (updates), plus a dump of its routing tables every eight hours (RIBs).
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-23">RIPE currently has 20 collectors in operation:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-24"> rrc00 in Amsterdam, the Netherlands
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-25"> rrc01 in London, United Kingdom
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-26"> rrc04 in Geneva, Switzerland
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-27"> rrc05 in Vienna, Austria
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-28"> rrc06 in Otemachi, Japan
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-29"> rrc07 in Stockholm, Sweden
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-30"> rrc10 in Milan, Italy
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-31"> rrc11 in New York, USA
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-32"> rrc12 in Frankfurt, Germany
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-33"> rrc13 in Moscow, Russia
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-34"> rrc14 in Palo Alto, USA
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-35"> rrc15 in São Paulo, Brazil
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-36"> rrc16 in Miami, USA
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-37"> rrc18 in Barcelona, Spain
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-38"> rrc19 in Johannesburg, South Africa
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-39"> rrc20 in Zurich, Switzerland
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-40"> rrc21 in Paris, France
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-41"> rrc22 in Bucharest, Romania
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-42"> rrc23 in Singapore
                            </MatchText>
                        </li>
                        <li>
                            <MatchText id="match-43"> rrc24 in Montevideo, Uruguay</MatchText>
                        </li>
                    </ul>
                    <p>
                        <MatchText id="match-44">For this study, we used the routing table dumps (RIBs) of collector rrc00. The data was processed with pyBGPStream </MatchText>
                        <a href="#ref-4" onClick={() => scrollToReference('ref-4')}>(4)</a>
                        <MatchText id="match-45">, a Python library to use CAIDA BGPStream's BGPReader </MatchText>
                        <a href="#ref-5" onClick={() => scrollToReference('ref-5')}>(5)</a>
                        <MatchText id="match-46"> , which allows the data published by RIPE collectors to be processed simply and efficiently.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-47">
                            In addition to the routing tables, in order to conduct a study that considers the countries of Latin America, we need to map allocated number resources to the different countries. To do so, we will use the delegated-extended file. This record contains a daily updated report of the distribution of Internet number resources (IPv4 and IPv6 addresses, autonomous system numbers). Each Regional Registry generates a file in a standardized format defined by the Number Resource Organization (NRO) </MatchText>
                        <a href="#ref-6" onClick={() => scrollToReference('ref-6')}>(6)</a>
                        <MatchText id="match-48"> and these files are then consolidated to obtain a single file.

                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-49">Finally, to determine which Internet Exchange Points (IXPs) operate in each country or territory, we will use the CAIDA Internet eXchange Points Dataset </MatchText>
                        <a href="#ref-7" onClick={() => scrollToReference('ref-7')}>(7)</a>
                        <MatchText id="match-50">, which uses PCH </MatchText>
                        <a href="#ref-8" onClick={() => scrollToReference('ref-8')}>(8)</a>
                        <MatchText id="match-51"> as its data source. Compared with other references such as IXPDB </MatchText>
                        <a href="#ref-9" onClick={() => scrollToReference('ref-9')}>(9)</a>
                        <MatchText id="match-52">, this source contains a smaller number of IXPs. At the moment, however, it is the only one that provides historical data and in an easy-to-obtain format.</MatchText>
                    </p>
                    <h3 id="a3">
                        <MatchText id="match-53">Data Processing</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-54">Achieving our stated objectives requires defining how the autonomous systems in each country will be classified, and then processing our proposed data sources to obtain the desired parameters and indicators. The example below will allow us to establish this classification.</MatchText>
                    </p>

                    <figure>
                        <img alt="Ver" id="r1" src="https://imasd.lacnic.net/images_inf/Interconexion_BGP_LAC/r1.png" />
                        <figcaption>
                            <MatchText id="match-55">Figure 1: Representation of the BGP announcement of a route propagated between autonomous systems inside and outside a country or territory.</MatchText>
                        </figcaption>
                    </figure>
                    <p>
                        <MatchText id="match-56">Figure 1 shows AS-1, which announces its prefixes via BGP to AS-2 which, in turn, propagates this announcement to the remaining autonomous systems. Here, it is important to classify the role they each play, which is why they have been represented using three different colors. AS-1 (orange) announces prefixes, therefore it will be classified as an origin AS within this country. The blue systems (AS-2, AS-3, AS-4) provide transit: they connect to AS-1 within the country's territory. Finally, AS-5 (red) also provides transit, yet it is located outside the country. This type of autonomous systems will be classified as upstream and they are the ones that provide connectivity to a country. The announcement may continue to propagate to other autonomous systems, but these paths are no longer relevant for the purpose of a local analysis within the country of the origin AS.

                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-57">This information is obtained by processing the global routing tables stored and published by RIPE NCC collector rrc00. For each entry in the table, we are interested in the set of prefix/as-path pairs as follows:</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-58">Prefix1/length | AS-X … AS-3 AS-2 AS-1</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-59">We will analyze the AS path from right to left, as we want to determine the origin autonomous system and, from there, all other relevant systems as described in Figure 1. 7
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-60"> However, in order to make sense of this information, we need to map each prefix and autonomous system to a country. To establish this link between resources and territories, we will use the delegated-extended statistics file published by the RIRs.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-61">  Thus, each entry can be interpreted as follows:
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-62"> [Country of the prefix] | [Country of ASX] … [Country of AS-3] [Country of AS2] [Country of AS-1]
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-63">   Now we can start filtering the routing table entries, leaving only those that correspond to prefixes registered in the countries of the LACNIC service region. The AS path must be analyzed from right to left. If the country of AS-1 matches the country of the prefix, it is a route we are interested in analyzing and AS-1 will be considered an origin autonomous system for that country. We must then analyze AS-2. If this AS also belongs to the same country, it is considered a transit AS. Next, we must analyze AS-3 using the same procedure we used for AS-2. If it belongs to another country, it is considered an upstream AS and it is no longer necessary to continue analyzing the AS path.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-64">  If we apply this logic to the example shown in Figure 1, we obtain the following result:</MatchText>
                    </p>
                    <figure><img id="r2" alt="Ver" src="https://imasd.lacnic.net/images_inf/Interconexion_BGP_LAC/r2_es.png" /></figure>
                    <p>
                        <MatchText id="match-65">
                            In other words, based on this entry contained in the routing table we analyzed, it is possible to establish that, for this particular country, AS-1 is an Origin AS; AS-2, AS-3 and AS-4 are Transit autonomous systems; and AS-5 is an Upstream AS.
                        </MatchText>
                    </p>
                    <h3 id="a4">
                        <MatchText id="match-66">Generated Datasets</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-67">All the data required to meet the stated objectives are stored in datasets, which opens up the possibility of conducting other, more in-depth studies or applying different approaches. Therefore, it was decided to develop a set of scriptsthat would process the data in stages, obtaining partial results before producing a final dataset with summary data, which, in turn, are the main source for this report.</MatchText>
                    </p>

                    <figure>
                        <img alt="Ver" src="https://imasd.lacnic.net/images_inf/Interconexion_BGP_LAC/r3.png" />
                        <figcaption>
                            <MatchText id="match-68">Figure 2: Data sources and processing flow to obtain the desired information.</MatchText>
                        </figcaption>
                    </figure>
                    <p>
                        <MatchText id="match-69">
                            Figure 2 shows the data processing flow. The result is a set of datasets that contain routing information for the different countries of Latin America, i.e., a snapshot of routing information on a specific day. These datasets are as follows:
                        </MatchText>
                    </p>

                    <dl className="descriptive_list">
                        <div>
                            <dt>
                                <MatchText id="match-70">country-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-71">Lists the ASNs for each country considering their classification (origin, transit or upstream) and separates them based on the IP protocol.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-72">prefix-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-73">Specifies data for each prefix announced and registered at LACNIC, including the country and origin ASN as well as the number of routes and total hops.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-74">as-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-75">Records data for each AS in the region, as well as the prefixes they announce and their relationship with other autonomous systems. </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-76">country-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-77">Summarizes the information in country-data- .csv, specifying the number of autonomous systems by country according to their classification (origin, transit or upstream) </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-78">prefix-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-79">Groups the data included in prefix-data- .csv by country, which allows obtaining general data for each one, such as average prefix and AS path length, total number of prefixes announced, etc. </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-80">ixp-data-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-81">Specifies the number of Internet exchange points in each country. </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-82">country-routing-stat-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-83">Groups the information in country-summary- .csv, prefix-summary- .csv and ixp-data-summary- .csv to obtain, for each country, a set of relevant data related to its Internet development. </MatchText>
                            </dd>
                        </div>
                    </dl>
                    <p>
                        <MatchText id="match-84">The resulting data has all the necessary information, which we will now analyze in the following sections of this report.</MatchText>
                    </p>


                </>}
                {myLang === 'pt' && <>
                    <h2>
                        <MatchText id="match-10">Metodologia</MatchText>
                    </h2>
                    <h3 id="a2">
                        <MatchText id="match-11">Objetivos definidos</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-12">Este estudo prop&otilde;e analisar as diferentes caracter&iacute;sticas da interconex&atilde;o da regi&atilde;o, com base no processamento de tabelas de roteamento BGP globais. A fim de comparar as caracter&iacute;sticas b&aacute;sicas das publica&ccedil;&otilde;es de rotas na regi&atilde;o, obter conclus&otilde;es sobre o comportamento das operadoras na publica&ccedil;&atilde;o de prefixos, detectar os sites em que o tr&aacute;fego &eacute; trocado com maior n&uacute;mero de operadoras, conhecer os provedores de tr&aacute;fego upstream que predominam em cada pa&iacute;s, o n&uacute;mero m&eacute;dio de sistemas aut&ocirc;nomos pelos que passa o tr&aacute;fego e o impacto disso na estabilidade do roteamento regional, entre outros fatores.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-13">Assim, determinamos um conjunto de par&acirc;metros que nos permitir&aacute; comparar o estado atual e a evolu&ccedil;&atilde;o da Internet regional, com os seguintes objetivos:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-14"> Conhecer a natureza dos sistemas aut&ocirc;nomos de cada pa&iacute;s. Quantos sistemas aut&ocirc;nomos prestam servi&ccedil;o de tr&aacute;fego local e quais conectam com o restante do mundo.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-15"> Obter dados gerais sobre as tabelas da regi&atilde;o, como n&uacute;mero de prefixos anunciados, comprimento m&eacute;dio dos prefixos IPv4 e IPv6, comprimento m&eacute;dio dos AS path.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-16"> Obter outros dados que possam contribuir para avaliar o desenvolvimento da Internet, como o n&uacute;mero de IXP.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-17"> Conhecer a interconex&atilde;o, tanto entre pa&iacute;ses quanto dentro de cada um.</MatchText>
                        </li>
                    </ul>
                    <h3 id="a2">
                        <MatchText id="match-18">Fontes de dados</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-19">Para analisar as tabelas de roteamento BGP globais, usaremos o servi&ccedil;o RIS </MatchText>
                        <a href="#ref-2" onClick={() => scrollToReference('ref-2')}>(2)</a>
                        <MatchText id="match-20"> do RIPE NCC. O Servi&ccedil;o de Informa&ccedil;&otilde;es de Roteamento, o RIS (do ingl&ecirc;s, &quot;Routing Information Service&quot;), coleta e armazena informa&ccedil;&otilde;es de rotas na Internet obtidas em diferentes partes do mundo. Esta rede global &eacute; formada por coletores (Remote Route Collectors) que geralmente est&atilde;o localizados em IXP.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-21">Os coletores armazenam as informa&ccedil;&otilde;es no formato MRT descrito no RFC 6396 </MatchText>
                        <a href="#ref-3" onClick={() => scrollToReference('ref-3')}>(3)</a>
                        <MatchText id="match-22">. Cada coletor armazena todos os pacotes BGP trocados a cada 15 minutos (atualiza&ccedil;&otilde;es) e um dump de suas tabelas de roteamento a cada 8 horas (ribs).</MatchText>
                    </p>

                    <p>
                        <MatchText id="match-23">
                            At&eacute; agora, o RIPE possui 20 coletores que atualmente est&atilde;o em opera&ccedil;&atilde;o:</MatchText>
                    </p>
                    <ul>
                        <li>
                            <MatchText id="match-24"> rrc00 em Amsterd&atilde;, Pa&iacute;ses Baixos.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-25"> rrc01 em Londres, Reino Unido.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-26"> rrc04 em Genebra, Su&iacute;&ccedil;a.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-27"> rrc05 em Viena, &Aacute;ustria.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-28"> rrc06 em Otemachi, Jap&atilde;o.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-29"> rrc07 em Estocolmo, Su&eacute;cia.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-30"> rrc10 em Mil&atilde;o, It&aacute;lia.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-31"> rrc11 em Nova Iorque, Estados Unidos.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-32"> rrc12 em Frankfurt, Alemanha.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-33"> rrc13 em Moscou, R&uacute;ssia.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-34"> rrc14 em Palo Alto, Estados Unidos.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-35"> rrc15 em S&atilde;o Paulo, Brasil.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-36"> rrc16 em Miami, Estados Unidos.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-37"> rrc18 em Barcelona, Espanha.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-38"> rrc19 em Joanesburgo, &Aacute;frica do Sul.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-39"> rrc20 em Zurique, Su&iacute;&ccedil;a.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-40"> rrc21 em Paris, Fran&ccedil;a.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-41"> rrc22 em Bucareste, Rom&ecirc;nia.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-42"> rrc23 em Singapura.</MatchText>
                        </li>
                        <li>
                            <MatchText id="match-43"> rrc24 em Montevid&eacute;u, Uruguai.</MatchText>
                        </li>
                    </ul>
                    <p>
                        <MatchText id="match-44">Para fazer este estudo, usamos os dumps das tabelas de roteamento (ribs) do coletor rrc00. O processamento foi realizado usando a ferramenta pyBGPStream </MatchText>
                        <a href="#ref-4" onClick={() => scrollToReference('ref-4')}>(4)</a>
                        <MatchText id="match-45">: uma biblioteca Python para usar BGPReader </MatchText>
                        <a href="#ref-5" onClick={() => scrollToReference('ref-5')}>(5)</a>
                        <MatchText id="match-46"> de CAIDA BGPStream, que permite o processamento simples e eficiente dos dados publicados pelos coletores do RIPE.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-47">
                            Al&eacute;m da necessidade de conhecer as tabelas de roteamento para processar as rotas da Internet, precisamos associar os recursos aos diferentes pa&iacute;ses para poder fazer um estudo que leve em considera&ccedil;&atilde;o os pa&iacute;ses da Am&eacute;rica Latina. Para conseguir essa associa&ccedil;&atilde;o, usaremos o arquivo delegatedextended. Este registro cont&eacute;m uma atualiza&ccedil;&atilde;o di&aacute;ria do status da aloca&ccedil;&atilde;o dos recursos de numera&ccedil;&atilde;o da Internet (endere&ccedil;os IPv4 e IPv6 e N&uacute;meros do Sistemas Aut&ocirc;nomos). Este arquivo &eacute; gerado por cada Registro Regional sob um formato padronizado pela NRO </MatchText>
                        <a href="#ref-6" onClick={() => scrollToReference('ref-6')}>(6)</a>
                        <MatchText id="match-48"> (Number Resource Organization) e depois consolidado em um &uacute;nico arquivo.
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-49">
                            Por &uacute;ltimo, para conhecer os pontos de troca (IXP) que tem cada pa&iacute;s ou territ&oacute;rio, usaremos um dataset gerado por CAIDA </MatchText>
                        <a href="#ref-7" onClick={() => scrollToReference('ref-7')}>(7)</a>
                        <MatchText id="match-50"> , que usa PCH </MatchText>
                        <a href="#ref-8" onClick={() => scrollToReference('ref-8')}>(8)</a>
                        <MatchText id="match-51"> como fonte desses dados. Esta fonte possui um n&uacute;mero menor de IXP&nbsp;quando comparada a outras refer&ecirc;ncias como o IXPDB9, mas no momento &eacute; a &uacute;nica que possui dados retroativos e em um formato de f&aacute;cil obten&ccedil;&atilde;o.</MatchText>
                    </p>
                    <h3 id="a3">
                        <MatchText id="match-52">Processamento das informa&ccedil;&otilde;es</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-53">Para atingir os objetivos propostos, &eacute; fundamental definir como classificar os sistemas aut&ocirc;nomos em cada pa&iacute;s, para ent&atilde;o poder processar nossas fontes de dados propostas e obter os par&acirc;metros e indicadores desejados. O exemplo a seguir permitir&aacute; estabelecer essas classifica&ccedil;&otilde;es. </MatchText>
                    </p>
                    <figure>
                        <img alt="Ver" src="/images/interconexion-bgp/r1.png" />
                        <figcaption>
                            <MatchText id="match-54">Figura 1: Representa&ccedil;&atilde;o do an&uacute;ncio BGP de uma rota que se propaga entre sistemas aut&ocirc;nomos que est&atilde;o dentro e fora de um pa&iacute;s ou territ&oacute;rio.</MatchText>
                        </figcaption>
                    </figure>
                    <p>
                        <MatchText id="match-55">Na figura 1 pode-se ver o AS-1, que anuncia seus prefixos por meio do BGP para o AS-2, e este propaga o referido an&uacute;ncio para os outros sistemas aut&ocirc;nomos. Aqui &eacute; importante classificar o papel que cada um desempenha, por isso foram divididos em 3 cores. O AS-1 (laranja) &eacute; o que anuncia prefixos, portanto, vamos classific&aacute;-lo como um AS de origem dentro deste pa&iacute;s. Os sistemas azuis (AS-2, AS-3 e AS-4), s&atilde;o os que d&atilde;o tr&aacute;fego: conectam o AS-1 dentro do territ&oacute;rio do pa&iacute;s. Por &uacute;ltimo, o AS-5 (vermelho) continua dando tr&aacute;fego, mas &eacute; de origem estrangeira. Classificaremos esses tipos de sistemas aut&ocirc;nomos como upstream, e s&atilde;o eles que d&atilde;o conectividade a um pa&iacute;s. O an&uacute;ncio pode continuar a se espalhar por outros sistemas aut&ocirc;nomos, mas esses caminhos n&atilde;o s&atilde;o mais relevantes para a an&aacute;lise local no n&iacute;vel do pa&iacute;s do AS de origem.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-56">Para obter essas informa&ccedil;&otilde;es, processaremos as tabelas de roteamento globais, armazenadas e publicadas pelo coletor rrc00 do RIPE NCC. Para cada entrada em sua tabela, estamos interessados no conjunto de pares de prefixo e AS path da seguinte forma:
                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-57">

                            Prefixo1/comprimento | AS-X &hellip; AS-3 AS-2 AS-1</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-58">Observamos o AS path da direita para a esquerda, pois nos interessa conhecer o sistema aut&ocirc;nomo de origem do prefixo, e a partir dele ir obtendo os demais sistemas que s&atilde;o relevantes, conforme refletido na figura 1.

                        </MatchText>
                    </p>
                    <p>
                        <MatchText id="match-59">

                            Mas para dar sentido a essas informa&ccedil;&otilde;es, precisamos associar cada prefixo e sistema aut&ocirc;nomo a um pa&iacute;s. Portanto, usaremos o arquivo de estat&iacute;sticas delegated-extended que os RIR publicam, a fim de obter essa associa&ccedil;&atilde;o entre recursos e territ&oacute;rio. Assim, cada entrada pode ser interpretada como:</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-60">[Pa&iacute;s do prefixo] | [Pa&iacute;s ASX] &hellip; [Pa&iacute;s AS-3] [Pa&iacute;s AS-2] [Pa&iacute;s AS-1]</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-61">Aqui, j&aacute; podemos filtrar todas as entradas da tabela de roteamento, para ficarmos com apenas aquelas com prefixos cadastrados nos pa&iacute;ses cobertos pelo LACNIC. Depois resta analisar o AS path da direita para a esquerda. Se o pa&iacute;s do AS-1 coincide com o do prefixo, &eacute; uma rota que nos interessa analisar, e consideramos esse AS-1 como um sistema aut&ocirc;nomo de origem do pa&iacute;s. Depois passamos para o AS-2, se este tamb&eacute;m pertencer ao mesmo pa&iacute;s, &eacute; considerado ent&atilde;o um AS de tr&aacute;fego e se passa a analisar o AS-3 usando o mesmo procedimento que para o AS-2. Pelo contr&aacute;rio, se pertencer a um outro pa&iacute;s, &eacute; considerado um AS upstream e j&aacute; n&atilde;o &eacute; necess&aacute;rio continuar analisando a cadeia do AS path.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-62">Se aplicarmos essa l&oacute;gica ao exemplo da figura 1, vamos obter o seguinte resultado:</MatchText>
                    </p>
                    <figure><img alt="Ver" src="/images/interconexion-bgp/r2_pt.png" /> </figure>
                    <p>
                        <MatchText id="match-63">Quer dizer, a partir dessa entrada na tabela de roteamento analisada, podemos associar a esse pa&iacute;s o AS1 como origem; AS-2, AS-3 e AS-4 como tr&aacute;fego; e AS-5 como upstream.</MatchText>
                    </p>
                    <h3 id="a4">
                        <MatchText id="match-64">Datasets gerados</MatchText>
                    </h3>
                    <p>
                        <MatchText id="match-65">Todos os dados necess&aacute;rios ao cumprimento dos objetivos inicialmente definidos s&atilde;o armazenados em datasets ou conjunto de dados, o que abre as possibilidades de fazer outros estudos mais aprofundados ou com outras abordagens.</MatchText>
                    </p>
                    <p>
                        <MatchText id="match-66">
                            Portanto, foi estabelecido o desenvolvimento de um conjunto de scripts que realizam o processamento em etapas com resultados parciais, at&eacute; obter um dataset final com os dados resumidos, que s&atilde;o, por sua vez, a principal fonte deste relat&oacute;rio.

                        </MatchText>
                    </p>
                    <figure>
                        <img alt="Ver" src="/images/interconexion-bgp/r3.png" />
                        <figcaption>
                            <MatchText id="match-67"> Figura 2: Fontes dados, e fluxo de processamento de dados para obter as informa&ccedil;&otilde;es desejadas.</MatchText>
                        </figcaption>
                    </figure>
                    <p>
                        <MatchText id="match-68">Na figura 2, pode observar-se o fluxo de dados y processamento realizado. Seu resultado &eacute; um conjunto de dados que cont&ecirc;m informa&ccedil;&otilde;es sobre o roteamento dos diferentes pa&iacute;ses da Am&eacute;rica Latina, em um determinado dia. Esses datasets s&atilde;o:</MatchText>
                    </p>
                    <dl className="descriptive_list">
                        <div>
                            <dt>
                                <MatchText id="match-69">country-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-70">Para cada pa&iacute;s, lista os ASNs de acordo com sua classifica&ccedil;&atilde;o (origem, tr&aacute;fego ou upstream) e tamb&eacute;m diferencia de acordo com o protocolo IP.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-71">prefix-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-72">Mostra dados de cada prefixo anunciado e registrado no LACNIC. Como o pa&iacute;s e ASN de origem, e o n&uacute;mero de rotas e saltos no total.</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-73">as-data-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-74">Registra dados para cada AS da regi&atilde;o, como os prefixos que anuncia e sua rela&ccedil;&atilde;o com outros sistemas aut&ocirc;nomos</MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-75">country-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-76">Resume as informa&ccedil;&otilde;es de contry-data- .csv, indicando o n&uacute;mero de sistemas aut&ocirc;nomos por pa&iacute;s de acordo com sua classifica&ccedil;&atilde;o (origem, tr&aacute;fego ou upstream) </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-77">prefix-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-78">Agrupa os dados registrados em prefix-data- .csv por pa&iacute;s, que permite obter dados gerais de cada um, como comprimento m&eacute;dio dos prefixos e de AS path, n&uacute;mero total de prefixos anunciados, etc. </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-79">ixp-data-summary-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-80">Indica o n&uacute;mero de pontos de troca registrados em cada pa&iacute;s. </MatchText>
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <MatchText id="match-81">country-routing-stat-&lt; t&gt;.csv </MatchText>
                            </dt>
                            <dd>
                                <MatchText id="match-82">Agrupa informa&ccedil;&otilde;es de contry-summary- .csv, prefix-summary- .csv e ixp-data-summary- .csv para obter um conjunto de dados relevantes sobre cada pa&iacute;s, em rela&ccedil;&atilde;o a seu desenvolvimento da Internet.</MatchText>
                            </dd>
                        </div>
                    </dl>
                    <p>
                        <MatchText id="match-83">Esses dados resultantes cont&ecirc;m todas as informa&ccedil;&otilde;es necess&aacute;rias que analisaremos nas pr&oacute;ximas se&ccedil;&otilde;es deste relat&oacute;rio.</MatchText>
                    </p>

                </>}
            </div>
        </div>
    )
}

export default Metodologia;