import React, { useContext } from 'react'
import { LangContext } from '../../../context/DataContext'

function Conclusiones() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>Conclusiones</h2>
                    <p>Lo primero que se puede observar de este estudio es que faltan IXP de pa&iacute;ses destacados en la regi&oacute;n, como Brasil. Por eso, es de suma importancia que los puntos de intercambio se sumen al proyecto de instalaci&oacute;n de colectores impulsado por LACNIC, as&iacute; se puede contar con datos representativos de todos los pa&iacute;ses.</p>
                    <p>Salvando esta limitaci&oacute;n, el trabajo encontr&oacute; datos alentadores: observando el &aacute;rea de influencia de los IXP, se comprob&oacute; que existe cierta interconexi&oacute;n regional, aunque a&uacute;n podr&iacute;a mejorar. Brasil es uno de los pa&iacute;ses m&aacute;s interconectado con operadores alcanzados por todos los IXP excepto los m&aacute;s peque&ntilde;os (IXSY y GYE). En los puntos de intercambio con tablas de mayor tama&ntilde;o, tambi&eacute;n se observa una buena cobertura de prefijos IPv4 y ASN, al compararlas con tablas de colectores globales (la intersecci&oacute;n ronda el 70% en IXP chilenos y argentinos).</p>
                    <p>No obstante, queda trabajo por hacer por parte de los operadores de red para contar con una Internet mejor: las diferencias entre IPv4 e IPv6 son considerables, lo que muestra como se sigue descuidando IPv6. Adem&aacute;s, es notable la presencia de prependings en las tablas de los IXP, algo que no deber&iacute;a ser tan frecuente.</p>
                    <p>En lo que respecta a pr&oacute;ximos pasos para continuar explorando estos datos, en estudios futuros se analizar&aacute; en mayor detalle los prependings presentes en las tablas de los IXP, por ejemplo procesando las frecuencias por cada AS de origen, para comprender mejor la naturaleza de estos prependings. Adem&aacute;s, es posible realizar una comparaci&oacute;n a nivel conjunto de red m&aacute;s grande y no de prefijo anunciado para obtener &iacute;ndices de cobertura a nivel de prefijos m&aacute;s certeros. Tambi&eacute;n es posible analizar estos datos de interconexi&oacute;n en conjunto con datos de otros estudios sobre conectividad en la regi&oacute;n LAC y as&iacute; por ejemplo estudiar si existe correlaci&oacute;n entre mejores latencias para los pa&iacute;ses que est&aacute;n interconectados seg&uacute;n el &aacute;rea de cobertura de sus IXP.</p>
                    <p>Finalmente, a medida que se pueda contar con m&aacute;s colectores en los IXP de la regi&oacute;n, ser&aacute; posible hacer comparaciones respecto a esas tablas en su conjunto y las tablas BGP globales. Antes que ello ocurra, es necesario que IXP de pa&iacute;ses importantes como Brasil publiquen esa informaci&oacute;n.</p>

                </>}
                {myLang === 'en' &&
                    <> <h2>Conclusions</h2>
                        <p>The first thing that can be observed in this study is that IXPs of some of the largest countries in the region such as Brazil are missing. This means that it is extremely important for Internet exchange points to be part of the collector installation project promoted by LACNIC, as this will allow collecting representative data in all the different countries.</p>
                        <p>Except for this limitation, the data collected by the study is encouraging: an analysis of each IXPs’ area of influence shows that, while there is certainly room for improvement, some regional interconnection exists. </p>
                        <p>Brazil is one of the most interconnected countries, with IXPs reaching all but the smallest operators (IXSY and GYE). Internet exchange points with larger tables cover a good proportion of IPv4 prefixes and ASNs when compared to the tables of global collectors (intersection is approximately 70% for Chilean and Argentine IXPs).</p>
                        <p>Nevertheless, there is still work for network operators to do to have a better Internet. Considerable disparities between IPv4 and IPv6 are observed, and this shows that IPv6 continues to be neglected. Likewise, while they should be infrequent, there is a considerable number of prependings in the IXPs’ BGP tables.</p>
                        <p>As for the next steps in the exploration of this data, future studies will analyze in greater detail the prependings present in the IXPs’ tables. For example, by processing the frequencies per origin AS to better understand the nature of these prependings.</p>
                        <p>In addition, other comparisons can be made to determine the coverage rates at more specific prefix level. This interconnection data can also be analyzed together with data obtained from other Latin American and Caribbean connectivity studies, for example, for the purpose of determining whether there is any correlation between lower latencies and the countries that are interconnected based on the area covered by their IXPs.</p>
                        <p>Finally, as more collectors become available at IXPs across the region, comparisons will be possible against these tables as a whole and against global BGP tables. Before this, it is necessary that the IXPs of major countries such as Brazil make this information publicly available.</p>
                    </>}
                {myLang === 'pt' &&
                    <>
                        <h2>Conclus&otilde;es</h2>
                        <p>A primeira coisa que pode ser observada neste estudo &eacute; que faltam IXP de pa&iacute;ses de destaque na regi&atilde;o, como o Brasil. Por isso, &eacute; de extrema import&acirc;ncia que os pontos de troca se unam ao projeto de instala&ccedil;&atilde;o de coletores promovido pelo LACNIC. Desta forma, ser&aacute; poss&iacute;vel ter dados representativos de todos os pa&iacute;ses.</p>
                        <p>Exceto por essa limita&ccedil;&atilde;o, o estudo encontrou dados animadores: observando a &aacute;rea de influ&ecirc;ncia dos IXP, verificou-se que existe certa interconex&atilde;o regional, embora poderia melhorar.</p>
                        <p>O Brasil &eacute; um dos pa&iacute;ses mais interconectados, com operadoras alcan&ccedil;adas por todos os IXP, excetuando os menores (IXSY e GYE). Nos pontos de troca com tabelas maiores, observa-se tamb&eacute;m uma boa cobertura de prefixos IPv4 e ASN, quando comparadas com tabelas de coletores globais (a interse&ccedil;&atilde;o &eacute; em torno de 70% nos IXP chilenos e argentinos).</p>
                        <p>No entanto, as operadoras de rede t&ecirc;m bastante trabalho pela frente para contar com uma Internet melhor. As diferen&ccedil;as entre o IPv4 e o IPv6 s&atilde;o consider&aacute;veis, o que mostra como o IPv6 continua sendo negligenciado. Al&eacute;m disso, &eacute; not&aacute;vel a presen&ccedil;a de prependings nas tabelas dos IXP, coisa que n&atilde;o deveria ser t&atilde;o frequente.</p>
                        <p>Em rela&ccedil;&atilde;o aos pr&oacute;ximos passos para continuar explorando esses dados, estudos futuros analisar&atilde;o com mais detalhes os prependings presentes nas tabelas dos IXP. Por exemplo, processando as frequ&ecirc;ncias por cada AS de origem para compreender melhor a natureza desses prependings.</p>
                        <p>Al&eacute;m disso, &eacute; poss&iacute;vel fazer uma compara&ccedil;&atilde;o no n&iacute;vel conjunto de maior rede e n&atilde;o de prefixo anunciado, para obter taxas de cobertura no n&iacute;vel de prefixos mais precisos. Tamb&eacute;m &eacute; poss&iacute;vel analisar esses dados de interconex&atilde;o juntamente com dados de outros estudos sobre conectividade na regi&atilde;o da Am&eacute;rica Latina e o Caribe e assim, por exemplo, estudar se existe uma correla&ccedil;&atilde;o entre melhores lat&ecirc;ncias para os pa&iacute;ses que est&atilde;o interconectados de acordo com a &aacute;rea de abrang&ecirc;ncia de seus IXP.</p>
                        <p>Finalmente, &agrave; medida que mais coletores forem disponibilizados nos IXP da regi&atilde;o, ser&aacute; poss&iacute;vel fazer compara&ccedil;&otilde;es com essas tabelas como um todo e com as tabelas BGP globais. Antes, &eacute; necess&aacute;rio que IXP de pa&iacute;ses importantes, como o Brasil, publiquem essas informa&ccedil;&otilde;es.</p>

                    </>}
            </div>

        </div>
    )
}

export default Conclusiones
