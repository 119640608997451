import React from 'react'
import Button from '../../../components/Button'

function Conectividad2022Es({styles}) {
    return (
        <>
        <h1>Análisis de los caminos del tráfico dentro de cada país de la región LAC</h1> 
            <div>
                <div id="p1" class="alerts alert_white">
                    <p>Autor: Agustín Formoso y Guillermo Cicileo</p>
                    <p>Coordinación y Revisión: Área de Comunicaciones</p>
                    <p>Edición: Septiembre 2023</p>
                    <p>Área: Área de Tecnología</p>
                     <Button color="button_blue" to="/reportes/interconexion/lacnic-estudio_trafico_ pais_lac-es.pdf">Descargar (PDF)</Button>
                 

                 
             </div></div>
             

            <h2 id="resumen_select">Resumen Ejecutivo</h2>
            <p>Este documento forma parte de una serie de documentos que analizan las mediciones realizadas durante 2022 por parte de LACNIC en la región LAC. Este documento abarca las <strong>mediciones realizadas de forma interna a un país: tráfico con origen en un país y con destino dentro el mismo país</strong>.<br />
                Se realizó una medición activa del comportamiento del tráfico mediante el uso de traceroutes. Estos traceroutes se miden desde diferentes puntos de la red hacia direcciones IP de cada país, midiendo unas <a href="#5500redes">5500 redes</a>. En base a esto se realizaron distintos análisis que quedan a disposición de los operadores de la región y otros investigadores que quieran ahondar sobre el tema. Además, se <a href="#anexo3grafos" >disponibilizaron los datos</a> para quien desee realizar un análisis en mayor profundidad.<br />
                En términos generales se midió una latencia mejor que en la campaña de 2020. Sobre el estudio de 2020, este estudio agrega la <a href="#perspectivacaminos">perspectiva de caminos</a>, es decir los puntos intermedios por dónde pasa el tráfico.<br />
                Los países con presencia de IXPs u operadores grandes mantienen más de un 90% del tráfico dentro del país. También en esos casos los valores de latencia son típicamente menores a 40 ms, y cuando se mira al mismo tiempo <a href="#caminoslatencias">caminos y latencias</a> se nota un panorama razonable. Este estudio también toma nota sobre los casos <a href="#fugatrafico" >donde se fuga tráfico</a>, y por dónde lo hace.<br />
                Ampliando a nivel de ASN, se lista un ranking de cada país con <a href="#redcentral">las redes más centrales</a>, es decir aquellas que captaron la mayor parte de las mediciones. Este ranking incluye las redes de cada país así como la de otros países y redes que operan en múltiples países. Se nota que los IXPs establecidos se encuentran en el top del ranking. Además, se elaboraron <a href="#anexo3grafos" >grafos de conectividad</a> que muestran cómo son las relaciones entre diferentes redes de cada país.<br />
                Se dedica una sección exclusiva al análisis de IXPs. Se ve que enrutan típicamente más del 40% del tráfico medido, el tráfico que pasa por el IXP presenta un <a href="#aspath">as-path más corto</a>, y tienen un <a href="#impactolatencia">impacto positivo en la latencia</a> frente al tráfico que no pasa por el IXP.<br />
                Adicionalmente se proveen 3 anexos en los que se detallan los destinos de traceroutes salientes (ASNs), las rutas con circuitos por el extranjero y los grafos de conectividad de cada país.
            </p>
            <ul>
                <li><a href="#anexo1">Anexo 1: Destinos de traceroutes salientes (ASNs)</a></li>
                <li><a href="#anexo2">Anexo 2: Rutas con circuitos por el extranjero</a></li>
                <li><a href="#anexo3">Anexo 3: Grafos de los países de LAC</a></li>
            </ul>


            <h2 id="introduccion_select">Introducción: análisis de caminos del tráfico regional</h2>
            <p>Desde LACNIC hemos llevado a cabo diferentes tipos de mediciones en Internet a nivel país y sobre todo a nivel regional. Uno de los aspectos que nos interesa medir es la conectividad entre redes que operan en los países de la región, por eso, hemos realizado estudios basados en tiempos de latencia. Estos estudios mostraron una mejora importante en cuanto a los tiempos de conectividad tanto internos a cada país como entre países. Por ejemplo se puede ver el <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">estudio de Conectividad en la región LAC: 2020</a> en la sección de Informes Técnicos del sitio de LACNIC.<br />
                A raíz de consultas e interés de la comunidad respecto de los resultados de estos estudios basados en mediciones de latencia, hemos llevado adelante otros estudios basados en información disponible de las tablas de enrutamiento de la región: <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-en-lac">Interconexión en BGP en la región de América Latina y el Caribe</a> e <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/interconexion-bgp-a-nivel-local-en-la-region-de-americalatina-y-el-caribe">Interconexión BGP a nivel local en la región de América Latina y el Caribe</a>. Estos estudios permitieron tener un panorama de la conectividad a nivel de ruteo en cada país y a nivel de región, complementando la información anterior.<br />
                Para este nuevo estudio buscamos realizar una medición activa del comportamiento del tráfico mediante el uso de traceroutes. Estos traceroutes se miden desde diferentes puntos de la red hacia direcciones IP de cada país. Y con los resultados podemos analizar cuales son los ASN con mayor centralidad en cada país, si los caminos son de longitudes razonables, si quedan dentro de cada país o se realizan interconexiones por fuera, si se nota la presencia de IXPs locales en ese país, entre otros.
            </p>


            <h2 id="datosusados_select">Datos usados en este estudio</h2>
            <h3 id="fuente">Fuentes de datos</h3>
            <p>Acerca de las fuentes de datos utilizadas:</p>
            <ul>
                <li><strong>Geofeeds de LACNIC</strong>: es un servicio de MiLACNIC donde los miembros pueden agregar información de geolocalización de sus bloques IP <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic" >(+ info)</a>.</li>
                <li><strong>Información de registro</strong>: los RIRs son una fuente de datos que indica dónde se encuentran registradas las direcciones IP, en particular aquellas que aparecen en el set de datos de este estudio. El formato utilizado son los archivos “delegated” publicados por cada RIR <a target='_blank' rel="noreferrer" href="https://ftp.ripe.net/pub/stats/">(+ info)</a>.</li>
                <li><strong>PeeringDB</strong>: es una plataforma que cuenta con información contribuida por la comunidad de Internet <a target='_blank' rel="noreferrer" href="https://www.peeringdb.com/">(+ info)</a>. En particular se utilizaron los siguientes conjuntos de datos:
                    <ul>
                        <li>IX, con información acerca de los IXPs.</li>
                        <li>IXPFX, o “IX prefix”, con información acerca de qué prefijos son utilizados en cada IXP.</li>
                        <li>Las dos fuentes de datos anteriores permiten obtener los IXPs por donde circularon los traceroutes de las mediciones.</li>
                    </ul>
                </li>
                <li><strong>AS Names</strong>: cuenta con el conjunto de datos asns.txt donde lista los nombres coloquiales por los que son conocidos los ASNs y los países dónde están siendo utilizados. Este conjunto de datos sirve para utilizar esas etiquetas en diferentes visualizaciones y tablas <a target='_blank' rel="noreferrer" href="https://bgp.potaroo.net/cidr/autnums.html">(+ info)</a>.</li>
                <li><strong>AS Population</strong>: estimación de la población detrás de ASNs, provista por APNIC. Estos datos son útiles para estimar el impacto que tiene la operación de un ASN en los usuarios finales. Estos datos son utilizados únicamente en la sección de <a href="#traceroutes">Traceroutes que son enrutados por otro país</a><a target='_blank' rel="noreferrer" href="https://stats.labs.apnic.net/aspop">(+ info)</a>.</li>
                <li><strong>RIPE IPmap</strong>: es una plataforma donde se infiere información de geolocalización de direcciones IP a través de mediciones activas <a target='_blank' rel="noreferrer" href="https://ipmap.ripe.net/"> (+ info)</a>.</li>
                <li><strong>RIPE RIS</strong>: es un observatorio de rutas de Internet donde, además de otra gran cantidad de información, se puede ver el ASN que origina un prefijo en Internet <a target='_blank' rel="noreferrer" href="https://ris.ripe.net/">(+ info)</a>.</li>
                <li><strong>Speedchecker</strong>: plataforma de mediciones activa que permite lanzar pings y traceroutes desde sondas ubicadas en la región <a target='_blank' rel="noreferrer" href="https://www.speedchecker.com/">(+ info)</a>.</li>
            </ul>


            <h4 id="plataforma">Plataformas de mediciones</h4>
            <p>En estudios anteriores, se han analizado las plataformas de medición disponibles que podrían utilizarse para este tipo de estudios (ver sección Introducción en <a target='_blank' rel="noreferrer" href="https://imasd.lacnic.net/es/interconexion/conectividad-en-la-region-lac-2020">Conectividad en la región LAC: 2020)</a>, llegando a la conclusión que RIPE Atlas y Speedchecker son las más adecuadas.<br />
                RIPE Atlas y Speedchecker son plataformas complementarias ya que cubren diferentes tipos de red, a través de mecanismos comparables (miden a través de los mismos protocolos). Si bien Speedcheker cubre más redes que RIPE Atlas, no la reemplaza; es de esperarse que si la cobertura de RIPE Atlas mejora, lo haga hacia redes (o segmentos de esas redes) que aún no están cubiertas por la otra plataforma. Si se toman las redes que cubre cada plataforma se pueden agrupar de la siguiente forma:</p>


            <div class="row">
                <div class="columna" ><figure><figcaption>ASNs de la región LAC cubiertos por la campaña de mediciones</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico01_s_i.png" class="imguno" alt="grafico01_s_i" /></figure></div>
                <div class="columna" ><figure><figcaption>ASNs de la región LAC cubiertos (y no cubiertos) por la campaña de mediciones</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico01_s_d.png" class="imguno" alt="grafico01_s_d" /></figure></div>
            </div>
            <p>Por lo mencionado anteriormente en cuanto a cobertura y consistencia con estudios de años anteriores la campaña de mediciones 2022 se implementó a través de Speedchecker, sin quitar la opción de realizar una campaña similar con RIPE Atlas.</p>

            <h3 id="consideraciones">Consideraciones sobre los datos</h3>
            <p>Se tuvieron que realizar algunas consideraciones a la hora de analizar los datos:</p>
            <ul>
                <li>Si bien el objetivo del informe es abarcar los países de la región LAC, no todos ellos contaron con suficientes resultados al finalizar la campaña de mediciones.</li>
                <ul>
                    <li>Los siguientes países no contaban con sondas al momento de realizar las mediciones: Antillas Neerlandesas (AN), Aruba (AW), Belize (BZ), Cuba (CU), Islas Malvinas (FK), Islas Georgias del Sur y Sandwich del Sur (GS), Surinam (SR).</li>
                    <li>Los siguientes países no reportaron suficientes resultados (menos de 10) y son omitidos en algunas secciones del documento: Guayana Francesa (GF, 9 resultados), Guyana (GY, 5).</li>
                </ul>
                <li>A partir de las mediciones se notó que algunas redes operan a nivel global. En este estudio esas redes no cuentan con un país de registro específico, sino que al momento de asignarles una ubicación geográfica se les asignó el código <strong>WW</strong> (worldwide, o global). Las redes son las siguientes:
                    <ul>
                        <li>AS16625 AKAMAI-AS</li>
                        <li>AS1299 TWELVE99 Telia Company AB</li>
                        <li>AS3356 LEVEL3</li>
                        <li>AS13335 CLOUDFLARENET</li>
                        <li>AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.</li>
                        <li>AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL</li>
                        <li>AS3549 LVLT-3549</li>
                        <li>AS7195 EDGEUNO SAS</li>
                        <li>AS18747 IFX18747</li>
                        <li>AS23520 COLUMBUS-NETWORKS</li>
                        <li>AS3257 GTT-BACKBONE GTT Communications Inc.</li>
                        <li>AS174 COGENT-174</li>
                        <li>AS1239 SPRINTLINK</li>
                    </ul>
                </li>
            </ul>



            <h2 id="5500redes_select">Cobertura de la campaña de mediciones</h2>

            <div class="row">
                <div class="columna_left"><p id="p_padding">La campaña de mediciones cubrió un total de 5588 redes. Estas redes pueden ser aquellas que albergan una sonda (origen de las mediciones), albergan una dirección IP (destino de las mediciones), o aquellas por las que pasa la medición (redes intermedias, no son origen ni destino de las mediciones). Una observación de esto es que hay 511 redes que no tienen una sonda de medición ni una dirección IP de destino, es decir son redes “intermedias” que fueron relevadas por la campaña de mediciones. Se puede ver una distribución de las redes en la siguiente gráfica:</p></div>
                <div class="columna_right"><figure class="figure_c"><figcaption>ASNs vistos por la campaña de mediciones</figcaption><img style={styles.images} class="columna-imgc" src="/images/estudio-de-conectividad-2022/grafico02.png" alt="" /></figure></div>
            </div>




            <h2 id="impactolatencia_select">Latencias</h2>
            <h3 id="compara">Comparación 2020/2022</h3>

            <div class="row">
                <div class="columna"><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico03.png" alt="grafico03" /></div>
                <div class="columna"><p>En 2020 se realizó un <a target='_blank' rel="noreferrer" href="https://www.lacnic.net/innovaportal/file/4297/1/lacnic-conectividad-lac-es.pdf" >estudio de latencia de la región LAC</a>, donde se midió la latencia interna de varios países de la región. Debido a que la plataforma y la metodología de ambos estudios es similar, es posible realizar una comparación entre ambos resultados. A la izquierda se muestra una comparativa de los resultados de las mediciones de latencia obtenidas en cada año. ¿Cómo se comparan los resultados obtenidos en 2022 con los obtenidos en 2020?</p>
                    <p>Una primera observación es que muchos de los países presentan valores de latencia más bajos en 2022 que en 2020, en particular aquellos países con latencias más altas. En promedio las mejoras son de 44.4 ms.</p>
                    <p>Cabe destacar el caso de Panamá, que a partir del estudio 2020 comenzó <a target='_blank' rel="noreferrer" href="https://blog.lacnic.net/programa-frida/proyecto-de-sondas-en-panama-para-medir-la-latencia">un proyecto de despliegue de sondas de mediciones</a> para mejorar la observabilidad de la conectividad regional. Si se comparan las latencias de ambos años se nota una mejora de ~34 ms.</p>
                </div>
            </div>


            <div class="row">
                <div class="columna"><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico04.png" alt="grafico04" /></div>
                <div class="columna"><p>Dado que esta campaña de mediciones está basada en traceroutes, es posible comparar los hops que se mantienen dentro de un país contra los que salen del país (para luego volver a entrar). Sin mayores sorpresas las mediciones se alinean con las expectativas: los valores de latencia de los hops que salen del país superan a la interna por un amplio margen.</p>
                    <p>Sin embargo, los hops que salen del país son un indicador de enrutamiento subóptimo, ya que estos paquetes tienen como destino una dirección IP del mismo país.</p>
                    <p>En la sección de <a href="#caminoslatencias" target='_blank' rel="noreferrer">Latencias y caminos</a> se verá en mayor detalle la diferencia de RTT entre hops externos e internos, y cómo esta se relaciona con el porcentaje de hops que salen de un país.</p>
                </div>
            </div>



            <h2 id="perspectivacaminos_select">Caminos</h2>
            <h3 id="traceroutes">Traceroutes que son enrutados por otro país</h3>

            <div class="row">
                <div class="columna"><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico05.png" alt="grafico05" /></div>
                <div class="columna"><p class="p_padding">Si se comparan los caminos que se mantuvieron dentro del país con los que salieron del país, se pueden hacer algunas observaciones.</p>
                    <p class="p_padding">En primer lugar se puede comparar el porcentaje de hops y de traceroutes que salen de cada país. Se considera un hop saliente a un país si la dirección IP del hop no se encuentra registrada en el país de origen, y se considera un traceroute como saliente si al menos un hop de ese traceroute es saliente.</p>
                    <p class="p_padding">Además de contemplar el país donde una dirección IP (hop) se encuentra registrada, también se tiene en cuenta la latencia. Este criterio es detallado en mayor profundidad en la sección <a href="#geolocalizacion"  target='_blank' rel="noreferrer">Correcciones de geolocalización</a>, donde se explica que la razón del mismo es minimizar la cantidad de recursos que están registrados fuera de un país pero operan dentro de él (baja latencia). Por ejemplo, una dirección IP registrada en Estados Unidos pero a 20 ms (o menos) de una sonda ubicada en Argentina será considerada como ubicada en Argentina.</p>
                    <p class="p_padding">En la gráfica se pueden ver aquellos países grandes con presencia de IXPs, como Argentina, Brasil, o Chile, los cuales presentan un porcentaje de traceroutes saliente bajo, con 2.7, 5.6, y 0.4% respectivamente.</p>
                    <p class="p_padding">También se pueden ver los países con presencia de operadores grandes con bajos porcentajes, como ser México y Uruguay con 6.1 y 3.1% respectivamente.</p>
                    <p class="p_padding">El ranking de los países con porcentaje más alto de traceroutes enrutados por fuera del país fueron Guyana, Honduras, Panamá, Venezuela, El Salvador, Guatemala, y República Dominicana con valores por encima de 10%. Cuando se analiza el porcentaje de los ASNs que deciden enrutar traceroutes por fuera, se nota lo siguiente: en Guyana el 100% de los ASNs vistos por el experimento deciden hacerlo, en Honduras 66%, en Panamá 50%, en Venezuela 41%, y El Salvador 25%. Estos “cruces de frontera” se encuentran detallados en la tabla <a href="#fugatrafico" >ASNs que enrutan traceroutes por otro país</a>, donde se muestran aquellos ASNs que deciden enrutar traceroutes por otro país (aquellos que enrutan más de un 5% de todos los traceroutes lanzados desde ese país).</p>
                    <p class="p_padding">Otro recurso en este documento es la tabla de los ASNs más vistos en traceroutes, por país. En la misma se detallan todos los ASNs que más se ven en los traceroutes lanzados desde cada país, no únicamente los que cruzan una frontera.</p>
                    <p class="p_padding">Nótese que uno de los objetivos de este estudio es describir de la mejor forma posible los resultados y observaciones realizadas durante el experimento. <strong> Invitamos a los lectores, que tienen un mayor conocimiento del entorno local, tomen este estudio como disparador y realicen una mejor interpretación de los datos.</strong></p>
                </div>
            </div>




            <div class="row">
                <div class="columna">
                    <p class="p_padding">Recordemos que en este documento se analizan las mediciones que tienen origen y destino el mismo país. Es interesante conocer la <strong>cantidad de ASNs</strong> que, dado que tienen que enviar un paquete dentro del país, terminan enrutando vía otro país para luego regresar al país de origen. Este es el porcentaje de ASNs de cada país que enrutan paquetes por un ASN fuera del país, tomando como 100% todos los ASNs de ese país que se vieron en el experimento. A continuación se muestra el ranking para esta métrica. </p>
                    <figure class="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                        Cantidad de ASNs
                    </figcaption><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico06.png" alt="grafico06" />
                    </figure></div>
                <div class="columna"><p class="p_padding">Al contar con la estimación de usuarios por ASN, también se puede estimar la población de un país que cae dentro de los casos mencionados anteriormente. Estos son graficados a continuación. </p>
                    <div><figure class="figure_c figure_left"><figcaption>Enrutamiento en países en LAC<br />
                        Porcentaje de la población
                    </figcaption><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico07.png" alt="grafico07" /></figure></div>
                    <p class="p_padding">Son notables los casos de Chile, Trinidad y Tobago, y Panamá que si bien cuentan con ASNs que enrutan por fuera de su país, como estos sirven a pocos usuarios o ninguno entonces  el porcentaje de población termina siendo nulo.</p>
                    <p class="p_padding">Sin mucha notoriedad le siguen en el ranking algunos casos con una población alrededor del 3%.</p>
                </div>
            </div>
                <p>Por lo general los países con mayor porcentaje (mayor a 3% de la población del país) tienen una gran componente en uno o a lo sumo dos ASNs. Estas componentes se pueden atribuir a:
                </p><ul>
                    <li>Colombia con mayor participación de  UFINET PANAMÁ y TV AZTECA SUCURSAL COLOMBIA  (3 y 2% resp.)</li>
                    <li>Uruguay con Telefónica Móviles del Uruguay</li>
                    <li>Venezuela con TELEFóNICA VENEZOLANA</li>
                    <li>Guayana con E-Networks</li>
                    <li>Paraguay con Núcleo</li>
                    <li>Mexico con Uninet</li>
                    <li>Costa Rica con Instituto Costarricense de Electricidad y Telecom.</li>
                    <li>Perú con Telefónica del Perú</li>
                    <li>Honduras con Telefónica Celular y CABLECOLOR (27 y 14%)</li>
                    <li>Guatemala con Telgu y, COMCEL GUATEMALA (39 y 18%)</li>
                    <li>República Dominicana con Compañía Dominicana de Teléfonos y ALTICE DOMINICANA (64 y 23%)</li>
                </ul><p></p>


                <h3 id="fugatrafico">Tablas</h3>

                <p>Dado que las mediciones tienen origen y destino un mismo país, es interesante conocer aquellos países por donde las mediciones dejan el país. Se elaboraron dos tablas con información acerca de los destinos por donde salen los traceroutes. La primera tabla muestra los países por donde salieron las mediciones, y la segunda los ASNs por donde lo hicieron. Se puede pensar en la segunda tabla como una ampliación de la primera. Para ambos casos se muestran las filas (países o ASNs) que representan más del 5% del total de los traceroutes que salen de ese país. Se calcula el porcentaje basado en:</p>
                <ul>
                    <ol>1.	Un traceroute saliente es aquel que tiene al menos un salto que pasa primero por una IP geolocalizada en su propio país, y en su siguiente salto lo hace por una dirección IP geolocalizada en otro país. Para más información se puede consultar la sección de <a href="https://www.reddisegno.com.ar/lacnic/2023/geolocalizacion">Geolocalización.</a></ol>
                    <ol>2.	Para calcular el porcentaje se cuentan todos los traceroutes del punto 1, y se los compara frente a todos los traceroutes lanzados desde ese país.</ol>
                </ul>

                <p>Existe una consideración a la hora de geolocalizar redes que operan en muchos países. Esta consideración las geolocaliza con el código <stron>WW</stron>, y se puede conocer más en la sección de <a href="#consideraciones">Consideraciones sobre los datos.</a></p>
                <p>A continuación se muestra la primera tabla (nivel país), y en el <a href="#anexo1">Anexo 1: destinos de traceroutes salientes (ASNs)</a> se deja la tabla con mayor detalle (nivel ASN).</p>
                <p>Referencia:<br />
                    PAIS: país donde se originaron las mediciones. Es también el país destino de las mediciones.<br />
                    PAIS_INTERMEDIO: país por donde salieron las mediciones.
                </p>

                <figure class="tabla_small">

                    <table class="table">
                        <thead>
                            <tr><th>PAIS</th><th>PAIS_INTERMEDIO</th><th>PORCENTAJE</th></tr></thead>
                        <tbody><tr><td>AR</td><td>WW</td><td>94%</td></tr>
                            <tr><td>BR</td><td>WW<br />US</td><td>82%<br />9%</td></tr>
                            <tr><td>CL</td><td>WW</td><td>100%</td></tr>
                            <tr><td>CO</td><td>WW</td><td>100%</td></tr>
                            <tr><td>CR</td><td>WW<br />GT</td><td>88%<br />12%</td></tr>
                            <tr><td>DO</td><td>WW</td><td>100%</td></tr>
                            <tr><td>EC</td><td>WW</td><td>100%</td></tr>
                            <tr><td>SV</td><td>WW</td><td>100%</td></tr>
                            <tr><td>GT</td><td>WW<br />US</td><td>87%<br />13%</td></tr>
                            <tr><td>GY</td><td>WW</td><td>100%</td></tr>
                            <tr><td>HN</td><td>WW</td><td>100%</td></tr>
                            <tr><td>MX</td><td>WW<br />US</td><td>55%<br />44%</td></tr>
                            <tr><td>PA</td><td>WW</td><td>100%</td></tr>
                            <tr><td>PY</td><td>BR<br />WW</td><td>50%<br />50%</td></tr>
                            <tr><td>PE</td><td>WW</td><td>100%</td></tr>
                            <tr><td>TT</td><td>WW<br />GD<br />JM</td><td>67%<br />25%<br />8%</td></tr>
                            <tr><td>UY</td><td>WW<br />BR</td><td>75%<br />25%</td></tr>
                            <tr><td>VE</td><td>WW<br />CO<br />US</td><td>69%<br />15%<br />15%</td></tr>
                        </tbody>
                    </table>
                </figure>

                <h3 id="redcentral">ASNs más vistos en traceroutes, por país</h3>
                <p>A partir de las mediciones se puede determinar cuáles son las redes más vistas por traceroutes dentro de un país, tanto si son redes de ese país como si son otras redes por donde pasaron los traceroutes. Esa métrica puede dar una idea de la importancia de cada red en ese país. Para esto se cuentan todos los traceroutes que involucran a cada red y se lo divide por la cantidad de traceroutes lanzados desde ese país, como porcentaje. Si luego se elabora un ranking tomando en cuenta ese porcentaje, filtrando sólo aquellas redes que representan más de un 5%, se obtiene lo siguiente (nótese que los porcentajes no deben sumar 100%, ya que un mismo traceroute involucra a varias redes):</p>


                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/asn_1.png" class="imguno" alt="asn_1" /></p>


                <h2 id="caminoslatencias_select">Latencias y caminos</h2>

                <p>De las mediciones se desprenden dos puntos importantes:</p>
                <ul>
                    <li>Hay países que, al enviar paquetes con destino el mismo país, enrutan un porcentaje de ellos a través de otro país.</li>
                    <li>Lo hacen generalmente con una latencia mayor a la que lo harían por dentro.</li>
                </ul>
                <p>Si se grafican estos dos puntos, es decir la diferencia de RTT entre los paquetes que salen menos los que permanecen dentro del país (eje x), y el porcentaje de paquetes que sale (eje y), se obtiene lo siguiente: </p>

                <p></p><figure><figcaption>Latencia en países de LAC<br />
                    Hops que salen vs. hops que permanecen dentro de cada país
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico08.png" class="columna-grafos" alt="grafico08" /></figure><p></p>

                <ul>
                    <li>Eje x: la diferencia de RTT entre los hops que salen del país y los que se quedan dentro del mismo. Valores altos indican una penalidad al salir del país. Valores bajos indican que las latencias son similares.</li>
                    <li>Eje y: Porcentaje de hops que salen del país. Típicamente este debería ser un valor bajo. Cabe destacar los casos de Bolivia, Guyana Francesa, y Nicaragua, que no presentaron hops por fuera del país.</li>
                </ul>

                <p>De los puntos anteriores podemos agrupar los países en las siguientes categorías</p>
                <ul>
                    <li>Cuadrante superior derecho: aquellos que tienen una penalidad de RTT al salir del país y además gran porcentaje. Este cuadrante se encuentra vacío, sería necesario revisar la conectividad a nivel de país.</li>
                    <li>Cuadrante inferior derecho: se paga una penalidad al salir pero se lo hace pocas veces. Son rutas que al salir tienen una alta latencia.</li>
                    <li>Cuadrante superior izquierdo: estos países pagan una baja penalidad en términos de latencia, pero cuentan con muchas rutas salientes.</li>
                    <li>Cuadrante inferior izquierdo: se paga una baja penalidad pocas veces, se podría considerar este tráfico como enrutamiento óptimo.</li>

                </ul>

                <p>Similares observaciones se pueden hacer si en vez de graficar la cantidad de paquetes salientes se mira la cantidad de traceroutes salientes. La distribución de los países es similar, con la gran diferencia de que el porcentaje es mayor (basta con que únicamente 1 hop salga para que se considere que el traceroute salga, por eso la tendencia a ser un porcentaje más alto):</p>

                <p></p><figure><figcaption>Latencia en países de LAC<br />
                    Traceroutes que salen vs. traceroutes que permanecen dentro de cada país
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico09.png" class="columna-grafos" alt="grafico09" /></figure><p></p>


                <h2 id="grafos_select">Grafos</h2>
                <p>Es útil cargar los resultados de las mediciones en una estructura de grafo, donde se pueden aplicar métodos conocidos sobre el mismo. Por ejemplo se pueden buscar loops, o circuitos, en ese grafo. Basados en los resultados de traceroutes se construyó un grafo para cada país donde:</p>
                <ul>
                    <li>Cada nodo del grafo es una red, puede ser un ASN o un IXP.</li>
                    <li>Cada arista del grafo representa el salto en un traceroute visto entre ASNs consecutivos.</li>
                </ul>

                <p>El siguiente es un traceroute de ejemplo que forma parte de la campaña de mediciones. Es importante notar que cada fila se corresponde con un hop del traceroute, es decir las columnas asn_origin y asn_destination son el origen y destino de ese hop.</p>
                <p>El ASN de origen del traceroute es el campo asn_origin del primer hop y el ASN de destino final del traceroute es el asn_destination del último hop (última fila). En el siguiente caso el traceroute se lanza desde el AS <b>6057</b> y tiene como destino el AS <b>19422</b></p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/traceroute1.png" class="imguno" alt="traceroute1"/></p>

                <p >Ejemplo de traceroute de la campaña de mediciones,<br />
                    con el ASN de origen y destino marcados en negrita.
                </p>

                <p>A partir del traceroute anterior se puede extraer la siguiente información:</p>
                <ul>
                    <li>Se origina en la sonda con dirección 167.57.114.53, anunciada por el ASN 6057.</li>
                    <li>Tiene destino la dirección 200.58.155.34, anunciada por el ASN 19422.</li>
                    <li>El traceroute recorre los ASNs 6057 ➔ 12956 ➔ 19422.</li>
                    <li>Tiene 11 saltos y un RTT que varía.</li>
                </ul>

                <p>El traceroute anterior contiene más información de la necesaria para armar el grafo de latencias. Dado que estamos buscando los hops entre ASNs, podemos descartar los hops que no conectan dos ASNs. Además, mantendremos el RTT diferencia entre los ASNs, es decir RTTn+1 - RTTn. Esto nos permite reducir el traceroute a uno con menos información, donde se mantienen pares de hops (filas 1-2, 3-4, etc.):</p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/traceroute2.png" class="imguno" alt="traceroute2"/></p>

                <p >Ejemplo de traceroute reducido a los datos necesarios,<br />
                    que serán incluidos en el grafo de país.
                </p>

                <p>A partir de esa información se construye un grafo de las siguientes características:</p>
                <ul>
                    <li>3 nodos 6057, 12956, y 19422</li>
                    <li>2 aristas: 6057 ➔ 12956 y 12956 ➔ 19422</li>
                    <li>A las aristas se agrega un valor de RTT, que será utilizado por la librería de grafos para calcular la posición de los nodos al pintarlos: nodos con menor RTT serán ubicados más próximo uno de otros. Este es el RTT diferencia entre los hops hopn y hopn+1.
                        <ul>
                            <li>Entre 6057 ➔ 12956, el RTT diferencia es 14.2 ms - 9.3 ms = 4.9 ms</li>
                            <li>Entre 12956 ➔ 19422 es 15.1 - 14.2 = 0.9 ms</li>
                        </ul>

                    </li>
                </ul>

                <p>Además, se puede armar una escala de colores donde cada color representa el país donde se encuentre el nodo (ASN). Los nodos se marcan con un círculo pintado, y IXPs con un círculo vacío. La relación ASN ➔ país se determinó mediante:</p>
                <ul>
                    <ol>1. Tomando la dirección IP de cada salto del traceroute se buscó en
                        <ul>
                            <ol>a. whois el país de registro de esa dirección</ol>
                            <ol>b. En RIPE RIS el ASN que anuncia esa dirección hacia Internet</ol>
                        </ul>
                    </ol>
                    <ol>2. Al tener una dirección IP y un ASN de los puntos 1.a y 1.b, se concluye que el ASN se encuentra asignado en ese país.
                        <ul><ol>a. Si hay varias direcciones IP que son anunciadas por el mismo ASN, y todas ellas están registradas al mismo país, entonces el ASN sigue estando en el único país obtenido de 1.a. En cambio, si el ASN anuncia direcciones registradas en países diferentes, por ejemplo registradas en los países XX e YY, entonces el ASN se marca como asignado en XX|YY.</ol></ul>

                    </ol>
                    <ol>3. Adicionalmente, en caso de que no se encuentre un país para un ASN siguiendo el método anterior, se recurre a una base de datos en potaroo.net que contiene el país donde se estima que opera el ASN. A los ASNs que localizan de esta forma, por ejemplo al país XX se los asigna como XX*.</ol>
                </ul>



                <p></p><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico10.png" class="columna-grafos" alt="grafico10" /></figure><p></p>

                <p>Este análisis se aplicó a todos los países de la región y los resultados se pueden ver en el <a href="#anexo3grafos" target="_blank">Anexo 3: Grafos de los países de LAC.</a></p>

                <h2 id="ixp_select">IXPs</h2>
                <h3 id="presencia">Presencia</h3>

                <p>Una observación que se desprende de los traceroutes es el porcentaje de traceroutes lanzados en un país que pasan por IXPs del mismo país. Para determinar eso comparamos las direcciones IP vistas en los traceroutes con las direcciones IP registradas en PeeringDB asignadas a IXPs. Se debe notar que si los IXP de un país no registraron sus redes en PeeringDB no serán tenidos en cuenta y no serán contabilizados como IXP. Si contamos la cantidad de traceroutes que pasan por un IXP de ese país, se obtiene lo siguiente:</p>
                <p><b>IXPs vistos por traceroutes de la campaña de mediciones</b></p>
                <p>AR AR-IX Cabase (Argentina)<br />
                    BO PIT Bolivia (La Paz)<br />
                    BR IX.br (PTT.br)<br />
                    CL PIT Santiago - PIT Chile (Santiago)<br />
                    CO NAP Colombia (Bogota)<br />
                    CR CRIX (San Jose)<br />
                    EC NAP.EC - UIO (Quito/UIO)<br />
                    HN IXP-HN (Tegucigalpa)<br />
                    PA InteRed Panama (Panama)<br />
                    PE Peru IX (PIT Peru sac) - Lima (Lima)<br />
                    PY IXpy (San Lorenzo)<br />
                    TT TTIX (Barataria)
                </p>

                <p></p><figure class="figure_c figure_left"><figcaption>Presencia de IXPs<br />
                    Traceroutes que pasan por el IXP
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico11.png" class="columna-img" alt="grafico11" /></figure><p></p>

                <p>De la gráfica se puede ver que el caso típico enruta entre un 40 y 50% del tráfico medido. Dos puntos a tener en cuenta son los casos de Brasil y Chile, donde la expectativa era obtener muchas más muestras pasando por el IXP. Estos casos no hablan tanto de la presencia del IXP sino del sesgo de la plataforma de mediciones.</p>

                <h3 id="latencia">Latencias</h3>

                <div class="row">
                    <div class="columna"><figure class="figure_c figure_left"><figcaption>Comparación de latencia<br />
                        Traceroutes internos a cada país
                    </figcaption><img style={styles.images} class="columna-img" src="/images/estudio-de-conectividad-2022/grafico12.png" alt="grafico12" /></figure></div>
                    <div class="columna"><p>También es útil analizar la latencia de los traceroutes que pasan por IXPs, y compararla con la latencia de aquellos que no pasan por IXPs. En este caso se tomó el RTT del último hop del traceroute. Si se grafican estos valores se obtiene el gráfico que se encuentra a la izquierda.</p>
                        <p>A primera vista se nota que por lo general las latencias de traceroutes que pasan por IXPs son más bajas. El caso excepcional donde las latencias más altas son las de traceroutes que pasa por IXPs es el de Chile, donde las latencias difieren por muy poco (17.0 vs. 15.6 para aquellos traceroutes que pasan y no pasan por IXPs respectivamente).</p>
                        <p>Dos casos particulares son los de Panamá y Trinidad y Tobago, donde las latencias de traceroutes que pasan por IXPs son más bajas y en línea con el resto de la región (barras negras), sin embargo, las latencias que no pasan por IXPs son notoriamente altas (barra gris).</p>
                    </div>
                </div>


                <h3 id="preslat">Presencia y latencias</h3>

                <p>En esta sección se hará un análisis similar al análisis que se hizo en la sección de <a href="https://www.reddisegno.com.ar/lacnic/2023/caminoslatencias">Latencias y caminos</a>, donde se toman dos variables y se las grafica en un par de ejes cartesianos. Esto permite realizar un análisis rápido de la región y dividir los casos en cuatro cuadrantes.</p>
                <p>Si se toma la diferencia de RTT entre los traceroutes que pasan por un IXP y los que no, se puede cuantificar el impacto de un IXP en el ecosistema local. Además, si se toma el porcentaje de traceroutes que no pasan por el IXP frente al total de traceroutes lanzados, se puede cuantificar la frecuencia de este comportamiento. Estas dos métricas dan una idea de:</p>
                <ul>
                    <li>Si se paga una penalidad alta (diferencia de RTT)</li>
                    <li>Qué frecuentemente se la paga (porcentaje de traceroutes)</li>
                </ul>
                <p>Si se grafican estas dos métricas, diferencia de RTT en el eje de las x y porcentaje de traceroutes que no pasa por un IXP en el eje de las y, se puede detectar de forma rápida los diferentes casos:</p>
                <ul>
                    <li>Cuadrante superior derecho, afortunadamente sin países. Serían los casos donde poco tráfico pasa por el IXP local, y el que no pasa paga una penalidad de latencia muy alta.</li>
                    <li>Cuadrante inferior derecho, por ejemplo el caso de Panamá (PA). Hay mucho tráfico que pasa por el IXP, y el que decide salir paga una penalidad de latencia.</li>
                    <li>Cuadrante superior izquierdo, por ejemplo Perú (PE). Hay poco tráfico que pasa por el IXP, sin embargo la penalidad de no pasar es muy baja.</li>
                    <li>Cuadrante inferior izquierdo, por ejemplo Bolivia (BO). Gran parte del tráfico pasa por el IXP, sin embargo la penalidad de no pasar no es alta.</li>
                </ul>

                <p></p><figure><figcaption>Latencia  en países de LAC<br />
                    Traceroutes que pasan por un IXP vs. Los que no lo hacen
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico13.png" class="imagen_wide" alt="grafico13" /></figure><p></p>


                <h3 id="aspath">Número de saltos y redes</h3>

                <p>A partir de los traceroutes se puede determinar dos métricas: la cantidad de hops dentro de traceroutes, y la cantidad de redes por las que pasa el traceroute. Las redes pueden ser de dos tipos: ASNs o IXPs. Para determinar qué tipo de red es, se toman en cuenta dos fuentes de datos: búsqueda reversa de IP a ASN según RIPE RIS, y la información de IXPs en PeeringDB.</p>
                <p>Aplicando el mismo concepto al número de hops de cada traceroute, se obtienen los datos de la gráfica a la izquierda. De esa información se puede ver que los traceroutes que pasan por los IXPs son por lo general más cortos, pero con algunas salvedades. Los casos donde hay más hops en traceroutes que usan el IXP son Chile, Colombia, Costa Rica, Ecuador, y Trinidad y Tobago. Como fue analizado en la sección anterior, estos 5 excepto el de chile muestran mejores valores de latencia al usar el IXP, por lo que el número de hops termina siendo una métrica poco útil para medir impacto.</p>

                <div class="row">
                    <div class="columna" ><figure><figcaption>Número de hops <br />
                        Traceroutes internos a cada país
                    </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico14.png" class="imagen" alt="grafico14" /></figure></div>
                    <div class="columna" ><figure><figcaption>Redes<br />
                        Traceroutes internos a cada país
                    </figcaption>
                        <img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico15.png" class="imagen" alt="grafico15" /></figure></div>
                </div>

                <p>De la misma forma se puede graficar la cantidad de redes (ya sean ASNs o IXPs) por donde pasan los traceroutes.</p>
                <p>La expectativa inicial es de que en aquellos países que cuentan con un IXP se vea el impacto del mismo en la conectividad. Un punto a tener en cuenta es que esta métrica incluye el IXP por donde pasa el traceroute, si se quieren contar únicamente los ASNs hay que restar 1 a la métrica, por ejemplo Costa Rica (CR) tiene una métrica de 2.4 redes (promedio), y al restarle 1 quedaría en 1.4 ASNs.</p>
                <p>En una primera instancia se observaron casos donde la cantidad de redes en traceroutes que involucran al IXP local son más que cuando no se lo involucra. El primer caso es el de Honduras, donde se reporta 1.0 en la cantidad de redes que pasan por IXP-HN (Tegucigalpa). Esto se debe a que todas las demás direcciones IP de los traceroutes que pasan por el IXP pertenecen al espacio privado y no se puede derivar un ASN de ellas. Por ese motivo en este caso la única red que cuenta es la del IXP. Este mismo problema podría estar pasando en otros casos y sesgando los datos.</p>
                <p>Esta inconsistencia llevó a preguntarse si la calidad de los datos podría ser mejorada. ¿Podríamos aplicar algunos filtros para asegurarnos de que los datos con los que se trabaja sean más confiables a la hora de contar redes? Una regla simple que se aplicó fue:</p>
                <ul>
                    <li>Para los traceroutes que involucran al IXP, se deben observar al menos 3 redes, incluyendo la red del IXP: ASN1 --&gt; IXP --&gt; ASN2.</li>
                    <li>Para los traceroutes que no involucran al IXP, al menos 2: ASN1 --&gt; ASN2.</li>
                </ul>
                <p>Una vez aplicada esta restricción se obtienen datos más alineados con las expectativas (cantidad de redes similar entre ambos casos). El caso de Honduras mencionado anteriormente queda descartado automáticamente.</p>
                <p>En este caso también se decidió agregar una métrica más: cantidad de redes en un traceroute que pasa por un IXP, sin contar el IXP. Es decir, esta métrica cuenta únicamente los ASNs involucrados en el traceroute y se grafica en color blanco:</p>


                <p></p><figure><figcaption>Redes<br />
                    Traceroutes internos a cada país
                </figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico16.png" class="imagen" alt="grafico16" /></figure><p></p>

                <p>Una vez filtrados, los datos muestran que los traceroutes involucran un número de ASNs por lo general menor al involucrar al IXP local.</p>
                <p>Algunos casos a tener en cuenta:</p>
                <ul>
                    <li>En el caso de Haití se obtuvo una única muestra que pasó por HIX Haiti (Port-au-Prince), por lo que se lo descartó y se lo quitó de la gráfica.</li>
                    <li>En Perú únicamente pasaron 5 traceroutes por Perú IX (PIT Peru sac) - Lima (Lima), originados desde 2 redes (de un total de 8 vistas por la campaña de mediciones).</li>
                </ul>

                <h3 id="ejemplo">Un ejemplo: Argentina</h3>

                <p>En las secciones anteriores se vió que es común que la latencia y el número de saltos sean mejores en aquellos traceroutes que pasan por IXPs. Si se toma el ejemplo de Argentina, un país con fuerte presencia del IXP y además con un número de mediciones considerable, y se observan los detalles del conjunto de datos, se puede observar lo siguiente:</p>
                <p>En términos de latencia, la mediana del RTT de los traceroutes más lentos (percentil 99) son 175 ms para los que pasan por IXPs y 215 ms para los que no. Si bien estamos hablando de los casos más lentos, una diferencia de 40 ms es algo no trivial. El traceroute típico (percentil 50) tiene una mediana de 21.3 ms si pasa por un IXP, y 24.4 si no lo hace. Es una diferencia de 3 ms. Por debajo de 12 ms no hay una diferencia significativa. Este punto se da alrededor del percentil 20, es decir que el 20% de los traceroutes más rápidos tienen valores similares de latencia cuando se comparan aquellos que pasan por un IXP contra los que no lo hacen.</p>
                <p>En términos de hops, los traceroutes requieren en el peor de los casos (percentil 99) de 19 hops para llegar a destino si pasan por un IXP. De no pasar por un IXP requieren de 21. El caso típico (percentil 50) es de 9 hops si pasan por un IXP, y de 11 si no lo hacen. Para los traceroutes más cortos (percentil 7, menos de 6 hops) no hay diferencias.</p>
                <p>En términos de cantidad de redes por las que pasan los traceroutes se ven valores muy similares. Dos observaciones al margen:</p>
                <ul>
                    <li>Los traceroutes que pasan por el IXP presentan 3 redes o más ya que se cuenta la red de origen, el IXP, y la red de destino.</li>
                    <li>Los que no pasan presentan 2 o más: es el caso de una conexión directa.</li>
                </ul>


                <div class="row">
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17a.png" class="imgdos" alt="grafico17a" /></div>
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17b.png" class="imgdos" alt="grafico17b" /></div>
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico17c.png" class="imgdos" alt="grafico17c" /></div>
                </div>


                <p>La conclusión más clara es que las mediciones que pasan a través del IXP en Argentina presentan mejores métricas de latencia y número de saltos de forma medible. Tomando un traceroute típico de esta campaña de mediciones, la mejora es de un ~18% en términos de saltos y un ~12% en términos de latencia. Para los traceroutes más cortos o de menor latencia (menos de 7 saltos o menos de 20 ms) la mejora es casi nula.</p>

                <h2 id="geolocalizacion_select">Geolocalización</h2>
                <h3 id="fuentedato">Fuentes de datos</h3>
                <p>La fuente principal de geolocalización para este trabajo son los <a href="https://ftp.ripe.net/pub/stats/" target='_blank' rel="noreferrer">archivos de registro</a> (conocidos como delegated-extended, provisto por los diferentes RIRs). Estos archivos capturan el país donde la entidad legal detrás de un bloque IP está constituída, y de por sí eso introduce errores. Por ese motivo fue necesario realizar algunas correcciones.</p>
                <p>Una fuente secundaria de geolocalización es <a href="https://ipmap.ripe.net/" target='_blank' rel="noreferrer">RIPE IPmap</a>, donde la geolocalización de las direcciones IP está basada en mediciones activas por parte de sondas de Atlas. Se puede inferir la ubicación geográfica de direcciones IP que responden con un ping suficientemente bajo a una sonda con una ubicación conocida.</p>
                <p>Otra fuente secundaria fue el servicio de <a href="https://www.lacnic.net/4867/1/lacnic/servicio-geofeeds-de-lacnic" target='_blank' rel="noreferrer">Geofeeds de LACNIC</a>, donde los miembros de prefijos pueden indicar en qué lugar geográfico se están utilizando los mismos.</p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico18.png" class="imagen" alt="" /></p>


                <p>Cuando se incorporan las 3 fuentes de geolocalización, se lo hace siguiendo el siguiente criterio, donde las fuentes son listadas de mayor a menor precisión:</p>
                <ul>
                    <ol>1.	Primero se toma la información proveniente de las <a href="#consideraciones" target='_blank' rel="noreferrer">Consideraciones sobre los datos</a>, estas son direcciones IP pertenecientes a redes globales que operan en múltiples países.</ol>
                    <ol>2.	Luego se toma la información de Geofeeds, considerada la fuente de información más confiable, ya que es una declaración por parte del operador acerca de dónde se utilizan los bloques IP.</ol>
                    <ol>3.	De no haber información en el punto anterior se toma la información proveniente de IPmap.</ol>
                    <ol>4.	Y de la misma forma se procede a tomar información de los archivos de registro.</ol>
                </ul>


                <h3 id="correccion">Correcciones: Delta RTT</h3>

                <p>Al analizar los datos se notó que habían valores de latencia muy bajos entre países lejanos, como por ejemplo mediciones de 5 ms entre Argentina y Estados Unidos. Este hecho llevó a analizar la latencia de los hops dentro de un mismo traceroute, de la siguiente forma:</p>
                <ul>
                    <ol>1.	Se recorre cada traceroute desde el hop #1 hasta el último hop analizando cada par de hops adyacentes (1-2, 2-3, 3-4, etc).</ol>
                    <ol>2.	Se descartan aquellas comparaciones de pares de hops que pertenecen al mismo país.</ol>
                    <ol>3.	Se determina la diferencia de RTT, o delta RTT, entre los dos hops de cada par de hops según RTTn+1 - RTTn. Es decir, si el 1º hop responde con 3 ms y el 2º con 4 ms, el delta RTT es de 4 ms - 3 ms = 1 ms.  En caso que el 1º hop responda con 4ms y el 2º con 3ms, el delta RTT es de -1ms. Esto puede suceder ya que los hops más altos no necesariamente responden con valores de latencia más altos debido a varios factores, entre ellos que el momento exacto de la medición no es el mismo, el nivel de carga de los routers varía, u otras causas.</ol>
                    <ol>4.	A partir de los valores de delta RTT se puede inferir si los puntos IP en los extremos del enlace están próximos entre sí, en términos de latencia. En la sección <a href="#determinando">Determinando el umbral de latencia</a> veremos cómo definimos proximidad en el contexto de este estudio. A partir de esa métrica se puede realizar la siguiente modificación:
                        <ul>
                            <ol>a.	Para aquellas direcciones IP en los extremos del enlace con una geolocalización conocida por los métodos anteriores, se puede comparar los países de registro.</ol>
                            <ol>b.	En caso de ser países diferentes y de tener una latencia muy baja, se considera que la información de registro no refleja la información de latencia, y se sobreescribe el país del hopn+1 con la del país del hopn.</ol>
                        </ul>
                    </ol>
                </ul>

                <p>Luego de incorporar el concepto de Delta RTT, se lo coloca en el 4o lugar de la lista de fuentes de geolocalización a consultar (más confiable que la información de registro pero menos que IPmap). La lista final es la siguiente (con el porcentaje de IPs geolocalizadas en paréntesis):</p>
                <ul>
                    <ol>1.	Redes globales (26%)</ol>
                    <ol>2.	Geofeeds (1.7%)</ol>
                    <ol>3.	IPmap (10%)</ol>
                    <ol>4.	Delta RTT (1.9%)</ol>
                    <ol>5.	Registro (60%)</ol>
                </ul>

                <p>Se puede ver que hay un 40% de IPs cuya información de geolocalización no proviene de los archivos de registro, lo que representa una mejora considerable en la calidad de los datos.</p>

                <h4 id="determinando">Determinando el umbral de latencia</h4>

                <p>Hasta ahora hemos hablado del concepto de baja latencia, pero ¿qué tan baja tiene que ser para considerar la dirección IPn+1 como en el mismo país de la dirección IPn? Una posible referencia es el umbral que toma RIPE IPmap para determinar una dirección IP como muy cercana a una sonda de RIPE Atlas: 10 ms.</p>
                <p>Antes de determinar un umbral, ¿qué se puede ver de los perfiles de latencia de los países? Comparemos la latencia en función de la ubicación de las direcciones IP, proviniendo de fuentes confiables (Geofeeds e IPmap). A continuación se muestran los perfiles de latencia de algunos países, indicando si la dirección IP pertenece al mismo país o no.</p>

                <p>Traceroute graph for AR</p>

                <div class="row">
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19a.png" class="imgdos" alt="grafico19a" /></div>
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19b.png" class="imgdos" alt="grafico19b" /></div>
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19c.png" class="imgdos" alt="grafico19c" /></div>
                    <div class="columna"><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico19d.png" class="imgdos" alt="grafico19d" /></div>
                </div>

                <p>Los perfiles presentan características muy similares: una componente de latencia principal correspondiente a las mediciones que se mantienen dentro del país (color anaranjado), y el resto de las mediciones con una o más componentes. En casi todos los países la componente principal suele estar claramente delimitada y situarse por debajo de 50 ms. Esto nos da una primera aproximación a un umbral de latencia que pueda discriminar según un RTT está dentro del país o no.</p>
                <p>Cuando se analizan las mediciones de toda la región como conjunto, se nota que la componente principal está centrada en 17.4 ms (mediana). De modo de tener un criterio sencillo y fácil de recordar, el umbral para esta campaña de mediciones fue fijado en <b>20 ms</b>.</p>
                <p>Una pregunta abierta es si se debería aplicar el mismo umbral a todos los países, dado que varían mucho en tamaño, cantidad de redes, y otras propiedades.</p>
                <p>Otra pregunta abierta es si el umbral para RTTs que se mantienen dentro del país es el mismo que el umbral para RTTs que salen del país. Es decir, ¿habrá un RTT1 para mediciones que permanecen y un RTT2 para mediciones que salen? Ejemplo de esto son países como Brasil y Argentina, donde las mediciones que permanecen dentro parecen estar por debajo de los 50 ms y las que salen parecen estar por encima de los 100 ms.</p>
                <p>Una vez aplicado el criterio de Delta RTT sobre todo el conjunto de mediciones, se vio que el mismo aportaba información complementaria a los otros métodos. En la siguiente gráfica se muestra la cantidad de direcciones IP únicas a las que cada método agrega información de geolocalización. De la gráfica se puede ver que IPmap realiza la mayor parte de las contribuciones de geolocalización de las tres fuentes. Además se puede ver que los aportes de cada fuente son complementarios, presentando pocas intersecciones (IPs donde ambas fuentes proveen un resultado).</p>
                <p>Un ejemplo donde el Delta RTT tuvo un impacto es el caso de México, que presenta mucha proximidad con Estados Unidos en términos de latencia. De las correcciones de geolocalización aplicadas, un 16% de las direcciones registradas en Estados Unidos fueron corregidas hacia México. También se notó que un 52% de los saltos son a través de operadores como Cogent (AS174, con 8.2% de los casos dentro de ese intervalo) o Level 3 (AS3356 y AS3549, con 6.1%). Observaciones similares se notaron para los demás países. En el caso de Argentina fue un 55% de casos en ese intervalo (casos notables con Telecom Italia, 2.3% y Level 3 con 1.4%), y en Colombia fueron un 37% (Level 3 con 11.3% y GTT Communications con 3.7%). </p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico20.png" class="imagen" alt="grafico20" /></p>

                <h2 id="conclusion_select">Conclusiones</h2>


                <p>Si bien no es el objetivo principal de esta investigación, cuando se compararon los resultados de 2020 con los de 2023 se notó que los <b>valores de latencia 2023 son sensiblemente mejores</b> para la mayoría de los países.</p>
                <p>En cuanto a <b>tráfico que se mantiene de forma local dentro de un país</b>, la mayoría de los casos presenta porcentajes mayores al 90%, lo que es una buena cifra. Los países más grandes como Argentina, Brasil, y México presentaron valores altos (97, 94, y 94% respectivamente). La presencia de IXPs y de operadores grandes contribuye al alto porcentaje. únicamente algunos casos puntuales presentaron porcentajes por debajo del 90%.</p>
                <p>Si además se tiene en cuenta la latencia introducida al enviar tráfico por el exterior (para luego volver a entrar), pocos países introducen latencias alarmantes. En líneas generales <b>no hay tráfico que se fuga</b> por otros países, y cuando lo hace se introduce una latencia que no es preocupante.</p>
                <p>Al analizar la centralidad de las redes, es decir la cantidad de tráfico que pasa por cada red, se notó que los <b>IXPs y las redes principales se encuentran en el top del ranking</b> de cada país.</p>
                <p>Los <b>IXPs locales de diferentes países son visibles tanto en términos de cantidad de tráfico como de performance</b>. Además, el tráfico por IXPs circuló con latencias más bajas que el resto del tráfico.</p>
                <p>Con una Internet cada vez más globalizada y optimizada conseguir <b>fuentes confiables de geolocalización</b> es sumamente importante. La información de registro de los RIRs no es suficientemente precisa como para geolocalizar la infraestructura de Internet. El estudio considera diversas fuentes de geolocalización y agrega una fuente propia. En total se mejora la precisión del 40% de los resultados.</p>

                <h2 id="anexo1_select">Anexo 1: Destinos de traceroutes salientes (ASNs)</h2>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/anexo1.png" class="imguno" alt="anexo1"/></p>


                <h2 id="anexo2_select">Anexo 2: Rutas con circuitos por el extranjero</h2>
                <h3 id="a2res">Resumen de los circuitos</h3>

                <p>Aquí se listan los casos de traceroutes con circuitos que salen del país. Es decir son traceroutes que salieron desde un ASN, luego pasaron por un país extranjero, para luego volver a entrar al mismo ASN. Estos casos podrían indicar enrutamiento subóptimo.</p>
                <p>Para mostrar toda la información se muestran las tablas en formato compacto. Descripción de las columnas:</p>
                <p><b>ip_o:</b> ip_origin, dirección IP de la sonda<br />
                    <b>ip_d:</b> ip_destination, dirección IP del hop<br />
                    <b>asn_o:</b> asn_origin, ASN de la sonda<br />
                    <b>asn_d:</b> asn_destination, ASN del hop<br />
                    <b>co:</b> country_origin, país de la sonda<br />
                    <b>cd:</b> country_destination, país del hop<br />
                    <b>h:</b> hop_number, número de hop<br />
                    <b>r:</b> RTT
                </p>


                <p>ASNs de <b>AR</b> que tienen una ruta circuitosa por el extranjero</p>
                <p>AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS262229 (COOP DE LUZ Y FUERZA DE LIB.GRAL.SAN MARTIN LTDA)</p>


                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta0.png" class="imguno" alt="ruta0"/></p>
                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta1.png" class="imguno" alt="ruta1"/></p>
                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta2.png" class="imguno" alt="ruta2"/></p>
                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta3.png" class="imguno" alt="ruta3"/></p>


                <p>ASNs de <b>CO</b> que tienen una ruta circuitosa por el extranjero</p>
                <p>AS265688 (SINERGY SOLUCIONES INTEGRALES) --&gt; AS7018 (ATT-INTERNET4, US) --&gt; AS265688 (SINERGY SOLUCIONES INTEGRALES)</p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta4.png" class="imguno" alt="ruta4" /></p>


                <p>ASNs de <b>PE</b> que tienen una ruta circuitosa por el extranjero</p>
                <p>AS6147 (Telefonica del Peru S.A.A.) --&gt; AS12956 (TELXIUS TELEFONICA GLOBAL SOLUTIONS SL, WW) --&gt; AS6147 (Telefonica del Peru S.A.A.)</p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta5.png" class="imguno" alt="ruta5"/></p>


                <p>ASNs de <b>UY</b> que tienen una ruta circuitosa por el extranjero</p>

                <p>Nota: Este caso fue analizado en conjunto con los operadores de AS 6057. <br />
                    Se trató de reproducir el camino con sondas de RIPE Atlas pero<br />
                    no fue posible ya que el traceroute no presentaba circuitos.</p>

                <p>AS6057 (Administracion Nacional de Telecomunicaciones) --&gt; AS10429 (TELEFONICA BRASIL S.A, BR) --&gt; AS26599 (TELEFONICA BRASIL S.A, BR) --&gt; AS8167 (V tal, BR) --&gt; AS6057 (Administracion Nacional de Telecomunicaciones)</p>

                <p><img style={styles.images} src="/images/estudio-de-conectividad-2022/ruta6.png" class="imguno" alt="ruta6"/></p>




                <h2 id="anexo3grafos_select">Anexo 3: Grafos de los países de LAC</h2>
                <h3 id="a3des">Descarga online</h3>

                <p>Los datos que soportan a los grafos de este documento (grafos con origen y destino dentro de un mismo país) se pueden encontrar en la sección de descargas del <a href="https://simon.lacnic.net/static/simon_app/conectividad-2022/" target='_blank' rel="noreferrer">sitio de LACNIC</a>. Son archivos CSV donde cada línea se corresponde con una arista del grafo, y contiene la siguiente información:</p>
                <ul>
                    <li>Red de origen - dado un traceroute con hopn y hopn+1, esta red es el ASN o IXP correspondiente al hopn.</li>
                    <li>Red de destino - idem pero correspondiente al hopn+1</li>
                    <li>RTT (diff) - es la diferencia entre RTTn+1 y RTTn.</li>
                </ul>
                <p>Es de sumo interés contar con la colaboración de los lectores en cuanto a sucesivos análisis de los datos o visualizaciones basadas en los mismos.</p>


                <h3 id="a3repr">Representación visual</h3>

                <p>En esta sección se muestra de forma visual los grafos calculados durante el estudio. La metodología utilizada al momento de elaborar los grafos puede verse en la sección de <a href="#grafos">Grafos</a>.</p>

                <table class="table" id="t1">
                    <thead><tr><th>ARGENTINA (AR)</th></tr></thead>
                    <tbody>
                        <tr><td><figure><figcaption>Traceroute graph for AR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico21.png" class="imagen_wide" alt="grafico21" /></figure></td></tr>
                        <tr><td>
                            <div class="row">
                                <div class="columna">
                                    Graph centrality (top 20)<br />
                                    1.	AR-IX Cabase<br />
                                    2.	AS7303 Telecom Argentina S.A.<br />
                                    3.	AS10834 Telefonica de Argentina<br />
                                    4.	AS3549 LVLT-3549<br />
                                    5.	AS262589 InterNexa Global Network<br />
                                    6.	AS19037 AMX Argentina S.A.<br />
                                    7.	AS3356 LEVEL3<br />
                                    8.	AS11664 Techtel LMDS Comunicaciones Interactivas S.A.<br />
                                    9.	AS11014 CPS<br />
                                    10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                                </div>
                                <div class="columna">11.	AS16814 NSS S.A.<br />
                                    12.	AS22927 Telefonica de Argentina<br />
                                    13.	AS27747 Telecentro S.A.<br />
                                    14.	AS52361 ARSAT - Empresa Argentina de Soluciones Satelitales S.A.<br />
                                    15.	AS52360 CABASE Camara Arg de Base de Datos y Serv en Linea<br />
                                    16.	AS174 COGENT-174<br />
                                    17.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    18.	AS265862 BM SOLUCIONES S.R.L.<br />
                                    19.	AS20207 Gigared S.A.<br />
                                    20.	AS52376 CABASE Camara Arg de Base de Datos y Serv en Linea
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t2">
                    <thead><tr><th>BOLIVIA (BO)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for BR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico22.png" class="imagen_wide" alt="grafico22" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 20)<br />
                                    1.	PIT Bolivia<br />
                                    2.	AS27839 Comteco Ltda<br />
                                    3.	AS26210 AXS Bolivia S. A.<br />
                                    4.	AS6568 Entel S.A. - EntelNet<br />
                                    5.	AS25620 COTAS LTDA.<br />
                                    6.	AS27882 Telefonica Celular de Bolivia S.A.<br />
                                    7.	AS262159 Digital TV CABLE DE EDMUND S.R.L.<br />
                                    8.	AS52250 Ag para el Desarrollo de la Sociedad de la Inf en Bolivia - ADSIB<br />
                                    9.	AS267685 SIRIO TELECOMUNICACIONES S.R.L
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t3">
                    <thead><tr><th>BRASIL (BR)</th></tr></thead>
                    <tbody>
                        <tr><td><figure><figcaption>Traceroute graph for BO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico23.png" class="imagen_wide" alt="grafico23" /></figure></td></tr>
                        <tr><td>
                            <div class="row">
                                <div class="columna">
                                    Graph centrality (top 20)<br />
                                    1.	IX.br (PTT.br) São Paulo<br />
                                    2.	AS16735 ALGAR TELECOM SA<br />
                                    3.	AS3356 LEVEL3<br />
                                    4.	AS4230 CLARO S.A.<br />
                                    5.	IX.br (PTT.br) Porto Alegre<br />
                                    6.	AS8167 V tal<br />
                                    7.	AS26599 TELEFONICA BRASIL S.A<br />
                                    8.	AS3549 LVLT-3549<br />
                                    9.	IX.br (PTT.br) Fortaleza<br />
                                    10.	AS26615 TIM SA
                                </div>
                                <div class="columna">11.	IX.br (PTT.br) Rio de Janeiro<br />
                                    12.	AS28283 Adylnet Telecom<br />
                                    13.	AS10429 TELEFONICA BRASIL S.A<br />
                                    14.	AS28573 Claro NXT Telecomunicacoes Ltda<br />
                                    15.	IX.br (PTT.br) Curitiba<br />
                                    16.	AS25933 Vogel Solucoes em Telecom e Informatica SA<br />
                                    17.	Equinix São Paulo<br />
                                    18.	AS268886 WILLYNET PROVEDOR<br />
                                    19.	AS14840 BR Digital<br />
                                    20.	AS53062 GGNET TELECOM BACKBONE
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t4">
                    <thead><tr><th>CHILE (CH)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for CL</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico24.png" class="imagen_wide" alt="grafico24" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	PIT Santiago - PIT Chile<br />
                                    2.	AS7004 CTC Transmisiones Regionales S.A.<br />
                                    3.	AS7418 TELEFONICA CHILE S.A.<br />
                                    4.	AS14259 Gtd Internet S.A.<br />
                                    5.	AS6535 Telmex Servicios Empresariales S.A.<br />
                                    6.	AS6429 Telmex Chile Internet S.A.<br />
                                    7.	AS22047 VTR BANDA ANCHA S.A.<br />
                                    8.	AS3549 LVLT-3549<br />
                                    9.	AS265662 TLINK SPA<br />
                                    10.	AS27651 ENTEL CHILE S.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t5">
                    <thead><tr><th>COLOMBIA (CO)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for CO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico25.png" class="imagen_wide" alt="grafico25" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	NAP Colombia<br />
                                    2.	AS262186 TV AZTECA SUCURSAL COLOMBIA<br />
                                    3.	AS13489 EPM Telecomunicaciones S.A. E.S.P.<br />
                                    4.	AS3549 LVLT-3549<br />
                                    5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                                    6.	AS19429 ETB - Colombia<br />
                                    7.	AS52468 UFINET PANAMA S.A.<br />
                                    8.	AS14080 Telmex Colombia S.A.<br />
                                    9.	AS10299 EMPRESAS MUNICIPALES DE CALI E.I.C.E. E.S.P.<br />
                                    10.	AS23520 COLUMBUS-NETWORKS
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>


                <table class="table" id="t6">
                    <thead><tr><th>COSTA RICA (CR)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for CR</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico26.png" class="imagen_wide" alt="grafico26" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	CRIX<br />
                                    2.	AS52468 UFINET PANAMA S.A.<br />
                                    3.	AS262197 MILLICOM CABLE COSTA RICA S.A.<br />
                                    4.	AS23520 COLUMBUS-NETWORKS<br />
                                    5.	AS52228 Cable Tica<br />
                                    6.	AS11830 Instituto Costarricense de Electricidad y Telecom.<br />
                                    7.	AS1299 TWELVE99 Telia Company AB<br />
                                    8.	AS262202 Telefonica de Costa Rica TC, SA<br />
                                    9.	AS263762 COOPERATIVA DE ELECTRIFICACION RURAL DE GUANACASTE R.L.<br />
                                    10.	AS28022 CRISP S.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t7">
                    <thead><tr><th>REPúBLICA DOMINICANA (DO)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for DO</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico27.png" class="imagen_wide" alt="grafico27" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS23520 COLUMBUS-NETWORKS<br />
                                    2.	AS28118 ALTICE DOMINICANA S.A.<br />
                                    3.	AS6400 Compania Dominicana de Telefonos S. A.<br />
                                    4.	AS64126 DOMINICAN CABLE GROUP DCG, S.R.L.<br />
                                    5.	AS1299 TWELVE99 Telia Company AB<br />
                                    6.	AS264821 COMCAST-SRL<br />
                                    7.	AS174 COGENT-174<br />
                                    8.	AS264605 TELEVIADUCTO S.R.L.<br />
                                    9.	AS270098 WI-FI DOMINICANA, E.I.R.L.<br />
                                    10.	AS12066 ALTICE DOMINICANA S.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t8">
                    <thead><tr><th>ECUADOR (EC)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for EC</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico28.png" class="imagen_wide" alt="grafico28" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 20)<br />
                                    1.	NAP.EC - UIO<br />
                                    2.	AS26613 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                    3.	AS264668 NEDETEL S.A.<br />
                                    4.	NAP.EC - GYE<br />
                                    5.	AS27947 Telconet S.A<br />
                                    6.	AS61468 CEDIA<br />
                                    7.	AS27738 Ecuadortelecom S.A.<br />
                                    8.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                                    9.	AS263238 Eliana Vanessa Morocho Ona<br />
                                    10.	AS23487 CONECEL<br />
                                    11.	AS14522 Satnet<br />
                                    12.	AS1299 TWELVE99 Telia Company AB<br />
                                    13.	AS28006 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                    14.	AS3356 LEVEL3<br />
                                    15.	AS27668 ETAPA EP<br />
                                    16.	AS27757 CORPORACION NACIONAL DE TELECOMUNICACIONES - CNT EP<br />
                                    17.	AS263792 IN.PLANET S. A<br />
                                    18.	AS52458 WISP INTERNET ECUADOR<br />
                                    19.	AS265711 KOLVECH S.A. TELECOMVAS<br />
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t9">
                    <thead><tr><th>GUYANA FRANCESA (GF)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for GF</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico29.png" class="imagen_wide" alt="grafico29" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS2200 FR-RENATER Reseau National de telecommunications pour la Technologie<br />
                                    2.	AS21351 CANALPLUSTELECOM Canal + Telecom SAS<br />
                                    3.	AS263175 GUYACOM
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t10">
                    <thead><tr><th>GUATEMALA (GT)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for GT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico30.png" class="imagen_wide" alt="grafico30" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    2.	AS23243 COMCEL GUATEMALA S.A.<br />
                                    3.	AS174 COGENT-174<br />
                                    4.	AS52468 UFINET PANAMA S.A.<br />
                                    5.	AS14754 Telgua<br />
                                    6.	AS6453 AS6453<br />
                                    7.	AS3356 LEVEL3<br />
                                    8.	AS27742 Amnet Telecomunicaciones S.A.<br />
                                    9.	AS267715 RED CENTROAMERICANA DE TELECOMUNICACIONES S.A, SUCURSAL GUATEMALA, SOCIEDAD EXTRANJERA<br />
                                    10.	AS1299 TWELVE99 Telia Company AB
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>


                <table class="table" id="t11">
                    <thead><tr><th>GUYANA (GY)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for GY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico31.png" class="imagen_wide" alt="grafico31" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS19863 Guyana Telephone &amp; Telegraph Co.<br />
                                    2.	AS23520 COLUMBUS-NETWORKS<br />
                                    3.	AS264694 EGOVERNMENT UNIT<br />
                                    4.	AS52253 E-Networks Inc.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t12">
                    <thead><tr><th>HONDURAS (HN)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for HN</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico32.png" class="imagen_wide" alt="grafico32" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS52262 Telefonica Celular S.A<br />
                                    2.	AS27932 Redes y Telecomunicaciones<br />
                                    3.	AS1299 TWELVE99 Telia Company AB<br />
                                    4.	AS23520 COLUMBUS-NETWORKS<br />
                                    5.	AS27884 CABLECOLOR S.A.<br />
                                    6.	AS7087 Administracion de Redes Colomsat S.A.<br />
                                    7.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    8.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                    9.	AS1239 SPRINTLINK<br />
                                    10.	AS20299 Newcom Limited
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>

                <table class="table" id="t13">
                    <thead><tr><th>HAITÍ (HT)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for HT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico33.png" class="imagen_wide" alt="grafico33" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS27759 ACCESS HAITI S.A.<br />
                                    2.	AS52260 Telecommunications de Haiti Teleco<br />
                                    3.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                                    4.	AS33576 DIG001<br />
                                    5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                    6.	AS1299 TWELVE99 Telia Company AB
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t14">
                    <thead><tr><th>MEXICO (MX)</th></tr></thead>
                    <tbody>
                        <tr><td><figure><figcaption>Traceroute graph for MX</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico34.png" class="imagen_wide" alt="grafico34" /></figure></td></tr>
                        <tr><td>
                            <div class="row">
                                <div class="columna">
                                    Graph centrality (top 20)<br />
                                    1.	AS8151 Uninet S.A. de C.V.<br />
                                    2.	AS32098 TRANSTELCO-INC<br />
                                    3.	AS18734 Operbes, S.A. de C.V.<br />
                                    4.	AS174 COGENT-174<br />
                                    5.	AS3356 LEVEL3<br />
                                    6.	AS1299 TWELVE99 Telia Company AB<br />
                                    7.	AS6503 Axtel, S.A.B. de C.V.<br />
                                    8.	AS22884 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                                    9.	AS3549 LVLT-3549<br />
                                    10.	AS11172 Alestra, S. de R.L. de C.V.
                                </div>
                                <div class="columna">111.	AS17072 TOTAL PLAY TELECOMUNICACIONES SA DE CV<br />
                                    12.	AS13999 Mega Cable, S.A. de C.V.<br />
                                    13.	AS6453 AS6453<br />
                                    14.	AS28469 AT&amp;T COMUNICACIONES DIGITALES S DE RL<br />
                                    15.	AS23520 COLUMBUS-NETWORKS<br />
                                    16.	AS6461 ZAYO-6461<br />
                                    17.	AS28548 Cablevision, S.A. de C.V.<br />
                                    18.	AS11888 Television Internacional, S.A. de C.V.<br />
                                    19.	AS7438 Pegaso PCS, S.A. de C.V.<br />
                                    20.	AS28545 Cablemas Telecomunicaciones SA de CV
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t15">
                    <thead><tr><th>NICARAGUA (NI)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for NI</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico35.png" class="imagen_wide" alt="grafico35" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS14754 Telgua<br />
                                    2.	AS52242 Yota De Nicaragua<br />
                                    3.	AS25607 IBW Communications
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>


                <table class="table" id="t16">
                    <thead><tr><th>PANAMA (PA)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for PA</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico36.png" class="imagen_wide" alt="grafico36" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	InteRed Panama<br />
                                    2.	AS1299 TWELVE99 Telia Company AB<br />
                                    3.	AS23520 COLUMBUS-NETWORKS<br />
                                    4.	AS18809 Cable Onda<br />
                                    5.	AS11556 Cable &amp; Wireless Panama<br />
                                    6.	AS27930 Shadwell International Inc<br />
                                    7.	AS52468 UFINET PANAMA S.A.<br />
                                    8.	AS21599 Cable Onda<br />
                                    9.	AS28005 Digicel Panama, S.A<br />
                                    10.	AS262191 COLUMBUS NETWORKS COLOMBIA<br />
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t17">
                    <thead><tr><th>PERÚ (PE)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for PE</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico37.png" class="imagen_wide" alt="grafico37" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS3132 Red Cientifica Peruana<br />
                                    2.	AS6147 Telefonica del Peru S.A.A.<br />
                                    3.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    4.	AS262210 VIETTEL PERU S.A.C.<br />
                                    5.	AS61482 CONVERGIA<br />
                                    6.	AS262253 ECONOCABLE MEDIA SAC<br />
                                    7.	AS269857 FIBER DIGITAL S.R.L<br />
                                    8.	AS27843 OPTICAL TECHNOLOGIES S.A.C.<br />
                                    9.	AS12252 America Movil Peru S.A.C.<br />
                                    10.	AS21575 ENTEL PERU S.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t18">
                    <thead><tr><th>PARAGUAY (PY)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for PY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico38.png" class="imagen_wide" alt="grafico38" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS23201 Telecel S.A.<br />
                                    2.	IX.br (PTT.br) São Paulo<br />
                                    3.	AS27768 COMPANIA PARAGUAYA DE COMUNICACIONES S.A. COPACO S.A.<br />
                                    4.	AS61512 GIG@NET SOCIEDAD ANONIMA<br />
                                    5.	AS3356 LEVEL3<br />
                                    6.	AS266831 MONGELOS ARCE MARCIALDELTA NETWORKS<br />
                                    7.	AS52468 UFINET PANAMA S.A.<br />
                                    8.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    9.	AS21928 T-MOBILE-AS21928<br />
                                    10.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>




                <table class="table" id="t19">
                    <thead><tr><th>EL SALVADOR (SV)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for SV</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico39.png" class="imagen_wide" alt="grafico39" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS6453 AS6453<br />
                                    2.	AS1299 TWELVE99 Telia Company AB<br />
                                    3.	AS23520 COLUMBUS-NETWORKS<br />
                                    4.	AS3491 BTN-ASN<br />
                                    5.	AS3257 GTT-BACKBONE GTT Communications Inc.<br />
                                    6.	AS14754 Telgua<br />
                                    7.	AS27773 MILLICOM CABLE EL SALVADOR S.A. DE C.V.<br />
                                    8.	AS33576 DIG001<br />
                                    9.	AS262199 Columbus Networks El Salvador SA de CV<br />
                                    10.	AS6461 ZAYO-6461
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>

                <table class="table" id="t20">
                    <thead><tr><th>TRINIDAD Y TOBAGO (TT)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for TT</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico40.png" class="imagen_wide" alt="grafico40" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS27665 Columbus Communications Trinidad Limited.<br />
                                    2.	AS23520 COLUMBUS-NETWORKS<br />
                                    3.	AS5639 Telecommunication Services of Trinidad and Tobago<br />
                                    4.	AS27800 Digicel Trinidad and Tobago Ltd.<br />
                                    5.	AS27924 AMPLIA COMMUNICATIONS LTD.<br />
                                    6.	AS264811 AIR LINK COMMUNICATIONS<br />
                                    7.	AS27789 GREENDOT<br />
                                    8.	AS393629 GDGR<br />
                                    9.	AS1299 TWELVE99 Telia Company AB<br />
                                    10.	AS263222 RVR INTERNATIONAL LIMITED
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>



                <table class="table" id="t21">
                    <thead><tr><th>URUGUAY (UY)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for UY</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico41.png" class="imagen_wide" alt="grafico41" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS6057 Administracion Nacional de Telecomunicaciones<br />
                                    2.	AS12956 TELXIUS TELEFONICA GLOBAL SOLUTIONS SL<br />
                                    3.	AS19422 Telefonica Moviles del Uruguay SA<br />
                                    4.	AS28000 LACNIC - Latin American and Caribbean IP address<br />
                                    5.	AS6762 SEABONE-NET TELECOM ITALIA SPARKLE S.p.A.<br />
                                    6.	AS10429 TELEFONICA BRASIL S.A<br />
                                    7.	AS26599 TELEFONICA BRASIL S.A<br />
                                    8.	AS8167 V tal<br />
                                    9.	AS61455 LACTLD - LATIN AMERICAN AND CARIBBEAN TLD ASSOCIATION<br />
                                    10.	AS1797 Uruguay
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>


                <table class="table" id="t22">
                    <thead><tr><th>VENEZUELA (VE)</th></tr></thead>
                    <tbody>
                        <tr><td>
                            <div class="row">
                                <div class="columna" ><figure><figcaption>Traceroute graph for VE</figcaption><img style={styles.images} src="/images/estudio-de-conectividad-2022/grafico42.png" class="imagen_wide" alt="grafico42" /></figure><br />
                                </div>
                                <div class="columna">Graph centrality (top 10)<br />
                                    1.	AS3549 LVLT-3549<br />
                                    2.	AS6306 TELEFONICA VENEZOLANA, C.A.<br />
                                    3.	AS263703 VIGINET C.A<br />
                                    4.	AS8048 CANTV Servicios, Venezuela<br />
                                    5.	AS52320 GlobeNet Cabos Submarinos Colombia, S.A.S.<br />
                                    6.	AS1299 TWELVE99 Telia Company AB<br />
                                    7.	AS61461 Airtek Solutions C.A.<br />
                                    8.	AS3356 LEVEL3<br />
                                    9.	FL-IX <br />
                                    10.	AS11562 Net Uno, C.A.
                                </div>
                            </div>
                        </td></tr>
                    </tbody>
                </table>
                </>
    )
}

export default Conectividad2022Es
