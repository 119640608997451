import React, { useContext } from 'react';
import { LangContext, MenuContext } from '../../../context/DataContext'; 

function MenuLateralMejoresPracticasParaIXP() { 
    const { myLang } = useContext(LangContext); 

    const { activeAnchor, scrollToAnchor } = useContext(MenuContext) 

    return ( 
    <> 
            {myLang === 'es' && 
            <nav>
                <button>Mejores prácticas para IXP <i className="fas fa-angle-down"></i></button>
                
                <ul className="active">
                    <li><a href="#infraestructura" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')} >Infraestructura</a></li>
                    <li><a href="#servicio" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Servicio</a></li>   
                    <li><a href="#operacion" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Operación</a></li>
                    <li><a href="#gobernanza" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Gobernanza</a></li>
                    <li><a href="#comunidad" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Comunidad</a></li> 
                </ul>
            </nav>
            }
            {myLang === 'en' && 
            <nav>
                <button>Best Practices for IXPs <i className="fas fa-angle-down"></i></button>
                
                <ul className="active">
                    <li><a href="#infrastructure" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Infrastructure</a></li>
                    <li><a href="#service" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Service</a></li>   
                    <li><a href="#operation" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Operation</a></li>
                    <li><a href="#governance" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Governance</a></li>
                    <li><a href="#community" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Community</a></li> 
                </ul>
            </nav>
            }
            {myLang === 'pt' && 
            <nav>
                <button>Melhores práticas para IXP <i className="fas fa-angle-down"></i></button>
                
                <ul className="active">
                    <li><a href="#infraestrutura" className={activeAnchor === 'p1' ? 'active' : null} onClick={() => scrollToAnchor('p1')}>Infraestrutura</a></li>
                    <li><a href="#servico" className={activeAnchor === 'p2' ? 'active' : null} onClick={() => scrollToAnchor('p2')}>Serviço</a></li>   
                    <li><a href="#operacao" className={activeAnchor === 'p3' ? 'active' : null} onClick={() => scrollToAnchor('p3')}>Operação</a></li>
                    <li><a href="#Gobernanca" className={activeAnchor === 'p4' ? 'active' : null} onClick={() => scrollToAnchor('p4')}>Gobernança</a></li>
                    <li><a href="#comunidade" className={activeAnchor === 'p5' ? 'active' : null} onClick={() => scrollToAnchor('p5')}>Comunidade</a></li> 
                </ul>
            </nav>
            }
        </>
    );
}

export default MenuLateralMejoresPracticasParaIXP;