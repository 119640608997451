import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../context/DataContext';
import { Link } from 'react-router-dom'
import DocumentTitle from '../components/DocumentTitle';
import '../css/index.css';
import Header from "../components/Header"
import Footer from "../components/Footer"
 

export default function IndexContent(props) {
	const { myLang } = useContext(LangContext)
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 760);

	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth <= 760);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<>
			<Header url_es="/es" url_en="/en" url_pt="/pt" />
			{myLang === 'es' && <Es isSmallScreen={isSmallScreen} />}
			{myLang === 'en' && <En isSmallScreen={isSmallScreen}/>}
			{myLang === 'pt' && <Pt isSmallScreen={isSmallScreen} />}
			<Footer className="master_foot foot_home" />
		</>
	);
}

function Es({isSmallScreen}) {
 

	return (
		<>
			<main className="main_index" /* style={{
				backgroundImage: `url(/images/conexiones-red_1048-7701.jpg)`,
				backgroundSize: "cover",
				padding: "112px 0 10px",
			}} */>
				<div>
					<h1><DocumentTitle>Informes técnicos</DocumentTitle> </h1>
					<p>En este sitio se encuentran los resultados de investigaciones técnicas llevadas a cabo
						por LACNIC. Estos estudios han sido coordinados con la colaboración de distintos investigadores de
						la región.

					</p>
					<div className="grid_it_reports">
						<div>
							<div><Link className="grid_it_cib" to="/es/ciberseguridad"><img alt="ver" src="/images/ciberseguridad.png" width="512" height="512" /><span className='text'>{isSmallScreen ? 'Ciberseguridad' : 'Mejorando la ciberseguridad'}</span></Link></div>
							<div><Link className="grid_it_int" to="/es/interconexion"><img alt="ver" src="/images/interconexion.png" width="512" height="512" /><span className='text'> {isSmallScreen ? 'Interconexión' : 'Fortaleciendo la interconexión regional'}</span></Link></div>
							<div><Link className="grid_it_cib_ip" to="/es/ipv6"><img alt="ver" src="/images/ipv6.png" width="512" height="512" /><span className='text'>{isSmallScreen ? 'IPv6' : 'Promoviendo el despliegue IPv6'}</span></Link></div>
						</div>
					</div>
				</div>

			</main>
			<article className="index">
				<section>

					<h2>Últimos informes: </h2>
					<div className="grid_imasd_latest">
						<div>
							<img alt="ver" src="/images/cibersecurity-smart-city.png" />
							<h3>Mejores prácticas para IXP</h3>
							<a href="/es/interconexion/mejores-practicas-para-ixp"><i className="far fa-file-alt"></i>  Leer informe</a>
						</div>
						<div>
							<img alt="ver" src="/images/pantalla-proyector-hologramas-tecnologia-sistema-nube.jpeg" />
							<h3>Análisis de los caminos del tráfico dentro de cada país de la región LAC</h3>
							<a href="/es/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac"><i className="far fa-file-alt"></i>  Leer informe</a>
						</div>  
						<div>
							<img alt="ver" src="/images/ciudad-inteligente-futurista-tecnologia.jpeg" />
							<h3>Uso de DNS-over-TLS en Latinoamérica y el Caribe</h3>
							<a href="/es/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe"><i className="far fa-file-alt"></i> Leer informe</a>
						</div>
						 
					</div>
				</section>
			</article>
		</>
	)
}
function En({isSmallScreen}) {
	
	return (
		<>
			<main className="main_index">
				<div>
					<h1><DocumentTitle>Tecnhical Reports</DocumentTitle> </h1>
					<p>This site contains the results of research carried out by LACNIC within the framework of the projects "Security, Stability and Resilience Studies (SSR)" and "Strengthening of Regional Internet Infrastructure". These studies have been coordinated by LACNIC with the collaboration of different researchers in the region.</p>
					<div className="grid_it_reports">
						<div>
							<div><Link className="grid_it_cib" to="/en/ciberseguridad"><img alt="ver" src="/images/ciberseguridad.png" width="512" height="512" />{isSmallScreen ? 'Cybersecurity' : 'Improving cybersecurity'}</Link></div>
							<div><Link className="grid_it_int" to="/en/interconexion"><img alt="ver" src="/images/interconexion.png" width="512" height="512" />{isSmallScreen ? 'Interconnection' : 'Strengthening regional interconnection'}</Link></div>
							<div><Link className="grid_it_cib_ip" to="/en/ipv6"><img alt="ver" src="/images/ipv6.png" width="512" height="512" />{isSmallScreen ? 'IPv6' : 'Promoting IPv6 deployment'}</Link></div>
						</div>
					</div>
				</div>

			</main>
			<article className="index">
				<section>
					<h2>Latest Reports:</h2>
					<div className="grid_imasd_latest">
						<div>
							<img alt="ver" src="/images/cibersecurity-smart-city.png" />
							<h3>Best Practices for IXPs</h3>
							<a href="/en/interconexion/mejores-practicas-para-ixp"><i className="far fa-file-alt"></i>  Read Report</a>
						</div>
						 
						<div>
							<img alt="ver" src="/images/pantalla-proyector-hologramas-tecnologia-sistema-nube.jpeg" />
							<h3>Analysis of the paths followed by traffic within each country of LAC region</h3>
							<a href="/en/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac" id="bot_info2"><i className="far fa-file-alt"></i>  Read Report</a>
						</div>  
						<div>
							<img alt="ver" src="/images/ciudad-inteligente-futurista-tecnologia.jpeg" />
							<h3>Use of DNS-over-TLS In Latin America and the Caribbean</h3>
							<a href="/en/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe"><i className="far fa-file-alt"></i> Read Report</a>
						</div>
						 
					</div>
				</section>
			</article>
		</>
	)
}
function Pt({isSmallScreen}) {
	return (
		<>
			<main className="main_index">
				<div>
					<h1><DocumentTitle>Relatórios técnicos</DocumentTitle> </h1>
					<p>Este site contém os resultados das pesquisas realizadas pelo LACNIC no âmbito dos projetos "Estudos de Segurança, Estabilidade e Resiliência (SSR)" e "Fortalecimento da Infraestrutura Regional da Internet". Esses estudos foram coordenados pelo LACNIC com a colaboração de diversos pesquisadores da região.</p>
					<div className="grid_it_reports">
						<div>
							<div><Link className="grid_it_cib" to="/pt/ciberseguridad"><img alt="ver" src="/images/ciberseguridad.png" width="512" height="512" />{isSmallScreen ? 'Segurança cibernética' : 'Melhorando a segurança cibernética'}</Link></div>
							<div><Link className="grid_it_int" to="/pt/interconexion"><img alt="ver" src="/images/interconexion.png" width="512" height="512" />{isSmallScreen ? 'Interconexão' : 'Fortalecimento da interconexão regional'}</Link></div>
							<div><Link className="grid_it_cib_ip" to="/pt/ipv6"><img alt="ver" src="/images/ipv6.png" width="512" height="512" />{isSmallScreen ? 'IPv6' : 'Promovendo a implantação do IPv6'}</Link></div>
						</div>
					</div>
				</div>
			</main>
			<article className="index">
				<section>
					<h2>Últimos relatórios:</h2>
					<div className="grid_imasd_latest">
						<div>
							<img alt="ver" src="/images/cibersecurity-smart-city.png" />
							<h3>Melhores práticas para IXP</h3>
							<a href="/pt/interconexion/mejores-practicas-para-ixp"><i className="far fa-file-alt"></i>  Leia o Relatório</a>
						</div>
						<div>
							<img alt="ver" src="/images/pantalla-proyector-hologramas-tecnologia-sistema-nube.jpeg" />
							<h3>Análise das rotas do tráfego dentro de cada país da região LAC</h3>
							<a href="/pt/interconexion/analisis-de-los-caminos-del-trafico-dentro-de-cada-pais-de-la-region-lac" ><i className="far fa-file-alt"></i>  Leia o Relatório</a>
						</div> 
						<div>
							<img alt="ver" src="/images/ciudad-inteligente-futurista-tecnologia.jpeg" />
							<h3>Uso do DNS-over-TLS na América Latina e o Caribe</h3>
							<a href="/pt/interconexion/uso-de-dns-over-tls-en-latinoamerica-y-el-caribe"><i className="far fa-file-alt"></i>  Leia o Relatório</a>
						</div>
						 
						 
					</div>
				</section>
			</article>
		</>
	)
}