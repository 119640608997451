import React, { useContext } from 'react';
import { LangContext } from '../../context/DataContext';
import { useParams, useLocation } from "react-router-dom"
import Reports from '../../components/Reports';
import NoMatch from '../../components/NoMatch';
import EstadisticasTendencias2020 from './EstadisticasTendencias2020';
import OperadoresDespliegueIPv6 from './OperadoresDespliegueIPv6';
import V1EquiposCPE from './V1EquiposCPE';
import V2EquiposCPE from './V2EquiposCPE';
import IPv6EnEquiposCPE from '../reports/IPv6EnEquiposCPE/IPv6EnEquiposCPE'
import Wrapper from '../../components/Wrapper';

export default function IPv6(props) {
    const uselocation = useLocation();
    const url = uselocation.pathname;
    const { handleLanguaje } = useContext(LangContext);

    if (url.includes(`/es/`) === true) {
        handleLanguaje('es')
        return (
            <ContentReport />
        )
    } if (url.includes(`/en/`) === true) {
        handleLanguaje('en')
        return (
            <ContentReport />
        )
    } if (url.includes(`/pt/`) === true) {
        handleLanguaje('pt')
        return (
            <ContentReport />
        )
    }
}

function ContentReport() {
    const { slug } = useParams();
    if (slug === undefined) {
        return (
            <ReportMainWrapper >
                <div className="report_list">
                    <EstadisticasTendencias2020 />
                    <OperadoresDespliegueIPv6 />
                    <V1EquiposCPE />
                    <V2EquiposCPE />
                </div>
            </ReportMainWrapper>
        )
    } if (slug === 'estadisticas-y-tendencias-el-despliegue-de-ipv6-en-america-latina-y-el-caribe-2016-2020') {
        return (
            <ReportMainWrapper >
                <EstadisticasTendencias2020 />
            </ReportMainWrapper>
        )
    } if (slug === 'el-comportamiento-de-operadores-en-el-despliegue-de-ipv6-en-america-latina-y-el-caribe') {
        return (
            <ReportMainWrapper >
                <OperadoresDespliegueIPv6 />
            </ReportMainWrapper>
        )
    } if (slug === 'informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-1-2020') {
        return (
            <ReportMainWrapperIPv6 menu_lateral='soporte-de-ipv6-en-equipos-cpe-version-1' >
                <IPv6EnEquiposCPE />
            </ReportMainWrapperIPv6>
        )
    } if (slug === 'informe-sobre-soporte-de-ipv6-en-equipos-cpe-version-2-2021') {
        return (
            <ReportMainWrapper >
                <V2EquiposCPE />
            </ReportMainWrapper>
        )
    } else return <NoMatch />
}

function ReportMainWrapperIPv6({ children, menu_lateral }) {
    const { slug } = useParams();
    return (
        <Wrapper
            url_es={`/es/ipv6/${slug === undefined ? '' : slug}`}
            url_en={`/en/ipv6/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/ipv6/${slug === undefined ? '' : slug}`}
            menu_lateral={menu_lateral}
        >
            <ReportTitle />
            {children} 
        </Wrapper>
    )
}

function ReportMainWrapper({ children }) {
    const { slug } = useParams();
    return (
        <Reports category="ipv6"
            url_es={`/es/ipv6/${slug === undefined ? '' : slug}`}
            url_en={`/en/ipv6/${slug === undefined ? '' : slug}`}
            url_pt={`/pt/ipv6/${slug === undefined ? '' : slug}`}>
            <ReportTitle />
            {children}
        </Reports>
    )
}
function ReportTitle() {
    const { myLang } = useContext(LangContext)
    return (
        <p>
            {myLang === 'es' && <> Informes técnicos, IPv6 </>}
            {myLang === 'en' && <> Technical Reports, IPv6 </>}
            {myLang === 'pt' && <> Relatórios técnicos, IPv6 </>}
        </p>
    )
}