import React, { useContext } from 'react';
import { LangContext } from '../../../context/DataContext';
function IPSpoofing() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p5">
                {myLang === 'es' && <>
                    <h2>IP Spoofing</h2>
                    <h3>&iquest;Qu&eacute; es?</h3>
                    <p>El <em>IP spoofing</em> es la suplantaci&oacute;n de direcciones IP. Se refiere a la creaci&oacute;n de paquetes IP con una direcci&oacute;n IP origen falsa, con el objetivo de hacerse pasar por otro sistema inform&aacute;tico. La suplantaci&oacute;n de direcciones IP permite a actores maliciosos realizar distintos tipos de ataques y su detecci&oacute;n es muy dificultosa.</p>
                    <p>Para llevar a cabo el <em>IP spoofing</em>, un actor malicioso se vale de herramientas que modifican la direcci&oacute;n IP de origen del encabezado IP de los paquetes, de modo que el sistema receptor interprete que dichos paquetes provienen de otro lugar.</p>
                    <p>El IP <em>spoofing</em> es posible debido a la falta de implementaci&oacute;n de pol&iacute;ticas de filtrado adecuadas para prevenirlo. (<a href="https://www.rfc-editor.org/rfc/rfc2827">https://www.rfc-editor.org/rfc/rfc2827</a>,&nbsp;<a href="https://www.rfc-editor.org/rfc/rfc3704.html">https://www.rfc-editor.org/rfc/rfc3704.html</a>) Estas pol&iacute;ticas de filtrado deben encargarse de verificar la direcci&oacute;n de origen de los paquetes. Para referirnos a estas pol&iacute;ticas hablaremos de <em>filtros de tipo source address validation</em> o <em>filtros de tipo SAV</em>.</p>
                    <p>Una organizaci&oacute;n puede, sin saberlo, permitir el IP <em>spoofing</em>, tanto sobre el tr&aacute;fico que ingresa (<em>inbound</em> <em>IP</em> <em>spoofing</em>) como sobre el tr&aacute;fico que sale a internet (<em>outbound</em> <em>IP</em> <em>spoofing</em>).</p>
                    <h4>Inbound IP spoofing</h4>
                    <p>Se refiere a la posibilidad de que ingrese a la organizaci&oacute;n, desde sistemas aut&oacute;nomos externos, tr&aacute;fico con direcciones IP de origen de la propia organizaci&oacute;n o de <a href="https://team-cymru.com/community-services/bogon-reference/" target="_blank" rel="noreferrer">redes </a><em><a href="https://team-cymru.com/community-services/bogon-reference/" target="_blank" rel="noreferrer">bogons</a>.</em> Estas situaciones no deber&iacute;an ocurrir y se deben filtrar, pues:</p>
                    <ul>
                        <li>Los bloques IP de la organizaci&oacute;n deber&iacute;an ser el destino del tr&aacute;fico entrante y no el origen. El <em>inbound IP spoofing</em>, cuando utiliza direcciones IP de la propia organizaci&oacute;n, da lugar a diferentes ataques que pueden afectar a sus servicios y a sus usuarios.</li>
                        <li>Las redes <em>bogons</em> no deber&iacute;an aparecer en las tablas de ruteo de internet.</li>
                    </ul>
                    <p>El <em>inbound IP spoofing</em> es posible cuando una organizaci&oacute;n no implementa filtros de tipo SAV sobre el tr&aacute;fico entrante o, como los llamaremos a partir de ahora, <em>filtros de tipo inbound SAV</em>.</p>
                    <h4>Outbound IP spoofing</h4>
                    <p>Se refiere a la posibilidad de que salga de la organizaci&oacute;n, hacia sistemas aut&oacute;nomos externos, tr&aacute;fico IP que tiene direcciones IP de origen que no pertenecen a la propia organizaci&oacute;n. Estas situaciones no deber&iacute;an ocurrir y se deben filtrar dichos paquetes, pues permiten la realizaci&oacute;n de distintos ataques que pueden afectar la disponibilidad de servicios de terceros.</p>
                    <p>El <em>outbound IP spoofing</em> es posible cuando una organizaci&oacute;n no implementa <em>filtros de tipo outbound SAV</em>.</p>
                    <h3>&iquest;Qu&eacute; protocolos se aprovechan para realizar IP spoofing?</h3>
                    <p>El IP <em>spoofing</em> se realiza en la capa de red, a nivel IP. Sin embargo, dependiendo del ataque, podr&iacute;a cambiar el protocolo encapsulado por IP.</p>
                    <p>Por ejemplo, en <a href="https://www.osi.es/es/actualidad/blog/2018/08/21/que-son-los-ataques-dos-y-ddos" target="_blank" rel="noreferrer">ataques de DoS</a> por inundaci&oacute;n o <em>flooding</em> se pueden utilizar diversos protocolos:</p>
                    <ul>
                        <li>HTTP para saturar el servicio dado.</li>
                        <li>TCP con <em>flags</em> espec&iacute;ficos, como syn, fin, rst, etc., para saturar el servicio dado.</li>
                        <li>ICMP para dar m&aacute;s volumen al ataque y saturar la capacidad de la red o del servidor.</li>
                        <li>UDP para dar m&aacute;s volumen al ataque y saturar la capacidad de la red o del servidor.</li>
                    </ul>
                    <p>Para los que se conocen como <em>ataques de DDoS</em> y otros en los que se necesita interactuar con servicios utilizando tr&aacute;fico <em>spoofeado</em>, se utilizan protocolos de aplicaci&oacute;n que se transportan sobre UDP.</p>
                    <p>Las aplicaciones que utilizan estos protocolos brindan lo que se conoce como <em>servicios reflexivos</em>. Estos servicios, que utilizan UDP como protocolo de transporte, no necesitan de un <em>handshake</em> previo para el inicio de la conexi&oacute;n.</p>
                    <h3>&iquest;Qu&eacute; ataques se aprovechan del IP spoofing y c&oacute;mo lo explotan?</h3>
                    <h4>Ataques de DoS por inundaci&oacute;n</h4>
                    <p>El objetivo de un ataque de DoS es comprometer la disponibilidad de un servicio en la organizaci&oacute;n v&iacute;ctima. En caso de que la organizaci&oacute;n donde est&aacute; el <em>host</em> controlado por el actor malicioso no implemente filtros de tipo outbound SAV, este podr&iacute;a ocultar el origen del ataque realizando IP <em>spoofing</em>.</p>
                    <h4>Ataques DDoS por amplificaci&oacute;n</h4>
                    <p>Para que los ataques de DDoS sean posibles, deben darse dos condiciones. Por un lado, la organizaci&oacute;n desde donde realiza el ataque el actor malicioso no tiene implementados filtros de tipo <em>outbound</em> SAV y, por otro, es necesaria la identificaci&oacute;n de servicios reflexivos y <a href="https://www.cisa.gov/uscert/ncas/alerts/TA14-017A" target="_blank" rel="noreferrer">amplificables vulnerables accesibles desde Internet</a>.&nbsp;</p>
                    <p>En el informe &laquo;<a href="https://imasd.lacnic.net/reportes/ciberseguridad/estudio-de-recursos-de-internet-presentes-en-la-region-de-lacnic-con-protocolos-udp-que-posibilitan-ataques-de-tipo-ddos.pdf" target="_blank" rel="noreferrer">Estudio de recursos de internet presentes en la regi&oacute;n de LACNIC con protocolos UDP que posibilitan ataques de tipo DDoS</a>&raquo; se trata la segunda problem&aacute;tica. All&iacute; se describe c&oacute;mo es posible realizar ataques de denegaci&oacute;n de servicio distribuido o DDoS.</p>
                    <p>De manera resumida, el actor malicioso debe:</p>
                    <ol>
                        <li>Identificar servicios reflexivos y amplificables vulnerables, accesibles desde internet.</li>
                        <li>Enviar requerimientos <em>spoofeados</em> a los servicios vulnerables identificados previamente (presentes en internet por desconocimiento), utilizando como IP de origen la IP del objetivo del ataque.</li>
                        <li>Realizar de manera coordinada el paso anterior desde distintos <em>hosts</em>, en organizaciones que no aplican filtros de tipo <em>outbound</em> SAV, posibilitando as&iacute; incrementar el volumen del ataque, de manera proporcional a la cantidad de <em>hosts</em> utilizados por el actor malicioso.</li>
                    </ol>
                    <h4>NSNXAttack</h4>
                    <p><a href="https://www.nxnsattack.com/shafir2020-nxnsattack-paper.pdf" target="_blank" rel="noreferrer">NSNXAttack</a> es un ataque que, a diferencia de los anteriores, se aprovecha de que la organizaci&oacute;n v&iacute;ctima no implementa filtros de tipo <em>inbound</em> SAV. Estos ataques se valen del hecho de que un actor malicioso puede realizar consultas al DNS <em>resolver</em> como si estuviese utilizando un <em>host</em> de la organizaci&oacute;n. En particular, en este tipo de ataque se busca afectar la disponibilidad del servicio brindado por el DNS recursivo de la organizaci&oacute;n.</p>
                    <h4>DNS cache poisoning</h4>
                    <p>El DNS <em><a href="https://es.wikipedia.org/wiki/Envenenamiento_de_DNS" target="_blank" rel="noreferrer">cache poisoning</a>&nbsp;</em>es un ataque para proveer un servidor de nombres de dominio (<a href="https://es.wikipedia.org/wiki/Domain_Name_System">DNS</a>) de datos que no se originan en fuentes autoritativas. Los problemas de DNS <em>cache poisoning</em> pueden darse independientemente de que la organizaci&oacute;n v&iacute;ctima del ataque implemente filtros de tipo <em>inbound</em> SAV. Sin embargo, la no implementaci&oacute;n de dichos filtros puede facilitar el ataque.</p>
                    <p>El DNS <em>cache poisoning</em> afecta la integridad de los datos provistos por el servicio de DNS, lo que ofrece el eslab&oacute;n inicial de un ataque m&aacute;s complejo. Por ejemplo, uno que se valga de esta situaci&oacute;n para realizar <em>phishing</em> a usuarios, <a href="https://www.zscaler.it/blogs/security-research/brazilian-bank-targeted-phishing-site-and-dns-poisoning" target="_blank" rel="noreferrer">estafas bancarias</a>o el <a href="https://www.theguardian.com/technology/2017/aug/31/wikileaks-hacked-ourmine-group-julian-assange-dns-attack" target="_blank" rel="noreferrer"><em>defacement</em> </a>de sitios web.</p>
                    <h4>Otros posibles ataques</h4>
                    <p>Dado que el <em>inbound IP spoofing</em> permite a un actor malicioso interactuar con servicios internos desde direcciones locales, podr&iacute;an existir otros protocolos de aplicaci&oacute;n en el que distintos vectores de ataque se aprovechen de que no se aplique el paradigma de confianza cero, conocido como <em>zero trust.</em></p>
                    <p><sup><a href="https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf">https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf</a></sup></p>
                    <p><sup><a href="https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security">https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security</a></sup></p>
                    <p><sup><a href="https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/">https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/</a></sup></p>
                    <p><sup><a href="https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model">https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model</a></sup></p>
                    <h3>Beneficios de tomar acciones contra el IP spoofing</h3>
                    <p>Los asociados de LACNIC se pueden beneficiar de tomar acciones para evitar el IP <em>spoofing</em> con la mitigaci&oacute;n de distintos ataques que aprovechan el <em>inbound IP spoofing</em> contra su organizaci&oacute;n. En particular, se pueden mencionar ataques como <em>NSNXAttack</em> y DNS <em>cache poisoning</em>, as&iacute; como otros que puedan surgir en el futuro, basados en el aprovechamiento de la confianza dada por comunicaciones internas en ambientes no alineados con el paradigma de <em>confianza cero</em>.</p>
                    <p>Asimismo, la comunidad de internet se puede beneficiar de que los distintos actores tomen acciones para evitar el IP <em>spoofing</em>, al limitar las redes desde las que es posible lanzar ataques de DDoS.&nbsp;</p>
                </>}
                {myLang === 'en' && <>
                    <h2>IP Spoofing</h2>
                    <h3>What is IP spoofing?</h3>
                    <p>IP address spoofing or IP spoofing is masquerading as another IP address. It refers to the creation of IP packets with a false source IP address, for the purpose of impersonating another computing system. IP spoofing allows malicious actors to launch different types of attacks and makes their detection very difficult.</p>
                    <p>In IP spoofing, a malicious actor uses tools that modify the IP address of the sender of the packets so that to the recipient it appears that the packets came from another source.</p>
                    <p>IP spoofing is possible when proper filtering policies are not implemented to prevent this type of attacks.(<a href="https://www.rfc-editor.org/rfc/rfc2827" target="_blank" rel="noreferrer" >https://www.rfc-editor.org/rfc/rfc2827</a>&nbsp;|&nbsp;<a href="https://www.rfc-editor.org/rfc/rfc3704.html" target="_blank" rel="noreferrer" >https://www.rfc-editor.org/rfc/rfc3704.html</a>)&nbsp;These filtering policies should check the source address of the packets. To refer to these policies, we will use the term source address validation (SAV) or SAV filters.</p>
                    <p>An organization may unknowingly allow IP spoofing, both for incoming traffic (inbound IP spoofing) as well as for traffic bound for the Internet (outbound IP spoofing).</p>
                    <h4>Inbound IP spoofing</h4>
                    <p>The possibility that traffic from external autonomous systems will enter an organization by appearing to have originated from IP addresses within the organization itself or in <a href="https://team-cymru.com/community-services/bogon-reference/" target="_blank" rel="noreferrer" >bogon networks</a>. This should not occur and such traffic should be filtered, as:</p>
                    <ul>
                        <li>The organization's IP addresses should be the destination and not the source of incoming traffic. When it uses the organization's own IP addresses, inbound IP spoofing originates various attacks that may affect its users and services.</li>
                        <li>Bogon networks should never appear in internet routing tables.</li>
                    </ul>
                    <p>Inbound IP spoofing can occur when an organization does not deploy SAV for inbound traffic (inbound SAV).</p>
                    <h4>Outbound IP spoofing</h4>
                    <p>The possibility that IP traffic with source IP addresses that do not belong to the organization itself may leave the organization towards external autonomous systems. These situations should never occur, and these packets should be filtered, as they allow various attacks to be launched that may affect the availability of third-party services.</p>
                    <p>Outbound IP can occur when an organization does not deploy SAV for outbound traffic (outbound SAV).</p>
                    <h3>Which protocols are leveraged for IP spoofing?</h3>
                    <p>IP spoofing takes place in the network layer, at the IP address level. Depending on the attack, however, a different protocol may be encapsulated in the IP packets.</p>
                    <p>For example, <a href="https://www.osi.es/es/actualidad/blog/2018/08/21/que-son-los-ataques-dos-y-ddos" target="_blank" rel="noreferrer" >flooding DoS</a> attacks can use various protocols:</p>
                    <ul>
                        <li>HTTP to saturate the service.</li>
                        <li>TCP with specific flags, such as syn, fin, first, etc., to saturate the service.</li>
                        <li>ICMP to increase the volume of the attack and saturate the capacity of the network or server.</li>
                        <li>UDP to increase the volume of the attack and saturate the capacity of the network or server.</li>
                    </ul>
                    <p>Distributed denial-of-service (DDoS) and other types of attacks that need to interact with services using spoofed traffic use application protocols transported over UDP.</p>
                    <p>Applications that use these protocols provide what are known as reflective services. These services use UDP as the transport protocol and do not require handshaking prior to exchanging data.</p>
                    <h3>What type of attacks leverage IP spoofing and how do they do it?</h3>
                    <h4>Flooding DoS attacks</h4>
                    <p>A DoS attack seeks to compromise the availability of a service in the target organization. If a malicious actor controls a host in an organization that does not deploy outbound SAV, they may hide the source of the attack by performing IP spoofing.</p>
                    <h4>Amplification DDoS attacks</h4>
                    <p>For DDoS attacks to be possible, two conditions must be met. First, the organization from which the attack is launched must not have any outbound SAV in place; second, vulnerable reflective and amplifiable services that can be <a href="https://www.cisa.gov/uscert/ncas/alerts/TA14-017A" target="_blank" rel="noreferrer" >accessed from the Internet must be identified</a>.</p>
                    <p>The report titled <a href="https://imasd.lacnic.net/reportes/ciberseguridad/estudio-de-recursos-de-internet-presentes-en-la-region-de-lacnic-con-protocolos-udp-que-posibilitan-ataques-de-tipo-ddos.pdf" target="_blank" rel="noreferrer" >&ldquo;Study of Internet Resources in the LACNIC Service Region that Use UDP Protocols and Enable DDoS Attacks&rdquo;</a> addresses the latter issue. It describes the vulnerabilities that can enable distributed denial-of-service (DDoS) attacks.</p>
                    <p>In short, the malicious actor must:</p>
                    <ol>
                        <li>Identify vulnerable reflective and scalable services that can be accessed from the Internet.</li>
                        <li>Send spoofed requests to previously identified vulnerable services (present on the Internet due to a lack of knowledge), using the IP address of the attack target as the source IP address.</li>
                        <li>Perform the previous step in a coordinated manner from multiple hosts, in organizations that do not apply outbound SAV, thus making it possible to increase the volume of the attack proportionally to the number of hosts used by the malicious actor.</li>
                    </ol>
                    <h4>NSNXAttacks</h4>
                    <p>Unlike the attacks described above, an <a href="https://www.nxnsattack.com/shafir2020-nxnsattack-paper.pdf" target="_blank" rel="noreferrer" >NSNXAttack</a> takes advantage of the fact that the victim organization does not deploy inbound SAV. In these attacks, a malicious actor sends queries to the DNS resolver as if they were using a host belonging to the organization. This type of attack specifically seeks to affect the availability of the service provided by the organization's recursive DNS.</p>
                    <h4>DNS cache poisoning</h4>
                    <p><a href="https://es.wikipedia.org/wiki/Envenenamiento_de_DNS" target="_blank" rel="noreferrer" >DNS cache poisoning</a> is the practice of providing a domain name server (<a href="https://es.wikipedia.org/wiki/Domain_Name_System">DNS</a>) with data that does not originate from authoritative sources. DNS cache poisoning can occur regardless of whether the victim organization deploys inbound SAV. However, failure to deploy inbound SAV may facilitate an attack.</p>
                    <p>DNS cache poisoning affects the integrity of the data provided by the DNS service, creating what can be the first element of more complex attacks, for example, phishing, <a href="https://www.zscaler.it/blogs/security-research/brazilian-bank-targeted-phishing-site-and-dns-poisoning" target="_blank" rel="noreferrer" >bank fraud</a>, or <a href="https://www.theguardian.com/technology/2017/aug/31/wikileaks-hacked-ourmine-group-julian-assange-dns-attack" target="_blank" rel="noreferrer" >website defacement attacks</a>.</p>
                    <h4>Other possible attacks</h4>
                    <p>Because inbound IP spoofing allows a malicious actor to interact with internal services using local addresses, other application protocols may also allow various attack vectors to leverage the fact that the zero-trust paradigm is not applied<em>.</em> </p>
                    <p><a href="https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf" target="_blank" rel="noreferrer" >https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf</a></p>

                    <p><a href="https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security" target="_blank" rel="noreferrer" >https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security</a></p>

                    <p><a href="https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/" target="_blank" rel="noreferrer" >https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/</a></p>

                    <p><a href="https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model" target="_blank" rel="noreferrer" >https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model</a></p>

                    <h3>Benefits of implementing actions against IP spoofing</h3>
                    <p>LACNIC members can benefit from the implementation of actions to prevent IP spoofing by mitigating various attacks that take advantage of inbound IP spoofing against their organization. Attacks such as NSNXAttacks and DNS cache poisoning, as well as others that may be devised in the future, are examples of attacks that take advantage of the trust placed on internal communications within environments that are not in line with the zero-trust paradigm.</p>
                    <p>Likewise, the Internet community as a whole can benefit if the different actors take actions to prevent IP spoofing, limiting the networks from which DDoS attacks can be launched.</p>

                </>}
                {myLang === 'pt' && <>
                    <h2>IP <em>Spoofing</em></h2>
                    <h3>O que &eacute;?</h3>
                    <p>El <em>IP spoofing</em> &eacute; a falsifica&ccedil;&atilde;o de endere&ccedil;os IP. Refere-se &agrave; cria&ccedil;&atilde;o de pacotes IP com um endere&ccedil;o IP de origem falso, com o objetivo de se passar por outro sistema de computador. A falsifica&ccedil;&atilde;o de endere&ccedil;os IP permite que atores maliciosos realizem diferentes tipos de ataques e sua detec&ccedil;&atilde;o &eacute; muito dif&iacute;cil.</p>
                    <p>Para realizar o IP <em>spoofing</em>, um ator malicioso usa ferramentas que modificam o endere&ccedil;o IP de origem no cabe&ccedil;alho IP dos pacotes para que o sistema receptor interprete os pacotes como vindos de outro lugar.</p>
                    <p>O IP <em>spoofing</em> &eacute; poss&iacute;vel devido &agrave; falta da implementa&ccedil;&atilde;o de pol&iacute;ticas de filtragem adequadas para evit&aacute;-lo.(<a href="https://www.rfc-editor.org/rfc/rfc2827">https://www.rfc-editor.org/rfc/rfc2827</a>&nbsp;|<a href="https://www.rfc-editor.org/rfc/rfc3704.html">https://www.rfc-editor.org/rfc/rfc3704.html</a>) Essas pol&iacute;ticas de filtragem devem se encarregar de verificar o endere&ccedil;o de origem dos pacotes. Para fazer refer&ecirc;ncia a essas pol&iacute;ticas, falaremos de <em>filtros do tipo source address validation</em> ou <em>filtros do tipo SAV</em>.</p>
                    <p>Uma organiza&ccedil;&atilde;o pode, sem saber, permitir o IP <em>spoofing</em>, tanto no tr&aacute;fego de entrada (<em>inbound</em> <em>IP</em> <em>spoofing</em>) quanto no tr&aacute;fego de sa&iacute;da para a Internet (<em>outbound</em> <em>IP</em> <em>spoofing</em>).</p>
                    <h4>Inbound IP spoofing</h4>
                    <p>Refere-se &agrave; possibilidade de entrar na organiza&ccedil;&atilde;o, a partir de sistemas aut&ocirc;nomos externos, tr&aacute;fego com endere&ccedil;os IP provenientes da pr&oacute;pria organiza&ccedil;&atilde;o ou de <a href="https://team-cymru.com/community-services/bogon-reference/" target="_blank" rel="noreferrer">redes <em>bogons</em></a>.&nbsp;Estas situa&ccedil;&otilde;es n&atilde;o deveriam ocorrer e devem ser filtradas, uma vez que:</p>
                    <ul>
                        <li>Os blocos IP da organiza&ccedil;&atilde;o deveriam ser o destino do tr&aacute;fego de entrada, e n&atilde;o a origem. O <em>inbound IP spoofing</em>, ao usar endere&ccedil;os IP da pr&oacute;pria organiza&ccedil;&atilde;o, d&aacute; origem a diferentes ataques que podem afetar os seus servi&ccedil;os e os seus usu&aacute;rios.</li>
                        <li>As redes <em>bogons</em> n&atilde;o deveriam aparecer nas tabelas de roteamento da Internet.</li>
                    </ul>
                    <p>O <em>inbound IP spoofing</em> &eacute; poss&iacute;vel quando uma organiza&ccedil;&atilde;o n&atilde;o implementa filtros SAV no tr&aacute;fego de entrada ou, como os chamaremos de agora em diante, <em>filtros do tipo inbound SAV.</em></p>
                    <h4>Outbound IP spoofing</h4>
                    <p>Refere-se &agrave; possibilidade de sair da organiza&ccedil;&atilde;o, para sistemas aut&ocirc;nomos externos, tr&aacute;fego IP com endere&ccedil;os IP de origem que n&atilde;o pertencem &agrave; pr&oacute;pria organiza&ccedil;&atilde;o. Essas situa&ccedil;&otilde;es n&atilde;o deveriam ocorrer e esses pacotes devem ser filtrados, pois permitem a realiza&ccedil;&atilde;o de diferentes ataques que podem afetar a disponibilidade de servi&ccedil;os de terceiros.</p>
                    <p>O <em>outbound IP spoofing</em> &eacute; poss&iacute;vel quando uma organiza&ccedil;&atilde;o n&atilde;o implementa <em>filtros do tipo outbound SAV</em>.</p>
                    <h3>Quais protocolos s&atilde;o usados ​​para fazer IP spoofing?</h3>
                    <p>O IP <em>spoofing</em> &eacute; feito na camada de rede, no n&iacute;vel IP. Por&eacute;m, dependendo do ataque, poderia trocar o protocolo encapsulado pelo IP.</p>
                    <p>Por exemplo, em <a href="https://www.osi.es/es/actualidad/blog/2018/08/21/que-son-los-ataques-dos-y-ddos" target="_blank" rel="noreferrer">ataques de DoS</a>&nbsp;por inunda&ccedil;&atilde;o ou <em>flooding</em> podem ser usados diferentes protocolos:</p>
                    <ul>
                        <li>O HTTP para saturar o servi&ccedil;o fornecido.</li>
                        <li>O TCP com <em>flags</em> espec&iacute;ficos, como syn, fin, first, etc., para saturar o servi&ccedil;o fornecido.</li>
                        <li>O ICMP para dar mais volume ao ataque e saturar a capacidade da rede ou do servidor.</li>
                        <li>O UDP para dar mais volume ao ataque e saturar a capacidade da rede ou do servidor.</li>
                    </ul>
                    <p>Para os chamados <em>ataques de DDoS</em> e outros que precisam interagir com servi&ccedil;os usando tr&aacute;fego <em>spoofeado</em>, s&atilde;o usados ​​protocolos de aplicativos que s&atilde;o transportados por UDP.</p>
                    <p>Os aplicativos que usam esses protocolos fornecem os chamados <em>servi&ccedil;os reflexivos</em>. Esses servi&ccedil;os, que usam o UDP como protocolo de transporte, n&atilde;o precisam de um <em>handshake</em> pr&eacute;vio para o in&iacute;cio da conex&atilde;o.</p>
                    <h3>Quais ataques se aproveitam do IP spoofing e como eles o exploram?</h3>
                    <h4>Ataques de DoS por inunda&ccedil;&atilde;o</h4>
                    <p>O objetivo de um ataque de DoS &eacute; comprometer a disponibilidade de um servi&ccedil;o na organiza&ccedil;&atilde;o v&iacute;tima. Caso a organiza&ccedil;&atilde;o onde o <em>host</em> est&aacute; controlado pelo ator malicioso n&atilde;o implemente filtros do tipo <em>outbound SAV</em>, este poderia ocultar a origem do ataque fazendo IP <em>spoofing</em>.</p>
                    <h4>Ataques DDoS por amplifica&ccedil;&atilde;o</h4>
                    <p>Para que os ataques de DDoS sejam poss&iacute;veis, duas condi&ccedil;&otilde;es devem estar presentes. De um lado, a organiza&ccedil;&atilde;o a partir da qual o ator malicioso realiza o ataque n&atilde;o tem filtros do tipo <em>outbound SAV</em> implementados e, do outro, &eacute; necess&aacute;ria a identifica&ccedil;&atilde;o de servi&ccedil;os reflexivos e escal&aacute;veis vulner&aacute;veis, ​​<a href="https://www.cisa.gov/uscert/ncas/alerts/TA14-017A" target="_blank" rel="noreferrer">acess&iacute;veis desde a Internet</a>.</p>
                    <p>No relat&oacute;rio <a href="https://imasd.lacnic.net/reportes/ciberseguridad/estudio-de-recursos-de-internet-presentes-en-la-region-de-lacnic-con-protocolos-udp-que-posibilitan-ataques-de-tipo-ddos.pdf" target="_blank" rel="noreferrer">&laquo;Estudo dos recursos da Internet presentes na regi&atilde;o do LACNIC com protocolos UDP que possibilitam ataques do tipo DDoS&raquo;</a> &eacute; abordado o segundo problema.&nbsp;Nele &eacute; descrito como &eacute; poss&iacute;vel realizar ataques de nega&ccedil;&atilde;o de servi&ccedil;o ou DDoS.</p>
                    <p>Em resumo, o ator malicioso deve:</p>
                    <ol>
                        <li>Identificar servi&ccedil;os reflexivos e escal&aacute;veis vulner&aacute;veis, acess&iacute;veis desde a Internet.</li>
                        <li>Enviar requerimentos <em>spoofeados</em> para os servi&ccedil;os vulner&aacute;veis ​​previamente identificados (presentes na Internet por desconhecimento), usando o IP do alvo do ataque como IP de origem.</li>
                        <li>Realizar o passo anterior de forma coordenada desde diferentes <em>hosts</em>, em organiza&ccedil;&otilde;es que n&atilde;o aplicam filtros do tipo <em>outbound SAV</em>, possibilitando assim aumentar o volume do ataque, proporcionalmente ao n&uacute;mero de <em>hosts</em> usados pelo agente malicioso.</li>
                    </ol>
                    <h4>NSNXAttack</h4>
                    <p>O <a href="https://www.nxnsattack.com/shafir2020-nxnsattack-paper.pdf" target="_blank" rel="noreferrer">NSNXAttack</a> &eacute; um ataque que, ao contr&aacute;rio dos anteriores, tira partido do fato de a organiza&ccedil;&atilde;o v&iacute;tima n&atilde;o implementar filtros do tipo<em> inbound SAV</em>. Esses ataques aproveitam o fato de que um ator malicioso pode realizar consultas ao <em>DNS resolver</em> como se estivesse usando um <em>host</em> da organiza&ccedil;&atilde;o. Em particular, este tipo de ataque visa afetar a disponibilidade do servi&ccedil;o prestado pelo DNS recursivo da organiza&ccedil;&atilde;o.</p>
                    <h4>DNS cache poisoning</h4>
                    <p>O <a href="https://es.wikipedia.org/wiki/Envenenamiento_de_DNS" target="_blank" rel="noreferrer">DNS <em>cache poisoning</em></a>&nbsp;&eacute; um ataque para fornecer um servidor de nomes de dom&iacute;nio (<a href="https://es.wikipedia.org/wiki/Domain_Name_System">DNS</a>) de dados que n&atilde;o se originam de fontes autorizadas. Os problemas de DNS <em>cache poisoning</em> podem ocorrer independentemente de a organiza&ccedil;&atilde;o v&iacute;tima do ataque implementar filtros do tipo <em>inbound SAV</em>. No entanto, a n&atilde;o implementa&ccedil;&atilde;o de tais filtros pode facilitar o ataque.</p>
                    <p>O <a href="https://www.zscaler.it/blogs/security-research/brazilian-bank-targeted-phishing-site-and-dns-poisoning" target="_blank" rel="noreferrer">DNS <em>cache poisoning</em> </a>afeta a integridade dos dados fornecidos pelo servi&ccedil;o de DNS, o elo inicial para um ataque mais complexo. Por exemplo, aquele que usa esta situa&ccedil;&atilde;o para fazer <em>phishing</em> a usu&aacute;rios, fraude <a href="https://www.theguardian.com/technology/2017/aug/31/wikileaks-hacked-ourmine-group-julian-assange-dns-attack" target="_blank" rel="noreferrer">banc&aacute;ria&nbsp;ou <em>defacement</em> de sites</a>.</p>
                    <h4>Outros ataques poss&iacute;veis</h4>
                    <p>Uma vez que o <em>inbound IP spoofing</em> permite que um ator malicioso interaja com servi&ccedil;os internos de endere&ccedil;os locais, poderiam existir outros protocolos de aplica&ccedil;&atilde;o nos quais diferentes vetores de ataque tirem proveito do fato de que o paradigma de confian&ccedil;a zero, conhecido como <em>zero trust</em>, n&atilde;o &eacute; aplicado. </p>
                    <p><a href="https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf" target="_blank" rel="noreferrer">https://storage.googleapis.com/pub-tools-public-publication-data/pdf/43231.pdf</a></p>
                    <p><a href="https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security" target="_blank" rel="noreferrer">https://cloud.google.com/blog/topics/developers-practitioners/what-zero-trust-identity-security</a></p>
                    <p><a href="https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/" target="_blank" rel="noreferrer">https://www.cloudflare.com/es-es/learning/security/glossary/what-is-zero-trust/</a></p>
                    <p><a href="https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model" target="_blank" rel="noreferrer">https://www.akamai.com/es/our-thinking/zero-trust/zero-trust-security-model</a></p>
                    <h3>Benef&iacute;cios de agir contra o IP spoofing</h3>
                    <p>Os associados do LACNIC podem se beneficiar ao tomar a&ccedil;&otilde;es para prevenir o IP <em>spoofing</em> com a mitiga&ccedil;&atilde;o de diferentes ataques que aproveitam o <em>inbound IP spoofing</em> contra sua organiza&ccedil;&atilde;o. Em particular, podemos citar ataques como o <em>NSNXAttack</em> e DNS <em>cache poisoning</em>, bem como outros que possam surgir no futuro, com base no aproveitamento da confian&ccedil;a conferida pela comunica&ccedil;&atilde;o interna em ambientes n&atilde;o alinhados com o paradigma de <em>confian&ccedil;a zero</em>.</p>
                    <p>Da mesma forma, a comunidade da Internet pode se beneficiar com as a&ccedil;&otilde;es dos diferentes atores para prevenir o <em>IP spoofing</em>, limitando as redes a partir das quais &eacute; poss&iacute;vel lan&ccedil;ar ataques de DDoS.</p>

                </>}
            </div>
        </div>
    )
}

export default IPSpoofing
