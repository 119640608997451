import React, {useContext} from 'react';
import { LangContext } from '../../../context/DataContext' 

function MecanismosDeTransicion() {
    const { myLang } = useContext(LangContext)
    return (
        <div>
            <div id="p7">
                {myLang === 'es' && <>
                    <h2>Mecanismos de Transici&oacute;n</h2>
                    <p>Dada la gran diversidad de tecnolog&iacute;as, en los &uacute;ltimos a&ntilde;os se fueron definiendo distintos mecanismos de transici&oacute;n, los cuales podemos separar los siguientes grupos:</p>
                    <ul>
                        <li><strong>Doble Pila</strong>: Consiste en a&ntilde;adir IPv6 en todos  los puntos (contenido, transporte y dispositivos) permitiendo una <em>coexistencia </em>de ambas versiones del  protocolo IP. De esta manera un dispositivo final, por definici&oacute;n, si est&aacute; con  doble pila va a preferir utilizar IPv6 para cada servicio disponible. Como no  siempre es posible contar con contenido, transporte o dispositivos con soporte  de IPv6, fueron surgiendo los distintos modelos descritos a continuaci&oacute;n.</li>
                        <li><strong>Soporte IPv6 sobre infraestructura IPv4</strong>:  Originalmente al haber tan poco soporte de IPv6 en las redes de acceso y CPEs,  surgieron algunos mecanismos basados en t&uacute;neles y traducciones (seg&uacute;n el caso)  que permiten tener IPv6 en un dispositivo final de usuario y transportar su  tr&aacute;fico sobre una red IPv4 del ISP. Algunos ejemplos de esto son:</li>
                        <ul>
                        <li>6in4</li>
                        <li>6to4 [<a href="https://tools.ietf.org/html/rfc3056" target="_blank" rel="noreferrer">RFC3056</a>]</li>
                        <li>Teredo [<a href="https://tools.ietf.org/html/rfc4380" target="_blank" rel="noreferrer">RFC4380</a>]</li>
                        <li>6RD [<a href="https://tools.ietf.org/html/rfc5969" target="_blank" rel="noreferrer">RFC5969</a>]</li>
                        </ul>
                        <li><strong>Soporte de IPv4 sobre infraestructura IPv6</strong>: En  este caso se llegan a considerar ya redes de acceso solo IPv6, e IPv4 como un  servicio adicional. De ah&iacute; que surge el concepto de IPv4 as a Service (<strong>IPv4-as-a-Service</strong>). Sobre estos  mecanismos nos enfocaremos en el presente documento.</li>
                    </ul>
                    <p>Utilizar Carrier Grade NAT (CGN) no se lo considera naturalmente un mecanismo de transici&oacute;n ya que lo &uacute;nico que realiza es seguir extendiendo “la vida &uacute;til” de IPv4. Muchas veces termina siendo necesario tener que utilizarlo por el ISP para seguir garantizando conectividad IPv4 de extremo a extremo ante la falta de IPv4, limitaciones tecnol&oacute;gicas y financieras. Independientemente de la utilizaci&oacute;n de CGN, siempre se debe realizar el despliegue IPv6.</p>
                </>}
            </div>
        </div>
    )
}

export default MecanismosDeTransicion
